import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import ISystem from "rdptypes/project/ISystem";
import IAuthState from '../auth/IAuthState';
import { setValue } from '../helpers/objectPathResolver';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange } from './helpers';

const actionTypeId = "UpdateLayoutProperty";

interface ActionData extends IActionData {
    layoutId: string;

    property: string;
    value: any;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;

    if (data.property === "systems") {
        // The JSON field import works by setting the systems array directly. This should probably
        // use the CreateSystemAction instead, but it doesn't (yet).

        // If necessary we have to set the partsPackageId on each system to the initial value.

        const value: { [systemId: string]: ISystem } = { ...data.value };
        for (const sys of Object.values(value)) {
            if (!sys.partsPackageId) {
                sys.partsPackageId = "2024060101";
            }
        }

        setValue(state.layouts[data.layoutId], data.property, value);
    } else {
        setValue(state.layouts[data.layoutId], data.property, data.value);
    }

    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
});

export const createNewUpdateLayoutPropertyAction =
    (layoutId: string, property: string, value: any, authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            property,
            value
        } as ActionData,
        authState);