import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Position, area, convertArea, feature, point } from "@turf/turf";
import { Feature, Geometry } from "geojson";
import IAction from "rdptypes/IAction";
import CenterPivotGeometryHelper from "../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import { getSystemValidity } from "../../GeometryHelpers/SystemGeometryHelpers/getSystemValidity";
import { createNewLayoutAction } from "../../actions/NewLayoutAction";
import { createNewUpdateLayoutPropertyAction } from "../../actions/UpdateLayoutProperty";
import { createBlankProject, executeAction } from "../../actions/actionExecutorRegistry";
import IAuthState from "../../auth/IAuthState";
import { staticDevSettingsDbProvider } from "../../db/DevSettingsDbProvider";
import IDbProject from "../../db/IDbProject";
import { DisplayAreaUnitBuilder, DisplayAreaUnits } from "../../helpers/areas";
import { handleCreateNewOptimizedSystem } from "../../helpers/optimizedSystemHelpers/handleCreateNewOptimizedSystem";
import IProject from "../../model/project/IProject";
import * as optimizeCenterPivotModule from "../../optimization/centerPivot";
import * as CONSTANTS from "./copied-from-mapbox-gl-draw/constants";

const SimpleSelectMode = MapboxDraw.modes.simple_select;
export const DynamicCenterPivotOptimizationMode = { ...SimpleSelectMode };
export default DynamicCenterPivotOptimizationMode;

const DEV_TEST = false//false//true;
// const DEV_TEST_CENTER = undefined;
// const DEV_TEST_CENTER = [-97.7086075320188, 40.14773532333302];
// const DEV_TEST_CENTER = [-97.71355298257359,40.150752680639755]
// const  DEV_TEST_CENTER = [-97.71359224030519, 40.150672335998166]
// const  DEV_TEST_CENTER =  [-97.70821349796239, 40.14708326899793]
// PASSING
// const  DEV_TEST_CENTER =  [-97.71614750873411, 40.14782489288342]
// const DEV_TEST_CENTER = [-97.71502840446628,40.14872574191486]
// const DEV_TEST_CENTER = [-97.7136948199981, 40.14755826846877]
// const DEV_TEST_CENTER = [-97.71528379679117, 40.14863102881381]
// const DEV_TEST_CENTER = [-97.70967450306334, 40.14775578728302]
// const DEV_TEST_CENTER = [-97.71087586254113, 40.14878327213705]
// const DEV_TEST_CENTER = [-97.71077407423164, 40.148919318299306]
// const DEV_TEST_CENTER = [-97.71591685191756, 40.1493589590857]
// const DEV_TEST_CENTER = [-97.71656940140869, 40.147628361299894]

// const DEV_TEST_CENTER = [-97.82529167095495, 40.02100583091477] // big
// const DEV_TEST_CENTER = [-97.82590643092269, 40.02053501192209] // small

// const DEV_TEST_CENTER = [-97.83993963806763, 40.02370274942092] 

const DEV_TEST_CENTER = [-97.83976017484774,40.01961149291006]


interface IOptions {
    optimizationSettings: optimizeCenterPivotModule.IOptimizationSettings;
    project: IProject;
    layoutId: string;
    authState: IAuthState;
}

export interface IDrawUpdateExtEvent_DynamicCenterPivotOptimizationResult {
    action: "dynamic_center_pivot_optimization_result";
    definition: {
        optimizedSystem: optimizeCenterPivotModule.IOptimizedSystem;
    };
}

interface IArgs {
    position: Position;
    optimizationSettings: optimizeCenterPivotModule.IOptimizationSettings;
    project: IProject;
    layoutId: string;
    authState: IAuthState;
}
const handlePreviewOptimizedDynamicSystem = async (args: IArgs, areaDisplayUnits: DisplayAreaUnits, optimizationArgs: optimizeCenterPivotModule.IOptimizationArgs) => {
    // const t = createTimerLog("inner");
    const features: Feature<Geometry>[] = [];
    const optimizedSystem = await optimizeCenterPivotModule.optimize({
        optimizationArgs,
        optimizationSettings: {
            ...args.optimizationSettings,
            center: point(args.position).geometry,
        },
    });
    let irrigatedAreaLabel = "-";
    // t.logDeltaMS("optimize")
    if (optimizedSystem) {
        const fakeState = createBlankProject();
        const dbPrjFaker: IDbProject = {
            state: fakeState,
            pushAction: async (action: IAction) => {
                executeAction(action, fakeState);
                return fakeState;
            },
            readonly: false,
        };
        const newLayoutAction = createNewLayoutAction(args.authState);
        dbPrjFaker.pushAction(newLayoutAction.action);
        dbPrjFaker.pushAction(
            createNewUpdateLayoutPropertyAction(
                newLayoutAction.layoutId,
                "fieldBoundary",
                args.project.layouts[args.layoutId].fieldBoundary,
                args.authState
            )
        );
        const customerSettings = staticDevSettingsDbProvider.customer.get();
        const dealerPricing = undefined;
        const systemId = handleCreateNewOptimizedSystem({
            optimizedSystem,
            authState: args.authState,
            dbPrj: dbPrjFaker,
            layoutId: newLayoutAction.layoutId,
            spanDefaults: staticDevSettingsDbProvider.span.getUseCustom()
                ? staticDevSettingsDbProvider.span.get(false)
                : undefined,
            towerDefaults: staticDevSettingsDbProvider.tower.getUseCustom()
                ? staticDevSettingsDbProvider.tower.get(false)
                : undefined,
            customerSettings,
            dealerPricing
        });

        // const t = createTimerLog("inner");
        const f = CenterPivotGeometryHelper.createSingleGeoJSONFeature(
            dbPrjFaker.state.layouts[newLayoutAction.layoutId].systems[systemId],
            dbPrjFaker.state.layouts[newLayoutAction.layoutId].systems[systemId].centerPivot.point
        )
        f.feature.properties = {    
            rdpFeatureType: "dynamicOptimization/irrigatedArea-flangedSide",
        }
        features.push(
            f.feature
        )
        if (f.wheelTracks) {
            features.push(
                ...f.wheelTracks.map(wt => (
                    feature(
                        wt.geometry,
                        {  
                            rdpFeatureType:"dynamicOptimization/wheelTrack"
                        }
                    )
                ))
            )

        }

        const irrigatedAreaM2 = f.feature.geometry.type === 'Polygon' ? area(f.feature) : 0;
        if (irrigatedAreaM2 > 0) {
            const irrigatedAcres = convertArea(irrigatedAreaM2, 'meters', 'acres');
            irrigatedAreaLabel = new DisplayAreaUnitBuilder(irrigatedAcres, 'acres')
                .convert(areaDisplayUnits)
                .appendValue(2)
                .appendString(" ")
                .appendShortName()
                .toString();
            
        }

        if (DEV_TEST) {
            dbPrjFaker.pushAction(
                createNewUpdateLayoutPropertyAction(
                    newLayoutAction.layoutId,
                    "obstacles",
                    args.project.layouts[args.layoutId].obstacles,
                    args.authState
                )
            );
            dbPrjFaker.pushAction(
                createNewUpdateLayoutPropertyAction(
                    newLayoutAction.layoutId,
                    "wheelObstacles",
                    args.project.layouts[args.layoutId].wheelObstacles,
                    args.authState
                )
            );
            dbPrjFaker.state.systemClearance = args.project.systemClearance;
            const a = new CenterPivotGeometryHelper({ project: dbPrjFaker.state, layoutId: newLayoutAction.layoutId, systemId }).getSystemValidityArgs();
            const validity = getSystemValidity(
                "dyanmic",
                "dyanmic",
                a
            )
            if (validity !== 'valid') {
                console.log("v", validity, args.position)
            }
        }
    }
    return {
        features,
        optimizedSystem,
        irrigatedAreaLabel
    };
};

// Notes:
DynamicCenterPivotOptimizationMode.onSetup = function (this, options: IOptions) {
    const point = this.newFeature({
        type: CONSTANTS.geojsonTypes.FEATURE,
        properties: {
            rdpFeatureType: "dynamicCenterInvalid",
            rdpDynamicOptimizationLabel: true
        },
        geometry: {
            type: CONSTANTS.geojsonTypes.POINT,
            coordinates: [],
        },
    });

    this.addFeature(point);

    const optimizationArgs = optimizeCenterPivotModule.createOptimizationArgs({
        project: options.project,
        layoutId: options.layoutId,
        sacType: options.optimizationSettings?.sacType
    })
    // turn the opts into state.
    const state = {
        dragMoveLocation: null,
        boxSelectStartLocation: null,
        boxSelectElement: undefined,
        boxSelecting: false,
        canBoxSelect: false,
        dragMoving: false,
        canDragMove: false,
        initiallySelectedFeatureIds: [],

        // custom:
        options,
        optimizationFeatureIds: [],
        optimizedSystem: undefined,
        point,
        displaySettings: staticDevSettingsDbProvider.display.get(),
        optimizationArgs
    };

    this.fireActionable();

    this.setActionableState({
        combineFeatures: false,
        uncombineFeatures: false,
        trash: true,
    });

    return state;
};

DynamicCenterPivotOptimizationMode.toDisplayFeatures = function (
    this: MapboxDraw.DrawCustomModeThis,
    state,
    feature: Feature,
    display
) {
    if (
        feature.properties.id === state.point.id &&
        (feature.geometry.type !== "Point" || feature.geometry.coordinates.length !== 2)
    ) {
        return;
    }
    display(feature);
};

DynamicCenterPivotOptimizationMode.onMouseMove = function (this, state, e) {
    // const t = createTimerLog("---------------mouseMove");
    const { lngLat } = e;
    const movedPoint = DEV_TEST && DEV_TEST_CENTER
        ? DEV_TEST_CENTER
        : [lngLat.lng, lngLat.lat];
    const { authState, layoutId, optimizationSettings, project } = state.options as IOptions;
    state.optimizationFeatureIds.forEach((id) => this.deleteFeature(id));
    state.optimizationFeatureIds = [];
    state.optimizedSystem = undefined;
    state.point.incomingCoords(movedPoint);

    handlePreviewOptimizedDynamicSystem({
        authState,
        layoutId,
        optimizationSettings,
        position: movedPoint,
        project,
    }, state.displaySettings.areas, state.optimizationArgs).then(result => {
        state.optimizationFeatureIds = result.features.map((f) => {
            const r = this.newFeature(f);
            this.addFeature(r);
            return r.id;
        });
        state.optimizedSystem = result.optimizedSystem;
        if (result.optimizedSystem) {
            this.updateUIClasses({ mouse: CONSTANTS.cursors.ADD });
            state.point.setProperty("rdpFeatureType", "dynamicCenterValid");
            state.point.setProperty("title", result.irrigatedAreaLabel);
        } else {
            this.updateUIClasses({ mouse: CONSTANTS.cursors.POINTER });
            state.point.setProperty("rdpFeatureType", "dynamicCenterInvalid");
            state.point.setProperty("title", "");
        }
    
        // t.logMS();
        return false;
    });

};

DynamicCenterPivotOptimizationMode.onStop = function (this, state) {
    state.optimizationFeatureIds.forEach((id) => this.deleteFeature(id));
    state.optimizationFeatureIds = [];
    this.deleteFeature(state.point.id);
};

DynamicCenterPivotOptimizationMode.onTap = DynamicCenterPivotOptimizationMode.onClick = function (this, state, e) {
    if (e.originalEvent.button === 0) {
        // left mouse: accept
        if (state.optimizedSystem) {
            this.map.fire("draw.update_ext", {
                action: "dynamic_center_pivot_optimization_result",
                definition: {
                    optimizedSystem: state.optimizedSystem,
                },
            } as IDrawUpdateExtEvent_DynamicCenterPivotOptimizationResult);
        }
    }
    this.changeMode(CONSTANTS.modes.SIMPLE_SELECT, {});
};
