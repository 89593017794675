import * as turf from "@turf/turf";
import * as React from "react";

import EastIcon from '@mui/icons-material/East';
import EditIcon from '@mui/icons-material/Edit';
import NorthIcon from '@mui/icons-material/North';
import RestoreIcon from '@mui/icons-material/Restore';
import SouthIcon from '@mui/icons-material/South';
import WestIcon from '@mui/icons-material/West';
import { Button, Card, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from "@mui/material";
import i18next, { t } from "i18next";
import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import IBoolean from "rdptypes/roe/IBoolean";
import ICard from "rdptypes/roe/ICard";
import IChoice from "rdptypes/roe/IChoice";
import INumber from "rdptypes/roe/INumber";
import IPage from "rdptypes/roe/IPage";
import { partsPackages } from "roedata/data";
import { createNewUpdateSystemPropertyAction } from "../../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../../auth/AuthCtx";
import PageRenderer from "../../../../../components/roe/componentRenderers/PageRenderer";
import { pageStyles } from "../../../../../components/roe/styles";
import DevSettingsCtx from "../../../../../db/DevSettingsCtx";
import { formatLatLn } from "../../../../../docGeneration/DocumentGenerationHelpers";
import { translationEn } from '../../../../../locales/en/translation.json';
import { translationEs } from '../../../../../locales/es/translation.json';
import IDrawerTab from "../IDrawerTab";
import { useDrawerValidation } from "../useDrawerValidation";
import EditPivotCenterDialog from "./EditPivotCenterDialog";

const pivotImg = new URL(
    'pivot.png?as=webp&width=25',
    import.meta.url
  );

const page: IPage = {
    position: 0,
    title: "",
    components: [
        {
            type: "choice",
            fieldPath: "Circle.CenterPivot.PivotCenterHeight",
            title: { "en" : translationEn["height-cap"], "es": translationEs["height-cap"]},
            options: [
                { id: "Standard", title: { "en" : translationEn["standard"], "es": translationEs["standard"]}, default: "true" },
                { id: "LowProfile", title: { "en" : translationEn["common-phrases"]["sltLowProfile"], "es": translationEs["common-phrases"]["sltLowProfile"]}},
                { id: "Sugargator", title: { "en" : translationEn["common-phrases"]["sltSugargator"], "es": translationEs["common-phrases"]["sltSugargator"]}},
            ]
        } as IChoice,
        {
            type: "card",
            title: "Partial Pivot Barriers",
            disableDealerSettings: true,
            children: [
                { 
                    type: "boolean", 
                    title: {"en":"Partial Pivot", "es": "Partial Pivot"}, 
                    fieldPath: "Circle.CenterPivot.isPartialPivot",
                    validate: false,
                    hook: (value: boolean, sys?: ISystemBase): ({ fieldPath: string; value: any; }[]) | undefined => {
                        if (sys?.FlangedSide?.Span?.some(x => x.Disconnecting)) {
                            return [
                                { 
                                    fieldPath: "FlangedSide.Span",
                                    value: sys.FlangedSide.Span.map(x => ({
                                        ...x,
                                        Disconnecting: false,
                                        dropSpanEndRelativeToPreviousSpanEnd: undefined,
                                        dropSpanStartRelativeToPreviousSpanStart: undefined
                                    }))
                                }
                            ]
                        }
                    }
                },
                {
                    visible: (sys: ISystemBase) => sys.Circle.CenterPivot.isPartialPivot,
                    type: "number",
                    fieldPath: "Circle.CenterPivot.clockwiseCompassHeadingEnd",
                    title: { "en" :"Forward", "es": "Inicio"},
                    icon: "rotate-right",
                    default: 0,
                    units: "°",
                    decimalPlaces: 1,
                    min: 0,
                    max: 360
                } as INumber,
                {
                    visible: (sys: ISystemBase) => sys.Circle.CenterPivot.isPartialPivot,
                    type: "number",
                    fieldPath: "Circle.CenterPivot.clockwiseCompassHeadingStart",
                    title:{ "en" : "Reverse", "es": "Final"},
                    icon: "rotate-left",
                    default: 0,
                    units: "°",
                    decimalPlaces: 1,
                    min: 0,
                    max: 360
                } as INumber
            ]
        } as ICard,  
        {
            type: "card",
            title: "Allow other systems to overlap this system",
            disableDealerSettings: true,
            children: [
                { 
                    type: "boolean", 
                    title: { "en": "Allow overlap", "es": "Allow overlap" }, 
                    fieldPath: "overlapping",
                    validate: false
                } as IBoolean,
            ]
        } as ICard, 
    ]
}

const nudgeFactor = 1; // ft
const directionButtonStyles = { width: 48, height: 48 };
const centerPivotTab: IDrawerTab = {
    icon:  <img src={pivotImg}/>,
    getTitle: () => i18next.format(t("center-pivot"), 'capitalize-each'),
    component: ({ dbPrj, layoutId, systemId }) => {
        const drawerValidation = useDrawerValidation({
            dbPrj, layoutId, systemId
        });
        const userDisplayCoords = React.useContext(DevSettingsCtx).dealerSettings.display.current.coordinates;
        const system = dbPrj.state.layouts[layoutId]?.systems[systemId];
        const { roeData } = partsPackages[system.partsPackageId];
        const authState = React.useContext(AuthCtx);
        const [showEditPivotCenterDialog,setShowEditPivotCenterDialog ] = React.useState(false);
        const [ startingCoordaintes ] = React.useState(turf.point(system.centerPivot.point.coordinates).geometry);
        const [ offsetX, setOffsetX ] = React.useState<string>("");
        const [ offsetY, setOffsetY ] = React.useState<string>("");

        const startX = startingCoordaintes.coordinates[0];
        const startY = startingCoordaintes.coordinates[1];
        const endX = system.centerPivot.point.coordinates[0];
        const endY = system.centerPivot.point.coordinates[1];
        const hasCenterMoved = !(startX === endX && startY === endY);
        React.useEffect(() => {
            const x = (endX === startX) 
                ? 0
                : turf.distance(
                    startingCoordaintes, 
                    [  endX, startY ], 
                    { units: 'feet'}
                ) * Math.sign(endX - startX)
            setOffsetX(x.toFixed(2))
        }, [ system.centerPivot.point.coordinates[0] ]);
        React.useEffect(() => {
            const y = (endY === startY) 
                ? 0
                : turf.distance(
                    startingCoordaintes, 
                    [  startX, endY ], 
                    { units: 'feet'}
                ) * Math.sign(endY - startY);
            setOffsetY(y.toFixed(2));
        }, [ system.centerPivot.point.coordinates[1] ]);


        const handleResetCoords = () => {
            if (!hasCenterMoved) return;
            dbPrj.pushAction(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    'centerPivot.point.coordinates',
                    startingCoordaintes.coordinates,
                    authState
                )
            )
        }

        const handleXYCoordsOnBlur = (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
            let parsedX = parseFloat(offsetX);
            let parsedY = parseFloat(offsetY);
            if (isNaN(parsedX)) parsedX = 0;
            if (isNaN(parsedY)) parsedY = 0;
            const crntCoord = structuredClone(startingCoordaintes);
            if (parsedX) {
                turf.transformTranslate(crntCoord, parsedX, 90, { units: 'feet', mutate: true });
            }
            if (parsedY) {
                turf.transformTranslate(crntCoord, parsedY, 0, { units: 'feet', mutate: true });
            }
            dbPrj.pushAction(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    'centerPivot.point.coordinates',
                    crntCoord.coordinates,
                    authState
                )
            )
        }
                
        const handleNudgeCoord = (v: number, bearing: number) => {
            const crntCoord = structuredClone(system.centerPivot.point);
            turf.transformTranslate(crntCoord, v, bearing, { units: 'feet', mutate: true });
            dbPrj.pushAction(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    'centerPivot.point.coordinates',
                    crntCoord.coordinates,
                    authState
                )
            )
        }

        const formattedLatLng = formatLatLn(system.centerPivot.point.coordinates[1], system.centerPivot.point.coordinates[0], userDisplayCoords);
        return (
            <Stack direction='column' margin={1}>
                <PageRenderer 
                    page={page} 
                    rules={roeData.rules}
                    dbPrj={dbPrj} 
                    layoutId={layoutId} 
                    systemId={systemId} 
                    ctx={drawerValidation.ctx}
                    oneColArrangement={true}
                    spacing={1}
                />
                <Card style={{ padding: 10 }} variant="outlined">
                    <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} paddingBottom={2}>
                        <Typography sx={{ ...pageStyles.cardTitleStyle, margin: 0, flex: 1 }}>{i18next.format(t("field-design-drawer.center-pivot.pivot-center.title"), 'capitalize-each')}</Typography>
                        <Button disabled={!hasCenterMoved} sx={{ width: 90 }} variant="contained" color='error' endIcon={<RestoreIcon />} size="small" onClick={handleResetCoords}>
                            Reset
                        </Button>
                        <Button sx={{ width: 90 }} variant="contained" endIcon={<EditIcon />} size="small" onClick={() => setShowEditPivotCenterDialog(true) }>
                            Edit
                        </Button>
                    </Stack>
                    <Grid container>
                        <Grid item xs={6}>{i18next.format(t("latitude"), 'capitalize-each')}</Grid>
                        <Grid item xs={6}>{formattedLatLng.latPart}</Grid>
                        <Grid item xs={6}>{i18next.format(t("longitude"), 'capitalize-each')}</Grid>
                        <Grid item xs={6}>{formattedLatLng.lonPart}</Grid>
                    </Grid>

                    
                    <Stack direction={"row"} py={2}>
                        <TextField
                            label={i18next.format(t("field-design-drawer.center-pivot.pivot-center.center-x-offset"), 'capitalize-each')}
                            type="number"
                            variant="standard"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{t("ft")}</InputAdornment>
                            }}
                            value={offsetX}
                            onChange={(ev) => setOffsetX(ev.target.value)}
                            // onBlur={handleXCoordsOnBlur}
                            onBlur={handleXYCoordsOnBlur}
                            fullWidth
                        />
                        <Tooltip title={i18next.format(t("west"), 'capitalize-each')}>
                            <IconButton size="medium" onClick={() => handleNudgeCoord(nudgeFactor, 270)} sx={directionButtonStyles}>
                                <WestIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={i18next.format(t("east"), 'capitalize-each')}>
                            <IconButton size="medium" onClick={() => handleNudgeCoord(nudgeFactor, 90)} sx={directionButtonStyles}>
                                <EastIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    
                    <Stack direction={"row"}>
                        <TextField
                            label={i18next.format(t("field-design-drawer.center-pivot.pivot-center.center-y-offset"), 'capitalize-each')}
                            type="number"
                            variant="standard"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{t("ft")}</InputAdornment>
                            }}
                            value={offsetY}
                            onChange={(ev) => setOffsetY(ev.target.value)}
                            // onBlur={handleYCoordsOnBlur}
                            onBlur={handleXYCoordsOnBlur}
                            fullWidth
                        />
                        <Tooltip title={i18next.format(t("north"), 'capitalize-each')}>
                            <IconButton size="medium" onClick={() => handleNudgeCoord(nudgeFactor, 0)} sx={directionButtonStyles}>
                                <NorthIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={i18next.format(t("south"), 'capitalize-each')}>
                            <IconButton size="medium" onClick={() => handleNudgeCoord(nudgeFactor, 180)} sx={directionButtonStyles}>
                                <SouthIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    
                </Card>

                {
                    showEditPivotCenterDialog && (
                        <EditPivotCenterDialog
                            crntCenter={system.centerPivot.point}
                            onCancel={() => setShowEditPivotCenterDialog(false)}
                            onConfirm={(updatedPoint) => {
                                dbPrj.pushAction(
                                    createNewUpdateSystemPropertyAction(
                                        layoutId,
                                        systemId,
                                        'centerPivot.point',
                                        updatedPoint,
                                        authState
                                    )
                                )
                                setShowEditPivotCenterDialog(false);
                            }}
                        />
                    )
                }
            </Stack>
        )
    }
}

export default centerPivotTab;