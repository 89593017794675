import IMCP from "./IMCP";
import ISprinklerChart from "./ISprinklerChart";
import ISprinklers from "./ISprinklers";

export enum SystemTypes {
    CenterPivot = "CenterPivot",
    HoseFeedMaxigator = "HoseFeedMaxigator",
    CanalFeedMaxigator = "CanalFeedMaxigator",
    KwikTow = "KwikTow",
    SwingArmRetro = "SwingArmRetro",
    Ancillary = "Ancillary"
}
export enum Countries {
    UnitedStates = "UnitedStates",
    Australia = "Australia",
    NewZealand = "NewZealand",
    Other = "Other"
}
export enum ValveSizes {
    a4Inch = "a4Inch",
    a6Inch = "a6Inch",
    a8Inch = "a8Inch"
}
export enum ValveMaterials {
    Aluminum = "Aluminum",
    CastIron = "CastIron"
}
export enum PipeSizes {
    a3Inch = "a3Inch",
    a4Inch = "a4Inch",
    a6Inch = "a6Inch",
    a8Inch = "a8Inch"
}
export enum Pressures {
    a30To200Psi = "a30To200Psi",
    a18To80Psi = "a18To80Psi"
}
export enum ControlFunctions {
    aManualOnOff = "aManualOnOff",
    a5To50PSIPressureReducingValve = "a5To50PSIPressureReducingValve",
    a25To120PSIPressureReducingValve = "a25To120PSIPressureReducingValve",
    a25To200PSIPressureReducingValve = "a25To200PSIPressureReducingValve"
}
export enum ControlFunctionOptions {
    None = "None",
    SleeveExhaust = "SleeveExhaust",
    RateOfFlowValve = "RateOfFlowValve"
}
export enum RatesOfFlow {
    High = "High",
    Low = "Low"
}
export enum OnOffModes {
    aNoSolenoid = "aNoSolenoid",
    a12To24VDCLatchSolenoid_5AmpMax = "a12To24VDCLatchSolenoid_5AmpMax",
    a12VDCSolenoid_6AmpMax = "a12VDCSolenoid_6AmpMax",
    a24VDCSolenoid_3AmpMax = "a24VDCSolenoid_3AmpMax",
    a24VACSolenoid_3AmpInrushCurrent = "a24VACSolenoid_3AmpInrushCurrent",
    a24VACSolenoid1_1AmpInrushHeavyDuty = "a24VACSolenoid1_1AmpInrushHeavyDuty",
    a24VACSolenoid3_6AmpInrushCurrent = "a24VACSolenoid3_6AmpInrushCurrent",
    a120VACSolenoid1_16Inch = "a120VACSolenoid1_16Inch",
    a120VACSolenoid1_8Inch = "a120VACSolenoid1_8Inch",
    aHydraulicRemoteControl = "aHydraulicRemoteControl"
}
export enum Filters {
    ExternalFilter = "ExternalFilter",
    InternalFilter = "InternalFilter"
}
export enum RadioTelemetryTypes {
    SatellitePlus = "SatellitePlus",
    Radio = "Radio",
    Cell = "Cell"
}
export enum OntracRadio_Mhz {
    a900 = "a900",
    a450 = "a450"
}
export enum SatellitePlusBaseModelTypes {
    CellPlusVerizon = "CellPlusVerizon",
    SatellitePlus = "SatellitePlus"
}
export enum SatellitePlusControlPanelTypes {
    Advanced = "Advanced",
    Preferred = "Preferred",
    RAMS = "RAMS"
}
export enum RadioTelemetrySubscriptionPeriods {
    a4Months = "a4Months",
    a6Months = "a6Months",
    a9Months = "a9Months",
    a12Months = "a12Months"
}
export enum CellControlPanelTypes {
    ReinkeNonRAMS = "ReinkeNonRAMS",
    ReinkeRAMS = "ReinkeRAMS",
    OtherBasic = "OtherBasic",
    OtherComputer = "OtherComputer"
}
export enum CellMountingBrackets {
    PivotCenter = "PivotCenter",
    Span = "Span"
}
export enum CellBaseModels {
    Verizon = "Verizon",
    ATT = "ATT",
    Satellite = "Satellite"
}
export enum GPSBaseOptions {
    ExistingReinke = "ExistingReinke",
    WithBase = "WithBase",
    ExistingReinkeFreewave = "ExistingReinkeFreewave",
    ExistingNonReinke = "ExistingNonReinke"
}
export enum PressureGaugeTypes {
    a100PSI = "a100PSI",
    a200PSI = "a200PSI",
    a30PSI = "a30PSI",
    a60PSI = "a60PSI"
}
export enum SprinklerManufacturerTypes {
    None = "None",
    Nelson = "Nelson",
    Senninger = "Senninger",
    Komet = "Komet"
}
export enum DualSprinklerPackageControlTypes {
    Water = "Water",
    Air = "Air"
}
export enum EndGunTypes {
    DualP85 = "DualP85",
    R75LP = "R75LP",
    None = "None",
    R55i = "R55i",
    R55 = "R55",
    R75 = "R75",
    SingleP85 = "SingleP85",
    SR75 = "SR75",
    SR100 = "SR100",
    SR100NV = "SR100NV",
    SimeWing = "SimeWing",
    TwinMax = "TwinMax",
    Twin101Ultra = "Twin101Ultra"
}
export enum AgriInjectTypes {
    None = "None",
    Fertigation = "Fertigation",
    Insectigator = "Insectigator",
    Chemigation = "Chemigation"
}
export enum UnitTypes {
    NotApplicable = "NotApplicable",
    Simplex = "Simplex",
    Duplex = "Duplex"
}
export enum TankTypes {
    aNotApplicable = "aNotApplicable",
    a20 = "a20",
    a30 = "a30",
    a70 = "a70",
    a110 = "a110",
    a200 = "a200"
}
export enum GallonsPerHourTypes {
    aNotApplicable = "aNotApplicable",
    a1_9 = "a1_9",
    a5_6 = "a5_6",
    a18 = "a18",
    a30 = "a30",
    a55 = "a55",
    a110 = "a110",
    a150 = "a150",
    a4x30 = "a4x30",
    a30x30 = "a30x30",
    a85x85 = "a85x85"
}
export enum CenterPivotTypes {
    E2065 = "E2065",
    E2100 = "E2100",
    E2085 = "E2085",
    A80G = "A80G",
    S80G = "S80G",
    S2085G = "S2085G",
    S2065G = "S2065G",
    E2045 = "E2045"
}
export enum TowerHeights {
    Standard = "Standard",
    LowProfile = "LowProfile",
    Sugargator = "Sugargator",
    Supergator = "Supergator"
}
export enum GasketTypes {
    Standard = "Standard",
    TripleLip = "TripleLip"
}
export enum PipeBottomFittingTypes {
    ElbowPlain = "ElbowPlain",
    ElbowSAE = "ElbowSAE",
    Elbow8Inch = "Elbow8Inch",
    ElbowReducer = "ElbowReducer",
    ExtPipe = "ExtPipe",
    Filter475GPM = "Filter475GPM",
    Filter700GPM = "Filter700GPM",
    Filter1200GPM = "Filter1200GPM",
    Filter2000GPM = "Filter2000GPM",
    None = "None"
}
export enum TowTypes {
    None = "None",
    SkidTow = "SkidTow",
    FourWheelPivotMover = "FourWheelPivotMover"
}
export enum TireSizes {
    a11x225 = "a11x225",
    a112x24 = "a112x24",
    a149x24 = "a149x24",
    a169x24 = "a169x24",
    a32085R38 = "a32085R38",
    a136x38 = "a136x38",
    a38080R38 = "a38080R38",
    a112x38 = "a112x38",
    a32085R24 = "a32085R24",
    a38085R24 = "a38085R24",
    a184x26 = "a184x26"
}
export enum TireTypes {
    Recaps = "Recaps",
    FourPly = "FourPly",
    SixPly = "SixPly",
    NonDirectional = "NonDirectional",
    Radial = "Radial",
    Turf = "Turf",
    MachII = "MachII",
    Rhinogator = "Rhinogator",
    Shark = "Shark"
}
export enum RiserPipeTypes {
    LongPipe = "LongPipe",
    ShortPipe = "ShortPipe",
    ExtraShortPipe = "ExtraShortPipe"
}
export enum AnchorPackageTypes {
    None = "None",
    Bolt = "Bolt",
    Skid = "Skid",
    CompetitivePadAdapter = "CompetitivePadAdapter",
    CompetitivePadAdapterWithSkids = "CompetitivePadAdapterWithSkids",
    CompetitivePadAdapterWithChannel = "CompetitivePadAdapterWithChannel",
    EIIPadAdapter = "EIIPadAdapter",
    Chain17 = "Chain17",
    Chain25 = "Chain25",
    Turnbuckle = "Turnbuckle"
}
export enum PadBoltTypes {
    None = "None",
    a34x10 = "a34x10",
    a1x9 = "a1x9"
}
export enum NetworkProviderTypes {
    None = "None",
    Provider_1 = "Provider_1",
    Provider_2 = "Provider_2",
    Provider_3 = "Provider_3",
    Provider_4 = "Provider_4",
    Provider_5 = "Provider_5",
    Provider_6 = "Provider_6",
    Provider_7 = "Provider_7",
    Hologram = "Hologram",
    Starcom = "Starcom",
    Verizon = "Verizon"
}
export enum EndOfSystemTypes {
    EndBoom = "EndBoom",
    SAC = "SAC"
}
export enum SwingArmLengths {
    None = "None",
    SAC156 = "SAC156",
    SAC175 = "SAC175",
    SAC194 = "SAC194",
    SAC213 = "SAC213"
}
export enum ValveTypes {
    None = "None",
    Manual = "Manual",
    Diaphragm = "Diaphragm",
    Reinke = "Reinke",
    Auto800 = "Auto800",
    Auto1000 = "Auto1000"
}
export enum BoosterPumpTypes {
    None = "None",
    TwoHP = "TwoHP",
    FiveHP = "FiveHP"
}
export enum LightTypes {
    None = "None",
    Standard = "Standard",
    StrobeClear = "StrobeClear",
    StrobeAmber = "StrobeAmber",
    StrobeBlue = "StrobeBlue",
    StrobeGreen = "StrobeGreen",
    StrobeRed = "StrobeRed",
    Strobe400Clear = "Strobe400Clear",
    Strobe400Amber = "Strobe400Amber",
    Strobe400Blue = "Strobe400Blue",
    Strobe400Green = "Strobe400Green",
    Strobe400Red = "Strobe400Red"
}
export enum WaterFeedTypes {
    EndFeed = "EndFeed",
    CenterFeed = "CenterFeed"
}
export enum GearDriveTypes {
    Standard = "Standard",
    High = "High",
    Low = "Low",
    TwoThirtyVolt = "TwoThirtyVolt"
}
export enum HoseFeedTypes {
    Standard = "Standard",
    DoubleEndFeed = "DoubleEndFeed",
    FourWheelDrive = "FourWheelDrive",
    Sugargator = "Sugargator",
    A100 = "A100", // Obsolete, replaced by CF200
    PivotingLateral = "PivotingLateral",
    CF200 = "CF200"
}
export enum CF200Pumps {
    /**
     * Default selection when NOT CF200 system
     */
    None = "None",

    /**
     * 15Hp Electric Motor with 4RB Pump
     */
    Hp15 = "Hp15",

    /**
     * 20Hp Electric Motor with 4RB Pump
     */
    Hp20 = "Hp20",

    /**
     * 25Hp Electric Motor with 4RB Pump
     */
    Hp25 = "Hp25"
}
export enum PivotingLateralPivotCenterTypes {
    None = "None",
    E2065 = "E2065",
    A80G = "A80G",
    E2085 = "E2085"
}
export enum HoseAndCouplerKitTypes {
    None = "None",
    HoseWithCouplers = "HoseWithCouplers",
    CouplersOnly = "CouplersOnly",
    HardHoseAdapter = "HardHoseAdapter"
}
export enum BaseInletSizes {
    a4 = "a4",
    a45 = "a45",
    a5 = "a5",
    a6 = "a6",
    a8 = "a8"
}
export enum DragHoseStandardLengths {
    a165 = "a165",
    a220 = "a220",
    a260 = "a260",
    a330 = "a330",
    a520 = "a520",
    NonStandard = "NonStandard"
}
export enum InletTypes {
    None = "None",
    AutoReverseOverHose = "AutoReverseOverHose",
    StandardHose = "StandardHose",
    DualHose = "DualHose",
    ReverseOnHose = "ReverseOnHose"
}
export enum FuelTankSizes {
    a300 = "a300",
    a550 = "a550"
}
export enum CartTypes {
    Standard = "Standard",
    Sugargator = "Sugargator"
}
export enum CanalFeedEngines {
    Dom67 = "Dom67",
    Dom64 = "Dom64",
    Dom109 = "Dom109",
    Dom129 = "Dom129",
    Int52 = "Int52",
    Int69 = "Int69",
    Int84 = "Int84",
    Int108 = "Int108",
    Int150 = "Int150",
    Int255 = "Int255",
    DomReserved1 = "DomReserved1",
    DomReserved2 = "DomReserved2"
}
export enum Pumps {
    a5RBS = "a5RBS",
    a6RBS = "a6RBS",
    a8H = "a8H",
    a10RB = "a10RB",
    a5YBH = "a5YBH",
    a6YB = "a6YB"
}
export enum CanalFeedGenerators {
    a10KW = "a10KW",
    a1212KW = "a1212KW",
    a15KW = "a15KW",
    a20KW = "a20KW",
    a25KW = "a25KW",
    a30KW = "a30KW",
    MeccAlte_05_0_KW = "MeccAlte_05_0_KW",
    MeccAlte_07_5_KW = "MeccAlte_07_5_KW",
    MeccAlte_10_0_KW = "MeccAlte_10_0_KW",
    MeccAlte_15_0_KW = "MeccAlte_15_0_KW",
    MeccAlte_18_0_KW = "MeccAlte_18_0_KW",
    MeccAlte_25_0_KW = "MeccAlte_25_0_KW",
    MeccAlte_27_0_KW = "MeccAlte_27_0_KW",
    MeccAlte_30_0_KW = "MeccAlte_30_0_KW"

}
export enum IntakeTypes {
    Float = "Float",
    SelfCleaningScreen = "SelfCleaningScreen",
    LiftKit = "LiftKit"
}
export enum GuidanceTypes {
    Furrow = "Furrow",
    Cable = "Cable",
    BuriedWire = "BuriedWire",
    FenceLine = "FenceLine",
    NavigatorGPS = "NavigatorGPS"
}
export enum GuidanceLocations {
    PowerTower = "PowerTower",
    OuterTower = "OuterTower"
}
export enum WinchTypes {
    Manual = "Manual",
    Electric = "Electric"
}
export enum WireTypes {
    Shielded = "Shielded",
    NonShielded = "NonShielded"
}
export enum PanelModels {
    RPMBasic = "RPMBasic",
    RPMStandard = "RPMStandard",
    RPMAdvanced = "RPMAdvanced",
    RPMAdvancedPlus = "RPMAdvancedPlus",
    RPMConnect = "RPMConnect",
    RPMPreferred = "RPMPreferred"
}
export enum Languages {
    English = "English",
    Spanish = "Spanish",
    Russian = "Russian"
}
export enum EndTowerPositioningTypes {
    None = "None",
    Resolver = "Resolver",
    GPS = "GPS"
}
export enum PressureTransducerOptions {
    None = "None",
    AtPivot = "AtPivot",
    AtEnd = "AtEnd"
}
export enum CollectorRingTypes {
    None = "None",
    a10Ring = "a10Ring",
    a11RingICII = "a11RingICII",
    a11RingUnited = "a11RingUnited",
    a14Ring = "a14Ring"
}
export enum SwitchMountTypes {
    None = "None",
    CamWheel = "CamWheel",
    CamPlate = "CamPlate"
}
export enum FlowmeterTypes {
    None = "None",
    Vertical = "Vertical",
    Horizontal = "Horizontal"
}
export enum ElectricalFrequencies {
    a60 = "a60",
    a50 = "a50"
}
export enum PanelLocations {
    FlangedSide = "FlangedSide",
    FlexSide = "FlexSide"
}
export enum FlowmeterModels {
    None = "None",
    McPropeller = "McPropeller",
    McMag = "McMag",
    Seametrics = "Seametrics"
}
export enum JackKitTypes {
    None = "None",
    Manual = "Manual"
}
export enum GeneratorOptions {
    None = "None",
    GeneratorOnly = "GeneratorOnly",
    GeneratorSet = "GeneratorSet"
}
export enum GeneratorModels {
    None = "None",
    Lima_10_0_kW = "Lima_10_0_kW",
    Lima_12_5_kW = "Lima_12_5_kW",
    Lima_15_0_kW = "Lima_15_0_kW",
    Lima_20_0_kW = "Lima_20_0_kW",
    Lima_25_0_kW = "Lima_25_0_kW",
    Lima_30_0_kW = "Lima_30_0_kW",
    MeccAlte_05_0_kW = "MeccAlte_05_0_kW",
    MeccAlte_07_5_kW = "MeccAlte_07_5_kW",
    MeccAlte_10_0_kW = "MeccAlte_10_0_kW",
    MeccAlte_15_0_kW = "MeccAlte_15_0_kW",
    MeccAlte_18_0_kW = "MeccAlte_18_0_kW",
    MeccAlte_25_0_kW = "MeccAlte_25_0_kW",
    MeccAlte_27_0_kW = "MeccAlte_27_0_kW",
    MeccAlte_30_0_kW = "MeccAlte_30_0_kW"
}
export enum GeneratorFrameTypes {
    None = "None",
    OpenFrame = "OpenFrame",
    EnclosedFrame = "EnclosedFrame"
}
export enum OptionsReplaceSystemEnum {
    New_Development = "New_Development",
    Flood = "Flood",
    SideRoll_Traveler = "SideRoll_Traveler",
    Zimmatic = "Zimmatic",
    Valley = "Valley",
    Reinke = "Reinke"
}
export enum SystemCategories {
    CenterPivot = "CenterPivot",
    LateralMoveMaxi = "LateralMoveMaxi"
}
export enum RC10PanelModels {
    RPMBasic = "RPMBasic",
    RPMStandard = "RPMStandard",
    RPMAdvanced = "RPMAdvanced",
    RPMAdvancedPlus = "RPMAdvancedPlus",
    RPMConnect = "RPMConnect",
    RPMPreferred = "RPMPreferred",
    RPMStandardWithAutoReverse = "RPMStandardWithAutoReverse",
    RPMPrefTouchScreen = "RPMPrefTouchScreen"
}
export enum RC10FacePlateUpgrade {
    None = "None",
    RPMStandardWithAutoReverse = "RPMStandardWithAutoReverse",
    AdvancedFaceplate = "AdvancedFaceplate",
    AdvancedPlusFaceplate = "AdvancedPlusFaceplate",
    ConnectFacePlate = "ConnectFacePlate",
    TouchscreenFaceplate = "TouchscreenFaceplate"
}
export enum RC10MountingLocations {
    PivotCenter = "PivotCenter",
    OuterTower = "OuterTower"
}
export enum RC10Types {
    RC10d = "RC10d",
    RC10i = "RC10i"
}
export enum RC10ModemBaseModels {
    Verizon = "Verizon",
    Att = "Att",
    Satellite = "Satellite",
    Rogers = "Rogers",
    International = "International",
    International2 = "International2",
    International3 = "International3"
}
export enum SACGPSGuidanceBaseOptions {
    CoaxBase = "CoaxBase",
    RadioBase = "RadioBase",
    SingleSystemFreewaveRadioBase = "SingleSystemFreewaveRadioBase",
    NoBaseFreewave = "NoBaseFreewave",
    NoBase = "NoBase",
    NoBaseNonReinke = "NoBaseNonReinke"
}
export enum SACPipeTypes {
    Galvanized = "Galvanized",
    PaintedCNPlus = "PaintedCNPlus",
    GalvanizedCNPlus = "GalvanizedCNPlus"
}
export enum SACZoneControlTypes {
    ESAC060 = "ESAC060",
    ESAC120 = "ESAC120",
    ESAC125 = "ESAC125"
}
export enum SACConductorOptions {
    /**
     * Default wire for SAC/Retro
     */
    SpanWire10C = "SpanWire10C",

    /**
     * Automatic upgrade to 11C when EndOfSystem includes a second EndGun
     */
    SpanWire11C = "SpanWire11C",

    /**
     * Optional wire upgrade to support additional devices at EndOfSystem
     */
    SpanWire14C = "SpanWire14C"
}
export enum SACDistributionFlowRate {
    Low = "Low",
    Medium = "Medium",
    High = "High"
}
export enum SACPathCreatorType {
    Dealer = "Dealer",
    Reinke = "Reinke"
}
export enum SACRetroSpanTypes {
    E65E60E665E660 = "E65E60E665E660",
    E2065E2060E2665E2660 = "E2065E2060E2665E2660",
    E80E100E6580E680 = "E80E100E6580E680",
    AlumIII = "AlumIII",
    AlumIV = "AlumIV",
    Valley = "Valley",
    ValleyWithDrop = "ValleyWithDrop",
    Zimmatic = "Zimmatic"
}
export enum SACRetroTireTypes {
    Other = "Other",
    Recaps = "Recaps",
    FourPly = "FourPly",
    t11x225 = "11x22.5",
    t112x24 = "11.2x24",
}
export enum TowerBoxOverloads {
    HeavyDuty = "HeavyDuty",
    None = "None",
    Standard = "Standard"
}
export enum CouplerTypes {
    HiTorque = "HiTorque",
    CXCoupler = "CXCoupler",
    SteelUJoints = "SteelUJoints"
}
export enum SpanTypes {
    E2100 = "E2100",
    E2085 = "E2085",
    E2065 = "E2065",
    E2060 = "E2060",
    E2045 = "E2045",
    E2665 = "E2665",
    E2660 = "E2660",
    AlumIV = "AlumIV",
    A80G = "A80G",
    A60G = "A60G",
    S2085G = "S2085G",
    S2065G = "S2065G",
    PL2085G = "PL2085G",
    PL2065G = "PL2065G",
    EndBoom = "EndBoom"
}
export enum BuckBoostTypes {
    aNone = "aNone",
    a15KVA = "a15KVA",
    a225KVA = "a225KVA"
}
export enum WrapAroundSpanTypes {
    None = "None",
    TenDegree = "TenDegree",
    NinetyDegree = "NinetyDegree"
}
export enum WheelGearSeries {
    None = "None",
    SevenFourty = "SevenFourty",
    SevenFourtyAD = "SevenFourtyAD",
    SevenFourtyFive = "SevenFourtyFive"
}
export interface ISystemBase {
    partsPackageId: string;

    SystemProperties?: ISystemProperties;
    DealerProperties?: IDealerProperties;
    MainlineValveOptions?: IMainlineValveOptions;
    Ontrac?: IOntrac;
    SprinklerProperties?: ISprinklerProperties;
    Options?: IOptions;
    FlexSide?: IFlexSide;
    FlangedSide?: IFlangedSide;
    AgriInject?: IAgriInject;
    SprinklerLube?: ISprinklerLube;
    HeatExchangers?: IHeatExchangers;
    ClemonsFilters?: IClemonsFilters;
    CheckValves?: ICheckValves;
    FlowmeterComponents?: IFlowmeterComponents;
    Circle?: ICircle;
    CropX?: ICropX;
    Lateral?: ILateral;
    ControlPanel?: IControlPanel;
    RC10?: IRC10;
    SpanProperties?: ISpanProperties;
    TowerProperties?: ITowerProperties;
    FirstSpanType?: SpanTypes;
    QuoteProperties?: IQuoteProperties;
    mcp: IMCP;
}

export interface IMiscItem {
    id: string;
    description: string;
    /**
     * The price of the Misc Item.
     * USD Cents for partsPackageId < 2024110401, local currency minor units for partsPackageId >= 2024110401
     * @type number
     */
    priceCents: number;
    taxable: boolean;
}

export interface IQuoteProperties {
    MiscItems: IMiscItem[];
    ProposalInformation: IProposalInformation;
}

export interface IPricing {
    installation: IPrice;
    freight: IPrice;
    pivotPad: IPrice;
    tradeIn: IPrice;
    system: IPrice;
    paymentComputationMethod: "Percent" | "Price",
    downPayment: {
        percent: number;
        /**
         * The price of the payment.
         * USD Cents for partsPackageId < 2024110401, local currency minor units for partsPackageId >= 2024110401
         * @type number
         */
        usdCents: number;
    },
    dueOnDelivery: {
        percent: number;
        /**
         * The price of the payment.
         * USD Cents for partsPackageId < 2024110401, local currency minor units for partsPackageId >= 2024110401
         * @type number
         */
        usdCents: number;
    },
    listPriceDiscountPercent: number;
    markupPercentOverDealerCost: number;
    /**
     * USD Dollars for partsPackageId < 2024110401, local currency major units for partsPackageId >= 2024110401
     * @type number
     */
    markupDollarOverDealerCost: number;
    /**
     * USD Dollars for partsPackageId < 2024110401, local currency major units for partsPackageId >= 2024110401
     * @type number
     */
    quotedPriceDollar: number;
    salesTaxPercent: number;
    programDiscountPercent: number;
    tmDiscountPercent: number;
    deliveryDiscountPercent: number;
    printListPrice: boolean;
    onlyPrintTotalPrice: boolean;
    currency?: string;

    /**
     * Set when partsPackageId >= 2024110401 and using a local currency. Pre 2024110401, the rate
     * uses the latest available rate.
     * @type IFEXRate
     */
    rate?: IFEXRate;
}

export interface IFEXRate {
    validAtTimestampMs: number;
    exchangeRate_1USDToSymbol: number;
}
export interface IPrice {
    /**
     * The price of the item.
     * USD Cents for partsPackageId < 2024110401, local currency minor units for partsPackageId >= 2024110401
     * @type number
     */
    priceUsdCents?: number;
    isTaxable: boolean;
}

export enum PaymentTypes {
    Cash = "Cash",
    ReinkeHuntington = "ReinkeHuntington",
    AgDirect = "AgDirect",
    FirstNat = "FirstNat",
    DiversifiedFS = "DiversifiedFS",
    ReinkeCoastCapital = "ReinkeCoastCapital",
    NotSelected = "NotSelected",
    Other = "Other",
}

export enum RebateTypes {
    InvoiceDeduction = "InvoiceDeduction",
    FactoryCheck = "FactoryCheck",
}

export enum PriceComputationMethods {
    ListPricePercentageDiscount = "ListPricePercentageDiscount",
    DealerPricePercentageMarkup = "DealerPricePercentageMarkup",
    DealerPriceAbsoluteMarkup = "DealerPriceAbsoluteMarkup",
    DealerDetermined = "DealerDetermined",
}

export interface IProposalInformation {
    customerComments: string;
    tradeinNotes: string;
    factoryNotes: string;
    PONumber: string;
    pricing: IPricing;
    paymentTerms: PaymentTypes;
    reinkeFinanceInterestBuyDown: boolean;
    hasMidAmericaRiskInsurance: boolean;
    shipVia: string;
    requestedShippingDate: string;//yyyy-mm-dd 
    isNationalAccountOrder: boolean;
}

export interface ISystemProperties {
    SystemType?: SystemTypes;
    ReverseTow?: boolean;
}
export interface IDealerProperties {
    Country?: Countries;
    AccountNumber?: string;
}
export interface IMainlineValveOptions {
    Valves?: IMainlineValveOptionsValves;
    BrassAirValve?: number;
    PlasticAirValve?: number;
}
export interface IMainlineValveOptionsValves {
    MainlineValve?: IMainlineValveOptionsValvesMainlineValve[];
}
export interface IMainlineValveOptionsValvesMainlineValve {
    MainlineValveNumber?: number;
    ValveMaterial?: ValveMaterials;
    Adapter?: boolean;
    ControlFunction?: ControlFunctions;
    RateOfFlow?: RatesOfFlow;
    ControlFunctionOption?: ControlFunctionOptions;
    Filter?: Filters;
    TubingProtection?: boolean;
    OnOffMode?: OnOffModes;
    Pressure?: Pressures;
    Quantity?: number;
    Size?: ValveSizes;
    PipeSize?: PipeSizes;
}
export interface IOntrac {
    IsEnabled?: boolean;
    RadioTelemetryType?: RadioTelemetryTypes;
    Radio?: IOntracRadio;
    SatellitePlus?: IOntracSatellitePlus;
    Cell?: IOntracCell;
}
export interface IOntracRadio {
    RadioType?: OntracRadio_Mhz;
    BaseStation?: number;
    TouchScreen?: number;
    Advanced?: number;
    RAMS?: number;
    Repeater?: number;
    Omni2?: number;
    Omni40?: number;
    Omni100?: number;
    OmniWhip?: number;
    OmniRubberWhip?: number;
    Yagi?: number;
    Mount?: number;
}
export interface IOntracSatellitePlus {
    SatellitePlusUnit?: IOntracSatellitePlusSatellitePlusUnit[];
}
export interface IOntracSatellitePlusSatellitePlusUnit {
    SatellitePlusUnitNumber?: number;
    BaseModel?: SatellitePlusBaseModelTypes;
    ControlPanelType?: SatellitePlusControlPanelTypes;
    SubscriptionPeriod?: RadioTelemetrySubscriptionPeriods;
}
export interface IOntracCell {
    CellUnit?: IOntracCellCellUnit[];
}
export interface IOntracCellCellUnit {
    CellUnitNumber?: number;
    ExistingNavigatorGPS?: boolean;
    WaterStatus?: boolean;
    SystemPressure?: boolean;
    SystemPosition?: boolean;
    NavigatorRetro?: boolean;
    WireTheft?: boolean;
    RainGauge?: boolean;
    SystemStartStop?: boolean;
    SystemStop?: boolean;
    MountingBracket?: CellMountingBrackets;
    BaseModel?: CellBaseModels;
    ControlPanelType?: CellControlPanelTypes;
    SubscriptionPeriod?: RadioTelemetrySubscriptionPeriods;
}
export interface ISprinklerProperties {
    SprinklerEngineCode?: SprinklerManufacturerTypes;
    PressureGauge?: PressureGaugeTypes;
    PumpPressureAtSysGPMForESP?: number;
    MainlineLossAtMaxGPMForESP?: number;
    PumpElevation?: number;
    DesignedEndPressure?: number;
    PivotPressure?: number;
    TotalGPM?: number;
    MaximumSystemGPM?: number;
    MaximumTopInletPSI?: number;
    MinimumSystemGPM?: number;
    SystemPressureLoss?: number;
    SystemPressureLossAtMaxSystemGPM?: number;
    PeakElevation?: number;
    EndPressureGauge?: boolean;
    DualSprinklerPackage?: boolean;
    DualSprinklerPackageControlType?: DualSprinklerPackageControlTypes;
    EndGunOperationPivotMode?: boolean;
    EndGunOperationLateralMode?: boolean;
    ControlLineBoosterPump?: boolean;
    VRIZones?: ISprinklerPropertiesVRIZones;
    SprinklerValveType?: SprinklerValveTypes;
}

/**
 * 3/4" valves for Sprinklers ONLY
 */
export enum SprinklerValveTypes {
    /**
     * Plastic valve offered because it's cheaper.
     * Cannot be used with hose valves as it does not blead off the water and will collapse the hose.
     */
    Poly = "Poly",
    /**
     * Steal valve, can be used with hose drops
     */
    ReinkeSealMatic = "ReinkeSealMatic",
    /**
     * Option is in ROE for the wrap span. The option is a Boolean checkbox (required) 
     * when wrap span is selected. Yields "Aqua Valves" on the system.
     */
    Aquamatic = "Aquamatic"
}

export interface ISprinklerPropertiesVRIZones {
    Zone?: ISprinklerPropertiesVRIZonesZone[];
    ValveType?: VRIValveType;
    ValveBox?: ISprinklerPropertiesVRIZonesValveBox[];
}

export enum VRIValveType {
    Poly,
    ReinkeSealMatic
}

export interface ISprinklerPropertiesVRIZonesValveBox {
    StartZone?: number;
    EndZone?: number;
    TowerIndex?: number;
    Tower?: number;
    TowerLocation?: number;
    TubingLength?: number;
}

export interface ISprinklerPropertiesVRIZonesZone {
    ZoneNumber?: number;
    Controlled?: boolean;
    EndingLocation?: number;
    StartingLocation?: number;
    Length?: number;
    Acres?: number;
    ZoneRelayAddress?: string;
    DeviceCount?: number;
}
export interface IOptions {
    DoubleEndFeedSafetyValve?: boolean;
    FlushValve?: boolean;
    SafetyValve?: boolean;
    IntakeDrainValve?: boolean;
    SpareBoltPackage?: boolean;
    ReinkeSignMount?: boolean;
    ChemTankMountKit?: boolean;
    AuxAccessStep?: boolean;
    PivotLightRAMSConstantOn?: boolean;
    JackKit?: JackKitTypes;
    JackFootKit?: boolean;
    MovableBarricades?: number;
    StandardLowProfileStationaryBarricades?: number;
    SugargatorStationaryBarricades?: number;
    ProxSwitchBarricades?: number;
    GeneratorOption?: GeneratorOptions;
    GeneratorSlideRails?: boolean;
    EngDigitalPanel?: boolean;
    FuelTank?: boolean;
    GeneratorModel?: GeneratorModels;
    GeneratorFrameType?: GeneratorFrameTypes;
    PivotLight?: LightTypes;
    ReplaceSystem?: OptionsReplaceSystemEnum;
    ReplaceComments?: string;
    ElectricTowKit?: number;
}
export interface IFlexSide {
    EndOfSystem?: IFlexSideEndOfSystem;
    Span?: IFlexSideSpan[];
    Sprinklers?: ISprinklers;
    SprinklerChart?: ISprinklerChart;
    Tower?: IFlexSideTower[];
}
export interface IFlangedSide {
    EndOfSystem?: IFlangedSideEndOfSystem;
    Span?: IFlangedSideSpan[];
    Sprinklers?: ISprinklers;
    SprinklerChart?: ISprinklerChart;
    Tower?: IFlangedSideTower[];
}
export interface IAgriInject {
    AgriInjectType?: AgriInjectTypes;
    Unit?: UnitTypes;
    Tank?: TankTypes;
    GallonsPerHour?: GallonsPerHourTypes;
}
export interface ISprinklerLube {
    SL1200_35LBPailQty?: number;
    SL1200_120LBPKegQty?: number;
    SL1200_400LBDrumQty?: number;
    SL220_35LBPailQty?: number;
    SL220_220LBKegQty?: number;
    SL220_400LBDrumQty?: number;
    PumpManual35LBPailQty?: number;
    PumpManual120LBKegQty?: number;
    Pneumatic120LBKegQty?: number;
    Pneumatic120LBKegWithReelQty?: number;
    Pneumatic400LBDrumQty?: number;
    Pneumatic400LBDrumWithReelQty?: number;
}
export interface IHeatExchangers {
    HE40HPQty?: number;
    HE75HPQty?: number;
    HE95HPQty?: number;
    HE135HPQty?: number;
    HE200HPQty?: number;
    HE270HPQty?: number;
    HE400HPQty?: number;
}
export interface IClemonsFilters {
    CF6InchQty?: number;
    CF8InchQty?: number;
    CF10InchQty?: number;
    CF12InchQty?: number;
}
export interface ICheckValves {
    CV4InchQty?: number;
    CV6InchQty?: number;
    CV8InchQty?: number;
    CV10InchQty?: number;
    CV12InchQty?: number;
    Flange616Qty?: number;
    Flange6Qty?: number;
    Flange816Qty?: number;
    Flange8to6Qty?: number;
    Flange8PlainQty?: number;
    Gasket6InchQty?: number;
    Gasket8InchQty?: number;
    Gasket10InchQty?: number;
}
export interface IFlowmeterComponents {
    PropHoriz6?: number;
    PropHorizPulse6?: number;
    PropVert6?: number;
    PropVertPulse6?: number;
    PropHoriz8?: number;
    PropHorizPulse8?: number;
    PropVert8?: number;
    PropVertPulse8?: number;
    PropHoriz10?: number;
    PropHorizPulse10?: number;
    PropVert10?: number;
    PropVertPulse10?: number;
    Mag6?: number;
    Mag8?: number;
    Mag10?: number;
    PulseCable?: number;
    RPShort?: number;
    RPLong?: number;
    FTProp?: number;
    FTMag?: number;
    FTwithVanes?: number;
    FTwithoutVanes?: number;
    FS6?: number;
    FS8?: number;
}
export interface ICircle {
    CenterPivot?: ICircleCenterPivot;
    SinglePhase230VoltSystem?: boolean;
    EngineDrivenMiniPivot?: boolean;
    KwikTow?: ICircleKwikTow;
    SwingArm?: ICircleSwingArm;
    SACRetro?: ICircleSACRetro;
}
export interface ICircleCenterPivot {
    CenterPivotType?: CenterPivotTypes;
    PivotCenterHeight?: TowerHeights;
    Gasket?: GasketTypes;
    E2045FoundationFormKit?: boolean;
    Walkway?: boolean;
    PipeBottomFitting?: PipeBottomFittingTypes;
    MaleRingLock?: boolean;
    PipeBottomAdapter8to6?: boolean;
    PipeBottomAdapter?: boolean;
    PerpendicularOffset?: boolean;
    TowOptions?: ICircleCenterPivotTowOptions;
    RiserPipeType?: RiserPipeTypes;
    RiserPipeFor6InchValve?: boolean;
    RiserPipeFor6x19FlangeDevice?: boolean;
    RiserPipeFor8x23FlangeDevice?: boolean;
    AnchorPackage?: AnchorPackageTypes;
    PadBoltType?: PadBoltTypes;
    clockwiseCompassHeadingStart?: number;
    clockwiseCompassHeadingEnd?: number;
    isPartialPivot?: boolean;
}
export interface ICircleCenterPivotTowOptions {
    TowType?: TowTypes;
    Tires?: ICircleCenterPivotTowOptionsTires;
    ExtraHitch?: boolean;
}
export interface ICircleCenterPivotTowOptionsTires {
    OneTireMountedInReverse?: boolean;
    TubesForTires?: boolean;
    TireSize?: TireSizes;
    TireType?: TireTypes;
}
export interface ICircleKwikTow {
    Tires?: ICircleKwikTowTires;
    MotorizedCenter?: boolean;
    HydraulicLift?: boolean;
    SafetyValve?: boolean;
    ExtraPivotAnchors?: number;
    PercentOfCircle?: number;
}
export interface ICircleKwikTowTires {
    TireSize?: TireSizes;
    TireType?: TireTypes;
    TubesForTires?: boolean;
    OneTireMountedInReverse?: boolean;
}
export interface ICropX {
    IsEnabled?: boolean;
    SoilProbeV25Qty?: number;
    SoilProbeV35Qty?: number;
    SoilProbeV40Qty?: number;
    ProbelessIrrigationScheduling?: boolean;
    AntennaExtension3mCableQty?: number;
    InstallationKitV25Qty?: number;
    AntennaCableV40Qty?: number;
    PulseAntennaV40Qty?: number;
    InstallationKitV40Qty?: number;
    BatteryPackReplacementV40Qty?: number;
    NetworkProviderType?: NetworkProviderTypes;
    InstallationKitV35Qty?: number;
}
export interface IFlangedSideEndOfSystem {
    EndOfSystemType?: EndOfSystemTypes;
    SwingArmLength?: SwingArmLengths;
    EndGun?: IFlangedSideEndOfSystemEndGun;
    EndLight?: LightTypes;
    EndLightConstantOn?: boolean;
    VerticalBoosterMount?: boolean;
    StainlessSteelMounts?: boolean;
    Strainer?: boolean;
    ExtraReinkeEndSign?: boolean;
    AudibleAlarms?: number;
    
    /**
     * Indicates SAC only used for Sprinkler Calculations
     */
    HingeTowerOnly?: boolean;

    /**
     * Added in RDC (this was not an ROE field) to distinguish between a system with a 3' end cap,
     * and the case where the user has chosen end boom but not yet chosen any length.
     */
    EndBoomLengthSelected?: boolean;
}

export enum EndGunAcresMethods {
    Traditional = "Traditional",
    Precision = "Precision"
}

export interface IFlangedSideEndOfSystemEndGun {
    EndGunTypePrimary?: EndGunTypes;
    DiffuserLegacy?: boolean;
    Valve?: ValveTypes;
    BoosterPump?: BoosterPumpTypes;
    EndGunTypeSecondary?: EndGunTypes;
    SecondaryValve?: ValveTypes;
    EndGunAcresMethod?: EndGunAcresMethods;
    PercentOfArc?: number;
    SidesWatered?: number;
    CornersWatered?: number;
}
export interface IFlexSideEndOfSystem {
    EndOfSystemType?: EndOfSystemTypes;
    SwingArmLength?: SwingArmLengths;
    EndGun?: IFlexSideEndOfSystemEndGun;
    EndLight?: LightTypes;
    EndLightConstantOn?: boolean;
    VerticalBoosterMount?: boolean;
    StainlessSteelMounts?: boolean;
    Strainer?: boolean;
    ExtraReinkeEndSign?: boolean;
    AudibleAlarms?: number;

    /**
     * Indicates SAC only used for Sprinkler Calculations
     */
    HingeTowerOnly?: boolean;

    /**
     * Added in RDC (this was not an ROE field) to distinguish between a system with a 3' end cap,
     * and the case where the user has chosen end boom but not yet chosen any length.
     */
    EndBoomLengthSelected?: boolean;
}

export interface IFlexSideEndOfSystemEndGun {
    EndGunTypePrimary?: EndGunTypes;
    DiffuserLegacy?: boolean;
    Valve?: ValveTypes;
    BoosterPump?: BoosterPumpTypes;
    EndGunTypeSecondary?: EndGunTypes;
    SecondaryValve?: ValveTypes;
    EndGunAcresMethod?: EndGunAcresMethods;
    PercentOfArc?: number;
    SidesWatered?: number;
    CornersWatered?: number;
}
export interface ILateral {
    Tires?: ILateralTires;
    WaterFeed?: WaterFeedTypes;
    GearDrive?: GearDriveTypes;
    ButterflyValves?: boolean;
    DropFlexSide?: boolean;
    HoseFeed?: ILateralHoseFeed;
    CanalFeed?: ILateralCanalFeed;
    Guidance?: ILateralGuidance;
    LengthOfField?: number;
    dropSpanStartRelativeToPreviousSpanStart?: number;
    dropSpanEndRelativeToPreviousSpanEnd?: number;
}
export interface ILateralTires {
    TireSize?: TireSizes;
    TireType?: TireTypes;
    TubesForTires?: boolean;
    OneTireMountedInReverse?: boolean;
}
export interface ILateralHoseFeed {
    HoseFeedType?: HoseFeedTypes;
    PivotingLateralPivotCenterType?: PivotingLateralPivotCenterTypes;
    HoseAndCouplerKit?: HoseAndCouplerKitTypes;
    BaseInletSize?: BaseInletSizes;
    DragHoseStandardLength?: DragHoseStandardLengths;
    DragHoseNonStandardLength?: number;
    ElectricDragCordLength?: number;
    HardHoseAdapters?: number;
    InletType?: InletTypes;
    CF200Pump?: CF200Pumps;
    PivotCenterWithSSRiserPipe?: boolean;
    HydraulicLift?: boolean;
    JunctionBoxWithPlug?: boolean;
    PivotOption?: boolean;
    PowerTowerEndBoom?: boolean;
    SingleSpanMaxi?: boolean;
    Towable?: boolean;
    CenterLift?: boolean;
    HydraulicPumpKit?: boolean;
    RotateCart?: boolean;
    BallastKit?: boolean;
    HDWheelGear?: boolean;
    SafetyValve?: boolean;
    JunctionBoxWithoutPlug?: number;
}
export interface ILateralCanalFeed {
    FuelTankSize?: FuelTankSizes;
    Cart?: CartTypes;
    Engine?: CanalFeedEngines;
    Pump?: Pumps;
    Generator?: CanalFeedGenerators;
    Intake?: IntakeTypes;
    FourFootSuctionPipes?: number;
    SixFootSuctionPipes?: number;
    BoosterPumpSelfCleaningScreen?: boolean;
}
export interface ILateralGuidance {
    GuidanceType?: GuidanceTypes;
    Location?: GuidanceLocations;
    GPSBase?: GPSBaseOptions;
    FurrowOptions?: ILateralGuidanceFurrowOptions;
    CableOptions?: ILateralGuidanceCableOptions;
    BuriedWireOptions?: ILateralGuidanceBuriedWireOptions;
    GPSCoverageStudyCompleted?: boolean;
    GPSCoverageStudyNetworkName?: string;
    GPSCoverageStudySiteName?: string;
    GPSCoverageStudyBaseLocation?: string;
    GPSCoverageStudyLateralStartLocation?: string;
    GPSCoverageStudyLateralEndLocation?: string;
}
export interface ILateralGuidanceFurrowOptions {
    Winch?: WinchTypes;
    LongSystem?: boolean;
}
export interface ILateralGuidanceCableOptions {
    RollsOfCable?: number;
    ExtraCableStops?: number;
}
export interface ILateralGuidanceBuriedWireOptions {
    SolarPowered?: boolean;
    Wire?: ILateralGuidanceBuriedWireOptionsWire;
}
export interface ILateralGuidanceBuriedWireOptionsWire {
    WireType?: WireTypes;
    RollsOfWire?: number;
}
export interface IControlPanel {
    PanelModel?: PanelModels;
    IntlManual?: Languages;
    EndTowerPositioning?: EndTowerPositioningTypes;
    GPSFields?: number;
    PACTimer?: boolean;
    LightningArrestor?: boolean;
    EndGunShutoffOverride?: boolean;
    PowerAutoRestart?: boolean;
    PivotAutoStop?: boolean;
    PivotAutoReverse?: boolean;
    CartAutoReverse?: boolean;
    ChemAuxControl?: boolean;
    InjectorPump?: boolean;
    InjectorPump30Amp?: boolean;
    GenStartControl?: boolean;
    RemotePanelOptions?: IControlPanelRemotePanelOptions;
    RemoteMCPLessMount?: boolean;
    LowPressureSafetyReg?: boolean;
    LowPressureSafetyLP?: boolean;
    LowVoltageSafety?: boolean;
    FrostControl?: boolean;
    WeepHolePlug5Pack?: boolean;
    WeepHolePlug50Pack?: boolean;
    RainGauge?: boolean;
    WindGauge?: boolean;
    LargeServiceWireKit?: boolean;
    PanelUL?: boolean;
    TowerUL?: boolean;
    GFI?: boolean;
    GroundingBar?: boolean;
    NonFuseCarrier?: boolean;
    PressureTransducerOptions?: PressureTransducerOptions;
    PressureTransducer0to25PSI?: boolean;
    PressureRestart?: boolean;
    TemperatureProbe?: boolean;
    CollectorRing?: CollectorRingTypes;
    SwitchMount?: SwitchMountTypes;
    Flowmeter?: FlowmeterTypes;
    FlowtubeWithVanes?: boolean;
    FlowtubeWithFS100?: boolean;
    FlowmeterPulse?: boolean;
    MCPSpanCableDisconnect?: boolean;
    ElectricalFrequency?: ElectricalFrequencies;
    Location?: PanelLocations;
    FlowmeterModel?: FlowmeterModels;
    TenFourPowerCord?: number;
    FourteenTwoPowerCord?: number;
    MinVoltage?: number;
    SourceVoltage?: number;
}
export interface IControlPanelRemotePanelOptions {
    RemotePanel?: boolean;
    RollsOfUnshieldedResolverWire?: number;
    RollsOfShieldedResolverWire?: number;
}
export interface IRC10 {
    IsEnabled?: boolean;
    SystemCategory?: SystemCategories;
    RC10ControlPanel?: RC10PanelModels;
    LowPressureShutdownSwitch?: boolean;
    SwingArmCorner?: boolean;
    PowerAutoRestart?: boolean;
    HasRPMStandardWithAutoReverse?: boolean;
    PositioningDeviceOption?: EndTowerPositioningTypes;
    FacePlateOption?: RC10FacePlateUpgrade;
    MountingLocation?: RC10MountingLocations;
    ControllerType?: RC10Types;
    AutoRestartPanelKit?: boolean;
    EndOfSystemGPSPanelKit?: boolean;
    ModemBaseModel?: RC10ModemBaseModels;
    PumpControl?: boolean;
    ChemControl?: boolean;
    EndGunControl?: boolean;
    AuxControl?: boolean;
    SpeedControl?: boolean;
    StartRelayKit?: boolean;
    PowerRelay?: boolean;
    Rain?: boolean;
    Wind?: boolean;
    Humidity?: boolean;
    Temperature?: boolean;
    RainWindMountingKit?: boolean;
    PressureSwitch20Psi?: boolean;
    PressureSwitch45Psi?: boolean;
    PressureTransducer0200?: boolean;
    PressureTransducer025?: boolean;
}
export interface ICircleSwingArm {
    Tires?: ICircleSwingArmTires;
    SteeringMotorCover?: boolean;
    GPSBase?: SACGPSGuidanceBaseOptions;
    ExtraGPSDataCable?: boolean;
    GPSBaseConvert?: boolean;
    PipeCoating?: SACPipeTypes;
    SprinklerValveType?: SprinklerValveTypes;
    SACConductorOption?: SACConductorOptions;
    ZoneControlType?: SACZoneControlTypes;
    DistributionFlowRate?: SACDistributionFlowRate;
    LeadingSwingArm?: boolean;
    EnergySaverPackage?: boolean;
    ControlLineBoosterESP?: boolean;
    ControlLineBooster?: boolean;
    AirCompressor?: boolean;
    VRISACSpan?: boolean;
    SACPathCreator?: SACPathCreatorType;
    ESAC125DistributionFlowRateFactors?: string;
    GPSCoverageStudyCompleted?: boolean;
    GPSCoverageStudyNetworkName?: string;
    GPSCoverageStudySiteName?: string;
    GPSCoverageStudyBaseLocation?: string;
    GPSCoverageStudyPivotLocation?: string;
}
export interface ICircleSwingArmTires {
    TireSize?: TireSizes;
    TireType?: TireTypes;
    TubesForTires?: boolean;
    OneTireMountedInReverse?: boolean;
}
export interface ICircleSACRetro {
    ParentSpanType?: SACRetroSpanTypes;
    HingeTireType?: SACRetroTireTypes;
    HingeTowerBoxOverloads?: TowerBoxOverloads;
    Coupler?: CouplerTypes;
    HingeTowerGearRatio?: GearDriveTypes;
    HingeTowerType?: TowerHeights;
    LastWheelTrack?: number;
    ParentSpanLength?: number;
    SACCableGauge?: number;
    DropType?: SACRetroSprinklerDropType;
    TowerUL?: boolean;
    IsControlPanelVRISACSpanCompliant?: boolean;
    TowerBoxKit?: boolean;
    NewLastSpanWire?: boolean;
    OmitStiffenerStabilizer?: boolean;
}
export enum SACRetroSprinklerDropType {
    /**
     * None - Sprinklers are to be Up Top
     */
    None = "None",

    /**
     * Rigid ( Galvanized )
     */
    RigidGalvanized = "RigidGalvanized",
    
    /**
     * Rigid ( PVC or Polyethylene )
     */
    RigidPVCPoly = "RigidPVCPoly",

    /**
     * Rigid ( Combo PVC/Hose or Tube/Hose )
     */
    RigidCombo = "RigidCombo",
    
    /**
     * Hose Drops - including all sub-types
     */
    Hose = "Hose"
}
export interface IFlangedSideSpan {
    SpanNumber?: number;
    SpanType?: SpanTypes;
    Length?: number;
    Spacing?: number;
    MinCableGauge?: number;
    ExtraCableConductors?: number;
    Extension?: boolean;
    Disconnecting?: boolean;
    dropSpanStartRelativeToPreviousSpanStart?: number;
    dropSpanEndRelativeToPreviousSpanEnd?: number;
    Stabilizers?: boolean;
    LargeTrussRods?: boolean;
    EndBoom?: boolean;
    SwingArm?: boolean;
}
export interface IFlexSideSpan {
    SpanNumber?: number;
    SpanType?: SpanTypes;
    Length?: number;
    Spacing?: number;
    MinCableGauge?: number;
    ExtraCableConductors?: number;
    Extension?: boolean;
    Disconnecting?: boolean;
    dropSpanStartRelativeToPreviousSpanStart?: number;
    dropSpanEndRelativeToPreviousSpanEnd?: number;
    Stabilizers?: boolean;
    LargeTrussRods?: boolean;
    EndBoom?: boolean;
    SwingArm?: boolean;
}
export interface ISpanProperties {
    AlumIVSupportRod?: boolean;
    LongSystemAlignment?: boolean;
    StainlessSteelCable?: boolean;
    StainlessSteelJoints?: boolean;
    GalvanizedCNJoints?: boolean;
    GalvanizedCNPlus?: boolean;
    TheftDeterrentClip?: boolean;
    ConduitClipWOSpringClip?: boolean;
}
export interface IFlangedSideTower {
    TowerNumber?: number;
    TowerType?: TowerHeights;
    CenterDrive?: GearDriveTypes;
    BuckBoost?: BuckBoostTypes;
    Tires?: IFlangedSideTowerTires;
    WrapAroundSpan?: WrapAroundSpanTypes;
    AutomatedValves?: boolean;

    /**
     * Not used on or after parts package 2024081901
     * Replaced with an equivalent field on ITowerProperties instead
     */
    AirCompressor?: boolean;

    /**
     * Not used on or after parts package 2024081901
     * Replaced with an equivalent field on ITowerProperties instead
     */
    ControlLineBoosterPump?: boolean;

    HeavyDuty?: boolean;
    TowerCrossBrace?: boolean;
    StiffenerTrussToPipe?: boolean;
    StiffenerStabilizers?: boolean;
    TowerBaseAccessStep?: boolean;
    TowerAutoReverse?: boolean;
    TowerAutoStop?: boolean;
    Guidance?: boolean;
    ThreeTires?: boolean;
    ThreeTiresFlex?: boolean;
    AgriTrac?: boolean;
    clockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
    anticlockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
}
export interface IFlangedSideTowerTires {
    TireSize?: TireSizes;
    TireType?: TireTypes;
    TwelveLugs?: boolean;
}
export interface IFlexSideTower {
    TowerNumber?: number;
    TowerType?: TowerHeights;
    CenterDrive?: GearDriveTypes;
    BuckBoost?: BuckBoostTypes;
    Tires?: IFlexSideTowerTires;
    WrapAroundSpan?: WrapAroundSpanTypes;
    AutomatedValves?: boolean;

    /**
     * Not used on or after parts package 2024081901
     * Replaced with an equivalent field on ITowerProperties instead
     */
    AirCompressor?: boolean;

    /**
     * Not used on or after parts package 2024081901
     * Replaced with an equivalent field on ITowerProperties instead
     */
    ControlLineBoosterPump?: boolean;

    HeavyDuty?: boolean;
    TowerCrossBrace?: boolean;
    StiffenerTrussToPipe?: boolean;
    StiffenerStabilizers?: boolean;
    TowerBaseAccessStep?: boolean;
    TowerAutoReverse?: boolean;
    TowerAutoStop?: boolean;
    Guidance?: boolean;
    ThreeTires?: boolean;
    ThreeTiresFlex?: boolean;
    AgriTrac?: boolean;
    clockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
    anticlockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
}
export interface IFlexSideTowerTires {
    TireSize?: TireSizes;
    TireType?: TireTypes;
    TwelveLugs?: boolean;
}
export interface ITowerProperties {
    Coupler?: CouplerTypes;
    WheelGear?: WheelGearSeries;
    TireProperties?: ITowerPropertiesTireProperties;
    UMCCoverKit?: boolean;
    CattleGuards?: boolean;
    DrainCouplers?: boolean;
    LPDrainKit?: boolean;
    WatermanDrain?: boolean;

    /**
     * Only used on or after parts package 2024081901
     * Replaces an equivalent field on IXSideTower instead
     */
    AirCompressor?: boolean;

    /**
     * Only used on or after parts package 2024081901
     * Replaces an equivalent field on IXSideTower instead
     */
    ControlLineBoosterPump?: boolean;
}
export interface ITowerPropertiesTireProperties {
    TubesForTires?: boolean;
    OneTireMountedInReverse?: boolean;
}
