import { Box } from "@mui/material";
import * as turf from "@turf/turf";
import i18next, { t } from "i18next";
import { isCenterPivot } from "rdptypes/helpers/system";
import * as React from "react";
import { FC } from 'react';
import QuoteClass from "roedata/roe_migration/QuoteClass";
import CenterPivotGeometryHelper from "../../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { getRDPSystemModel } from "../../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../../helpers/lengths";
import { AnnotationType } from "../../../model/project/IAnnotation";
import { IProjectWithId } from "../../../routes/pages/ProjectList";
import { formatLatLn } from "../../DocumentGenerationHelpers";
import { documentStyles } from "../../styles";

interface Props {
    systemId: string;
    layoutId: string;
    pwid: IProjectWithId;
}
const SystemDetailsTable: FC<Props> = React.forwardRef((props) => {
    const {systemId, pwid, layoutId} = props;

    let colWidth = 100/3;
    let oneColWidth = colWidth + "%";
    let twoColWidth = (colWidth * 2) + "%";
    let threeColWidth = (colWidth * 3) + "%";

    let fontSize = "0.8em";
    let cellStyle = {...documentStyles.cell, justifyContent: "left", padding: 2, width: oneColWidth, fontSize};

    const ns = "reports.";

    let rows: JSX.Element[] = [];

    const getCell = (title: string, val: string, fullWidth?: boolean) => {
        return <div style={{ ...cellStyle, width: fullWidth? threeColWidth : oneColWidth}}>
            <strong style={{fontFamily: "Arial Black"}}>{title}</strong>: {val}
        </div>;
    }

    let waterLineLength = 0;
    let electricLineLength = 0;

    let layout = pwid.project.layouts[layoutId!];

    layout.annotations.forEach((annotation) => {
        switch (annotation.type){
            case AnnotationType.canal:
            case AnnotationType.electricLine:
            case AnnotationType.line:
            case AnnotationType.waterLine:
                const line = annotation.lineAnnotation.line;
                const length = turf.length(turf.feature(line), { units: 'feet'});

                if (annotation.type === AnnotationType.electricLine){
                    electricLineLength += length;
                }
                else if (annotation.type === AnnotationType.waterLine){
                    waterLineLength += length;
                }
                break;
        }
    });

    let helper: CenterPivotGeometryHelper | LateralGeometryHelper;
    let system = pwid.project.layouts[layoutId].systems[systemId];
    let location: {
        latPart: string,
        lonPart: string
    } = undefined;

    const settings = React.useContext(DevSettingsCtx);

    if (isCenterPivot(system)) {
        helper = new CenterPivotGeometryHelper({
            project: pwid.project,
            layoutId: layoutId!,
            systemId: systemId!
        });

        let spanNumber = 0;
        //don't count end booms and swing arms
        system.FlangedSide.Span.forEach(span => {
            if (!span.EndBoom && !span.SwingArm){
                spanNumber++;
            }
        });

        let endGunAcres = 0;
        helper.getEndgunAcres.forEach((eg) => {
            let area = 0;
            eg.areas.forEach((a) => {
                area += a;
            });

            endGunAcres += area;
        });

        let systemLength = new DisplayLengthUnitBuilder(helper.systemRadiusFeetIncludingEndboomOrSac, 'feet')
            .convert(settings.dealerSettings.display.current.lengths)
            .appendValue(0)
            .appendString(" ")
            .appendShortName()
            .toString();

        rows.push(<div style={documentStyles.row}>{...[
                getCell(i18next.format(t(ns + 'system-length'), 'capitalize-each'), systemLength),
                getCell(i18next.format(t(ns + 'number-of-spans'), 'capitalize-each'), spanNumber.toString())
            ]}
            </div>
        );

        location = formatLatLn(helper.center.coordinates[1], helper.center.coordinates[0], settings.dealerSettings.display.current.coordinates);
    }

    const quote = new QuoteClass(system);
    rows.push(<div style={documentStyles.row}>{...[
            getCell(i18next.format(t(ns + 'system-model'), 'capitalize-each'), getRDPSystemModel(quote))
        ]}
        </div>
    );

    let waterLineLength_ = new DisplayLengthUnitBuilder(waterLineLength, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendShortName()
        .toString();

    let electricLineLength_ = new DisplayLengthUnitBuilder(electricLineLength, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendShortName()
        .toString();

    rows.push(<div style={documentStyles.row}>{...[
            getCell(i18next.format(t('water-line'), 'capitalize-each'), waterLineLength_),
            getCell(i18next.format(t('electric-line'), 'capitalize-each'), electricLineLength_)
        ]}
        </div>
    );

    if (typeof location !== "undefined"){
        rows.push(<div style={documentStyles.row}>{...[
                getCell(i18next.format(t('pivot-center'), 'capitalize-each'), location.latPart + " / " + location.lonPart, true)
            ]}
        </div>
        );
    }

    return <Box style={{border: "1px solid black", marginBottom: 20}}>
        <Box style={documentStyles.heading}>
            <Box style={{...documentStyles.sectionHeader, fontSize: 14}}>{i18next.format(t('system-details'), 'capitalize-each')}</Box>
        </Box>
        <Box style={{paddingLeft: 15, paddingRight: 15}}>
            <Box style={{...documentStyles.table}}>
                {rows}
            </Box>
        </Box>
    </Box>
});

export default SystemDetailsTable;