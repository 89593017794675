import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import ISystem from "rdptypes/project/ISystem";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import VRI_Zones from "roedata/roe_migration/VRIClass.VRI_Zones";
import { SystemSpanSide, getSpans } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import { documentStyles } from "../styles";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

export interface Props {
    system: ISystem;
    quote: QuoteClass;
    vriZones: VRI_Zones;
}

const VRIInformationSection: FC<Props> = (props) => {
    let {system, quote, vriZones} = props;

    let controlledDevices = 0;
    let controlledZones = 0;

    for (let i = 1; i <= vriZones.Count; i++){
        if (vriZones.Get(i).Controlled){
            controlledZones ++;
            controlledDevices += vriZones.Get(i).DeviceCount;
        }
    }

    let airCompressorStr: string;
    let locationOfEndTubing = vriZones.ValveBoxes.Get()[vriZones.ValveBoxCount - 1].TowerLocation;

    let flangedSideSpans = getSpans(system, SystemSpanSide.flanged, quote);
    let flexSideSpans = getSpans(system, SystemSpanSide.flex, quote);
    
    if (HasSwingArmCorner(quote.System) && system.Circle.SwingArm.AirCompressor){
        locationOfEndTubing = flangedSideSpans[flangedSideSpans.length - 1].endingLocationFeet;
        airCompressorStr = `Using SAC Air Compressor on Tower ${flangedSideSpans.length} located at ${locationOfEndTubing}'`;
    }
    else {
        if (vriZones.AirCompressorLocation < 0){
            let span = flexSideSpans[0 - vriZones.AirCompressorLocation];
            if (typeof span.type === "undefined" && (0 - vriZones.AirCompressorLocation) > 0){
                //e.g. end boom
                span = flangedSideSpans[0 - vriZones.AirCompressorLocation + 1];
            }

            airCompressorStr = `On Flex Tower ${0 - vriZones.AirCompressorLocation} located at ${(0 - (span.endingLocationFeet)).toFixed()}'`;
        }
        else if (vriZones.AirCompressorLocation === 0){
            airCompressorStr = "On Power Tower"
        }
        else {
            let span = flangedSideSpans[vriZones.AirCompressorLocation];
            if (typeof span.type === "undefined" && vriZones.AirCompressorLocation > 0){
                //e.g. end boom
                span = flangedSideSpans[vriZones.AirCompressorLocation - 1];
            }
            airCompressorStr = `On Flanged Tower ${vriZones.AirCompressorLocation} located at ${(span.endingLocationFeet).toFixed()}'`
        }
    }

    let threeEighthsTubing = locationOfEndTubing - vriZones.ValveBoxes.Get()[0].TowerLocation + vriZones.ValveBoxCount * 6;

    let rows: IRow[] = [{cells: [{
        text: t("common-phrases.sltNumberOfZones") +":",
        header: true,
        widthPc: 36
    },{
        text: vriZones.Count.toString(),
        widthPc: 64
    }]},{
        cells: [{
            text: t("common-phrases.sltNumberOfControlledZones") +":",
            header: true,
            widthPc: 36
        },{
            text: controlledZones.toString(),
            widthPc: 64
        }]
    },{
        cells: [{
            text: t("common-phrases.sltDevicesControlled") +":",
            header: true,
            widthPc: 36
        },{
            text: controlledDevices.toFixed(),
            widthPc: 64
        }]
    },{
        cells: [{
            text: t("common-phrases.sltAirCompressor") +":",
            header: true,
            widthPc: 36
        },{
            text: airCompressorStr,
            widthPc: 64
        }]
    },{
        cells: [{
            text: "1/4\" Tubing required:",
            header: true,
            widthPc: 36
        },{
            text: vriZones.QuarterInchTubingLength.toFixed(2) + "'",
            widthPc: 64
        }]
    },{
        cells: [{
            text: "3/8\" Tubing required:",
            header: true,
            widthPc: 36
        },{
            text: threeEighthsTubing.toFixed(2) + "'",
            widthPc: 64
        }]
    }];

    return (
        <View style={documentStyles.section}>
            <View style={documentStyles.heading}>
                <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltVRIInformation")}</Text>
            </View>
            <View style={{paddingLeft: 15, paddingRight: 15}}>
                <FixedWidthTable data={{rows}} />
            </View>
        </View>
    );
};

export default VRIInformationSection;