import ISystem from "../project/ISystem";
import { ISystemBase, PaymentTypes, SystemTypes } from "../project/ISystemBase.AutoGenerated";
import { SideEnum } from "./SideEnum";

export const isCenterPivot = (sys: ISystemBase) => {
    let st = sys.SystemProperties.SystemType;
    return st === SystemTypes.CenterPivot || st === SystemTypes.SwingArmRetro || st === SystemTypes.KwikTow;
}

export const isPartialPivot = (system: ISystem) => {
    if (!system.centerPivot) return false;
    if (!system.Circle.CenterPivot.isPartialPivot) return false;
    if (system.Circle!.CenterPivot.clockwiseCompassHeadingStart === undefined 
        && system.Circle!.CenterPivot.clockwiseCompassHeadingEnd === undefined) return false;

    const startHeading = system.Circle!.CenterPivot.clockwiseCompassHeadingStart || 0;
    const endHeading = system.Circle!.CenterPivot.clockwiseCompassHeadingEnd || 0;

    return startHeading !== endHeading;
}

export const formatPaymentTermsType = (ptt: PaymentTypes | string) => {
    if (typeof(ptt) === "string"){
        ptt = PaymentTypes[ptt];
    }

    if (typeof ptt === "undefined"){
        return "Undefined";
    }
    
    switch (ptt){
        case PaymentTypes.Cash: 
            return "Cash";
        case PaymentTypes.AgDirect:
            return "AgDirect";
        case PaymentTypes.ReinkeHuntington:
            return "Reinke Finance / Huntington Bank";
        case PaymentTypes.DiversifiedFS:
            return "Diversified Financial Services";
        case PaymentTypes.FirstNat:
            return "First National";
            case PaymentTypes.ReinkeCoastCapital:
            return "Coast Capital";
        default:
            return ptt.toString();
    }
}

export const getFwdSide = (sys: ISystem): SideEnum => {
    if (isCenterPivot(sys)) {
        return SideEnum.Flanged;
    }
    if (sys.flexIsFwd) {
        return SideEnum.Flex;
    }
    return SideEnum.Flanged;
}

export const getAftSide = (sys: ISystem): SideEnum | undefined => {
    if (isCenterPivot(sys)) {
        return undefined;
    }
    if (sys.flexIsFwd) {
        return SideEnum.Flanged;
    }
    return SideEnum.Flex;
}