import { DefaultOutlet, IOutlet as Outlet } from "rdptypes/project/ISprinklerChart";
import { BoomBackDevices, BoomBackTypes, IBoomBack as BoomBacks, ID3000 as D3000, D3000Plates, DeviceTypes, DeviceWeights, DropTypes, FittingTypes, HoseClamps, HoseDropWeights, HoseTopFittings, NozzleClipTypes, OutletValveTypes, PipeMaterialTypes, RegulatorLocations, RegulatorPressures, RegulatorTypes, RigidDropBottomFittings, RigidDropMaterialTypes, RigidDropTypes, ThreadTypes, UPipeFittings, UPipeMaterialTypes, UPipeReaches, UPipeTypes, WeightBottomFittings, WeightTopFittings } from "rdptypes/project/ISprinklers";
import {
    BoosterPumpTypes,
    EndGunTypes,
    EndOfSystemTypes,
    SpanTypes,
    SprinklerManufacturerTypes,
    SprinklerValveTypes,
    TowerHeights
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import { WarningSeverity, WarningTypes } from 'rdptypes/project/SprinklerEngineTypes/Enums';
import { SprinklerEngineWarning } from 'rdptypes/project/SprinklerEngineTypes/SprinklerEngineWarning';
import schema from "../data/partsPackages/2024060101/poSchema";
import { mergeDeep } from '../helpers/mergeDeep';
import { Cosineof162Degrees, SystemMotionEnum } from "./CommonConstants";
import { FeetToMeters, GetMinimalEndGunPressures, PressureLossSimpsonsRule, RollsToOrder, SelectDualSprinklerPackageValveBoxTowerNumber, SprinklerBrand } from "./CommonFunctions";
import * as devext from "./ExtensionMethods.DeviceTypes.ExtensionMethods";
import { DeviceNozzle } from "./Nozzle";
import * as ohf from "./OtherHelpers";
import PartsAssembler from "./PartsAssembler";
import QuoteClass, { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import { SpanPipeTypes, SpanType } from "./SpanFunctions";
import { ComputeSpanMapKey, OutletMapItem, SpanMaps } from './SpanMaps';
import { SelectSprinkler } from "./SprinklersChartSideClass.SprinklerHelper";
import * as sysf from "./SystemFunctions";
import { TowersClass } from "./TowersClass";
import { strLanguageText } from "./TranslationsLib";
import { EndGun, EndOfSystem, Side, Span, SprinklerChart, Tower } from "./Types";
import { Format, StringFormat } from "./VbCompat";
export class SprinklersChartSideClass {
    private Quote: QuoteClass;
    private IsFlangedSide: boolean;
    public Side: Side;
    public TowersClass: TowersClass;
    private readonly POSection: number = 12;
    private readonly PORigidDrops: number = 1;
    private readonly PORDUPipe: number = 2;
    private readonly PORDDBF: number = 3;
    private readonly PORDClamp: number = 4;
    private readonly POHDUPipe: number = 5;
    private readonly POHDFittings: number = 6;
    private readonly POHDHoseClamps: number = 7;
    private readonly POHDWeight: number = 8;
    private readonly POHDScrewClamp: number = 9;
    private readonly POHDDragAdapter: number = 10;
    private readonly POHDDragSock: number = 11;
    private readonly POHDHose: number = 12;
    private readonly PODeviceWeight: number = 13;
    private readonly PORegs: number = 14;
    private readonly POSprinklers: number = 15;
    private readonly POPipeJointCompound: number = 16;
    private readonly POImpactRiser: number = 17;
    private readonly POValve: number = 20;
    private readonly POBoomBacks: number = 21;
    private readonly POPC: number = 22;
    private readonly PODualNozzle: number = 23;
    private readonly POWASControlValve: number = 25;
    private readonly POESPAquaValve: number = 26;
    private readonly POESPBoostValve: number = 27;
    private readonly POESPDrainValve: number = 28;
    private readonly POESPStrainer: number = 29;
    private readonly POESPControlLine: number = 30;
    private readonly POBoomBackValves: number = 31;
    private readonly POD3000Plate2: number = 33;
    private readonly PODeviceAdapter: number = 34;
    private readonly PO3000FCN: number = 35;
    private readonly POHoseSling: number = 36;
    private readonly HDFittingSTxHBBlack: number = 0;
    private readonly HDFittingFxHBBlack: number = 1;
    private readonly HDFittingMxHBGray: number = 2;
    private readonly HDFittingMxHBBlack: number = 3;
    private readonly HDFittingCloseGray: number = 4;
    private readonly HDFittingCloseBlack: number = 5;
    private readonly HDFittingCloseGalv: number = 6;
    private readonly HDFittingCouplingGray: number = 7;
    private readonly HDFittingCouplingBlack: number = 8;
    private readonly HDFittingCouplingGalv: number = 9;
    private readonly HDFittingMxHBBlackNelson: number = 10;
    private readonly HDFittingFxHBBlackNelson: number = 11;
    private readonly HDFittingMax: number = 9;
    private readonly MaxHDHoseClamps: number = 2;
    private bDropGroundClearanceProblem: boolean;
    private PrepSprinklerData = (warnings: SprinklerEngineWarning[]) => {
        let LastSpan: number = sidef.Spans(this.Quote.System, this.Side).Count;
        if (LastSpan === 0) {
            return;
        }
        for (let i: number = 1; i <= LastSpan; i++
        ) {
            this.GenerateSpanOutlets(i, LastSpan, warnings);
        }
        if (!this.Side.Span[LastSpan - 1].EndBoom) {
            this.AddNoEndBoom();
        }
    }
    private AddOutlet = (SpanNumber: number, Distance: number, Height: number, bTowerBoomBackOutlet: boolean, iLastSpan: number) => {
        let bSAC: boolean;
        let bEndBoom: boolean;
        let LocationOfHinge: number;
        if (this.Side.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC && (SpanNumber === iLastSpan || SpanNumber === iLastSpan - 1)) {
            LocationOfHinge = spanf.EndingRadius(this.Quote.System, this.Side, this.Side.Span[iLastSpan - 2 - 1]);
            bSAC = true;
            if (SpanNumber === iLastSpan) {
                bEndBoom = true;
            }
        }
        if (this.Side.EndOfSystem.EndOfSystemType === EndOfSystemTypes.EndBoom && SpanNumber === iLastSpan) {
            bEndBoom = true;
        }
        const outlets = ohf.Outlets(this.Side.SprinklerChart);
        let i: number = outlets.Count + 1;
        this.Side.SprinklerChart.Outlet.push(mergeDeep({}, DefaultOutlet));
        let curOutlet: Outlet = this.Side.SprinklerChart.Outlet[i - 1];
        curOutlet.OutletNumber = i;
        curOutlet.RelativeHeight = Height;
        curOutlet.SpanNumber = SpanNumber;
        curOutlet.IsTowerBoomBackOutlet = bTowerBoomBackOutlet;
        curOutlet.IsSACOutlet = bSAC;
        curOutlet.IsEndBoomOutlet = bEndBoom;
        curOutlet.HookLocation = 0;
        if (i === 1) {
            curOutlet.Location = Distance + spanf.StartingLocation(this.Quote.System, this.Side, this.Side.Span[1 - 1]);
            curOutlet.Radius = Distance + spanf.StartingLocation(this.Quote.System, this.Side, this.Side.Span[1 - 1]);
        }
        else {
            let PrevOutlet: Outlet = this.Side.SprinklerChart.Outlet[i - 1 - 1];
            if (!(PrevOutlet.HookLocation === 0)) {
                curOutlet.Location = Distance + PrevOutlet.HookLocation;
                if (bSAC) {
                    curOutlet.Radius = Math.sqrt(Math.pow(LocationOfHinge, 2) + Math.pow((curOutlet.Location - LocationOfHinge), 2) - 2 * LocationOfHinge * (curOutlet.Location - LocationOfHinge) * Cosineof162Degrees);
                }
                else {
                    curOutlet.Radius = Distance + PrevOutlet.HookRadius;
                }
            }
            else {
                curOutlet.Location = Distance + PrevOutlet.Location;
                if (bSAC) {
                    curOutlet.Radius = Math.sqrt(Math.pow(LocationOfHinge, 2) + Math.pow((curOutlet.Location - LocationOfHinge), 2) - 2 * LocationOfHinge * (curOutlet.Location - LocationOfHinge) * Cosineof162Degrees);
                }
                else {
                    curOutlet.Radius = Distance + PrevOutlet.Radius;
                }
            }
        }

        let bha = sidef.BaselineHeightAtLocation(this.Quote.System, this.Side, sidef.FlangedSide(this.Quote.System, this.Side), curOutlet.Location);

        curOutlet.AbsoluteHeight = bha + ((curOutlet.RelativeHeight + 3) / 12);
    }

    private AddHook = (Distance: number, iLastSpan: number) => {
        let spChart = this.Side.SprinklerChart;
        let i: number = ohf.Outlets(spChart).Count;
        let oPrev = spChart.Outlet[i - 1 - 1];
        let bSAC: boolean = !(oPrev.Location === oPrev.Radius);
        let o = spChart.Outlet[i - 1];
        o.HookLocation = Distance + o.Location;
        if (bSAC) {
            let LocationOfHinge: number = spanf.EndingRadius(this.Quote.System, this.Side, this.Side.Span[iLastSpan - 2 - 1]);
            o.HookRadius = Math.sqrt(Math.pow(LocationOfHinge, 2) + Math.pow((o.HookLocation - LocationOfHinge), 2) - 2 * LocationOfHinge * (o.HookLocation - LocationOfHinge) * Cosineof162Degrees);
        }
        else {
            o.HookRadius = Distance + o.Radius;
        }
    }
    private AddNoEndBoom = () => {
        let sc: SprinklerChart = this.Side.SprinklerChart;
        let i: number = ohf.Outlets(sc).Count;
        let o: Outlet = sc.Outlet[i - 1];
        o.HookLocation = 1 + o.HookLocation;
        o.HookRadius = 1 + o.HookRadius;
    }
    private GenerateSpanOutlets = (SpanNumber: number, SpanCount: number, warnings: SprinklerEngineWarning[]) => {
        let OutletList: OutletMapItem[];
        let s: Span = this.Side.Span[SpanNumber - 1];
        if (!s.EndBoom && !s.SwingArm && !s.SpanType) {
            warnings.push({
                Message: `Span Type was not set for span: ${SpanNumber}`,
                WarningSeverity: WarningSeverity.Critical,
                WarningType: WarningTypes.InvalidData,
                Context: 'Span Configuration'
            });
        }

        if (!s.Length) {
            warnings.push({
                Message: `Span length was not set for span: ${SpanNumber}`,
                WarningSeverity: WarningSeverity.Critical,
                WarningType: WarningTypes.InvalidData,
                Context: 'Span Configuration'
            });
        }

        if (!s.EndBoom && !s.SwingArm && !s.Spacing) {
            warnings.push({
                Message: `Spacing was not set for span: ${SpanNumber}`,
                WarningSeverity: WarningSeverity.Critical,
                WarningType: WarningTypes.InvalidData,
                Context: 'Span Configuration'
            });
        }

        if ((s.EndBoom || s.SwingArm || (s.SpanType && s.Spacing)) && s.Length) {
            let spanType: SpanTypes;
            let spacing: number;
            if (s.EndBoom) {
                spanType = SpanTypes.EndBoom;
                const eos = this.IsFlangedSide ? this.Quote.RightEOSClass : this.Quote.LeftEOSClass;
                spacing = eos.ComputeEndBoomSpacing();
            }
            else if (s.SwingArm) {
                spanType = this.Quote.SwingArmClass.ComputeSwingArmType();
                spacing = 57;
            } else {
                spanType = s.SpanType;
                spacing = s.Spacing;
            }
            OutletList = SpanMaps[ComputeSpanMapKey(spanType, s.Length, spacing, s.Extension)];
            if (!OutletList || OutletList.length === 0) {
                warnings.push({
                    Message: `Span type(${s.SpanType}), length(${s.Length}) and spacing(${s.Spacing}) are not compatible for span: ${SpanNumber}.`,
                    WarningSeverity: WarningSeverity.Critical,
                    WarningType: WarningTypes.InvalidData,
                    Context: 'Computing Outlets'
                });
            } else {
                var sideTestVar = this.IsFlangedSide;
                let HookDistance: number;
                let Height: number;
                for (let o of OutletList) {
                    if (o.OutletCode === 0) {
                        HookDistance = o.Distance;
                    }
                    else {
                        Height = o.Height;
                        this.AddOutlet(SpanNumber, o.Distance / 12, Height, false, SpanCount);
                    }
                }
                let isTowerBoomBackOK: boolean = true;
                if (this.Side.Span[SpanNumber - 1].SwingArm) {
                    isTowerBoomBackOK = false;
                }
                if (this.Side.Span[SpanNumber - 1].EndBoom) {
                    isTowerBoomBackOK = false;
                }
                if (this.Quote.SwingArmClass.FieldSet.DataValid()) {
                    if (this.Quote.System.Circle.SwingArm.EnergySaverPackage) {
                        isTowerBoomBackOK = false;
                    }
                }
                if (isTowerBoomBackOK) {
                    this.AddOutlet(SpanNumber, ((HookDistance - 8) / 12), (Height * (8 / HookDistance)), true, SpanCount);
                    this.AddHook(8 / 12, SpanCount);
                }
                else {
                    this.AddHook(HookDistance / 12, SpanCount);
                }
            }
        }
    }
/*public ComputeSprinklerDataCode = (): string => {
return `${this.ComputeSprinklerDataCodePart1()}${this.ComputeSprinklerDataCodePart2()}${this.ComputeSprinklerDataCodePart3()}`;
}
*//*public ComputeSprinklerDataCodePart1 = (): string => {
On Error Resume Next
let x = new StringBuilder();
let s: Spans = sidef.Spans(this.Side);
let k: number = s.Count;
for (let i: number = 1; i <= k; i++
) {
let sp = s.Span[i - 1];
x.Append(sp.SpanType);
x.Append(sp.Length);
x.Append(sp.Spacing);
x.Append(sp.Extension);
x.Append(spanf.SwingArm(sp));
x.Append(sp.EndBoom);
x.Append(sp.StartingRadius);
}
let eos = this.Side.EndOfSystem;
let eg = eos.EndGun;
x.Append(eg.EndGunTypePrimary);
x.Append(eg.EndGunTypeSecondary);
x.Append(eg.BoosterPump);
x.Append(eos.EndOfSystemType);
if (eos.EndOfSystemType !== EndOfSystemTypes.EndBoom) {
x.Append(this.Quote.System.Circle.SwingArm.EnergySaverPackage);
}
if (sysf.HasSwingArmCorner(this.Quote.System)) {
x.Append(this.Quote.System.Circle.SwingArm.ZoneControlType);
x.Append(this.Quote.System.Circle.SwingArm.DistributionFlowRate);
x.Append(this.Quote.System.Circle.SwingArm.VRISACSpan);
x.Append(this.Quote.System.Circle.SwingArm.ESAC125DistributionFlowRateFactors.GetHashCodeStable());
}
x.Append(this.Quote.System.ControlPanel.ElectricalFrequency);
if (sysf.IsMaxigator(this.Quote.System)) {
x.Append(this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit);
}
return x.ToString().Replace(",", ".");
}
*//*public ComputeSprinklerDataCodePart2 = (): string => {
On Error Resume Next
return "";
}
*//*public ComputeSprinklerDataCodePart3 = (): string => {
On Error Resume Next
return "";
}
*/public PrepData = (warnings: SprinklerEngineWarning[]) => {
        this.Side.SprinklerChart.Outlet = [];
        this.PrepSprinklerData(warnings);
        // TODO still needed?
        //this.Side.SprinklerChart.DataCode = this.ComputeSprinklerDataCode();
    }
    public AssembleWarnings = () => {
            let iSpanCount: number = sidef.Spans(this.Quote.System, this.Side).Count;
            if (iSpanCount === 0) {
                return;
            }
            let i: number;
            let sSpanMidpoint: number;
            let sSystemMidpoint: number;
            let sGPMReq: number;
            let sGPMDel: number;
            sSystemMidpoint = spanf.EndingLocation(this.Quote.System, this.Side, this.Side.Span[iSpanCount - 1]) / 2;
            for (i = 1; i <= iSpanCount; i++) {
                let sp = this.Side.Span[i - 1];
                sGPMReq = this.Side.SprinklerChart.AdditionalSpanData?.[i-1]?.GPMRequired || 0; 
                sGPMDel = this.Side.SprinklerChart.AdditionalSpanData?.[i-1]?.GPMDelivered || 0;
                sSpanMidpoint = (spanf.EndingLocation(this.Quote.System, this.Side, sp) - spanf.StartingLocation(this.Quote.System, this.Side, sp)) / 2;
                if ((sGPMDel - sGPMReq) / sGPMReq > 0.1) {
                    if (sSpanMidpoint > sSystemMidpoint && !(sysf.IsMaxigator(this.Quote.System)) && i !== iSpanCount) {
                        this.Quote.Warnings.Add(strLanguageText("sltOneOrMoreSpansAreOver"), WarningLevels.SectionBlock);
                    }
                    else {
                        this.Quote.Warnings.Add(strLanguageText("sltOneOrMoreSpansAreOver"), WarningLevels.Decision);
                    }
                }
                if ((sGPMDel - sGPMReq) / sGPMReq <= -0.03) {
                    this.Quote.Warnings.Add(strLanguageText("sltOneOrMoreSpansAreUnder"), WarningLevels.SectionBlock);
                }
                else if ((sGPMDel - sGPMReq) / sGPMReq <= -0.02) {
                    this.Quote.Warnings.Add(strLanguageText("sltOneOrMoreSpansAreUnder"), WarningLevels.Decision);
                }
            }
            let bNoDropWeight: boolean;
            for (let pi: number = 1; pi <= this.Side.Sprinklers.Package.length; pi++) {
                let pkg = this.Side.Sprinklers.Package[pi-1];
                if (pkg.Drop === DropTypes.Hose && pkg.HoseDrop.DeviceWeight === DeviceWeights.None && pkg.HoseDrop.Weight === HoseDropWeights.None) {
                    bNoDropWeight = true;
                }
            }
            if (bNoDropWeight) {
                this.Quote.Warnings.Add(strLanguageText("sltHoseDropWeightWarning"), WarningLevels.Decision);
            }
            let SideDescription: string = "";
            if (sysf.IsDualSided(this.Quote.System)) {
                SideDescription = strLanguageText(this.IsFlangedSide ? "sltFlangedSide" : "sltFlexSide") + ", ";
            }
            let eg1ip = this.Side.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties;
            if (eg1ip.EndGunTypeCalculated !== EndGunTypes.None) {
                if (eg1ip.EndGunTypeCalculated === EndGunTypes.DualP85) {
                    this.Quote.Warnings.Add(strLanguageText("sltDualP85sApplied"), WarningLevels.Informational);
                }
                if (eg1ip.DiffuserCalculated) {
                    this.Quote.Warnings.Add(strLanguageText("sltDiffuserApplied"), WarningLevels.Informational);
                }
                let sEndGunGPM: number = eg1ip.GPMDelivered;
                let sReqEndGunGPM: number = eg1ip.GPMRequired;
                let bBPLimit: boolean = eg1ip.LimitedByBoosterPump;
                let sPressure: number = eg1ip.Pressure;
                let sNozzleSize: number = Math.round(eg1ip.NozzleSize * 20) / 20;
                const { sDangerPSI, sWarningPSI } = GetMinimalEndGunPressures(eg1ip.EndGunTypeCalculated, sNozzleSize, eg1ip.DiffuserCalculated);
                if (sPressure < sDangerPSI) {
                    this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + strLanguageText("lsltEndGunPressWayTooLow"), WarningLevels.SectionBlock);
                }
                else if (sPressure < sWarningPSI) {
                    this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + strLanguageText("lsltEndGunPressTooLow"), WarningLevels.Decision);
                }
                switch (eg1ip.EndGunTypeCalculated) {
                    case EndGunTypes.R55i:
                    case EndGunTypes.R55:
                        if (sPressure > 40) {
                            this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + strLanguageText("sltEndGunPressTooHigh"), WarningLevels.SectionBlock);
                        }
                        break;
                    case EndGunTypes.R75:
                        if (sPressure > 60) {
                            this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + strLanguageText("sltEndGunPressTooHigh"), WarningLevels.SectionBlock);
                        }
                        break;
                    case EndGunTypes.R75LP:
                        if (sPressure > 40) {
                            this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + strLanguageText("sltEndGunPressTooHigh"), WarningLevels.SectionBlock);
                        }
                        break;
                    case EndGunTypes.DualP85:
                    case EndGunTypes.SingleP85:
                    case EndGunTypes.SR75:
                        if (sPressure > 80) {
                            this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + strLanguageText("sltEndGunPressTooHigh"), WarningLevels.Decision);
                        }
                        break;
                    case EndGunTypes.SR100:
                    case EndGunTypes.SR100NV:
                    case EndGunTypes.SimeWing:
                    case EndGunTypes.TwinMax:
                        if (sPressure > 100) {
                            this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + strLanguageText("sltEndGunPressTooHigh"), WarningLevels.Decision);
                        }
                        break;
                }
                if (sEndGunGPM < sReqEndGunGPM * 0.9) {
                    if (bBPLimit) {
                        this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + StringFormat(strLanguageText("sltDueToABoosterPumpLimitUnder"), Format((sReqEndGunGPM - sEndGunGPM) * 100 / sReqEndGunGPM, "##")), WarningLevels.Decision);
                    }
                    else {
                        this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + StringFormat(strLanguageText("sltDueToANozzleSizeUnderwater"), Format((sReqEndGunGPM - sEndGunGPM) * 100 / sReqEndGunGPM, "##")), WarningLevels.Decision);
                    }
                }
                if (sEndGunGPM > sReqEndGunGPM * 1.1) {
                    switch (eg1ip.EndGunTypeCalculated) {
                        case EndGunTypes.R55i:
                        case EndGunTypes.R55:
                        case EndGunTypes.R75:
                        case EndGunTypes.R75LP:
                            this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + StringFormat(strLanguageText("sltEndGunOverwater"), Format((-sReqEndGunGPM + sEndGunGPM) * 100 / sReqEndGunGPM, "##")), WarningLevels.Decision);
                            break;
                        default:
                            this.Quote.Warnings.Add(SideDescription + strLanguageText("sltPrimaryEndGun") + ": " + StringFormat(strLanguageText("sltDueToANozzleSizeOverwater"), Format((-sReqEndGunGPM + sEndGunGPM) * 100 / sReqEndGunGPM, "##")), WarningLevels.Decision);
                            break;
                    }
                }
            }
            let eg2ip = this.Side.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties;
            if (eg2ip.EndGunTypeCalculated !== EndGunTypes.None) {
                let sEndGunGPM: number = eg2ip.GPMDelivered;
                let sReqEndGunGPM: number = eg2ip.GPMRequired;
                let sPressure: number = eg2ip.Pressure;
                let sNozzleSize: number = Math.round(eg2ip.NozzleSize * 20) / 20;
                const { sDangerPSI, sWarningPSI } = GetMinimalEndGunPressures(eg2ip.EndGunTypeCalculated, sNozzleSize, eg2ip.DiffuserCalculated);
                if (sPressure < sDangerPSI) {
                    this.Quote.Warnings.Add(SideDescription + strLanguageText("sltSecondaryEndGun") + ": " + strLanguageText("lsltEndGunPressWayTooLow"), WarningLevels.SectionBlock);
                }
                else if (sPressure < sWarningPSI) {
                    this.Quote.Warnings.Add(SideDescription + strLanguageText("sltSecondaryEndGun") + ": " + strLanguageText("lsltEndGunPressTooLow"), WarningLevels.Decision);
                }
                switch (eg2ip.EndGunTypeCalculated) {
                    case EndGunTypes.R55i:
                    case EndGunTypes.R55:
                        if (sPressure > 40) {
                            this.Quote.Warnings.Add(SideDescription + strLanguageText("sltSecondaryEndGun") + ": " + strLanguageText("sltEndGunPressTooHigh"), WarningLevels.SectionBlock);
                        }
                        break;
                    case EndGunTypes.R75:
                        if (sPressure > 60) {
                            this.Quote.Warnings.Add(SideDescription + strLanguageText("sltSecondaryEndGun") + ": " + strLanguageText("sltEndGunPressTooHigh"), WarningLevels.SectionBlock);
                        }
                        break;
                    case EndGunTypes.R75LP:
                        if (sPressure > 40) {
                            this.Quote.Warnings.Add(SideDescription + strLanguageText("sltSecondaryEndGun") + ": " + strLanguageText("sltEndGunPressTooHigh"), WarningLevels.SectionBlock);
                        }
                        break;
                }
                if (sEndGunGPM < sReqEndGunGPM * 0.9) {
                    this.Quote.Warnings.Add(SideDescription + strLanguageText("sltSecondaryEndGun") + ": " + StringFormat(strLanguageText("sltDueToANozzleSizeUnderwater"), Format((sReqEndGunGPM - sEndGunGPM) * 100 / sReqEndGunGPM, "##")), WarningLevels.Decision);
                }
                if (sEndGunGPM > sReqEndGunGPM * 1.1) {
                    this.Quote.Warnings.Add(SideDescription + strLanguageText("sltSecondaryEndGun") + ": " + StringFormat(strLanguageText("sltEndGunOverwater"), Format((-sReqEndGunGPM + sEndGunGPM) * 100 / sReqEndGunGPM, "##")), WarningLevels.Decision);
                }
            }
            let ip = this.Side.SprinklerChart.IrrigationProperties;
            if (ip.PressureExceededCount > 0 && ip.PressureNotMetCount > 0) {
                this.Quote.Warnings.Add(StringFormat(strLanguageText("sltInOrderToProvideExceededNotMet"), ip.PressureExceededCount, ip.PressureNotMetCount), WarningLevels.Decision);
            }
            else if (ip.PressureExceededCount > 0) {
                this.Quote.Warnings.Add(StringFormat(strLanguageText("sltInOrderToProvideExceeded"), ip.PressureExceededCount), WarningLevels.Decision);
            }
            else if (ip.PressureNotMetCount > 0) {
                this.Quote.Warnings.Add(StringFormat(strLanguageText("sltInOrderToProvideNotMet"), ip.PressureNotMetCount), WarningLevels.Decision);
            }
            let iOutlet: number;
            let sError: number;
            let sLowError: number = 0;
            let sHighError: number = 0;
            let bBlackPlasticError: boolean;
            let dDropLengthInFeet: number;
            let iOutletCount: number;
            let bNoMore: boolean;
            let spChart = this.Side.SprinklerChart;
            iOutletCount = ohf.Outlets(spChart).Count;
            for (iOutlet = 1; iOutlet <= iOutletCount; iOutlet++
            ) {
                let o = spChart.Outlet[iOutlet - 1];
                if (o.Device !== DeviceTypes.None) {
                    if (o.Drop === DropTypes.Rigid) {
                        if (o.RigidDrop.DropType === RigidDropTypes.Variable) {
                            dDropLengthInFeet = this.RigidDropToFeet(this.OutletRigidDropLengthAsEnum(iOutlet, sError));
                            if (o.RigidDrop.UPipeMaterial === UPipeMaterialTypes.BlackPlastic) {
                                switch (o.RigidDrop.DropMaterial) {
                                    case RigidDropMaterialTypes.Poly:
                                        if (dDropLengthInFeet > 7) {
                                            bBlackPlasticError = true;
                                        }
                                        break;
                                    case RigidDropMaterialTypes.Combo:
                                    case RigidDropMaterialTypes.ComboPVCHose:
                                        if (dDropLengthInFeet > 9) {
                                            bBlackPlasticError = true;
                                        }
                                        break;
                                }
                                if (bBlackPlasticError) {
                                    this.Quote.Warnings.Add(strLanguageText("sltBlackPlasticCannotBeUsed"), WarningLevels.SectionBlock);
                                }
                            }
                            if (sError < 0 && sError < sLowError) {
                                sLowError = sError;
                            }
                            if (sError > 0 && sError > sHighError) {
                                sHighError = sError;
                            }
                        }
                    }
                }
            }
            if (sLowError < 0) {
                this.Quote.Warnings.Add(StringFormat(strLanguageText("sltRigidDropTooShort"), Format(FeetToMeters(-sLowError), "0.0")), WarningLevels.Decision);
            }
            if (sHighError > 0) {
                this.Quote.Warnings.Add(StringFormat(strLanguageText("sltRigidDropTooLong"), Format(FeetToMeters(sHighError), "0.0")), WarningLevels.Decision);
            }
            // Icon TODO: I have commented the following line. Is it needed?:
            //this.DetectPOErrors();
            if (this.bDropGroundClearanceProblem) {
                this.Quote.Warnings.Add(strLanguageText("lsltDropGroundClearanceWarning"), WarningLevels.Decision);
            }
            if (sysf.HasSwingArmCorner(this.Quote.System)) {
                let bRegsOnEntireSystem: boolean = true;
                let spks = this.Quote.System.FlangedSide.Sprinklers;
                for (let pi: number = 1; pi <= spks.Package.length; pi++
                ) {
                    let pkg = spks.Package[pi-1];
                    if (pkg.Regulator.RegulatorType === RegulatorTypes.None || pkg.Regulator.AsNeeded) {
                        bRegsOnEntireSystem = false;
                    }
                }
                if (!(bRegsOnEntireSystem)) {
                    this.Quote.Warnings.Add(strLanguageText("sltRegsNeededOnSAC"), WarningLevels.Informational);
                }
            }
            if (this.Quote.System.SprinklerProperties.DualSprinklerPackage) {
                let towerCount: number = sidef.Towers(this.Quote.System.FlangedSide).Count;
                if (towerCount < 2) {
                    this.Quote.Warnings.Add(strLanguageText("Dual Sprinkler Package requires minimum of 2 Spans."));
                }
                let sprinklerBoxTowerNumber: number = SelectDualSprinklerPackageValveBoxTowerNumber(this.Quote.System, this.Quote.System.FlangedSide);
                if (this.Quote.System.FlangedSide.Tower[sprinklerBoxTowerNumber - 1].Guidance) {
                    this.Quote.Warnings.Add(strLanguageText(`Dual Sprinkler Package requires placement of Sprinkler Valve Box at Tower:(${sprinklerBoxTowerNumber}). Ensure 'Lateral Move Guidance' is on the Power Tower or any other Outer Tower. `));
                }
            }
            if (this.Quote.System.SprinklerProperties.DualSprinklerPackage) {
                let errors: string[] = [];
                let list = ohf.Outlets(this.Quote.System.FlangedSide.SprinklerChart).ToList();
                list.forEach((o) => {
                    if (o.OutletNumber > 0 && o.Device !== DeviceTypes.None) {
                        let o2: Outlet = list[o.OutletNumber];
                        if (o2 && o2?.Device !== DeviceTypes.None && !(o2?.IsTowerBoomBackOutlet)) {
                            let o3: Outlet = list[o2.OutletNumber];
                            if (o3 && !(o3?.IsTowerBoomBackOutlet)) {
                                errors.push(`Outlet(${o.OutletNumber}) is in use where Outlet(${o2.OutletNumber}) is expected to be empty but it is not. `);
                            }
                        }
                    }
                }
                );
                if (errors.length > 0) {
                    this.Quote.Warnings.Add(strLanguageText("sltDualSprinklerPackageWarning"));
                    errors.slice(0, 6).forEach((e) => {
                        this.Quote.Warnings.Add(strLanguageText(e));
                    });
                    if (errors.length > 6) {
                        this.Quote.Warnings.Add(strLanguageText(`... and ${errors.length - 6} more similar errors.`));
                    }
                }
            }
        }
    public DataOK = (WithWarnings: boolean): void => {
        /*

        TODO Icon
        
        if (!(String.IsNullOrWhiteSpace(this.Side.SprinklerChart.DataCode))) {
            if (this.Side.SprinklerChart.DataCode.Replace(",", ".") !== this.ComputeSprinklerDataCode()) {
                if (this.Side.SprinklerChart.DataCode.Replace(",", ".") === this.ComputeSprinklerDataCodePart1()) {
                }
                else {
                    this.Quote.Warnings.Add(strLanguageText("sltSprinklerSystemParameters"));
                    return false;
                }
            }
        }*/
       // TODO Icon: The WithWarnings check has been removed. Should I have done this?
        // if (WithWarnings) {
            this.AssembleWarnings();
        // }
    }
    private SelectD3000Plate2 = (o: Outlet, selectPlate3: boolean = false): number => {
        let d: D3000 = o.D3000;
        let validate: (number) => number = x => x /*(iterationNbr) => {
let p: Part = null;
if (!(Parts.TryGetValue(ComputePartKey(12, 33, iterationNbr), p))) {
let sb: StringBuilder;
sb.AppendLine(`${NameOf(SelectD3000Plate2)
}: Unable to find device iteration number(${iterationNbr}) in the Parts catalog from criteria - `);
sb.AppendLine(`Outlet( `);
sb.AppendLine(`    OutletNumber(${o.OutletNumber}), Device(${o.Device}), UseNelson3030(${o.UseNelson3030}), NozzleIndex(${o.NozzleIndex})`);
sb.AppendLine(`    , RegulatorType(${o.Regulator.RegulatorType}), Pressure(${o.Regulator.Pressure}), LDNType(${o.LDN.LDNType}), ChemPad(${o.LDN.ChemPad}), Pad(${o.LDN.Pad})`);
sb.AppendLine(`    , BubblerShroud(${o.LDN.BubblerShroud}), Plate1(${o.D3000.Plate1}), Plate2(${o.D3000.Plate2}), Plate3(${o.D3000.Plate3})`);
sb.AppendLine(`    , KometSpray.Pad(${o.KometSpray.Pad}), TrashBusterBody(${o.TrashBusterBody})`);
sb.AppendLine(")");
sb.AppendLine(`BAD Return value: ${iterationNbr}`);
// new Exception()
throw new Error();
}
return iterationNbr;
}
*/;
        const plate: D3000Plates = selectPlate3 ? d.Plate3 : d.Plate2;
        switch (plate) {
            case D3000Plates.None:
                return 0;
            case D3000Plates.Blue:
                return validate(3);
            case D3000Plates.Green:
                switch (d.Plate1) {
                    case D3000Plates.Blue:
                        return 0;
                    default:
                        return validate(6);
                }
            case D3000Plates.Gray:
                return validate(1);
            case D3000Plates.Black:
                return validate(2);
            case D3000Plates.Turqoise:
                return validate(7);
            case D3000Plates.Purple:
                return validate(4);
            case D3000Plates.Orange:
                return validate(9);
            case D3000Plates.Red:
                switch (d.Plate1) {
                    case D3000Plates.Gray:
                    case D3000Plates.Blue:
                        return 0;
                    default:
                        return validate(8);
                }
            case D3000Plates.Brown:
                return validate(10);
            case D3000Plates.Tan:
                return 0;
            case D3000Plates.D4Maroon:
                return validate(22);
            case D3000Plates.D6Gold:
                return validate(21);
        }
    }
    private SelectReg = (i: number, iDeviceQty: number): number => {
        let sGPMDel: number;
        let o = this.Side.SprinklerChart.Outlet[i - 1];
        if (iDeviceQty === 2) {
            sGPMDel = o.GPMDelivered / 2;
        }
        let reg = o.Regulator;
        let regType = reg.RegulatorType;
        if (iDeviceQty === 2) {
            switch (regType) {
                case RegulatorTypes.NelsonBlueSTHF:
                    if (!(((sGPMDel >= 6 && reg.Pressure === RegulatorPressures.a6) || sGPMDel >= 8))) {
                        regType = RegulatorTypes.NelsonBlueSTLF;
                    }
                    break;
                case RegulatorTypes.NelsonBlueFTHF:
                    if (!(((sGPMDel >= 6 && reg.Pressure === RegulatorPressures.a6) || sGPMDel >= 8))) {
                        regType = RegulatorTypes.NelsonBlueFTLF;
                    }
                    break;
                case RegulatorTypes.SenningerMF:
                    if (!(((sGPMDel >= 4.5 && (reg.Pressure === RegulatorPressures.a6 || reg.Pressure === RegulatorPressures.a10)) || sGPMDel >= 6))) {
                        regType = RegulatorTypes.SenningerLF;
                    }
                    break;
            }
        }
        switch (regType) {
            case RegulatorTypes.NelsonBlueSTLF:
                switch (reg.Pressure) {
                    case RegulatorPressures.a6:
                        return 300; //3519381	NELSON BLUE  6# LFST REG
                    case RegulatorPressures.a10:
                        //return 301; //3519382	NELSON BLUE 10# LFST REG
                        //ROE-838 - Swap part number 3519382 to 3519345
                        return 370; //3519345	NELSON ALLFLO 10# FXST
                    case RegulatorPressures.a15:
                        return 371;
                    case RegulatorPressures.a20:
                        return 372;
                    case RegulatorPressures.a25:
                        return 304;
                    case RegulatorPressures.a30:
                        return 305;
                }
                break;
            case RegulatorTypes.NelsonBlueSTHF:
                switch (reg.Pressure) {
                    case RegulatorPressures.a6:
                        return 310;
                    case RegulatorPressures.a10:
                        return 370;
                    case RegulatorPressures.a15:
                        return 371;
                    case RegulatorPressures.a20:
                        return 372;
                    case RegulatorPressures.a25:
                        return 314;
                    case RegulatorPressures.a30:
                        return 315;
                }
                break;
            case RegulatorTypes.NelsonBlueFTLF:
                switch (reg.Pressure) {
                    case RegulatorPressures.a6:
                        return 320;
                    case RegulatorPressures.a10:
                        //return 321; //#3519372
                        return 326; //#3519340
                    case RegulatorPressures.a15:
                        //ROE-875 - Replace Part Number 3519373 with 3519341 in both ROE and RDC
                        //return 322; //#3519373	NELSON BLUE 15# LF REG
                        return 327; //#3519341	NELSON ALLFLO 15# FXF
                    case RegulatorPressures.a20:
                        return 323;
                    case RegulatorPressures.a25:
                        return 324;
                    case RegulatorPressures.a30:
                        return 325;
                    case RegulatorPressures.a40:
                        return 336;
                }
            case RegulatorTypes.NelsonBlueFTHF:
                switch (reg.Pressure) {
                    case RegulatorPressures.a6:
                        //RDC-17 - Swap Regulator Parts ( 3519361 with new 3519339 )
                        //return 330; //3519361	NELSON BLUE 6# HF REG
                        return 337; //3519339	NELSON ALLFLO 6# FXFs
                    case RegulatorPressures.a10:
                        return 331;
                    case RegulatorPressures.a15:
                        return 332;
                    case RegulatorPressures.a20:
                        //RDC - 128 - P/N Suspended - Swap 3519364 with new part 3519342
                        //return 333; //3519364	NELSON BLUE 20# HF REG
                        return 405; //3519342	NELSON ALLFLO 20# FXF
                    case RegulatorPressures.a25:
                        return 334;
                    case RegulatorPressures.a30:
                        return 335;
                    case RegulatorPressures.a40:
                        return 336;
                }
            case RegulatorTypes.SenningerLF:
                switch (reg.Pressure) {
                    case RegulatorPressures.a6:
                        return 340;
                    case RegulatorPressures.a10:
                        return 341;
                    case RegulatorPressures.a15:
                        return 342;
                    case RegulatorPressures.a20:
                        return 343;
                    case RegulatorPressures.a25:
                        return 1;
                    case RegulatorPressures.a30:
                        return 2;
                    case RegulatorPressures.a40:
                        return 3;
                }
            case RegulatorTypes.SenningerMF:
                switch (reg.Pressure) {
                    case RegulatorPressures.a6:
                        return 350;
                    case RegulatorPressures.a10:
                        return 351;
                    case RegulatorPressures.a15:
                        return 352;
                    case RegulatorPressures.a20:
                        return 353;
                    case RegulatorPressures.a25:
                        return 1;
                    case RegulatorPressures.a30:
                        return 2;
                    case RegulatorPressures.a40:
                        return 3;
                }
            case RegulatorTypes.SenningerPSR:
                switch (reg.Pressure) {
                    case RegulatorPressures.a6:
                        return 365;
                    case RegulatorPressures.a10:
                        return 364;
                    case RegulatorPressures.a15:
                        return 366;
                    case RegulatorPressures.a20:
                        return 363;
                }
                break;
            case RegulatorTypes.KometKPR:
                switch (reg.Pressure) {
                    case RegulatorPressures.a6:
                        return 401;
                    case RegulatorPressures.a10:
                        return 402;
                    case RegulatorPressures.a15:
                        return 403;
                    case RegulatorPressures.a20:
                        return 404;
                }
                break;
        }
    }

    /**
     * Select the appropriate Interation number for given criteria.
     * NOTE: if a condition requires the exclusion of a UPipe then returns 0.
     * Should avoid adding a part in this case.
     */
    private SelectRigidDropUPipe = (o: Outlet, currentSpan: Span, previousSpan: Span, requiresFlexGooseneckPipeOnSAC: boolean): number => {
        if (o.RigidDrop.DropMaterial === RigidDropMaterialTypes.Galvanized) return 0;
        if (requiresFlexGooseneckPipeOnSAC && (currentSpan.SwingArm || currentSpan.EndBoom)) return 0;

        switch (o.RigidDrop.UPipeMaterial) {
            case UPipeMaterialTypes.PVC:
                return 1; // 351810	3/4" SCH 80PVC GOOSENECK MXF
            case UPipeMaterialTypes.Galvanized:
                return 2; // 351786	U PIPE-3/4 SCH 40 GALV-M X F
            case UPipeMaterialTypes.BlackPlastic:
                return 3; // 391610	GOOSENECK-3/4 MXM-SGL180-SEN
            case UPipeMaterialTypes.Nelson180:
                return 4; // 391611	GOOSENECK-3/4 MXM-SGL180-NEL
        }
    }

    /**
     * Select the appropriate Interation number for given criteria.
     * NOTE: if a condition requires the exclusion of a Drop Clamp Kit then returns 0.
     * Should avoid adding a part in this case.
     */
    private SelectRigidDropClampKit = (o: Outlet, currentSpan: Span, previousSpan: Span, requiresFlexGooseneckPipeOnSAC: Boolean): number => {
        if (o.RigidDrop.DropMaterial === RigidDropMaterialTypes.Galvanized) return 0;
        if (requiresFlexGooseneckPipeOnSAC && (currentSpan.SwingArm || currentSpan.EndBoom)) return 0;

        if (o.VRIControlled && [RigidDropMaterialTypes.PVC, RigidDropMaterialTypes.Poly].indexOf(o.RigidDrop.DropMaterial) !== -1) {
            if (currentSpan.EndBoom) {
                // This is a special case with varying rules by: previous SpanType, length of EndBoom, and position of outlet
                const distanceToEOS = spanf.EndingLocation(this.Quote.System, this.Side, currentSpan) - o.Location;
                switch (spanf.SpanType(this.Quote.System, this.Side, previousSpan)) {
                    case SpanTypes.E2065:
                    case SpanTypes.E2060:
                    case SpanTypes.E2665:
                    case SpanTypes.E2660:
                    case SpanTypes.S2065G:
                        switch (currentSpan.Length) {
                            case 13:
                            case 23:
                                // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                return 17 // 356863	CLAMP KIT W/O U-PIPE 3"
                            // return 10 // 356893	DROP CLAMP KIT/COUPLER 3"
                            case 32:
                            case 51:
                                if (distanceToEOS <= 10) {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 17 // 356863	CLAMP KIT W/O U-PIPE 3"
                                    // return 10 // 356893	DROP CLAMP KIT/COUPLER 3"
                                } else {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 19 // 356867	CLAMP KIT W/O U-PIPE 6"
                                    // return 12 // 356895	DROP CLAMP KIT/COUPLER 6"
                                }
                            case 42:
                            case 61:
                                if (distanceToEOS <= 20) {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 17 // 356863	CLAMP KIT W/O U-PIPE 3"
                                    // return 10 // 356893	DROP CLAMP KIT/COUPLER 3"
                                } else {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 19 // 356867	CLAMP KIT W/O U-PIPE 6"
                                    // return 12 // 356895	DROP CLAMP KIT/COUPLER 6"
                                }
                            case 85:
                            case 105:
                                // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                return 18 // 356865	CLAMP KIT W/O U-PIPE 4 1/2"
                            // return 11  // 356894	DROP CLAMP KIT/COUPLER 4-1/2"
                        }
                        break;
                    case SpanTypes.A60G:
                    case SpanTypes.AlumIV:
                        switch (currentSpan.Length) {
                            case 13:
                            case 23:
                                // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                return 18 // 356865	CLAMP KIT W/O U-PIPE 4 1/2"
                            // return 11  // 356894	DROP CLAMP KIT/COUPLER 4-1/2"
                            case 32:
                            case 51:
                                if (distanceToEOS <= 10) {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 18 // 356865	CLAMP KIT W/O U-PIPE 4 1/2"
                                    // return 11  // 356894	DROP CLAMP KIT/COUPLER 4-1/2"
                                } else {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 19 // 356867	CLAMP KIT W/O U-PIPE 6"
                                    // return 12 // 356895	DROP CLAMP KIT/COUPLER 6"
                                }
                            case 42:
                                if (distanceToEOS <= 20) {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 18 // 356865	CLAMP KIT W/O U-PIPE 4 1/2"
                                    // return 11  // 356894	DROP CLAMP KIT/COUPLER 4-1/2"
                                } else {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 19 // 356867	CLAMP KIT W/O U-PIPE 6"
                                    // return 12 // 356895	DROP CLAMP KIT/COUPLER 6"
                                }
                        }
                        break;
                    case SpanTypes.PL2065G:
                        switch (currentSpan.Length) {
                            case 23:
                            case 42:
                                // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                return 20 // 356869	CLAMP KIT W/O U-PIPE 6 5/8"
                            // return 13 // 356896	DROP CLAMP KIT/COUPLER 6-5/8"
                        }
                        break;
                    case SpanTypes.E2045:
                        switch (currentSpan.Length) {
                            case 13:
                            case 23:
                                // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                return 17 // 356863	CLAMP KIT W/O U-PIPE 3"
                            // return 10 // 356893	DROP CLAMP KIT/COUPLER 3"
                            case 32:
                            case 51:
                                if (distanceToEOS <= 10) {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 17 // 356863	CLAMP KIT W/O U-PIPE 3"
                                    // return 10 // 356893	DROP CLAMP KIT/COUPLER 3"
                                } else {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 18 // 356865	CLAMP KIT W/O U-PIPE 4 1/2"
                                    // return 11  // 356894	DROP CLAMP KIT/COUPLER 4-1/2"
                                }
                            case 42:
                            case 61:
                                if (distanceToEOS <= 20) {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 17 // 356863	CLAMP KIT W/O U-PIPE 3"
                                    // return 10 // 356893	DROP CLAMP KIT/COUPLER 3"
                                } else {
                                    // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                                    return 18 // 356865	CLAMP KIT W/O U-PIPE 4 1/2"
                                    // return 11  // 356894	DROP CLAMP KIT/COUPLER 4-1/2"
                                }
                        }
                        break;
                }
            } else {
                switch (spanf.SpanType(this.Quote.System, this.Side, currentSpan)) {
                    case SpanTypes.E2045:
                        // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                        return 18 // 356865	CLAMP KIT W/O U-PIPE 4 1/2"
                    // return 11  // 356894	DROP CLAMP KIT/COUPLER 4-1/2"
                    case SpanTypes.E2060:
                    case SpanTypes.E2660:
                    case SpanTypes.A60G:
                    case SpanTypes.AlumIV:
                        // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                        return 19 // 356867	CLAMP KIT W/O U-PIPE 6"
                    // return 12 // 356895	DROP CLAMP KIT/COUPLER 6"
                    case SpanTypes.E2065:
                    case SpanTypes.E2665:
                    case SpanTypes.S2065G:
                    case SpanTypes.PL2065G:
                        // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                        return 20 // 356869	CLAMP KIT W/O U-PIPE 6 5/8"
                    // return 13 // 356896	DROP CLAMP KIT/COUPLER 6-5/8"
                    case SpanTypes.A80G:
                        // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                        return 21 // 356871	CLAMP KIT W/O U-PIPE 8"
                    // return 14 // 356897	DROP CLAMP KIT/COUPLER 8"
                    case SpanTypes.E2085:
                    case SpanTypes.PL2085G:
                        // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                        return 22 // 356873	CLAMP KIT W/O U-PIPE 8 5/8"
                    // return 15 // 356898	DROP CLAMP KIT/COUPLER 8-5/8"
                    case SpanTypes.E2100:
                        // ROE-824 - U-Pipes for ESAC and VRI - Replace PN with Clamp Kit
                        return 23 // 356875	CLAMP KIT W/O U-PIPE 10"
                    // return 16 // 356899	DROP CLAMP KIT/COUPLER 10"
                    case SpanTypes.EndBoom:
                    // Resolved outside of this block.
                }
            }
        }
        return 0;
    }

    private SelectRDDropBottomFitting = (i: number): number => {
        switch (this.Side.SprinklerChart.Outlet[i - 1].RigidDrop.BottomFitting) {
            case RigidDropBottomFittings.Gray:
                return 1;
            case RigidDropBottomFittings.Black:
                return 2;
            case RigidDropBottomFittings.Galvanized:
                return 3;
        }
    }
    private CountHDFittings = (outletNumber: number, iDeviceQty: number, HDFittings: number[]) => {
        let bRegUsedOnTop: boolean;
        let bRegUsedOnBottom: boolean;
        let bForceMaleFitting: boolean;
        let o: Outlet = this.Side.SprinklerChart.Outlet[outletNumber - 1];
        bRegUsedOnTop = o.Regulator.RegulatorType !== RegulatorTypes.None && o.HoseDrop.RegulatorLocation === RegulatorLocations.Top;
        bRegUsedOnBottom = o.Regulator.RegulatorType !== RegulatorTypes.None && o.HoseDrop.RegulatorLocation === RegulatorLocations.Bottom;
        switch (o.Device) {
            case DeviceTypes.SenningerQuadSpray:
                bForceMaleFitting = true;
                break;
            default:
                bForceMaleFitting = false;
                break;
        }
        if (bRegUsedOnTop) {
            if (o.HoseDrop.UPipeFitting === UPipeFittings.MxF) {
                HDFittings[this.HDFittingCloseGalv - 1] += 1;
            }
        }
        if (o.Regulator.RegulatorType !== RegulatorTypes.None) {
            if (o.Device === DeviceTypes.SenningerQuadSpray) {
                HDFittings[this.HDFittingCloseBlack - 1] += 1;
            }
        }
        switch (o.HoseDrop.HoseTopFitting) {
            case HoseTopFittings.FxHBBlack:
                HDFittings[this.HDFittingFxHBBlack - 1] += iDeviceQty;
                break;
            case HoseTopFittings.MxHBGray:
                switch (o.HoseDrop.UPipeFitting) {
                    case UPipeFittings.MxF:
                        HDFittings[this.HDFittingMxHBGray - 1] += iDeviceQty;
                        break;
                    default:
                        if (bRegUsedOnTop) {
                            HDFittings[this.HDFittingMxHBGray - 1] += iDeviceQty;
                        }
                        else {
                            HDFittings[this.HDFittingFxHBBlack - 1] += iDeviceQty;
                        }
                        break;
                }
                break;
            case HoseTopFittings.MxHBBlack:
                switch (o.HoseDrop.UPipeFitting) {
                    case UPipeFittings.MxF:
                        HDFittings[this.HDFittingMxHBBlack - 1] += iDeviceQty;
                        break;
                    default:
                        if (bRegUsedOnTop) {
                            HDFittings[this.HDFittingMxHBBlack - 1] += iDeviceQty;
                        }
                        else {
                            HDFittings[this.HDFittingFxHBBlack - 1] += iDeviceQty;
                        }
                        break;
                }
                break;
        }
        switch (o.HoseDrop.WeightTopFitting) {
            case WeightTopFittings.FxHBBlack:
                HDFittings[this.HDFittingFxHBBlack - 1] += iDeviceQty;
                break;
            case WeightTopFittings.MxHBGray:
                switch (o.HoseDrop.Weight) {
                    case HoseDropWeights.FxF24Poly:
                        HDFittings[this.HDFittingMxHBGray - 1] += iDeviceQty;
                        break;
                    default:
                        if (bRegUsedOnBottom) {
                            HDFittings[this.HDFittingMxHBGray - 1] += iDeviceQty;
                        }
                        else {
                            HDFittings[this.HDFittingFxHBBlack - 1] += iDeviceQty;
                        }
                        break;
                }
                break;
            case WeightTopFittings.MxHBBlack:
                switch (o.HoseDrop.Weight) {
                    case HoseDropWeights.FxF24Poly:
                        HDFittings[this.HDFittingMxHBBlack - 1] += iDeviceQty;
                        break;
                    default:
                        if (bRegUsedOnBottom) {
                            HDFittings[this.HDFittingMxHBBlack - 1] += iDeviceQty;
                        }
                        else {
                            HDFittings[this.HDFittingFxHBBlack - 1] += iDeviceQty;
                        }
                        break;
                }
                break;
        }
        switch (o.HoseDrop.WeightBottomFitting) {
            case WeightBottomFittings.FxHBBlack:
                if (o.HoseDrop.SubstituteSTxHB) {
                    HDFittings[this.HDFittingSTxHBBlack - 1] += iDeviceQty;
                }
                else {
                    HDFittings[this.HDFittingFxHBBlack - 1] += iDeviceQty;
                }
                break;
            case WeightBottomFittings.MxHBGray:
                if (bRegUsedOnBottom || bForceMaleFitting) {
                    HDFittings[this.HDFittingMxHBGray - 1] += iDeviceQty;
                }
                else {
                    if (o.HoseDrop.SubstituteSTxHB) {
                        HDFittings[this.HDFittingSTxHBBlack - 1] += iDeviceQty;
                    }
                    else {
                        HDFittings[this.HDFittingFxHBBlack - 1] += iDeviceQty;
                    }
                }
                break;
            case WeightBottomFittings.MxHBBlack:
                if (bRegUsedOnBottom || bForceMaleFitting) {
                    HDFittings[this.HDFittingMxHBBlack - 1] += iDeviceQty;
                }
                else {
                    if (o.HoseDrop.SubstituteSTxHB) {
                        HDFittings[this.HDFittingSTxHBBlack - 1] += iDeviceQty;
                    }
                    else {
                        HDFittings[this.HDFittingFxHBBlack - 1] += iDeviceQty;
                    }
                }
                break;
            case WeightBottomFittings.CloseGray:
                if (bRegUsedOnBottom || bForceMaleFitting) {
                    HDFittings[this.HDFittingCloseGray - 1] += iDeviceQty;
                }
                break;
            case WeightBottomFittings.CloseBlack:
                if (bRegUsedOnBottom || bForceMaleFitting) {
                    HDFittings[this.HDFittingCloseBlack - 1] += iDeviceQty;
                }
                break;
            case WeightBottomFittings.CloseGalv:
                if (bRegUsedOnBottom || bForceMaleFitting) {
                    HDFittings[this.HDFittingCloseGalv - 1] += iDeviceQty;
                }
                break;
            case WeightBottomFittings.CouplingGray:
                if (!((bRegUsedOnBottom || bForceMaleFitting))) {
                    HDFittings[this.HDFittingCouplingGray - 1] += iDeviceQty;
                }
                break;
            case WeightBottomFittings.CouplingBlack:
                if (!((bRegUsedOnBottom || bForceMaleFitting))) {
                    HDFittings[this.HDFittingCouplingBlack - 1] += iDeviceQty;
                }
                break;
            case WeightBottomFittings.CouplingGalv:
                if (!((bRegUsedOnBottom || bForceMaleFitting))) {
                    HDFittings[this.HDFittingCouplingGalv - 1] += iDeviceQty;
                }
                break;
        }
    }
    private SelectHDDropWeight = (i: number): number => {
        let hd = this.Side.SprinklerChart.Outlet[i - 1].HoseDrop;
        switch (hd.Weight) {
            case HoseDropWeights.MxM18Galv:
                return 4;
            case HoseDropWeights.MxHB18Galv:
                return 6;
            case HoseDropWeights.MxHB24Galv:
                return 0;
            case HoseDropWeights.SlipOver30Poly:
                return 8;
            case HoseDropWeights.FxF24Poly:
                return 9;
            case HoseDropWeights.SlimMxHB:
                return 27;
            case HoseDropWeights.NelsonCoil:
                return 11; // 351598   WEIGHT-COIL 1 LB NELSON 
        }
    }
    private SelectHDDragAdapter = (i: number): number => {
        switch (this.Side.SprinklerChart.Outlet[i - 1].Device) {
            case DeviceTypes.NelsonD3000Spray:
            case DeviceTypes.NelsonD3000FCNSpray:
                return 1;
            case DeviceTypes.SenningerLDNSpray:
                return 2;
            case DeviceTypes.SenningerSuperSpray:
            case DeviceTypes.KometSpray:
                return 3;
        }
    }
    private SelectValve = (i: number, bStdBoomBack: boolean): number => {
        let o = this.Side.SprinklerChart.Outlet[i - 1];
        switch (o.Valve) {
            case OutletValveTypes.Brass:
                if (!(bStdBoomBack) && o.Drop !== DropTypes.None) {
                    return 1;
                }
                else if (o.Regulator.RegulatorType === RegulatorTypes.None) {
                    return 2;
                }
                else {
                    return 3;
                }
            case OutletValveTypes.PlasticLever:
                return 4;
            case OutletValveTypes.PlasticKnob:
                return 5;
        }
    }
/*private DetectPOErrors = () => {
let LocalPO: PartsAssembler;
LocalPO.SpecialBOM = new BillOfMaterials();
this.bDropGroundClearanceProblem = false;
this.WritePO(LocalPO);
}
*/public WritePO = (PO: PartsAssembler, RunForDualSprinklerPackage: boolean = false) => {
        let iCur: number;
        let sHDStiffHoseReg = 0;
        let sHDStiffHoseBlue = 0;
        let sHDStiffHoseEdpm = 0;
        let sFirstTBBLoc: number;
        let sLastTBBLoc: number;
        let iESPAquaValves = 0;
        let iWASAquaValves = 0;
        let iPCD3000Qty = 0;
        let iPCD3030Qty = 0;
        let iPCKometSprayQty = 0;
        let iPCKometTwisterQty = 0;
        let iPCS3000Qty = 0;
        let iPCR3000BlackQty = 0;
        let iPCR3000WhiteQty = 0;
        let iPCR3000TanQty = 0;
        let iPCS3030Qty = 0;
        let iPCR3030BlackQty = 0;
        let iPCR3030WhiteQty = 0;
        let iPCR3030TanQty = 0;
        let iPCLDNQty = 0;
        let iPCFanQty = 0;
        let sMDSWorkspace: number;
        let sMaxDeviceSpacing: number;
        let sMinGroundClearance: number = 99;
        let sLastDeviceLocation: number;
        let iLastDeviceSpan: number;
        let bOrderedEndBoomBackValve: boolean;
        let HDFittings: number[] = [];
        let iLastSpan: number = sidef.NumberOfSpans(this.Side);
        let BoomBacks: BoomBacks = this.Side.Sprinklers.BoomBacks;
        let bHoseDrops: boolean;
        let sOD: number;
        let iDeviceQty = 0;
        let iJointQty = 0;
        let iExtraPCs = 0;
        let iPlasticPlugs = 0;
        let iSpanCount: number = sidef.Spans(this.Quote.System, this.Side).Count;
        let iTowerCount: number;
        let iOutletCount: number;
        let currentSpan: Span;
        let previousSpan: Span;
        let iSprinklerCode: number;
        let sError: number;
        let bBlackPlastic125Ordered: boolean;
        let bNoMore: boolean;
        let sOutletLocation: number;
        let iAquaValvesUpToTower = 0;
        let iControlValvesNeeded = 0;
        const sys = this.Quote.System;
        let hasAirCompressor = !!sys.TowerProperties.AirCompressor;
        const uPipePackageOnSACResult = sysf.RequiresUPipePackageOnSAC(sys);
        const hasVRI = sysf.HasVRI(sys);
        if (this.TowersClass.FieldSet.DataValid()) {
            iTowerCount = sidef.Towers(this.Side).Count;
            for (let i: number = 1; i <= iTowerCount; i++
            ) {
                let t: Tower = this.Side.Tower[i - 1];
                if (this.Side.Tower[i - 1].AutomatedValves) {
                    iAquaValvesUpToTower = i;
                    iControlValvesNeeded += 1;
                    if (this.Side.Tower[i - 1].AirCompressor) {
                        hasAirCompressor = true;
                    }
                }
            }
        }

        /** NOTE: The purpose of this block is to mark each Outlet as Controlled by VRI
         * where there's an applicable zone which covers the Outlet. This is for 
         * proper counting of parts for the BOM.
         */
        if (hasVRI) {
            if (sys.SprinklerProperties.VRIZones.Zone.length > 1) {
                let startLocation = 0;
                const outlets = this.Side.SprinklerChart.Outlet;
                const zones = sys.SprinklerProperties.VRIZones.Zone;
                // NOTE: must iterate over ALL zones to keep track of start location AND end location
                // Reset outlets, basically cleaning up previous run data
                for (const o of outlets) {
                    o.VRIControlled = false;
                }
                for (const z of zones) {
                    const controlledOutlets = outlets.filter(o => o.Location >= startLocation && o.Location <= z.EndingLocation);
                    for (const o of controlledOutlets) {
                        o.VRIControlled = true;
                    }
                    startLocation = z.EndingLocation + 0.01
                }
            }
        } else {
            const outlets = this.Side.SprinklerChart.Outlet;
            // Reset outlets, basically cleaning up previous run data
            for (const o of outlets) {
                o.VRIControlled = false;
            }
        }

        let useVRIPolyValves = false;
        if (hasVRI) {
            useVRIPolyValves = sys.SprinklerProperties.SprinklerValveType === SprinklerValveTypes.Poly;
        }
        let hasSAC = sysf.HasSwingArmCorner(sys);
        let useSACPolyValves = false;
        if (hasSAC) {
            useSACPolyValves = sys.Circle.SwingArm.SprinklerValveType === SprinklerValveTypes.Poly;
        }

        let sFirstESPLoc: number;
        let sLastESPLoc: number;
        let sFirstAquaLoc: number;
        let sLastAquaLoc: number;
        let iDeviceWeight: DeviceWeights;
        let sc: SprinklerChart = this.Side.SprinklerChart;
        iOutletCount = ohf.Outlets(sc).Count;
        let iItemsMultiplier: number;
        for (iCur = 1; iCur <= iOutletCount; iCur++
        ) {
            let o = sc.Outlet[iCur - 1];
            if (currentSpan === null || typeof (currentSpan) === "undefined") {
                currentSpan = this.Side.Span[o.SpanNumber - 1];
            }
            else if (currentSpan.SpanNumber !== o.SpanNumber) {
                previousSpan = currentSpan;
                currentSpan = this.Side.Span[o.SpanNumber - 1];
            }
            sOutletLocation = o.Location;
            iItemsMultiplier = 1;
            iDeviceQty = 1;
            if (o.Device === DeviceTypes.None) {
                switch (SpanType(sys, this.Side, currentSpan)) {
                    case SpanTypes.E2100:
                    case SpanTypes.E2085:
                    case SpanTypes.E2065:
                    case SpanTypes.E2060:
                    case SpanTypes.E2045:
                    case SpanTypes.E2665:
                    case SpanTypes.E2660:
                        break;
                    default:
                        if (!(RunForDualSprinklerPackage)) {
                            iPlasticPlugs += 1;
                        }
                        break;
                }
                continue;
            }
            if (o.SpanNumber > 2) {
                if (sLastDeviceLocation > 0) {
                    sMDSWorkspace = sOutletLocation - sLastDeviceLocation;
                    if (iLastDeviceSpan !== o.SpanNumber) {
                        sMDSWorkspace -= 4;
                    }
                    if (sMDSWorkspace > sMaxDeviceSpacing) {
                        sMaxDeviceSpacing = sMDSWorkspace;
                    }
                }
                sLastDeviceLocation = sOutletLocation;
                iLastDeviceSpan = o.SpanNumber;
            }
            let iStdBoomBackQty: number = 0;
            if (BoomBacks.BoomBackType === BoomBackTypes.Standard && !(RunForDualSprinklerPackage)) {
                if (o.SpanNumber >= BoomBacks.FirstTower) {
                    let iBBQtyAfterTower: number = BoomBacks.QtyPerTower / 2;
                    let iBBQtyBeforeTower: number = iBBQtyAfterTower;
                    if (this.Side.Span[o.SpanNumber - 1].SwingArm) {
                        iBBQtyBeforeTower = 0;
                        iBBQtyAfterTower = 0;
                    }
                    if (o.SpanNumber < iSpanCount) {
                        if (iBBQtyBeforeTower > 3 && this.Side.Span[o.SpanNumber + 1 - 1].EndBoom) {
                            iBBQtyBeforeTower = 3;
                        }
                    }
                    else if (this.Side.Span[o.SpanNumber - 1].EndBoom) {
                        if (iBBQtyAfterTower > 2) {
                            iBBQtyAfterTower = 2;
                        }
                        if (o.SpanNumber > 1 && this.Side.Span[o.SpanNumber - 1 - 1].SwingArm) {
                            iBBQtyAfterTower = 0;
                        }
                    }
                    let iDevicesToTower: number = this.DevicesToTower(iCur, false);
                    if (iDevicesToTower > 0 && iDevicesToTower <= iBBQtyBeforeTower) {
                        if (BoomBacks.Valves) {
                            iStdBoomBackQty = 2;
                        }
                        else {
                            iStdBoomBackQty = 1;
                        }
                    }
                    if (iStdBoomBackQty === 0 && o.SpanNumber > BoomBacks.FirstTower) {
                        iDevicesToTower = this.DevicesToTower(iCur, true);
                        if (iDevicesToTower > 0 && iDevicesToTower <= iBBQtyAfterTower) {
                            if (BoomBacks.Valves) {
                                iStdBoomBackQty = 2;
                            }
                            else {
                                iStdBoomBackQty = 1;
                            }
                        }
                    }
                }
            }
            let eos: EndOfSystem = this.Side.EndOfSystem;
            let eg: EndGun = eos.EndGun;
            let useAltEndGunPart = currentSpan.EndBoom && eg.EndGunTypePrimary !== EndGunTypes.None && spanf.EndingLocation(this.Quote.System, this.Side, currentSpan) - sOutletLocation <= 10;
            let useAltTowerPart = !(useAltEndGunPart) && (sOutletLocation - spanf.StartingLocation(this.Quote.System, this.Side, currentSpan) <= 20 || spanf.EndingLocation(this.Quote.System, this.Side, currentSpan) - sOutletLocation <= 20);
            let isSenningerImpact = devext.Manufacturer(o.Device) === SprinklerManufacturerTypes.Senninger && [DeviceTypes.Senninger12MediumSpacingPlasticImpact, DeviceTypes.Senninger6DoubleRedVanePlasticImpact, DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact].indexOf(o.Device) !== -1;
            let hasBoosterPump = [BoosterPumpTypes.TwoHP, BoosterPumpTypes.FiveHP].indexOf(eos.EndGun.BoosterPump) !== -1;
            let OverrideDeviceOn48InchDrop: (number) => number = (originalDevice) => {
                switch (originalDevice) {
                    case 181:
                        return 182;
                    case 1181:
                        return 1182;
                    case 3:
                        return 183;
                    case 1003:
                        return 1183;
                    case 13:
                        return 1;
                    case 1013:
                        return 1001;
                    case 1015:
                        return 1016;
                    case 26:
                        return 21;
                    case 27:
                        return 5;
                    default:
                        return originalDevice;
                }
            }
                ;
            let AddPartsFor48InchDropByEndGun: (Outlet, PartsAssembler) => void = (o2, PO2) => {
                let qty: number = o2.Regulator.RegulatorType === RegulatorTypes.None ? 2 : 1;
                switch (devext.Manufacturer(o2.Device)) {
                    case SprinklerManufacturerTypes.Komet:
                    case SprinklerManufacturerTypes.Nelson:
                        PO2.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391611", 1); // GOOSENECK-3/4 MXM-SGL 180-NEL
                        PO2.InsertPart(schema.groups.Sprinklers_RigidDropBottomFitting.groupId, "351823", qty); // 3/4-INCH PLASTIC COUPLING-BLK
                        PO2.InsertPart(schema.groups.Sprinklers_RigidDrops.groupId, "351872", 1); // DRP-3/4X48 BLK PLAS WO/U-PIPE
                        break;
                    case SprinklerManufacturerTypes.Senninger:
                        PO2.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391610", 1); // GOOSENECK-3/4 MXM-SGL 180-SEN
                        PO2.InsertPart(schema.groups.Sprinklers_RigidDropBottomFitting.groupId, "351823", qty); // 3/4-INCH PLASTIC COUPLING-BLK
                        PO2.InsertPart(schema.groups.Sprinklers_RigidDrops.groupId, "351872", 1); // DRP-3/4X48 BLK PLAS WO/U-PIPE
                        break;
                }
            }
                ;
            if (iStdBoomBackQty > 0) {
                switch (BoomBacks.Device) {
                    case BoomBackDevices.D3000:
                        if (o.UseNelson3030) {
                            iPCD3030Qty += iStdBoomBackQty;
                        }
                        else {
                            iPCD3000Qty += iStdBoomBackQty;
                        }
                        break;
                    case BoomBackDevices.S3000:
                        if (DeviceNozzle(o.Device).Nozzles(o.NozzleIndex).Nozzle1Size <= 0.4) {
                            if (o.UseNelson3030) {
                                iPCS3030Qty += iStdBoomBackQty;
                            }
                            else {
                                iPCS3000Qty += iStdBoomBackQty;
                            }
                        }
                        else if (true) {
                            if (o.UseNelson3030) {
                                iPCR3030BlackQty += iStdBoomBackQty;
                            }
                            else {
                                iPCR3000BlackQty += iStdBoomBackQty;
                            }
                        }
                        break;
                    case BoomBackDevices.R3000:
                        if (DeviceNozzle(o.Device).Nozzles(o.NozzleIndex).Nozzle1Size <= 0.23) {
                            if (o.UseNelson3030) {
                                iPCR3030WhiteQty += iStdBoomBackQty;
                            }
                            else {
                                iPCR3000WhiteQty += iStdBoomBackQty;
                            }
                        }
                        else if (DeviceNozzle(o.Device).Nozzles(o.NozzleIndex).Nozzle1Size <= 0.39) {
                            if (o.UseNelson3030) {
                                iPCR3030TanQty += iStdBoomBackQty;
                            }
                            else {
                                iPCR3000TanQty += iStdBoomBackQty;
                            }
                        }
                        else if (true) {
                            if (o.UseNelson3030) {
                                iPCR3030BlackQty += iStdBoomBackQty;
                            }
                            else {
                                iPCR3000BlackQty += iStdBoomBackQty;
                            }
                        }
                        break;
                    case BoomBackDevices.LDN:
                        iPCLDNQty += iStdBoomBackQty;
                        break;
                    case BoomBackDevices.Fan:
                        iPCFanQty += iStdBoomBackQty;
                        break;
                    case BoomBackDevices.Komet:
                        iPCKometSprayQty += iStdBoomBackQty;
                        break;
                    case BoomBackDevices.KometTwisterPartCircle:
                        iPCKometTwisterQty += iStdBoomBackQty;
                        break;
                }
                sOD = spanf.PipeOutsideDiameter(this.Side.Span[o.SpanNumber - 1]);
                if (iStdBoomBackQty === 2) {
                    iDeviceQty = 2;
                    if (bOrderedEndBoomBackValve) {
                        PO.InsertPart(schema.groups.Sprinklers_BoomBackValvesAndControls.groupId, "355245", 1); // ASY BOOMBACK VALVE
                    }
                    else {
                        PO.InsertPart(schema.groups.Sprinklers_BoomBackValvesAndControls.groupId, "355255", 1); // ASY BOOMBACK VALVE-END
                        bOrderedEndBoomBackValve = true;
                    }
                    if (sOD <= 3) {
                        PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351721", 1); // BOOMBACK-3"PIPE-14'-OH VALVE
                    }
                    else if (sOD <= 4.5) {
                        PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351722", 1); // BOOMBACK-4.5"PIPE-14'-OH VALVE
                    }
                    else if (sOD <= 6) {
                        // ROE-843 - Replace P/N 351723 with P/N 351724
                        // PO.InsertRecord(POSection, POBoomBacks, 14, 1) '351723	BOOMBACK-6"PIPE-14'-OH VALVE
                        PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351724", 1);  // BOOMBK-6&6 5/8"PIPE-14'-OH VAL
                    }
                    else if (sOD <= 6.625) {
                        PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351724", 1); // BOOMBK-6&6 5/8"PIPE-14'-OH VAL
                    }
                    else if (sOD <= 8) {
                        PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351725", 1); // BOOMBACK-8/8-5/8"-14'-OH VALVE
                    }
                    else if (sOD <= 8.625) {
                        PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351725", 1); // BOOMBACK-8/8-5/8"-14'-OH VALVE
                    }
                    else if (true) {
                        PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351726", 1); // BOOMBACK-10"PIPE-14'-OH VALVE
                    }
                }
                else {
                    switch (BoomBacks.PipeMaterial) {
                        case PipeMaterialTypes.Galvanized:
                            if (sOD <= 3) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351850", 1); // BOOM BACK-W/3" CLMP-14'
                            }
                            else if (sOD <= 4.5) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351800", 1); // BOOM BACK-W/4 1/2" CLMP-14'
                            }
                            else if (sOD <= 6) {
                                // ROE-815 - Old BoomBack Suspended (Swap Part Number 351801 ---> 351802)
                                // PO.InsertRecord(POSection, POBoomBacks, 2, 1) '351801	BOOM BACK-W/6" CLMP-14'
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351802", 1);  // BOOM BACK-W/6&6 5/8" CLMP-14'
                            }
                            else if (sOD <= 6.625) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351802", 1); // BOOM BACK-W/6&6 5/8" CLMP-14'
                            }
                            else if (sOD <= 8) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351803", 1); // BOOM BACK-W/8/8-5/8" CLMP-14'
                            }
                            else if (sOD <= 8.625) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351803", 1); // BOOM BACK-W/8/8-5/8" CLMP-14'
                            }
                            else if (true) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351804", 1); // BOOM BACK-W/10" CLMP-14'
                            }
                            break;
                        case PipeMaterialTypes.PVC:
                            if (sOD <= 3) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351841", 1); // BOOM BACK PVC-W/3" CLAMP
                            }
                            else if (sOD <= 4.5) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351842", 1); // BOOM BACK PVC-W/4 1/2" CLAMP
                            }
                            else if (sOD <= 6) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351843", 1); // BOOM BACK PVC-W/6" CLAMP
                            }
                            else if (sOD <= 6.625) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351839", 1); // BOOM BACK PVC-W/6 5/8" CLAMP
                            }
                            else if (sOD <= 8) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351840", 1); // BOOM BACK PVC-W/8/8-5/8 CLAMP
                            }
                            else if (sOD <= 8.625) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351840", 1); // BOOM BACK PVC-W/8/8-5/8 CLAMP
                            }
                            else if (true) {
                                PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351846", 1); // BOOM BACK PVC-W/10" CLAMP
                            }
                            break;
                    }
                }
            }
            else if (o.IsTowerBoomBackOutlet) {
                if (RunForDualSprinklerPackage) {
                    continue;
                }
                else {
                    iDeviceQty = 2;
                    if (spanf.PipeType(this.Quote.System, this.Side, this.Side.Span[o.SpanNumber - 1]) === SpanPipeTypes.Poly) {
                        iExtraPCs = 2;
                    }
                    else {
                        iExtraPCs = 3;
                    }
                    if (o.SpanNumber < iSpanCount) {
                        if (spanf.PipeType(this.Quote.System, this.Side, this.Side.Span[o.SpanNumber + 1 - 1]) !== SpanPipeTypes.Poly) {
                            iExtraPCs += 1;
                        }
                    }
                    switch (o.Device) {
                        case DeviceTypes.NelsonD3000PartCircle:
                            if (o.UseNelson3030) {
                                iPCD3030Qty += iExtraPCs;
                            }
                            else {
                                iPCD3000Qty += iExtraPCs;
                            }
                            break;
                        case DeviceTypes.KometSprayPartCircle:
                            iPCKometSprayQty += iExtraPCs;
                            break;
                        case DeviceTypes.KometTwisterPartCircle:
                            iPCKometTwisterQty += iExtraPCs;
                            break;
                        case DeviceTypes.NelsonS3000PartCircle:
                            if (DeviceNozzle(o.Device).Nozzles(o.NozzleIndex).Nozzle1Size <= 0.4) {
                                if (o.UseNelson3030) {
                                    iPCS3030Qty += iExtraPCs;
                                }
                                else {
                                    iPCS3000Qty += iExtraPCs;
                                }
                            }
                            else if (true) {
                                if (o.UseNelson3030) {
                                    iPCR3030BlackQty += iExtraPCs;
                                }
                                else {
                                    iPCR3000BlackQty += iExtraPCs;
                                }
                            }
                            break;
                        case DeviceTypes.NelsonR3000PartCircle:
                            if (DeviceNozzle(o.Device).Nozzles(o.NozzleIndex).Nozzle1Size <= 0.23) {
                                if (o.UseNelson3030) {
                                    iPCR3030WhiteQty += iExtraPCs;
                                }
                                else {
                                    iPCR3000WhiteQty += iExtraPCs;
                                }
                            }
                            else if (DeviceNozzle(o.Device).Nozzles(o.NozzleIndex).Nozzle1Size <= 0.39) {
                                if (o.UseNelson3030) {
                                    iPCR3030TanQty += iExtraPCs;
                                }
                                else {
                                    iPCR3000TanQty += iExtraPCs;
                                }
                            }
                            else if (true) {
                                if (o.UseNelson3030) {
                                    iPCR3030BlackQty += iExtraPCs;
                                }
                                else {
                                    iPCR3000BlackQty += iExtraPCs;
                                }
                            }
                            break;
                        case DeviceTypes.SenningerLDNSpray:
                            iPCLDNQty += iExtraPCs;
                            break;
                        case DeviceTypes.Senninger180Fan:
                            iPCFanQty += iExtraPCs;
                            break;
                    }
                    let l: number;
                    if (o.SpanNumber > iTowerCount) {
                        l = iTowerCount;
                    }
                    else {
                        l = o.SpanNumber;
                    }
                    switch (this.Side.Tower[l - 1].TowerType) {
                        case TowerHeights.Sugargator:
                            switch (BoomBacks.PipeMaterial) {
                                case PipeMaterialTypes.Galvanized:
                                    PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351708", 1); // TWR LEG BOOMBACK-GALV-SUGAR
                                    break;
                                case PipeMaterialTypes.PVC:
                                    PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351707", 1); // TWR LEG BOOMBACK-PVC-SUGAR
                                    break;
                            }
                            break;
                        default:
                            switch (spanf.SpanType(this.Quote.System, this.Side, this.Side.Span[o.SpanNumber - 1])) {
                                case SpanTypes.AlumIV:
                                    switch (BoomBacks.PipeMaterial) {
                                        case PipeMaterialTypes.Galvanized:
                                            PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351716", 1); // TWR LEG BOOMBACK-GALV A4
                                            break;
                                        case PipeMaterialTypes.PVC:
                                            PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351715", 1); // TWR LEG BOOMBACK-PVC A4
                                            break;
                                    }
                                    break;
                                default:
                                    switch (BoomBacks.PipeMaterial) {
                                        case PipeMaterialTypes.Galvanized:
                                            PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351712", 1); // BOOMBACK-TOWER-LEG-GALV
                                            break;
                                        case PipeMaterialTypes.PVC:
                                            PO.InsertPart(schema.groups.Sprinklers_BoomBacks.groupId, "351710", 1); // BOOMBACK-TOWER-LEG-PVC
                                            break;
                                    }
                                    break;
                            }
                            break;
                    }
                    if (BoomBacks.Valves) {
                        PO.InsertPart(schema.groups.Sprinklers_BoomBackValvesAndControls.groupId, "355315", 1); // TOWER LEG BOOMBACK VALUE ASY
                    }
                }
            }
            else {
                if (o.Drop === DropTypes.Hose) {
                    if (o.HoseDrop.UPipeType === UPipeTypes.BlackPlasticDouble125) {
                        if (o.DeviceDoubled) {
                            iDeviceQty = 2;
                        }
                        else {
                            if (!((currentSpan.EndBoom || sOutletLocation - spanf.StartingLocation(this.Quote.System, this.Side, currentSpan) <= 25 || spanf.EndingLocation(this.Quote.System, this.Side, currentSpan) - sOutletLocation <= 25))) {
                                if (o.GPMDelivered > 4) {
                                    iDeviceQty = 2;
                                }
                            }
                        }
                    }
                }
                iSprinklerCode = SelectSprinkler(this.Side.SprinklerChart.Outlet[iCur - 1], hasSAC, iOutletCount);
                if (useAltEndGunPart && o.Fitting.DropNextToEndGun) {
                    iSprinklerCode = OverrideDeviceOn48InchDrop(iSprinklerCode);
                }
                PO.InsertPartByIteration(schema.groups.Sprinklers_SprinklerDevices.groupId, iSprinklerCode, iDeviceQty * iItemsMultiplier);
                iJointQty += iDeviceQty * iItemsMultiplier;
            }
            if (o.Regulator.RegulatorType === RegulatorTypes.None) {
                if (useAltEndGunPart) {
                    if (o.Fitting.DropNextToEndGun) {
                        AddPartsFor48InchDropByEndGun(o, PO);
                    }
                    else {
                        switch (o.Fitting.FittingType) {
                            case FittingTypes.None:
                            case FittingTypes.Close:
                                break;
                            case FittingTypes.Galvanized:
                                if (isSenningerImpact) {
                                    if (iSprinklerCode === 164) {
                                        PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352303", 1); // RISER 3/4 X 10 MXF GALV
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352304", 1); // RISER 3/4 X 18 MXF GALV
                                    }
                                }
                                else {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352304", 1); // RISER 3/4 X 18 MXF GALV
                                }
                                break;
                            case FittingTypes.StainlessSteel:
                                if (isSenningerImpact) {
                                    if (iSprinklerCode === 164) {
                                        PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352303SS", 1); // RISER 3/4 X 10 MXF SS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352304SS", 1); // RISER 3/4 X 18 MXF SS
                                    }
                                }
                                else {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352304SS", 1); // RISER 3/4 X 18 MXF SS
                                }
                                break;
                        }
                    }
                }
                else if (useAltTowerPart) {
                    switch (o.Fitting.FittingType) {
                        case FittingTypes.None:
                        case FittingTypes.Close:
                            break;
                        case FittingTypes.Galvanized:
                            if (isSenningerImpact) {
                                if ([104, 105, 164, 280].indexOf(iSprinklerCode) === -1) {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352303", 1); // RISER 3/4 X 10 MXF GALV
                                }
                            }
                            else {
                                PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352304", 1); // RISER 3/4 X 18 MXF GALV
                            }
                            break;
                        case FittingTypes.StainlessSteel:
                            if (isSenningerImpact) {
                                if ([104, 105, 164, 280].indexOf(iSprinklerCode) === -1) {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352303SS", 1); // RISER 3/4 X 10 MXF SS
                                }
                            }
                            else {
                                PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352304SS", 1); // RISER 3/4 X 18 MXF SS
                            }
                            break;
                    }
                }
                else {
                    if (isSenningerImpact) {
                        if ([104, 105, 164, 280].indexOf(iSprinklerCode) === -1) {
                            switch (o.Fitting.FittingType) {
                                case FittingTypes.None:
                                case FittingTypes.Close:
                                    break;
                                case FittingTypes.Galvanized:
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "358319", 1); // RISER 3/4" X 3-1/2" MXF
                                    break;
                                case FittingTypes.StainlessSteel:
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "358319SS", 1); // RISER 3/4" X 3-1/2" MXF SS
                                    break;
                            }
                        }
                    }
                }
            }
            else {
                if (o.Drop === DropTypes.None) {
                    switch (o.Fitting.FittingType) {
                        case FittingTypes.None:
                            break;
                        case FittingTypes.Close:
                            if (sidef.Spans(this.Quote.System, this.Side).AnyStainless() || sidef.Spans(this.Quote.System, this.Side).AnyAluminum()) {
                                if (useAltEndGunPart) {
                                    if (o.Fitting.DropNextToEndGun) {
                                        AddPartsFor48InchDropByEndGun(o, PO);
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351794SS", 1); // 18" DROP-3/4 SCH 40 SS-M X M
                                    }
                                }
                                else if (useAltTowerPart) {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352305SS", 1); // RISER 3/4 X 10 MXM SS
                                }
                                else {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351820", 1); // 3/4-INCH CLOSE NIPPLE-NELSON
                                }
                            }
                            else {
                                if (useAltEndGunPart) {
                                    if (o.Fitting.DropNextToEndGun) {
                                        AddPartsFor48InchDropByEndGun(o, PO);
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351794", 1); // 18" DROP-3/4 SCH 40 GALV-M X M
                                    }
                                }
                                else if (useAltTowerPart) {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352305", 1); // RISER 3/4 X 10 MXM GALV
                                }
                                else {
                                    switch (devext.Manufacturer(o.Device)) {
                                        case SprinklerManufacturerTypes.Komet:
                                        case SprinklerManufacturerTypes.Nelson:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351820", 1); // 3/4-INCH CLOSE NIPPLE-NELSON
                                            break;
                                        case SprinklerManufacturerTypes.Senninger:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351836", 1); // 3/4-INCH CLOSE NIPPLE-BLK PLAS
                                            break;
                                    }
                                }
                            }
                            break;
                        case FittingTypes.Galvanized:
                            if (useAltEndGunPart) {
                                if (o.Fitting.DropNextToEndGun) {
                                    AddPartsFor48InchDropByEndGun(o, PO);
                                }
                                else {
                                    if (isSenningerImpact) {
                                        PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351794", 1); // 18" DROP-3/4 SCH 40 GALV-M X M
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351794", 1); // 18" DROP-3/4 SCH 40 GALV-M X M
                                    }
                                }
                            }
                            else if (useAltTowerPart) {
                                if (isSenningerImpact) {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352301", 1); // RISER 3/4 X 4 MXM GALV
                                }
                                else {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352305", 1); // RISER 3/4 X 10 MXM GALV
                                }
                            }
                            else {
                                if (isSenningerImpact) {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352300", 1); // RISER 3/4 X 2-1/2 MXM GALV
                                }
                                else {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352300", 1); // RISER 3/4 X 2-1/2 MXM GALV
                                }
                            }
                            break;
                        case FittingTypes.StainlessSteel:
                            if (useAltEndGunPart) {
                                if (o.Fitting.DropNextToEndGun) {
                                    AddPartsFor48InchDropByEndGun(o, PO);
                                }
                                else {
                                    if (isSenningerImpact) {
                                        PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351794SS", 1); // 18" DROP-3/4 SCH 40 SS-M X M
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351794SS", 1); // 18" DROP-3/4 SCH 40 SS-M X M
                                    }
                                }
                            }
                            else if (useAltTowerPart) {
                                if (isSenningerImpact) {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352301SS", 1); // RISER 3/4 X 4 MXM SS
                                }
                                else {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352305SS", 1); // RISER 3/4 X 10 MXM SS
                                }
                            }
                            else {
                                if (isSenningerImpact) {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352300SS", 1); // RISER 3/4 X 2-1/2 MXM SS
                                }
                                else {
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "352300SS", 1); // RISER 3/4 X 2-1/2 MXM SS
                                }
                            }
                            break;
                    }
                }
            }
            switch (o.Regulator.RegulatorType) {
                case RegulatorTypes.None:
                    break;
                case RegulatorTypes.SenningerLF:
                    if (o.Device !== DeviceTypes.SenningerQuadSpray) {
                        PO.InsertPartByIteration(schema.groups.Sprinklers_Regulators.groupId, this.SelectReg(iCur, iDeviceQty), iDeviceQty * iItemsMultiplier);
                    }
                    break;
                default:
                    PO.InsertPartByIteration(schema.groups.Sprinklers_Regulators.groupId, this.SelectReg(iCur, iDeviceQty), iDeviceQty * iItemsMultiplier);
                    break;
            }
            switch (o.NozzleClip) {
                case NozzleClipTypes.Dual:
                    switch (SprinklerBrand(o.Device)) {
                        case SprinklerManufacturerTypes.Nelson:
                            if (o.UseNelson3030) {
                                PO.InsertPart(schema.groups.Sprinklers_DualTripleNozzleClip.groupId, "3501479", iDeviceQty * iItemsMultiplier); // DUAL NOZZLE CLIP W/1 3NV NOZ
                            }
                            else {
                                PO.InsertPart(schema.groups.Sprinklers_DualTripleNozzleClip.groupId, "352183", iDeviceQty * iItemsMultiplier); // DUAL NOZZLE CLIP-W/1 3TN NOZ
                            }
                            break;
                        case SprinklerManufacturerTypes.Senninger:
                            PO.InsertPart(schema.groups.Sprinklers_DualTripleNozzleClip.groupId, "3501341", iDeviceQty * iItemsMultiplier); // DUAL NOZZLE W/1 UP3 NOZZLE
                            break;
                        case SprinklerManufacturerTypes.Komet:
                            PO.InsertPart(schema.groups.Sprinklers_DualTripleNozzleClip.groupId, "3501365", iDeviceQty * iItemsMultiplier); // KOMET KFRN DUAL NOZZLE
                            break;
                    }
                    break;
                case NozzleClipTypes.Triple:
                    switch (SprinklerBrand(o.Device)) {
                        case SprinklerManufacturerTypes.Senninger:
                            PO.InsertPart(schema.groups.Sprinklers_DualTripleNozzleClip.groupId, "3501342", iDeviceQty * iItemsMultiplier); // TRIPLE NOZZLE W/2 UP3 NOZZLE
                            break;
                        default:
                            PO.InsertPart(schema.groups.Sprinklers_DualTripleNozzleClip.groupId, "352181", iDeviceQty * iItemsMultiplier); // TRIPLE NOZZLE CLIP W/2 3TN NOZ
                            break;
                    }
                    break;
            }
            if (o.IsTowerBoomBackOutlet) {
                continue;
            }
            if (o.Valve !== OutletValveTypes.None) {
                PO.InsertPartByIteration(schema.groups.Sprinklers_Valves.groupId, this.SelectValve(iCur, iStdBoomBackQty > 0), iDeviceQty * iItemsMultiplier);
            }
            if (iStdBoomBackQty > 0) {
                continue;
            }
            switch (o.Device) {
                case DeviceTypes.NelsonD3000Spray:
                    let itrNumber: number = this.SelectD3000Plate2(o);
                    if (itrNumber > 0) {
                        PO.InsertPartByIteration(schema.groups.Sprinklers_D3000ExtraPlate.groupId, itrNumber, iDeviceQty * iItemsMultiplier);
                    }
                    itrNumber = this.SelectD3000Plate2(o, true);
                    if (itrNumber > 0) {
                        PO.InsertPartByIteration(schema.groups.Sprinklers_D3000ExtraPlate.groupId, itrNumber, iDeviceQty * iItemsMultiplier);
                    }
                    if (o.D3000.SprinklerConverter) {
                        PO.InsertPart(schema.groups.Sprinklers_D3000ExtraPlate.groupId, "3501511", iDeviceQty * iItemsMultiplier); // NELSON-CONVERTER #11472
                        if (o.D3000.Plate1 === D3000Plates.Tan && [D3000Plates.None, D3000Plates.D4Maroon, D3000Plates.D6Gold].indexOf(o.D3000.Plate2) === -1 && [D3000Plates.D4Maroon, D3000Plates.D6Gold].indexOf(o.D3000.Plate3) === -1) {
                            PO.InsertPart(schema.groups.Sprinklers_D3000ExtraPlate.groupId, "3501588", iDeviceQty * iItemsMultiplier); // CAP ONLY NELSON-D3030
                        }
                    }
                    if (o.D3000.BubblerClipLEPA) {
                        PO.InsertPart(schema.groups.Sprinklers_D3000ExtraPlate.groupId, "351942", iDeviceQty * iItemsMultiplier); // CLIP, BUBBLER D3000-NEL#10577
                    }
                    break;
                case DeviceTypes.NelsonD3000FCNSpray:
                case DeviceTypes.NelsonR3000FCNRotatorBlue:
                case DeviceTypes.NelsonR3000FCNRotatorGreen:
                    PO.InsertPart(schema.groups.Sprinklers_3000FlowControlNozzle.groupId, "3501545", iDeviceQty * iItemsMultiplier); // NELSON 3000FC
                    break;
            }
            switch (o.Drop) {
                case DropTypes.Hose:
                    iDeviceWeight = o.HoseDrop.DeviceWeight;
                    break;
                case DropTypes.Rigid:
                    iDeviceWeight = o.RigidDrop.DeviceWeight;
                    break;
            }
            if (!(iDeviceWeight === DeviceWeights.None)) {
                let i: DeviceTypes = o.Device;
                switch (SprinklerBrand(i)) {
                    case SprinklerManufacturerTypes.Nelson:
                        switch (iDeviceWeight) {
                            // @ts-ignore
                            case DeviceWeights.None:
                                break;
                            case DeviceWeights.OnePound:
                                if (devext.IsNelsonSpinner(i) || devext.IsNelsonSpinnerLowPressure(i) || devext.IsNelsonRotator(i) || devext.IsNelsonAccelerator(i) || devext.IsNelsonSpray(i)) {
                                    if (o.UseNelson3030) {
                                        if (o.Regulator.RegulatorType !== RegulatorTypes.None) {
                                            PO.InsertPart(schema.groups.Sprinklers_DeviceWeight.groupId, "351590", iDeviceQty * iItemsMultiplier); // WEIGHT-DROP 1 LB NELSON
                                        }
                                        else {
                                        }
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.Sprinklers_DeviceWeight.groupId, "351590", iDeviceQty * iItemsMultiplier); // WEIGHT-DROP 1 LB NELSON
                                    }
                                }
                                break;
                            case DeviceWeights.Magnum:
                                break;
                        }
                        break;
                    case SprinklerManufacturerTypes.Senninger:
                        if (iDeviceWeight === DeviceWeights.OnePound) {
                            if (devext.IsSenningerIWob(i) || devext.IsSenningerXiWob(i) || devext.IsSenningerSuperSpray(i) || devext.IsSenningerLDNSpray(i)) {
                                PO.InsertPart(schema.groups.Sprinklers_DeviceWeight.groupId, "3501195", iDeviceQty * iItemsMultiplier); // SENN ONE WEIGHT
                            }
                        }
                        if (iDeviceWeight === DeviceWeights.Magnum) {
                            PO.InsertPart(schema.groups.Sprinklers_DeviceWeight.groupId, "351597", iDeviceQty * iItemsMultiplier); // UNIV MAG WEIGHT SENN UNIMAGWGT
                        }
                        break;
                    case SprinklerManufacturerTypes.Komet:
                        if (devext.IsKometSpray(i)) {
                            if (iDeviceWeight === DeviceWeights.OnePound) {
                                PO.InsertPart(schema.groups.Sprinklers_DeviceWeight.groupId, "351595", iDeviceQty * iItemsMultiplier); // WEIGHT-DROP .85 LB NEL SLIM
                            }
                        }
                        else {
                        }
                        break;
                }
            }
            for (let j: number = 0; j <= this.HDFittingMax; j++) {
                if (HDFittings.length < j) {
                    HDFittings.push(0);
                } else {
                    HDFittings[j] = 0;
                }
            }
            switch (o.Drop) {
                case DropTypes.Rigid:
                    PO.InsertPartByIteration(schema.groups.Sprinklers_RigidDrops.groupId, this.SelectRigidDrop(iCur), iItemsMultiplier);
                    let itrx = this.SelectRigidDropUPipe(o, currentSpan, previousSpan, uPipePackageOnSACResult.Required)
                    if (itrx > 0) {
                        PO.InsertPartByIteration(schema.groups.Sprinklers_RigidDropUPipe.groupId, itrx, iItemsMultiplier); // Dynamic PN Selection
                    }
                    itrx = this.SelectRigidDropClampKit(o, currentSpan, previousSpan, uPipePackageOnSACResult.Required)
                    if (itrx > 0){ 
                        PO.InsertPartByIteration(schema.groups.Sprinklers_RigidDropUPipe.groupId, itrx, iItemsMultiplier); // Dynamic PN Selection
                    }

                    iJointQty += iItemsMultiplier;
                    if (o.Regulator.RegulatorType === RegulatorTypes.None) {
                        PO.InsertPartByIteration(schema.groups.Sprinklers_RigidDropBottomFitting.groupId, this.SelectRDDropBottomFitting(iCur), iItemsMultiplier);
                    }
                    if (this.Side.SprinklerChart.Outlet[iCur - 1].RigidDrop.UPipeMaterial === UPipeMaterialTypes.BlackPlastic || this.Side.SprinklerChart.Outlet[iCur - 1].RigidDrop.UPipeMaterial === UPipeMaterialTypes.Nelson180) {
                        PO.InsertPart(schema.groups.Sprinklers_RigidDropBottomFitting.groupId, "351823", iItemsMultiplier); // 3/4-INCH PLASTIC COUPLING-BLK
                    }
                    if (hasSAC && (o.IsSACOutlet || o.IsEndBoomOutlet)) {
                        if (!uPipePackageOnSACResult.Required) { // NOTE: should fall into this block and then suppress adding part for this condition
                            if (o.IsEndBoomOutlet) {
                                // This is a special case with varying rules by: previous SpanType, length of EndBoom, and position of outlet
                                const distanceToEOS = spanf.EndingLocation(this.Quote.System, this.Side, currentSpan) - o.Location;
                                const spanLength = currentSpan.Length;

                                switch (spanLength) {
                                    case 51:
                                        if (distanceToEOS <= 10) {
                                            PO.InsertPart(schema.groups.Sprinklers_RigidDropReinforcementClamp.groupId, "351867", iItemsMultiplier);  // REINF CLAMP-7FT GALV DROP 3"
                                        } else {
                                            PO.InsertPart(schema.groups.Sprinklers_RigidDropReinforcementClamp.groupId, "351828", iItemsMultiplier);  // REINF CLAMP-7FT DROP 6/6-5/8
                                        }
                                        break;
                                    case 61:
                                        if (distanceToEOS <= 20) {
                                            PO.InsertPart(schema.groups.Sprinklers_RigidDropReinforcementClamp.groupId, "351867", iItemsMultiplier);  // REINF CLAMP-7FT GALV DROP 3"
                                        } else {
                                            PO.InsertPart(schema.groups.Sprinklers_RigidDropReinforcementClamp.groupId, "351828", iItemsMultiplier);  // REINF CLAMP-7FT DROP 6/6-5/8
                                        }
                                        break;
                                    case 85:
                                        PO.InsertPart(schema.groups.Sprinklers_RigidDropReinforcementClamp.groupId, "351868", iItemsMultiplier);  // REINF CLAMP-7FT GALV DROP 4-1/2"
                                        break;
                                    case 105:
                                        PO.InsertPart(schema.groups.Sprinklers_RigidDropReinforcementClamp.groupId, "351868", iItemsMultiplier);  // REINF CLAMP-7FT GALV DROP 4-1/2"
                                        break;
                                }
                            } else {
                                PO.InsertPart(schema.groups.Sprinklers_RigidDropReinforcementClamp.groupId, "351828", iItemsMultiplier);  // REINF CLAMP-7FT DROP 6/6-5/8
                            }
                        }
                    } else {
                        // NOTE: suppress adding part for VRI Controlled Outlet having drop material requiring Flex Gooseneck UPipe.
                        if (o.RigidDrop.ReinforcementClamp && this.RigidDropToFeet(this.OutletRigidDropLengthAsEnum(iCur, sError)) > 6.5 &&
                            !(o.VRIControlled && [RigidDropMaterialTypes.PVC, RigidDropMaterialTypes.Poly].indexOf(o.RigidDrop.DropMaterial) !== -1)) {
                            PO.InsertPart(schema.groups.Sprinklers_RigidDropReinforcementClamp.groupId, "351828", iItemsMultiplier);  // REINF CLAMP-7FT DROP 6/6-5/8
                        }
                    }
                    if (o.RigidDrop.GroundClearance < sMinGroundClearance) {
                        sMinGroundClearance = o.RigidDrop.GroundClearance;
                    }
                    break;
                case DropTypes.Hose:
                    bHoseDrops = true;
                    bBlackPlastic125Ordered = false;
                    let hd = o.HoseDrop;
                    switch (hd.UPipeReach) {
                        case UPipeReaches.Standard:
                            switch (hd.UPipeType) {
                                case UPipeTypes.Galv:
                                    switch (hd.UPipeFitting) {
                                        case UPipeFittings.MxF:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351786", iItemsMultiplier); // U PIPE-3/4 SCH 40 GALV-M X F
                                            break;
                                        case UPipeFittings.MxHB:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351791", iItemsMultiplier); // U PIPE-3/4 SCH 40 GALV-M X HB
                                            break;
                                        case UPipeFittings.MxM:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351785", iItemsMultiplier); // U PIPE-3/4 SCH 40 GALV-M X M
                                            break;
                                    }
                                    break;
                                case UPipeTypes.PVC:
                                    switch (hd.UPipeFitting) {
                                        case UPipeFittings.MxF:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351810", iItemsMultiplier); // 3/4" SCH 80PVC GOOSENECK MXF
                                            break;
                                        case UPipeFittings.MxHB:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351838", iItemsMultiplier); // GOOSENECK SCH80 M X HB PVC
                                            break;
                                        case UPipeFittings.MxM:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351811", iItemsMultiplier); // GOOSENECK-3/4 MXM 0.154W PVC
                                            break;
                                    }
                                    break;
                                case UPipeTypes.BlackPlasticSingle180:
                                    switch (hd.UPipeFitting) {
                                        case UPipeFittings.MxHB:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391609", iItemsMultiplier); // GOOSENECK-3/4MNPTX3/4B-SGL-180
                                            break;
                                        case UPipeFittings.MxM:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391610", iItemsMultiplier); // GOOSENECK-3/4 MXM-SGL 180-SEN
                                            break;
                                    }
                                    break;
                                case UPipeTypes.Nelson180:
                                    switch (hd.UPipeFitting) {
                                        case UPipeFittings.MxHB:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391612", iItemsMultiplier); // GOOSENCK 3/4MNPT X BARB-SNG180
                                            break;
                                        case UPipeFittings.MxM:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391611", iItemsMultiplier); // GOOSENECK-3/4 MXM-SGL 180-NEL
                                            break;
                                    }
                                    break;
                                case UPipeTypes.BlackPlasticSingle125:
                                    if (currentSpan.EndBoom || sOutletLocation - spanf.StartingLocation(this.Quote.System, this.Side, currentSpan) <= 25 || spanf.EndingLocation(this.Quote.System, this.Side, currentSpan) - sOutletLocation <= 25) {
                                        switch (hd.UPipeFitting) {
                                            case UPipeFittings.MxHB:
                                                PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391609", iItemsMultiplier); // GOOSENECK-3/4MNPTX3/4B-SGL-180
                                                break;
                                            case UPipeFittings.MxM:
                                                PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391610", iItemsMultiplier); // GOOSENECK-3/4 MXM-SGL 180-SEN
                                                break;
                                        }
                                    }
                                    else {
                                        switch (hd.UPipeFitting) {
                                            case UPipeFittings.MxHB:
                                                PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391607", iItemsMultiplier); // GOOSENECK-3/4MNPTX3/4B-SGL-125
                                                break;
                                            case UPipeFittings.MxM:
                                                PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391608", iItemsMultiplier); // GOOSENCK3/4MNPTX3/4MNPT-SGL125
                                                break;
                                        }
                                        bBlackPlastic125Ordered = true;
                                    }
                                    break;
                                case UPipeTypes.BlackPlasticDouble125:
                                    if (iDeviceQty === 2) {
                                        switch (hd.UPipeFitting) {
                                            case UPipeFittings.MxHB:
                                                PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351912", iItemsMultiplier); // GOOSENCK3/4MNPT X 3/4B-DBL125
                                                break;
                                            case UPipeFittings.MxM:
                                                PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351913", iItemsMultiplier); // GOOSENCK3/4MNPTX3/4MNPT-DBL125
                                                break;
                                        }
                                        bBlackPlastic125Ordered = !(currentSpan.EndBoom);
                                    }
                                    else if (currentSpan.EndBoom || sOutletLocation - spanf.StartingLocation(this.Quote.System, this.Side, currentSpan) <= 25 || spanf.EndingLocation(this.Quote.System, this.Side, currentSpan) - sOutletLocation <= 25) {
                                        switch (hd.UPipeFitting) {
                                            case UPipeFittings.MxHB:
                                                PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391609", iItemsMultiplier); // GOOSENECK-3/4MNPTX3/4B-SGL-180
                                                break;
                                            case UPipeFittings.MxM:
                                                PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391610", iItemsMultiplier); // GOOSENECK-3/4 MXM-SGL 180-SEN
                                                break;
                                        }
                                    }
                                    else {
                                        switch (hd.UPipeFitting) {
                                            case UPipeFittings.MxHB:
                                                PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391607", iItemsMultiplier); // GOOSENECK-3/4MNPTX3/4B-SGL-125
                                                break;
                                            case UPipeFittings.MxM:
                                                PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "391608", iItemsMultiplier); // GOOSENCK3/4MNPTX3/4MNPT-SGL125
                                                break;
                                        }
                                        bBlackPlastic125Ordered = true;
                                    }
                                    break;
                            }
                            break;
                        case UPipeReaches.a12Inch:
                            switch (hd.UPipeType) {
                                case UPipeTypes.Alum:
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351809", iItemsMultiplier); // 12-FURROW ARM-3/4 MXM ALUM
                                    break;
                                case UPipeTypes.Galv:
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351792", iItemsMultiplier); // 12" FURROW ARM-3/4 SCH40G-MXHB
                                    break;
                                case UPipeTypes.PVC:
                                    PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351812", iItemsMultiplier); // FURROW ARM-12 MXHB 0.154W PVC
                                    break;
                            }
                            break;
                        case UPipeReaches.a20Inch:
                            PO.InsertPart(schema.groups.Sprinklers_HoseDropUPipe.groupId, "351793", iItemsMultiplier); // 20" FURROW ARM-3/4 SCH40G-MXHB
                            break;
                    }
                    iJointQty += iItemsMultiplier;
                    if (bBlackPlastic125Ordered) {
                        if (hd.UseKometTrussRodSlings) {
                            if (currentSpan.SwingArm) {
                                PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351917", iDeviceQty * iItemsMultiplier); // SLING-HOSE-3/4" TR ROD KOMET
                            }
                            else if (currentSpan.LargeTrussRods) {
                                PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351917", iDeviceQty * iItemsMultiplier); // SLING-HOSE-3/4" TR ROD KOMET
                            }
                            else {
                                switch (spanf.SpanType(this.Quote.System, this.Side, currentSpan)) {
                                    case SpanTypes.E2100:
                                    case SpanTypes.E2085:
                                    case SpanTypes.S2085G:
                                    case SpanTypes.PL2085G:
                                    case SpanTypes.A80G:
                                    case SpanTypes.A60G:
                                        PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351917", iDeviceQty * iItemsMultiplier); // SLING-HOSE-3/4" TR ROD KOMET
                                        break;
                                    case SpanTypes.E2065:
                                    case SpanTypes.E2665:
                                    case SpanTypes.S2065G:
                                    case SpanTypes.PL2065G:
                                        if (currentSpan.Length >= 175) {
                                            PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351917", iDeviceQty * iItemsMultiplier); // SLING-HOSE-3/4" TR ROD KOMET
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351915", iDeviceQty * iItemsMultiplier); // SLING-HOSE-5/8" TR ROD KOMET
                                        }
                                        break;
                                    case SpanTypes.E2060:
                                    case SpanTypes.E2660:
                                        let isLastSpan: boolean = currentSpan.SpanNumber === sidef.Spans(this.Quote.System, this.Side).Count && !(currentSpan.EndBoom);
                                        let lengthThreshold: number = isLastSpan ? 181 : 180;
                                        if (currentSpan.Length >= lengthThreshold) {
                                            PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351917", iDeviceQty * iItemsMultiplier); // SLING-HOSE-3/4" TR ROD KOMET
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351915", iDeviceQty * iItemsMultiplier); // SLING-HOSE-5/8" TR ROD KOMET
                                        }
                                        break;
                                    case SpanTypes.AlumIV:
                                        PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351915", iDeviceQty * iItemsMultiplier); // SLING-HOSE-5/8" TR ROD KOMET
                                        break;
                                }
                            }
                        }
                        else {
                            if (currentSpan.SwingArm) {
                                PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351911", iDeviceQty * iItemsMultiplier); // SLING-HOSE-3/4" TR ROD SENN
                            }
                            else if (currentSpan.LargeTrussRods) {
                                PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351911", iDeviceQty * iItemsMultiplier); // SLING-HOSE-3/4" TR ROD SENN
                            }
                            else {
                                switch (spanf.SpanType(this.Quote.System, this.Side, currentSpan)) {
                                    case SpanTypes.E2100:
                                    case SpanTypes.E2085:
                                    case SpanTypes.S2085G:
                                    case SpanTypes.PL2085G:
                                    case SpanTypes.A80G:
                                    case SpanTypes.A60G:
                                        PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351911", iDeviceQty * iItemsMultiplier); // SLING-HOSE-3/4" TR ROD SENN
                                        break;
                                    case SpanTypes.E2065:
                                    case SpanTypes.E2665:
                                    case SpanTypes.S2065G:
                                    case SpanTypes.PL2065G:
                                        if (currentSpan.Length >= 175) {
                                            PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351911", iDeviceQty * iItemsMultiplier); // SLING-HOSE-3/4" TR ROD SENN
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351910", iDeviceQty * iItemsMultiplier); // SLING-HOSE-5/8" TR ROD SENN
                                        }
                                        break;
                                    case SpanTypes.E2060:
                                    case SpanTypes.E2660:
                                        let isLastSpan: boolean = currentSpan.SpanNumber === sidef.Spans(this.Quote.System, this.Side).Count && !(currentSpan.EndBoom);
                                        let lengthThreshold: number = isLastSpan ? 181 : 180;
                                        if (currentSpan.Length >= lengthThreshold) {
                                            PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351911", iDeviceQty * iItemsMultiplier); // SLING-HOSE-3/4" TR ROD SENN
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351910", iDeviceQty * iItemsMultiplier); // SLING-HOSE-5/8" TR ROD SENN
                                        }
                                        break;
                                    case SpanTypes.AlumIV:
                                        PO.InsertPart(schema.groups.Sprinklers_HoseSling.groupId, "351910", iDeviceQty * iItemsMultiplier); // SLING-HOSE-5/8" TR ROD SENN
                                        break;
                                }
                            }
                        }
                    }
                    this.CountHDFittings(iCur, iDeviceQty, HDFittings);
                    for (let j: number = 1; j <= this.HDFittingMax; j++
                    ) {
                        let iQty: number = HDFittings[j - 1] * iItemsMultiplier;
                        if (iQty > 0) {
                            switch (j) {
                                case this.HDFittingFxHBBlack:
                                    switch (o.Device) {
                                        case DeviceTypes.SenningerLowAngleIWobWhite:
                                        case DeviceTypes.SenningerLowAngleIWobBlue:
                                        case DeviceTypes.SenningerStandardAngleIWobGray:
                                        case DeviceTypes.SenningerHighAngleIWobBlack:
                                        case DeviceTypes.SenningerXiWobBlack:
                                        case DeviceTypes.SenningerXiWobWhite:
                                        case DeviceTypes.SenningerXiWobBlue:
                                        case DeviceTypes.SenningerXiWobGray:
                                        case DeviceTypes.SenningerLDNSpray:
                                        case DeviceTypes.SenningerXcelWobbler:
                                        case DeviceTypes.SenningerQuadSpray:
                                        case DeviceTypes.SenningerSuperSpray:
                                        case DeviceTypes.KometTwisterBlack:
                                        case DeviceTypes.KometTwisterBlue:
                                        case DeviceTypes.KometTwisterYellow:
                                        case DeviceTypes.KometTwisterWhite:
                                        case DeviceTypes.KometSpray:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351799", iQty); // ADAPTER-FEM X HOSE BARB-BLACK
                                            break;
                                        default:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351848", iQty); // ADAPTER F X HB-NELSON
                                            break;
                                    }
                                    break;
                                case this.HDFittingMxHBBlack:
                                    switch (o.Device) {
                                        case DeviceTypes.SenningerLowAngleIWobWhite:
                                        case DeviceTypes.SenningerLowAngleIWobBlue:
                                        case DeviceTypes.SenningerStandardAngleIWobGray:
                                        case DeviceTypes.SenningerHighAngleIWobBlack:
                                        case DeviceTypes.SenningerXiWobBlack:
                                        case DeviceTypes.SenningerXiWobWhite:
                                        case DeviceTypes.SenningerXiWobBlue:
                                        case DeviceTypes.SenningerXiWobGray:
                                        case DeviceTypes.SenningerLDNSpray:
                                        case DeviceTypes.SenningerXcelWobbler:
                                        case DeviceTypes.SenningerQuadSpray:
                                        case DeviceTypes.SenningerSuperSpray:
                                        case DeviceTypes.KometTwisterBlack:
                                        case DeviceTypes.KometTwisterBlue:
                                        case DeviceTypes.KometTwisterYellow:
                                        case DeviceTypes.KometTwisterWhite:
                                        case DeviceTypes.KometSpray:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351798", iQty); // ADAPTER-MALE X HOSE BARB-BLACK
                                            break;
                                        default:
                                            PO.InsertPart(schema.groups.Sprinklers_HoseDropFittings.groupId, "351847", iQty); // ADAPTER M X HB-NELSON
                                            break;
                                    }
                                    break;
                                default:
                                    PO.InsertPartByIteration(schema.groups.Sprinklers_HoseDropFittings.groupId, j, iQty);
                                    break;
                            }
                        }
                    }

                    // Count HoseDrop hose clamps - formerly: CountHDHoseClamps()
                    if (o.IsSACOutlet || o.VRIControlled) { // TEST if the outlet has valves or is on esac
                        switch (hd.HoseTopClamp) {
                            case HoseClamps.Screw:
                                PO.InsertPart(schema.groups.Sprinklers_HoseDropHoseClamps.groupId, "351926", iDeviceQty * iItemsMultiplier);  // SCREW CLAMP FOR 3/4" SURELINE
                                break;
                            case HoseClamps.Pinch:
                                PO.InsertPart(schema.groups.Sprinklers_HoseDropHoseClamps.groupId, "351925", iDeviceQty * iItemsMultiplier);  // PINCH CLAMP FOR 3/4" SURELINE
                                break;
                        }

                        switch (hd.HoseBottomClamp) {
                            case HoseClamps.Screw:
                                PO.InsertPart(schema.groups.Sprinklers_HoseDropHoseClamps.groupId, "351926", iDeviceQty * iItemsMultiplier);  // SCREW CLAMP FOR 3/4" SURELINE
                                break;
                            case HoseClamps.Pinch:
                                PO.InsertPart(schema.groups.Sprinklers_HoseDropHoseClamps.groupId, "351925", iDeviceQty * iItemsMultiplier);  // PINCH CLAMP FOR 3/4" SURELINE
                                break;
                        }
                    } else {
                        switch (hd.HoseTopClamp) {
                            case HoseClamps.Screw:
                                PO.InsertPart(schema.groups.Sprinklers_HoseDropHoseClamps.groupId, "351826", iDeviceQty * iItemsMultiplier);  // SCREW CLAMP FOR 3/4" IPS HOSE
                                break;
                            case HoseClamps.Pinch:
                                PO.InsertPart(schema.groups.Sprinklers_HoseDropHoseClamps.groupId, "351825", iDeviceQty * iItemsMultiplier);  // PINCH CLAMP FOR 3/4" IPS HOSE
                                break;
                        }

                        switch (hd.HoseBottomClamp) {
                            case HoseClamps.Screw:
                                PO.InsertPart(schema.groups.Sprinklers_HoseDropHoseClamps.groupId, "351826", iDeviceQty * iItemsMultiplier);  // SCREW CLAMP FOR 3/4" IPS HOSE
                                break;
                            case HoseClamps.Pinch:
                                PO.InsertPart(schema.groups.Sprinklers_HoseDropHoseClamps.groupId, "351825", iDeviceQty * iItemsMultiplier);  // PINCH CLAMP FOR 3/4" IPS HOSE
                                break;
                        }
                    }

                    let iterNbr = this.SelectHDDropWeight(iCur);
                    if (iterNbr > 0) {
                        PO.InsertPartByIteration(schema.groups.Sprinklers_HoseDropWeight.groupId, iterNbr, iDeviceQty * iItemsMultiplier);
                    }
                    if (hd.ScrewClamp) {
                        PO.InsertPart(schema.groups.Sprinklers_HoseDropSpringClamp.groupId, "351826", iDeviceQty * iItemsMultiplier); // SCREW CLAMP FOR 3/4" IPS HOSE
                    }
                    if (hd.DragAdapter) {
                        PO.InsertPartByIteration(schema.groups.Sprinklers_HoseDropDragAdapter.groupId, this.SelectHDDragAdapter(iCur), iDeviceQty * iItemsMultiplier);
                    }
                    if (hd.DragSock) {
                        PO.InsertPart(schema.groups.Sprinklers_HoseDropDragSock.groupId, "352185", iDeviceQty * iItemsMultiplier); // DRAG SOCK
                    }

                    if (o.IsSACOutlet || o.VRIControlled) { // 'TEST if the outlet has valves or is on esac
                        sHDStiffHoseEdpm += (o.DropLength * iDeviceQty * iItemsMultiplier);
                    } else {
                        if (o.HoseDrop.ReinkeBlue) {
                            sHDStiffHoseBlue += (o.DropLength * iDeviceQty * iItemsMultiplier);
                        }
                        else {
                            sHDStiffHoseReg += (o.DropLength * iDeviceQty * iItemsMultiplier);
                        }
                    }
                    if (o.HoseDrop.GroundClearance < sMinGroundClearance) {
                        sMinGroundClearance = o.HoseDrop.GroundClearance;
                    }
                    break;
            }
            switch (o.Device) {
                case DeviceTypes.NelsonD3000Spray:
                case DeviceTypes.NelsonD3000FCNSpray:
                case DeviceTypes.NelsonS3000SpinnerRed:
                case DeviceTypes.NelsonS3000SpinnerPurple:
                case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
                case DeviceTypes.NelsonS3000SpinnerYellow:
                case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
                case DeviceTypes.NelsonA3000AcceleratorMaroon:
                case DeviceTypes.NelsonA3000AcceleratorGold:
                case DeviceTypes.NelsonR3000RotatorBrown:
                case DeviceTypes.NelsonR3000RotatorOlive:
                case DeviceTypes.NelsonR3000RotatorRed:
                case DeviceTypes.NelsonR3000RotatorOrange:
                case DeviceTypes.NelsonR3000RotatorBlue:
                case DeviceTypes.NelsonR3000RotatorWhite:
                case DeviceTypes.NelsonR3000FCNRotatorBlue:
                case DeviceTypes.NelsonO3000OrbitorBlack:
                case DeviceTypes.NelsonO3000OrbitorBlue:
                case DeviceTypes.NelsonO3000OrbitorPurple:
                case DeviceTypes.NelsonO3030OrbitorFXBlack:
                case DeviceTypes.NelsonO3030OrbitorFXWhite:
                case DeviceTypes.NelsonR3000RotatorGreen:
                case DeviceTypes.NelsonR3000FCNRotatorGreen:
                case DeviceTypes.NelsonA3000AcceleratorNavy:
                case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
                    if (HDFittings[this.HDFittingSTxHBBlack - 1] > 0) {
                        PO.InsertPart(schema.groups.Sprinklers_DeviceAdapter.groupId, "351849", iDeviceQty * iItemsMultiplier); // ADAPTER ST X HB-NELSON
                    }
                    else {
                        if ((o.Regulator.RegulatorType === RegulatorTypes.None || o.Regulator.Thread === ThreadTypes.Female) && !(o.UseNelsonAD3030MT)) {
                            PO.InsertPart(schema.groups.Sprinklers_DeviceAdapter.groupId, "3501510", iDeviceQty * iItemsMultiplier); // NELSON 3000 ADAPTER
                        }
                    }
                    break;
            }
            if (o.ESP) {
                if (sFirstESPLoc === 0) {
                    sFirstESPLoc = o.Location;
                }
                sLastESPLoc = o.Location;
                iESPAquaValves += 1;
            }
            if (o.SpanNumber <= iAquaValvesUpToTower) {
                if (sFirstAquaLoc === 0) {
                    sFirstAquaLoc = o.Location;
                }
                sLastAquaLoc = o.Location;
                iWASAquaValves += 1;
            }
        }
        if (iPlasticPlugs > 0) {
            PO.InsertPart(schema.groups.Sprinklers_SprinklerDevices.groupId, "351845", iPlasticPlugs); // PLUG 3/4" NPT PLASTIC
        }
        if (iPCD3030Qty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350117", iPCD3030Qty); // SPRAY-PART CIRCLE-PCD3030
        }
        if (iPCS3030Qty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350113", iPCS3030Qty); // NELSON PC-S3030 #12650 SPINNER
        }
        if (iPCR3030BlackQty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350114", iPCR3030BlackQty); // NELSON PC-R3030 #12651-001 BLK
        }
        if (iPCR3030WhiteQty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350115", iPCR3030WhiteQty); // NELSON PC-R3030 #12651-002 WHT
        }
        if (iPCR3030TanQty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350116", iPCR3030TanQty); // NELSON PC-R3030 #12651-003 TAN
        }
        if (iPCD3000Qty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350109", iPCD3000Qty); // SPRAY-PART CIRCLE-PCD3000
        }
        if (iPCS3000Qty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350108", iPCS3000Qty); // SPINNER-PART CIRCLE-PCS3000
        }
        if (iPCR3000BlackQty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350110", iPCR3000BlackQty); // CAP/BDY ASY PCR3000B 10843-001
        }
        if (iPCR3000WhiteQty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350111", iPCR3000WhiteQty); // CAP/BDY ASY PCR3000W 10843-002
        }
        if (iPCR3000TanQty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350112", iPCR3000TanQty); // CAP/BDY ASY PCR3000T 10843-003
        }
        if (iPCLDNQty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "3501300", iPCLDNQty); // SENN LDN PC PAD UP3
        }
        if (iPCFanQty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "350107", iPCFanQty); // SPRAY FAN SENN 180
        }
        if (iPCKometSprayQty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "3501364", iPCKometSprayQty); // KOMET PC SPRAY BLUE,CC
        }
        if (iPCKometTwisterQty > 0) {
            PO.InsertPart(schema.groups.Sprinklers_PartialCircles.groupId, "3501354", iPCKometTwisterQty); // KOMET TWISTER PC 180 GREY
        }
        if (BoomBacks.Valves && !(RunForDualSprinklerPackage)) {
            PO.InsertPart(schema.groups.Sprinklers_BoomBackValvesAndControls.groupId, "355243", 1); // ASY-BOOMBACK VALVE CONTROL
        }
        if (sMinGroundClearance < 5) {
            if (sMaxDeviceSpacing >= 9.5) {
                this.bDropGroundClearanceProblem = true;
            }
        }
        else if (sMinGroundClearance < 6) {
            if (sMaxDeviceSpacing >= 14.25) {
                this.bDropGroundClearanceProblem = true;
            }
        }
        else if (sMinGroundClearance < 7) {
            if (sMaxDeviceSpacing >= 19) {
                this.bDropGroundClearanceProblem = true;
            }
        }
        if (sFirstESPLoc > 0) {
            let lESPHose: number = 40 + (sLastESPLoc - sFirstESPLoc) + 2 * iESPAquaValves;
            if (lESPHose > 0) {
                PO.InsertPartByIteration(schema.groups.Sprinklers_ControlLine.groupId, 1, RollsToOrder(lESPHose, 250));
            }
            if (this.Quote.System.Circle.SwingArm.ControlLineBoosterESP || this.Quote.System.Circle.SwingArm.AirCompressor) {
                iESPAquaValves -= 1;
            }
            else {
                iESPAquaValves -= 2;
                if (useSACPolyValves) {
                    PO.InsertPart(schema.groups.Sprinklers_ESPBoostValve.groupId, "351972", 1);  // CONTROL LINE BOOST POLY VLV AS
                } else {
                    PO.InsertPart(schema.groups.Sprinklers_ESPBoostValve.groupId, "351989", 1); // CONTROL LINE BOOST RNK VLV ASY
                }
            }
            if (useSACPolyValves) {
                PO.InsertPart(schema.groups.Sprinklers_ESPStrainerValve.groupId, "352003", 1);  // STRNR/POLY VALVE W/ELBOW
            } else {
                PO.InsertPart(schema.groups.Sprinklers_ESPStrainerValve.groupId, "351986", 1); // STRNR/REINKE VALVE W/ELBOW
            }
            if (iESPAquaValves > 0) {
                if (useSACPolyValves) {
                    PO.InsertPart(schema.groups.Sprinklers_AquamaticValve.groupId, "351997", iESPAquaValves);  // 3/4" POLY VALVE W/TEE
                } else {
                    PO.InsertPart(schema.groups.Sprinklers_AquamaticValve.groupId, "351987", iESPAquaValves);  // 3/4" REINKE VALVE W/TEE
                }
            }
            for (let j: number = 1; j <= iLastSpan; j++
            ) {
                if (spanf.EndingLocation(this.Quote.System, this.Side, this.Side.Span[j - 1]) > sFirstESPLoc) {
                    PO.InsertPart(schema.groups.Sprinklers_ControlLineDrainValve.groupId, "351982", 1); // CONTROL LINE DRAIN VALVE ASSY
                }
            }
        }
        if (sFirstAquaLoc > 0) {
            let lESPHose: number = 40 + (sLastAquaLoc - sFirstAquaLoc) + 2 * iWASAquaValves;
            if (lESPHose > 0) {
                PO.InsertPartByIteration(schema.groups.Sprinklers_ControlLine.groupId, 1, RollsToOrder(lESPHose, 250));
            }
            iWASAquaValves -= iControlValvesNeeded;
            if (iWASAquaValves > 0) {
                PO.InsertPart(schema.groups.Sprinklers_AquamaticValve.groupId, "351980", iWASAquaValves); // 3/4" AQUAMATIC VALVE W/3/8"TEE
            }
            PO.InsertPart(schema.groups.Sprinklers_ControlLineDrainValve.groupId, "351982", iAquaValvesUpToTower); // CONTROL LINE DRAIN VALVE ASSY
            if (hasAirCompressor) {
                PO.InsertPart(schema.groups.Sprinklers_WASControlValve.groupId, "395998", iControlValvesNeeded); // VALVE BOX-DUAL SPRKLR-PIV LAT
            }
            else {
                PO.InsertPart(schema.groups.Sprinklers_WASControlValve.groupId, "355257", iControlValvesNeeded); // WRAP SPAN CONTROL VALVE
            }
        }
        if (BoomBacks.Valves && BoomBacks.BoomBackType !== BoomBackTypes.None && !(RunForDualSprinklerPackage)) {
            sFirstTBBLoc = spanf.EndingLocation(this.Quote.System, this.Side, this.Side.Span[BoomBacks.FirstTower - 1]);
            sLastTBBLoc = spanf.EndingLocation(this.Quote.System, this.Side, this.Side.Span[iLastSpan - 1]);
            PO.InsertPartByIteration(schema.groups.Sprinklers_ControlLine.groupId, 1, RollsToOrder((sLastTBBLoc - sFirstTBBLoc) + (iLastSpan - BoomBacks.FirstTower + 1) * 20, 250));
        }
        if (sHDStiffHoseReg > 0) {
            PO.InsertPartByIteration(schema.groups.Sprinklers_HoseDropHose.groupId, 2, RollsToOrder(sHDStiffHoseReg * 1.05, 250));
        }
        if (sHDStiffHoseBlue > 0) {
            PO.InsertPartByIteration(schema.groups.Sprinklers_HoseDropHose.groupId, 1, RollsToOrder(sHDStiffHoseBlue * 1.05, 250));
        }
        if (sHDStiffHoseEdpm > 0) {
            PO.InsertPartByIteration(schema.groups.Sprinklers_HoseDropHose.groupId, 3, RollsToOrder(sHDStiffHoseEdpm * 1.05, 250)); // 351738B	HOSE-3/4 EPDM SURELINE 250' BL
        }
        if (iJointQty > 0) {
            let iCanQty: number = 1;
            if (this.IsFlangedSide) {
                iJointQty -= 75;
            }
            else {
                iJointQty -= 50;
            }
            if (iJointQty > 0) {
                iCanQty += RollsToOrder(iJointQty, 50);
            }
            PO.InsertPart(schema.groups.Sprinklers_PipeJointCompound.groupId, "351815", iCanQty); // PIPE JNT COMPOUND 4 OZ TRU-BLU
        }
    }
    public Init = (pQuote: QuoteClass, bIsFlangedSide: boolean) => {
        this.IsFlangedSide = bIsFlangedSide;
        this.Quote = pQuote;
    }
    private SelectRigidDrop = (i: number): number => {
        let eDL: number;
        let sError: number;
        let o = this.Side.SprinklerChart.Outlet[i - 1];
        if (o.RigidDrop.DropType === RigidDropTypes.Fixed) {
            eDL = o.RigidDrop.Length * 10;
        }
        else {
            eDL = this.OutletRigidDropLengthAsEnum(i, sError);
        }
        switch (o.RigidDrop.DropMaterial) {
            case RigidDropMaterialTypes.Galvanized:
                switch (eDL) {
                    case 40:
                        return 1;
                    case 45:
                        return 2;
                    case 50:
                        return 3;
                    case 55:
                        return 4;
                    case 60:
                        return 5;
                    case 65:
                        return 6;
                    case 70:
                        return 7;
                    case 75:
                        return 54;
                    case 80:
                        return 55;
                }
                break;
            case RigidDropMaterialTypes.PVC:
                switch (eDL) {
                    case 35:
                        return 8;
                    case 40:
                        return 9;
                    case 45:
                        return 10;
                    case 50:
                        return 11;
                    case 55:
                        return 12;
                    case 60:
                        return 13;
                    case 65:
                        return 14;
                    case 70:
                        return 15;
                    case 75:
                        return 16;
                    case 80:
                        return 17;
                    case 85:
                        return 18;
                    case 90:
                        return 19;
                }
                break;
            case RigidDropMaterialTypes.Poly:
                switch (eDL) {
                    case 30:
                        return 20;
                    case 35:
                        return 21;
                    case 40:
                        return 22;
                    case 45:
                        return 23;
                    case 50:
                        return 24;
                    case 55:
                        return 25;
                    case 60:
                        return 26;
                    case 65:
                        return 27;
                    case 70:
                        return 28;
                    case 75:
                        return 29;
                    case 80:
                        return 30;
                    case 85:
                        return 31;
                    case 90:
                        return 32;
                    case 95:
                        return 51;
                    case 100:
                        return 52;
                    case 105:
                        return 53;
                }
                break;
            case RigidDropMaterialTypes.Combo:
                switch (eDL) {
                    case 40:
                        return 33;
                    case 45:
                        return 34;
                    case 50:
                        return 35;
                    case 55:
                        return 36;
                    case 60:
                        return 37;
                    case 65:
                        return 38;
                    case 70:
                        return 39;
                    case 75:
                        return 40;
                    case 80:
                        return 41;
                    case 85:
                        return 42;
                    case 90:
                        return 43;
                    case 95:
                        return 44;
                    case 100:
                        return 45;
                    case 105:
                        return 46;
                    case 110:
                        return 47;
                    case 115:
                        return 48;
                    case 120:
                        return 49;
                    case 125:
                        return 50;
                }
                break;
            case RigidDropMaterialTypes.ComboPVCHose:
                switch (eDL) {
                    case 35:
                        return 100;
                    case 40:
                        return 101;
                    case 45:
                        return 102;
                    case 50:
                        return 103;
                    case 55:
                        return 104;
                    case 60:
                        return 105;
                    case 65:
                        return 106;
                    case 70:
                        return 107;
                    case 75:
                        return 108;
                    case 80:
                        return 109;
                    case 85:
                        return 110;
                    case 90:
                        return 111;
                    case 95:
                        return 112;
                    case 100:
                        return 113;
                    case 105:
                        return 114;
                    case 110:
                        return 115;
                    case 115:
                        return 116;
                    case 120:
                        return 117;
                    case 125:
                        return 118;
                }
                break;
        }
    }
    private RigidDropToFeet = (e: number): number => {
        switch (e) {
            case 30:
                return 3;
            case 35:
                return 3.5;
            case 40:
                return 4;
            case 45:
                return 4.5;
            case 50:
                return 5;
            case 55:
                return 5.5;
            case 60:
                return 6;
            case 65:
                return 6.5;
            case 70:
                return 7;
            case 75:
                return 7.5;
            case 80:
                return 8;
            case 85:
                return 8.5;
            case 90:
                return 9;
            case 95:
                return 9.5;
            case 100:
                return 10;
            case 105:
                return 10.5;
            case 110:
                return 11;
            case 115:
                return 11.5;
            case 120:
                return 12;
            case 125:
                return 12.5;
        }
    }
    private OutletRigidDropLengthAsEnum = (i: number, sErrorInFeet: number): number => {
        sErrorInFeet = 0;
        let eDL: number;
        let dl: number = this.Side.SprinklerChart.Outlet[i - 1].DropLength - 0.25;
        if (dl <= 3) {
            eDL = 30;
        }
        else if (dl <= 3.5) {
            eDL = 35;
        }
        else if (dl <= 4) {
            eDL = 40;
        }
        else if (dl <= 4.5) {
            eDL = 45;
        }
        else if (dl <= 5) {
            eDL = 50;
        }
        else if (dl <= 5.5) {
            eDL = 55;
        }
        else if (dl <= 6) {
            eDL = 60;
        }
        else if (dl <= 6.5) {
            eDL = 65;
        }
        else if (dl <= 7) {
            eDL = 70;
        }
        else if (dl <= 7.5) {
            eDL = 75;
        }
        else if (dl <= 8) {
            eDL = 80;
        }
        else if (dl <= 8.5) {
            eDL = 85;
        }
        else if (dl <= 9) {
            eDL = 90;
        }
        else if (dl <= 9.5) {
            eDL = 95;
        }
        else if (dl <= 10) {
            eDL = 100;
        }
        else if (dl <= 10.5) {
            eDL = 105;
        }
        else if (dl <= 11) {
            eDL = 110;
        }
        else if (dl <= 11.5) {
            eDL = 115;
        }
        else if (dl <= 12) {
            eDL = 120;
        }
        else if (true) {
            eDL = 125;
        }
        switch (this.Side.SprinklerChart.Outlet[i - 1].RigidDrop.DropMaterial) {
            case RigidDropMaterialTypes.Galvanized:
                switch (eDL) {
                    case 30:
                    case 35:
                        eDL = 40;
                        sErrorInFeet = dl - 4;
                        if (sErrorInFeet > 0) {
                            sErrorInFeet = 0;
                        }
                        break;
                    case 85:
                    case 90:
                    case 95:
                    case 100:
                    case 105:
                    case 110:
                    case 115:
                    case 120:
                    case 125:
                        eDL = 80;
                        sErrorInFeet = dl - 8;
                        if (sErrorInFeet < 0) {
                            sErrorInFeet = 0;
                        }
                        break;
                }
                break;
            case RigidDropMaterialTypes.PVC:
                switch (eDL) {
                    case 30:
                        eDL = 35;
                        sErrorInFeet = dl - 3.5;
                        if (sErrorInFeet > 0) {
                            sErrorInFeet = 0;
                        }
                        break;
                    case 95:
                    case 100:
                    case 105:
                    case 110:
                    case 115:
                    case 120:
                    case 125:
                        eDL = 90;
                        sErrorInFeet = dl - 9;
                        if (sErrorInFeet < 0) {
                            sErrorInFeet = 0;
                        }
                        break;
                }
                break;
            case RigidDropMaterialTypes.Poly:
                switch (eDL) {
                    case 30:
                        sErrorInFeet = dl - 3;
                        if (sErrorInFeet > 0) {
                            sErrorInFeet = 0;
                        }
                        break;
                    case 110:
                    case 115:
                    case 120:
                    case 125:
                        eDL = 105;
                        sErrorInFeet = dl - 10.5;
                        if (sErrorInFeet < 0) {
                            sErrorInFeet = 0;
                        }
                        break;
                }
                break;
            case RigidDropMaterialTypes.Combo:
                switch (eDL) {
                    case 30:
                    case 35:
                        eDL = 40;
                        sErrorInFeet = dl - 4;
                        if (sErrorInFeet > 0) {
                            sErrorInFeet = 0;
                        }
                        break;
                    case 125:
                        sErrorInFeet = dl - 12.5;
                        if (sErrorInFeet <= 0) {
                            sErrorInFeet = 0;
                        }
                        break;
                }
                break;
            case RigidDropMaterialTypes.ComboPVCHose:
                switch (eDL) {
                    case 30:
                        eDL = 35;
                        sErrorInFeet = dl - 3.5;
                        if (sErrorInFeet > 0) {
                            sErrorInFeet = 0;
                        }
                        break;
                    case 125:
                        sErrorInFeet = dl - 12.5;
                        if (sErrorInFeet <= 0) {
                            sErrorInFeet = 0;
                        }
                        break;
                }
                break;
        }
        return eDL;
    }
    private DevicesToTower = (iCur: number, Reverse: boolean): number => {
        let i: number;
        let count: number = 0;
        let TowerFound: boolean;
        let iOutletCount: number;
        let spChart = this.Side.SprinklerChart;
        iOutletCount = ohf.Outlets(spChart).Count;
        if (!(Reverse)) {
            for (i = iCur; i <= iOutletCount; i++
            ) {
                let o = spChart.Outlet[i - 1];
                if (this.Side.Span[o.SpanNumber - 1].EndBoom) {
                    return 0;
                }
                if (o.HookLocation > 0) {
                    TowerFound = true;
                }
                if (o.Device !== DeviceTypes.None) {
                    count += 1;
                }
                if (TowerFound) {
                    return count;
                }
            }
        }
        else {
            for (i = iCur; i >= 1; i += -1) {
                let o = spChart.Outlet[i - 1];
                if (o.HookLocation > 0) {
                    return count;
                }
                if (o.SpanNumber === 1) {
                    return;
                }
                if (o.Device !== DeviceTypes.None) {
                    count += 1;
                }
            }
            return count;
        }
    }
    public SpanPressureLoss = (i: number, GPM: number = 0): number => {
        let c: number;
        let g: number;
        if (GPM === 0) {
            g = this.Side.SprinklerChart.IrrigationProperties.SideGPM;
        }
        else {
            g = GPM;
        }
        c = this.Side.SprinklerChart.IrrigationProperties.CoverageRadiusWithEndGun;
        let sp = this.Side.Span[i - 1];
        return this.SimpsonsRulePressureLoss(g, spanf.StartingLocation(this.Quote.System, this.Side, sp), spanf.EndingLocation(this.Quote.System, this.Side, sp), spanf.StartingRadius(this.Quote.System, this.Side, sp), spanf.EndingRadius(this.Quote.System, this.Side, sp), c, spanf.PipeInsideDiameter(this.Quote.System, this.Side, sp), spanf.CFactor(this.Quote.System, this.Side, sp));
    }
    private SimpsonsRulePressureLoss = (SideGPM: number, PipeStart: number, PipeEnd: number, ArcStart: number, ArcEnd: number, ArcLength: number, InsideDiameter: number, CFactor: number): number => {
        let SystemMotion: SystemMotionEnum;
        if (sysf.IsMaxigator(this.Quote.System)) {
            SystemMotion = SystemMotionEnum.Lateral;
        }
        else {
            SystemMotion = SystemMotionEnum.Pivot;
        }
        return PressureLossSimpsonsRule(SideGPM, PipeStart, PipeEnd, ArcStart, ArcEnd, ArcLength, SystemMotion, CFactor, InsideDiameter);
    }
}
