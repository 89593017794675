{
    "translationEs": {
        "exchange-rate-effective-as-of": "as of",
        "exchange-rate-note": "Prices shown are based on exchange and may vary",
        "exchange-rate-unavailable-note": "Foreign exchange rate information currently unavailable",
        "update-proposal-fex-rate": "Update the current exchange rate information for this proposal",
        "currency": "Currency",
        "gSAC": "Guidance Wheel SAC",
        "gMSAC": "Guidance Wheel MSAC",
        "gSSAC": "Guidance Wheel SSAC",
        "nSAC": "Non-Guidance Wheel SAC",
        "nMSAC": "Non-Guidance Wheel MSAC",
        "nSSAC": "Non-Guidance Wheel SSAC",
        "ebSAC": "End Boom SAC",
        "ebMSAC": "End Boom MSAC",
        "ebSSAC": "End Boom SSAC",
        "upload": "upload",
        "expand": "expand",
        "collapse": "collpase",
        "overlapping-irrigated-area": "overlapping irrigated area",
        "net-irrigated-area": "net irrigated area",
        "by": "por",
        "coverage-area": "coverage area",
        "area": "area",
        "dealer": "distribuidor",
        "customer": "cliente",
        "name": "nombre",
        "total-systems": "total systems",
        "field-details": "field details",
        "system-details": "system details",
        "true": "true",
        "false": "false",
        "filter": "filter",
        "address": "dirección",
        "import-field": "import field",
        "email": "correo electrónico",
        "phone": "número de teléfono",
        "edit": "edit",
        "country": "país",
        "zip": "código postal",
        "general": "general",
        "full": "full",
        "highest": "highest",
        "lowest": "lowest",
        "start": "start",
        "stop": "stop",
        "no-information-available": "no information available",
        "city": "ciudad",
        "state": "estado",
        "grower": "cultivador",
        "growers": "cultivadores",
        "upload-csv": "cargar CSV",
        "please": "por favor",
        "download": "descargue",
        "choose-file": "elegir archivo",
        "no-file-chosen": "ningún archivo elegido",
        "cancel": "cancelar",
        "none": "Ninguno",
        "standard": "Estándar",
        "height-cap": "Altura",
        "after": "después de",
        "clearance": "distancia",
        "feet": "pies",
        "ok": "OK",
        "list": "lista",
        "map": "mapa",
        "description": "descripción",
        "color": "color",
        "and": "y",
        "update": "actualizar",
        "date": "fecha",
        "farm": "granja",
        "change": "cambiar",
        "next": "siguiente",
        "back": "atrás",
        "layout": "diseño",
        "layouts": "diseños",
        "system": "sistema",
        "systems": "sistemas",
        "rename": "renombrar",        
        "no": "no",
        "yes": "sí",
        "save": "guardar",
        "import": "importar",
        "export": "exportar",
        "measure": "medir",
        "annotation": "anotación",
        "annotations": "anotaciones",
        "obstacle": "obstáculo",
        "boundary": "límite",
        "canal": "canal",
        "electric-line": "línea eléctrica",
        "lateral-feed-line": "línea de alimentación lateral",
        "label": "etiqueta",
        "line": "línea",
        "point": "punto",
        "pump": "bomba",
        "water-line": "línea de agua",
        "span": "tramo",
        "spans-and-towers": "tramos y torres",
        "warnings": "warnings",
        "wheel": "rueda",
        "delete": "borrar",
        "span-obstacle": "obstáculo del tramo",
        "wheel-obstacle": "obstáculo de rueda",
        "wet-area-boundary": "límite de la zona húmeda",
        "pivot-center-boundary": "límite del centro de pivote",
        "field-boundary": "límite del campo",
        "equipment": "equipamiento",
        "lateral": "lateral",
        "wet-area": "zona húmeda",
        "pivot-center": "centro pivote",
        "center-pivot": "pivote central",
        "ft": "pies",
        "price": "precio",
        "taxable": "impuestado",
        "tax": "impuesto",
        "title": "title",
        "boundaries": "límites",
        "obstacles": "obstáculos",
        "wrap-span": "wrap span",
        "drop-span": "drop span",
        "waiting-for-system": "Esperando al sistema",
        "too-many-systems": "Demasiados sistemas",
        "id": "ID",
        "feature-type": "tipo de elemento",
        "as": "como",
        "filename": "nombre de archivo",
        "main-irrigated-area": "principal zona de regadío",
        "sac-irrigated-area": "superficie de regadío SAC",
        "end-gun-irrigated-area": "zona de regadío end gun",
        "wrap-irrigated-area": "zona de regadío wrap",
        "flex-side-irrigated-area": "superficie de regadío del lado flex",
        "rigid-side-irrigated-area": "superficie de regadío lateral rígida",
        "acres": "acres",
        "hectares": "hectáreas",
        "meters": "metros",
        "inches": "pulgadas",
        "centimeters": "centímetros",
        "millimeters": "milímetros",
        "north-abbrev": "N",
        "south-abbrev": "S",
        "east-abbrev": "E",
        "west-abbrev": "O",
        "end-of-system": "fin del sistema",
        "system-design": "diseño del sistema",
        "field-design-report": "informe de diseño de campo",
        "custom-value": "valor personalizado",
        "custom": "personalizado",
        "custom-span-length": "Longitud de tramo personalizada",
        "side-view": "Vista Lateral",
        "close": "cerrar",
        "open": "abrir",
        "component": "componente",
        "end-boom-lengths": "longitudes end boom",
        "delete-system": "eliminar sistema",
        "copy-system": "copy system",
        "reset-proposal": "reset proposal",
        "first-abbrev": "1er",
        "second-abbrev": "2do",
        "elevation": "elevación",
        "on": "on",
        "off": "off",
        "model": "modelo",
        "length": "longitud",        
        "cart-start": "inicio del carro",
        "calculating": "calculando",
        "h-tower": "TODO",
        "s-tower": "TODO",
        "s-tower2": "S tower",
        "end-lat": "lat. final",
        "end-lon": "lon. final",
        "start-lat": "lat. inicial",
        "start-lon": "lon. inicial",
        "latitude": "latitud",
        "longitude": "longitud",
        "north": "norte",
        "north-east": "noreste",
        "east": "este",
        "south-east": "sureste",
        "south": "sur",
        "south-west": "sur oeste",
        "west": "oeste",
        "north-west": "noroeste",
        "end-gun": "endgun",
        "throw": "throw",
        "edit-verticies": "edit points",
        "delete-verticies": "delete points",
        "project-list": {
            "layout-initial": "L",
            "project-initial": "F",
            "confirm-delete-item-title": "delete item",
            "confirm-delete-body-field": "field",
            "confirm-delete-body-layout": "layout",
            "confirm-delete-body-multiple-layouts-part1": "This field contains",
            "confirm-delete-body-multiple-layouts-part2": "layout(s)",
            "confirm-delete-body-confirm-text": "Are you sure that you would like to delete this"
        },
        "path-data-control": {
            "title": "path data",
            "export": "export",
            "import": "import",
            "replace": "replace",
            "clear": "clear",
            "export-dialog": {
                "title": "Waiver of Liability",
                "description": "By clicking \"Agree\" below you release Reinke Mfg. Co., Inc. and Ag Connections, Inc. of any responsibility or liability from this created path file and you assume all liability and understand that the GPS guided MSAC, SAC and SSAC follows this path file and understand the risks involved which include but are not limited to machine collision, improper machine alignment, structural damage, impacted field coverage due to the improper boundary clearance selected by the user, improper placement of equipment boundary by user, GPS data logged in the field by user, GPS file importation by user, and known and unknown software discrepancies. Nonetheless, I assume all related risks both known and unknown to me associated with the use of this path file.",
                "decline": "decline",
                "agree": "agree"
            },
            "error-dialog": {
                "title": "Invalid Path Data",
                "import-description": "The uploaded path data is not compatible with Reinke Design Center. Please check your file and try again.",
                "export-description": "Path data cannot be generated for this system. Please check your system and try again."
            }
        },
        "field-design-drawer": {
            "center-pivot": {
                "pivot-center": {
                    "title": "TODO",
                    "center-x-offset": "center X offset",
                    "center-y-offset": "center Y offset"
                }
            },
            "validation": {
                "error": {
                    "wrapSpanTooClose": "Wrap span too close to system end.",
                    "swingArmLength": "Swing Arm Length Invalid."
                }
            },
            "lateral": {
                "pivoting-lateral": {
                    "title": "Pivoting Lateral",
                    "header-pivot-id": "Pivot ID",
                    "header-direction": "Direction",
                    "header-delete": "Delete",
                    "tooltip-none": "none",
                    "tooltip-outside": "outside",
                    "tooltip-inside": "inside",
                    "tooltip-delete": "delete",
                    "footnote": "Note: Modifying a pivot direction or deleting a pivot will remove all drop spans related with this lateral."
                }
            }
        },
        "csv-upload-dialog": {
            "title": "Cargar cultivadores desde CSV",
            "body": " la plantilla de cultivadores, rellénela y cárguela a continuación.",
            "decoding": "Descodificando archivo, por favor espere...",
            "about-to": "A punto de importar ",
            "following-errors": "Los siguientes errores deben solucionarse antes de continuar: ",
            "following-warnings": "Las siguientes advertencias se generaron durante la descodificación del archivo, pero pueden ignorarse: ",
            "will-be-skipped": "se omitirá",
            "a-grower-with-the-name": "Un cultivador con el nombre",
            "already-exists": "ya existe en la base de datos",
            "row": "Fila",
            "invalid-file-type": "Error: el tipo de archivo seleccionado no es válido. Por favor, seleccione un archivo CSV válido.",
            "is-missing-from-row": "falta en la fila",
            "manager-name": "Nombre del gerente",
            "grower-name": "Nombre del cultivador",
            "grower-address": "Dirección del cultivador",
            "grower-country": "País cultivador",
            "grower-zip": "Código postal del cultivador",
            "grower-city": "Ciudad cultivador",
            "grower-state": "Estado del cultivador",
            "is-empty": "está en blanco",
            "please-enter": "Por favor, introduzca el nombre del cultivador y los datos de su explotación.",
            "legal-desc": "Descripción legal",
            "mailing-address": "Dirección postal (si es diferente)",
            "mailing-city-etc": "Ciudad, estado, país y código postal",
            "share-grower": "Compartir este cultivador con mis colegas concesionarios",
            "allow-dealers": "Permitir que los colegas del concesionario modifiquen el cultivador y sus campos"
        },
        "no-fields-match-criteria": "Ninguna casilla coincide con los criterios de búsqueda/filtrado",
        "login": {
            "welcome" : "¡Bienvenidos a Reinke Design Center!",
            "choose-account": "Por favor elija su cuenta para comenzar",
            "some-accounts-not-mine": "¿La cuenta no figura en la lista? Por favor, asegúrese de estar en línea y luego ",
            "no-accounts": "Por favor, asegúrese de estar en línea y luego",
            "log-in": "acceso"
        },
        "reports": {
            "field-area": "zona de campo",
            "system-model": "modelo de sistema",
            "system-length": "longitud del sistema",
            "number-of-spans": "número de tramos",
            "end-gun-throw": "endgun throw",
            "primary-end-gun-settings": "Primary End Gun Settings",
            "secondary-end-gun-settings": "Secondary End Gun Settings",
            "degrees-from-north": "Degrees from North",
            "degree-of-sweep": "grado de barrido",
            "total-irrigated-acres": "total de acres regados",
            "pivot-acres": "acres de pivote",
            "pivot-area": "pivot area",
            "end-gun-acres": "acres del endgun",
            "swing-arm-acres": "brazo oscilante acres",
            "wrap-span-acres": "wrap span acres",
            "drop-span-acres": "drop span acres",
            "span-no": "Tramo nº",
            "pipe-size": "Tamaño del Tubo",
            "outlet-spacing": "Outlet Sp.",
            "dist-tower": "Dist - Twr.",
            "power-track-start-stop": "Arranque/Parada Power Track",
            "cart-path-length": "Longitud del Recorrido del Carro",
            "power-cart-type": "Tipo de Power Cart",
            "dist-to-cart": "Dist al Carro",
            "general-field-design": "General Field Design Report",
            "field-design": "Field Design Report",
            "elevation": {
                "highest-point": "Punto más alto debajo del área de cobertura",
                "lowest-point": "Punto más bajo debajo del área de cobertura",
                "relative-elevation-from-center-point": "Relative Elevation from Center Point"
            },
            "not-yet-set": "Aún no fijado",
            "general-print-out": "General Print Out",
            "full-field-report": "Full Field Report",
            "select-systems": "Select Systems",
            "which-systems": "Which systems would you like to include in the report?"
        },
        "proposals": {
            "map-warning": "Layout contains a proposal (Map functionality limited)",
            "already-generated-proposal": "Ya se ha generado una propuesta para este sistema. Si desea realizar algún cambio, descarte la propuesta o duplique este diseño.",
            "submit-order": "Enviar pedido",
            "discard-proposal": "Descartar propuesta",
            "field-design-report": "Informe de Diseño de Campo",
            "vri-report": "Informe VRI",
            "irrigation-system": {
                "this-customer-irrigation": "Esta propuesta de sistema de riego para clientes se presenta a",
                "and-the-prices": "y los precios aquí indicados serán efectivos a partir de"
            },
            "purchase-order": {
                "barricades": {
                    "title": "Barricades.",
                    "text": "Although described as one of the `Options and Accessories` in this Purchase Order, if the configuration of the System requires installation of barricades for safe operation, Reinke strongly recommends that Dealer includes barricades in this Purchase Order. If barricades are so required, and Reinke barricades are not included as part of this Purchase Order, by signing below, Dealer agrees:"
                },
                "barricades-a": {
                    "text": "(a)    To release Reinke, its agents, successors and assigns of and from any and all claims, causes of action, damages or liabilities resulting directly or indirectly from the deletion of the barricades from this Purchase Order; and"
                },
                "barricades-b": {
                    "text": "(b)    To indemnify and hold Reinke harmless from and against any and all third-party claims, causes of action, damages or liabilities resulting directly or indirectly from the deletion of the barricades from this Purchase Order." 
                }
            },
            "irrigation-system-purchase-agreement": {
                "this-customer-irrigation": "This Customer Irrigation Purchase Agreement (the \"Agreement\") is made by and between",
                "terms": {
                    "title": "Terms and Conditions",
                    "delays-price-increases": {
                        "title": "Delays; Price Increases.",
                        "text": "Performance of this Purchase Agreement by the Dealer is subject to the ability of the Dealer to obtain the System from the manufacturer, Reinke Manufacturing Co., Inc. (“Reinke”).  Dealer shall be under no liability if delivery of the System is delayed due to labor strikes or disturbances, lack of transportation facilities, casualty, war, civil commotion, government orders or reservations, or other causes beyond Dealer’s reasonable control.  In the event Dealer’s cost for the System at the time fixed for delivery has increased above its costs as of the date of this Purchase Agreement, Customer agrees that the price will be adjusted to reflect the price in effect at the date of System delivery.  In such case, the Customer shall have the privilege of accepting delivery at such increased price or of canceling this Purchase Agreement by giving written notice of such cancellation to the Dealer within five (5) days after written notice of such price increase is provided by Dealer to Customer.  Upon such cancellation and reimbursement from Dealer for any payments theretofore received by Dealer from the Customer, the sale of the System shall be rescinded.  If Customer does not cancel this Purchase Agreement within said five-day period, this Purchase Agreement shall be amended to reflect the new System price and such amended price shall supersede the price set forth in this Purchase Agreement."
                    },
                    "taxes": {
                        "title": "Taxes.",
                        "text": "The purchase price for the System is exclusive of any present or future federal, state, local or other excise, sales, or use taxes.  If the Dealer is required by applicable law to pay or collect any such tax or taxes on account of Customer’s purchase of the System, then such amount of tax shall be paid by the Customer in addition to the purchase price stated in this Purchase Agreement."
                    },
                    "risk-of-loss": {
                        "title": "Risk of Loss; Security Interest.",
                        "text": "Title and risk of loss or damage to the System shall pass to the Customer upon delivery F.O.B. Customer’s location, except that a security interest in the System shall remain with Dealer, regardless of the mode of attachment of the System to realty or other property, until full payment has been made.  Customer agrees that the System shall remain personal property and is not intended to constitute a fixture or real estate under any circumstances.  The Customer agrees, upon request, to do all things necessary to perfect the security interest in the System, and hereby irrevocably authorizes Dealer to file any financing statements, amendments or continuation statements."
                    },
                    "system-performance-warranty": {
                        "title": "System Performance; Warranty.",
                        "text": "Dealer shall not be responsible for any failure of the System to perform correctly when such failure is due to incomplete or inaccurate information provided by the Customer to Dealer or to Reinke.  The Customer recognizes that the accuracy of the Reinke Irrigation System Sprinkler Package depends upon the completeness and accuracy of the information provided by Customer and the System being operated at the proper velocity, flow rate, pressure and field conditions.  Furthermore, neither Dealer nor Reinke shall be responsible for any misuse, reconfiguration or alteration of the System if such misuse, reconfiguration or alteration causes any defect, damage or breach of warranty regarding the System.  THE SCOPE OF REINKE'S WARRANTY OBLIGATIONS WITH RESPECT TO THE SYSTEM SHALL BE NO GREATER THAN REINKE’S END USER LIMITED WARRANTY AND LIMITATIONS OF LIABILITY APPLICABLE TO THE SYSTEM OR ANY PARTS THEREOF."
                    },
                    "in-no-event": {
                        "text": "IN NO EVENT SHALL DEALER OR REINKE BE LIABLE TO THE CUSTOMER FOR ANY CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, INDIRECT, PUNITIVE OR SPECIAL DAMAGES OF ANY NATURE, OR FOR LOSS, DAMAGE, COST OF CAPITAL, OR EXPENSE DIRECTLY OR INDIRECTLY ARISING FROM THE SALE, USE, INSTALLATION OR MANUFACTURE OF THE SYSTEM, OR FROM ANY OTHER CAUSE OF ACTION OR SUIT WHETHER BASED ON EXPRESS OR IMPLIED WARRANTY, STATUTE, TORT, OR CONTRACT, EVEN IF DEALER OR REINKE HAVE BEEN ADVISED OR KNEW, OR SHOULD HAVE KNOWN, OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES."
                    },
                    "customer-acknowledges": {
                        "text": "(Customer Initials)  ______   The Dealer has provided the Customer with a copy of the Reinke End User Limited Warranty. Customer acknowledges receipt of the Warranty and that Customer has read the terms contained in the Warranty."
                    },
                    "barricades": {
                        "title": "Barricades.",
                        "text": "Although described as one of the ”Options and Accessories” in this Purchase Agreement, if the configuration of the System requires installation of barricades for safe operation, Dealer strongly recommends that the Customer includes barricades in this Purchase Agreement.  If barricades are so required, and Reinke barricades are not included as part of this Purchase Agreement, by signing below, Customer agrees:"
                    },
                    "barricades-a": {
                        "text": "(a)    To release Dealer and Reinke, their agents, successors, and assigns of from any and all claims, causes of action, damages or liabilities resulting directly or indirectly from the deletion of the barricades from this Purchase Agreement; and"
                    },
                    "barricades-b": {
                        "text": "(b)    To indemnify and hold Dealer and Reinke harmless from and against any and all claims, causes of action, damages or liabilities resulting directly or indirectly from the deletion of the barricades from this Purchase Agreement and the failure of the Customer to provide a substantial barricade prior to the operation of the System." 
                    },
                    "governing-law": {
                        "title": "Governing Law; Dispute Resolution",
                        "text-a": "Unless otherwise required by law, the contractual relationship between the Customer and Dealer shall be governed by the laws of the State of",
                        "text-b": "without giving effect to any choice or conflict of law provision or rule (whether of this State or any other jurisdiction) that would cause the application of the laws of any jurisdiction other than that specified herein.  In the event that any dispute arises between the Customer and Dealer in any manner related to this Purchase Agreement, jurisdiction and venue shall be exclusively in the state or federal courts of the State specified above, and Customer shall be deemed to have consented to personal jurisdiction in such courts.  Customer waives any defense or inconvenient forum to the maintenance of any action or proceeding so commenced."
                    },
                    "entire-agreement": {
                        "title": "Entire Agreement.",
                        "text": "This Purchase Agreement, including the foregoing Terms and Conditions and any attachments hereto, constitutes the entire agreement between the Customer and Dealer relating to the subject matter hereof.  This Purchase Agreement shall not be modified orally or by failure of either party to enforce its rights hereunder and shall be modified only by a writing duly executed by authorized representatives of the Customer and Dealer."
                    },
                    "the-undersigned": {
                        "text": "THE UNDERSIGNED CUSTOMER HAS READ THE TERMS SET FORTH ABOVE AND AGREES TO BE BOUND TO SUCH TERMS."
                    },
                    "customer-signature": "Customer Signature",
                    "dealer-signature": "Dealer Signature",
                    "reinke-signature": "Reinke Signature"
                }
            },
            "flex-side-end-guns": "Flex Side Endguns",
            "rigid-side-end-guns": "Rigid Side Endguns"
        },
        "projects": {
            "click-new": "Haga clic en NUEVO CULTIVADOR para empezar.",
            "add-grower": "Add a New Grower or upload a grower list to get started",
            "new-grower": "nuevo cultivador",
            "show-deleted": "show deleted",
            "deleted-grower": "deleted grower",
            "deleted-project": "deleted project",
            "show-shared-with-me": "show shared with me",
            "un-delete-grower": "Would you like to un-delete it?",
            "un-delete-project": "Would you like to un-delete it?",
            "project-has-been-deleted": "This project has been deleted which means that you cannot currently make changes.",
            "grower-has-been-deleted": "This grower has been deleted which means that you cannot currently make changes or load their projects.",
            "new-field": "nuevo campo",
            "edit-grower": "edit grower",
            "delete-field": "borrar campo",
            "confirm-delete-field": "¿Está seguro de que desea eliminar este campo?",
            "search-by": "Buscar por",
            "you-havent-created": "Add a new field for",
            "click-to-start": "haga clic en NUEVO CAMPO para empezar",
            "field-name": "Nombre de campo",
            "project-state": "field state",
            "project-type": "tipo de proyecto",
            "last-modified": "última modificación",
            "last-modified-by": "última modificación por",
            "field-project": "Planifique el diseño del nuevo campo en un mapa antes de seleccionar los componentes del sistema.",
            "system-project": "Pase directamente a la selección de componentes para un nuevo sistema.",
            "choose-on-map": {
                "choose-approx": "Debe elegir la ubicación aproximada de este proyecto para que podamos descargar la fotografía aérea. Esto le permitirá trabajar sin conexión.",
                "type-search": "Para empezar, escriba la dirección o las coordenadas de la explotación en el cuadro de búsqueda que aparece a continuación.",
                "now-drag": "Ahora arrastra el mapa de abajo para que el centro no esté a más de 5 millas del campo."
            },
            "first-span-type": "Primer tipo de tramo",
            "current-layout-name": "Nombre del diseño actual",
            "new-layout-name-same": "El nuevo nombre del diseño no debe ser el mismo que el actual.",
            "new-layout-name-long": "El nombre del diseño no debe tener más de 80 caracteres.",
            "new-layout-name-unique": "El nombre del nuevo diseño debe ser único para este campo."
        },
        "field-settings": {
            "minimum-boundary-clearance": "Distancia Mínima al Límite",
            "minimum-obstacle-clearance": "Distancia Mínima al Obstáculo",
            "minimum-system-clearance": "Distancia Mínima del Sistema",
            "field-settings": "Ajustes de Campo"
        },
        "map-display": {
            "loading": "Espere un momento, el mapa se está cargando",
            "irrigated-area": "superficie regada",
            "clear-measurements": "eliminar medidas",
            "field-report": "field report",
            "toggle-map-features": "alternar características del mapa",
            "select-annotation-type": "seleccionar tipo de anotación",
            "click-label-annotation": "Haga clic donde desea que vaya la anotación de la etiqueta",
            "name-label-annotation": "Introduzca el texto para la anotación de la etiqueta. A continuación, podrá hacer clic en el mapa para añadirla.",
            "label-text": "Texto de la Etiqueta",
            "select-obstacle-type": "seleccione el tipo de obstáculo",
            "select-boundary-type": "seleccione el tipo de límite",
            "select-system-type": "seleccione el tipo de sistema",
            "left-drawer": {
                "irrigated-areas": "Zonas de Regadío",
                "system-lengths": "System Lengths",
                "including-eos": "including end of system"
            },
            "speed-dial": {
                "edge-clearance": "Equipment Clearance",
                "set-all-clearances": "Ajustar Holguras",
                "s-tower-clearances": "S Tower Clearance",
                "h-tower-clearances": "TODO",
                "set-clearance": {
                    "title": "Fijar la Distancia",
                    "current-value": "TODO",
                    "offset-clearance-label": "Offet from the Equipment clearance",
                    "note": "Additional clearance to be added on top of the Equipment Clearance"
                }
            },
            "rename-annotation": {
                "title": "Rename Annotation",
                "type": "Annotation Type",
                "label": "Current Label"
            }
        },
        "nav": {
            "map-layer": "Capa de Mapa",
            "toolbox": "Toolbox",
            "doc-library": "Documentos",
            "undo": "Anular",
            "road-labels": "etiquetas viales",
            "logout": "Cierre de sesión",
            "account-settings": "Configuración de la cuenta",
            "dealer-settings": "Configuración del distribuidor",
            "tools": {
                "wire-sizing": "Dimensionamiento de Cables Subterráneos",
                "pressure-loss": "Pérdida de Presión en la Línea Principal",
                "system-comparison": "Comparación de Sistemas",
                "lease": "Arrendamiento",
                "lease-comparison": "Comparación de Arrendamientos",
                "loan": "Préstamo",
                "loan-comparison": "Comparación de Préstamos",
                "blended-rate": "Tasa Mixta"
            },
            "sync": {
                "sync-now": "Sincronizar ahora",
                "not-yet-synchronized": "Aún no sincronizado",
                "sync-in-progress": "Sincronización en curso",
                "working-offline": "Trabajar sin conexión",
                "sync-successful": "Sincronización correcta",
                "sync-failed": "Error de sincronización"
            },
            "layouts": {
                "new-layout": "Nuevo Diseño",
                "delete-layout": "borrar diseño",
                "confirm-delete-layout": "¿Está seguro de que desea eliminar este diseño?",
                "copy-to-new-layout": "Copiar a un nuevo diseño"
            },
            "systems": {
                "new-system": "Nuevo Sistema",
                "confirm-delete-system": "¿Está seguro de que desea eliminar este sistema?",
                "delete-will-remove-field": "This will also delete the system in field layout. If you want to keep the system in field layout, click cancel and click the Reset Proposal button instead.",
                "confirm-reset-proposal": "Are you sure that you would like to reset this system?",
                "confirm-copy-system": "Are you sure that you would like to copy this system? Please enter the field name for the new system below.",
                "field-name-not-blank": "Field name cannot be blank",
                "multiple-systems": "Multiple Systems"
            },
            "docs": {
                "warranty-features": "Características de la Garantía",
                "user-guide": "Guía del Usuario"
            },
            "export": {
                "export-shapes": "Exportar Formas",
                "export-shape": "Exportar Forma",
                "invalid-filename": "Sólo puede utilizar los caracteres"
            },
            "labels": {
                "show-labels": "mostrar etiquetas",
                "show-clearance": "mostrar distancia"
            }
        },
        "system-design-redirect-dialog": {
            "title": "Invalid system geometry",
            "body": "The system geometry is invalid and must be corrected before using this page. You will be redirected to the map view where you can correct the system."
        },
        "dealer-settings": {
            "dealer-info": "Información del Distribuidor",
            "dealer-logo": "Dealer Logo",
            "system-defaults": "Valores por Defecto del Sistema",
            "span-defaults": "Span Defaults",
            "tower-defaults": "Tower Defaults",
            "optimization-defaults": "Valores Predeterminados de Optimización",
            "customer-settings": "Ajustes del Cliente",
            "dealer-pricing": "Dealer Pricing",
            "display-settings": "Ajustes de Pantalla",
            "map-settings": "Configuración del Mapa",
            "sac-opt-settings": "Ajustes del Optimizador SAC",
            "use-app-defaults": "No defaults applied",            
            "use-default-logo": "Use default logo",
            "use-own-logo": "Use own logo",
            "define-own-defaults": "User defaults applied",
            "no-custom-logo": "You have not defined a custom logo. The following image will be used on the PDF proposals instead.",
            "sac-opt-settings-options": {
                "max-steering-angle-roc": "Tasa máxima de cambio del ángulo de dirección",
                "at-stwr": "en STWR",
                "steering-angle-must-be": "La tasa de cambio del ángulo de dirección STWR debe estar entre"
            },
            "customer-settings-options": {
                "discount-off-list-price-pc": "Descuento sobre el precio de catálogo %",
                "markup-over-dealer-cost-pc": "Margen sobre el coste del distribuidor %",
                "markup-over-dealer-cost-major-units": "Markup over dealer cost",
                "quoted-price-major-units": "Quoted price",
                "sales-tax-pc": "Impuesto sobre las ventas (%)",
                "system-taxable": "Sistema imponible",
                "installation-taxable": "Instalación imponible",
                "freight-taxable": "Flete imponible",
                "pivot-pad-taxable": "Pastilla pivotante imponible",
                "system-trade-in-taxable": "Sistema permuta imponible",
                "rebate-taxable": "Rebaja imponible",
                "price-computation": "Price computation",
                "print-list-price": "Print list price",
                "only-print-total-price": "Only print total price"
            },            
            "dealer-pricing-options": {
                "program-discount-pc": "Program discount (%)",
                "delivery-discount-pc": "Delivery discount (%)",
                "tm-discount-pc": "TM discount (%)"
            }, 
            "display-settings-options": {
                "coordinates": "Coordenadas",
                "decimal-degrees": "Grados decimales",
                "degrees-minutes-seconds": "Grados, minutos, segundos",
                "areas": "Áreas",
                "lengths": "Longitudes",
                "pressures": "Pressures",
                "irrigation-rates": "Irrigation rates",
                "minor-lengths": "Longitudes menores",
                "volumes": "Volumes",
                "weights": "Weights"
            },
            "span-settings-options": {
                "span-type": "Span Type",
                "spacing": "Spacing",
                "wire-gauge": "Wire Gauge"
            },
            "tower-settings-options": {
                "tower-type": "Tower Type",
                "center-drive": "Center Drive"
            },
            "map-settings-options": {
                "map-feature-colors": "Colores de los elementos del mapa",
                "change-color": "Cambiar color",
                "fill-color": "color de relleno",
                "line-color": "color de línea", 
                "text-color": "color del texto",
                "text-halo-color": "color del halo de texto",
                "circle-color": "color del círculo",
                "map-draw-features": {
                    "gl-draw-polygon-fill-active": "Relleno de polígono (seleccionado/dibujo)",
                    "gl-draw-polygon-stroke-active": "Trazo del polígono (seleccionado/dibujado)",
                    "gl-draw-line-active": "Línea (seleccionada/dibujada)",
                    "rdp-electric-line": "Anotación de la línea eléctrica",
                    "rdp-water-line": "Anotación de la línea de agua",
                    "rdp-canal": "Anotación del canal",
                    "rdp-line": "Anotación de línea",
                    "rdp-obstacle-outline": "Contorno del obstáculo span",
                    "rdp-wheelObstacle-outline": "Contorno del obstáculo de la rueda",
                    "rdp-fieldBoundary-outline": "Contorno del límite del campo",
                    "rdp-wetAreaBoundary-outline": "Límite de la zona húmeda",
                    "rdp-pivotCenterBoundary-outline": "Contorno del límite del centro de giro",
                    "rdp-system/lateral": "Recorrido del carro (sistema lateral)",
                    "rdp-system/canal": "Canal (sistema lateral alimentado por canales)",
                    "rdp-centerPivotSelectMode/irrigatedArea-valid": "Superficie de regadío válida (sistema seleccionado)",
                    "rdp-dynamicOptimization/feedLine-invalid": "Línea de alimentación no válida (optimización dinámica del sistema lateral)",
                    "rdp-dynamicOptimization/irrigatedArea-flangedSide": "Superficie de riego lateral con bridas (optimización dinámica)",
                    "rdp-dynamicOptimization/irrigatedArea-flexSide": "Superficie regada en el lado flexible (optimización dinámica)",
                    "rdp-dynamicOptimization/wheelTrack": "Trayectoria de las ruedas (optimización dinámica)",
                    "rdp-system/irrigatedArea-active": "Superficie de regadío lateral con bridas (activa)",
                    "rdp-system/irrigatedArea-dropSpan-active": "Superficie regada por goteo (activa)",
                    "rdp-system/irrigatedArea-dropSpan-inactive": "Superficie regada por goteo (inactiva)",
                    "rdp-system/irrigatedArea-endGun-active": "Zona de regadío end gun (activa)",
                    "rdp-system/irrigatedArea-endGun-inactive": "Zona de regadío end gun (inactiva)",
                    "rdp-system/irrigatedArea-flexSide-active": "Superficie regada en el lado flexible (activa)",
                    "rdp-system/irrigatedArea-flexSide-inactive": "Superficie regada en el lado flexible (inactiva)",
                    "rdp-system/irrigatedArea-inactive": "Superficie de riego lateral con brida (inactiva)",
                    "rdp-system/irrigatedArea-sac-active": "Superficie de regadío SAC (activa)",
                    "rdp-system/irrigatedArea-sac-inactive": "Superficie de regadío SAC (inactiva)",
                    "rdp-system/wheelTrack-active": "Vía de la rueda (activa)",
                    "rdp-system/wheelTrack-inactive": "Vía de la rueda (inactiva)",
                    "rdp/irrigatedArea-critical": "Superficie irrigada (alerta crítica)",
                    "rdp/irrigatedArea-warning": "Superficie irrigada (alerta)",
                    "sweepline": "Línea de barrido",
                    "rdp-display-label": "Texto de la etiqueta de característica",
                    "rdp-label-text": "Texto de la herramienta de medición",
                    "rdp-centerPivotSelectMode/wheelTracks": "Vía de la rueda (pivote central seleccionado)",
                    "rdp-dyanimcOptimization-label": "Texto de optimización dinámica",
                    "rdp-persistent-measurement-label": "Texto de medición almacenado",
                    "rdp-persistent-measurement-line": "Línea de medición almacenada",
                    "rdp-projectList/fieldBoundary": "Límite del campo (lista de proyectos)",
                    "gl-draw-polygon-and-line-vertex-stroke-inactive": "Asa de arrastre",
                    "gl-draw-polygon-midpoint": "Asa de arrastre del punto medio",
                    "rdp-dyanimcCenterInvalid": "Punto central del pivote dinámico (no válido)",
                    "rdp-dyanimcCenterValid": "Punto central del pivote dinámico (válido)",
                    "rdp-point": "Anotación de puntos",
                    "rdp-pump": "Anotación de la bomba",
                    "rdp-system/pivotCenter-active": "TODO",
                    "rdp-system/pivotCenter-inactive": "TODO",
                    "rdp/boundaryClearance": "TODO",
                    "rdp/obstacleClearance": "TODO",
                    "gl-draw-vertex-label": "TODO",
                    "rdp/boundaryClearance-equipment": "TODO",
                    "rdp/boundaryClearance-sTower": "TODO",
                    "rdp/boundaryClearance-hTower": "TODO",
                    "rdp/obstacleClearance-equipment": "TODO",
                    "rdp/obstacleClearance-sTower": "TODO",
                    "rdp/obstacleClearance-hTower": "TODO",
                    "rdp/segment-select-mode-clearance-equipment": "TODO",
                    "rdp/segment-select-mode-clearance-sTower": "TODO",
                    "rdp/segment-select-mode-clearance-hTower": "TODO",
                    "rdp/wheelTrack-critical": "TODO",
                    "rdp/wheelTrack-warning": "TODO"
                }
            }
        },
        "optimization-dialog": {
            "optimize-system": "Optimizar el sistema",
            "choose-span-lengths": "Elija la longitud de los tramos",
            "span-lengths": "Longitudes de tramo",
            "end-boom-lengths": "End Boom Lengths",
            "allow-span-extensions": "Allow span extensions",
            
            "center-pivot": {
                "cost-per-acre-ratio": "Cost per Acre Ratio",
                "allow-partial-pivots": "Permitir pivotes parciales",
                "allow-wrap-spans": "Permitir tramos de envoltura",
                "max-wrap-number": "Número máximo de envolturas",
                "end-of-system":"end of system",
                "optimize-center-pivot-location": "Optimizar la localización del pivote central",
                "center-pivot-optimization": "Optimización del pivote central",
                "clearances-previously-set": "TODO",
                "clearances-only-for-sac": "TODO",
                "restrict-max-system-radius": "Restrict maximum radius",
                "restrict-min-system-radius": "Restrict minimum radius",
                "whole-field-optimization": "Whole Field Optimization",
                "restrict-max-number-of-systems": "Restrict number of systems",
                "max-system-number": "Max number of systems"
            },
            "lateral": {
                "system-type": "System Type",
                "feed-type": "Feed Type",
                "feed-location": "Feed Location",
                "canal-width": "Canal Width",
                "distance-canal-center-to-system": "Distance from canal center to system",
                "end-feed": "Fin de alimentación",
                "allow-drop-spans" : "Permitir vanos de caída",
                "max-drop-number": "Número máximo de caídas",
                "aft-side": "TODO",
                "fwd-side": "TODO",
                "eos": "TODO",
                "lateral-optimization": "Optimización lateral",
                "end-gun-note": "Note, endboom and end gun choices will be applied to the fwd and aft sides"
            }
        },
        "proposal-information":{
            "pivot-pads": "Pastillas Pivotantes",
            "misc-items": "Miscelaneos",
            "trade-in": "Canje",
            "sub-total": "Subtotal",
            "sales-tax": "Impuestos",
            "total": "Total",
            "incomplete-price": "Los precios están incompletos - faltan los precios de algunos componentes.",
            "critical-errors-on-proposal-generation": "No podrá generar una propuesta hasta que se hayan resuelto los errores críticos."
        },
        "shipping": {
            "loading": "Getting available shipping dates from Reinke",
            "invalid-shipping-date": "The requested shipping date is invalid.",
            "error-min-ship-date-Xdays": "The selected shipment date must be no sooner than 6 days from the current date.",
            "error-ship-date-api": "The selected shipment date is unavailable."
        },
        "roe":{
            "lateral": "lateral",
            "pivoting-lateral": "pivoting lateral",
            "center-pivot": "pivote central",
            "retro-swing-arm": "brazo oscilante retro",
            "ancillary": "ancillary",        
            "standard-pivot": "pivote estándar",
            "towable": "remolcable",
            "engine-driven-mini-pivot": "minipivote accionado por motor",
            "four-wheel-pivot-mover": "Motor pivotante con 4 ruedas",
            "kwik-tow": "kwik-tow",
            "skid-skid": "kwik-tow",
            "canal-feed": "alimentación del canal",
            "hose-feed": "alimentación de manguera",
            "end-feed": "end feed",
            "center-feed": "center feed",
            "end-of-system":{
                "type": "Final del Sistema",
                "end-boom": "Voladizo",
                "swing-arm-corner": "Brazo Esquinero Super"
            },
            "spans-and-towers": {
                "add-span": "Click the (+) icon to add your first span",
                "no-flex-spans": "This is a dual sided system - you must define at least one flex side span",
                "no-flanged-spans": "This is a dual sided system - you must define at least one flanged side span",
                "towers-add-span": "Please add your first span on the spans tab. You will then be able to edit the tower properties here.",
                "max-one-span": "This system type combination allows only one span."
            },
            "mcp":{
                "full-load-amps": "Full Load Amps",
                "voltage-at-main-panel": "Tensión en el Panel de Control Principal"
            },
            "sprinklers": {  
                "copy-from-flanged-side-boombacks": "Copy from Flanged Side",
                "copy-from-flanged-side-package": "Copy Flanged Package",
                "generate-sprinkler-chart": "Para generar la carta de aspersores, haga clic en el botón siguiente", 
                "chart-generation-unavailable": "The Sprinkler Chart cannot be generated until required fields are not filled in. These appear in red along the steps.",
                "calculate": "Calcular",
                "chart-error": "La carta de aspersores no puede calcularse debido a los siguientes errores:",
                "use-flanged-package": "Usar el Paquete Bridado", 
                "use-flex-package": "Usar el Paquete Flex",  
                "sprinkler-options": "Opciones de Aspersores",          
                "sprinkler-chart": "Carta de Aspersores",
                "flanged-boombacks": "Flanged Boombacks",
                "flexed-boombacks": "Flexed Boombacks",
                "flanged-package": "Paquete Bridado",
                "flexed-package": "Paquete Flex",                
                "maxi-package": "Maxi Package",
                "power-tower-package": "Power Tower Package",
                "sprinkler-brand": "Marca de Aspersor",
                "pressure-gauge": "Manómetro",
                "end-gauge-30-psi": "Manómetro Final - 30 psi",
                "end-gauge-2-1-bar": "End Gauge - 2.07 bar",
                "system-gpm": "GPM del Sistema",
                "system-cmh": "System m3/h",
                "side-gpm": "Side GPM",
                "side-cmh": "Side m3/h",
                "end-pressure": "Presión Final",
                "elevation": "Elevación",
                "map-elevation": "Map Elevation",
                "boombacks": "Huella Seca",
                "boomback-device": "Dispositivo de Boom Back",
                "boomback-qty-per-tower": "Cant. por Torre de Boom Back",
                "first-tower": "Primer Torre",
                "boomback-auto-reverse": "Valvulas y Control de Auto Reversa",
                "boomback-device-type":{
                    "D3000" : "Spray D3000 de Círculo Parcial",
                    "S3000": "Spinner S3000 de Círculo Parcial",
                    "R3000": "Rotator de Círculo Parcial R3000",
                    "LDN": "LDN de Círculo Parcial",
                    "Fan": "Spray Senninger de 180°",
                    "Komet": "Spray Komet de Círculo Parcial",
                    "KometTwisterPartCircle": "Twister Komet de Círculo Parcial"
                },
                "boomback-material": {
                    "Galvanized": "Galvanizada",
                    "PVC": "PVC"
                },
                "package": {
                    "package-type": "Tipo de Paquete",
                    "package-types": {
                        "sprKometTwisterWhite": "KP-Twister - Up Top - Blanco",
                        "sprKometTwisterBlack": "KP-Twister - Ángulo Estándar - Negro",
                        "sprKometTwisterBlue": "KP-Twister - Angulo Bajo - Azul",
                        "sprKometTwisterYellow": "KP-Twister - Angulo Ultra Bajo - Amarillo",
                        "sprKometSprayBlue": "Spray KP – Cóncavo – Mediano - Azul",
                        "sprKometSprayGrey": "Spray KP – Mate – Grueso - Gris",
                        "sprKometSprayBlack": "Spray KP – Mate – Medio - Negro",
                        "sprKometSprayYellow":"Spray KP – Mate – Fino - Amarillo",
                        "sprSenningerQuadSpray": "Quad Spray",
                        "sprSenningerLDNSpray": "LDN Spray",
                        "sprSenningerSuperSpray":"Super Spray",
                        "sprSenningerXiwob": "Xi-Wob",
                        "sprSenningerXcelWobbler": "Xcel Wobbler",
                        "sprSenningerIWob": "I-Wob2",
                        "sprSenningerVariableSpacingPlasticImpactRed": "6° Double Red Vane Plastic Impacto",
                        "sprSenningerVariableSpacingPlasticImpactWhite": "6° - Impactos de Doble Vena",
                        "sprSenningerConstantSpacingPlasticImpact": "12° - Impactos de Plástico de Espaciamiento Medio",
                        "NelsonR3000Rotator": "R3000 Rotator",
                        "NelsonS3000Spinner": "S3000 Spinner",
                        "NelsonO3000Orbitor": "O3000 Orbitor",
                        "NelsonO3030OrbitorFX": "O3030 OrbitorFX",
                        "NelsonA3000Accelerator": "A3000 Accelerator",
                        "NelsonD3000Spray": "D3000 Spray"
                    },
                    "regulator-type": "Tipo de Regulador",
                    "regulator-types": {
                        "KometKPR":"Komet KPR",
                        "NelsonBlueFTHF": "Nelson Azul FT/HF",
                        "NelsonBlueFTLF": "Nelson Azul FT/LF",
                        "NelsonBlueLFHF": "Nelson",
                        "NelsonBlueSTHF": "Nelson Azul ST/HF",
                        "NelsonBlueSTLF":"Nelson Azul ST/LF",
                        "SenningerLF": "Senninger LF",
                        "SenningerLFMF": "Senninger LF/MF",
                        "SenningerMF": "Senninger MF",
                        "SenningerPSR":"Senninger PSR"
                    },
                    "nozzle-clip": "Clip de Boquilla",
                    "nozzle-clip-types":{
                        "Dual":"Doble boquilla",
                        "Triple": "Triple c/boquilla"
                    },
                    "regulator-pressure": "Presión Regulador",
                    "use-only-when-needed": "Use Only When Needed",
                    "thread-type": "Con cuerda",
                    "thread-types": {
                        "female": "Hembra",
                        "square": "Cuadrada"
                    },
                    "valve-type": "Tipos de Válvulas",
                    "valve-types": {
                        "Brass": "Válvula de Bronce",
                        "PlasticLever": "Válvula Plástica c/palanca",
                        "PlasticKnob": "Válvula Plástica c/mariposa"
                    },
                    "drop-type": "Tipo de Bajante",
                    "sprinkler-spacing": "Sprinkler Spacing",
                    "spacing-feet": "Spacing (feet / inches)",
                    "spacing-meters": "Spacing (meters)",
                    "second-nozzle-gpm": "Second Nozzle GPM",
                    "third-nozzle-gpm": "Third Nozzle GPM",
                    "drop-types":{
                        "Rigid": "Bajantes Rígidas",
                        "Hose": "Bajantes de Manguera"
                    },
                    "drop-spacing": "Epaciamiento de Bajantes Gradual",
                    "ground-clearance": "Altura Libre del suelo (pies)",
                    "ground-clearance-m": "Ground Clearance (m)",
                    "reinke-blue": "Manguera de 3/4\" Azul Reinke",
                    "reinke-blue-m": "Reinke Blue 19.1mm HD Flex Hose",
                    "use-komet-truss": "Use Komet Truss Rod Slings",
                    "all-devices-doubled": "Todos los dispositivos duplicados",
                    "u-pipe-reach": "Alcance del tubo-U",
                    "u-pipe-fitting": "Cople de tubo-U",
                    "u-pipe-type": "Tipo de Tubo-U",
                    "u-pipe-types": {
                        "Alum": "Aluminio",
                        "Nelson180": "Nelson Plastico Negro Sencillo 180°",
                        "BlackPlasticSingle180": "Plastico Negro Sencillo 180°",
                        "BlackPlasticSingle125": "Plastico Negro Sencillo 125° c/ Truss Rod Slings",
                        "BlackPlasticDouble125": "Plastico Negro Doble 125° c/ Truss Rod Slings"
                    },
                    "screw-clamp": "Abrazadera de tornillo",
                    "drag-adaptor": "Adaptador de Arrastre",
                    "drag-shock": "Calcetín de Arrastre",
                    "regulator-location": "Local. del Regulador",
                    "regulator-locations": {
                        "Bottom": "Parte Inferior",
                        "Top": "Parte Superior"
                    },
                    "hose-top-fitting": "Cople de manguera superior",
                    "hose-top-fittings": {
                        "FxHBBlack": "Female by Hose Barb Negro",
                        "MxHBGray":"Male by Hose Barb Gris",
                        "MxHBBlack": "Male by Hose Barb Negro"
                    },
                    "hose-top-clamp": "Abrasadera Superior",
                    "hose-bottom-clamp": "Abrasadera Inferior",
                    "clamp-types": {
                        "Screw" : "De Tornillo",
                        "Pinch": "De Pellisco"
                    },
                    "drop-weight": "Contrapesos",
                    "drop-weight-types": {
                        "SlipOver30Poly": "Deslizable 30\" Poly",
                        "FxF24Poly": "3/4\" HxH 24\" Poly"
                    },
                    "u-pipe-fitting-types": {
                        "MxF": "Male by Female",
                        "MxHB": "Male by Hose Barb",
                        "MxM" : "Male by Male",
                        "FxF24Poly": "3/4\" FxF 24\" Poly",
                        "FxF24Poly-m": "19.1mm FxF 60.96cm Poly"
                    }, 
                    "weight-top-fitting": "Cople Arriba del Contrapeso",
                    "weight-bottom-fitting": "Cople Abajo del Contrapeso",
                    "weight-fitting-types": {
                        "FxHBBlack": "Female by Hose Barb Negro",
                        "MxHBGray": "Male by Hose Barb Gris",
                        "MxHBBlack": "Male by Hose Barb Black",
                        "CloseGray": "Niple 3/4\" Gris",
                        "CloseBlack": "Niple 3/4\" Negro",
                        "CloseGalv": "Niple 3/4\" Galvanized",
                        "CouplingGray":"Cople 3/4\" Gris",
                        "CouplingBlack": "Cople 3/4\" Negro",
                        "CouplingGalv": "Cople 3/4\" Galvanized"
                    },
                    "device-weight": "Contrapeso del Dispositivo",
                    "substitute-adapter": "Substituir el adaptador STxHB por el Female by Hose Barb",
                    "length": "Longitud",
                    "drop-length": "Long. de Bajante (pies)",
                    "drop-length-m": "Drop Length (m)",
                    "drop-length-types": {
                        "Fixed": "Fijo",
                        "Variable": "Variables"
                    },
                    "rigid-drop-type": "Tipo de Bajante",
                    "rigid-drop-type-materials": {
                        "Galvanized": "Galvanizada",
                        "PVC": "PVC",
                        "ComboPVCHose": "Combo PVC/Manguera",
                        "Poly": "Polietileno",
                        "Combo": "Tubo/Manguera"
                    },
                    "u-pipe": "Tubo-U",
                    "preferred-ground-clearance": "Altura Libre Requerida (pies)",
                    "preferred-ground-clearance-m": "Preferred Ground Clearance (meters)",
                    "reinforcement-clamp": "Abrasadera Reforzada (para bajantes de 7' o más)",
                    "reinforcement-clamp-m": "Reinforcement Clamp (for drops 2.13m or longer)",
                    "u-pipe-materials": {
                        "Galvanized": "Cuello Galvanized Male by Female",
                        "PVC": "Cuello de PVC Male by Female",
                        "BlackPlastic": "Plastico Negro Male by Male",
                        "Nelson180": "Male by Male Nelson 180"
                    },
                    "drop-bottom-fitting": "Cople en Parte Inferior",
                    "drop-bottom-fittings": {
                        "Gray": "Cople 3/4\" Gris",
                        "Black": "Cople 3/4\" Negro",
                        "Galvanized": "Cople 3/4\" Galvanized"
                    }
                }
            }
        },
        "common-phrases":{
            "extension-Abrv": "Ext",
            "sltCustomerComments": "Customer Comments",
            "sltDiscountPattern" : "(Standard-Program-Delivery-TM Discount)",
            "sltLPDrainWarning" : "The LP Drain Kit requires a Hose Drop for installation.",
            "sltPivotAutoStop" : "Pivot Auto Stop",
            "sltPivotAutoStopSpecial" : "Pivot Auto Stop (Requires 11 Ring Collector Reel with Cam Plate)",
            "sltUnit" : "Unit",
            "sltGPSGuidanceWarning" : "Coverage maps must be evaluated by Reinke prior to sale.  Please send path end points and base station coordinates to Reinke Mfg.   Push pin locations for the path end points and base can be selected from Google Maps.",
            "sltNoticeOfRebateText" : "I, {0}, agree that the rebate being offered by REINKE MANUFACTURING COMPANY, INC. With the purchase Of my New Reinke irrigation system In the total amount Of {1}, shall be applied to the purchase price Of said system.",
            "sltBBMsg3" : "Minimum voltage not met at location {0}. Selection of a lower minimum voltage, larger gauge span wire or a Buck & Boost should be considered.",
            "sltBBMsgL2" : "Minimum voltage not met at location {0}. The Buck & Boost may need to be on a tower farther from the electrical source.",
            "sltBBMsgL1" : "Minimum voltage not met at location {0}. The Buck & Boost may need to be on a tower nearer to the electrical source.",
            "sltRadioTelemetryInfo1" : "Radio Telemetry Annual Use Fees (These fees are billed directly to customer by ReinTech, LLC)",
            "sltWASWarning" : "When in the \"wrap-around\" mode, over-application will occur.  Please note that this over-application will affect the uniformity of any chemicals that are being applied with the system as well.",
            "sltDragHoseTooLong3" : "To calculate hose length, divide the distance between risers by 2 and add 20' to 30' for the loop.  Hose should be ordered in 10' increments.",
            "sltDragHoseTooLong4" : "Hose diameter, water pressure, terrain, soil type and condition affect the length of hose that can be pulled.",
            "sltSpanCableProblem" : "Invalid system design requires {0} conductors on span number {1} of the {2}. Please reconfigure system by reducing the number of spare conductors selected with the span cable or change the cable gauge from #8 to #10.",
            "sltGalvCNPlusWarning" : "The 6\" Galvanized CN+ Pipe specified is Special Order Only - Please Contact Reinke Manufacturing Co., Inc. for its Availability and Approximate Delivery Date.",
            "sltPleaseReviewCompleteSys" : "Please review this Dealer Irrigation System Purchase Order carefully to make certain a complete system has been ordered and that all options selected are listed correctly.",
            "sltFullReplacementValueInsurance" : "Full Replacement Value Insurance is available from Mid-America Risk Managers, contact your Reinke dealer for details. (Not available in Canada.)",
            "sltCorrosionResistantAluminumAlloyMainControlPanelOutlasts" : "Corrosion Resistant Aluminum Alloy Main Control Panel outlasts ordinary steel panels",
            "sltComputerDesignedSprinklerPackagesInsure" : "Computer designed sprinkler packages insure uniform water application for maximum yields",
            "sltExclusiveMechanicalSafetyInterlockPrevents" : "Exclusive mechanical safety interlock prevents access to inside of tower boxes without shutting off the electrical power",
            "sltHighTensileStrengthSteelAllowsReinkeReduceTower" : "High Tensile Strength Steel lets Reinke reduce tower weight considerably, reducing drive train stress and repair expense",
            "sltRigidDropTooShort" : "Due to a limit in rigid drop lengths, some of all of the drops will be lower than the ground clearance by {0} feet or less.",
            "sltRigidDropTooLong" : "Due to a limit in rigid drop lengths, some or all of the drops will be higher than the ground clearance by {0} feet or less.",
            "sltTheGPMRequiredExceeds" : "The GPM required exceeds the GPM capacity of the sprinkler at {0} feet. Reduce the System GPM and/or select spans with closer spaced outlets.",
            "sltIncompleteQuote" : "This quote file was saved with a previous version of the program, but was incomplete when it was saved.  Incomplete quotes from previous versions cannot be viewed.",
            "sltEndGunPressTooHigh" : "Excessively high pressure at the end gun may result in reduced coverage, increased wind drift, improper operation and/or decrease the life expectancy of the end gun or other related components.",
            "sltCPDigitalEndGun" : "Digital precision, programmable control of end gun, depth adjustment & independent Fwd/Rev speeds for up to ten different sections of the field",
            "sltPressureTransducerRestartText" : "Shuts system down by adjustable low pressure presets, can restart when pressure rises to presets, and allows monitoring pressure with telemetry options",
            "sltPressureTransducerText" : "Shuts system down by adjustable low pressure presets and allows monitoring pressure with telemetry options.",
            "sltNoSprinklers" : "Please contact Reinke Dealer Support for sprinkler package pricing on the swing arm, parent system or both as no sprinkler package is included in this proposal.",
            "sltCropXProbelessIrrigationSchedulingFee" : "A CropX Probeless Irrigation Scheduling service will be provided with this system for a separate fee of $600.00 annually.",
            "sltSystemPressureTooHighWarning" : "Operating pressures above 100 PSI void any Warranties described in the Reinke Irrigation Systems Limited Warranty.",
            "sltSystemPressureTooHighWarning2" : "You Must Reduce the \"System GPM\" and/or \"System End Pressure\" and/or \"Increase the Pipe Diameter\" of the system span lengths to Reduce the total system Pressure Loss to continue.",
            "sltFlexCantBeLongerThanFlanged" : "The length of the Flex Extension side must not exceed the length of Flanged Parent side.  You must shorten the Flex Extension side or lengthen the Flanged Parent side before continuing.",
            "sltBBoxWarning" : "The B-Box is usually placed inboard of any disconnect spans. This may cause nuisance shut downs at low speed settings when all spans are attached.",
            "sltBBoxMissing" : "The location of the disconnect span does not allow for the use of a B-Box. There will be no safety shutdown when system has stalled.",
            "sltTARTASWarning" : "A Tower Auto Stop or Tower Auto Reverse with Barricades is recommended to prevent the system from moving beyond the field boundaries causing physical damage to the system and/or property.",
            "slt6InchValveWarning" : "A 6\" Sleeve Valve has been specified but not yet ordered.  Nelson Mainline Sleeve valves can be ordered under Ancillary Equipment",
            "sltRegsNeededOnSAC" : "Sprinklers, on the Parent System, may experience operating pressures that exceed their specified range when the Swing Arm is Retracted.  Pressure Regulators are recommended on the entire system.",
            "sltFullLoadAmps" : "Full Load Amps",
            "sltHoseDropWeightWarning" : "No hose drop weights or device weights have been selected.",
            "sltPurchaseOrder" : "Dealer Purchase Order",
            "sltCustomerQuote" : "Customer Irrigation System Proposal",
            "sltCustomerContract" : "Customer Purchase Agreement",
            "sltElectricalReport" : "Electrical Report",
            "sltPartsList" : "Parts List",
            "sltPartsMaster" : "Parts Master",
            "sltSprinklerChart" : "Sprinkler Chart",
            "sltOpenQuote" : "Open Proposal",
            "sltQuoteFileAllFiles" : "Proposal File (*.zip;*.xml)|*.zip;*.xml|All Files (*.*)|*.*",
            "sltQuoteArchiveFileAllFiles" : "Proposal File (*.zip)|*.zip|All Files (*.*)|*.*",
            "sltInvalidESAC125DistFactors" : "Invalid File Format for ESAC 12.5 Distribution Factors!",
            "sltImportDistributionFlowFactors" : "Import Distribution Flow Rate Factors for ESAC 12.5",
            "sltGPSCoverageStudyYesCompleteDetails" : "When GPS Coverage Study is Completed, the following details are required: \"Network\", \"Site\".",
            "sltGPSCoverageStudyNoCompleteDetailsPivot" : "When GPS Coverage Study is NOT Completed, the following details are required: \"Network\", \"Site\", \"Base Location\", \"Pivot Location\".",
            "sltGPSCoverageStudyNoCompleteDetailsLateral" : "When GPS Coverage Study is NOT Completed, the following details are required: \"Network\", \"Site\", \"Base Location\", \"Lateral Start Location\", \"Lateral End Location\".",
            "sltGPSCoverageStudyCompletedYesMessage" : "This site will be added to the current study. This may result in requiring additional equipment and may cause a delay in shipping the radio.",
            "sltGPSCoverageStudyCompletedNoMessage" : "A coverage study will have to be run for this network. This requires additional time and may result in the need for additional equipment and potential delay in shipping the radio(s).",
            "sltDistributionFlowFactorsFileAllFiles" : "Dist Flow Factors File (*.csv)|*.csv|Dist Flow Factors File (*.txt)|*.txt|All Files (*.*)|*.*",
            "sltDoYouWantToRevert" : "Do you want to revert to the saved {0}?",
            "sltCropXNetworkProviderRequired" : "Selection of Network Provider is required with any selection of CropX product.",
            "sltCreateDefaultDirectoryFailed" : "Attempt to create directory failed with the following error: . Please set the \"Default Proposal Directory\" in Config - Options or clear the value to use the program default.",
            "sltQuantityMustBeGreaterThan0" : "Quantity must be greater than 0.",
            "sltSystemType" : "System Type",
            "sltBeginningOfSystem" : "Beginning Of System",
            "sltMaxigatorGuidance" : "Lateral Move Guidance",
            "sltEndOfSystem" : "End Of System",
            "sltSwingArmCorner" : "Swing Arm Corner",
            "sltSprinklers" : "Sprinklers",
            "sltQuoteInfo" : "Proposal Information",
            "sltDealerInfo" : "Dealer Information",
            "sltPhone" : "Phone:",
            "sltFax" : "FAX:",
            "sltMobile" : "Mobile:",
            "sltOk" : "OK",
            "sltCancel" : "Cancel",
            "sltAboutReinkeSys" : "About Reinke Systems",
            "sltPricesSubjectToChange" : "Prices subject to change without notice.",
            "sltCopyright" : "Copyright © 1997-{0} Reinke Manufacturing Co., Inc.",
            "sltThreeWheelTower" : "3-Wheel Tower",
            "sltOf" : "of",
            "sltSACAcres" : "SAC Acres:",
            "sltOptions" : "Options",
            "sltDisconnect" : "Disconnect",
            "sltDisconnect-Abrv" : "Disc",
            "sltRecaps" : "Recaps",
            "sltCenterPivot" : "Center Pivot",
            "sltHoseFeedMaxigator" : "Hose Feed Lateral Move & CF200",
            "sltCanalFeedMaxigator" : "Canal Feed Lateral Move",
            "sltKwikTow" : "Kwik Tow",
            "sltSwingArmRetroKit" : "Swing Arm Retro Kit",
            "sltCenterDrive" : "Center Drive",
            "sltTires" : "Tires",
            "sltIntake" : "Intake",
            "sltBoosterPumpSelfCleaning" : "Booster Pump - Self Cleaning Screen",
            "sltWheelCoating" : "Wheel Coating",
            "sltGalvanized" : "Galvanized",
            "sltWaterFeed" : "Water Feed",
            "sltEnd" : "End",
            "sltCenter" : "Center",
            "sltButterflyValves" : "Butterfly Valves",
            "sltFuelTank" : "Fuel Tank",
            "sltGenerator" : "Generator",
            "sltMurphyPanel" : "Murphy Panel",
            "sltGeneratorModel" : "Generator Model",
            "sltEngine" : "Engine",
            "sltPump" : "Pump",
            "sltOnly10FtOfSuction" : "Only 10' of suction extension may be ordered.",
            "sltSaveReportAs" : "Save Report As",
            "sltFinalDisposition" : "Final Disposition",
            "sltReportFileAllFiles" : "Report File (*.html)|*.html|All Files (*.*)|*.*",
            "sltStandard" : "Standard",
            "sltLowProfile" : "Perfil Bajo",
            "sltSpanCable" : "Span Cable",
            "sltTowerBoxCert" : "Tower Box UL/C Certification (Req. in WA && Canada)",
            "sltHeavyDuty" : "Heavy Duty",
            "sltNone" : "None",
            "sltCF200PumpOptionText1" : "15Hp Motor with 4RB Pump\n(500-750GPM @ 28-22psi)",
            "sltCF200PumpOptionText2" : "20Hp Motor with 4RB Pump\n(500-850GPM @ 36-27psi)",
            "sltCF200PumpOptionText3" : "25Hp Motor with 4RB Pump\n(500-900GPM @ 43-34psi)",
            "sltLastWheelTrack" : "Last Wheel Track (in feet):",
            "sltTowerAutoReverse" : "Tower Auto Reverse",
            "sltControlPanel" : "Control Panel",
            "sltPleaseCompleteProposalWShipDate" : "Please complete the Proposal Information, including Customer Name, Address, City, State, Zip Sales Person, Ship Via and Ship Date.",
            "sltPowerTowerEnd" : "Power Tower End Boom",
            "sltE2100" : "E2100",
            "sltE2085" : "E2085",
            "sltLargeServiceWireKit" : "Large Service Wire Adapter Kit",
            "sltS2085G" : "S2085G",
            "sltS2065G" : "S2065G",
            "sltEngineDrivenMini" : "Engine Driven Mini Pivot",
            "sltTowType" : "Tow Type",
            "sltReverseTow" : "Reverse Tow",
            "sltPivotCenter" : "Pivot Center",
            "sltSugargator" : "Sugargator",
            "sltSupergator" : "Supergator",
            "slt11x225" : "11 x 22.5",
            "slt112x24" : "11.2 x 24",
            "sltXtraHitchAssy" : "Extra Hitch Assembly",
            "sltLong" : "Long",
            "sltCollectorReel" : "Collector Reel",
            "sltGasket" : "Gasket",
            "sltCustomerInfo" : "Customer Information",
            "sltDealerPricing" : "Dealer Pricing",
            "sltCustomerPricing" : "Customer Pricing",
            "sltMiscItems" : "Miscellaneous Items",
            "sltDesignDetails" : "Design Details",
            "sltListPrice" : "List Price",
            "sltCustomerPrice" : "Customer Price",
            "sltPivotPad" : "+ Pivot Pad",
            "sltTradeIn" : "- Trade-In",
            "sltAquaValves" : "Aqua Valves",
            "sltDoubleEndFeed" : "Double End Feed",
            "sltPDFFileAllFiles" : "PDF File (*.pdf)|*.pdf|All Files (*.*)|*.*",
            "sltSystemComponents" : "System Components",
            "sltCopyReportToClipboard" : "Copy Report to Clipboard",
            "sltEMailReportAsAttachment" : "Email Report as Attachment",
            "sltStd_481_36Rpm" : "Std Speed, 40:1",
            "sltHigh_241_72Rpm" : "High Speed, 25:1 HDC",
            "sltHoseAndCouplers" : "Hose and Couplers",
            "sltCenterLift" : "Pivoting (Center Lift)",
            "slt4Inch" : "4\"",
            "slt6inch" : "6\"",
            "slt8Inch" : "8\"",
            "sltStainlessSteelMounts" : "Stainless Steel Mounts and Fittings",
            "sltMovable" : "Movable:",
            "sltPivotKit" : "Pivot Kit (Anchor Chains)",
            "sltHardHoseAdapter" : "Adapter Kit Only (Hard Hose)",
            "sltElectricDragCord" : "Electric Drag Cord:",
            "sltHoseLength" : "Hose Length",
            "sltStandardLowProfileStationary" : "Standard/Low Profile Stationary:",
            "sltOther" : "Other",
            "slt149x24" : "14.9 x 24",
            "slt169x24" : "16.9 x 24",
            "sltWireSizingObsolete" : "The Wire Sizing Tool is obsolete. The preferred wiring sizing tool is now found in the Electrical Report.",
            "sltTubesForTires" : "Tubes for Tires",
            "sltTowable" : "Towable",
            "sltAcres" : "Acres",
            "sltGuidanceType" : "Guidance Type",
            "sltCable" : "Cable",
            "sltBuriedWire" : "Buried Wire",
            "sltElectric" : "Electric",
            "sltManual" : "Manual",
            "sltGuidanceLocation" : "Guidance Location",
            "sltPowerTower" : "Power Tower",
            "sltLengthOfRun" : "Length of Run (in feet):",
            "sltFeetOfGuideCableReq" : "Feet of guide cable required:",
            "sltRollsOfGuideCableRec" : "Rolls of guide cable recommended:",
            "sltFeetOfBuriedCableReq" : "Feet of buried wire required:",
            "sltRollsOfBuriedCableRec" : "Rolls of buried wire recommended:",
            "sltDownloading" : "Documents are currently downloading from the Internet. Please try again later.",
            "sltPanelModel" : "Panel Type",
            "sltGallons" : "Gallons",
            "sltLiters" : "Liters",
            "sltSaveReportToDisk" : "Save Report to Disk",
            "sltPressureTransducer" : "Pressure Transducer",
            "sltEMailProposalFileToReinke" : "E-Mail Proposal File to Reinke",
            "sltShippingMustBeInFuture" : "You cannot backdate the requested ship date.",
            "sltPhoneLink" : "Digital Phone Link",
            "sltFor6InchValve" : "For 6\" Valve",
            "sltFor6x19FlangeDevice" : "For 6\" x 19.7\" Flanged Device",
            "sltFor8x23FlangeDevice" : "For 8\" x 23.6\" Flanged Device",
            "sltPowerSupply" : "Power Supply",
            "sltLightningArrestor" : "Lightning Arrestor",
            "sltFrostControl" : "Frost Control",
            "sltInjectorPumpReceptacle" : "Injector Pump Receptacle",
            "sltLowPressureSafety" : "Low Pressure Safety",
            "sltGroundingBarRod" : "Grounding Bar/Ground Rod Kit (Req. in WA)",
            "sltMCPULCCert" : "MCP UL/C Certification (Req. in CO,ID,MN,OR,SD,WA && Canada)",
            "sltGroundFault" : "Ground Fault Interrupter (Req. in Canada)",
            "sltPACTimer" : "PAC Timer",
            "sltHours" : "Hours",
            "sltSpans" : "Spans",
            "sltAlumIVLongitudinal" : "Alum IV Longitudinal Support Rods",
            "sltLongSysAlignment" : "Long System Alignment (Recommended on systems longer than 9 spans.)",
            "sltSpan" : "Span",
            "sltType" : "Type",
            "sltLength" : "Length",
            "sltSpacing" : "Spacing",
            "sltExt" : "Ext.",
            "sltDisc" : "Disc.",
            "sltDistance" : "Distance",
            "sltE2065" : "E2065",
            "sltE2060" : "E2060",
            "sltE2665" : "E2665",
            "sltE2660" : "E2660",
            "sltAlumIV" : "Alum IV",
            "sltA80G" : "A80G",
            "sltA60G" : "A60G",
            "sltDiameter" : "Diameter",
            "sltInchAbbr" : "\"",
            "sltTools" : "Tools",
            "sltLease" : "Lease",
            "sltLeaseComparison" : "Lease Comparison",
            "sltLoan" : "Loan",
            "sltLoanComparison" : "Loan Comparison",
            "sltBlendedRate" : "Blended Rate",
            "sltBlue" : "Blue",
            "sltRed" : "Red",
            "sltOutletSpacing" : "Outlet Spacing",
            "slt40inches" : "40\"",
            "slt57inches" : "57\"",
            "slt114inches" : "114\"",
            "slt4footSpanExt" : "4' Span Extension",
            "sltSpanDisconnect" : "Span Disconnect",
            "sltFirstMaxi" : "A {0} span is not valid as the first span on Canal or Hose Feed Lateral Moves.",
            "sltTowers" : "Towers",
            "sltLeft" : "Flex Extension",
            "sltRight" : "Flanged Parent",
            "sltTower" : "Tower",
            "sltTowerType" : "Tower Type",
            "sltPleaseCompleteProposalWOShipDate" : "Please complete the Proposal Information, including Customer Name, Address, City, State, Zip and Sales Person.",
            "sltGdnc" : "Gdnc.",
            "sltEndGunAcres" : "End Gun Acres:",
            "slt112x38" : "11.2 x 38",
            "sltEndBoom" : "End Boom",
            "sltEndLight" : "End Light",
            "sltWrapAroundSpanSwingArmCorner" : "Swing Arm Corner is not allowed with a Wrap Around Span.",
            "sltEndGunType" : "End Gun Type",
            "sltSenningerLowAngleIWobBlue" : "9-G Blue Low Angle I-Wob",
            "sltEndGunWillWater" : "End Gun/SAC Irrigated Acres",
            "sltBoosterPump" : "Booster Pump",
            "slt2HP" : "2 HP",
            "slt5HP" : "5 HP",
            "sltEndGunValve" : "End Gun Valve",
            "slt800" : "Nelson 800",
            "slt136x38" : "13.6 x 38",
            "sltSwitchMount" : "Switch Mount",
            "sltPipeCoating" : "Pipe Coating",
            "sltAirCompressor" : "Air Compressor",
            "sltFeetOfPerimeterRequired" : "Feet of perimeter wire required: {0}.",
            "sltRollsOfPerimeterRecommended" : "Rolls of perimeter wire recommended: {0}",
            "sltFeetOfRadiusRequired" : "Feet of radius wire required: {0}.",
            "sltRollsOfRadiusRecommended" : "Rolls of radius wire recommended: {0}",
            "sltOutlets" : "Outlets",
            "sltOutlet" : "Outlet",
            "sltLoc" : "Loc.",
            "sltPackage" : "Package:",
            "sltNoz" : "Noz",
            "sltSpace" : "Space",
            "sltPSI" : "PSI",
            "sltBar" : "bar",
            "sltGPMR" : "GPMR",
            "sltGPMD" : "GPMD",
            "sltGPML" : "GPML",
            "sltHook" : "Hook",
            "sltPlug" : "Plug",
            "sltAlum" : "Alum",
            "sltSolarPowered" : "Solar Powered",
            "sltFlexTower" : "Flex Tower",
            "sltAgriTrac" : "Agri-TRACS",
            "sltPrint" : "Print",
            "sltFinancialTools" : "Financial Tools",
            "sltGroundClearanceCannotZero" : "Ground Clearance cannot equal 0.",
            "sltDesignTools" : "Design Tools",
            "sltFieldDesigner" : "Field Designer",
            "sltPleaseSelectBrand" : "Please select a sprinkler brand.",
            "sltDEERENOTINCLUDED" : "ONE OR MORE ITEMS NOT INCLUDED",
            "sltRequiredKWs" : "Required kW",
            "sltResolver" : "Resolver",
            "sltLowVoltageSafety" : "Low Voltage Safety (NOT to be used with Phase Converters!)",
            "sltBoomBack" : "Boom Back",
            "sltPlateOptions" : "Plate Options",
            "sltSavePDFAs" : "Save PDF As",
            "sltSACPathCreatedBy" : "SAC Path Created By",
            "sltRedChemPlateMustBe" : "Red Chem Plate must be used in addition to another plate.",
            "sltBoomBacks" : "Boom Backs",
            "sltWrapAroundSpan" : "Wrap Around Span",
            "sltWrap" : "Wrap",
            "sltSpanStabilizers" : "Span Stabilizers",
            "sltSenninger180Fan" : "Senninger 180° Fan Spray",
            "sltValveOptions" : "Valve Options",
            "sltSpanIrrigationInfo" : "Span Irrigation Information",
            "sltAccept" : "Accept",
            "sltReject" : "Reject",
            "sltPromptAdvancePlusPanelWithRC10d" : "Ordering the Advanced Plus panel with RC10d instead of the Advanced panel with RC10i will not only be more economical but will also provide more features and functionality. Select \"Reject\" to return to the MCP screen to change your panel selection to Advanced Plus.",
            "sltSystemGPM1" : "System GPM",
            "sltMaximumSystemGPM" : "Max System GPM",
            "sltMaximumSystemCMH" : "Max System m3/h",
            "sltMinTopInletPSIatMaxGPM" : "Min Top Inlet PSI @ Max System Irrigation Rate",
            "sltMinTopInletBaratMaxGPM" : "Min Top Inlet PSI @ Max System Irrigation Rate",
            "sltMinimumSystemGPM" : "Min System GPM",
            "sltMinimumSystemCMH" : "Min System m3/h",
            "sltRightSideGPM" : "Flanged Side GPM",
            "sltSystemInformation" : "System Information",
            "sltPivotCenterWalkway" : "Pivot Center Walkway",
            "sltPivotTopPressure" : "Top of Inlet Pressure",
            "sltS2065PL" : "PL2065G",
            "sltPressure" : "Pressure",
            "sltLeftSideGPM" : "Flex Side GPM",
            "sltPowerTowerGalv" : "The water bearing power tower components are galvanized.",
            "sltPivotAutoStopWithCamPlateUses11CReel" : "Pivot Auto Stop with Cam Plate will use the 11th ring in the collector reel.",
            "sltMaxiSlopeWarning" : "Maximum running slope limitations - side slope 4%, direction of travel 5%.",
            "sltElevation" : "Elevation",
            "sltMaxi" : "Maxi",
            "sltEndPressureBelowRegulatorRequirements" : "System End Pressure must be 5 PSI above highest Regulator Pressure.",
            "sltAPackageCannotBePlaced" : "A package cannot be placed after a package with End equal to EOS.",
            "sltThePackagesMustBePlaced" : "The packages must be placed in order by their end location.",
            "sltTheElevationToEndPressure" : "The elevation to end pressure ratio will require regulators to be used on the entire system.",
            "sltTheLastPackageMustBeConfigured" : "The last package must be configured with End equal to EOS.",
            "sltSystemGPMCannotBeZero" : "System GPM cannot be zero.",
            "sltSystemGPMMax5500PSI" : "System GPM maximum is 5500 GPM.",
            "sltEndPressureCannotBeZero" : "End Pressure cannot be zero.",
            "sltEndPressureMin15PSI" : "End Pressure minimum is 15 PSI.",
            "sltEndPressureMin3PSI" : "End Pressure minimum is 3 PSI.",
            "sltEndPressureMax100PSI" : "End Pressure maximum is 100 PSI.",
            "sltInvalidSpanType" : "Invalid Span Type used on Span Number {0}.",
            "sltOneOrMoreSpansAreOver" : "One or more spans are excessively overwatering.",
            "sltOneOrMoreSpansAreUnder" : "One or more spans are excessively underwatering.",
            "sltAlumIVOnMaxi" : "Reinke Lateral Move systems should not be designed with Aluminum IV spans in row crop farming situations.",
            "sltInOrderToProvideExceededNotMet" : "In order to provide the best flow rate, nozzle pressure was exceeded {0} and was not met {1} times.",
            "sltInOrderToProvideExceeded" : "In order to provide the best flow rate, nozzle pressure was exceeded {0} times.",
            "sltInOrderToProvideNotMet" : "In order to provide the best flow rate, nozzle pressure was not met {0} times.",
            "sltLoss" : "Loss",
            "sltGPMReq" : "GPM Req",
            "sltGPMDel" : "GPM Del",
            "sltWaterPercent" : "Water %",
            "sltTheSystemCannotSpecified" : "THE SYSTEM CANNOT BE ORDERED AS SPECIFIED",
            "sltWarnings" : "WARNINGS",
            "sltPkg" : "Pkg.",
            "sltPackageType" : "Package Type",
            "sltRegulators" : "Regulators",
            "slt6" : "6",
            "slt10" : "10",
            "slt15" : "15",
            "slt20" : "20",
            "slt25" : "25",
            "slt30" : "30",
            "slt40" : "40",
            "sltSprinklerPackage" : "Sprinkler Package",
            "sltBlueRotatorsNotAllowedWithBoomBacks" : "Blue and White Rotators not allowed with Boom Backs.",
            "sltESPNotAllowedWithBoomBacks" : "Energy Saver Package cannot be used with Boom Backs.",
            "sltEndTowerPositioning" : "End Tower Positioning",
            "sltSenningerConstantPlastic" : "12° Medium Spacing Plastic Impact",
            "sltSenningerVariablePlasticWhite" : "6° Double White Vane Plastic Impact",
            "sltDealer" : "Dealer",
            "sltSenningerSuperSpray" : "Super Spray",
            "sltSenningerLDNSpray" : "LDN Spray",
            "sltSenningerQuadSpray" : "Quad Spray",
            "sltDualNozzleClip" : "Dual w/Nozzle",
            "sltFeet" : "feet",
            "sltRegulatorOptions" : "Regulator Options",
            "sltDropOptions" : "Drop Options",
            "sltFittingOptions" : "Fitting Options",
            "sltInvestment" : "Investment",
            "sltSpacingMustBeBetween" : "Spacing must be between {0} and {1} feet. Please be sure you have finished configuring your Drop options.",
            "sltRegulatorsAreRequired" : "Regulators are required on Double End Feed systems.",
            "sltYouMustCompleteDropsExiting" : "You must complete the drops configuration of this spinkler package before exiting this screen.",
            "sltYouMustCompleteFittingsExiting" : "You must complete the fittings configuration of this spinkler package before exiting this screen.",
            "sltYouMustCompleteSprinklerExiting" : "You must complete the plate configuration of this spinkler package before exiting this screen.",
            "sltNelsonBlue" : "Nelson Blue",
            "sltSennLFMF" : "Senn. LF/MF",
            "sltSennPSR" : "Senn. PSR",
            "sltWarningTheFollowingOptionsReset" : "Warning: The following options will be reset because of the package change:",
            "sltNonDiscountedSpecialOrderPrice" : "Non-Discounted Special Order Price",
            "sltWarningTheFollowingOptionsResetRegulator" : "Warning: The following options will be reset because of the regulator option change:",
            "sltWarningTheFollowingOptionsResetDrop" : "Warning: The following options will be reset because of the drop option change:",
            "sltWarningTheFollowingOptionsResetFitting" : "Warning: The following options will be reset because of the fitting option change:",
            "sltTowerAutoStop" : "Tower Auto Stop",
            "sltStop" : "Stop",
            "sltBarricades" : "Barricades and Trailing Tire Plate",
            "sltTowerInconsistentWithCenterPivot" : "The center pivot and tower heights are inconsistent.",
            "sltFlushValve" : "Flush Valve",
            "sltSafetyValve" : "Safety Valve",
            "sltBuckandBoostTransformer" : "Buck && Boost Transformer",
            "slt15KVA" : "15 KVA",
            "slt22_5KVA" : "22½ KVA",
            "sltJackFootKit" : "Jack Foot Kit",
            "sltPivotLight" : "Pivot Light",
            "slt5KW" : "Lima 5 kW",
            "slt7_5KW" : "Lima 7.5 kW",
            "slt10KW" : "Lima 10 kW",
            "slt12KW" : "Lima 12 kW",
            "slt15KW" : "Lima 15 kW",
            "slt20KW" : "Lima 20 kW",
            "slt25KW" : "Lima 25 kW",
            "slt30KW" : "Lima 30 kW",
            "sltGeneratorSlideRails" : "Slide Rails",
            "sltCashDiscount" : "Cash Discount (%)",
            "sltSalesTax" : "Sales Tax (%)",
            "sltSystem" : "System",
            "sltInstallation" : "Installation",
            "sltFreight" : "Freight",
            "sltDescription" : "Description",
            "sltPrice" : "Price",
            "sltStandardSystem": "Standard System",
            "sltAncillary": "Ancillary",
            "sltTotalOptions": "Total Options",
            "sltTotalAncillary": "Total Ancillary",
            "sltTotalUpgrades": "Total Upgrades",
            "sltPercent" : "Percent",
            "sltUpgrades" : "Upgrades",
            "sltTotalPrice" : "Total Price",
            "sltDownPayment" : "Down Payment",
            "sltDueOnDelivery" : "Due on Delivery",
            "sltDueOnInstallation" : "Due on Installation",
            "sltTerms" : "Terms",
            "sltCash" : "Cash",
            "sltFirstNationalLease" : "First National Equipment Financing",
            "sltNotConfigured" : "Not configured.",
            "sltDownloading1" : "Downloading…",
            "sltCenterPivotSection" : "Center Pivot Section",
            "sltPivotRisePipe" : "Pivot Rise Pipe",
            "sltAnchorBoltPackage" : "Anchor Bolt Package",
            "sltRussian" : "Russian",
            "sltDrainCouplers" : "Drain Couplers",
            "sltCompleted" : "Completed.",
            "sltLengthOfRunMustBe" : "Length of Run must be at least 15 feet.",
            "sltGuideCableRolls" : "Guide Cable Rolls",
            "sltExtraCableStop" : "Extra Cable Stop",
            "sltBuriedWireRolls" : "Buried Wire Rolls",
            "sltVRISection" : "New VRI Restrictions",
            "sltCanalFeedSection" : "Canal Feed Section",
            "sltBoosterPumpSelfCleaningScreen" : "Booster Pump Self Cleaning Screen",
            "sltSuctionPipeQuantity" : "Only 10' of suction extension may be ordered",
            "sltControlPanelSection" : "Control Panel Section",
            "sltCropXSection" : "CropX Section",
            "sltRC10Section" : "RC10 Section",
            "sltNetworkProvider" : "Network Provider",
            "sltCropXQtyNotZero" : "Equipment Quantity Not Zero",
            "sltRAMSWeather" : "Rain Gauge",
            "sltRAMSWeatherWind" : "Wind Vane",
            "sltRAMSFlowmeter" : "Flowmeter",
            "sltMCPSpanCableDisconnect" : "MCP Span Cable Disconnect",
            "sltRemoteControlPanel" : "Remote Control Panel",
            "sltEnhancedWaterPackage" : "Enhanced Water Package",
            "sltPowerCord" : "Power Cord",
            "sltPowerCordMustBe0to40Feet" : "Power Cord Length must be 0-40 feet.",
            "sltControlLineBoosterESP" : "Control Line Booster ESP",
            "sltSACConductorOption" : "Minimum Conductor Count Incorrect",
            "sltKwikTowSection" : "Kwik Tow Section",
            "sltWarrantyOverview" : "Warranty Overview",
            "sltLastWheelTrackMustBeAtLeast" : "Last Wheel Track must be at least 50 feet and less than 6000 feet.",
            "sltTASorTARRequiredWithWAS" : "Tower Auto Stop or Reverse is required after Wrap Around Span.",
            "sltEndGunShutoffOverride" : "End Gun Shutoff Override",
            "sltGenStartRunControl" : "Generator Start/Run Control",
            "sltPowerAutoRestart" : "Power Auto Restart",
            "sltChemAuxControl" : "Chem/Aux Control",
            "sltTemperatureProbe" : "Temperature Probe",
            "sltDEFSafetyValve" : "DEF Safety Valve",
            "sltJackKit" : "JackKit",
            "sltElectricTowKit" : "Electric Tow Kit",
            "sltJunctionBoxWithPlug" : "Junction Box W/Plug",
            "sltBuckandBoostTransformer1" : "Buck and Boost Transformer",
            "sltDoYouWantSaveChanges" : "Do you want to save the changes you made to {0}?",
            "sltProposalReadOnlySaveChangesAs" : "This Proposal has been submitted as an Order and is now Read Only. {0}Do you want to use \"Save As\" to persist changes as a New Proposal?",
            "sltProposalReadOnlySaveChangesAs2" : "This Proposal has been submitted as an Order and is now Read Only.",
            "sltSaveQuoteAs" : "Save Proposal As",
            "sltYouCannotUseSameFilename" : "You cannot use the same filename as an open proposal.",
            "sltZone" : "Zone",
            "sltTheQuoteFileObsoleteConfig" : "The proposal contains an invalid configuration.",
            "sltRightSpans" : "Flanged Parent Spans",
            "sltRightSpansNotConfigured" : "Flanged Parent Spans not configured.",
            "sltLeftSpans" : "Flex Extension Spans",
            "sltLeftSpansNotConfigured" : "Flex Extension Spans not configured.",
            "sltRightTowers" : "Flanged Parent Towers",
            "sltRightTowersNotConfigured" : "Flanged Parent Towers not configured.",
            "sltLeftTowers" : "Flex Extension Towers",
            "sltLeftTowersNotConfigured" : "Flex Extension Towers not configured.",
            "sltRightEndOfSystem" : "Flanged Parent End of System",
            "sltRightEndOfSystemNotConfigured" : "Flanged Parent End of System not configured.",
            "sltLeftEndOfSystem" : "Flex Extension End of System",
            "sltLeftEndOfSystemNotConfigured" : "Flex Extension End of System not configured.",
            "sltEndOfSystemNotConfigured" : "End of System: Not configured.",
            "sltPowerTowerEndBoomNotConfigured" : "Power Tower End Boom not configured.",
            "sltRightSprinklers" : "Flanged Parent Sprinklers",
            "sltLeftSprinklers" : "Flex Extension Sprinklers",
            "sltReverseTowAlum" : "Because Tower Number {0} will be equipped as a Reverse Tow, Span Number {1} cannot be an Aluminum span.",
            "sltDeerePrice" : "Please consult Reinke for pricing on John Deere engines.",
            "sltSenningerXiWobBlue" : "Senninger Xi-Wob Blue",
            "sltEstEOSSprinklerCoverage" : "Est. EOS Sprinkler Coverage",
            "sltFiltersAreRequiredWithVFDs" : "NOTE: FILTERS are REQUIRED when VFDs are in USE!",
            "sltCoverage" : "Coverage",
            "sltPressureLoss" : "Pressure Loss",
            "sltMaxSprinklerSpacing" : "Max. Sprinkler Spacing",
            "sltRequiredSprinklerGPM" : "Required Sprinkler GPM",
            "sltGPM" : "GPM",
            "sltCMH" : "m3/h",
            "sltSelectNozzle" : "Select Nozzle",
            "sltSelectPressure" : "Select Pressure",
            "sltGPMPerAcre1" : "GPM Per Acre",
            "sltCMHPerHectare" : "m3/h Per Hectare",
            "sltEndPressureIsZero" : "End Presure is zero.",
            "sltTheSystemGPMAndPressureNotSufficient" : "The System GPM and EndPressure are not sufficient to water the end of the system.",
            "sltPSIIsNegative" : "PSI is negative",
            "sltInsideDiameter" : "Inside Diameter",
            "sltCFactor" : "C Factor",
            "sltInsufficientGPMAtFeet" : "Insufficent GPM at {0} feet.",
            "sltSpacingRequirementExceedsNarrower" : "Spacing requirement exceeds the capablities of the sprinkler.  Use a narrower spacing.",
            "sltCriticalErrorPleaseContact" : "Critical error.  Please contact Reinke Manufacturing and report this error.",
            "sltRegulatorPressureNotMetRaise" : "Regulator pressure requirement not met. Raise end pressure.",
            "sltRegulatorPressureExceedsAt" : "Regulator pressure exceeds package pressure at {0} feet. Please contact Reinke for assistance.",
            "sltRegulatorPressureDoesNotPackage" : "Warning: The Regulator PSI specified is too low for the selected sprinkler device. Increase the Regulator PSI (and System End Pressure if needed) or select a different sprinkler device to continue. NOTE: Use two Package Types if the lower pressure is desired on the remainder of the system length.",
            "sltInlinePressureTooHigh" : "Warning: The Inline PSI specified is too high for the selected sprinkler device. Add a Regulator or select a lower Regulator PSI (and lower the System End Pressure if needed) or select a different sprinkler device to continue.",
            "sltInlinePressureTooLow" : "Inline pressure is too low for the selected sprinkler device at {0} feet.",
            "sltDoYouWishContinueLow" : "Do you wish to continue designing the sprinkler package with the pressure too low?",
            "sltOutletSpacingExceedsDevice" : "Outlet spacing exceeds maximum spacing of device at {0} feet.",
            "sltUnableToReserveOutletESP" : "Unable to reserve an outlet for ESP at {0} feet.",
            "sltYouMustEitherLower" : "You must either use a pressure regulator or lower the system pressure.",
            "sltYouShouldEitherIncrease" : "You should either select a different sprinkler package or increase the system pressure.",
            "sltSprinklerSystemParameters" : "Sprinkler System Parameters",
            "sltHoseFeedSection" : "Hose Feed Section",
            "sltNotIncludedInPrice" : "NOT INCLUDED",
            "sltConsultReinkeEnginePump" : "Please consult your engine and pump size selections with the Reinke engineering department.",
            "sltHoseDiameter" : "Hose Diameter",
            "sltHoseQuantity" : "Hose Quantity",
            "sltEndGun" : "End Gun",
            "sltDiffuser" : "Diffuser",
            "sltTheNumberOfTowersSpans" : "The number of towers must equal the number of spans minus one, which is {0}.",
            "sltTheNumberOfTowersEqualSpans" : "The number of towers must equal the number of spans, which is {0}.",
            "sltTheCouplerOptionIsNotValidRTS" : "The Coupler option is not valid on Reverse Tow Systems.",
            "sltOnlyOneTowerBuckBoost" : "Only one tower per side may be equipped with a Buck and Boost Transformer.",
            "sltContractReinkeforPrice" : "CONTACT REINKE",
            "sltWheelGear740" : "740 Series Wheel Gear",
            "sltE2045FoundationFormKit" : "E2045 Foundation Form Kit",
            "sltSpanJointsMixed" : "This span configuration may result in the use of both stainless and non-stainless steel span joints.",
            "sltDragHoseWith136x38Tire" : "The drag hose should be shortened by 10% or more due to the use of the 13.6 x 38 tire.",
            "sltSystemPrice" : "System Price",
            "sltBWGFirstSpan" : "Buried Wire Guidance is not valid on the first tower of a hose feed end feed system.",
            "sltOnlyOneTowerMaxigator" : "Only one tower per system may be equipped with Lateral Move Guidance.",
            "sltGPSBaseOptions" : "GPS Base Options",
            "sltMaxigatorNotConfiguredOuter" : "Lateral Move Guidance is not configured for outer towers.",
            "sltMaxigatorNotConfiguredSystem" : "Lateral Move Guidance is not valid on this type of system.",
            "sltMaxigatorRequiredOuter" : "Lateral Move Guidance is required – recommended on middle tower.",
            "sltASpanIsNotValidWithAn" : "A {0} span is not valid with an {1} span.",
            "sltOnlyOneSpanIsAllowedWith" : "Only one span is allowed with the Engine Driven Mini Pivot option.",
            "sltAgriInjectPrice" : "Agri-Inject Price",
            "sltRC10RetroPrice" : "RC10 Retro Price",
            "sltOnly24SpansAllowed" : "Only 24 spans are allowed.",
            "sltOnly8SpansAllowedRTS" : "Only 8 spans are allowed on Reverse Tow systems.",
            "sltDoubleEndFeedSystems2Spans" : "Double End Feed systems require at least 2 spans.",
            "sltOnly24SpansAllowedEntire" : "Only 24 spans are allowed on an entire system.",
            "sltBWGLastSpan" : "Buried Wire Guidance is not valid on the last tower.",
            "sltReplacesWarning" : "You must indicate what this system replaces.",
            "sltMissingModemBaseModelWarning" : "You must select a Modem Base Model.",
            "sltEndTowerPositioningWarning" : "End Tower Positioning requires a selection.",
            "sltSACRetroOptionsRequiredWarning" : "Selection required for one of two options: Either \"Send Tower Box Kit\" or \"Send New Last Span Wire\".",
            "sltSACRetroDropTypeGalvanizedWarning" : "Using galvanized rigid drops will prohibit the selection of Air Compressor on the Swing Arm configuration.",
            "sltSACRetroDropTypePvcPolyWarning" : "Using PVC or Polyethylene rigid drops with the selection of Air Compressor on the Swing Arm configuration will add Flex Gooseneck parts.",
            "sltESPPSI" : "ESP PSI",
            "sltMaxGPM" : "Max. System GPM",
            "sltMinGPM" : "Min. System GPM",
            "sltAnE1060SpanNotLast" : "An {0} span cannot be the last span.",
            "sltInPerson" : "In Person",
            "sltFollowUpDate" : "Follow-up Date",
            "sltHowContacted" : "How Contacted",
            "sltTelephone" : "Telephone",
            "sltDidNotBuy" : "Did not buy",
            "sltGuidance" : "G",
            "sltReason" : "Reason",
            "sltReinke" : "Reinke",
            "sltPartialCircleLDN" : "Partial Circle LDN",
            "sltIncluded" : "Included",
            "sltBarricadesWarning" : "Barricades are no longer ordered in pairs. Please confirm the correct quantity is ordered.",
            "sltNoFuelTankSelected" : "An engine has been selected with no fuel tank.",
            "sltPumpPressureAtGPM" : "Pump Pressure @ {0} GPM",
            "sltPleaseEnterShipVia" : "Please enter Ship Via information.",
            "sltMainlineLossAtGPM" : "Mainline Pressure Loss @ {0} GPM",
            "sltPumpPressuresCannotBeZero" : "The System GPM Pump Pressure cannot be less than {0}.",
            "sltDragHoseTooLong1" : "The selected hose length exceeds the maximum recommended hose length for the hose diameter and cart type chosen.",
            "sltDragHoseTooLong2a" : "Two-Wheel cart maximums are 400' x 4\" or 260' x 5\".",
            "sltDragHoseTooLong2b" : "Four-Wheel cart maximums are 560' x 4\" or 420 ' x 5\" or 300' x 6\".",
            "sltGroundClearanceMustBeLess" : "Ground Clearance must be less than 20 feet.",
            "sltValueBetween" : "Value must be between {0} and {1}.",
            "sltValueNotNumeric" : "Value must be numeric: {0}.",
            "sltMustHaveASpan" : "You must have a least one span.",
            "sltPleaseSelectAReport" : "Please select a report to view:",
            "sltTowerOptionsBadTower" : "{0} cannot be placed on this tower.",
            "slt230VoltSinglePhase" : "230-Volt Single Phase",
            "sltOnly5Spans230VoltSinglePhase" : "Only 5 spans are allowed on 230 volt single phase systems.",
            "sltKWRequired" : "kW or more req.",
            "sltCPManual" : "Manual System/Pump (On/Off) Controls",
            "sltOutletsSpacingInconsistent" : "The span outlet spacings on this system are inconsistent.",
            "sltCPControl" : "Control 2 end guns or 1 end gun & 1 auxiliary",
            "sltCPProgASAR" : "Programmable 2 pos. auto stop/auto reverse with delay at barriers",
            "sltCPProgPark" : "Programmable park",
            "sltCPAllFunctions" : "All functions performed with repeatable accuracy",
            "sltCPComputer" : "Computer technology for total programming control of all functions to 1/10 deg",
            "sltTheTotalLength1000feet" : "The total length of a 230 volt single phase system cannot exceed 1000'.",
            "sltLastMaxi" : "A 213' span can only be the last span on towable systems and Lateral Moves.",
            "sltFirstCFM" : "A {0} span cannot be the first span on a Canal Feed Lateral Move.",
            "sltSugargatorStationary" : "Sugargator Stationary:",
            "sltCPIncludes" : "Includes serial port for laptop connection to transfer data",
            "sltPanelLocation" : "Panel Location",
            "sltTWTwrFlex" : "3WTwrFlex",
            "sltSpanDisconnectNotAllowedDEFS" : "Span Disconnect is not allowed on a Double End Feed system.",
            "sltSpanDisconnectNotAllowedKTS" : "Span Disconnect is not allowed on the second span of a Kwik Tow system.",
            "sltSpanDisconnectNotAllowedHFEFS" : "Span Disconnect is not allowed on the second span of a Hose Feed End Feed system.",
            "sltFlexSide" : "Flex Side",
            "sltSpanDisconnectAllowedE65" : "Span Disconnect allowed only on spans that follow an E2065, an E2060, an E2665, or an E2660 span.",
            "sltSpanDisconnectNotAllowedFirst" : "Span Disconnect is not allowed on the first span.",
            "sltSpanDisconnectAllowedAlumIV" : "Span Disconnect is allowed only on E2065, E2060, E2665, and E2665  spans.",
            "sltFlangedSide" : "Flanged Side",
            "sltJunctionBoxWithOutPlug" : "Junction Box W/O Plug",
            "sltIntakeDrainValve" : "Intake Drain Valve",
            "sltRigid" : "R",
            "sltTheTotal6inch1560feet" : "The total length of the 6\" aluminum spans cannot exceed 1560'.",
            "sltTheTotalLength2600feet" : "The total system length cannot exceed 2600'.",
            "sltTheTotalLength1320feet" : "The total length of a Kwik Tow system cannot exceed 1320'.",
            "sltPadAdapterBolt" : "Pad Adapter Bolt",
            "sltTheTotalLength1600feetCenterLift" : "The Pivoting Center Lift option supports a maximum side length of 1600 feet.",
            "sltTheTotalLength3000feet" : "The total length of Lateral Move cannot exceed 3000'.",
            "sltTheTotalLength2000feet" : "The total length of each side of a Center Feed Lateral Move cannot exceed 2000'.",
            "sltDoYouWantToMade" : "Do you want to save the changes you made?",
            "sltTheChangesMadeSectionsReset" : "The changes you have made will require the settings in the following proposal sections to be reset:",
            "sltIllegalAccessInvalidData" : "Illegal access of invalid data in section",
            "sltIllegalAccessOutOfRangeData" : "Illegal access of out of range data in section",
            "sltMiscellaneousPartEntryNotFound" : "Miscellaneous part entry not found in parts master table",
            "sltNoEntryInOutletDatabase" : "No entry in outlet database for span code",
            "sltNoEntryInNozzleDatabase" : "No entry in nozzle database for package code",
            "sltIllegalRestrictionCheckBox" : "Illegal restriction on check box value.",
            "sltCPLogs" : "Logs up to 1000 of the latest events & 64 pivot operations",
            "slt60to1UMC" : "60:1 UMC",
            "slt40to1UMC" : "40:1 UMC",
            "sltLow_721_24Rpm" : "Low Speed, 60:1",
            "slt30to1UMC" : "30:1 UMC",
            "sltSprinklerChartNotCalculated" : "Sprinkler Package not calculated.",
            "sltSprinklerDualPackageMinimum2Spans": "Dual Sprinkler Package requires minimum of 2 Spans.",
            "sltCreateSprinklerChart" : "Calculate Sprinkler Package",
            "sltNelson15Single3RNBI" : "15° Single 3RN Brass Impact",
            "sltNelson15DoubleFCNBI" : "15° Double FCN Brass Impact",
            "sltNelson1518MediumSpacingBI" : "15/18° Medium Spacing Brass Impact",
            "sltGroundClearanceMustBeMore" : "Ground Clearance must be more than 3 feet.",
            "sltNelson15Double3RNBI" : "15° Double 3RN Brass Impact",
            "sltNelson8DoubleFCNPI" : "8° Double FCN Plastic Impact",
            "sltNelson8Double3RNPI" : "8° Double 3RN Plastic Impact",
            "sltSenningerVariablePlasticRed" : "6° Double Red Vane Plastic Impact",
            "slt10Gauge" : "#10",
            "slt12Gauge" : "#12",
            "sltVoltageTooHigh" : "Voltage exceeds 500 volts at tower {0}.",
            "slt230Volt" : "60:1 - 1HP 230V",
            "sltSenningerLowAngleIWobWhite" : "6-G White Low Angle I-Wob",
            "sltSenningerHighAngleIWobBlack" : "9-G Black Std. Angle I-Wob",
            "sltNoPackage" : "No Package",
            "sltF2L" : "F2L",
            "sltF3L" : "F3L",
            "sltF4L" : "F4L",
            "sltD905" : "D1005",
            "sltD1105" : "D1105",
            "sltV1505" : "V1505",
            "sltD1703" : "D1703",
            "sltV3300" : "V3300",
            "sltV2203" : "V2203",
            "sltAmps" : "Amps",
            "slt800P" : "800P",
            "slt2P85s" : "2 P85s",
            "slt1P85" : "1 P85",
            "sltSR100" : "SR100",
            "sltSR100NV" : "SR100NV",
            "sltPL2085G" : "PL2085G",
            "slt8Pound" : "#8",
            "sltTotalAmps" : "Total Amps",
            "sltArrangeAll" : "Arrange All",
            "sltCascade" : "Cascade",
            "sltKWs" : "kW",
            "sltClose" : "Close",
            "sltVoltage" : "Voltage",
            "sltCustomerPricingmnu" : "Customer Pricing…",
            "sltDealerPricingmnu" : "Dealer Pricing…",
            "sltDealerInformation" : "Dealer Information…",
            "sltExit" : "Exit",
            "sltFile" : "File",
            "sltHelp" : "Help",
            "sltNewProposal" : "New Proposal",
            "sltOpenProposalmnu" : "Open Proposal…",
            "sltOptionsmnu" : "Options…",
            "sltKVA" : "KVA",
            "sltRequiredGeneratorHP" : "Required Generator HP",
            "sltQuoteFileIsNewer" : "The quote file is newer than the version of the software you are using.  Please update to the latest version.",
            "sltReports" : "Reports",
            "sltReportType": "Report type",
            "sltSave" : "Save Proposal",
            "sltSaveAs" : "Save As…",
            "sltRemotePanelVoltageLoss" : "Remote Panel Voltage Loss",
            "sltTileHorizontal" : "Tile Horizontal",
            "sltTileVertical" : "Tile Vertical",
            "sltConfig" : "Config",
            "sltWindow" : "Window",
            "sltUSEnglish" : "U.S. English",
            "sltMetric" : "Metric",
            "sltDragCordVoltageLoss" : "Drag Cord Voltage Loss",
            "sltTotalListPrice" : "Total List Price",
            "sltTotalPriceIncomplete": "Total price is incomplete - price is missing for some components",
            "sltMinimumVoltage" : "Minimum Voltage",
            "sltDealerPOnum" : "Order #",
            "sltSerialnum" : "Serial #",
            "sltDateReceived": "Date Received",
            "sltPriceDate": "Price Date",
            "sltRequestedShipDate": "Requested Ship Date",
            "sltTradePc": "Trade %",
            "sltProgram": "Program",
            "sltInterestRateBuyDown": "Interest Rate Buy Down",
            "sltReplaces2" : "Replaces",
            "sltShippingNotes": "Shipping Notes",
            "sltSubmittedElectronically": "Submitted Electronically",
            "sltGL": "GL",
            "sltLegal" : "Legal:",
            "sltModel" : "Model",
            "sltTowerCount" : "Tower Count",
            "sltTireSize" : "Tire Size",
            "sltSystemLength" : "System Length",
            "sltPivot" : "Pivot",
            "sltLengthOfRun2": "Length of Run",
            "sltCPIncludesAux" : "Includes 4 additional auxiliary outputs (6 total)",
            "sltCPControlMonitoring" : "Control system with use of optional monitoring options",
            "sltSprinklerPackageInfo" : "Sprinkler Package Information",
            "sltRightEndPressure" : "Flanged Side End Pressure",
            "sltRightEndGun" : "Flanged Side End Gun",
            "slt1stEndGun": "1st End Gun",
            "slt2ndEndGun": "2nd End Gun",
            "sltLeftEndGun" : "Flex Side End Gun",
            "sltSystemAcres" : "System Acres:",
            "sltBoosterPump1" : "Booster Pump:",
            "sltRightBoosterPump" : "Flanged Side Booster Pump",
            "sltLeftBoosterPump" : "Flex Side Booster Pump",
            "sltTotalAcres" : "Total Acres:",
            "sltExtraConductors" : "Extra Conductors",
            "sltHoursToApplyOneInch" : "Hours to Apply 1\"",
            "sltDiscount" : "Discount",
            "sltCPDigitalDepth" : "Digital depth adjustment and independent Fwd/Rev speeds",
            "sltCPStartStop" : "Start/Stop, Fwd/Rev, Adjustable System Speed/Application Depth",
            "sltCP480V" : "480 volt, 3-phase surge protection",
            "sltFourPly" : "4-Ply",
            "sltSubTotal" : "Sub-Total",
            "sltFactoryNotes": "Factory Notes",
            "sltSixPly" : "6-Ply",
            "sltTotal" : "Total:",
            "sltTurf" : "Turf",
            "sltMachII" : "Mach II",
            "sltRhinogator" : "Rhinogator",
            "sltPaymentTerms" : "Payment Terms",
            "sltComments1" : "Comments:",
            "sltStandardFeatures" : "Standard Features",
            "sltGalvanizedHighTensileStrengthSteel" : "Galvanized high tensile strength steel pipeline material for years of corrosion protection",
            "sltChromiumNickelSteelAlloyPipelineMaterial" : "Chromium Nickel steel alloy pipeline material for years of corrosion protection",
            "sltMarineGradeAluminumPipelineMaterial" : "Marine grade aluminum pipeline material for years of corrosion protection",
            "sltHighTensileStrengthSteelUtilized" : "High Tensile Strength Steel utilized in all structural components for maximum durability",
            "sltHighTensileStrengthSteelProvidesHighest" : "High Tensile Strength Steel provides highest strength to weight ratio in the industry",
            "sltFrictionReducingFullSweepElbows" : "Friction reducing full sweep elbows reduces your pumping cost",
            "sltEndBoomsAreSupportedWithTrussRods" : "End Booms are supported with truss rods (not only cable) for more stability",
            "sltReinkeHeavyDutyWheelGearDesigned" : "Reinke Heavy Duty Wheel Gear designed especially for the demands of irrigation",
            "sltReinkeIrrigationDutyMotorsProvide" : "Reinke Irrigation Duty Motors provide years of reliable service",
            "sltDrivelineShieldsProtect" : "Driveline Shields protect you and your family",
            "sltInsulatedTowerBoxesProtect" : "Insulated Tower Boxes protect electrical components from condensation",
            "sltDoubleJacketedSpanCablesForExtra" : "Double Jacketed Span Cables for extra safety and long cable life",
            "sltCustomerOwnerName" : "Customer/Owner Name:",
            "sltPage" : "Page",
            "sltDate" : "Date:",
            "sltCPRestarts" : "Restarts system after power failure is restored",
            "sltQty" : "Qty",
            "sltRightSpanInfo" : "Flanged Parent Span Information",
            "sltLeftSpanInfo" : "Flex Extension Span Information",
            "sltSecTowerEndPressure" : "Sec. Tower End Pressure",
            "sltSecTowerEndGun" : "Sec. Tower End Gun",
            "sltSecTowerBoosterPump" : "Sec. Tower Booster Pump",
            "sltMainSystemGPM" : "Main System GPM",
            "sltPowerTowerEndGun" : "Power Tower End Gun",
            "sltPowerBoosterPump" : "Power Tower Booster Pump",
            "sltPowerEndBoomGPM" : "Power Tower End Boom GPM",
            "sltCPEndGunCycle" : "Allows shutting End Gun Cycle off at pivot panel",
            "sltMainlineValvePrice" : "Mainline Valve Price",
            "sltCustomerNameCannotBeDealerName" : "The Customer Name must be completed and cannot be the Dealer Name.",
            "sltPurchaserSign" : "Purchaser Signature:",
            "sltDealerSign" : "Dealer Signature:",
            "sltReinkeIrrigationSysSC" : "Customer Purchase Agreement",
            "sltReinkeIrrigationSysPO" : "Dealer Purchase Order",

            "sltDealernum" : "Dealer #:",
            "sltPRICESEFFECTIVE" : "PRICES EFFECTIVE:",
            "sltForRMCIUseOnly" : "For Reinke Use Only",
            "sltPleaseCompleteAddress" : "Please complete Farm/Ranch Address.",
            "sltPleaseCompleteCityStateZip" : "Please complete Farm/Ranch City, State, Country, Zip.",
            "sltFileMissing" : "File {0} is missing because this program cannot access the Internet.",
            "sltApplicationNotFoundPdf" : "Application not found to open PDF file {0}. Please ensure the appopriate PDF reader is installed.",
            "sltSenningerStandardAngleIWobGray" : "6-G Gray Standard Angle I-Wob",
            "sltNumber1" : "Number",
            "sltNumberNo": "No.",
            "sltWheelTrack" : "Wheel Trk",
            "sltPSILoss" : "PSI Loss",
            "sltBarLoss" : "Bar Loss",
            "sltPartNumber" : "Part Number",
            "sltUnitPrice" : "Unit Price",
            "sltExtendedPrice" : "Extended Price",
            "sltCustomerTerms" : "Customer Terms:",
            "sltDealerTerms" : "Dealer Terms:",
            "sltTradeDiscount" : "Trade Discount:",
            "sltCashDiscount1" : "Cash Discount:",
            "sltTowerRebate" : "Tower Rebate:",
            "sltApplicableSalesIP" : "Applicable Sales/Incentive Program:",
            "sltNetPrice" : "Net Price",
            "sltDownPayment10percent" : "Down Payment (10%):",
            "sltBalanceDue" : "Balance Due",
            "sltNewSystemPartsPriceList" : "New System Parts/Price List",
            "sltNewSystemMasterPartsPriceList" : "New System Master Parts/Price List",
            "sltLongSystemWireAlignmentRecommendedIfInstalled" : "Long System Wire Alignment recommended if installed on uneven terrain.",
            "sltFullyGusseted18InchPivotBearing" : "Fully gusseted 18 inch pivot bearing increases bearing life",
            "sltPivotFlexJointRelievesStress" : "Pivot Flex Joint relieves stress and improves pivot bearing life",
            "sltStainlessSteelRiserGasketSeatMaintains" : "Stainless Steel riser gasket seat maintains positive seal, reducing repair expense",
            "sltPipeBottomFitting" : "Pipe Bottom Fitting",
            "sltSpanInformation" : "Span Information",
            "sltDueToABoosterPumpLimitUnder" : "Due to a booster pump limitation, the end gun will underwater by {0}%. Use a larger booster pump or reduce total system flow.",
            "sltASpanCannotKT" : "A {0} span cannot be the first span of a Kwik Tow.",
            "sltSimeWing" : "Sime Wing",
            "sltTwinMax" : "Twin Max",
            "sltRAMSConstantOn" : "Constant On Kit",
            "sltOnly19Allowed" : "Only 1-9 Allowed.",
            "sltSpare" : "s",
            "sltTowerTireAndWheelAssembly" : "Tower Wheel and Tire Assembly",
            "sltNotValSpanLen" : "Not A Valid Span Length",
            "sltFullyUpgradeableMainControlPanelToMeet" : "Fully upgradeable Main Control Panel to meet your future needs",
            "sltSimpleEasyToUnderstandControlsMakes" : "Simple, easy to understand controls makes your Reinke easy to operate",
            "sltAdjustableMainControlPanelMountInsures" : "Adjustable Main Control Panel mount insures a location convenient for you",
            "sltOnlyOneSpanIsAllowedSSM" : "Only one span is allowed on Single Span Maxi's.",
            "sltEyeLevelPressureGaugeProvides" : "Eye level pressure gauge provides convenient, accurate readings",
            "sltDueToASpanCableLimitationTheMaximum" : "Due to a Span Cable limitation, the maximum number of extra conductors available is:",
            "sltCenteringRingOnGearboxPrevents" : "Centering ring on gearbox prevents lug bolt breakage",
            "sltThePreviousSpanHasExtraConductorsTheCurrent" : "The previous span has {0} extra conductors.  The current span must have {1} or less.",
            "sltUniqueVRingPipeFlangeSealReduces" : "Unique V-Ring pipe flange seal reduces your pumping and maintenance costs",
            "sltReinkeInternalFlexJointIncreases" : "Reinke Internal Flex Joint increases the life of rubber boots reducing repair costs",
            "sltHighStrengthSingleTowerLegsProtect" : "High strength single tower legs protect your systems water-pipe by absorbing the stress associated with uneven terrain",
            "sltSingleSpanMaxi" : "Single Span Maxi",
            "sltSR75" : "SR75",
            "sltAlumRodsRequired" : "6\" aluminum requires Alum IV Longitudinal Support Rods. Please select on the flanged spans screen.",
            "sltTheTotalLength1800feet" : "The total length of 4 Wheel Pivot Mover cannot exceed 1800'.",
            "sltSpanCableGaugeProblem" : "A larger gauge cable cannot follow a smaller gauge cable.",
            "sltSpanCableSpanDiscProblem" : "Only 10C cable can be used after the Span Disconnect.",
            "sltNonSingle2Spans" : "2 or more spans are required on Hose Feed Lateral Moves, unless Single Span Maxi is selected.",
            "sltLongestSwingArmCornerSystemNets" : "Longest Swing Arm Corner system nets more acres for your dollars",
            "sltLargeTrussRods" : "Truss Rods 3/4\"",
            "sltLoadOldQuote" : "This proposal file was saved by a previous version of Reinke Systems. The information can only be viewed.",
            "sltPatentedEnergySaverPackageImproves" : "Patented Energy Saver Package (ESP) improves pumping efficiency",
            "sltUniqueTowableReinkeGearboxMakes" : "Unique Towable Reinke Gearbox makes towing a Reinke easier",
            "sltStainlessSteel304LPipelineMaterial" : "Stainless Steel (304L) pipeline material for maximum corrosion protection",
            "sltLargerRingCollectorReelRequired" : "A larger collector reel may be required to support the number of cable conductors.",
            "sltCartAutoReverse" : "Cart Auto Reverse",
            "sltAgriTRACsInconsistent" : "The use of Agri-TRACS is not consistent on each tower.",
            "sltSourceVoltage" : "Voltage at MCP",
            "sltEndGunVoltage" : "Voltage at End Gun",
            "sltSpanWireSizing" : "Span Wire Sizing",
            "sltUndergroundWireSizing" : "Underground Wire Sizing",
            "sltMainLinePressureLoss" : "Main Line Pressure Loss",
            "sltSystemComparison" : "System Comparison",
            "sltAgriInject" : "Agri-Inject",
            "sltResolverWire" : "Resolver Wire",
            "sltNoSatSunShipDate" : "Requested Ship Date presently excludes Saturday and Sunday. Please select a weekday.",
            "sltShipDateValidationFailed" : "Real-time verification of Ship Date failed. Please try to Submit Online Order again, or contact Dealer Support.",
            "sltShipDateUnavailable" : "Real-time verification of Ship Date failed. (Scheduling Unavailable) Please select an available Requested Ship Date, or contact Dealer Support to request increased delivery scheduling for the desired date.",
            "sltPleaseEnterRequestedShipDate" : "Please enter a valid \"Requested Ship Date.\"  This is needed to print the purchase order.",
            "sltNotAValidPurchaseOrder" : "Not a valid purchase order.  Enter \"Requested Ship Date.\"",
            "sltTireType" : "Tire Type",
            "sltLocation" : "Location",
            "sltSpacingRange" : "Valid spacing range:",
            "sltTwoZoneMinimum" : "A minimum of two zones is required.",
            "slt10To50PSI" : "10-50 PSI",
            "slt30To200PSI" : "30-200 PSI",
            "slt18To80PSI" : "18-80 PSI",
            "sltManualOnOff" : "Manual On/Off",
            "slt5To50PSIPressureReducingValve" : "5-50 PSI Pressure Reducing",
            "slt25To120PSIPressureReducingValve" : "25-120 PSI Pressure Reducing",
            "slt25To200PSIPressureReducingValve" : "25-200 PSI Pressure Reducing",
            "sltSleeveExhaust" : "Sleeve Exhaust",
            "sltRateOfFlowValve" : "Rate-of-Flow",
            "slt12To24VDCLatchSolenoid_5AmpMax" : "12-24 VDC Latch Solenoid - 0.5 Amp Max",
            "slt12VDCSolenoid_6AmpMax" : "12 VDC Solenoid - 0.6 Amp Max",
            "slt24VDCSolenoid_3AmpMax" : "24 VDC Solenoid - 0.3 Amp Max",
            "slt24VACSolenoid_3AmpInrushCurrent" : "24 VAC Solenoid - 0.3 Amp Inrush Current",
            "slt24VACSolenoid1_1AmpInrushHeavyDuty" : "24 VAC Solenoid - 1.1 Amp Inrush Heavy Duty",
            "slt24VACSolenoid3_6AmpInrushCurrent" : "24 VAC Solenoid - 3.6 Amp Inrush Current",
            "sltElectricalFrequency" : "Electrical Frequency",
            "slt120VACSolenoid1_16Inch" : "120 VAC Solenoid 1/16\"",
            "slt120VACSolenoid1_8Inch" : "120 VAC Solenoid 1/8\"",
            "sltRemotePanelVoltage" : "Remote Panel Voltage Loss:",
            "sltHydraulicRemoteControl" : "Hydraulic Remote Control",
            "sltSaveVRI" : "Save VRI Zone File",
            "sltExternalFilter" : "External Filter",
            "sltInternalFilter" : "Internal Filter",
            "sltMainlineValve" : "Mainline Valve",
            "sltMainlineValves" : "Mainline Valves",
            "sltControlFunction" : "Control Function",
            "sltOnOffMode" : "On/Off Mode",
            "sltFilter" : "Filter",
            "sltQuantity" : "Quantity",
            "sltSize" : "Size",
            "sltControlFunctionOptional" : "Control Function Optional",
            "sltNoSolenoid" : "No Solenoid",
            "sltMaxControlledZones" : "The maximum number of controlled zones cannot exceed {0}.",
            "sltHeatExchangersPrice" : "Heat Exchangers Price",
            "sltCompleteDropsFirst" : "You must complete the drops configuration of this spinkler package before configuring the plates.",
            "sltCompleteFittingsFirst" : "You must complete the fittings configuration of this spinkler package before configuring the plates.",
            "sltSpecialOrderPart" : "SPECIAL ORDER PART",
            "sltSecondNozzleGPM" : "Second Nozzle GPM:",
            "sltSecondNozzleGPMMustExist" : "Second Nozzle Flowrate cannot be 0.",
            "sltConfirmVoltage" : "Please confirm the voltage values:",
            "sltImpactsNotAllowedWithBoomBacks" : "Impacts not allowed with Boom Backs.",
            "sltRegDropsRequiredWithBoomBacks" : "Regulators and drops are required with Boom Backs.",
            "sltTradeInNotes" : "Trade-In Notes",
            "sltInterestFreeUntil" : "Interest Free until",
            "sltLowInterest" : "Low Interest",
            "sltRebate" : "Rebate:",
            "sltPleaseEnterInterestFreeDate" : "Please enter an \"Interest Free Until\" date.",
            "sltRebateAgreementMsg" : "Rebate amount of ${0} to be sent to customer.",
            "sltNoticeOfRebate" : "Notice of Rebate Distribution",
            "sltVRIReset" : "Because the sprinkler chart is changing, the VRI Zone configuration will be reset.",
            "sltSecondGPMLimit" : "Second nozzle GPM must be less than  {0}.",
            "sltVRI" : "VRI",
            "sltDoYouWantToLoseScreen" : "Do you want to lose the changes you made?",
            "sltSprinklerBrand" : "Sprinkler Brand",
            "sltPressureTransducer0to25PSI" : "Use of the 0-25 PSI Transducer will NOT ALLOW the addition of an end gun and/or a Swing Arm Corner span.  Top of Inlet Pressure should not exceed 25 PSI.",
            "sltHoseFeedSoftHoseMin45PSIRequired" : "Hose Feed Lateral Systems with Soft Hose options require a minimum of 45 PSI at the Base of Inlet to prevent the hose from kinking. Increase system end pressure by {0} PSI to meet this requirement.",
            "sltDualSprinklerPackageWarning" : "The Dual Sprinkler option for Pivoting Lateral requires the lateral sprinklers to use a maximum of every other outlet. You must review your selections and make one or more of the following changes to continue: 1)Select a wider sprinkler spacing 2) Select a higher pressure regulator and end pressure if needed 3) Reduce the total system flow",
            "sltNelson" : "Nelson",
            "sltSenninger" : "Senninger",
            "sltBrandChange" : "Changing the sprinkler brand will require a sprinkler redesign.  Do you wish to proceed?",
            "sltCopyFrom" : "Copy from",
            "sltMustDesignSprinklers" : "You must design the sprinklers before you can leave this screen.  Do you wish to design?",
            "sltMustDesignPackage" : "You must design the sprinkler package(s) to continue.",
            "sltCPMechanicalPark" : "2 Position mechanical Park, Controlled at pivot center",
            "sltCPChemPump" : "Allows on/off interaction of Chem Pump with system",
            "sltCPChemPumpHookup" : "Allows easy hookup for Chem Pump",
            "sltCPGenerator" : "Allows on/off interaction of generator with system",
            "sltCPRemote" : "Allows MCP to be relocated up to 1/2 mile from pivot",
            "sltCPLowVoltage" : "Shuts system down by adjustable low voltage presets",
            "sltCPLowPressure" : "Shuts system down by low pressure presets",
            "sltCPLowPressureRestart" : "Shuts system down by low pressure presets and can restart when pressure rises to presets",
            "sltCPLowTemp" : "Shuts system down by adjustable low temperature presets",
            "sltCPLowTempRestart" : "Shuts system down by adjustable low temperature presets and can restart when temperature rises to presets",
            "sltCPPhone" : "Provides status updates, alarms and system control from phone",
            "sltCPRain" : "Shuts system down by adjustable rainfall presets",
            "sltCPWind" : "Shuts system down by adjustable wind speed presets",
            "sltRemotePanelVoltageZero" : "Remote Panel Voltage Loss cannot be 0. Please consult the Design Tools program.",
            "sltHydraulicPumpKit" : "480V Hydraulic Pump Kit",
            "sltStrainer" : "Strainer - Last Tower Top",
            "sltGPSRadio" : "Single System - Hardwired Base with Freewave Radio (Includes 2000' Data Cable)",
            "sltGPSNoBaseSharing" : "No Base - Sharing Existing Freewave Network",
            "sltGPSBaseConvert" : "Reinke Hardwired Base to Radio Base - Conversion Kit",
            "sltPivotAutoReverse" : "Pivot Auto Reverse",
            "sltGPSLineOfSight" : "GPS Base must be within 2 miles line of sight.  If not, please order a GPS Base.",
            "sltGalvCN" : "CN+ Galvanized",
            "sltLongSystemWireAlignmentWarning" : "Long System Wire Alignment is recommended!",
            "sltSenningerXiWobGray" : "Senninger Xi-Wob Gray",
            "sltSenningerXiWobBlack" : "Senninger Xi-Wob Black",
            "sltJan" : "Jan",
            "sltFeb" : "Feb",
            "sltMar" : "Mar",
            "sltApr" : "Apr",
            "sltMay" : "May",
            "sltJun" : "Jun",
            "sltJul" : "Jul",
            "sltAug" : "Aug",
            "sltSep" : "Sep",
            "sltOct" : "Oct",
            "sltNov" : "Nov",
            "sltDec" : "Dec",
            "sltRebateDesc" : "for {0}. Delivery",
            "sltPleaseCompleteRebate" : "Please complete the rebate information.",
            "sltAncillaryEquipment" : "Telemetry/Ancillary Equip.",
            "sltHeatExchanger" : "Engine Cooling Units",
            "sltTelemetryPrice" : "Telemetry Price",
            "sltClemonsFiltersPrice" : "Clemons Filters Price",
            "sltSprinklerLubePrice" : "Sprinkler Lube Price",
            "sltCheckValvesPrice" : "Check Valves Price",
            "sltRadioTelemetry" : "Radio Telemetry",
            "sltRC10" : "RC10",
            "sltCropX" : "CropX",
            "sltFieldLength" : "Field Length",
            "sltFlangedEndofSystem" : "Flanged End of System",
            "sltFlexEndofSystem" : "Flex End of System",
            "sltContactReinkeBeforeQuoting" : "Contact Reinke Mfg. for approval before quoting this system.",
            "sltDistanceFromGuidanceToEOSTooLong" : "The distance from the guidance tower to the end of the system exceeds the maximum recommendation of {0} feet.",
            "sltExceeds30KW" : "The total system kW exceeds 30 kW.",
            "sltExtraGPSDataCable" : "Extra GPS Data Cable (2000' Spool)",
            "sltControlled" : "Controlled",
            "sltWidth" : "Width",
            "sltDevices" : "Devices",
            "sltOrder": "Order",
            "sltQuote": "Quote",
            "sltSoldBy": "Sold by",
            "sltNetwork": "Network",
            "sltSite": "Site",
            "sltBaseLoc": "Base Loc",
            "sltPivotLoc": "Pivot Loc",
            "sltStartLoc": "Start Loc",
            "sltEndLoc": "End Loc",
            "sltRoeVersion": "Reinke Design Center Version",
            "sltScreenFilters" : "Screen Filters",
            "sltCheckValves" : "Backflow Prevention",
            "sltSprinklerLube" : "Premium Gear Lubricant",
            "sltGalv" : "Galv",
            "sltNumberOfZones" : "Number of zones",
            "sltBlackPlasticCannotBeUsed" : "Black Plastic U-Pipes cannot be used with Poly drops longer than 7' and Combo drops longer than 9'.",
            "sltClemons" : "Clemons Filters (Stainless Steel Screen 3/32\")",
            "sltAdapterReducer" : "Adapter 6-5/8\" to 8\"",
            "sltPercentOutOfRange" : "Percent must be between 1 and 100.",
            "sltStart" : "Start",
            "sltDiscountRange" : "Discount range must be between 45% and 51%.",
            "sltThirdNozzleGPM" : "Third Nozzle GPM:",
            "sltThirdNozzleGPMMustExist" : "Third Nozzle Flowrate cannot be 0.",
            "sltThirdGPMLimit" : "Third nozzle GPM must be less than  {0}.",
            "sltElevationMustBeBetween" : "Field Elevation must be 0 and 150 feet.",
            "sltAgTrac" : "AgTrac",
            "sltWarning" : "Warning",
            "sltStiffenerStabilizers" : "Stiffener - Stabilizers",
            "sltStiffenerTrussToPipe" : "Stiffener - Truss to Pipe",
            "sltHeavyDutyLegs" : "Heavy Duty Legs",
            "sltTowerCrossBrace" : "Tower Cross Brace",
            "sltTowerConnectingToSpan" : "Tower {0} connecting to Span {1}",
            "sltRev" : "Rev",
            "slt3WTwr" : "3WTwr",
            "sltStfStb" : "StfStb",
            "sltStfTP" : "StfTP",
            "sltHdLg" : "HdLg",
            "sltTwrBr" : "TwrBr",
            "sltSpnStb" : "SpnStb",
            "sltTrRd" : "TrRd¾",
            "sltHydraulicCylinderKit" : "Hydraulic Cylinder Kit for Axles",
            "sltAssemblyInfo" : "Assembly Information",
            "sltNumberOfControlledZones" : "Number of controlled zones",
            "sltFlangedSideTitleWarning" : "Spans - Flanged Parent - Must be longer than Flex Side",
            "sltFlexSideTitleWarning" : "Spans - Flex Extension - Must NOT exceed the length of Flanged Parent Side",
            "sltUMCCoverKit" : "UMC Cover Kit",
            "sltReinkeSign" : "Reinke Sign/Leg Mount Option",
            "sltSenningerXiWobWhite" : "Senninger Xi-Wob White",
            "sltSenningerXcelWobbler" : "Senninger Xcel Wobbler",
            "sltE2045" : "E2045",
            "sltTheTotal4inch1300feet" : "The total length of E2045 spans cannot exceed 1300'.",
            "sltE2045CannotBeReversed" : "An E2045 span cannot be used on or before the rigid span.",
            "sltTotalLenth1300E2045" : "The total system length cannot exceed 1300' when using the E2045 pivot center.",
            "sltDevicesControlled" : "Devices controlled",
            "sltVRIZones" : "VRI Zones",
            "sltValveBox" : "Valve Box",
            "sltVRIValveBoxes" : "VRI Valve Boxes",
            "sltMailingAddress" : "Mailing Address (if different):",
            "sltEMail1" : "Email:",
            "sltMailingAddress1" : "Mailing:",
            "sltManagerName1" : "Manager:",
            "sltCustomer" : "Customer",
            "sltA100" : "A-100",
            "sltCF200" : "CF200",
            "sltTubingRequired" : "Tubing Required",
            "sltTowerLocation" : "Tower Location",
            "sltReplaces" : "This system replaces",
            "sltLastZone" : "Last Zone",
            "sltFirstZone" : "First Zone",
            "sltLastDevice" : "Last Device",
            "sltFirstDevice" : "First Device",
            "sltZoneRelayAddress" : "Zone Relay Address",
            "sltMaxDiameter" : "Max. Diameter",
            "sltUsingSACAirCompt" : "Using SAC Air Compressor on Tower",
            "sltLocatedAt" : "located at",
            "sltOnFlexTower" : "On Flex Tower",
            "sltOnPowerTower" : "On Power Tower",
            "sltOnFlangedTower" : "On Flanged Tower",
            "sltQuarterInchTubingRequired" : "1/4\" Tubing required",
            "sltThreeEightsTubingRequired" : "3/8\" Tubing required",
            "sltStartingLocation" : "Starting Location",
            "sltEndingLocation" : "Ending Location",
            "sltGPSRadioNZ" : "Single System - Hardwired Base less Radio (Includes 2000' Data Cable)",
            "sltGPSNoBaseSharingNZ" : "No Base less Radios - Sharing Existing Network",
            "sltReinkeValve" : "2\" Reinke",
            "sltPercentOfArc" : "percent of arc",
            "sltPercentOfFieldLength" : "percent of field length",
            "sltKomet" : "Komet",
            "sltKometTwisterBlack" : "KP-Twister - Black",
            "sltKometTwisterBlue" : "KP-Twister - Blue",
            "sltKometTwisterYellow" : "KP-Twister - Yellow",
            "sltKometTwisterWhite" : "KP-Twister - Up Top - White",
            "sltKometSpray" : "Komet Spray",
            "sltKometSprayPartCircle" : "Komet Spray Partial Circle",
            "sltKometTwisterPartCircle" : "Komet Twister Partial Circle",
            "sltSACPath0Dollars" : "Reinke ($0)",
            "sltSACPath250Dollars" : "Reinke ($250)",
            "sltVRIInformation" : "VRI Information",
            "sltCopyWhichItem" :"Copy which item?",
            "sltCopyItem"  :"Copy item:",
            "sltHowManyItems"  :"How many items?",
            "sltNumberOfItems"  :"Number of items:",
            "sltInsertAfterWhichItem"  :"Insert after which item?",
            "sltInsertAfterItem"  :"Insert after item:",
            "sltDeleteWhichItem"  :"Delete which item?",
            "sltDeleteItem"  :"Delete item:",
            "sltEditWhichItem"  :"Edit which item?",
            "sltEditItem"  :"Edit item:",
            "lsltThisOrderIsSubjectTo" : "Esta Orden está sujeta a la disponibilidad del Distribuidor de obtener el mensionado equipo proveniente del fabricante, y el Distribuidor no tendrá responsabilidad alguna si la entrega del equipo es retrasada debido a problemas laborales, falta de equipo de transporte, retrasos diversos en el transporte, muerte, guerra, conmoción civil, ordenes guvernamentales o cualquier otra circunstancia fuera del control del Distribuidor. En el evento de que el costo al Distribuidor por el mencionado equipo al momento de embarque sea mayor al costo mostrado a la fecha de este contrato, el precio será ajustado al precio en afecto al momento del embarque. En este caso el comprador tendrá el privilegio de aceptar el envio al precio incrementado o cancelar este contrato dando notificación escrita de la mencionada cancelación al Distribuidor dentro de 5 días después que se fue notoficada dicho incremento en el precio. Al momento de la mencionada cancelación y reembolso del distribuidor por cualquier pago recibido del Comprador al Distribuidor, desde ese momento y a continuación se cancelará la venta. Si el Comprador no cancela este contrato dentro del periodo de los 5 días mencionados, una nueva orden deberá de ser emitida que reflejará el nuevo precio y esta orden remplazará a este instrumento. Después de la entrega del equipo al comprador, todo equipo y trabajo en el proceso será riesgo del comprador. La exactitud del \"Paquete de Aspersores de Sistemas de Riego Reinke\", si es así comprado, dependerá en que tan completa y precisa sea la información proveida.",
            "lsltItIsUnderstoodBy" : "Es entendido por el distribuidor, un contratista independiente, que esta orden de compra está sujeta a revisión de Reinke Manufacturing Co.,Inc. y sus oficinas Internacionales en Deshler Nebraska antes de que se convierta un contrato de venta.",
            "lsltConfidential" : "La compra del sistema arriba descrito obedece a los Términos y Condiciones del Acuerdo de Compra del Sistema de Riego entre el Cliente y el Distribuidor incluyendo pero no se limita a la Garantía de los Sistemas de Riego Reinke. Este documento es considerado CONFIDENCIAL y no podrá ser reproducido en parte o en su totalidad sin un permiso por escrito por parte de REINKE MANUFACTURING COMPANY INC. o sus asociados.",
            "lsltDealerLanguage" : "El distribuidor está de acuerdo que esta orden obedece los Términos de Venta (Sección IX), Política de Crédito (Sección X), Orden de Sistema (Sección XVI) y cualquier otro término, condición, práctica y procedimiento aplicable a la compra del sistema de riego y/o partes queda dentro del Manual de Políticas y Procedimientos de Distribuidor Reinke.",
            "lsltSACAcresTerms" : "Los Acres cubiertos son calculados en base a un limite 5ft mas allá del sistema madre.Esto se recomienda para que la torre S no se encuentre con la huella de la torre H en suelos que desarrollen este problema. Los acres totales cubiertos variaran según el patrón que siga el brazo esquinero.",
            "lsltPaymentShallBe" : "Pago será como se indica arriba. Precios son sujetos de cambio sin advertencia. Si el cliente sugiere cambios en el diseño del sistema o retraza el proceso de manufactura o embarque del sistema, el precio del sistema será ajustado para reflejar el incremento consecuente. Además, el precio del sistema está sujeto a revisión por incrementos en los materiales y mano de obra durante el periodo en que se mete esta orden de compra y la terminación de la manufactura del sistema.",
            "lsltIfTheDealer" : "Si el cliente o su distribuidor sugiere cambios en el diseño del sistema o retraza el proceso de manufactura o embarque del sistema, el precio del sistema será ajustado para reflejar el incremento consecuente. Además, el precio del sistema está sujeto a revisión.",
            "lsltEndGunPressTooLow" : "La presión del cañón final es inferior a lo sugerido como rango de operación. Es recomendado incrementar la presión final y/o agregar una bomba buster de 2hp o 5hp para minimizar tamaño de gots, compactacion del suelo, erosion, daño al cultivo y/o uso inapropiado del cañón.",
            "lsltEndGunPressWayTooLow" : "La presión del cañón final Peligrosa. Es recomendado incrementar la presión final y/o agregar una bomba buster de 2hp o 5hp para minimizar tamaño de gots, compactacion del suelo, erosion, daño al cultivo y/o uso inapropiado del cañón.",
            "lsltHighAmpsOnCable" : "La Demanda del Sistema excede los 30amps. Este amperaje no es permitido debido al numero de conductores en uno o mas cables de tramo. El número de motores de Alta velocidad deberá ser reducido y/o eliminar la bomba booster. La propuesta no puede ser terminada hasta que se resuelva esto.",
            "lsltHighAmpsOnPanel" : "La Demanda del Sistema excede los {0} amps. Este amperaje no es permitido debido a una limitación del Panel de Control. El número de motores de Alta velocidad deberá ser reducido y/o eliminar la bomba booster. La propuesta no puede ser terminada hasta que se resuelva esto.",
            "lsltDropGroundClearanceWarning" : "El claro de la bajante queda por debajo de la recomendación para el máximo espaciamiento entre aspersores usados en este sistema. Incremente la distancia del suelo y/o reduzca el espaciamiento para mejorar el desempeño y la uniformidad que este sistema de riego puede proveer.",
            "lsltBarricadesMissingWarning1" : "Part circle and lateral systems require a minimum of two barricades per Tower Auto Stop/Reverse. Wrap and Drop spans will require a barricade for each location intended for their use. Barricades are no longer ordered in pairs. Please confirm the correct quantity is ordered.",
            "lsltBlackPlasticUPipeWarning" : "Cuellos de Ganso de 180grados de plástico negro se pueden usar hasta 25ft de cada lado de la torre, el punto pivote y en todo el voladizo en vez de los cuellos sencillos y dobles de 125grados que no serán efectivos en estas locaciones.",
            "lsltHighAmpsOnSide" : "La demanda de corriente de al menos uno de los lados excede los 20 amps. Este amperaje no es permitido por una limitación en el panel de control. Los motores de alta velocidad deberán ser reducidos y/o la bomba booster eliminada. El diseño no puede ser completado hasta que esto sea resuelto.",
            "lsltDesignDisclaimer" : "El diseño completo del sistema puede depender de factores no considerados en este programa. Reinke Manufacturing Co., Inc. No se hace responsable por el uso de medidas, incluyendo pero no limitando, cable o tamaño de tubería, al usar este programa.",
            "lsltSwingArmTooLong" : "Si el campo es cuadrado y el brazo esquinero solo se extiende en las esquinas, entonces el brazo esquinero mas el cañón quizá es demasiado largo de lo necesario. Seleccionar a un brazo esquinero menos largo puede cubrir lo mismo que el escenario anterior.",
            "lsltSwingArmHingeTowerOnlySpanish" : "Este pedido no incluye el tramo de esquina/pluma del brazo oscilante ni ningún componente final del sistema que normalmente se incluiría en el brazo oscilante. Incluye los dispositivos de rociadores, los kits de bajadas y abrazaderas cuando sea necesario, menos las válvulas que ya puedan estar presentes en el brazo oscilante existente en el campo.",
            "sltHigh" : "High",
            "sltLow" : "Low",
            "sltSettingsNoLongerSupportedTitle"  :"Settings No Longer Supported",
            "sltRemoveItemsThatAreInvalid"  :"Reset/remove the items.",
            "sltSettingsNoLongerSupported"  :"This proposal file contains one or more settings that are no longer supported.  You may either view or edit the proposal.  In order to edit this proposal, the following items must be reset or removed:",
            "sltDiffuserApplied"  :"The P85 Diffuser has been added to the end gun due to low operating pressure.",
            "sltDualP85sApplied"  :"Two P85 End Guns are required and used for this system due to high end gun flow.",
            "sltEndGunOverwater"  :"Due to a nozzle size limitation, the end gun will overwater by {0}%.",
            "sltPrimaryEndGun"  :"Primary End Gun",
            "sltSecondaryEndGun"  :"Secondary End Gun",
            "sltDueToANozzleSizeUnderwater" : "Due to a nozzle size limitation, the end gun will underwater by {0}%. Decreasing the Total System GPM or selecting a different end gun may alleviate this situation.",
            "sltDueToANozzleSizeOverwater" : "Due to a nozzle size limitation, the end gun will overwater by {0}%. Increasing the Total System GPM or selecting a different end gun may alleviate this situation.",
            "sltEndGunRadius" : "End Gun Radius",
            "sltEndGunPressure" : "End Gun Pressure",
            "sltRequiredEndGunGPM" : "End Gun GPM Required",
            "sltDeliveredEndGunGPM" : "End Gun GPM Delivered",
            "sltSideGPM" : "Side GPM",
            "sltSprinklersNotCharted"  :"Sprinklers have not been charted.",
            "sltEndGunNozzle" : "End Gun Nozzle",
            "sltBoostedPressure" : "Boosted Pressure",
            "sltCoverageWithEG" : "Coverage with End Gun",
            "sltCoverageWithoutEG" : "Coverage without End Gun",
            "sltPrimary" : "Primary",
            "sltSecondary" : "Secondary",
            "sltEndGunPerformance" : "End Gun Performance",
            "sltBaseOfInletPressure" : "Base of Inlet Pressure",
            "sltWithESP" : "with ESP",
            "sltElevationPressureLoss" : "Elevation Pressure Loss",
            "sltRadius" : "Radius",
            "sltSystemLoss" : "System Loss",
            "sltControlPanelFeatures" : "Control Panel Features",
            "sltTwinUltra" : "Twin 101 Ultra",
            "sltRPMBasic" : "RPM Basic Panel",
            "sltRPMStandard" : "RPM Standard Panel",
            "sltRPMAdvanced" : "RPM Advanced Panel",
            "sltRPMPreferred" : "RPM Preferred Touch Screen Panel",
            "sltNoEndTowerPositioning" : "No end tower positioning",
            "sltResolverOrNavigator" : "Resolver/Navigator GPS",
            "sltCPMechanicalReverse" : "2 Position mechanical Reverse, Controlled at pivot center",
            "sltFlowtubeIncluded" : "14\" Flow Tube included.",
            "sltFlowtubeNotIncluded" : "Flow Tube not available.",
            "sltNelsonR3000a" : "R3000 Rotator",
            "sltNelsonR3000aRotatorWhite" : "R3000 Rotator White",
            "sltNelsonR3000aRotatorOrange" : "R3000 Rotator Orange",
            "sltNelsonR3000aRotatorBrown" : "R3000 Rotator Brown",
            "sltNelsonR3000aRotatorOlive" : "R3000 Rotator Olive",
            "sltNelsonR3000aRotatorBlue" : "R3000 Rotator Blue",
            "sltNelsonR3000aRotatorGreen" : "R3000 Rotator Green",
            "sltNelsonR3000aRotatorRed" : "R3000 Rotator Red",
            "sltNelsonR3000aFCNRotatorBlue" : "R3000 FCN Rotator Blue",
            "sltNelsonR3000aFCNRotatorGreen" : "R3000 FCN Rotator Green",
            "sltNelsonS3000a" : "S3000 Spinner",
            "sltNelsonS3000aSpinnerRed" : "S3000 Spinner Red",
            "sltNelsonS3000aSpinnerPurple" : "S3000 Spinner Purple",
            "sltNelsonS3000aSpinnerYellow" : "S3000 Spinner Yellow",
            "sltNelsonS3000aSpinnerPurpleLowPressure" : "S3000 Spinner Purple Low Pressure",
            "sltNelsonS3000aSpinnerYellowLowPressure" : "S3000 Spinner Yellow Low Pressure",
            "sltNelsonA3000a" : "A3000 Accelerator",
            "sltNelsonA3000aAcceleratorMaroon" : "A3000 Accelerator Maroon",
            "sltNelsonA3000aAcceleratorGold" : "A3000 Accelerator Gold",
            "sltNelsonA3000aAcceleratorNavy" : "A3000 Accelerator Navy",
            "sltNelsonA3000aAcceleratorNavyLowPressure" : "A3000 Accelerator Navy Low Pressure",
            "sltNelsonO3000a" : "O3000 Orbitor",
            "sltNelsonO3000aOrbitorPurple" : "O3000 Orbitor Purple",
            "sltNelsonO3000aOrbitorBlack" : "O3000 Orbitor Black",
            "sltNelsonO3000aOrbitorBlue" : "O3000 Orbitor Blue",
            "sltNelsonO3000aOrbitorWhite" : "O3000 Orbitor White",
            "sltNelsonD3000a" : "D3000 Spray",
            "sltNelsonD3000aFCNSpray" : "D3000 FCN Spray",
            "sltWrongNumberOfD3000" : "Wrong number of D3000 Plates chosen.",
            "sltNelsonR3030" : "R3030 Rotator",
            "sltNelsonR3030RotatorWhite" : "R3030 Rotator White",
            "sltNelsonR3030RotatorOrange" : "R3030 Rotator Orange",
            "sltNelsonR3030RotatorBrown" : "R3030 Rotator Brown",
            "sltNelsonR3030RotatorOlive" : "R3030 Rotator Olive",
            "sltNelsonR3030RotatorBlue" : "R3030 Rotator Blue",
            "sltNelsonR3030RotatorGreen" : "R3030 Rotator Green",
            "sltNelsonR3030RotatorRed" : "R3030 Rotator Red",
            "sltNelsonS3030" : "S3030 Spinner",
            "sltNelsonS3030SpinnerRed" : "S3030 Spinner Red",
            "sltNelsonS3030SpinnerPurple" : "S3030 Spinner Purple",
            "sltNelsonS3030SpinnerYellow" : "S3030 Spinner Yellow",
            "sltNelsonS3030SpinnerPurpleLowPressure" : "S3030 Spinner Purple Low Pressure",
            "sltNelsonS3030SpinnerYellowLowPressure" : "S3030 Spinner Yellow Low Pressure",
            "sltNelsonA3030" : "A3030 Accelerator",
            "sltNelsonA3030MTAcceleratorMaroon" : "A3030MT Accelerator Maroon",
            "sltNelsonA3030MTAcceleratorGold" : "A3030MT Accelerator Gold",
            "sltNelsonA3030AcceleratorMaroon" : "A3030 Accelerator Maroon",
            "sltNelsonA3030AcceleratorGold" : "A3030 Accelerator Gold",
            "sltNelsonA3030AcceleratorNavy" : "A3030 Accelerator Navy",
            "sltNelsonA3030AcceleratorNavyLowPressure" : "A3030 Accelerator Navy Low Pressure",
            "sltNelsonO3030" : "O3030 Orbitor",
            "sltNelsonO3030OrbitorPurple" : "O3030 Orbitor Purple",
            "sltNelsonO3030OrbitorBlack" : "O3030 Orbitor Black",
            "sltNelsonO3030OrbitorBlue" : "O3030 Orbitor Blue",
            "sltNelsonO3030OrbitorWhite" : "O3030 Orbitor White",
            "sltNelsonO3030OrbitorFXBlack" : "O3030 OrbitorFX Black",
            "sltNelsonO3030OrbitorFXWhite" : "O3030 OrbitorFX White",
            "sltNelsonD3030" : "D3030 Spray",
            "sltNelsonD3030MT" : "D3030MT Spray",
            "sltPartialCircleD3000" : "Partial Circle D3000 Spray",
            "sltPartialCircleS3000" : "Partial Circle S3000 Spinner",
            "sltPartialCircleR3000" : "Partial Circle R3000 Rotator",
            "sltRecommendUltraLowPressureSwitch" : "Pivot Pressures below 20 psi may benefit from the use of the 0-15 PSI low pressure switch.",
            "sltR55i" : "R55i",
            "sltR55" : "R55",
            "sltR75" : "R75",
            "sltR75LP" : "R75LP",
            "sltFlowmeterComponentsPrice" : "Flowmeter Components Price",
            "sltDealerLogo" : "Set Dealer Logo...",
            "sltDealerLogoSelectImageButton" : "Select Image",
            "sltDealerLogoSetButton" : "Set as Logo",
            "sltDealerLogoSetConfirm" : "Logo has been set as the report header image.",
            "sltDealerLogoTooSmall" : "Image must be at least 400x200 pixels!",
            "sltDealerLogoRemove" : "Remove Dealer Logo...",
            "sltDealerLogoRemoveSuccess" : "Dealer Logo Removed!",
            "sltImageNotValid" : "Image Not Valid!",
            "sltImageNullOrEmpty" : "Image file cannot be null or empty!",
            "sltImageFiles" : "Image Files",
            "sltDiaphragm" : "Diaphragm",
            "slt32085R38" : "320/85R38",
            "sltNonDirectional" : "Non-Directional",
            "sltRadial" : "Radial",
            "sltShark" : "Shark",
            "sltMCPMinMax1Ph60HzVoltage" : "The 1 Phase voltage at the Main Control Panel / End Gun should be Min:208V, Max:250V and Default:230V.",
            "sltMCPMinMax3Ph60HzVoltage" : "The 3 Phase voltage at the Main Control Panel / End Gun should be Min:440V, Max:505V and Default:480V.",
            "sltMCPMinMax3Ph50HzVoltage" : "The 3 Phase voltage at the Main Control Panel / End Gun should be Min:360V, Max:435V and Default:380V.",
            "sltMCPVoltageTooLow" : "The voltage at the Main Control Panel cannot be less than the voltage at the End Gun.",
            "slt4WheelDrive" : "4 Wheel Drive",
            "sltPivotingLateral" : "Pivoting Lateral",
            "sltPivotingLateralPivotCenterType" : "Pivoting Lateral requires selection of the Pivot Center Type",
            "sltBallastKit" : "Ballast Kit",
            "sltRotateCart" : "Rotate Cart",
            "sltManualOnlyEnglish" : "The manual is only available in English for this system configuration.",
            "sltPivotingLateral1700Feet" : "The total length of a pivoting lateral with outer tower guidance cannot exceed 1700'.",
            "sltPivotingLateral1400Feet" : "The total length of a pivoting lateral with power tower guidance cannot exceed 1400'.",
            "sltIrrigateWhilePivoting" : "Irrigate while pivoting",
            "sltChemTankMountKitWithStep" : "Chem Tank Mounting Kit with Step",
            "sltChemAuxControlOptionMissing" : "A simultaneous interlocking device is required when injection pump units are used. The irrigation pumping plant and the chemical injection pump shall be interlocked so that if the pumping plant stops, the injection pump will also stop. Its purpose is to prevent pumping chemicals into the irrigation pipeline after the irrigation pump stops. A pressure switch or transducer might serve in this role but has not been ordered.",
            "sltSystemModel" : "System Model",
            "sltSystemArea" : "System Area",
            "sltTotalArea" : "Total Area",
            "sltSacandEndGunArea" : "End Gun + SAC Area",
            "sltSacArea": "SAC Area",
            "sltEndgunArea": "End Gun Area",
            "sltBlankLine" : "Blank Line",
            "sltDesignSystemFlow" : "Design System Flow",
            "sltDesignedFlowByArea" : "Design Flow by Acre (Hectare)",
            "sltEndPressure1" : "End Pressure",
            "sltPrimaryEndGunFlowRate" : "Primary End Gun Flow",
            "sltPrimaryEndGunModel" : "Primary End Gun Model",
            "sltSecondaryEndGunModel" : "Secondary Gun Model",
            "sltEffectiveGunThrow" : "Effective Gun Throw",
            "sltMaxGunThrow" : "Maximum Gun Throw",
            "sltSprinklerGroundClearance" : "Sprinkler Ground Clearance",
            "sltSprinklerDiameter" : "Sprinkler Diameter",
            "sltIntensityApplicationRate" : "Intensity/Application Rate Per Hour",
            "sltIrrigationRatePerDay" : "Irrigation Rate Per Day",
            "sltIrrigationRatePerMonth" : "Irrigation Rate Per Month",
            "sltLastTowerDistance" : "Last Tower Distance",
            "sltTowerSpeed" : "Tower Speed",
            "sltPercentTimerSetting" : "Percent Timer Setting",
            "sltDepthApplied" : "Depth Applied",
            "sltHoursApplied" : "Hours Applied",
            "sltInches" : "Inches",
            "sltMillimeter" : "MM",
            "sltFullCircle" : "Full Circle",
            "sltPercentSign" : "%",
            "sltReinkePerformanceSummary" : "Irrigation System Performance Summary",
            "sltDeliveryDiscountPercent" : "Delivery Discount (%)",
            "sltDeliveryDiscount" : "Delivery Discount",
            "sltValueCantBeZero" : "Value can't be zero!",
            "sltValueMustBeBetween1And100"  :"Value must be between 1 and 100!",
            "sltHardHoseAdapterNotSoldInPairs"  :"Hard Hose Adapters not sold in pairs!",
            "sltMaxFlow" : "Max System Flow",
            "sltMinFlow" : "Min System Flow",
            "sltLongPLNoFurrowWheel" : "The Furrow Wheel option may provide better performance when the system length is longer than 600 feet.  Return to Lateral Move Guidance if you choose to make this change.",
            "sltShortPLFurrowWheel" : "The Furrow Wheel option is not economical when the system length is shorter than 600 feet.  Return to Lateral Move Guidance if you choose to make this change.",
            "sltDontTowDownhill" : "Do not Tow Pivots Downhill!",
            "sltDisclaimer" : "Disclaimer",
            "sltLastRecord"  :"Last Record",
            "sltDesignedFlowByAreaMetric" : "Design Flow by Hectare ( Acre )",
            "sltInformationNotAvailable" : "Information Not Available",
            "lsltPerformanceSummaryDisclaimer"  :"La aplicación pluviométrica que entrega este Sistema puede ser afectada de manera importante por varias variables, incluyendo pero no limitadas, el incorrecto diseño de boquillas o su mala instalación, boquillas obstruidas, suelos compactados o con pendiente, configuración incorrecta del cañón final, falta de presión en la entrada del sistema, condiciones de clima desfavorables, operación errática o impropia de la velocidad del sistema.",
            "sltDeleteRC10"  :"This will delete your RC10 configuration.",
            "sltRC10PositionWarningSystem"  :"All position related functions of the RC10 will be disabled due to no positioning device. Return to the Main Control Panel section of your ROE order and select Resolver or Navigator Gps in the End Tower Positioning section to add a positioning device to your system. If you want to utilize the position dependant options as needed.",
            "sltRC10LateralPlusMonitorOptionWarning"  :"Monitor Options will only work properly if the Lateral Move System has a constant power source such as a drag cord.",
            "sltRC10PositionWarningRetro"  :"All position related functions of the RC10 will be disabled due to no positioning device, select End of System GPS in System Retro Kits section to add a positioning device to your order to utilize the position dependant options",
            "sltSACPowerAutoRestartOnRetro"  :"SAC Power Auto Restart must be installed on this system for Start function to work correctly, select SAC Power Auto Restart in Panel Retro Kits section if kit is needed.",
            "sltPowerAutoRestartOnExisting"  :"Power Auto Restart must be installed on this system for Start function to work correctly, select Power Auto Restart in Main Control Section of system order to add.",
            "sltPowerAutoRestartOnRetro"  :"Power Auto Restart must be installed on this system for Start function to work correctly, select Power Auto Restart in the 'Retro Kit Options' section.",
            "sltNoRemoteDirectionChange"  :"Using this Panel will not allow you to change directions remotely.",
            "sltPowerRelayOnRC10iWarning"  :"The Power Relay Kit has been selected; this option pulls power from the direction wires to supply power to the RC10i unit. When this option is selected Direction Change and Stop functions will not be available for panels with Auto-Reverse.",
            "sltPowerRelayStartRelayError"  :"'Power Relay' and 'Start Relay' can not both be Checked!",
            "sltRC10MCPAutoReverseChanged"  :"MCP Panel Model and Pivot Auto Reverse options have changed since RC10 was configured.",
            "sltRC10MCPPanelModelChanged"  :"MCP Panel Model has changed since RC10 was configured.",
            "sltModemModelIncompatible"  :"Modem Model selected is incompatible with System.",
            "sltRC10MCPEndTowerPositioningChanged"  :"MCP End Tower Positioning has changed since RC10 was configured.",
            "sltRC10MCPLowPressureChanged"  :"MCP Low Pressure options have changed since RC10 was configured.",
            "sltRC10MCPPowerAutoRestartChanged"  :"MCP Power Auto Restart option has changed since RC10 was configured.",
            "sltRC10SACChanged"  :"SAC option has changed since RC10 was configured.",
            "sltRC10SectionMustReset"  :"Must reset RC10 Section as dependencies have changed!",
            "sltNotRunningInROEDir"  :"EXE Not running in 'Reinke Systems' Directory, Quitting!",
            "sltRC10SystemCategoryInvalid": "RC10 System Category option invalid",
            "sltRC10ControlPanelInvalid": "RC10 Control Panel option invalid",
            "sltRC10AutoReverseInvalid": "RC10 Control Panel / Auto Reverse option invalid",
            "sltRC10FacePlateUpgradeInvalid": "RC10 Face Plate Upgrade option invalid",
            "sltRC10MountingLocationInvalid": "RC10 Mounting Location option invalid",
            "sltRC10LowPressureShutdownSwitchInvalid": "RC10 Low Pressure Shutdown Switch option invalid",
            "sltRC10SwingArmCornerInvalid": "RC10 Swing Arm Corner option invalid",
            "sltRC10PowerAutoRestartInvalid": "RC10 Power Auto Restart option invalid",
            "sltRC10ControllerInvalid": "RC10 Controller option invalid",
            "sltRC10EOSGPSKitInvalid": "RC10 End of System GPS Kit option invalid",
            "sltAddressLine"  :"Address",
            "sltDealerName"  :"Name",
            "sltCityState"  :"City/State",
            "sltRC10Price" : "RC10 Price",
            "sltCropXPrice" : "CropX Price",
            "sltNoInternet"  :"Internet not available!",
            "sltPleaseCompleteSalesPerson"  :"Please fill in the Sales Person",
            "sltInvalidZipCode" : "The Zip Code you have entered is invalid. Zip Code is a required field for the United States.",
            "sltEndOfSystemGPSNeeded"  :"End of System GPS must be selected in the 'Retro Kit Options.'",
            "sltSalesPerson"  :"Salesperson",
            "sltPleaseSave"  :"Please save the quote.",
            "sltFixProposalInformation"  :"Please revisit the Proposal Information Screen. Required information such as Customer Name, Address, City, State, Zip or Salesperson is missing and must be entered.",
            "sltFixCustomerPaymentInfo"  :"Please revisit the Proposal Information and edit the Payment Information Screen. Terms is Required information and must be selected.",
            "sltFixProposalInformationShipDate"  :"Please revisit the Proposal Information Screen. Requested Ship Date is required.",
            "sltFixProposalInformationShipDatePast" : "Please revisit the Proposal Information Screen. Requested Ship Date cannot be today or in the past.",
            "sltFixProposalInformationShipDateValidationNeeded" : "Requested Ship Date could not be confirmed. The ship date will default to {0}. Please confirm a scheduled Ship Date with Dealer Support or click 'Cancel' and try to resubmit this order.",
            "sltFixProposalInformationShipDateUnavailable" : "The requested Ship Date is unavailable for scheduling. Please revisit the Proposal Information Screen to select an available date in BOLD or call Dealer Support to adjust scheduling.",
            "sltPleaseCompleteQuote"  :"Quote is not complete.",
            "sltConfirmSubmission"  :"You are about to submit {0} to Reinke as an order.\nIf you are sure the order is accurate and complete select OK.",
            "sltMustBeSaved"  :"Before {0} can be submitted it must first be saved.",
            "sltSubmissionFailed"  :"Order submission to Reinke cannot be completed due to the following: {0}",
            "sltErrorColon"  :"Error:",
            "sltWarningColon"  :"Warning:",
            "sltSubmissionComplete"  :"Submission Complete!",
            "sltSomethingWentWrong"  :"Something went wrong!",
            "sltInvalidResponseFromServer"  :"Invalid response from server.",
            "sltQuoteIsInvalid"  :"Quote is Invalid",
            "sltSaveWasCancelled"  :"Save was cancelled",
            "sltUserCancelledSubmission"  :"User Cancelled Submission",
            "sltLoginSessionRequired"  :"Reinke Systems requires a user session to support some functionality. Please login to continue.",
            "sltInvalidQuote"  :"Invalid or Corrupted Quote File!",
            "sltQuoteAlreadyLoaded"  :"Quote by name {0} is already loaded.",
            "sltInvalidSystemGPM"  :"Invalid System GPM!",
            "sltInvalidEndPressure"  :"Invalid End Pressure!",
            "sltHardHoseQtyCantBeZero"  :"Hard Hose Qty can not be zero!",
            "sltJunctionBoxWithoutPlugCantBeZero"  :"The number of junction boxes with out plugs cannot be zero!",
            "sltDragCordOptionMustBe9999OrBelow"  :"Drag cord value must be 9999 or below!",
            "sltHoseQtyOptionMustBe999OrBelow"  :"Hose Quantity Option must be 9999 or below!",
            "sltS80G" : "S80G",
            "sltWheelGear740AD" : "740-AD Series Wheel Gear",
            "sltPaintedCNPipe" : "Please be aware that the paint may peel and potentially plug nozzles and/or valves during the life of the system.",
            "sltGalvanizedCNPipe" : "Please be aware that, because of the high silicone content the galvanizing application is difficult to control and can leave the pipe with an uneven coloring and dull finish.",
            "slt1000P" : "1000P",
            "sltPercentOfCircle" : "Percent of Circle",
            "sltRPMConnectSelected" : "You have chosen RPMConnect as your panel type. You MUST go into the \"Telemetry / Ancillary\" section to complete your choices.",
            "sltCF200PumpAdvisory" : "Please consult with and verify your motor and pump selection with the Reinke Engineering Department.",
            "sltHingeTowerOnlyWarning" : "Select all \"End of System\" and \"Swing Arm Corner\" options to match the components on the existing swing arm that this system will be placed on. The Swing Arm and End of System options will not be included with this order as they are already in the field, but are required to properly compute the Sprinkler Package.",
            "sltAnUpdateIsRequired" : "An update is required. Do you wish to update?",
            "slt32085R24" : "320/85R24",
            "slt38080R38" : "380/80R38",
            "sltSACPathNeeded"  :"Must provide the SAC Path with the Purchase Order to complete the Sprinkler Package!",
            "sltDistributionAreaFactorsNeeded"  :"Custom values for Distribution Area Factors are required to complete the sprinkler package, or change Zone Control Type to 12.0 or 6.0 to complete this purchase order!",
            "sltPdfSubmissionFailure"  :"Couldn't send the finished PDF file to Reinke.",
            "sltPdfSent"  :"PDF Sent successfully!",
            "sltOnlineOrderingEnabled"  :"Online Ordering Enabled",
            "sltOnlineOrderingDisabled"  :"Online Ordering Disabled",
            "sltOrderAlreadySubmitted"  :"Order Already Submitted!",
            "sltOrderSubmitRequiresOriginalDealer"  :"Order Submit Requires Original Dealer!",
            "sltOrderSubmitRequiresTradeDiscountUpdate"  :"Order Submit Requires Trade Discount Update",
            "sltOrderProcessing"  :"Order Processing"
        }
    }
}