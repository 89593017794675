import { Feature, LineString, Point, Polygon, Properties } from "@turf/turf";
import { ISpanVertex } from "rdptypes/geometry/systems/centerPivot/systemCoordinates";

export interface IProperties {
    isCenterPivot: true;
    systemId: string;
    layoutId: string;
    activeSystem: boolean;
    isEditable: boolean;
}

export type IGeoJSONFeatureResult = {
    verticies: ISpanVertex[];
    feature: Feature<Point, Properties>;
    wheelTracks?: undefined;
    endBoomTrack?: Feature<LineString, { type: 'anticlockwiseWrap' | 'center' | 'clockwiseWrap', wrapIndex?: number }>[];
} | {
    feature: Feature<Polygon, Properties>;
    verticies: ISpanVertex[];
    wheelTracks: Feature<LineString, { spanIndex: number, type: 'anticlockwiseWrap' | 'center' | 'clockwiseWrap', wrapIndex?: number }>[];
    endBoomTrack: Feature<LineString, { type: 'anticlockwiseWrap' | 'center' | 'clockwiseWrap', wrapIndex?: number }>[];
};