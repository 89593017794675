import { Box, Button, Divider, Typography } from "@mui/material";
import { t } from "i18next";
import IGrowerBase from "rdptypes/api/IGrowerBase";
import ISystem from "rdptypes/project/ISystem";
import { IFEXRate } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { useContext } from "react";
import { partsPackages } from "roedata/data";
import { formatUSDCentsByRate } from "../../../../../docGeneration/DocumentGenerationHelpers";
import { FEXContext } from "../../../../../foreignExchange";
import { pricingHelper } from "../../../../../helpers/pricingHelper";
import DealerDataCtx from "../../../../../userData/DealerDataCtx";
import { getGroupedPartsForSystem } from "../ProposalHelpers";
import ProposalWarnings from "../ProposalWarnings";
import CurrencyHeader from "./CurrencyHeader";

interface Props {
    system: ISystem;
    grower: IGrowerBase;
}

export default function LiveProposal(props: Props) {
    const user = useContext(DealerDataCtx);
    const fex = useContext(FEXContext);
    
    const [ currency, setCurrency ] = React.useState("USD");

    const ph = pricingHelper({ system: props.system, fex, user });
    let currencyAndRate: {
        symbol: string, rate?: IFEXRate
    };
    if (currency === "USD") {
        currencyAndRate = {
            symbol: "USD", rate: undefined
        }
    }
    else {
        if (!ph.localCurrency || ph.localCurrency.fexAvailable === false || ph.localCurrency.symbol !== currency) {
            currencyAndRate = {
                symbol: "USD", rate: undefined
            }
        }
        else {
            currencyAndRate = {
                symbol: ph.localCurrency.symbol, rate: ph.localCurrency.rate
            }
        }
    }
    const showItemPrices = user.permissions.enableLiveProposalPricing;

    const { roeData } = partsPackages[props.system.partsPackageId];

    const warnings = roeData.rules.flatMap(rule => rule.apply(props.system, user, props.grower));

    const totalCostCents = ph.system.totalListPriceUsdCents;
    let sectionIdToElementMapping: {[sectionId: string] : {elements: JSX.Element[]}} = {};

    let partsGroupedBySection = getGroupedPartsForSystem(props.system).partSections;
    const anyPriceIsNaN = ph.system.anyPriceIsNaN;

    const wrapForCsv = (str: string) => {
        return '"' + str.replace(/"/g, '""') + '"';
    }

    let csv = "Qty,Part Number,Description";
    if (showItemPrices){
        csv += ",Unit Price ($)"
    }

    for (let sectionId in ph.system.sectionIdToPartsMapping) {
        let sectionLabel: string = "Unknown";
        for (const [key, value] of Object.entries(roeData.poSchema.sections)) {
            if (value.seq.toString() === sectionId){
                sectionLabel = value.label;
            }
        }

        sectionIdToElementMapping[sectionId] = {elements: []};
        sectionIdToElementMapping[sectionId].elements.push(<Typography style={{textDecoration: "underline"}}>{sectionLabel}</Typography>);

        ph.system.sectionIdToPartsMapping[sectionId].parts.forEach(p => {
            let costStr: string;
            if (!isNaN(p.totalPriceUsdCents)) {
                costStr = formatUSDCentsByRate(p.totalPriceUsdCents, currencyAndRate.symbol, currencyAndRate.rate);
            }
            else {
                costStr = "Unknown";
            }

            csv += `\r\n${p.qty},${wrapForCsv(p.partNumber)},${wrapForCsv(p.description)}`;
            if (showItemPrices) {
                csv += `,${p.unitPriceUsdCents/100}`;
                costStr = ": " + costStr;
            } else {
                costStr = "";
            }
            sectionIdToElementMapping[sectionId].elements.push(
                <Typography fontSize={12}>{p.qty} * {p.description} ({p.partNumber}){costStr}</Typography>
            );
        });
    }

    let totalStr = `${t("common-phrases.sltTotalListPrice")}: ${formatUSDCentsByRate(totalCostCents, currencyAndRate.symbol, currencyAndRate.rate)}`;
    let paddingBottom = "5px";
    if (anyPriceIsNaN){
        totalStr += "*";
        paddingBottom = "22px";
    }

    let elements: JSX.Element[] = [];
    for (const [key, value] of Object.entries(sectionIdToElementMapping)) {
        elements = elements.concat(value.elements);
        elements.push(<Divider/>);
    } 

    const onDownloadCsv = () => {
        //to allow us to use the "download" prop of the a tag but be styled (reliably) like a MaterialUI button
        const link = document.createElement("a");
        link.download = `${props.system.name}-proposal.csv`;
        link.href = `data:text/csv;charset=utf-8, ${encodeURIComponent(csv)}`;
        link.click();
    }

    return (
        <>
        <Box sx={{paddingTop: "5px", paddingBottom, paddingRight: "10px"}}>
        {
            !warnings.some(x => x.severity === "denied") && <Box>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center",  marginBottom: "5px"}}>
                    <Button variant="outlined" onClick={(e) => {
                        e.stopPropagation();
                        onDownloadCsv();
                    }} color="primary">
                        {t("download")} CSV
                    </Button>               
                    {
                        currencyAndRate.symbol === "USD"
                            ? (
                                <Typography sx={{ fontSize: "1.2em" }}>
                                    <strong>{totalStr}</strong>       
                                </Typography>
                            )
                            : (
                                <Typography sx={{ fontSize: "1.1em" }}>
                                    <strong><em>{totalStr}</em></strong>       
                                </Typography>
                            )
                    } 
                </Box>
                {
                    anyPriceIsNaN && <Typography sx={{ fontSize:"0.7em", textAlign: "right"}}>
                        <strong>*{t("common-phrases.sltTotalPriceIncomplete")}</strong>       
                    </Typography>
                }
                <CurrencyHeader
                    system={props.system}
                    currency={currencyAndRate.symbol}
                    setCurrency={setCurrency}
                />
            </Box>
        }
            <Divider sx={{marginBottom: "5px"}}/>
                <ProposalWarnings warnings={warnings} />
                <Box sx={{ textAlign: "right" }}>
                    {!warnings.some(x => x.severity === "denied") && elements}
                </Box>
        </Box>
        </>
    );
}