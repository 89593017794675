import { IFEXRate } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IPricingHelperIPrice } from "./iPricePricing";
import { IPricingHelperMiscItems } from "./miscItemsPricing";
import { IPricingHelperSystemPricing } from "./systemPricing";

export interface IPricingHelperTotals {
    subTotalMinusTaxMinorUnits: (outputSymbol: string, include: {
        system: boolean,
        miscItems: boolean,
        installation: boolean,
        freight: boolean,
        pivotPad: boolean,
        tradeIn: boolean
    }) => number;
    taxMinorUnits: (outputSymbol: string, include: {
        system: boolean,
        miscItems: boolean,
        installation: boolean,
        freight: boolean,
        pivotPad: boolean,
        tradeIn: boolean
    }) => number;
}

const convertToUsdOrNaN = (value: number, valueCurrency: string, rate?: IFEXRate) => {
    if (valueCurrency === "USD") {
        return value;
    }
    else if (!rate || rate.exchangeRate_1USDToSymbol === 0) {
        return NaN;
    }
    else {
        return value / rate.exchangeRate_1USDToSymbol;
    }
}

export const calculateTotals = (
    systemPricing: IPricingHelperSystemPricing, miscItems: IPricingHelperMiscItems,
    installation: IPricingHelperIPrice, freight: IPricingHelperIPrice,
    pivotPad: IPricingHelperIPrice, tradeIn: IPricingHelperIPrice,
    rate?: IFEXRate
): IPricingHelperTotals => {

    const subTotalMinusTaxMinorUnits = (outputSymbol: string, include: {
        system: boolean,
        miscItems: boolean,
        installation: boolean,
        freight: boolean,
        pivotPad: boolean,
        tradeIn: boolean
    }) => {
        let subTotalMinusTaxUsdCents = 0;
        if (include.system) {
            subTotalMinusTaxUsdCents += systemPricing.systemPriceUsdCents;
        }
        if (include.miscItems) {
            subTotalMinusTaxUsdCents += convertToUsdOrNaN(miscItems.preTaxTotalCostMinorUnits, miscItems.currency, rate);
        }
        if (include.installation) {
            subTotalMinusTaxUsdCents += convertToUsdOrNaN(installation.priceMinorUnits, installation.currency, rate);
        }
        if (include.freight) {
            subTotalMinusTaxUsdCents += convertToUsdOrNaN(freight.priceMinorUnits, freight.currency, rate);
        }
        if (include.pivotPad) {
            subTotalMinusTaxUsdCents += convertToUsdOrNaN(pivotPad.priceMinorUnits, pivotPad.currency, rate);
        }
        if (include.tradeIn) {
            subTotalMinusTaxUsdCents -= convertToUsdOrNaN(tradeIn.priceMinorUnits, tradeIn.currency, rate);
        }
        if (outputSymbol === "USD") {
            return subTotalMinusTaxUsdCents;
        }
        if (!rate) return NaN;
        return subTotalMinusTaxUsdCents * rate.exchangeRate_1USDToSymbol;
    }
    
    const taxMinorUnits = (outputSymbol: string, include: {
        system: boolean,
        miscItems: boolean,
        installation: boolean,
        freight: boolean,
        pivotPad: boolean,
        tradeIn: boolean
    }) => {
        let taxUsdCents = 0;
        if (include.system) {
            taxUsdCents += systemPricing.systemTaxUsdCents;
        }
        if (include.miscItems) {
            taxUsdCents += convertToUsdOrNaN(miscItems.totalTaxMinorUnits, miscItems.currency, rate);
        }
        if (include.installation) {
            taxUsdCents += convertToUsdOrNaN(installation.taxMinorUnits, installation.currency, rate);
        }
        if (include.freight) {
            taxUsdCents += convertToUsdOrNaN(freight.taxMinorUnits, freight.currency, rate);
        }
        if (include.pivotPad) {
            taxUsdCents += convertToUsdOrNaN(pivotPad.taxMinorUnits, pivotPad.currency, rate);
        }
        if (include.tradeIn) {
            taxUsdCents -= convertToUsdOrNaN(tradeIn.taxMinorUnits, tradeIn.currency, rate);
        }
        if (outputSymbol === "USD") {
            return taxUsdCents;
        }
        if (!rate) return NaN;
        return taxUsdCents * rate.exchangeRate_1USDToSymbol;
    }

    return {
        subTotalMinusTaxMinorUnits,
        taxMinorUnits
    }
}
