import ISystem from "rdptypes/project/ISystem";
import { getInitialPackageState } from "../helpers/SprinklerHelper";
import { IPropertyValueMap } from "./interfaces";


export const resetPackages = (sys: ISystem, pvm: IPropertyValueMap): IPropertyValueMap => {
    let newPropertyMap: IPropertyValueMap = {
        ...pvm
    }
    sys.FlangedSide.Sprinklers.Package = [];
    for (let i = 0; i < 3; i++) {
        sys.FlangedSide.Sprinklers.Package.push(getInitialPackageState(i + 1));
    }
    sys.FlexSide.Sprinklers.Package = [];
    for (let i = 0; i < 3; i++) {
        sys.FlexSide.Sprinklers.Package.push(getInitialPackageState(i + 1));
    }
    newPropertyMap["FlangedSide.Sprinklers.Package"] = structuredClone(sys.FlangedSide.Sprinklers.Package);
    newPropertyMap["FlexSide.Sprinklers.Package"] = structuredClone(sys.FlexSide.Sprinklers.Package);
    return newPropertyMap;
}

export const resetChart = (sys: ISystem, pvm: IPropertyValueMap): IPropertyValueMap => {
    let newPropertyMap: IPropertyValueMap = {
        ...pvm
    }
    // Note: Don't do this here, this is handled by the validation worker


    
    // if (sys.FlangedSide.SprinklerChart.Outlet.length) {
    //     newPropertyMap["FlangedSide.SprinklerChart.Outlet"] = [];
    // }
    // if (sys.FlangedSide.SprinklerChart.AdditionalSpanData.length) {
    //     newPropertyMap["FlangedSide.SprinklerChart.AdditionalSpanData"] = [];
    // }
    // if (sys.FlexSide.SprinklerChart.Outlet.length) {
    //     newPropertyMap["FlexSide.SprinklerChart.Outlet"] = [];
    // }
    // if (sys.FlexSide.SprinklerChart.AdditionalSpanData.length) {
    //     newPropertyMap["FlexSide.SprinklerChart.AdditionalSpanData"] = [];
    // }
    // if (sys.SprinklerEngineResultWarnings) {
    //     newPropertyMap["SprinklerEngineResultWarnings"] = undefined;
    // }
    return newPropertyMap;
}