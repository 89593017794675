import { Polygon } from "@turf/turf";
import FeatureHelpers from "rdptypes/geometry/helpers/features";
import { IEndgunInformation, ILateralEndGunInformation } from "rdptypes/project/IEndgunInformation";
import ISystem from "rdptypes/project/ISystem";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import CenterPivotGeometryHelper from "../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { IProjectsMap, findModifiedLayouts } from "./utils/findModifiedSystems";

export interface IEndGunWorkerInputMessage {
    type: "input",
    payload: {
        id: string;
        previousProjects: IProjectsMap;
        projects: IProjectsMap;
    }
};

export interface IProjectUpdate { 
    projectId: string, 
    layouts: ILayoutUpdate[]
}
interface ILayoutUpdate { 
    layoutId: string, 
    systems: ISystemUpdate[]
}
interface ISystemUpdate { 
    systemId: string, 
    centerPivotOnOffs?: IEndgunInformation[],
    lateralOnOffsFlanged?: ILateralEndGunInformation[],
    lateralOnOffsFlex?: ILateralEndGunInformation[]
};

const compareOnOffs = (current: any, previous: any | undefined) => {
    if (!previous) return true;
    if (JSON.stringify(previous) !== JSON.stringify(current)) return true;
    return false;
}

export const handleEndGuns = (args: IEndGunWorkerInputMessage, beforeDeepCheckHook: () => void) => {
    const { previousProjects, projects } = args.payload;
    const modifiedProjects = findModifiedLayouts(previousProjects, projects);
    const updates: IProjectUpdate[] = [];
    if (modifiedProjects.length) {
        console.log("EndGunWorkerContext: modifiedProjects", modifiedProjects);
        beforeDeepCheckHook();
    }
    for (const modifiedProject of modifiedProjects) {
        const { layoutIds, projectId } = modifiedProject;
        const dbPrj = projects[projectId];
        const project = dbPrj;
        const layoutUpdates: ILayoutUpdate[] = [];
        for (const layoutId of layoutIds) {
            const systemUpdates: ISystemUpdate[] = [];
            const layout = project.layouts[layoutId];
            const systems: {
                system: ISystem;
                centerPivotGh?: CenterPivotGeometryHelper;
                lateralPivotGh?: LateralGeometryHelper;
                systemId: string;
            }[] = Object.entries(layout.systems).map(([systemId, system]) => {
                let centerPivotGh: CenterPivotGeometryHelper | undefined = undefined;
                let lateralPivotGh: LateralGeometryHelper | undefined = undefined;
                switch (system.SystemProperties?.SystemType) {
                    case SystemTypes.CenterPivot:
                        centerPivotGh = new CenterPivotGeometryHelper({
                            project,
                            systemId,
                            layoutId
                        });   
                        break;                 
                    case SystemTypes.HoseFeedMaxigator:
                    case SystemTypes.CanalFeedMaxigator:
                        lateralPivotGh = new LateralGeometryHelper({
                            project,
                            systemId,
                            layoutId
                        });
                        break;
                }
                return {
                    system,
                    centerPivotGh,
                    lateralPivotGh,
                    systemId
                }
            }).filter(x => x.centerPivotGh || x.lateralPivotGh);

            const addedGuns: Polygon[] = [];
            for (let i = 0; i < systems.length; i++) {
                const { system } = systems[i];
                if (system.sacOptimizerResult && system.sacOptimizerResult.success) {
                    system.sacOptimizerResult.geometry.endGunAreas.forEach(ps => addedGuns.push(...ps))
                }
            }

            for (let i = 0; i < systems.length; i++) {
                const  { systemId, system, lateralPivotGh, centerPivotGh } = systems[i];
                if (system.sacOptimizerResult) {
                    // pass
                }
                else {
                    if (centerPivotGh) {
                        try {
                            const updatedOnOffs = centerPivotGh.calculateEndGunOnOffs(addedGuns);
                            if (compareOnOffs(updatedOnOffs, system.endGuns?.centerPivotOnOffs)) {
                                console.log(">> updated needed");
                                systemUpdates.push({
                                    systemId,
                                    centerPivotOnOffs: updatedOnOffs
                                })
                            }
                            for (const eg of updatedOnOffs) {
                                for (const onOff of eg.onOffs) {
                                    const endGunPoly = FeatureHelpers.GetAnnulusSectorDrawFeature(
                                        eg.center,
                                        eg.throwStartFeet,
                                        eg.throwEndFeet,
                                        onOff.startBearing,
                                        onOff.endBearing,
                                        null,
                                        { degreeIncrement: 0.1 }
                                    );
                                    addedGuns.push(endGunPoly.geometry)
                                }
                            }
                        }
                        catch {
                            console.log("Failed to calculate end guns for", projectId, layoutId, systemId, "cp");
                        }
                    }
                    else if (lateralPivotGh) {
                        const latSystemUpdate: ISystemUpdate = { systemId };
                        try {
                            const rigid = lateralPivotGh.calculateEndGunPolygonsForSide('rigid', addedGuns);
                            if (compareOnOffs(rigid.endGunInformation, system.endGuns?.lateralOnOffsFlanged)) {
                                console.log(">> rigid updated needed")
                                latSystemUpdate.lateralOnOffsFlanged = rigid.endGunInformation;
                                addedGuns.push(...rigid.features.map(x => x.feature.geometry))
                            }
                        }
                        catch {
                            console.log("Failed to calculate end guns for", projectId, layoutId, systemId, "rigid");
                            latSystemUpdate.lateralOnOffsFlanged = [];
                        }
                        try {
                            const flex = lateralPivotGh.calculateEndGunPolygonsForSide('flex', addedGuns);
                            if (compareOnOffs(flex.endGunInformation, system.endGuns?.lateralOnOffsFlex)) {
                                console.log(">> flex updated needed")
                                latSystemUpdate.lateralOnOffsFlex = flex.endGunInformation;
                                addedGuns.push(...flex.features.map(x => x.feature.geometry))
                            }
                        }
                        catch {
                            console.log("Failed to calculate end guns for", projectId, layoutId, systemId, "flex");
                            latSystemUpdate.lateralOnOffsFlex = [];
                        }
                        if (latSystemUpdate.lateralOnOffsFlanged || latSystemUpdate.lateralOnOffsFlex) {
                            systemUpdates.push(latSystemUpdate)
                        }
                    }
                }
            }

            if (systemUpdates.length) {
                layoutUpdates.push({
                    layoutId,
                    systems: systemUpdates
                })
            }
        }
        if (layoutUpdates.length) {
            updates.push({
                projectId, 
                layouts: layoutUpdates
            })
        }
    }
    return updates;
}