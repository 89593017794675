import { booleanContains, booleanDisjoint, difference, intersect, LineString, polygon, Polygon } from "@turf/turf";
import { loCustom } from "rdptypes/geometry/helpers/turf";
import { PartialSpanLengthItem, partialToSpanLengthItem } from "../../components/OptimizeSystemDialog/RestrictSpanLengthsControl";
import { getAvailableSpanLengthsWithExtension } from "../../helpers/validation/spanExtensions";
import { fillSystem, fillSystemWithEndBoom, IAvoidWheelRegion, IFillSystemSpan, sum } from "./optimizeSpansCommon";

interface IArgs {
    feedLine: LineString;
    boundary: Polygon;
    obstacles: Polygon[];
    allowableSpanLengths?: PartialSpanLengthItem[];
    wheelObstacles: Polygon[];
    side: 'aft' | 'fwd',
    isFlangedSideAndAttachedToCart: boolean;
    isHoseFeed: boolean;
    allowableEndBoomLengths?: number[];
    currentNumberOfSpans: number
}

const booleanContains_PolygonsCustom = (poly1: Polygon, poly2: Polygon) => {
    // NOTE Simon 16/02/2024: bug in turfjs
    // turf booleanContains does not find true when all verticies of poly2 are inside the first polygon!
    // i.e.:
    // if poly1:            poly2:             
    //       ********       
    //       *      *         xxxxxxx
    //       ***    *         x     x      
    //         *    *         x     x
    //         *    *         x     x
    // *********    *         x     x
    // *            *         xxxxxxx
    // **************
    if (!booleanContains(poly1, poly2)) {
        return false;
    }
    return difference(poly2, poly1) === null;
}

const findMinDistanceToBoundary = (boundary: Polygon, feedLine: LineString, multiplier: number) => {
    let systemTo = 0;
    let offset = 100;
    while (offset >= 1) {
        const isInBoundary = () => {
            const endLine = loCustom(
                feedLine,
                (systemTo + offset) * multiplier,
                { units: 'feet' }
            )
            const ia = polygon(
                [
                    [
                        ...feedLine.coordinates,
                        ...endLine.geometry.coordinates.reverse(),
                        feedLine.coordinates[0]
                    ]
                ]
            )
            return booleanContains_PolygonsCustom(boundary, ia.geometry);
        }
        while (isInBoundary()) {
            systemTo += offset;
        }
        offset /= 10;
    }
    return systemTo;
}

const findMinDistanceToObstacles = (obstacles: Polygon[], boundary: Polygon, feedLine: LineString, multiplier: number, systemTo: number) => {
    let minDistance = systemTo; 
    const obstaclesInBoundary = obstacles
        .map((o): (Polygon[] | null) => {
            const i = intersect(boundary, o);
            if (!i) return null;
            if (i.geometry.type === 'MultiPolygon') {
                return i.geometry.coordinates.map(p => polygon(p).geometry)
            }
            else {
                return [i.geometry]
            }
        })
        .filter((o): o is Polygon[] => o !== null)
        .flatMap(o => o);

    obstaclesInBoundary.forEach(o => {

        let obstacleTo = 0;
        let offset = 100;
        while (offset >= 1) {
            const isDisjointFromObstacle = () => {
                const endLine = loCustom(
                    feedLine,
                    (obstacleTo + offset) * multiplier,
                    { units: 'feet' }
                )
                const ia = polygon(
                    [
                        [
                            ...feedLine.coordinates,
                            ...endLine.geometry.coordinates.reverse(),
                            feedLine.coordinates[0]
                        ]
                    ]
                )
                return booleanDisjoint(o, ia);
            }
            while (obstacleTo < minDistance && isDisjointFromObstacle()) {
                obstacleTo += offset;
            }
            offset /= 10;

        }
        if (obstacleTo < minDistance) {
            minDistance = obstacleTo;
        }
    })
    return minDistance;
}

const accumulateWheelObstacleRegions = (wheelObstacles: Polygon[], feedLine: LineString, systemTo: number, multiplier: number) => {
    let systemToCopy = systemTo;
    let avoidWheelRegions: IAvoidWheelRegion[] = [];
    if (wheelObstacles.length === 0) return {
        avoidWheelRegions: [],
        systemTo
    };
    
    const endLine = loCustom(
        feedLine,
        systemToCopy * multiplier,
        { units: 'feet' }
    )
    const tempBoundary = polygon(
        [
            [
                ...feedLine.coordinates,
                ...endLine.geometry.coordinates.reverse(),
                feedLine.coordinates[0]
            ]
        ]
    )
    const wheelObstaclesInBoundary = wheelObstacles
        .filter(x => !booleanDisjoint(x, tempBoundary));
    
    avoidWheelRegions = wheelObstaclesInBoundary.map(o => {
        let obstacleTo = 0;
        const isNotInObstacle = () => {
            const endLine = loCustom(
                feedLine,
                (obstacleTo + 1) * multiplier,
                { units: 'feet' }
            )
            const ia = polygon(
                [
                    [
                        ...feedLine.coordinates,
                        ...endLine.geometry.coordinates.reverse(),
                        feedLine.coordinates[0]
                    ]
                ]
            )
            return booleanDisjoint(o, ia);
        }
        while (obstacleTo < systemToCopy && isNotInObstacle()) {
            obstacleTo += 1;
        }
        const from = obstacleTo;

        const isInObstacle = () => {
            const endLine = loCustom(
                feedLine,
                (obstacleTo + 1) * multiplier,
                { units: 'feet' }
            )
            return !booleanDisjoint(o, endLine);
        }
        while (obstacleTo < systemToCopy && isInObstacle()) {
            obstacleTo += 1;
        }
        const to = obstacleTo + 1;
        return { from, to };
    })

    return { avoidWheelRegions, systemTo: systemToCopy };
}

export const optimizeSpansForLateral = (args: IArgs): {spans: IFillSystemSpan[], endBoom?: number } => {
    const { feedLine, boundary, obstacles, allowableSpanLengths, side, wheelObstacles, isFlangedSideAndAttachedToCart, allowableEndBoomLengths = [] } = args;

    const sortedAllowableSpanLengths = allowableSpanLengths 
        ? allowableSpanLengths.map(partialToSpanLengthItem)
        : [ ...getAvailableSpanLengthsWithExtension(false, args.isHoseFeed) ];
    sortedAllowableSpanLengths.sort((a,b) => b.totalLength - a.totalLength);

    if (sortedAllowableSpanLengths.length === 0) {
        console.log("Cannot optimize a system with no allowable spans");
        return {spans: [] };
    }
    if (!booleanContains(boundary, feedLine)) {
        console.log("feedl line not within boundary")
        return {spans: [] };
    }
    
    const multiplier = side === 'fwd' ? 1 : -1;

    // find min perpendicular distance from feedline to boundary:
    let systemTo = findMinDistanceToBoundary(boundary, feedLine, multiplier);
    // console.log("systemto boundary", systemTo)
    // console.log("systemTo", systemTo);

    // consider obstacles:
    systemTo = findMinDistanceToObstacles(obstacles, boundary, feedLine, multiplier, systemTo);
    // console.log("systemTo with obstacles", systemTo);
    
    // accumulate wheel obstacle regions:
    const wheelObstacleResults = accumulateWheelObstacleRegions(wheelObstacles, feedLine, systemTo, multiplier);
    systemTo = wheelObstacleResults.systemTo;
    const avoidWheelRegions = wheelObstacleResults.avoidWheelRegions;
    // console.log("systemTo with wheel obstacles", systemTo);
    // console.log("avoidWheelRegions", avoidWheelRegions);
    
    if (isFlangedSideAndAttachedToCart) {
        // the first span of a lateral flanged side must be 175ft or smaller: 
        const allAllowableFirstSpans = getAvailableSpanLengthsWithExtension(true, args.isHoseFeed)
            .filter(x => x.spanLength < 175 || (x.spanLength === 175 && !x.extension));
        const allowableFirstSpans = allAllowableFirstSpans.filter(x => 
            sortedAllowableSpanLengths.find(y => 
                y.extension === x.extension &&
                y.spanLength === x.spanLength &&
                y.totalLength === x.totalLength
            )
        );
        let best: { spans: IFillSystemSpan[]; sum: number; endBoom?: number } = { spans: [], sum: 0 };
        for (let i = 0; i < allowableFirstSpans.length; i++) {
            const crntFirstSpan = allowableFirstSpans[i];
            // cant fill if span is greater then available space:
            if (crntFirstSpan.totalLength > systemTo) continue;
            const spans = fillSystem(avoidWheelRegions, [crntFirstSpan], crntFirstSpan.totalLength, 0, false, args.isHoseFeed, args.currentNumberOfSpans);
            if (spans.length !== 1) {
                continue;
            }
            const fill = fillSystemWithEndBoom(avoidWheelRegions, sortedAllowableSpanLengths, systemTo, allowableEndBoomLengths, args.isHoseFeed, crntFirstSpan.totalLength, 1 + args.currentNumberOfSpans);
            spans.push(...fill.spans);
            const s = sum(spans.map(x => x.spanAndExtensionLength)) + (fill.endBoom || 0);
            if (s > best.sum) {
                best = { spans, sum: s, endBoom: fill.endBoom }
            }
        }
        // Need to handle the case where only 1 span was returned, as this span will not contain the 1 ft!
        if (best.spans.length === 1) {
            return fillSystemWithEndBoom(avoidWheelRegions, allowableFirstSpans, systemTo, allowableEndBoomLengths, args.isHoseFeed, 0, args.currentNumberOfSpans)
        }
        return best;

    }
    else {
        return fillSystemWithEndBoom(avoidWheelRegions, sortedAllowableSpanLengths, systemTo, allowableEndBoomLengths, args.isHoseFeed, 0, args.currentNumberOfSpans); 
    }
}