import IGetForeignExchangeRatesResponse from "rdptypes/api/IGetForeignExchangeRatesResponse";
import { IFEXContext, IFXCurrencySymbol } from "rdptypes/foreignExchange/interfaces";
import { symbolsResponse } from "rdptypes/foreignExchange/symbols";
import * as React from "react";
import ApiClientCtx from "../api/ApiClientCtx";

export const FEXContext = React.createContext<IFEXContext>(null);

// NOTE: This API does not refresh after some time
// Perhaps note on PDFs should contain timestamp of ex too

export const FEXProvider: React.FC<React.PropsWithChildren> = ({ children }) => {

    const api = React.useContext(ApiClientCtx);
    const [ latest, setLatest ] = React.useState<IGetForeignExchangeRatesResponse | null>(null);
    const available = latest !== null;

    const value: IFEXContext = {
        symbols: symbolsResponse.symbols,
        available,
        convertTo: (value: number, toSymbol: IFXCurrencySymbol | string) => {
            const rate = latest?.rates[toSymbol];
            if (toSymbol === "USD" || !rate || typeof rate !== "number" || !isFinite(rate)) {
                return {
                    value,
                    symbol: "USD"
                }
            }
            return {
                value: value * rate,
                symbol: toSymbol as IFXCurrencySymbol
            }
        },
        validAtTimestampMs: available && latest.timestamp 
            ? (latest.timestamp * 1000) // into ms
            : undefined
    };

    React.useEffect(() => {
        api.getForeignExchangeRates()
            .then(x => {
                if (x.error || !x.result) {
                    throw new Error(x.error);                    
                }
                else {
                    setLatest(x.result);
                }
            })
            .catch(e => {
                console.warn("FEX not available", e);
            })
    }, [])

    return (
        <FEXContext.Provider value={value}>
            {children}
        </FEXContext.Provider>
    )
}