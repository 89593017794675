import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { FC, useContext } from "react";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import { FEXContext } from "../../../../foreignExchange";
import { pricingHelper } from "../../../../helpers/pricingHelper";
import DealerDataCtx from "../../../../userData/DealerDataCtx";
import MiscItemRow from "./MiscItemRow";
import MiscItemsHeader from "./MiscItemsHeader";

interface Props {
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
};

const MAX_ROW_COUNT = 25;
const MiscItemsRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const fex = useContext(FEXContext);
    const user = useContext(DealerDataCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const ph = pricingHelper({ 
        system, user, fex
    });

    return (
        <>
            {
                system.partsPackageId >= "2024110401" && (
                    <MiscItemsHeader dbPrj={props.dbPrj} layoutId={props.layoutId} systemId={props.systemId} />
                )
            }
            <TableContainer component={Paper}>
                <Table sx={{}} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>{i18next.format(i18next.t("description"), 'capitalize')}</TableCell>
                            <TableCell>{`${i18next.format(i18next.t("price"), 'capitalize')} (${ph.miscItems.currency})`}</TableCell>
                            <TableCell>{i18next.format(i18next.t("taxable"), 'capitalize')}?</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            ph.miscItems.items.map((x, idx) => {
                                return <MiscItemRow 
                                    itemCurrency={ph.miscItems.currency}
                                    key={x.id}
                                    idx={idx}
                                    deleteMiscItem={(id) => {
                                        ph.miscItems.deleteItem(id, props.dbPrj, props.layoutId, props.systemId, authState);
                                    }}
                                    saveMiscItem={(item) => {
                                        ph.miscItems.saveItem(item, props.dbPrj, props.layoutId, props.systemId, authState);
                                    }}
                                    miscItem={x}
                                />
                            })
                        }
                        {
                            ph.miscItems.items.length < MAX_ROW_COUNT && (
                                <MiscItemRow 
                                    itemCurrency={ph.miscItems.currency}
                                    key={"new-item" + ph.miscItems.items.length}
                                    idx={ph.miscItems.items.length}
                                    deleteMiscItem={(id) => {
                                        ph.miscItems.deleteItem(id, props.dbPrj, props.layoutId, props.systemId, authState);
                                    }}
                                    saveMiscItem={(item) => {
                                        ph.miscItems.saveItem(item, props.dbPrj, props.layoutId, props.systemId, authState);
                                    }}
                                    miscItem={null}
                                />
                            )
                        }
                    </TableBody>
                </Table>
                
            </TableContainer>
        </>
    );
};

export default MiscItemsRenderer;