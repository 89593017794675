import { Button, Stack } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC, useContext, useState } from "react";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import { FEXContext } from "../../../../foreignExchange";
import { pricingHelper } from "../../../../helpers/pricingHelper";
import DealerDataCtx from "../../../../userData/DealerDataCtx";
import CustomerPricingModal from "./CustomerPricingModal";
import DealerPricingModal from "./DealerPricingModal";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
};

const ProposalInformationDiscountsSectionRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const user = useContext(DealerDataCtx);
    const fex = useContext(FEXContext);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const [showDealerPricingModal, setShowDealerPricingModal] = useState(false);
    const [showCustomerPricingModal, setShowCustomerPricingModal] = useState(false);

    const ph = pricingHelper({ system, user, fex });

    const showProposalFexInfo = ph.localCurrency?.symbol !== "USD";

    return (
        <Stack direction={'column'}>
            <Stack direction={'row'}>
                <Button onClick={()=> setShowDealerPricingModal(true)}>{i18next.format(t('common-phrases.sltDealerPricing'), 'capitalize-each')}</Button>
                <DealerPricingModal layoutId={props.layoutId} systemId={props.systemId} dbPrj={props.dbPrj} show={showDealerPricingModal} onClose={() => setShowDealerPricingModal(false)}/>
                <Button onClick={()=> setShowCustomerPricingModal(true)}>{i18next.format(t('common-phrases.sltCustomerPricing'), 'capitalize-each')}</Button>
                <CustomerPricingModal layoutId={props.layoutId} systemId={props.systemId} dbPrj={props.dbPrj} show={showCustomerPricingModal} onClose={() => setShowCustomerPricingModal(false)}/>
            </Stack>
            {
                (showProposalFexInfo) &&
                <>
                    {ph.helpers.generateFexWarningNoteLines(true).map((l, i) => <span key={i}>{l}</span>)}
                    <span style={{ paddingTop: 20 }}>{i18next.format(t('update-proposal-fex-rate'))}:</span>
                    {
                        ph.localCurrencyRateUpdateAvailable.available && (
                            <span>
                                {`(Latest Rate 1 USD : ${ph.localCurrencyRateUpdateAvailable.rate.exchangeRate_1USDToSymbol} ${ph.localCurrency?.symbol} ${t("exchange-rate-effective-as-of")} ${new Date(ph.localCurrencyRateUpdateAvailable.rate.validAtTimestampMs).toLocaleString()})`}
                            </span>
                        )
                    }
                    <Button 
                        onClick={()=> ph.updateLocalCurrencyRate(props.dbPrj, props.layoutId, props.systemId, authState)}
                        fullWidth={false}
                        disabled={!ph.localCurrencyRateUpdateAvailable.available}
                        sx={{ alignSelf: "flex-start" }}
                    >
                        Update
                    </Button>
                </>
            }
        </Stack>
    );
};

export default ProposalInformationDiscountsSectionRenderer;