
export class SACObstacleConstraints {
    public EquipmentBoundary: jsts.geom.Geometry;
    public STowerBoundary: jsts.geom.Geometry;
    public SpanObstacles: jsts.geom.Geometry[];
    public WheelObstacles: jsts.geom.Geometry[];
    public WetBoundary: jsts.geom.Geometry;
    public WetAreaObstacles: jsts.geom.Geometry[];

    constructor(args: {
        EquipmentBoundary: jsts.geom.Geometry;
        STowerBoundary: jsts.geom.Geometry;
        SpanObstacles: jsts.geom.Geometry[];
        WheelObstacles: jsts.geom.Geometry[];
        WetBoundary: jsts.geom.Geometry;
        wetAreaObstacles: jsts.geom.Geometry[];
    }) {
        this.EquipmentBoundary = args.EquipmentBoundary;
        this.STowerBoundary = args.STowerBoundary;
        this.SpanObstacles = args.SpanObstacles;
        this.WheelObstacles = args.WheelObstacles;
        this.WetBoundary = args.WetBoundary;
        this.WetAreaObstacles = args.wetAreaObstacles;
    }
}