import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import { Point, Polygon, length, lineString, multiLineString, nearestPointOnLine, point } from "@turf/turf";
import { Feature, LineString } from "geojson";
import { getCombineLateralLinePolygons } from "rdptypes/geometry/systems/lateral/helpers";
import { IProperties } from "rdptypes/geometry/systems/lateral/interfaces";
import ISystem, { ILateral } from "rdptypes/project/ISystem";
import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import { v4 as uuidV4 } from "uuid";
import { IDrawUpdateExtEvent_LateralUpdated, rdpFunctions } from ".";
import LateralGeometryHelper from "../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { ISpanVertex } from "../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper/systemCoordinates";
import { getSystemValidity } from "../../GeometryHelpers/SystemGeometryHelpers/getSystemValidity";
import { IGetSystemValidityArgs } from "../../GeometryHelpers/SystemGeometryHelpers/interfaces";
import { isOfMetaType } from "./copied-from-mapbox-gl-draw/common_selectors";
import * as Constants from "./copied-from-mapbox-gl-draw/constants";
import createVertex from "./copied-from-mapbox-gl-draw/create_vertex";
import doubleClickZoom from "./copied-from-mapbox-gl-draw/double_click_zoom";
const DirectSelectMode = MapboxDraw.modes.direct_select;
export const LateralSelectMode = { ...DirectSelectMode };
export default LateralSelectMode;

// Notes:
// This extension extends direct_select mode.
// It is intended to handle all interaction with center pivot systems
// Since the center pivot system comprises of multiple components/geometries (tracks, wraps, pivot center, etc..),
// it is perhaps easier to not render any of these system components, and instead render a simplified geometry which
// can be interacted with.
// So, onSetup (called when entering this mode), will create any additional geometry required. This geometry is deleted
// when leaving this mode.
// An custom event (draw.update_ext) is fired when leaving this mode, this can be used to react to the drawn changes

interface IState {
    systemId: string;
    layoutId: string;
    systemValidityArgs: IGetSystemValidityArgs;
    
    featureId?: string;
    verticies?: ISpanVertex[];
    definition?: IProperties;
    wheelTrackFeatureId?: string;

    selectedCoordPaths: any;
    dragMoving: boolean;

    fire_lateral_updated?: boolean;

    dragId?: string;

    addedSegment: boolean;
    pivotEndMode: "drag" | "add";

    pivotingEndMode?: {
        mode: "drag" | "add",
        lateral: ILateral;
        selectedVertex?: ISpanVertex;
    }

    system: ISystem;
}

LateralSelectMode.onSetup = function (this, opts) {
    if (!opts.definition || !opts.definition.isLateral || !opts.definition.systemId) {
        throw new Error("You must provide a lateral feature to enter lateral_pivot_select mode");
    }
    const { systemId, layoutId } = opts.definition;

    const project = rdpFunctions(this).getProject();
    const system = project?.layouts[layoutId]?.systems[systemId];
    if (!system) {
        throw new Error('You must provide a valid center pivot system to enter center_pivot_select mode');
    }

    const gh = new LateralGeometryHelper({ project, systemId, layoutId });
    const systemValidityArgs = gh.getSystemValidityArgs();

    const state = {
        dragMoveLocation: opts.startPos || null,
        dragMoving: false,
        canDragMove: false,
        selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
        systemId: systemId,
        layoutId: layoutId,
        initialDefinition: opts.definition,
        systemValidityArgs,
        addedSegment: false,
        pivotEndMode: "drag",
        system: structuredClone(system)
    } as IState;

    this.setSelectedCoordinates([]);
    // this.setSelected(featureId);
    doubleClickZoom.disable(this);

    this.setActionableState({
        trash: true,
    });

    this.map.fire("draw.update_ext", {
        action: "lateral_selected",
        definition: opts.definition,
    });

    return state;
};

LateralSelectMode.toDisplayFeatures = function (this: MapboxDraw.DrawCustomModeThis, state: IState, feature: Feature, display) {
    if (feature.properties.user_rdpFeatureType === 'obstacleClearance' && feature.properties.user_systemId === state.systemId) {
        // dont render the system obstacle boundary in this mode
    }
    else {
        display(feature);
    }
    const mp = rdpFunctions(this as any).getMapPermissions();
    if (LateralGeometryHelper.isLateral(feature) && feature.properties.user_systemId === state.systemId && mp.editSystem(state.systemId)) {
        const definition = LateralGeometryHelper.getDefinition(feature as any);
        if (definition) {
            feature.properties.active = Constants.activeStates.ACTIVE;
            const result = LateralGeometryHelper.createSingleGeoJSONFeature(state.system);
            if (result) {
                
                const { verticies } = result;
                const supplementaryPoints = verticies.map((x, idx) => {
                    const vertex = createVertex(feature.properties.id, x.handle.coordinates, `0.${idx}`, false) as Feature;
                    if ("pivotIdLabel" in x && x.pivotIdLabel) {
                        vertex.properties.user_label = x.pivotIdLabel;
                    }
                    return vertex;
                });
                
                supplementaryPoints.forEach(display);
                state.featureId = feature.properties.id;
                state.verticies = verticies;
                state.definition = definition;
                state.wheelTrackFeatureId = feature.properties.user_wheelTrackFeatureId;
            }
            if (!state.dragId) {
                const project = rdpFunctions(this).getProject();
                const system = project?.layouts[state.layoutId]?.systems[state.systemId];
                state.system = structuredClone(system);
            }
            
        }
    }
    this.fireActionable(state);
};

LateralSelectMode.onStop = function (this, state: IState) {
    // this.deleteFeature([state.featureId]);
    doubleClickZoom.enable(this);
    this.clearSelectedCoordinates();
};

LateralSelectMode.onDrag = function (this, state: IState, e) {
    const { selectedCoordPaths, definition } = state;
    const { lngLat } = e;
    if (selectedCoordPaths.length !== 1) return;

    const selectedVertexIndexString = selectedCoordPaths[0].split(".")[1];
    if (!selectedVertexIndexString) return;

    const selectedVertex = state.verticies[parseInt(selectedVertexIndexString)] as ISpanVertex;
    if (!selectedVertex) return;


    const movedPoint = point([ lngLat.lng, lngLat.lat ]);
    handlePoint(this, state, movedPoint, selectedVertex);

};

const handlePoint = (that: MapboxDraw.DrawCustomModeThis & MapboxDraw.DrawCustomMode<any, any>, state: IState, movedPoint: Feature<Point>, incommingSelectedVertex: ISpanVertex | { type: "midpoint", selectedSegmentIndex: number }) => {
    
    const feature = that.getFeature(state.featureId);
    if (!feature) return;
    const definition = state.definition;
    if (!definition) return;

    let result: {
        geometry: Polygon | LineString;
        verticies: ISpanVertex[];
        wheelTracks: LineString[];
    } | undefined = undefined;
    let undo: () => void = () => {};
    const selectedVertex = state.pivotingEndMode?.selectedVertex || incommingSelectedVertex;
    switch (selectedVertex.type) {
        case 'pivotingStart': {
            if (state.pivotingEndMode) {
                state.pivotingEndMode.selectedVertex = selectedVertex;
                state.system.lateral = structuredClone(state.pivotingEndMode.lateral);
                const originalLateral = state.pivotingEndMode.lateral;
                let dontHandleEndPoint = false;
                if (state.pivotingEndMode.mode === 'drag') {
                    // console.log("Drag")
                    if (!originalLateral.startPivot) {
                        // no end pivot
                        if (originalLateral.line.coordinates.length === 2) {
                            // easy case, can only move the point
                            state.system.lateral.line.coordinates[state.system.lateral.line.coordinates.length - 1] = movedPoint.geometry.coordinates;
                            dontHandleEndPoint = true;
                        }
                        else {
                            // this point can snap or move
                        }
                    }
                    else {
                        // has end pivot:
                        if (originalLateral.startPivot.retrace) {
                            // we want to move the end point
                            state.system.lateral.line.coordinates[0] = movedPoint.geometry.coordinates;
                            dontHandleEndPoint = true;
                        }
                        else if (!originalLateral.startPivot.lengthAlongCenterLineFt) {
                            // the point is not snapped
                            state.system.lateral.startPivot = undefined;
                        }
                        else {
                            // the point is snapped
                            // we will add this point
                            state.system.lateral.line.coordinates.push(movedPoint.geometry.coordinates);
                            state.system.lateral.startPivot = undefined;
                        }
                    }
                }
                else if (state.pivotingEndMode.mode === 'add') {
                    if (originalLateral.startPivot) {
                        if (originalLateral.startPivot.retrace) {
                            // we wont add anything here to allow the end to be moved
                            state.system.lateral.endPivot = undefined;
                        }
                        else {
                            // we will turn the snapped point into a point on the line
                            let runningDist = originalLateral.startPivot.lengthAlongCenterLineFt;
                            let i = state.system.lateral.line.coordinates.length - 1;
                            while ((i > 0) && turf.distance(originalLateral.line.coordinates[i], originalLateral.line.coordinates[i-1], { units: 'feet'}) < runningDist) {
                                state.system.lateral.line.coordinates.push(originalLateral.line.coordinates[i-1]);
                                runningDist -= turf.distance(originalLateral.line.coordinates[i], originalLateral.line.coordinates[i-1], { units: 'feet'});
                                i++;
                            }
                            if (runningDist && (i > 0)) {
                                const line = lineString([originalLateral.line.coordinates[i], originalLateral.line.coordinates[i-1]]);
                                const point = turf.along(line, runningDist, { units: 'feet' });
                                state.system.lateral.line.coordinates.push(point.geometry.coordinates);
                            }
                        }
                    }
                    // now we will add this point
                    state.system.lateral.line.coordinates.push(movedPoint.geometry.coordinates);
                    state.system.lateral.startPivot = undefined;
                }

                if (!dontHandleEndPoint) {
                    const selectedVertexSlice = state.system.lateral.line.coordinates.slice(0,-1).reverse();
                    let snapParameters: {
                        lengthPartLine: number;
                        location: number;
                    } | undefined = undefined;
                    if (selectedVertexSlice.length > 1) {
                        const directionalPartLine = lineString(selectedVertexSlice);
                        const npl = nearestPointOnLine(directionalPartLine, movedPoint, { units: 'feet' });
                        const location = npl.properties.location;
                        if (location > 0) {
                            let bNearFeedLine = npl.properties.dist < 50;
                            if (!bNearFeedLine) console.log(npl.properties.dist)
                            const selectedVertexTowersSlice = (selectedVertex.tower?.segments || []).filter(x => !x.pivotStart);
                            if (!bNearFeedLine && selectedVertexTowersSlice.length) {
                                const poly = getCombineLateralLinePolygons(
                                    selectedVertexTowersSlice, 
                                    selectedVertex.tower.insideRadius, 
                                    selectedVertex.tower.outsideRadius);
                                bNearFeedLine = turf.booleanPointInPolygon(movedPoint, poly);
                            }
                            if (bNearFeedLine) {
                                const lengthPartLine = length(directionalPartLine, { units: 'feet' });
                                snapParameters = {
                                    lengthPartLine,
                                    location
                                }
                            }
                        }
                    }
        
                    // are we close to the feed line?:
                    // are we inside the lateral?:
                    if (snapParameters) {
                        const endPivotEnd = state.system.lateral.endPivot?.lengthAlongCenterLineFt || 0;
                        if (snapParameters.lengthPartLine - endPivotEnd - snapParameters.location < 50) {
                            console.log("closing end of pivot");
                            state.system.lateral.startPivot = {
                                retrace: true
                            }
                        }
                        else {
                            console.log("snapping end pivot to feed line");
                            state.system.lateral.startPivot = {
                                lengthAlongCenterLineFt: snapParameters.location
                            }
                        }
                        state.system.lateral.line.coordinates.splice(-1, 1);
                    }
                    else {
                        // update the coordinate:
                        state.system.lateral.line.coordinates[state.system.lateral.line.coordinates.length - 1] = movedPoint.geometry.coordinates;
                    }
                }
                undo = () => {
                    state.system.lateral = structuredClone(state.pivotingEndMode.lateral);
                }
                try {
                    result = LateralGeometryHelper.createSingleGeoJSONFeature(
                        state.system
                    );
                }
                catch {
                    // invalid lateral
                }
            }
            break;
        }

        case 'pivotingEnd': {
            if (state.pivotingEndMode) {
                state.pivotingEndMode.selectedVertex = selectedVertex;
                state.system.lateral = structuredClone(state.pivotingEndMode.lateral);
                const originalLateral = state.pivotingEndMode.lateral;
                let dontHandleEndPoint = false;
                if (state.pivotingEndMode.mode === 'drag') {
                    // console.log("Drag")
                    if (!originalLateral.endPivot) {
                        // no end pivot
                        if (originalLateral.line.coordinates.length === 2) {
                            // easy case, can only move the point
                            state.system.lateral.line.coordinates[0] = movedPoint.geometry.coordinates;
                            dontHandleEndPoint = true;
                        }
                        else {
                            // this point can snap or move
                        }
                    }
                    else {
                        // has end pivot:
                        if (originalLateral.endPivot.retrace) {
                            // we want to move the start point
                            state.system.lateral.line.coordinates[state.system.lateral.line.coordinates.length - 1] = movedPoint.geometry.coordinates;
                            dontHandleEndPoint = true;
                        }
                        else if (!originalLateral.endPivot.lengthAlongCenterLineFt) {
                            // the point is not snapped
                            state.system.lateral.endPivot = undefined;
                        }
                        else {
                            // the point is snapped
                            // we will add this point
                            state.system.lateral.line.coordinates.unshift(movedPoint.geometry.coordinates);
                            state.system.lateral.endPivot = undefined;
                            if (state.system.lateral.anticlockwisePivotLineIndicies?.length) {
                                for (let index = 0; index < state.system.lateral.anticlockwisePivotLineIndicies.length; index++) {
                                    state.system.lateral.anticlockwisePivotLineIndicies[index]++;
                                }
                            }
                        }
                    }
                }
                else if (state.pivotingEndMode.mode === 'add') {
                    if (originalLateral.endPivot) {
                        if (originalLateral.endPivot.retrace) {
                            // we wont add anything here to allow the end to be moved
                            state.system.lateral.startPivot = undefined;
                        }
                        else {
                            // we will turn the snapped point into a point on the line
                            let runningDist = originalLateral.endPivot.lengthAlongCenterLineFt;
                            let i = 0;
                            while ((i < originalLateral.line.coordinates.length - 1) && turf.distance(originalLateral.line.coordinates[i], originalLateral.line.coordinates[i+1], { units: 'feet'}) < runningDist) {
                                state.system.lateral.line.coordinates.unshift(originalLateral.line.coordinates[i+1]);
                                if (state.system.lateral.anticlockwisePivotLineIndicies?.length) {
                                    for (let index = 0; index < state.system.lateral.anticlockwisePivotLineIndicies.length; index++) {
                                        state.system.lateral.anticlockwisePivotLineIndicies[index]++;
                                    }
                                }
                                runningDist -= turf.distance(originalLateral.line.coordinates[i], originalLateral.line.coordinates[i+1], { units: 'feet'});
                                i++;
                            }
                            if (runningDist && (i < originalLateral.line.coordinates.length - 1)) {
                                const line = lineString([originalLateral.line.coordinates[i], originalLateral.line.coordinates[i+1]]);
                                const point = turf.along(line, runningDist, { units: 'feet' });
                                state.system.lateral.line.coordinates.unshift(point.geometry.coordinates);
                                if (state.system.lateral.anticlockwisePivotLineIndicies?.length) {
                                    for (let index = 0; index < state.system.lateral.anticlockwisePivotLineIndicies.length; index++) {
                                        state.system.lateral.anticlockwisePivotLineIndicies[index]++;
                                    }
                                }
                            }
                        }
                    }
                    // now we will add this point
                    state.system.lateral.line.coordinates.unshift(movedPoint.geometry.coordinates);
                    if (state.system.lateral.anticlockwisePivotLineIndicies?.length) {
                        for (let index = 0; index < state.system.lateral.anticlockwisePivotLineIndicies.length; index++) {
                            state.system.lateral.anticlockwisePivotLineIndicies[index]++;
                        }
                    }
                    state.system.lateral.endPivot = undefined;
                }

                if (!dontHandleEndPoint) {
                    const selectedVertexSlice = state.system.lateral.line.coordinates.slice(1);
                    let snapParameters: {
                        lengthPartLine: number;
                        location: number;
                    } | undefined = undefined;
                    if (selectedVertexSlice.length > 1) {
                        const directionalPartLine = lineString(selectedVertexSlice);
                        const npl = nearestPointOnLine(directionalPartLine, movedPoint, { units: 'feet' });
                        const location = npl.properties.location;
                        if (location > 0) {
                            let bNearFeedLine = npl.properties.dist < 50;
                            if (!bNearFeedLine) console.log(npl.properties.dist)
                            const selectedVertexTowersSlice = (selectedVertex.tower?.segments || []).filter(x => !x.pivotEnd);
                            if (!bNearFeedLine && selectedVertexTowersSlice.length) {
                                const poly = getCombineLateralLinePolygons(
                                    selectedVertexTowersSlice, 
                                    selectedVertex.tower.insideRadius, 
                                    selectedVertex.tower.outsideRadius);
                                bNearFeedLine = turf.booleanPointInPolygon(movedPoint, poly);
                            }
                            if (bNearFeedLine) {
                                const lengthPartLine = length(directionalPartLine, { units: 'feet' });
                                snapParameters = {
                                    lengthPartLine,
                                    location
                                }
                            }
                        }
                    }
        
                    // are we close to the feed line?:
                    // are we inside the lateral?:
                    if (snapParameters) {
                        const startPivotEnd = state.system.lateral.startPivot?.lengthAlongCenterLineFt || 0;
                        if (snapParameters.lengthPartLine - startPivotEnd - snapParameters.location < 50) {
                            console.log("closing end of pivot");
                            state.system.lateral.endPivot = {
                                retrace: true
                            }
                        }
                        else {
                            console.log("snapping end pivot to feed line");
                            state.system.lateral.endPivot = {
                                lengthAlongCenterLineFt: snapParameters.location
                            }
                        }
                        state.system.lateral.line.coordinates.splice(0, 1);
                        if (state.system.lateral.anticlockwisePivotLineIndicies?.length) {
                            for (let index = 0; index < state.system.lateral.anticlockwisePivotLineIndicies.length; index++) {
                                state.system.lateral.anticlockwisePivotLineIndicies[index]--;
                            }
                        }
                    }
                    else {
                        // update the coordinate:
                        state.system.lateral.line.coordinates[0] = movedPoint.geometry.coordinates;
                    }
                }
                undo = () => {
                    state.system.lateral = structuredClone(state.pivotingEndMode.lateral);
                }
                try {
                    result = LateralGeometryHelper.createSingleGeoJSONFeature(
                        state.system
                    );
                }
                catch {
                    // invalid lateral
                }
            }
            break;
        }


        case 'feedLine': {
            const prevCoord = state.system.lateral!.line.coordinates[selectedVertex.vertexIndex];
            state.system.lateral!.line.coordinates[selectedVertex.vertexIndex] = movedPoint.geometry.coordinates;
            undo = () => {
                state.system.lateral!.line.coordinates[selectedVertex.vertexIndex] = prevCoord;
            }
            try {
                result = LateralGeometryHelper.createSingleGeoJSONFeature(
                    state.system
                );
            }
            catch {
                // invalid lateral
            }
            break;
        }
        case 'flexDropStart': {
            const line = lineString(selectedVertex.line.coordinates.slice());
            const nearest = nearestPointOnLine(line, movedPoint, { units: 'feet' });
            let dist = nearest.properties.location || 0;
            dist = Math.round(dist * 10) / 10;
            if (dist < 0) dist = 0;
            const prevdropSpanStartRelativeToPreviousSpanStart = (state.system as ISystemBase).Lateral.dropSpanStartRelativeToPreviousSpanStart;
            (state.system as ISystemBase).Lateral.dropSpanStartRelativeToPreviousSpanStart = dist;
            undo = () => {
                (state.system as ISystemBase).Lateral.dropSpanStartRelativeToPreviousSpanStart = prevdropSpanStartRelativeToPreviousSpanStart;
            }
            try {
                result = LateralGeometryHelper.createSingleGeoJSONFeature(
                    state.system
                );
            }
            catch {
                // invalid lateral
            }
            break;
        }
        case 'flexDropEnd': {
            const line = lineString(selectedVertex.line.coordinates.slice().reverse());
            const nearest = nearestPointOnLine(line, movedPoint, { units: 'feet' });
            let dist = nearest.properties.location || 0;
            dist = Math.round(dist * 10) / 10;
            if (dist < 0) dist = 0;
            const prevdropSpanEndRelativeToPreviousSpanEnd = (state.system as ISystemBase).Lateral.dropSpanEndRelativeToPreviousSpanEnd;
            (state.system as ISystemBase).Lateral.dropSpanEndRelativeToPreviousSpanEnd = dist;
            undo = () => {
                (state.system as ISystemBase).Lateral.dropSpanEndRelativeToPreviousSpanEnd = prevdropSpanEndRelativeToPreviousSpanEnd;
            }
            try {
                result = LateralGeometryHelper.createSingleGeoJSONFeature(
                    state.system
                );
            }
            catch {
                // invalid lateral
            }
            break;
        }
        case 'dropSpanStart': {       
            const line = selectedVertex.line;
            const nearest = nearestPointOnLine(line, movedPoint, { units: 'feet' });
            let dist = nearest.properties.location || 0;
            dist = Math.round(dist * 10) / 10;
            if (dist < 0) dist = 0;
            const spans = selectedVertex.side === 'FlangedSide'
                ? (state.system as ISystemBase).FlangedSide.Span
                : (state.system as ISystemBase).FlexSide.Span;
            const prevdropSpanStartRelativeToPreviousSpanStart = spans[selectedVertex.spanIndex].dropSpanStartRelativeToPreviousSpanStart;
            spans[selectedVertex.spanIndex].dropSpanStartRelativeToPreviousSpanStart = dist;
            undo = () => {
                spans[selectedVertex.spanIndex].dropSpanStartRelativeToPreviousSpanStart = prevdropSpanStartRelativeToPreviousSpanStart;
            }
            try {
                result = LateralGeometryHelper.createSingleGeoJSONFeature(
                    state.system
                );
            }
            catch {
                // invalid lateral
            }
            break;
        }
        case 'dropSpanEnd': {     
            const line = lineString(selectedVertex.line.coordinates.slice().reverse());
            const nearest = nearestPointOnLine(line, movedPoint, { units: 'feet' });
            let dist = nearest.properties.location || 0;
            dist = Math.round(dist * 10) / 10;
            if (dist < 0) dist = 0;
            const spans = selectedVertex.side === 'FlangedSide'
                ? (state.system as ISystemBase).FlangedSide.Span
                : (state.system as ISystemBase).FlexSide.Span;
            const prevdropSpanEndRelativeToPreviousSpanEnd = spans[selectedVertex.spanIndex].dropSpanEndRelativeToPreviousSpanEnd;
            spans[selectedVertex.spanIndex].dropSpanEndRelativeToPreviousSpanEnd = dist;
            undo = () => {
                spans[selectedVertex.spanIndex].dropSpanEndRelativeToPreviousSpanEnd = prevdropSpanEndRelativeToPreviousSpanEnd;
            }
            try {
                result = LateralGeometryHelper.createSingleGeoJSONFeature(
                    state.system
                );
            }
            catch {
                // invalid lateral
            }
            break;
        }
        default:
            break;
    }

    if (result && result.geometry.type === 'LineString' && feature.type === 'Polygon') {
        // a polygon that becomes a linestring must be invalid:
        result = undefined;
    }

    const wtf = state.wheelTrackFeatureId ? that.getFeature(state.wheelTrackFeatureId) : undefined;
    if (wtf) {
        if (result && result.wheelTracks) {
            const wt = multiLineString(result.wheelTracks.map(x => x.coordinates));
            wtf.incomingCoords(wt.geometry.coordinates);
        }
    }
    if (result && result.geometry.type === 'Polygon') {
        const { verticies } = result;
        state.verticies = verticies;
        feature.incomingCoords(result.geometry.coordinates)
        state.fire_lateral_updated = true;
        if (result.geometry.type === 'Polygon') {
            const validity = getSystemValidity(
                state.layoutId,
                state.systemId,
            {
                ...state.systemValidityArgs,
                systemAreaPolygon: result.geometry,
                wheelTracks: result.wheelTracks,
                feedLine: state.system.lateral!.line
            });
            feature.setProperty("validity", validity);
            feature.setProperty("dragId", state.dragId);
        }
        else {
            feature.setProperty("validity", 'critical');
        }
    }
    else if (result && result.geometry.type === 'LineString') {
        const { verticies } = result;
        state.verticies = verticies;
        feature.incomingCoords(result.geometry.coordinates)
        state.fire_lateral_updated = true;
        const validity = getSystemValidity(
            state.layoutId,
            state.systemId,{
            ...state.systemValidityArgs,
            wheelTracks: result.wheelTracks,
            feedLine: state.system.lateral!.line
        });
        feature.setProperty("validity", validity);
        feature.setProperty("dragId", state.dragId);
    }
    else {
        undo();
        feature.changed();
    }

}

LateralSelectMode.onTouchEnd = LateralSelectMode.onMouseUp = function(this, state: IState) {
    state.dragId = undefined;
    if (state.fire_lateral_updated) {
        this.map.fire("draw.update_ext", {
            action: "lateral_updated",
            definition: state.definition,
            updatedSystem: state.system
        } as IDrawUpdateExtEvent_LateralUpdated);
        state.fire_lateral_updated = false;
    }
    if (state.dragMoving) {
      this.fireUpdate();
    }
    this.stopDragging(state);
};
  
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);
const isVertex = (e: MapboxDraw.MapMouseEvent) => isOfMetaType(Constants.meta.VERTEX)(e) && !e.featureTarget.properties.delete;


LateralSelectMode.onTouchStart = LateralSelectMode.onMouseDown = function(this, state: IState, e) {
    state.dragId = uuidV4();
    // adding alt key to allow add pivot works,
    // should update verticy colors when in alt mode
    state.pivotEndMode = e.originalEvent.altKey ? "add" : "drag";
    state.addedSegment = e.originalEvent.altKey ? false : true;
    console.log("addedSegment", state.addedSegment)
    console.log("pivotEndMode", state.pivotEndMode)
    if (state.definition) {
        if (e.originalEvent.altKey) {
            state.pivotingEndMode = {
                mode: "add",
                lateral: structuredClone(state.system.lateral)
            }
        }
        else {
            state.pivotingEndMode = {
                mode: "drag",
                lateral: structuredClone(state.system.lateral)
            }
        }
    }
    if (isMidpoint(e)) {
        this.startDragging(state, e);
        const about = e.featureTarget.properties;

        const selectedSegmentIndexString = about.coord_path.split(".")[1];
        if (!selectedSegmentIndexString) return;
        const selectedSegmentIndex = parseInt(selectedSegmentIndexString);
        const movedPoint = point([ e.lngLat.lng, e.lngLat.lat ]);
        handlePoint(this, state, movedPoint, { type: 'midpoint', selectedSegmentIndex });
        this.fireUpdate();
        state.selectedCoordPaths = [`0.${selectedSegmentIndex + 1}`];        
    }
    else if (isVertex(e)) {
        return this.onVertex(state, e);
    }
};