import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import { isCenterPivot } from "rdptypes/helpers/system";
import { ElectricalFrequencies, HoseFeedTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { FieldSets, IsDualSided, IsMaxigator } from "roedata/roe_migration/SystemFunctions";
import { getCenterDriveType, getOverallTireSize, getPercentOfCircleCoverage, getPivotType, getSystemElectricalFrequencyHertz, getSystemLengthFeet, getSystemModel } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import { documentStyles } from "../styles";
import FixedWidthTable, { ICell, IRow } from "./table/FixedWidthTable";

interface Props {
    quote: QuoteClass;
};

const PurchaseOrderSystemInformationTable: FC<Props> = (props) => {
    const settings = React.useContext(DevSettingsCtx);
    let {quote} = props;
    let rows: IRow[] = [];

    let model = getSystemModel(quote, settings);
    if (IsMaxigator(quote.System) && FieldSets(quote.System).HoseFeed.DataValid() && quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral){
        model += " PVT / LAT";
    }

    let ns = "common-phrases."

    let cells: ICell[] = [{
        text: t(ns + "sltSystemInformation"),
        widthPc: 100/3,
        header: true
    }, {
        text: t(ns + "sltModel") + ": " + model,
        widthPc: (100/3) * 2,
        header: true
    }];

    rows.push({cells});

    let towerCount = quote.System?.FlangedSide?.Tower?.length ?? 0;
    if (IsDualSided(quote.System)){
        towerCount += quote.System?.FlexSide?.Tower?.length ?? 0;
    }

    let towersType = "N/A";
    if (quote.RightTowersClass.DataValid()){
        towersType = quote.RightTowersClass.TowersType();
    }

    cells = [{
        text: t(ns + "sltTowerCount") + ": " + (quote.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro ? "N/A" : towerCount),
        widthPc: 100/3
    }, {
        text: t(ns + "sltPivot") + ": " + getPivotType(quote),
        widthPc: 100/3
    }, {
        text: t(ns + "sltTowers") + ": " + towersType,
        widthPc: 100/3
    }];

    rows.push({cells});

    let systemLength = new DisplayLengthUnitBuilder(getSystemLengthFeet(quote), 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendSymbol()
        .toString();

    cells = [{
        text: t(ns + "sltSystemLength") + ": " + systemLength,
        widthPc: 100/3
    }];

    let fs = FieldSets(quote.System);
    if (fs.SystemType.DataValid()){
        if (isCenterPivot(quote.System) && quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && (fs.CenterPivot.DataValid() || fs.KwikTow.DataValid() || fs.SACRetro.DataValid())) {
            let pc = getPercentOfCircleCoverage(quote);
            if (pc){
                cells.push({
                    text: t(ns + "sltPercentOfCircle") + ": " + pc.toFixed(0) + "%",
                    widthPc: (100/3) * 2,
                });
            }
        }
        else if (!isCenterPivot(quote.System) && quote.System.SystemProperties.SystemType !== SystemTypes.Ancillary && (fs.CanalFeed.DataValid() || fs.HoseFeed.DataValid()) && 
        fs.Guidance.DataValid() && quote.System.Lateral.LengthOfField){
            let lengthOfField = new DisplayLengthUnitBuilder(quote.System.Lateral.LengthOfField, 'feet')
                .convert(settings.dealerSettings.display.current.lengths)
                .appendValue(0)
                .appendString(" ")
                .appendSymbol()
                .toString();

            cells.push({
                text: t(ns + "sltLengthOfRun2") + ": " + lengthOfField,
                widthPc: (100/3) * 2,
            })
        }
    }

    rows.push({cells});
    let electricalFrequency = "N/A";
    if (quote.System.SystemProperties.SystemType !== SystemTypes.Ancillary && quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro){
        let f = getSystemElectricalFrequencyHertz(quote);
        if (f === ElectricalFrequencies.a50){
            electricalFrequency = "50 Hz";
        }
        else if (f === ElectricalFrequencies.a60) {
            electricalFrequency = "60 Hz";
        }
    }

    cells = [{
        text: t(ns + "sltTireSize") + ": " + getOverallTireSize(quote),
        widthPc: 100/3
    }, {
        text: t(ns + "sltElectricalFrequency") + ": " + electricalFrequency,
        widthPc: 100/3
    }, {
        text: t(ns + "sltCenterDrive") + ": " + getCenterDriveType(quote),
        widthPc: 100/3
    }];

    rows.push({cells});

    let ns2 = "common-phrases.";
    return (<View style={documentStyles.section}>
        <View style={documentStyles.heading}>
            <Text style={documentStyles.sectionHeader}>{t(ns2 + "sltSystemInformation")}</Text>
        </View>
        <View style={{paddingLeft: 15, paddingRight: 15}}>
            <FixedWidthTable
                data={{rows}}
            />
        </View>
    </View>
    );
};

export default PurchaseOrderSystemInformationTable;