import { Checkbox, FormControlLabel, TableCell, TableRow, TextField } from "@mui/material";
import i18next from "i18next";
import { IFEXRate } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useState } from "react";
import { getCurrencySymbol } from "../../../../docGeneration/DocumentGenerationHelpers";
import DollarFormattedInput from "../DollarFormattedInput";

interface Props {
    savePrice?: (priceCents: number) => void;
    saveIsTaxable?: (isTaxable: boolean) => void;
    priceCents: number | undefined;
    isTaxable?: boolean | undefined;
    canBeTaxable: boolean;
    label: string;
    priceReadonly?: boolean;
    taxableReadonly?: boolean;

    localCurrency?: string;
    rate?: IFEXRate;
    valueCurrency: string;
};

export const createUsdCell = (
    priceReadonly: boolean, valueCurrency: string, valueMajorUnits: number,
    savePriceState: (priceCents: number) => void, onBlurCb: () => void,
    rate?: number, colSpan?: number
) => {
    const cellReadOnly = priceReadonly || valueCurrency !== "USD";
    const cellValue = valueCurrency === "USD"
        ? valueMajorUnits
        : rate
            ? valueMajorUnits / rate
            : NaN;
    const cellOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (cellReadOnly) return;
        if (e.target.value === ""){
            savePriceState(undefined);
        }
        let val = parseFloat(e.target.value);
        if (isNaN(val)) return;
        savePriceState(val);
    }
    const cellOnBlur = () => {
        if (cellReadOnly) return;
        onBlurCb();
    }
    return (
        <TableCell sx={{textAlign: "center", padding: "3px 16px"}} colSpan={colSpan}>
            <TextField 
                size="small"
                value={cellValue} 
                disabled={cellReadOnly} 
                InputProps={{
                    inputComponent: DollarFormattedInput as any
                }} 
                onChange={cellOnChange} 
                onBlur={cellOnBlur}
            />
        </TableCell>
    )
}
export const createLocalCell = (
    priceReadonly: boolean, valueCurrency: string, localCurrency: string, valueMajorUnits: number,
    savePriceState: (priceCents: number) => void, onBlurCb: () => void,
    rate?: number, colSpan?: number
) => {
    const cellReadOnly = priceReadonly || valueCurrency === "USD";
    const cellValue = valueCurrency !== "USD"
        ? valueMajorUnits
        : rate
            ? valueMajorUnits * rate
            : NaN;
    const cellOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (cellReadOnly) return;
        if (e.target.value === ""){
            savePriceState(undefined);
        }
        let val = parseFloat(e.target.value);
        if (isNaN(val)) return;
        savePriceState(val);
    }
    const cellOnBlur = () => {
        if (cellReadOnly) return;
        onBlurCb();
    }
    return (
        <TableCell sx={{textAlign: "center", padding: "3px 16px"}} colSpan={colSpan}>
            <TextField 
                size="small"
                value={cellValue} 
                disabled={cellReadOnly} 
                InputProps={{
                    inputComponent: DollarFormattedInput as any,
                    inputProps: { prefix: getCurrencySymbol(localCurrency)}
                }} 
                onChange={cellOnChange} 
                onBlur={cellOnBlur}
            />
        </TableCell>
    )
}

const PricingTableRow: FC<Props> = (props) => {
    const {isTaxable, priceCents, saveIsTaxable, savePrice, label, priceReadonly, canBeTaxable, taxableReadonly} = props;
    const [priceUSD, setPriceUSD] = useState<number| undefined>(priceCents ? priceCents/100 : 0);
    React.useEffect(() => {
        setPriceUSD(priceCents ? priceCents/100 : 0);
    }, [ priceCents ])

    const localCurrency = props.localCurrency ?? "USD";
    const displayExtraColumn = localCurrency !== "USD";

    const onBlurCb = () => {
        if (!priceReadonly && savePrice) {
            const price = isNaN(priceUSD) ? 0 : priceUSD;
            savePrice(price * 100);
            setPriceUSD(price);
        }
    }

    return (
        <>
            <TableRow>
                <TableCell sx={{textAlign: "right", padding: "3px 16px"}}>{label}</TableCell>
                {createUsdCell(priceReadonly || !savePrice, props.valueCurrency, priceUSD, setPriceUSD, onBlurCb, props.rate?.exchangeRate_1USDToSymbol)}
                {
                    displayExtraColumn && (
                        createLocalCell(priceReadonly || !savePrice, props.valueCurrency, props.localCurrency, priceUSD, setPriceUSD, onBlurCb, props.rate?.exchangeRate_1USDToSymbol)
                    )
                }
                <TableCell sx={{textAlign: "left", padding: "3px 16px"}}>
                    {canBeTaxable && <FormControlLabel label={i18next.format(i18next.t("tax"), 'capitalize')} control={<Checkbox disabled={taxableReadonly} checked={isTaxable} onChange={(e) => {
                        if (!taxableReadonly){
                            let checked = e.target.checked;
                            saveIsTaxable(checked);
                        }
                    }}/>}/>}
                </TableCell>
            </TableRow>
        </>
    );
};

export default PricingTableRow;