import { IPricing } from "rdptypes/project/ISystemBase.AutoGenerated";
import { createNewMultiAction } from "../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import IAuthState from "../../auth/IAuthState";
import IDbProject from "../../db/IDbProject";

export enum CustomerPriceAmmendmentType {
    listPriceDiscountPercent = "listPrice",
    markupOverDealerCostPercent = "markupPercentOverDealerCostPc",
    markupOverDealerCostDollar = "markupPercentOverDealerCostDollar",
    quotedPriceDollar = "quotedPriceDollar"
}

export interface IPricingHelperPriceComputation {
    customerPriceAmmendmentType: CustomerPriceAmmendmentType;
    value: number;
    resetCpat: (dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => void;
    setCpat: (value: number, cpat: CustomerPriceAmmendmentType, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => void;
    currency: string;
}

export const getCpatFromPricing = (
    listPriceDiscountPercent: number, markupPercentOverDealerCost: number, 
    quotedPriceDollar: number | undefined, markupDollarOverDealerCost: number
): { cpat: CustomerPriceAmmendmentType, value: number } => {
    let cpat: CustomerPriceAmmendmentType = CustomerPriceAmmendmentType.listPriceDiscountPercent;
    let num_ = listPriceDiscountPercent;
    if (markupPercentOverDealerCost > 0) {
        cpat = CustomerPriceAmmendmentType.markupOverDealerCostPercent;
        num_ = markupPercentOverDealerCost
    }
    else if (markupDollarOverDealerCost > 0) {
        cpat = CustomerPriceAmmendmentType.markupOverDealerCostDollar; 
        num_ = markupDollarOverDealerCost
    }
    else if (typeof quotedPriceDollar !== "undefined" && quotedPriceDollar > 0) {
        cpat = CustomerPriceAmmendmentType.quotedPriceDollar; 
        num_ = quotedPriceDollar
    }
    return {
        cpat, value: num_
    }
}

export const calculatePriceComputation = (
    pricing: IPricing, currency: string
): IPricingHelperPriceComputation => {
    
    const { listPriceDiscountPercent, markupPercentOverDealerCost, quotedPriceDollar, markupDollarOverDealerCost } = pricing;

    const cpatFromPricing = getCpatFromPricing(
        listPriceDiscountPercent, markupPercentOverDealerCost,
        quotedPriceDollar, markupDollarOverDealerCost
    )
    const result: IPricingHelperPriceComputation = {
        customerPriceAmmendmentType: cpatFromPricing.cpat,
        value: cpatFromPricing.value,
        currency,
        resetCpat(dbPrj, layoutId, systemId, authState) {
            dbPrj.pushAction(
                createNewMultiAction([
                    createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "QuoteProperties.ProposalInformation.pricing.listPriceDiscountPercent",
                    0,
                    authState),
                    createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "QuoteProperties.ProposalInformation.pricing.markupPercentOverDealerCost",
                    0,
                    authState),
                    createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "QuoteProperties.ProposalInformation.pricing.markupDollarOverDealerCost",
                    0,
                    authState),
                    createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "QuoteProperties.ProposalInformation.pricing.quotedPriceDollar",
                    0,
                    authState)],
                authState)
            );
        },
        setCpat(num, customerPriceAmmendmentType, dbPrj, layoutId, systemId, authState) {

            let lpdpc = 0;
            let modcp = 0;
            let modcd = 0;
            let qpd = 0;

            if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.listPriceDiscountPercent) {
                lpdpc = num;
            }
            else if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.markupOverDealerCostPercent) {
                modcp = num;
            }
            else if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.markupOverDealerCostDollar) {
                modcd = num;
            }
            else if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.quotedPriceDollar){
                qpd = num;
            }

            dbPrj.pushAction(
                createNewMultiAction([
                    createNewUpdateSystemPropertyAction(
                        layoutId,
                        systemId,
                        "QuoteProperties.ProposalInformation.pricing.listPriceDiscountPercent",
                        lpdpc,
                        authState),
                    createNewUpdateSystemPropertyAction(
                        layoutId,
                        systemId,
                        "QuoteProperties.ProposalInformation.pricing.markupPercentOverDealerCost",
                        modcp,
                        authState),
                    createNewUpdateSystemPropertyAction(
                        layoutId,
                        systemId,
                        "QuoteProperties.ProposalInformation.pricing.markupDollarOverDealerCost",
                        modcd,
                        authState),
                    createNewUpdateSystemPropertyAction(
                        layoutId,
                        systemId,
                        "QuoteProperties.ProposalInformation.pricing.quotedPriceDollar",
                        qpd,
                        authState)
                    ],
                    authState)
            );
        },
    }

    return result;
}
