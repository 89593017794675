import AddCircle from "@mui/icons-material/AddCircle";
import ArrowDownIcon from '@mui/icons-material/ArrowCircleDownRounded';
import ArrowUpIcon from '@mui/icons-material/ArrowCircleUpRounded';
import DeleteCircle from "@mui/icons-material/RemoveCircle";
import { Alert, Checkbox, IconButton, Stack } from "@mui/material";
import { t } from "i18next";
import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { getSpansWithoutEndOfSystem } from "rdptypes/helpers/Spans";
import { getAftSide, getFwdSide } from "rdptypes/helpers/system";
import ISystem from "rdptypes/project/ISystem";
import { SystemTypes, WaterFeedTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { ISpan, ITower } from "rdptypes/project/Types";
import { spanLengthsFeet } from 'rdptypes/reinkeComponents';
import IBoolean from "rdptypes/roe/IBoolean";
import IComponent from "rdptypes/roe/IComponent";
import ISideTable, { IColumn } from "rdptypes/roe/ISideTable";
import * as React from "react";
import { FC, useContext, useState } from "react";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import * as systemf from "roedata/roe_migration/SystemFunctions";
import { createAddSpanTowerAction } from "../../../../actions/AddSpan";
import { createDeleteSpanTowerActionV2 } from "../../../../actions/DeleteSpanV2";
import { createMoveCartAction } from "../../../../actions/MoveCart";
import { createSwapSpanTowerAction } from "../../../../actions/SwapSpan";
import AuthCtx from "../../../../auth/AuthCtx";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import IDbProject from "../../../../db/IDbProject";
import { DisplayLengthUnitBuilder } from "../../../../helpers/lengths";
import { translate } from "../../../../helpers/translation";
import { IDisplaySettingsFormState } from "../../../DealerSettingsDialog/TabDisplaySettings";
import { getComponentRenderer } from "../componentRendererFactory";
import IComponentRenderContext from "./../IComponentRenderContext";

export interface IDataRow {
    Span: ISpan;
    Tower: ITower;
    spanToCart: number; 
    rowType: 'rigid' | 'flex', 
    rowId: string,
    spanTowerIndex: number;
    sideEnum: SideEnum;
    distanceFromStart: number;
    
}
export interface ICartRow { 
    rowType: 'cart', 
    rowId: string,
    aftSideSpanCount: number,
    waterFeed?: WaterFeedTypes,
    dropFlexSide?: boolean,
    clockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
    anticlockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
};
export type IRow = IDataRow | ICartRow;

export const getRows = (system: ISystem): IRow[] => {
    const rows: IRow[] = [];

    const fwdSideEnum = getFwdSide(system);

    let aftRows: IDataRow[] = [];
    let fwdRows: IDataRow[] = [];    
    {
        // Flanged side:
        const towers = getSide(system, SideEnum.Flanged).Tower;
        const spans = getSpansWithoutEndOfSystem(system, SideEnum.Flanged); 
        const side = fwdSideEnum === SideEnum.Flanged ? "fwd" : "aft";  
        const rows: IDataRow[] = spans.map((x, idx): IDataRow => {
            return {
                Span: structuredClone(x),
                Tower: structuredClone(towers[idx]),
                spanToCart: idx + 1,
                rowType:  'rigid',
                rowId: `${side}-${idx}`,
                spanTowerIndex: idx,
                sideEnum: SideEnum.Flanged,
                distanceFromStart: spanf.EndingRadius(system, getSide(system, SideEnum.Flanged), x)
            }
        });
        if (side === 'aft') {
            aftRows = rows;
        }
        else {
            fwdRows = rows;
        }
    }
    
    // only lateral center feed systems have a flex side and cart:
    if (systemf.IsCenterFeed(system)) {
        const towers = getSide(system, SideEnum.Flex).Tower;
        const spans = getSpansWithoutEndOfSystem(system, SideEnum.Flex); 
        const side = fwdSideEnum === SideEnum.Flex ? "fwd" : "aft";        
        const rows: IDataRow[] = spans.map((x, idx) => ({ 
            Span: structuredClone(x),
            Tower: structuredClone(towers[idx]),
            spanToCart: idx + 1,
            rowType:  'flex',
            rowId: `${side}-${idx}`,
            spanTowerIndex: idx,
            sideEnum: SideEnum.Flex,
            distanceFromStart: spanf.EndingRadius(system, getSide(system, SideEnum.Flex), x)
        }));
        if (side === 'aft') {
            aftRows = rows;
        }
        else {
            fwdRows = rows;
        }
    }

    rows.push(...aftRows.reverse());
    if (systemf.IsCenterFeed(system)) {
        const cartRow: ICartRow = { 
            rowType: 'cart', 
            rowId: 'cart',
            aftSideSpanCount: aftRows.length,
            waterFeed: system.Lateral.WaterFeed,
            dropFlexSide: system.Lateral.DropFlexSide
        };
        rows.push(cartRow);
    }
    rows.push(...fwdRows);
    return rows;
}

interface Props {
    cmp: ISideTable;
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    ctx: IComponentRenderContext;
    mapDesignMode?: boolean;
};

const SideTableRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const dealerSettings = useContext(DevSettingsCtx).dealerSettings;

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const rows = React.useMemo(() => getRows(system), [ JSON.stringify(system)]);

    const [ selectedRowIds, setSelectedRowIds ] = useState<string[]>([]);
    const dataRows = rows.filter(row => row.rowType !== "cart") as IDataRow[];
    const selectedDataRows: IDataRow[] = selectedRowIds
        .map(rowId => rows.find(x => x.rowId === rowId))
        .filter(row => row !== undefined && row.rowType !== "cart") as IDataRow[];
    const selectedRowId = selectedRowIds.length === 1 ? selectedRowIds[0] : undefined;
    const selectedRow = selectedRowId !== undefined ? rows.find(x => x.rowId === selectedRowId) : undefined;

    const someRowsSelected = !!selectedRowIds.length;
    const allRowsSelected = selectedDataRows.length === dataRows.length;
    
    const addSpanTower = () => {
        let spanDefaults: ISpan | undefined = undefined;
        let towerDefaults: ITower | undefined = undefined;
        const lastSelectedSpan = selectedDataRows.length ? selectedDataRows[selectedDataRows.length - 1] : undefined;
        if (lastSelectedSpan) {
            spanDefaults = lastSelectedSpan.Span
            towerDefaults = lastSelectedSpan.Tower;
        }
        else {
            if (dealerSettings.system.useCustom) {
                spanDefaults = dealerSettings.system.custom.getSpanProperties(systemf.IsEDMP(system));
                towerDefaults = dealerSettings.system.custom.getTowerProperties(systemf.IsEDMP(system));
            }
        }
        if (props.mapDesignMode) {
            if (!spanDefaults) {
                spanDefaults = {};
            }
            if (!spanDefaults.Length) {
                spanDefaults.Length = spanLengthsFeet[Math.floor(spanLengthsFeet.length / 2)];
                spanDefaults.Extension = false;
            }
        } 
        props.ctx.pushActionAndImproveScores(createAddSpanTowerAction(props.layoutId, props.systemId, SideEnum.Flanged, {
            spanDefaults,
            towerDefaults
        }, authState), [], true);
    };

    const deleteSpanAndTower = () => {
        const flangedSide = selectedDataRows.filter(x => x.sideEnum === SideEnum.Flanged).map(x => x.Span.SpanNumber);
        const flexSide = selectedDataRows.filter(x => x.sideEnum === SideEnum.Flex).map(x => x.Span.SpanNumber);
        props.ctx.pushActionAndImproveScores(
            createDeleteSpanTowerActionV2(
                props.layoutId,
                props.systemId,
                {
                    flangedSideSpanNumbers: flangedSide,
                    flexSideSpanNumbers: flexSide
                },
                authState
            ), [], true
        );
        setSelectedRowIds([]);
    };

    const moveCart = (direction: "fwd" | "aft") => {     
        props.ctx.pushActionAndImproveScores(
            createMoveCartAction(
                props.layoutId,
                props.systemId,
                direction === 'fwd' ? getFwdSide(system) : getAftSide(system),
                authState
            ), [], true
        )
    }
    const moveCartFwd = () => moveCart("fwd");
    const moveCartAft = () => moveCart("aft");
    
    const moveSpanAndTowerFwd = () => { 
        if (!selectedRow || selectedRow.rowType === 'cart') return;

        const idx = rows.findIndex(x => x.rowId === selectedRowId);
        const nextFwdSpan = rows[idx + 1];
        if (!nextFwdSpan) return;
        if (nextFwdSpan.rowType === 'cart' || nextFwdSpan.sideEnum !== selectedRow.sideEnum) {
            moveCartAft();
            setSelectedRowIds([ "fwd-0" ]);
        }
        else {
            props.ctx.pushActionAndImproveScores(
                createSwapSpanTowerAction(
                    props.layoutId,
                    props.systemId,
                    selectedRow.sideEnum,
                    selectedRow.Span.SpanNumber,
                    nextFwdSpan.Span.SpanNumber,
                    authState
                ), [], true
            )
            setSelectedRowIds([ nextFwdSpan.rowId ]);
        }
    }
    const moveSpanAndTowerAft = () => { 
        if (!selectedRow || selectedRow.rowType === 'cart') return;

        const idx = rows.findIndex(x => x.rowId === selectedRowId);
        const nextAftSpan = rows[idx - 1];
        if (!nextAftSpan) return;
        if (nextAftSpan.rowType === 'cart' || nextAftSpan.sideEnum !== selectedRow.sideEnum) {
            moveCartFwd();
            setSelectedRowIds([ "aft-0" ]);
        }
        else {
            props.ctx.pushActionAndImproveScores(
                createSwapSpanTowerAction(
                    props.layoutId,
                    props.systemId,
                    selectedRow.sideEnum,
                    selectedRow.Span.SpanNumber,
                    nextAftSpan.Span.SpanNumber,
                    authState
                ), [], true
            )
            setSelectedRowIds([ nextAftSpan.rowId ]);
        }
    }
    const handleMoveRowFwd = () => {
        if (!selectedRow) return;
        if (selectedRow.rowType === 'cart') {
            moveCartFwd();
        }
        else {
            moveSpanAndTowerFwd();
        }
    }
    const handleMoveRowAft = () => {
        if (!selectedRow) return;
        if (selectedRow.rowType === 'cart') {
            moveCartAft();
        }
        else {
            moveSpanAndTowerAft();
        }
    }

    const canMoveRowFwd = (() => {
        if (!selectedRow) return false;

        // if there is only one span, we cannot move:
        if (rows.filter(x => x.rowType !== 'cart').length === 1) return false;

        // if selected row index is the last row, we can never move fwd:
        const idx = rows.findIndex(x => x.rowId === selectedRowId);
        if (idx === rows.length - 1) return false;

        return true;
    })();

    const canMoveRowAft = (() => {
        if (!selectedRow) return false;

        // if there is only one span, we cannot move:
        if (rows.filter(x => x.rowType !== 'cart').length === 1) return false;

        // if selected row index is the first row, we can never move aft:
        const idx = rows.findIndex(x => x.rowId === selectedRowId);
        if (idx === 0) return false;

        return true;
    })();
    
    let showButtons = !props.ctx.editingDealerDefaults && (props.cmp.showButtons || false);
    let flangedSpansCount = getSpansWithoutEndOfSystem(system, SideEnum.Flanged).length;
    let flexSpansCount = getSpansWithoutEndOfSystem(system, SideEnum.Flex).length;
    const disableAddTowerForEDMP = systemf.IsEDMP(system) && flangedSpansCount !== 0;

    return (
        <>
            {showButtons && <Stack style={{marginBottom: 10}} direction="row">
                <IconButton
                    disabled={disableAddTowerForEDMP}
                    onClick={addSpanTower}
                    >
                    <AddCircle />
                </IconButton>
                <IconButton
                    disabled={!selectedRowIds.length}
                    onClick={deleteSpanAndTower}
                    >
                    <DeleteCircle />
                </IconButton>
                <IconButton
                    disabled={!canMoveRowFwd}
                    onClick={handleMoveRowFwd}
                ><ArrowDownIcon/></IconButton>
                <IconButton
                    disabled={!canMoveRowAft}
                    onClick={handleMoveRowAft}
                ><ArrowUpIcon/></IconButton>
            </Stack>}
            {
                ((flangedSpansCount + flexSpansCount) === 0 && showButtons) && <p style={{marginLeft: "10px", color: "#1976d2"}}>{t("roe.spans-and-towers.add-span")}</p>
            }
            {
                ((flangedSpansCount + flexSpansCount) !== 0 && showButtons && systemf.IsCenterFeed(system) && flexSpansCount === 0) && <Alert severity="warning" style={{marginBottom: "5px"}}>{t("roe.spans-and-towers.no-flex-spans")}</Alert>
            }
            {
                ((flangedSpansCount + flexSpansCount) !== 0 && showButtons && systemf.IsCenterFeed(system) && flangedSpansCount === 0) && <Alert severity="warning" style={{marginBottom: "5px"}}>{t("roe.spans-and-towers.no-flanged-spans")}</Alert>
            }
            {
                ((flangedSpansCount + flexSpansCount) === 0 && !showButtons) && <p style={{marginLeft: "10px", color: "#1976d2", marginTop: 0}}>{t("roe.spans-and-towers.towers-add-span")}</p>
            }
            {
                showButtons && disableAddTowerForEDMP && <Alert severity="info" style={{marginBottom: "5px"}}>{t("roe.spans-and-towers.max-one-span")}</Alert>
            }
            {
                (flangedSpansCount + flexSpansCount) !== 0 && <table style={{fontSize: props.mapDesignMode ? 12 : 'unset'}}>
                    <thead style={{fontSize: props.mapDesignMode ? 12 : 14, whiteSpace: 'nowrap'}}>
                        <tr>
                            {
                                !props.ctx.editingDealerDefaults && (
                                    <>
                                        <th><Checkbox
                                            style={{padding: props.mapDesignMode ? 0 : 'unset'}}
                                            checked={someRowsSelected}
                                            indeterminate={someRowsSelected && !allRowsSelected}
                                            onChange={e => {
                                                if (allRowsSelected) {
                                                    setSelectedRowIds([]);
                                                } else {
                                                    setSelectedRowIds(rows.filter(x => x.rowType !== "cart").map((row) => row.rowId));
                                                }
                                            }}
                                        /></th>
                                        { (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator
                                            || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) && <th></th> }
                                        <th>#</th>
                                    </>
                                )
                            }
                            { props.cmp.columns
                                .filter(col => !(props.ctx.editingDealerDefaults && col.disableDealerSettings) && (!col.visible || col.visible(system)))
                                .map(col => <th>{translate(col)}</th>) }
                            {
                                !props.ctx.editingDealerDefaults && <th style={{ textAlign: "right" }}>Distance</th>
                            }
                            
                        </tr>
                    </thead>
                    <tbody>
                        { rows.map((row, irow) => row.rowType === "cart" ?
                            <CartRow {...props} row={row} system={system} /> :
                            <DataRow {...props} system={system} row={row}
                                selectedDataRows={selectedDataRows}
                                onSelectedChanged={selected => {
                                    const newSelectedRows = selectedRowIds.filter(x => x !== row.rowId);
                                    if (selected) {
                                        newSelectedRows.push(row.rowId);
                                    }
                                    setSelectedRowIds(newSelectedRows);
                                }}
                                displaySettings={dealerSettings.display.current}
                            />)}
                    </tbody>
                </table>
            }
        </>
    );
};

const cartCols: { disconnect: IBoolean } = {
    disconnect: {
        type: "boolean",
        fieldPath: "Lateral.DropFlexSide",
        title: "",
        hook: (value: boolean) => {
            if (value) {
                return [
                    { fieldPath: "Lateral.dropSpanStartRelativeToPreviousSpanStart", value: 0 },
                    { fieldPath: "Lateral.dropSpanEndRelativeToPreviousSpanEnd", value: 0 }
                ]
            }
        }
    }
};
export const CartRow: FC<{row: ICartRow, system: ISystem} & Props> = (props) => {
    props.cmp.cartDisconnectColumnIndex
    return (
        <tr>
            <td>C</td>
            <td>0</td>
            {
                (props.cmp.cartDisconnectColumnIndex !== undefined) && (
                    <>
                        {
                            Array.from(Array(props.cmp.cartDisconnectColumnIndex)).map((_, idx) => <td key={idx}></td>)
                        }
                        <td style={{borderRight: '1px solid lightgray'}}>
                            {getComponentRenderer(
                                cartCols.disconnect,
                                props.dbPrj,
                                props.layoutId,
                                props.systemId,
                                {
                                    ...props.ctx,
                                    fieldRoot: "",
                                    // changeFieldRoots: changeRows.map(row => buildFieldRoot(row, col))
                                },
                                null,
                                props.mapDesignMode
                            )}
                        </td>
                    </>
                )
            }
        </tr>
    );
}

const buildFieldRoot = (row: IDataRow, col: IColumn) => (row.sideEnum === SideEnum.Flanged ? "FlangedSide" : "FlexSide")
    + "."
    + col.arrayPath
    + "["
    + row.spanTowerIndex
    + "].";

export const DataRow: FC<{
    row: IDataRow; 
    system: ISystem; 
    selectedDataRows: IDataRow[], 
    onSelectedChanged: (selected: boolean) => any,
    displaySettings: IDisplaySettingsFormState
} & Props> = (props) => {
    const selected = props.selectedDataRows.indexOf(props.row) !== -1;
    const changeRows = [...props.selectedDataRows];
    if (!selected) {
        changeRows.push(props.row);
    }

    const showCentered = (cmp: IComponent): React.CSSProperties => {
        return props.mapDesignMode && cmp.type === "boolean" ? {display: 'flex', justifyContent: 'center'} : {};
    };
    return (
        <tr>
            {
                !props.ctx.editingDealerDefaults && (
                    <>
                        <td>
                            <Checkbox style={{padding: props.mapDesignMode ? 0 : 'unset'}} checked={selected} onChange={e => props.onSelectedChanged(!selected)} />
                        </td>
                        { 
                            (props.system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || props.system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) 
                                && <td  style={{borderLeft: '1px solid lightgray'}}>{props.row.sideEnum === SideEnum.Flanged ? "R" : "F"}</td> 
                        }
                        <td style={{borderRight: '1px solid lightgray', paddingRight: 10}}>{props.row.Span.SpanNumber}</td>
                    </>
                )
            }
            {
                props.cmp.columns
                    .filter(col => !(props.ctx.editingDealerDefaults && col.disableDealerSettings) && (!col.visible || col.visible(props.system)))
                    .map(col => 
                        <td style={{borderRight: '1px solid lightgray'}}>{getComponentRenderer(
                            col.cmp,
                            props.dbPrj,
                            props.layoutId,
                            props.systemId,
                            {
                                ...props.ctx,
                                fieldRoot: buildFieldRoot(props.row, col),
                                changeFieldRoots: changeRows.map(row => buildFieldRoot(row, col))
                            },
                            null,
                            props.mapDesignMode
                        )}
                        </td>
                    )
            }
            {
                !props.ctx.editingDealerDefaults && (
                    <td style={{borderRight: '1px solid lightgray', paddingRight: 10, textAlign: "end"}}>
                        {
                            isFinite(props.row.distanceFromStart) 
                                ? new DisplayLengthUnitBuilder(props.row.distanceFromStart, 'feet')
                                    .convert(props.displaySettings.lengths)
                                    .appendValue(1)
                                    .appendSymbol()
                                    .toString()
                                : "-"
                        }
                    </td>
                )
            }
        </tr>
    );
}

export default SideTableRenderer;