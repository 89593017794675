import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import ISystem from "rdptypes/project/ISystem";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { IPricingHelperResult } from "../../helpers/pricingHelper";
import { formatCents, IDocumentType } from "../DocumentGenerationHelpers";
import { generateFexWarningBoxFromLines } from "../fexWarning";
import { documentStyles } from "../styles";
import FixedWidthTable, { ICell, IRow } from "./table/FixedWidthTable";

export interface Props {
    system: ISystem;
    quote: QuoteClass;
    docType: IDocumentType;
    ph: IPricingHelperResult;
}

const TotalPricesTable: FC<Props> = (props) => {
    const { system, quote, ph } = props;
    const pricing = system.QuoteProperties?.ProposalInformation?.pricing;

    const subTotalMinusTaxUsdCents_WithoutTradeIn = ph.totals.subTotalMinusTaxMinorUnits("USD", {
        system: true,
        installation: true,
        freight: true,
        pivotPad: true,
        miscItems: true,
        tradeIn: false
    });
    const subTotalMinusTaxUsdCents_WithTradeIn = ph.totals.subTotalMinusTaxMinorUnits("USD", {
        system: true,
        installation: true,
        freight: true,
        pivotPad: true,
        miscItems: true,
        tradeIn: true
    });
    const taxUsdCents = ph.totals.taxMinorUnits("USD", {
        system: true,
        installation: true,
        freight: true,
        pivotPad: true,
        miscItems: true,
        tradeIn: true
    });
    const customerSavings = ph.system.totalListPriceUsdCents - ph.system.systemPriceUsdCents;


    let rows: IRow[] = [];
    let ns = "common-phrases."

    let showListPrice = pricing?.printListPrice;
    let showTotalPriceOnly = pricing?.onlyPrintTotalPrice;
    
    if (showListPrice){
        rows.push({cells: [{
            text: "",
            widthPc: 46
        }, {
            text: t(ns + "sltTotalListPrice"),
            widthPc: 24
        }, {
            text: formatCents(
                ph.helpers.convertToLocalIfNeeded(ph.system.totalListPriceUsdCents, "USD"),
                ph.localCurrency?.symbol
            ),
            textAlignRight: true,
            widthPc: 24
        }]});

        rows.push({cells: [{
            text: "",
            widthPc: 46
        }, {
            text: t(ns + "sltDiscount"),
            widthPc: 24
        }, {
            text: formatCents(
                ph.helpers.convertToLocalIfNeeded(customerSavings, "USD"),
                ph.localCurrency?.symbol
            ),
            textAlignRight: true,
            widthPc: 24
        }]});
    }

    let cells: ICell[] = [{
        text: "",
        widthPc: 46
    }, {
        text: t(ns + "sltCustomerPrice"),
        widthPc: 24
    }, {
        text: formatCents(
            ph.helpers.convertToLocalIfNeeded(ph.system.systemPriceUsdCents, "USD"),
            ph.localCurrency?.symbol
        ),
        textAlignRight: true,
        widthPc: 24
    }];
    
    if (ph.system.isTaxable) {
        cells.push({text: "T", widthPc: 6});
    }

    rows.push({cells});

    cells = [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltInstallation"),
        widthPc: 24
    }, {
        text: showTotalPriceOnly 
            ? t(ns + "sltIncluded") 
            : formatCents(
                ph.helpers.convertToLocalIfNeeded(ph.installation.priceMinorUnits, ph.installation.currency),
                ph.localCurrency?.symbol
            ),
        widthPc: 24,
        textAlignRight: true
    }];

    if (!showTotalPriceOnly && ph.installation.isTaxable){
        cells.push({text: "T", widthPc: 6});
    }

    rows.push({cells});

    cells = [{
            text: "",
            widthPc: 46
    },{
        text: t(ns + "sltFreight"),
        widthPc: 24
    }, {
        text: showTotalPriceOnly 
            ? t(ns + "sltIncluded") 
            : formatCents(
                ph.helpers.convertToLocalIfNeeded(ph.freight.priceMinorUnits, ph.freight.currency),
                ph.localCurrency?.symbol
            ),
        widthPc: 24,
        textAlignRight: true
    }];

    if (!showTotalPriceOnly && ph.freight.isTaxable){
        cells.push({text: "T", widthPc: 6});
    }

    rows.push({cells});

    if (quote.System.SystemProperties.SystemType !== SystemTypes.Ancillary){
        cells = [{
            text: "",
            widthPc: 46
        },{
            text: t(ns + "sltPivotPad"),
            widthPc: 24
        }, {
            text: showTotalPriceOnly 
                ? t(ns + "sltIncluded") 
                : formatCents(
                    ph.helpers.convertToLocalIfNeeded(ph.pivotPad.priceMinorUnits, ph.pivotPad.currency),
                    ph.localCurrency?.symbol
                ),
            widthPc: 24,
            textAlignRight: true
        }];
        
        if (ph.pivotPad.isTaxable){
            cells.push({text: "T", widthPc: 6});
        }

        rows.push({cells});
    }

    cells = [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltMiscItems"),
        widthPc: 24
    }, {
        text: formatCents(
            ph.helpers.convertToLocalIfNeeded(ph.miscItems.preTaxTotalCostMinorUnits, ph.miscItems.currency),
            ph.localCurrency?.symbol
        ),
        widthPc: 24,
        textAlignRight: true,
        bottomBorder: true
    }];

    if (ph.miscItems.anyTaxable){
        cells.push({text: "T", widthPc: 6});
    }

    rows.push({cells});

    rows.push({cells: [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltPrice"),
        widthPc: 24,
        header: true
    }, {
        text: formatCents(
            ph.helpers.convertToLocalIfNeeded(subTotalMinusTaxUsdCents_WithoutTradeIn, "USD"),
            ph.localCurrency?.symbol
        ),
        widthPc: 24,
        textAlignRight: true,
        header: true
    }]});

    let elements: JSX.Element[] = [<FixedWidthTable
        data={{rows}}
    />];

    elements.push(<View style={{marginBottom: 10}}/>);

    cells = [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltTradeIn"),
        widthPc: 24
    },{
        text: formatCents(
            ph.helpers.convertToLocalIfNeeded(ph.tradeIn.priceMinorUnits, ph.tradeIn.currency),
            ph.localCurrency?.symbol
        ),
        textAlignRight: true,
        widthPc: 24
    }];

    if (ph.tradeIn.isTaxable){
        cells.push({text: "T", widthPc: 6});
    }

    rows = [{cells}];

    cells = [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltSubTotal"),
        widthPc: 24
    },{
        text: formatCents(
            ph.helpers.convertToLocalIfNeeded(subTotalMinusTaxUsdCents_WithTradeIn, "USD"),
            ph.localCurrency?.symbol
        ),
        textAlignRight: true,
        widthPc: 24
    }];

    rows.push({cells});
    
    cells = [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltSalesTax"),
        widthPc: 24
    },{
        text: formatCents(
            ph.helpers.convertToLocalIfNeeded(taxUsdCents, "USD"),
            ph.localCurrency?.symbol
        ),
        textAlignRight: true,
        widthPc: 24,
        bottomBorder: true
    }];

    rows.push({cells});
    const totalCents = subTotalMinusTaxUsdCents_WithTradeIn + taxUsdCents;
    rows.push({cells: [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltTotalPrice"),
        widthPc: 24,
        header: true
    }, {
        text: formatCents(
            ph.helpers.convertToLocalIfNeeded(totalCents, "USD"),
            ph.localCurrency?.symbol
        ),
        widthPc: 24,
        textAlignRight: true,
        header: true,
    }]});

    elements.push(<FixedWidthTable
        data={{rows}}
    />);
    elements.push(<View style={{marginBottom: 10}}/>);


    rows = [{cells: [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltDownPayment"),
        widthPc: 24
    },{
        text: formatCents(
            ph.helpers.convertToLocalIfNeeded(ph.downPayment.downPayment.minorUnits, ph.downPayment.currency),
            ph.localCurrency?.symbol
        ),
        textAlignRight: true,
        widthPc: 24
    }]}];

    rows.push({cells: [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltDueOnDelivery"),
        widthPc: 24
    },{
        text: formatCents(
            ph.helpers.convertToLocalIfNeeded(ph.downPayment.dueOnDelivery.minorUnits, ph.downPayment.currency),
            ph.localCurrency?.symbol
        ),
        textAlignRight: true,
        widthPc: 24
    }]});

    rows.push({cells: [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltDueOnInstallation"),
        widthPc: 24
    },{
        text: formatCents(
            ph.helpers.convertToLocalIfNeeded(ph.downPayment.dueOnInstallation.minorUnits, ph.downPayment.currency),
            ph.localCurrency?.symbol
        ),
        textAlignRight: true,
        widthPc: 24
    }]});

    elements.push(<FixedWidthTable
        data={{rows}}
    />);

    if (system.QuoteProperties.ProposalInformation?.factoryNotes){
        elements.push(<View style={{paddingLeft: 15, paddingRight: 15}}><Text>{t(ns + "sltFactoryNotes")}: {system.QuoteProperties.ProposalInformation.factoryNotes}
            </Text>
        </View>);
    }

    if (system.QuoteProperties.ProposalInformation?.tradeinNotes){
        elements.push(<View style={{paddingLeft: 15, paddingRight: 15}}><Text>
            {t(ns + "sltTradeInNotes")}: {system.QuoteProperties.ProposalInformation.tradeinNotes}
            </Text>
        </View>);
    }
    
    let paymentElements: JSX.Element[] = [];

    paymentElements.push(<Text style={{marginBottom: 10}}>
        {t(ns + "lsltPaymentShallBe")}
    </Text>)

    if (HasSwingArmCorner(quote.System)){
        paymentElements.push(<Text style={{marginBottom: 10}}>
            {t(ns + "lsltSACAcresTerms")}
        </Text>)
    }

    if (system.QuoteProperties.ProposalInformation?.hasMidAmericaRiskInsurance){
        paymentElements.push(<Text style={{marginBottom: 10}}>
            {t(ns + "sltFullReplacementValueInsurance")}
        </Text>)
    }

    paymentElements.push(<Text>
        {t(ns + "lsltConfidential")}
    </Text>)

    return (<View>
            <View wrap={false} style={documentStyles.section}>
                <View style={[documentStyles.heading, {marginBottom: 10}]}>
                        <Text style={documentStyles.sectionHeader}>{t(ns + "sltInvestment")}</Text>
                </View>
                {elements}
                {generateFexWarningBoxFromLines(ph.helpers.generateFexWarningNoteLines((ph.localCurrency?.symbol ?? "USD") !== "USD"))}
            </View>
            <View wrap={false} style={{...documentStyles.section}}>
                <View style={[documentStyles.heading, {marginBottom: 10}]}>
                        <Text style={documentStyles.sectionHeader}>{t(ns + "sltPaymentTerms")}</Text>
                </View>
                <View style={{paddingLeft: 15, paddingRight: 15}}>
                    {paymentElements}
                </View>
            </View>
        
        </View>
    );
};

export default TotalPricesTable;