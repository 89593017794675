import { DropTypes, RigidDropMaterialTypes } from "rdptypes/project/ISprinklers";
import { BoosterPumpTypes, CartTypes, CenterPivotTypes, EndGunTypes, EndOfSystemTypes, HoseFeedTypes, ISystemBase, PanelModels, PipeBottomFittingTypes, PressureTransducerOptions, RiserPipeTypes, SACRetroSprinklerDropType, SpanTypes, SprinklerValveTypes, SystemTypes, TowTypes, TowerHeights, WaterFeedTypes, WrapAroundSpanTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as sidef from "./SideFunctions";
import SystemFieldSets from "./SystemFieldSets";
export { IsPivotingLateral } from "rdptypes/helpers/SystemFunctions.part";

export const IsReverseTow = (sys: ISystemBase): boolean => sys.SystemProperties.ReverseTow;

export const HasSwingArmCorner = (sys: ISystemBase): boolean => sys.FlangedSide.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC;

export const IsEDMP = (sys: ISystemBase): boolean => sys.Circle.EngineDrivenMiniPivot;

export const IsCenterFeed = (sys: ISystemBase): boolean => IsMaxigator(sys) && sys.Lateral.WaterFeed === WaterFeedTypes.CenterFeed;

export const IsMaxigator = (sys: ISystemBase): boolean => sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
    || sys.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator;

export const HasFiveHPBoosterPump = (sys: ISystemBase): boolean => sys.FlangedSide?.EndOfSystem?.EndGun?.BoosterPump === BoosterPumpTypes.FiveHP
    || sys.FlexSide?.EndOfSystem?.EndGun?.BoosterPump === BoosterPumpTypes.FiveHP;

export const IsLateralOnly = (sys: ISystemBase): boolean => (sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && sys.Lateral?.HoseFeed?.HoseFeedType !== HoseFeedTypes.PivotingLateral) || FieldSets(sys).CanalFeed.DataValid();

export const IsHoseFeedEndFeed = (sys: ISystemBase): boolean => IsMaxigator(sys) && sys.Lateral?.WaterFeed === WaterFeedTypes.EndFeed;

export const FlowTubeKitAllowed = (sys: ISystemBase): boolean => sys.Circle?.CenterPivot?.CenterPivotType === CenterPivotTypes.E2065
    || sys.Circle?.CenterPivot?.CenterPivotType === CenterPivotTypes.E2085
    || sys.Circle?.CenterPivot?.CenterPivotType === CenterPivotTypes.A80G;
export const VerticalFlowTubeKitAllowed = (sys: ISystemBase): boolean => {
    const cp = sys.Circle?.CenterPivot;
    if (cp?.PivotCenterHeight !== TowerHeights.Standard) return false;
    if (cp?.RiserPipeFor6InchValve) return true;
    if (cp?.RiserPipeType === RiserPipeTypes.ExtraShortPipe) return false;
    if (cp?.TowOptions?.TowType === TowTypes.FourWheelPivotMover && (
        cp?.PipeBottomFitting === PipeBottomFittingTypes.Filter700GPM
        || cp?.PipeBottomFitting === PipeBottomFittingTypes.Filter1200GPM
    )) return false;
    return true;
};
export const HasPowerTowerEndBoom = (sys: ISystemBase): boolean => IsHoseFeedEndFeed(sys) && sys.Lateral.HoseFeed.PowerTowerEndBoom;
export const IsDualSided = (sys: ISystemBase): boolean => IsCenterFeed(sys) || HasPowerTowerEndBoom(sys) || (sys.FlangedSide?.Span?.some(x => !x.EndBoom && !x.SwingArm) && sys.FlexSide?.Span?.some(x => !x.EndBoom && !x.SwingArm));

export const HasVRI = (sys: ISystemBase): boolean => sys.SprinklerProperties.VRIZones.Zone.length !== 0;
export const Is230V = (sys: ISystemBase): boolean => sys.Circle.SinglePhase230VoltSystem;
export const FieldSets = (sys: ISystemBase) => new SystemFieldSets(sys);

export const HasTowerAutoReverse = (sys: ISystemBase): boolean =>
    sys.FlangedSide.Tower.some(x => x.TowerAutoReverse) || 
    (IsCenterFeed(sys) && sys.FlexSide.Tower.some(x => x.TowerAutoReverse));

export const SwingArmAllowed = (sys: ISystemBase): boolean => {
    switch (sys.SystemProperties.SystemType) {
        case SystemTypes.SwingArmRetro:
            return true;
        case SystemTypes.CenterPivot:
            break;
        default:
            return false;
    }

    // ROE-350 - Prohibit Swing Arm if ANY Tower has a WrapAroundSpan
    for (let i = 1; i <= sys.FlangedSide.Tower.length; i++) {
        if ([WrapAroundSpanTypes.TenDegree, WrapAroundSpanTypes.NinetyDegree].indexOf(sys.FlangedSide.Tower[i - 1].WrapAroundSpan) !== -1) return false;
    }

    if (Is230V(sys)) return false;
    if (IsEDMP(sys)) return false;

    const iNumberOfSpans = sidef.NumberOfSpans(sys.FlangedSide);

    if (iNumberOfSpans === 0) return false;

    switch (sys.FlangedSide.Span[iNumberOfSpans - 1].SpanType) {
        case SpanTypes.S2065G:
        case SpanTypes.E2045:
            return false;
    }
    switch (sys.FlangedSide.Span[iNumberOfSpans - 1].Length) {
        case 204:
        case 213:
            return false;
    }

    if ((sys.Circle.CenterPivot.TowOptions.TowType ?? TowTypes.None) !== TowTypes.None) return false;

    if (sys.FlangedSide.Tower[iNumberOfSpans - 1].TowerType === TowerHeights.LowProfile) return false;

    if (sidef.AnyHighSpeed(sys.FlangedSide)) return false;

    // ROE-554 - Allow Drop Span Option with Swing Arms
    // If FlangedSide.FirstDisconnectingSpan > 0 Then Return False

    if (sys.ControlPanel.PanelModel === PanelModels.RPMBasic) return false;
    if (HasPressureTransducer0to25PSI(sys)) return false;

    return true;
}

export const HasPressureTransducer0to25PSI = (sys: ISystemBase): boolean =>
    sys.ControlPanel.PressureTransducerOptions === PressureTransducerOptions.AtPivot
    && sys.ControlPanel.PressureTransducer0to25PSI;

export const HasEnergySaverPackage = (sys: ISystemBase): boolean =>
    sys.FlangedSide.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC
    && sys.Circle.SwingArm.EnergySaverPackage;

export const HasEndGun = (sys: ISystemBase): boolean => (sys.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary ?? EndGunTypes.None) !== EndGunTypes.None
    || (sys.FlexSide.EndOfSystem.EndGun.EndGunTypePrimary ?? EndGunTypes.None) !== EndGunTypes.None;

export const PivotPressure = (sys: ISystemBase): number => sys.SprinklerProperties.PivotPressure;

export const GetSourceTowerHeight = (sys: ISystemBase): TowerHeights | undefined => {
    switch (sys.SystemProperties.SystemType) {
        case SystemTypes.SwingArmRetro:
            return undefined; // No Towers, no Pivot Center Tower, no Apans with Disconnect.
        case SystemTypes.CenterPivot:
            return sys.Circle.CenterPivot.PivotCenterHeight;
        case SystemTypes.CanalFeedMaxigator:
            switch (sys.Lateral.CanalFeed.Cart) {
                case CartTypes.Standard:
                    return TowerHeights.Standard
                case CartTypes.Sugargator:
                    return TowerHeights.Sugargator
                default:
                    return undefined;
            }
        case SystemTypes.HoseFeedMaxigator:
            if ([HoseFeedTypes.A100, HoseFeedTypes.CF200, HoseFeedTypes.DoubleEndFeed,
            HoseFeedTypes.FourWheelDrive, HoseFeedTypes.PivotingLateral,
            HoseFeedTypes.Standard].indexOf(sys.Lateral.HoseFeed.HoseFeedType) !== -1) {
                return TowerHeights.Standard
            } else if (sys.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.Sugargator) {
                return TowerHeights.Sugargator
            } else {
                return undefined;
            }
        case SystemTypes.KwikTow:
            return TowerHeights.Standard; //Kwik Tow is always 'Standard' Height
    }
}
    
export class RigidDropsOnSACResult {
    HasApplicableRigidDrops: boolean;
    RigidDropMaterialType: RigidDropMaterialTypes;
}

export class UPipePackageOnSACResult {
    Required: boolean;
    RigidDropsOnSACResult: RigidDropsOnSACResult
}

/**
 * Determine if ROE-760 ESAC/VRI Require Flex Gooseneck with Rigid Drops is satisfied.
 * If the sprinkler package isn't defined then we can't know if the gooseneck option 
 * is required. Otherwise we would need to know if the package covers the SAC by distance.
 * @returns True if has applicable rigid drops on SAC AND has either SAC Air Compressor OR VRI on side
 */
export const RequiresUPipePackageOnSAC = (sys: ISystemBase): UPipePackageOnSACResult => {
    const rigidDropsOnSACResult = HasApplicableRigidDropsOnSAC(sys);

    let hasSACAirCompressor = false;
    if (new SystemFieldSets(sys).SwingArm.DataValid()) {
        hasSACAirCompressor = sys.Circle.SwingArm.AirCompressor;
    }

    return {
        RigidDropsOnSACResult: rigidDropsOnSACResult,
        Required: rigidDropsOnSACResult.HasApplicableRigidDrops && (hasSACAirCompressor || HasVRI(sys)),
    };
}

/**
 * Determine if ROE-760 ESAC/VRI Require Flex Gooseneck with Rigid Drops is satisfied.
 * If the sprinkler package isn't defined then we can't know if the gooseneck option 
 * is required. Otherwise we would need to know if the package covers the SAC by distance.
 * @returns True if has applicable rigid drops on SAC
 */
export const HasApplicableRigidDropsOnSAC = (sys: ISystemBase): RigidDropsOnSACResult => {
    const result = new RigidDropsOnSACResult();
   switch (sys.SystemProperties.SystemType) {
        case SystemTypes.CenterPivot:
        case SystemTypes.KwikTow:
            if (new SystemFieldSets(sys).SprinklerConfig.DataValid()) {
                // Query the packages to see if they have applicable rigid drops on the Swing Arm Corner spans
                const side = sys.FlangedSide;
                const pks = side.Sprinklers.Package;
                let startLocation = 0;
                for (const p of pks) {
                    const hasDrop = p.Drop === DropTypes.Rigid &&
                        [RigidDropMaterialTypes.Galvanized, RigidDropMaterialTypes.PVC, RigidDropMaterialTypes.Poly].indexOf(p.RigidDrop.DropMaterial) !== -1;

                    const endSpan = sidef.SpanFromLocation(sys, side, p.EndingLocation, true, true);
                    const coversSAC = sidef.AnySwingArmSpans(side, startLocation, endSpan)
                    if (coversSAC && hasDrop) {
                        result.HasApplicableRigidDrops = true;
                        if (p.RigidDrop.DropMaterial === RigidDropMaterialTypes.Galvanized) result.RigidDropMaterialType = RigidDropMaterialTypes.Galvanized;
                        if ([RigidDropMaterialTypes.PVC, RigidDropMaterialTypes.Poly].indexOf(p.RigidDrop.DropMaterial) !== -1) result.RigidDropMaterialType = RigidDropMaterialTypes.PVC;
                        break;
                    }
                    // Keep track of the last package end location as it is inferred as next start location
                    startLocation = sidef.SpanFromLocation(sys, side, p.EndingLocation + 0.1, true, true);
                }
            }
            break;
        case SystemTypes.SwingArmRetro:
            if (new SystemFieldSets(sys).SACRetro.DataValid()) {
                result.HasApplicableRigidDrops = [SACRetroSprinklerDropType.RigidGalvanized, SACRetroSprinklerDropType.RigidPVCPoly].indexOf(sys.Circle.SACRetro.DropType) !== -1;
                if (sys.Circle.SACRetro.DropType === SACRetroSprinklerDropType.RigidGalvanized) result.RigidDropMaterialType = RigidDropMaterialTypes.Galvanized;
                    if (sys.Circle.SACRetro.DropType === SACRetroSprinklerDropType.RigidPVCPoly) result.RigidDropMaterialType = RigidDropMaterialTypes.PVC;
            }
            break;
}
    return result;
}

/**
 * Test if Valve Type selection is Poly (Plastic) on either Swing Arm Corner or VRI.
 * Restrictions on Hose Drops is an example of use for this check.
 * @returns 
 */
export const HasSprinklerPolyValve = (sys: ISystemBase): boolean =>
    HasSwingArmCorner(sys) && sys.Circle.SwingArm.SprinklerValveType === SprinklerValveTypes.Poly
|| HasVRI(sys) && sys.SprinklerProperties.SprinklerValveType === SprinklerValveTypes.Poly;