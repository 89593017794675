import { Box, Card, Typography } from "@mui/material";
import i18next, { t } from "i18next";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import ISystem from "rdptypes/project/ISystem";
import IComponent from "rdptypes/roe/IComponent";
import IPage from "rdptypes/roe/IPage";
import * as React from "react";
import { partsPackages } from "roedata/data";
import IAuthState from "../../../../../auth/IAuthState";
import IComponentRenderContext from "../../../../../components/roe/componentRenderers/IComponentRenderContext";
import PageRenderer from "../../../../../components/roe/componentRenderers/PageRenderer";
import { pageStyles } from "../../../../../components/roe/styles";
import IDbProject from "../../../../../db/IDbProject";
import { EndGunLocation } from "../../../../../model/project/IEndGun";
import EndGunForSide from "./EndGunForSide";

interface IEndOfSystemForSideComponents { eos: IComponent, primaryEndgun: IComponent, secondaryEndgun: IComponent };
interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    components: IEndOfSystemForSideComponents;
    authState: IAuthState;
    side: SideEnum;
    system: ISystem;
    ctx: IComponentRenderContext;
}

const EndOfSystemForSide: React.FC<Props> = ({ 
    dbPrj, layoutId, systemId, components, side, authState, system, ctx
}) => {
    const { roeData } = partsPackages[system.partsPackageId];

    const title = side === SideEnum.Flanged
        ? i18next.format(t("common-phrases.sltFlangedSide"), "capitalize-each")
        : i18next.format(t("common-phrases.sltFlexSide"), "capitalize-each");

    return (
        <Box>
            <Card style={{padding: 10 }} variant="outlined">
                <Typography sx={pageStyles.cardTitleStyle}>{title}</Typography>

                {/* End of System */}
                <PageRenderer 
                    page={{
                        position: 0,
                        title: "",
                        components: [ components.eos ]
                    } as IPage} 
                    rules={roeData.rules}
                    dbPrj={dbPrj} 
                    layoutId={layoutId} 
                    systemId={systemId}
                    ctx={ctx}
                    oneColArrangement={true}
                />

                <EndGunForSide
                    systemId={systemId}
                    system={system}
                    layoutId={layoutId}
                    dbPrj={dbPrj}
                    authState={authState}
                    side={side}
                    endGunLocation={EndGunLocation.Primary}
                    endGunComponent={components.primaryEndgun}
                    ctx={ctx}
                />
                
                <EndGunForSide
                    systemId={systemId}
                    system={system}
                    layoutId={layoutId}
                    dbPrj={dbPrj}
                    authState={authState}
                    side={side}
                    endGunLocation={EndGunLocation.Secondary}
                    endGunComponent={components.secondaryEndgun}
                    ctx={ctx}
                />

            </Card>
        </Box>
    )
}

export default EndOfSystemForSide;