import { getEndBoom } from "rdptypes/helpers/EndBoom";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { getSpansWithoutEndOfSystem } from "rdptypes/helpers/Spans";
import { getAftSide, getFwdSide } from "rdptypes/helpers/system";
import { ISide } from "rdptypes/project/Types";
import * as React from "react";
import { FC } from "react";
import LateralGeometryHelper from "../../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../../helpers/lengths";
import IProject from "../../../model/project/IProject";
import { SYSTEM_DESIGN_CONSTANTS } from "../SystemDiagram/constants";
import { IElevationProfile } from "../interfaces";
import { towerDefinitionFromTowerOrPivot } from "../towerDefinition";
import Span, { ISpanDiagramElementProps } from "./Span";
import Tower from "./Tower";

import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import EndBoom from "./EndBoom";

interface IProps {
    systemId: string;
    layoutId: string;
    project: IProject;
    minElevationFeet: number;
    maxElevationFeet: number;
    elevationProfile: IElevationProfile;
    zoom: boolean;
    justSystemDiagram?: {
        partWidthFeet: number,
        widthInPixels: number    
    };
}

const LateralDiagram: FC<IProps> = ({
    systemId, layoutId, project,
    minElevationFeet: incommingMinElevationFeet, maxElevationFeet: incommingMaxElevationFeet, 
    elevationProfile, zoom,
    justSystemDiagram
}) => {
    const settings = React.useContext(DevSettingsCtx);

    const system = project.layouts[layoutId]?.systems[systemId];
    if (!system) return null;

    // The coordinate reference system uses units of inches:
    //  * X = 0 - center of the lateral (canal center or feed line)
    //  * X increases towards the fwd end of the system
    //  * Y = 0 - minElevationFeet * 12
    //  * Y increases up (by using transform on svg element)

    if (!elevationProfile) return null;
    
    const profileTextElements: React.JSX.Element[] = [];
    const textFontStyle = `bold 5em sans-serif`;

    const aftSideEnum = getAftSide(system);
    const aftSide: ISide = aftSideEnum === SideEnum.Flanged
        ? system.FlangedSide
        : system.FlexSide;

    const fwdSideEnum = getFwdSide(system);
    const fwdSide: ISide = fwdSideEnum === SideEnum.Flanged
        ? system.FlangedSide
        : system.FlexSide;

        
    const hasAftSideEndGun = (aftSide.EndOfSystem.EndGun.EndGunTypePrimary !== EndGunTypes.None || aftSide.EndOfSystem.EndGun.EndGunTypeSecondary !== EndGunTypes.None);
    const hasFwdSideEndGun = (fwdSide.EndOfSystem.EndGun.EndGunTypePrimary !== EndGunTypes.None || fwdSide.EndOfSystem.EndGun.EndGunTypeSecondary !== EndGunTypes.None);
    const aftSpanCount = system.FlexSide?.Span?.length ?? 0;

    // system center sizing:
    const lateralGeometry = new LateralGeometryHelper({ systemId, layoutId, project });
    const systemCenterToAftStartFeet = lateralGeometry.getDistanceFromSystemCenterToAftStartFeet();
    const systemCenterToFwdStartFeet = lateralGeometry.getDistanceFromSystemCenterToFwdStartFeet();
    const systemCenterToAftEndFeet = lateralGeometry.getDistanceFromSystemCenterToAftEndFeet();
    const systemCenterToFwdEndFeet = lateralGeometry.getDistanceFromSystemCenterToFwdEndFeet();

    // calculate the total running system length:
    let totalSystemLengthInches = 0;
    let totalAftSystemLengthInches = 0;
    let maxSystemHeightFeet = 0;
    {
        if (
            system.FlexSide.Span.some(x => !Number.isFinite(spanf.LengthInFeet(system.FlexSide, x))) || 
            system.FlangedSide.Span.some(x => !Number.isFinite(spanf.LengthInFeet(system.FlangedSide, x)))
        ) {
            console.log("Incomplete system definition, system diagram not rendered 1");
            return null;
        }
        const flexSideLength = system.FlexSide.Span.length
            ? spanf.EndingRadius(system, system.FlexSide, system.FlexSide.Span.slice(-1)[0])
            : 0;
        const flangedSideLength = system.FlangedSide.Span.length
            ? spanf.EndingRadius(system, system.FlangedSide, system.FlangedSide.Span.slice(-1)[0])
            : 0;
        let aftSideLength = lateralGeometry.isRigidSideForward() 
            ? flexSideLength
            : flangedSideLength;
        let fwdSideLength = lateralGeometry.isRigidSideForward() 
            ? flangedSideLength
            : flexSideLength;
        aftSideLength += systemCenterToAftStartFeet;
        fwdSideLength += systemCenterToFwdStartFeet;

        totalSystemLengthInches = (aftSideLength + fwdSideLength) * 12;
        totalAftSystemLengthInches = aftSideLength * 12;
    }
    for (let i = 0; i < system.FlexSide?.Span?.length ?? 0; i++) {
        const tower = system.FlexSide.Tower[i];
        const towerDefinition = towerDefinitionFromTowerOrPivot(tower);
        maxSystemHeightFeet = Math.max(maxSystemHeightFeet, towerDefinition.heightFeet);
    }
    for (let i = 0; i < system.FlangedSide?.Span?.length ?? 0; i++) {
        const tower = system.FlangedSide.Tower[i];
        const towerDefinition = towerDefinitionFromTowerOrPivot(tower);
        maxSystemHeightFeet = Math.max(maxSystemHeightFeet, towerDefinition.heightFeet);
    }
    
    // find the profile elevation max/min:
    let minProfileElevationFeet = Number.MAX_VALUE;
    let maxProfileElevationFeet = Number.MIN_VALUE;
    for (const elevationProfileItem of elevationProfile) {
        if (
            elevationProfileItem.maxDistanceFeet >= -totalAftSystemLengthInches * 12 && 
            elevationProfileItem.minDistanceFeet <= totalSystemLengthInches * 12
        ) {
            minProfileElevationFeet = Math.min(minProfileElevationFeet, elevationProfileItem.elevationFeet);
            maxProfileElevationFeet = Math.max(maxProfileElevationFeet, elevationProfileItem.elevationFeet);
        }
    }

    const minElevationFeet = zoom ? minProfileElevationFeet : incommingMinElevationFeet;
    const maxElevationFeet = zoom ? maxProfileElevationFeet : incommingMaxElevationFeet;

    /**
     * The distance of the near end of the system from the middle of the system.
     */
    const paddingLeftInches = 10 * 12;
    const paddingRightInches = 10 * 12;

    const nearDistanceFromAftEnd = 10 * 12;
    const maxDepthBelowGroundInches = 3 * 12; // Show 3 feet of ground even at lowest elevation
    /** The distance of the far end of the system from the middle of the pivot center riser pipe. */
    const farDistanceFromAftEnd = totalSystemLengthInches + 10 * 12;
    const systemHeightAndSkyFeet = maxSystemHeightFeet + 10;
    const skyUpperElevationInches = (maxElevationFeet + systemHeightAndSkyFeet) * 12; // The elevation for upper sky line
    const baselineGroundUpperElevationInches = minElevationFeet * 12;

    // set up svg viewbox dimenstions:
    const svgLeft = -paddingLeftInches - systemCenterToAftEndFeet * 12;
    const svgWidth = paddingLeftInches + paddingRightInches + (systemCenterToAftEndFeet + systemCenterToFwdEndFeet) * 12;
    const svgTop = minElevationFeet * 12 - maxDepthBelowGroundInches;
    const svgHeight = maxDepthBelowGroundInches + (maxElevationFeet - minElevationFeet + systemHeightAndSkyFeet) * 12;
    
    // Generate the diagram elements
    const groundBaselineElement = (
        <rect 
            x={svgLeft} y={minElevationFeet * 12 - maxDepthBelowGroundInches} 
            width={svgWidth} height={maxDepthBelowGroundInches} 
            fill={SYSTEM_DESIGN_CONSTANTS.ground.color} 
            stroke={SYSTEM_DESIGN_CONSTANTS.ground.color}
        />
    )
    
    const skyElement = (
        <rect 
            x={svgLeft} y={baselineGroundUpperElevationInches} 
            width={svgWidth} height={skyUpperElevationInches - baselineGroundUpperElevationInches}
            fill="#D4E6F1"
        />
    )
    
    const groundElements: JSX.Element[] = [];
    const groundSegmentWidthInches = 10 * 12;
    for (let distanceInches = svgLeft; distanceInches < svgWidth + svgLeft; distanceInches += groundSegmentWidthInches) {
        const sectionWidthInches = Math.min(groundSegmentWidthInches, svgWidth + svgLeft - distanceInches);
        const startDistanceFeet = distanceInches / 12;
        const endDistanceFeet = startDistanceFeet + sectionWidthInches / 12;
        const startElevationProfileItem = elevationProfile.find(y => startDistanceFeet < y.maxDistanceFeet && startDistanceFeet >= y.minDistanceFeet);
        const endElevationProfileItem = elevationProfile.find(y => endDistanceFeet < y.maxDistanceFeet && endDistanceFeet >= y.minDistanceFeet);
        if (startElevationProfileItem === undefined || endElevationProfileItem === undefined) {
            // then we will not generate a diagram
            console.log("Incomplete elevation profile, system diagram not rendered 1");
            return null;
        }
        groundElements.push(
            <polygon 
                key={`ground-${distanceInches}`} 
                points={`
                    ${distanceInches} ${minElevationFeet * 12}
                    ${distanceInches} ${startElevationProfileItem.elevationFeet * 12}
                    ${distanceInches + sectionWidthInches} ${endElevationProfileItem.elevationFeet * 12}
                    ${distanceInches + sectionWidthInches} ${minElevationFeet * 12}
                `}
                fill={SYSTEM_DESIGN_CONSTANTS.ground.color}
                stroke={SYSTEM_DESIGN_CONSTANTS.ground.color}
            />
        )
    }    

    const systemCenterElements: React.ReactNode[] = [];
    const centerElevationProfileItem = elevationProfile.find(y => 0 < y.maxDistanceFeet && 0 >= y.minDistanceFeet);
    if (!centerElevationProfileItem) {
        console.log("Incomplete elevation profile, system diagram not rendered 2");
        return null;
    }
    if (lateralGeometry.isCanalFeed) {
        const canalWidthInches = lateralGeometry.canalWidthFeet * 12;
        const y0 = minProfileElevationFeet * 12 - maxDepthBelowGroundInches;
        const height = centerElevationProfileItem.elevationFeet * 12 - y0;
        systemCenterElements.push(
            <rect 
                key={"canal"}
                x={-canalWidthInches * 0.5} 
                y={minProfileElevationFeet * 12 - maxDepthBelowGroundInches} 
                width={canalWidthInches} 
                height={height}
                fill={SYSTEM_DESIGN_CONSTANTS.canal.color}
            />
        )

    }
    else {
        systemCenterElements.push(
            <line 
                key={'feed-line'} 
                x1={0} x2={0} 
                y1={minProfileElevationFeet * 12 - maxDepthBelowGroundInches} 
                y2={maxProfileElevationFeet * 12} 
                stroke='black' strokeWidth={12} 
            />
        )
    }

    const spanElements: JSX.Element[] = [];
    const aftSpanElements: JSX.Element[] = [];
    const isRigidSideForward = lateralGeometry.isRigidSideForward();
    // TODO: Currently the feed tower is assumed to be the same as the first flanged tower (for both sides)
    const cartTower = system.FlangedSide.Tower[0];
    const cartTowerDefinition = towerDefinitionFromTowerOrPivot(cartTower);

    // aft side
    const aftEndBoom = getEndBoom(system, aftSideEnum);
    const endGunOnFAftSpans = !aftEndBoom && hasAftSideEndGun;
    const aftSpansWithoutEndboom = getSpansWithoutEndOfSystem(system, aftSideEnum);
    let aftLastSpanBeforeEndBoomArgs: ISpanDiagramElementProps | undefined = undefined;
    let aftStartElevation: number;
    if (aftSide.Span.length) {    
        const distanceFeet = lateralGeometry.distanceFromCanalCenterToAftSide;
        const directionalDistanceFeet = -distanceFeet;
        const elevationProfileItem = elevationProfile.find(y => directionalDistanceFeet < y.maxDistanceFeet && directionalDistanceFeet >= y.minDistanceFeet);
        if (elevationProfileItem === undefined) {
            // then we will not generate a diagram
            console.log("Incomplete elevation profile, system diagram not rendered 3");
            return null;
        }
        aftSpanElements.push(
            <Tower 
                key={`afttower${aftSpanElements.length}`} 
                towerHeightInches={cartTowerDefinition.heightFeet * 12}
                startDistanceInches={distanceFeet * 12} 
                elevationFeet={elevationProfileItem.elevationFeet} 
            />
        )
        aftStartElevation = elevationProfileItem.elevationFeet;
    }
    for (let i = 0; i < aftSpansWithoutEndboom.length; i++) {
        const span = aftSide.Span[i];
        const tower = aftSide.Tower[i];
        const preceedingSpanTowerTower = i === 0 ? cartTower : aftSide.Tower[i-1];

        const spanLengthFeet = spanf.LengthInFeet(aftSide, span);
        const spanLengthInches = spanLengthFeet * 12;

        const startDistanceFeet = spanf.StartingRadius(system, aftSide, span) + systemCenterToAftStartFeet;
        const startDistanceInches = startDistanceFeet * 12;

        const endDistanceFeet = spanf.EndingRadius(system, aftSide, span) + systemCenterToAftStartFeet;
        const endDistanceInches = endDistanceFeet * 12;

        const directionalStartDistanceFeet = -startDistanceFeet;
        const directionalEndDistanceFeet = -endDistanceFeet;
        const startElevationProfileItem = elevationProfile.find(y => directionalStartDistanceFeet < y.maxDistanceFeet && directionalStartDistanceFeet >= y.minDistanceFeet);
        const endElevationProfileItem = elevationProfile.find(y => directionalEndDistanceFeet < y.maxDistanceFeet && directionalEndDistanceFeet >= y.minDistanceFeet);
        if (startElevationProfileItem === undefined || endElevationProfileItem === undefined) {
            // then we will not generate a diagram
            console.log(startDistanceFeet, endDistanceFeet, directionalStartDistanceFeet, directionalEndDistanceFeet, startElevationProfileItem, endElevationProfileItem, elevationProfile)
            console.log("Incomplete elevation profile, system diagram not rendered 4");
            return null;
        }
        
        const endTowerDefinition = towerDefinitionFromTowerOrPivot(tower);
        const startTowerDefinition = towerDefinitionFromTowerOrPivot(preceedingSpanTowerTower);

        aftLastSpanBeforeEndBoomArgs = {
            spanLengthInches: spanLengthInches,
            nozzleSpacingInches: span.Spacing,
            startDistanceInches: startDistanceInches,
            startHeightInches: startTowerDefinition.heightFeet * 12,
            endHeightInches: endTowerDefinition.heightFeet * 12,
            elevationStartFeet: startElevationProfileItem.elevationFeet,
            elevationEndFeet: endElevationProfileItem.elevationFeet,
            endGun: endGunOnFAftSpans && i === (aftSpansWithoutEndboom.length - 1),
        }
        aftSpanElements.push(
            <Span 
                key={`aftspan${aftSpanElements.length}`} 
                {...aftLastSpanBeforeEndBoomArgs}
            />
        );
        aftSpanElements.push(
            <Tower 
                key={`afttower${aftSpanElements.length}`} 
                towerHeightInches={endTowerDefinition.heightFeet * 12}
                startDistanceInches={endDistanceInches} 
                elevationFeet={endElevationProfileItem.elevationFeet} 
            />
        )
        let label =  "";
        if (i === 0) {
            if (aftSideEnum === SideEnum.Flanged) {
                label += "Flanged "
            }
            else {
                label += "Flex "
            }
        }
        label += (i + 1).toString();
        profileTextElements.push(
            <text 
                key={`aft-span-label-${i+1}`} 
                x={-startDistanceInches - 0.5 * spanLengthInches} 
                y={-(startElevationProfileItem.elevationFeet + endElevationProfileItem.elevationFeet)*0.5*12 - 14*12}
                style={{ font: textFontStyle }}
            >
                {label}
            </text>
        )
    }
    if (aftEndBoom && aftLastSpanBeforeEndBoomArgs) {
        const span = aftEndBoom;
        // NOTE: There is no swing arm tower stored, so we will use the last tower
        const preceedingSpanTowerTower = aftSide.Tower[aftSide.Tower.length - 1];

        // Note: If there is a swing arm, the swing arm and end boom are not fully extended, and the length in feet does not account for this.
        // As such, the angular extension length given by start/end radius is used to calculate span length    
        const startDistanceFeet = spanf.StartingRadius(system, aftSide, span) + systemCenterToAftStartFeet;
        const startDistanceInches = startDistanceFeet * 12;

        const endDistanceFeet = spanf.EndingRadius(system, aftSide, span) + systemCenterToAftStartFeet;
        const endDistanceInches = endDistanceFeet * 12;
        
        const spanLengthInches = endDistanceInches - startDistanceInches;

        const directionalStartDistanceFeet = -startDistanceFeet;
        const directionalEndDistanceFeet = -endDistanceFeet;
        const startElevationProfileItem = elevationProfile.find(y => directionalStartDistanceFeet < y.maxDistanceFeet && directionalStartDistanceFeet >= y.minDistanceFeet);
        const endElevationProfileItem = elevationProfile.find(y => directionalEndDistanceFeet < y.maxDistanceFeet && directionalEndDistanceFeet >= y.minDistanceFeet);
        if (startElevationProfileItem === undefined || endElevationProfileItem === undefined) {
            // then we will not generate a diagram
            console.log("Incomplete elevation profile, system diagram not rendered 5");
            return null;
        }
        
        const startTowerDefinition = towerDefinitionFromTowerOrPivot(preceedingSpanTowerTower);
        const endHeightInches = (startTowerDefinition.heightFeet + 2) * 12; 
        aftSpanElements.push(
            <EndBoom 
                key={`span${aftSpanElements.length}`} 
                spanLengthInches={spanLengthInches}
                nozzleSpacingInches={span.Spacing}
                startDistanceInches={startDistanceInches} 
                startHeightInches={startTowerDefinition.heightFeet * 12}
                endHeightInches={endHeightInches}
                elevationStartFeet={startElevationProfileItem.elevationFeet}
                elevationEndFeet={endElevationProfileItem.elevationFeet}
                endGun={hasAftSideEndGun}
                lastSpanBeforeEndBoomArgs={aftLastSpanBeforeEndBoomArgs}
            />
        );
    }

    // Fwd side:
    const fwdEndBoom = getEndBoom(system, fwdSideEnum);
    const endGunOnForwardSpans = !fwdEndBoom && hasFwdSideEndGun;
    const fwdSpansWithoutEndboom = getSpansWithoutEndOfSystem(system, fwdSideEnum);
    let fwdLastSpanBeforeEndBoomArgs: ISpanDiagramElementProps | undefined = undefined;
    let fwdStartElevation: number;
    if (fwdSide.Span.length) {    
        const distanceFeet = lateralGeometry.distanceFromCanalCenterToFwdSide;
        const directionalDistanceFeet = distanceFeet;
        const elevationProfileItem = elevationProfile.find(y => directionalDistanceFeet < y.maxDistanceFeet && directionalDistanceFeet >= y.minDistanceFeet);
        if (elevationProfileItem === undefined) {
            // then we will not generate a diagram
            console.log("Incomplete elevation profile, system diagram not rendered 6");
            return null;
        }
        fwdStartElevation = elevationProfileItem.elevationFeet;
        spanElements.push(
            <Tower 
                key={`afttower${aftSpanElements.length}`} 
                towerHeightInches={cartTowerDefinition.heightFeet * 12}
                startDistanceInches={distanceFeet * 12} 
                elevationFeet={elevationProfileItem.elevationFeet} 
            />
        )
    }
    for (let i = 0; i < fwdSpansWithoutEndboom.length; i++) {
        const span = fwdSide.Span[i];
        const tower = fwdSide.Tower[i];
        const preceedingSpanTowerTower = i === 0 ? cartTower : fwdSide.Tower[i-1];

        const spanLengthFeet = spanf.LengthInFeet(fwdSide, span);
        const spanLengthInches = spanLengthFeet * 12;

        const startDistanceFeet = spanf.StartingRadius(system, fwdSide, span) + systemCenterToFwdStartFeet;
        const startDistanceInches = startDistanceFeet * 12;

        const endDistanceFeet = spanf.EndingRadius(system, fwdSide, span) + systemCenterToFwdStartFeet;
        const endDistanceInches = endDistanceFeet * 12;

        const directionalStartDistanceFeet = startDistanceFeet;
        const directionalEndDistanceFeet = endDistanceFeet;
        const startElevationProfileItem = elevationProfile.find(y => directionalStartDistanceFeet < y.maxDistanceFeet && directionalStartDistanceFeet >= y.minDistanceFeet);
        const endElevationProfileItem = elevationProfile.find(y => directionalEndDistanceFeet < y.maxDistanceFeet && directionalEndDistanceFeet >= y.minDistanceFeet);
        if (startElevationProfileItem === undefined || endElevationProfileItem === undefined) {
            // then we will not generate a diagram
            console.log("Incomplete elevation profile, system diagram not rendered 7");
            return null;
        }
        
        const endTowerDefinition = towerDefinitionFromTowerOrPivot(tower);
        const startTowerDefinition = towerDefinitionFromTowerOrPivot(preceedingSpanTowerTower);

        fwdLastSpanBeforeEndBoomArgs = {
            spanLengthInches: spanLengthInches,
            nozzleSpacingInches: span.Spacing,
            startDistanceInches: startDistanceInches,
            startHeightInches: startTowerDefinition.heightFeet * 12,
            endHeightInches: endTowerDefinition.heightFeet * 12,
            elevationStartFeet: startElevationProfileItem.elevationFeet,
            elevationEndFeet: endElevationProfileItem.elevationFeet,
            endGun: endGunOnForwardSpans && i === (fwdSpansWithoutEndboom.length - 1),
        }
        spanElements.push(
            <Span 
                key={`span${spanElements.length}`} 
                {...fwdLastSpanBeforeEndBoomArgs}
            />
        );
        spanElements.push(
            <Tower 
                key={`tower${spanElements.length}`} 
                towerHeightInches={endTowerDefinition.heightFeet * 12}
                startDistanceInches={endDistanceInches} 
                elevationFeet={endElevationProfileItem.elevationFeet} 
            />
        )
        let label =  "";
        if (i === 0) {
            if (fwdSideEnum === SideEnum.Flanged) {
                label += "Flanged "
            }
            else {
                label += "Flex "
            }
        }
        label += (i + 1).toString();
        profileTextElements.push(
            <text 
                key={`fwd-span-label-${i+1}`} 
                x={startDistanceInches + 0.5 * spanLengthInches} 
                y={-(startElevationProfileItem.elevationFeet + endElevationProfileItem.elevationFeet)*0.5*12 - 14*12}
                style={{ font: textFontStyle }}
            >
                {label}
            </text>
        )
    }
    if (fwdEndBoom && fwdLastSpanBeforeEndBoomArgs) {
        const span = fwdEndBoom;
        // NOTE: There is no swing arm tower stored, so we will use the last tower
        const preceedingSpanTowerTower = fwdSide.Tower[fwdSide.Tower.length - 1];

        // Note: If there is a swing arm, the swing arm and end boom are not fully extended, and the length in feet does not account for this.
        // As such, the angular extension length given by start/end radius is used to calculate span length    
        const startDistanceFeet = spanf.StartingRadius(system, fwdSide, span) + systemCenterToFwdStartFeet;
        const startDistanceInches = startDistanceFeet * 12;

        const endDistanceFeet = spanf.EndingRadius(system, fwdSide, span) + systemCenterToFwdStartFeet;
        const endDistanceInches = endDistanceFeet * 12;
        
        const spanLengthInches = endDistanceInches - startDistanceInches;

        const directionalStartDistanceFeet = startDistanceFeet;
        const directionalEndDistanceFeet = endDistanceFeet;
        const startElevationProfileItem = elevationProfile.find(y => directionalStartDistanceFeet < y.maxDistanceFeet && directionalStartDistanceFeet >= y.minDistanceFeet);
        const endElevationProfileItem = elevationProfile.find(y => directionalEndDistanceFeet < y.maxDistanceFeet && directionalEndDistanceFeet >= y.minDistanceFeet);
        if (startElevationProfileItem === undefined || endElevationProfileItem === undefined) {
            // then we will not generate a diagram
            console.log("Incomplete elevation profile, system diagram not rendered 8");
            return null;
        }
        
        const startTowerDefinition = towerDefinitionFromTowerOrPivot(preceedingSpanTowerTower);
        const endHeightInches = (startTowerDefinition.heightFeet + 2) * 12; 
        spanElements.push(
            <EndBoom 
                key={`span${spanElements.length}`} 
                spanLengthInches={spanLengthInches}
                nozzleSpacingInches={span.Spacing}
                startDistanceInches={startDistanceInches} 
                startHeightInches={startTowerDefinition.heightFeet * 12}
                endHeightInches={endHeightInches}
                elevationStartFeet={startElevationProfileItem.elevationFeet}
                elevationEndFeet={endElevationProfileItem.elevationFeet}
                endGun={hasFwdSideEndGun}
                lastSpanBeforeEndBoomArgs={fwdLastSpanBeforeEndBoomArgs}
            />
        );
    }

    // canal feed:
    if (system.Lateral.CanalFeed) {
        // feed from the canal
        const y0 = minProfileElevationFeet * 12 - maxDepthBelowGroundInches;
        const y1 = centerElevationProfileItem.elevationFeet * 12 + cartTowerDefinition.heightFeet * 12;
        systemCenterElements.push(
            <path 
                d={`
                    M ${0} ${y0} 
                    L ${0} ${y1} 
                `} 
                stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
                strokeWidth={SYSTEM_DESIGN_CONSTANTS.pipe.width}
                fill="none" 
            />
        )
        if (aftSide.Span.length) {
            const y2 = aftStartElevation * 12 + cartTowerDefinition.heightFeet * 12;
            systemCenterElements.push(
                <path 
                    d={`
                        M ${0} ${y1} 
                        L ${-systemCenterToAftStartFeet*12} ${y2} 
                    `} 
                    stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
                    strokeWidth={SYSTEM_DESIGN_CONSTANTS.pipe.width}
                    fill="none" 
                />
            )
        }
        if (fwdSide.Span.length) {
            const y2 = fwdStartElevation * 12 + cartTowerDefinition.heightFeet * 12;
            systemCenterElements.push(
                <path 
                    d={`
                        M ${0} ${y1} 
                        L ${systemCenterToFwdStartFeet*12} ${y2}} 
                    `} 
                    stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
                    strokeWidth={SYSTEM_DESIGN_CONSTANTS.pipe.width}
                    fill="none" 
                />
            )
        }
    }
    


    const profileElevatonElements = justSystemDiagram ? [] : [
        <line 
            key={'min-profile-elevation'} 
            x1={svgLeft} x2={svgLeft + svgWidth} 
            y1={minProfileElevationFeet * 12} y2={minProfileElevationFeet * 12} 
            stroke='deeppink' strokeWidth={12} />,
        <line 
            key={'max-profile-elevation'} 
            x1={svgLeft} x2={svgLeft + svgWidth} 
            y1={maxProfileElevationFeet * 12} y2={maxProfileElevationFeet * 12} 
            stroke='deeppink' strokeWidth={12} />
    ]
    
    const minProfileRelativeElevation = minProfileElevationFeet - centerElevationProfileItem.elevationFeet;
    const maxProfileRelativeElevation = maxProfileElevationFeet - centerElevationProfileItem.elevationFeet;
    const minLabel = new DisplayLengthUnitBuilder(minProfileRelativeElevation, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(1)
        .appendString(" ")
        .appendShortName()
        .toString();
    const maxLabel = new DisplayLengthUnitBuilder(maxProfileRelativeElevation, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(1)
        .appendString(" ")
        .appendShortName()
        .toString();
    if (!justSystemDiagram) {
        profileTextElements.push(
            <text 
                key={'label-min-profile-elevation'} 
                x={-5*12} 
                y={Math.min(-baselineGroundUpperElevationInches, -minProfileElevationFeet*12 + 10*12)}
                style={{ font: textFontStyle }}
                >{minLabel}</text>,
            <text 
                key={'label-max-profile-elevation'} 
                x={-5*12} 
                y={Math.max(-skyUpperElevationInches + 7*12, -maxProfileElevationFeet*12 - 3 * 12)}
                style={{ font: textFontStyle }}
                >{maxLabel}</text>,
        )
    }

    const partRanges: { leftInches: number, rightInches: number, height: number, top: number }[] = [];
    if (justSystemDiagram) {

        const pixlesX = justSystemDiagram.widthInPixels;
        const targetInchesX = justSystemDiagram.partWidthFeet * 12;
        const inchesPerPixel = targetInchesX / pixlesX;

        let height = inchesPerPixel * 200;

        // then we will break the SVG into parts
        const partWidthInches = targetInchesX;// partWidthFeet * 12;
        const parts = Math.ceil(svgWidth / partWidthInches);
        for (let i = 0; i < parts; i++) {
            const left = svgLeft + partWidthInches * i
            const leftFeet = left / 12;
            const rightFeet = leftFeet + partWidthInches / 12;
            const ep1 = elevationProfile.find(y => leftFeet < y.maxDistanceFeet && leftFeet >= y.minDistanceFeet);
            const ep2 = elevationProfile.find(y => rightFeet < y.maxDistanceFeet && rightFeet >= y.minDistanceFeet);
            let avg: number;
            if (ep1 && ep2) {
                avg = -(ep1.elevationFeet + ep2.elevationFeet) * 0.5 * 12
            }
            else if (ep1) {
                avg = -ep1.elevationFeet * 12;
            }
            else if (ep2) {
                avg = -ep2.elevationFeet * 12;
            }
            else {
                avg = 0;
            }
            const top = Math.max(avg - 0.5 * height, -skyUpperElevationInches)
            partRanges.push({
                leftInches: left,
                rightInches: partWidthInches,
                height,
                top
            })
        }
    }
    else {
        partRanges.push({
            leftInches: svgLeft,
            rightInches: svgWidth,
            height: svgHeight,
            top: -skyUpperElevationInches
        })
    }
    return (
        <div
            style={{ 
                overflowX: zoom ? 'scroll' : undefined,
                overflowY: 'hidden'
            }}
        >
        {
            partRanges.map((x,i) => {
                return (
                    <svg
                        key={`svg-part-${i}`}
                        style={{height: zoom ? 180 : undefined, maxHeight: 200 }}
                        viewBox={`
                            ${x.leftInches} ${x.top} 
                            ${x.rightInches} ${x.height}
                        `}
                    >
                        <g  
                            transform="scale(1,-1)" 
                            x={x.leftInches} y={svgTop} width={x.rightInches} height={svgHeight}
                        >
                            {skyElement}
                            {groundBaselineElement}
                            {groundElements}
                            {systemCenterElements}
                            {/* {pivotElement} */}
                            {spanElements}
                            <g
                                transform="scale(-1,1)" 
                            >
                                {aftSpanElements}
                            </g>
                            {profileElevatonElements}
                        </g>
                        <g  
                            x={x.leftInches} y={svgTop} width={x.rightInches} height={svgHeight}
                        >
                            {profileTextElements}
                        </g>
                    </svg>
                )
            })
        }
        </div>
    );
};

export default LateralDiagram;