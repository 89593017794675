import { IFEXContext } from "rdptypes/foreignExchange/interfaces";
import IAction from "rdptypes/IAction";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { calculateRateData } from "../../../../actions/NewSystemActionV2";
import IAuthState from "../../../../auth/IAuthState";
import { IDrawUpdateExtEvent_DynamicCenterPivotOptimizationResult } from "../../../../components/Map/DynamicCenterPivotOptimizationMode";
import { staticDevSettingsDbProvider } from "../../../../db/DevSettingsDbProvider";
import IDbProject from "../../../../db/IDbProject";
import { handleCreateNewOptimizedSystem } from "../../../../helpers/optimizedSystemHelpers/handleCreateNewOptimizedSystem";

export const handleDynamicCenterPivotResults = (
    event: IDrawUpdateExtEvent_DynamicCenterPivotOptimizationResult,
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    fex: IFEXContext,
    systemDefaults?: ((layoutId: string, systemId: string, authState: IAuthState, systemType: SystemTypes) => IAction[])
) => {
    const customerSettings = staticDevSettingsDbProvider.customer.get();
    const dealerPricing = undefined;
    const rateData = calculateRateData(customerSettings, fex);
    handleCreateNewOptimizedSystem({
        optimizedSystem: event.definition.optimizedSystem,
        layoutId,
        dbPrj,
        authState,
        systemDefaults,
        spanDefaults: staticDevSettingsDbProvider.span.getUseCustom()
            ? staticDevSettingsDbProvider.span.get(false)
            : undefined,
        towerDefaults: staticDevSettingsDbProvider.tower.getUseCustom()
            ? staticDevSettingsDbProvider.tower.get(false)
            : undefined,
        customerSettings,
        dealerPricing,
        rateData
    })
}