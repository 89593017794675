import { Box } from "@mui/material";
import i18next, { t } from "i18next";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { getSpansWithoutSAC } from "rdptypes/helpers/Spans";
import ISystem from "rdptypes/project/ISystem";
import * as React from "react";
import { FC } from 'react';
import { EndingRadius, PipeOutsideDiameter } from "roedata/roe_migration/SpanFunctions";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../../helpers/lengths";
import { documentStyles } from "../../styles";

interface Props {
    system: ISystem;
}

const SpanTable: FC<Props> = React.forwardRef((props) => {
    const ns = "reports.";
    const settings = React.useContext(DevSettingsCtx);

    let headerCol = [
        t(ns + "span-no"), 
        i18next.format(t('model'), 'capitalize'), 
        i18next.format(t('length'), 'capitalize'), 
        t(ns + "dist-tower"),
        t(ns + "pipe-size"), 
        t(ns + "outlet-spacing")
    ];
    let columns: string[][] = [headerCol];
    let cellStyle = {...documentStyles.cell, justifyContent: "left", padding: 4, fontSize: "0.8em"};
    
    let flangedSideSpans = getSpansWithoutSAC(props.system, SideEnum.Flanged);
    flangedSideSpans.forEach((span) => {
        let diam = PipeOutsideDiameter(span) ? new DisplayLengthUnitBuilder(PipeOutsideDiameter(span), 'inches')
            .convert(settings.dealerSettings.display.current.minorLengths)
            .appendValue(0)
            .appendString(" ")
            .appendSymbol()
            .toString() : "-";

        let spanLength = new DisplayLengthUnitBuilder(span.Length, 'feet')
            .convert(settings.dealerSettings.display.current.lengths)
            .appendValue(1)
            .appendString(" ")
            .appendShortName()
            .toString();

        let spacing = span.Spacing ? new DisplayLengthUnitBuilder(span.Spacing, 'inches')
            .convert(settings.dealerSettings.display.current.minorLengths)
            .appendValue(0)
            .appendString(" ")
            .appendSymbol()
            .toString() : "-";

        let distanceToTowerValue = EndingRadius(props.system, props.system.FlangedSide, span);
        let distanceToTower = (!span.EndBoom && distanceToTowerValue) ? new DisplayLengthUnitBuilder(distanceToTowerValue, 'feet')
            .convert(settings.dealerSettings.display.current.lengths)
            .appendValue(1)
            .appendString(" ")
            .appendShortName()
            .toString() : "-";

        if (span.EndBoom){
            columns.push(["", t("roe.end-of-system.end-boom"), spanLength, distanceToTower, diam, spacing]);
        }
        else {
            columns.push(["R" + span.SpanNumber, span.SpanType ?? "-", spanLength, distanceToTower, diam, spacing]);
        }
    });

    let rows: JSX.Element[] = [];
    let colWidthPc = 100/columns.length;
    for (let i = 0; i < headerCol.length; i++){
        let cells: JSX.Element[] = [];
        columns.forEach((col, j) => {
            cells.push(<div style={{ ...cellStyle, width: `${colWidthPc}%`}}>
                {j !== 0 && col[i]}
                {j === 0 && <strong style={{fontFamily: "Arial Black"}}>{col[i]}</strong>}
            </div>);
        });

        rows.push(<div style={documentStyles.row}>{cells}</div>);
    }

    return <Box style={{border: "1px solid black", marginBottom: 20}}>
        <Box style={documentStyles.heading}>
            <Box style={{...documentStyles.sectionHeader, fontSize: 14}}>{t("common-phrases.sltSpanInformation")}</Box>
        </Box>
        <Box style={{paddingLeft: 15, paddingRight: 15}}>
            <Box style={{...documentStyles.table, marginBottom: 0}}>
                {rows}
            </Box>
        </Box>
    </Box>
});

export default SpanTable;