import { DualSprinklerPackageControlTypes, ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule, IRuleResult } from "rdptypes/roe/IRule";
import { IsPivotingLateral } from "../../../../../roe_migration/SystemFunctions";
import { ControlLineBoosterPumpOptionOk, DualSprinklerPackageControlOk, DualSprinklerPackageOptionOk, EndGunOperationLateralModeOk } from "../../../../../roe_migration/Valids.Valids";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "dual-sprinkler-package",
            title: { "en" : "Dual Sprinkler Package", "es": "Dual Sprinkler Package"},
            position: 8.1,
            disableDealerSettings: true,
            visible: (sys: ISystemBase) => IsPivotingLateral(sys),
            components: [
                {
                    type: "complexChoice",
                    title: {"en" :"Dual Sprinkler Package (Pivot while Wet)", "es": "Dual Sprinkler Package (Pivot while Wet)"},
                    options: [
                        { 
                            title: "Enabled", 
                            values: {
                                "SprinklerProperties.DualSprinklerPackage": true
                            }
                        },
                        { 
                            title: "Disabled", 
                            values: {
                                "SprinklerProperties.DualSprinklerPackage": false,
                                "SprinklerProperties.DualSprinklerPackageControlType": undefined,
                                "SprinklerProperties.ControlLineBoosterPump": undefined,
                                "SprinklerProperties.EndGunOperationLateralMode": undefined,
                                "SprinklerProperties.EndGunOperationPivotMode": undefined
                            }
                        }
                    ]
                },
                {
                    type: "card",
                    title: "Dual Sprinkler Package",
                    visible: (sys: ISystemBase) => sys.SprinklerProperties?.DualSprinklerPackage ?? false,
                    children: [
                        {
                            type: "note",
                            title: "Use Air Compressor control type with poor water quality and/or freezing conditions"
                        }, 
                        {
                            type: "complexChoice",
                            title: {"en" :"Control Type", "es": "Control Type"},
                            options: [
                                { 
                                    title: "Water Controlled", 
                                    values: {
                                        "SprinklerProperties.DualSprinklerPackageControlType": DualSprinklerPackageControlTypes.Water,
                                        "SprinklerProperties.ControlLineBoosterPump": false
                                    }
                                },
                                { 
                                    title: "Water Controlled with Control Line Booster Pump", 
                                    values: {
                                        "SprinklerProperties.DualSprinklerPackageControlType": DualSprinklerPackageControlTypes.Water,
                                        "SprinklerProperties.ControlLineBoosterPump": true
                                    }
                                },
                                { 
                                    title: "Air Compressor", 
                                    values: {
                                        "SprinklerProperties.DualSprinklerPackageControlType": DualSprinklerPackageControlTypes.Air,
                                        "SprinklerProperties.ControlLineBoosterPump": false
                                    }
                                }
                            ]
                        },
                        {
                            type: "complexChoice",
                            title: {"en" :"End Gun Operation Mode", "es": "End Gun Operation Mode"},
                            options: [
                                { 
                                    title: "Lateral", 
                                    values: {
                                        "SprinklerProperties.EndGunOperationLateralMode": true,
                                        "SprinklerProperties.EndGunOperationPivotMode": false 
                                    }
                                },
                                { 
                                    title: "Pivot", 
                                    values: {
                                        "SprinklerProperties.EndGunOperationLateralMode": false,
                                        "SprinklerProperties.EndGunOperationPivotMode":  true
                                    }
                                },
                                { 
                                    title: "Lateral and Pivot", 
                                    values: {
                                        "SprinklerProperties.EndGunOperationLateralMode": true,
                                        "SprinklerProperties.EndGunOperationPivotMode": true 
                                    }
                                }
                            ],
                            validateBeforeOpen: true,
                            includeOnlyValidationRules: []
                        },
                    ]
                }
            ],
        },
    ],
    rules: [
        {
            apply: (system, user, grower, quick) => {                
                if (!DualSprinklerPackageOptionOk(system.SprinklerProperties?.DualSprinklerPackage, {
                    IsPivotingLateral: IsPivotingLateral(system),
                })) {
                    return [
                        {
                            id: "SprinklersConfigForm.SprinklerProperties.DualSprinklerPackageOptionOk",
                            description: "Only pivoting lateral systems can have a dual sprinkler package.",
                            severity: "error",
                            targets: [
                                "SprinklerProperties.DualSprinklerPackage"
                            ]
                        }
                    ]
                }
                else {
                    return [];
                }
            }
        },
        {
            apply: (system, user, grower, quick) => {
                const results: IRuleResult[] = [];
                if (system.SprinklerProperties?.DualSprinklerPackage) {
                    if (!DualSprinklerPackageControlOk(system.SprinklerProperties.DualSprinklerPackageControlType, {
                        IsPivotingLateral: IsPivotingLateral(system),
                        DualSprinklerPackageOptionChecked: system.SprinklerProperties.DualSprinklerPackage,
                    })){
                        results.push(
                            {
                                id: "SprinklersConfigForm.SprinklerProperties.DualSprinklerPackageControlOk",
                                description: "Only pivoting lateral systems can have a dual sprinkler package.",
                                severity: "error",
                                targets: [
                                    "SprinklerProperties.DualSprinklerPackageControlType"
                                ]
                            }
                        );
                    }
                }
                return results;
            }
        },
        {
            apply: (system, user, grower, quick) => {
                // NOTE: hasEndGun is always true in this part of the ROE code
                const hasEndGun = true; //HasEndGun(system);
                const results: IRuleResult[] = [];        
                if (!EndGunOperationLateralModeOk(system.SprinklerProperties?.EndGunOperationLateralMode, {
                    HasDualSprinklerPackage: system.SprinklerProperties.DualSprinklerPackage,
                    HasEndGun: hasEndGun,
                    IsPivotingLateral: IsPivotingLateral(system),
                })) {
                    results.push({
                        id: "SprinklersConfigForm.SprinklerProperties.EndGunOperationLateralModeOk.LateralMode",
                        description: "End gun operational mode is invalid - system must be a pivoting lateral with a dual sprinkler package and have an end gun defined.",
                        severity: "error",
                        targets: [
                            "SprinklerProperties.EndGunOperationLateralMode"
                        ]
                    });
                }
                
        
                if (!EndGunOperationLateralModeOk(system.SprinklerProperties?.EndGunOperationPivotMode, {
                    HasDualSprinklerPackage: system.SprinklerProperties.DualSprinklerPackage,
                    HasEndGun: hasEndGun,
                    IsPivotingLateral: IsPivotingLateral(system),
                })) {
                    results.push({
                        id: "SprinklersConfigForm.SprinklerProperties.EndGunOperationLateralModeOk.PivotMode",
                        description: "End gun operational mode is invalid - system must be a pivoting lateral with a dual sprinkler package and have an end gun defined.",
                        severity: "error",
                        targets: [
                            "SprinklerProperties.EndGunOperationPivotMode"
                        ]
                    });
                }
                return results;
            }
        },
        {
            apply: (system, user, grower, quick) => {
                const results: IRuleResult[] = [];        
                if (!ControlLineBoosterPumpOptionOk(system.SprinklerProperties?.ControlLineBoosterPump, {
                    IsPivotingLateral: IsPivotingLateral(system),
                    DualSprinklerPackageOptionChecked: system.SprinklerProperties.DualSprinklerPackage,
                    DualSprinklerPackageControlType: system.SprinklerProperties.DualSprinklerPackageControlType,
                })){
                    results.push({
                        id: "SprinklersConfigForm.SprinklerProperties.ControlLineBoosterPumpOptionOk",
                        description: "A sprinkler control line booster pump can only be used on a pivoting lateral with a water controlled dual sprinkler package.",
                        severity: "error",
                        targets: [
                            "SprinklerProperties.ControlLineBoosterPump"
                        ]
                    });
                }
                return results;
            }
        }
    ] as IRule[],
};