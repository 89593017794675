import { InputAdornment, TableCell, TableRow, TextField } from "@mui/material";
import { IFEXRate } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { createLocalCell, createUsdCell } from "./PricingTableRow";

interface Props {
    savePercentage?: (percentage: number) => void;
    saveUsdCents?: (usdCents: number) => void;
    priceComputation: "Price" | "Percent";
    percentage: number | undefined;
    usdCents: number;
    label: string;
    readonly?: boolean;

    localCurrency?: string;
    rate?: IFEXRate;
    valueCurrency: string;
};

const PercentageTableRow: FC<Props> = (props) => {
    const {savePercentage, percentage, label, readonly, usdCents, priceComputation, saveUsdCents} = props;

    const [stateDollar, setStateDollar] = useState(usdCents ? usdCents/100 : 0);//have to use state here because of dollar formatting component
    const [statePercentage, setStatePercentage] = useState(percentage);

    useEffect(() => {
        setStateDollar(usdCents ? usdCents/100 : 0);
    }, [usdCents]);

    useEffect(() => {
        setStatePercentage(percentage);
    }, [percentage]);

    const localCurrency = props.localCurrency ?? "USD";
    const displayExtraColumn = localCurrency !== "USD";
    
    const onBlurCb = () => {
        if (!readonly && saveUsdCents) {
            const price = isNaN(stateDollar) ? 0 : stateDollar;
            saveUsdCents(price * 100);
            setStateDollar(price);
        }
    }

    return (
        <>
            <TableRow>
                <TableCell sx={{textAlign: "right", padding: "3px 16px"}}>{label}</TableCell>
                {
                    priceComputation === "Percent" && (
                        <TableCell sx={{textAlign: "center", padding: "3px 16px", width: "140px", minWidth: "60px"}}>
                            <TextField size="small" InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }} value={statePercentage} disabled={readonly} type="number" onChange={(e) => {
                                if (!readonly){
                                    let val = parseFloat(e.target.value);
                                    if (isNaN(val)) return;
                                    setStatePercentage(val);
                                }
                            }} onBlur={(e) => {
                                    if (!readonly && savePercentage){
                                        let val = parseFloat(e.target.value);
                                        if (isNaN(val)) return;
                                        setStatePercentage(val);
                                        savePercentage(val);
                                    }
                                }}
                            />
                        </TableCell>
                    )
                }
                {
                    createUsdCell(
                        readonly || priceComputation === "Percent" || !saveUsdCents,
                        props.valueCurrency,
                        stateDollar,
                        setStateDollar,
                        onBlurCb,
                        props.rate?.exchangeRate_1USDToSymbol,
                        priceComputation === "Percent" ? 1 : 2
                    )
                }
                {
                    displayExtraColumn && (
                        createLocalCell(
                            readonly || priceComputation === "Percent" || !saveUsdCents, 
                            props.valueCurrency, 
                            props.localCurrency, 
                            stateDollar, 
                            setStateDollar, 
                            onBlurCb, 
                            props.rate?.exchangeRate_1USDToSymbol,
                            priceComputation === "Percent" ? 1 : 2
                        )
                    )
                }
            </TableRow>
        </>
    );
};

export default PercentageTableRow;