import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import ISystem from "rdptypes/project/ISystem";
import * as React from "react";
import { useContext } from "react";
import { FEXContext } from "../../../../../foreignExchange";
import { pricingHelper } from "../../../../../helpers/pricingHelper";
import DealerDataCtx from "../../../../../userData/DealerDataCtx";

interface Props {
    system: ISystem;
    setCurrency: (currency: string) => void;
    currency: string;
}

export default function CurrencyHeader(props: Props) {
    const fex = useContext(FEXContext);
    const user = useContext(DealerDataCtx);
    const ph = pricingHelper({ system: props.system, fex, user });
    if (!ph.localCurrency?.fexAvailable) {
        return null;
    }
    const localCurrencySymbol = ph.localCurrency.symbol;

    const isUnkownCurrency = !["USD", localCurrencySymbol].includes(props.currency);
    if (isUnkownCurrency) {
        props.setCurrency("USD");
    }

    return (
        <Stack direction={'row'} marginBottom={"5px"} minHeight={"40px"}>
            <Typography variant="caption" flexGrow={1}>
                {ph.helpers.generateFexWarningNoteLines(props.currency !== "USD").join(" ") + "."}
            </Typography>
            <ToggleButtonGroup
                color="primary"
                value={props.currency}
                exclusive
                onChange={(e, v) => {
                    e.stopPropagation();
                    props.setCurrency(v)
                }}
                size="small"
                sx={{ display: 'flex', justifyContent: 'flex-end'}}
            >
                <ToggleButton value={"USD"}>USD</ToggleButton>
                <ToggleButton value={localCurrencySymbol}>{localCurrencySymbol}</ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    );
}