import { Box } from "@mui/material";
import { green, red } from "@mui/material/colors";
import * as turf from "@turf/turf";
import { Point } from "@turf/turf";
import i18next, { t } from "i18next";
import FeatureHelpers from "rdptypes/geometry/helpers/features";
import { IEndgunOnOffs } from "rdptypes/project/IEndgunInformation";
import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from 'react';
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { DisplayAreaUnitBuilder } from "../../../helpers/areas";
import { squareMetersToAcres } from "../../DocumentGenerationHelpers";
import { documentStyles } from "../../styles";

interface Props {
    endgunInformation: {
        center: Point;
        throwStartFeet: number;
        throwEndFeet: number;
        onOffs: IEndgunOnOffs[];
        type: EndGunTypes;
    }[];
    primary: boolean;
}

const EndgunInformationTable: FC<Props> = React.forwardRef((props) => {
    const ns = "reports.";
    const settings = React.useContext(DevSettingsCtx);

    let colWidth = 100/4;
    let oneColWidth = colWidth + "%";
    let twoColWidth = colWidth * 2 + "%";

    let fontSize = "0.8em";
    let cellStyle = {...documentStyles.cell, justifyContent: "left", padding: 4, width: oneColWidth, fontSize};

    let rows: JSX.Element [] = [];

    props.endgunInformation.forEach((gun) => {
        gun.onOffs.forEach((onOff, i) => {
            let cells: JSX.Element[] = [];

            cells.push(<div style={{ ...cellStyle}}>{i18next.format(t("area"), 'capitalize')} {(i + 1)}</div>);
            cells.push(<div style={{ ...cellStyle, justifyContent: "center" }}>{onOff.startBearing.toFixed(2)}</div>);
            cells.push(<div style={{ ...cellStyle, justifyContent: "center" }}>{onOff.endBearing.toFixed(2)}</div>);

            const endGunPoly = FeatureHelpers.GetAnnulusSectorDrawFeature(
                gun.center,
                gun.throwStartFeet,
                gun.throwEndFeet,
                onOff.startBearing,
                onOff.endBearing,
                null,
                { degreeIncrement: 0.1 }
            );

            let area = new DisplayAreaUnitBuilder(squareMetersToAcres(turf.area(endGunPoly.geometry)), 'acres')
                .convert(settings.dealerSettings.display.current.areas)
                .appendValue(2)
                .appendString(" ")
                .appendFullName()
                .toString();

            cells.push(<div style={{ ...cellStyle, justifyContent: "center" }}>{area}</div>);

            rows.push(<div style={{...documentStyles.row}}>
                {cells}
            </div>);
        });
    });

    if (rows.length === 0){
        rows.push(<div style={{...documentStyles.row}}>
            <div style={{ ...cellStyle, width: "100%", justifyContent:"center"}}>{i18next.format(t('no-information-available'), 'capitalize')}</div>
        </div>);
    }
    
    let title = props.primary ? i18next.format(t(ns + 'primary-end-gun-settings'), 'capitalize-each') : i18next.format(t(ns + 'secondary-end-gun-settings'), 'capitalize-each');

    return <Box style={{border: "1px solid black", marginBottom: 20}}>
        <Box style={documentStyles.heading}>
            <Box style={{...documentStyles.sectionHeader, fontSize: 14}}>{title}</Box>
        </Box>
        <Box style={{paddingLeft: 15, paddingRight: 15}}>
            <Box style={{...documentStyles.table, marginBottom: 0}}>
                <div style={{...documentStyles.row}}>
                    <div style={{ ...cellStyle}}><strong style={{fontFamily: "Arial Black"}}>{props.endgunInformation[0].type}</strong></div>
                    <div style={{display: "flex", flexDirection: "column",  width: twoColWidth}}>
                        <div style={{ ...cellStyle, width: "100%", justifyContent: "center"}}><strong style={{fontFamily: "Arial Black"}}>{t(ns + 'degrees-from-north')}</strong></div>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div style={{ ...cellStyle, backgroundColor: green[300], justifyContent: "center", width: "50%"}}><strong style={{fontFamily: "Arial Black"}}>{i18next.format(t('start'), 'capitalize')}</strong></div>
                            <div style={{ ...cellStyle, backgroundColor: red[300], justifyContent: "center", width: "50%"}}><strong style={{fontFamily: "Arial Black"}}>{i18next.format(t('stop'), 'capitalize')}</strong></div>
                        </div>
                    </div>
                    <div style={{ ...cellStyle, justifyContent: "center"}}><strong style={{fontFamily: "Arial Black"}}>{i18next.format(t('area'), 'capitalize')}</strong></div>
                </div>
                {rows}
            </Box>
        </Box>
    </Box>
});

export default EndgunInformationTable;