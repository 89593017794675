import { booleanEqual } from "@turf/turf";
import IAction from "rdptypes/IAction";
import { createNewMultiAction } from "../../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import IAuthState from "../../../../auth/IAuthState";
import { IDrawUpdateExtEvent_CenterPivotUpdated } from "../../../../components/Map";
import { generateChart } from "../../../../components/roe/componentRenderers/Sprinklers/SprinklerChart/SprinklerChart";
import IDbProject from "../../../../db/IDbProject";

export const handleCenterPivotUpdated = (
    event: IDrawUpdateExtEvent_CenterPivotUpdated,
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState
) => {
    const { systemId } = event.definition;
    const updatedSystem = event.updatedSystem;
    const actions: IAction[] = [];

    const project = dbPrj.state;
    const layout = project.layouts[layoutId!];
    const system = layout.systems[systemId!];

    if (!booleanEqual(updatedSystem.centerPivot!.point, system.centerPivot!.point)) {
        console.log("Updating system centerPivot point");
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "centerPivot.point",
            updatedSystem.centerPivot!.point,
            authState
        ))
    }
    if (updatedSystem.Circle!.CenterPivot!.clockwiseCompassHeadingStart !== system.Circle!.CenterPivot!.clockwiseCompassHeadingStart) {
        console.log("Updating system start bearing");
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "Circle.CenterPivot.clockwiseCompassHeadingStart",
            updatedSystem.Circle!.CenterPivot!.clockwiseCompassHeadingStart,
            authState
        ))
    }
    if (updatedSystem.Circle!.CenterPivot!.clockwiseCompassHeadingEnd !== system.Circle!.CenterPivot!.clockwiseCompassHeadingEnd) {
        console.log("Updating system end bearing");
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "Circle.CenterPivot.clockwiseCompassHeadingEnd",
            updatedSystem.Circle!.CenterPivot!.clockwiseCompassHeadingEnd,
            authState
        ))
    }
    const shouldUpdateSpanTowers = system.FlangedSide.Tower.some((systemTower, idx) => {
        const updatedTower = updatedSystem.FlangedSide.Tower[idx] || systemTower;
        const systemSpan = system.FlangedSide.Span[idx];
        const updatedSpan = updatedSystem.FlangedSide.Span[idx] || systemSpan;
        if (systemTower.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees !== 
            updatedTower.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees
        ) {
            return true;
        }
        if (systemTower.clockwiseWrapAngleRelativeToPreviousSpanDegrees !== 
            updatedTower.clockwiseWrapAngleRelativeToPreviousSpanDegrees
        ) {
            return true;
        }
        if (systemSpan.Disconnecting && 
            systemSpan.dropSpanStartRelativeToPreviousSpanStart ! ==
            updatedSpan.dropSpanStartRelativeToPreviousSpanStart
        ) {
            return true;
        }
        if (systemSpan.Disconnecting && 
            systemSpan.dropSpanEndRelativeToPreviousSpanEnd ! ==
            updatedSpan.dropSpanEndRelativeToPreviousSpanEnd
        ) {
            return true;
        }
        return false;
    });
    if (shouldUpdateSpanTowers) {
        console.log("Updating system span towers");
        // Note: these updated spans do not change length, so no switching of flanged/flex should need to occur
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "FlangedSide.Span",
            updatedSystem.FlangedSide.Span,
            authState
        ));
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "FlangedSide.Tower",
            updatedSystem.FlangedSide.Tower,
            authState
        ));
    }
    if (actions.length) {
        // clear the sac optimization result
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "sacOptimizerResult",
            undefined,
            authState
        ));
        actions.push(...generateChart(authState, dbPrj, layoutId, systemId, updatedSystem));
        dbPrj.pushAction(
            createNewMultiAction(actions, authState)
        )
    }
}