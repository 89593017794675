import IUserData from "rdptypes/IUserData";
import { IFEXRate, ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import IRoeData from "rdptypes/roe/IRoeData";
import { getDiscountConfigFromUserData } from "roedata/ProposalHelpers";
import { getGroupedPartsForSystem, proposalSectionIsAncillary } from "roedata/QuoteHelpers";
import PriceCalculator from "roedata/roe_migration/PriceCalculator";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import IAuthState from "../../auth/IAuthState";
import IDbProject from "../../db/IDbProject";
import { CustomerPriceAmmendmentType, IPricingHelperPriceComputation } from "./priceComputation";

interface ISectionPart {
    partNumber: string;
    description: string;
    qty: number;
    unitPriceUsdCents: number;
    totalPriceUsdCents: number;
}
export interface IPricingHelperSystemPricing {
    totalListPriceUsdCents: number;
    totalListPriceUsdCentsMinusAncillary: number;
    systemPriceUsdCents: number;
    systemTaxUsdCents: number;
    sectionIdToPartsMapping: {
        [sectionId: string] : {
            parts: ISectionPart[];
        }
    }
    anyPriceIsNaN: boolean;
    isTaxable: boolean;
    saveIsTaxable: (value: boolean, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => void;
    discountPcDecimal: number;
}
export const calculateSystemPricing = (
    system: ISystemBase, roeData: IRoeData, user: IUserData, 
    salesTaxMultiplier: number, priceComputation: IPricingHelperPriceComputation,
    rate?: IFEXRate
): IPricingHelperSystemPricing => {
    const isTaxable = system.QuoteProperties?.ProposalInformation.pricing.system.isTaxable || false
    const result: IPricingHelperSystemPricing = {
        totalListPriceUsdCents: 0,
        totalListPriceUsdCentsMinusAncillary: 0,
        systemPriceUsdCents: 0,
        anyPriceIsNaN: false,
        sectionIdToPartsMapping: {},
        isTaxable,
        saveIsTaxable(value, dbPrj, layoutId, systemId, authState) {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "QuoteProperties.ProposalInformation.pricing.system.isTaxable",
                value,
                authState));
        },
        systemTaxUsdCents: 0,
        discountPcDecimal: 0
    }
    const partsGroupedBySection = getGroupedPartsForSystem(system).partSections;

    const quote = new QuoteClass(system);
    for (let sectionId in partsGroupedBySection){
        result.sectionIdToPartsMapping[sectionId] = {
            parts: []
        }
        partsGroupedBySection[sectionId].forEach((p) => {
            const priceListPart = user.priceList[p.partNumber];
            if (!priceListPart) {
                // NOTE: Previously the live proposal would ignore these parts - now it will not - does this matter?
                console.log(`Part number ${p.partNumber} not in price list`);
            }
            const partsCatalogPart = roeData.partsCatalog[p.partNumber];
            if (!partsCatalogPart) {
                // NOTE: Previously the live proposal would ignore these parts - now it will not - does this matter?
                console.log(`Part number ${p.partNumber} not in catalog`);
            }
            const unitPriceUsdCents = priceListPart?.unitPriceUsdCents ?? NaN;
            const sectionPart: ISectionPart = {
                partNumber: p.partNumber,
                description: partsCatalogPart?.mapicsDescription ?? "Unknown Part",
                qty: p.qty,
                unitPriceUsdCents: unitPriceUsdCents,
                totalPriceUsdCents: p.qty * unitPriceUsdCents
            }
            result.sectionIdToPartsMapping[sectionId].parts.push(sectionPart);

            if (!isNaN(sectionPart.totalPriceUsdCents)){
                result.totalListPriceUsdCents += sectionPart.totalPriceUsdCents;
                if (!proposalSectionIsAncillary(parseInt(sectionId))){
                    result.totalListPriceUsdCentsMinusAncillary += sectionPart.totalPriceUsdCents;
                }
            }
            else {
                result.anyPriceIsNaN = true;
            }
        });
    }

    if (system?.MainlineValveOptions?.Valves?.MainlineValve){
        const sectionId = "14";
        if (system.MainlineValveOptions.Valves.MainlineValve.length && !(sectionId in result.sectionIdToPartsMapping)) {
            result.sectionIdToPartsMapping[sectionId] = {
                parts: []
            }
        }
        system.MainlineValveOptions.Valves.MainlineValve.forEach((valve) => {
            let info = quote.MainlineValvesClass.GetMainlineValveInfo(valve, user.priceList);
            let price = info.Price();
            
            const sectionPart: ISectionPart = {
                partNumber: info.PartNumber(),
                description: info.Description,
                qty: info.Quantity,
                unitPriceUsdCents: price / info.Quantity,
                totalPriceUsdCents: price
            }
            result.sectionIdToPartsMapping[sectionId].parts.push(sectionPart);

            if (!isNaN(sectionPart.totalPriceUsdCents)){
                result.totalListPriceUsdCents += price;
            }
            else {
                result.anyPriceIsNaN = true;
            }
        });
    }

    // It was decided in a meeting with Reinke on 28/09/2024 that only the dealer should absorb
    // the price of swing arm path creation
    let swingArmPathPriceCents = 0;
    if (HasSwingArmCorner(system)){
        swingArmPathPriceCents = quote.SwingArmClass.SpecialOrderPriceCents();
    }

    let dc = getDiscountConfigFromUserData(user, system);
    let pc = new PriceCalculator(quote, result.totalListPriceUsdCentsMinusAncillary, dc, partsGroupedBySection, user.priceList);

    let systemPrice: number = result.totalListPriceUsdCents;
    if (priceComputation.value > 0) {
        switch (priceComputation.customerPriceAmmendmentType) {
            case CustomerPriceAmmendmentType.listPriceDiscountPercent: {
                systemPrice = result.totalListPriceUsdCents - (priceComputation.value/100) * result.totalListPriceUsdCents;
                break;
            }
            case CustomerPriceAmmendmentType.markupOverDealerCostPercent: {
                const dealerPriceExcSwingArm = pc.getTotalDealerPriceCents() - swingArmPathPriceCents;
                systemPrice = dealerPriceExcSwingArm + (dealerPriceExcSwingArm * (priceComputation.value/100)) 
                break;
            }
            case CustomerPriceAmmendmentType.markupOverDealerCostDollar: {
                let markupUsdDollars = priceComputation.currency === "USD"
                    ? priceComputation.value
                    : (rate && rate.exchangeRate_1USDToSymbol)
                        ? priceComputation.value / rate.exchangeRate_1USDToSymbol
                        : NaN;
                const dealerPriceExcSwingArm = pc.getTotalDealerPriceCents() - swingArmPathPriceCents;
                systemPrice = dealerPriceExcSwingArm + (markupUsdDollars * 100);
                break;
            }
            case CustomerPriceAmmendmentType.quotedPriceDollar: {
                let quotePriceUsdDollars = priceComputation.currency === "USD"
                    ? priceComputation.value
                    : (rate && rate.exchangeRate_1USDToSymbol)
                        ? priceComputation.value / rate.exchangeRate_1USDToSymbol
                        : NaN;
                systemPrice = quotePriceUsdDollars * 100;
                break;
            }
            default:
                systemPrice = result.totalListPriceUsdCents;
                break;
        }
    }
    result.systemPriceUsdCents = systemPrice;
    result.systemTaxUsdCents = isTaxable ? systemPrice * salesTaxMultiplier : 0;

    result.discountPcDecimal = result.totalListPriceUsdCents !== 0
        ? result.systemPriceUsdCents / result.totalListPriceUsdCents
        : 1;

    return result;
}