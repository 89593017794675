/* Use the class function generateSimpleSelectGeoJSON() to get the System GeoJSON
 * The result will be an array of GeoJSON Features
 * Each item will have the properties passed in the constructor
 */

import { Feature, Geometry, GeometryCollection, LineString, MultiPolygon, Point, Polygon, Position, Properties, destination, feature, lineString, multiLineString, polygon } from "@turf/turf";
import { SideEnum } from "../../../helpers/SideEnum";
import * as spanf from "../../../helpers/SpanFunctions.part";
import { getSpansWithoutEndOfSystem, getSpansWithoutSAC } from "../../../helpers/Spans";
import { IEndgunInformation } from "../../../project/IEndgunInformation";
import ISystem from "../../../project/ISystem";
import { SystemTypes } from "../../../project/ISystemBase.AutoGenerated";
import FeatureHelpers from "../../helpers/features";
import { customUnion } from "../../helpers/turf";
import { ISpanSegment, calculateSpanSegments } from "./systemCoordinates";

interface ISacResultFeatures {
    irrigatedArea: Feature<Polygon>[];
    endGunAreas: Feature<Polygon, { isPrimary: boolean } & { [key: string]: any }>[];
    guidanceWheelTracks: Feature<LineString>[];
    nonGuidanceWheelTracks: Feature<LineString>[];
    endBoom: Feature<LineString>[];
}

interface ICenterPivotGeometryHelperBaseArgs<T extends Properties> {
    system: ISystem;
    properties: T;
}

export default class CenterPivotGeometryHelperBase<T extends Properties> {
    protected system: ISystem;
    protected centerPoint: Point = {type: "Point", coordinates: [0,0]};
    protected _endGunOnOffs: IEndgunInformation[] | undefined = undefined;
    private _systemRadius: number | undefined = undefined;
    private _wheelTrackRadii: number[] | undefined = undefined;
    private _cached_DrawFeaturesSimple:Feature<Geometry | GeometryCollection, Properties>[] | undefined = undefined;
    private _properties: T;
    
    constructor(args: ICenterPivotGeometryHelperBaseArgs<T>) {

        const { system } = args;
        this.system = system;
        if (!this.system || this.system.SystemProperties.SystemType !== SystemTypes.CenterPivot) {
            throw new Error("This system is not a center pivot");
        }

        if (this.system.centerPivot){
            this.centerPoint = this.system.centerPivot.point;
        }
        this._properties = args.properties;
    }

    // public accessors:
    public get propertiesForAll(): T {  
        return this._properties;
    }
    public get center() { return this.centerPoint; }

    // protected accessors:
    protected get wheelTrackRadii(): number[] {
        if (this._wheelTrackRadii === undefined) {
            
            this._wheelTrackRadii = [];
            for (const span of getSpansWithoutEndOfSystem(this.system, SideEnum.Flanged)) {
                if (!Number.isFinite(spanf.LengthInFeet(this.system.FlangedSide, span))) {
                    // Don't draw spans where the length hasn't yet been selected
                    continue;
                }
                this._wheelTrackRadii.push(spanf.EndingRadius(this.system, this.system.FlangedSide, span));
            }
        }
        return this._wheelTrackRadii;
    }
    protected get nonSacEndGuns(): IEndgunInformation[] {
        if (this._endGunOnOffs === undefined) {
            // TODO: Check that the following correctly defines an end gun. The end
            // guns created by the SAC use a diffent method to define the throw angle.
            // Also, bring the sac endgun calculation into this getter
            if (this.system.sacOptimizerResult) {
                // then end guns are drawn in the getDrawFeatures body:
                this._endGunOnOffs = []
            }
            else if (this.system.endGuns?.centerPivotOnOffs) {
                this._endGunOnOffs = this.system.endGuns?.centerPivotOnOffs;
            }
            else {
                this._endGunOnOffs = [];
            }
        }
        return this._endGunOnOffs!;
    }
    protected get systemRadiusFeetExcludingEos(): number {
        if (this._systemRadius === undefined) {
            if (this.wheelTrackRadii.length === 0) {
                this._systemRadius = 0;
            }
            else {
                this._systemRadius = this.wheelTrackRadii[this.wheelTrackRadii.length - 1];
            }
        }
        return this._systemRadius;
    }

    // protected methods:
    protected getSacAreaPolygon(includeProperties = true): ISacResultFeatures {
        // Note: Calculating the SAC every time was slowing the UI,
        // instead, the SAC worker also calculates and stores the geometry
        // along with the SAC result
        const sacAreaPolygons: ISacResultFeatures = {
            irrigatedArea: [],
            endGunAreas: [],
            guidanceWheelTracks: [],
            nonGuidanceWheelTracks: [],
            endBoom: []
        }
        if (this.system.sacOptimizerResult?.success) {
            sacAreaPolygons.irrigatedArea = this.system.sacOptimizerResult.geometry.irrigatedArea.map(a => feature(a));
            for (let i = 0; i < this.system.sacOptimizerResult.geometry.endGunAreas.length; i++) {
                const ega = this.system.sacOptimizerResult.geometry.endGunAreas[i];
                sacAreaPolygons.endGunAreas.push(
                    ...ega.map(a => feature(a, { isPrimary: i === 0 }))
                )    
            }
            sacAreaPolygons.guidanceWheelTracks = this.system.sacOptimizerResult.geometry.wheelTracksGuidance.map(a => feature(a));
            sacAreaPolygons.nonGuidanceWheelTracks = this.system.sacOptimizerResult.geometry.wheelTracksNonGuidance.map(a => feature(a));
            sacAreaPolygons.endBoom = this.system.sacOptimizerResult.geometry.endBoomTrack.map(a => feature(a));
        }

        if (includeProperties) {
            sacAreaPolygons.irrigatedArea.forEach(f => {
                f.properties = {
                    rdpFeatureType: "system/irrigatedArea-sac",
                    ...this.propertiesForAll
                }
            })
            sacAreaPolygons.endGunAreas.forEach(f => {
                f.properties = {
                    ...f.properties,
                    rdpFeatureType: "system/irrigatedArea-endGun",
                    ...this.propertiesForAll
                }
            })
            sacAreaPolygons.guidanceWheelTracks.forEach(f => {
                f.properties = {
                    rdpFeatureType: "system/wheelTrack",
                    ...this.propertiesForAll
                }
            })
            sacAreaPolygons.nonGuidanceWheelTracks.forEach(f => {
                f.properties = {
                    rdpFeatureType: "system/wheelTrack",
                    ...this.propertiesForAll
                }
            })
            sacAreaPolygons.endBoom.forEach(f => {
                f.properties = {
                    rdpFeatureType: "system/sacEndBoom",
                    ...this.propertiesForAll
                }
            })
        }
        // console.log("sacAreaPolygons", featureCollection(sacAreaPolygons.endGunAreas.map(e => {
        //     const a = {...e};
        //     a.properties = {}
        //     return a
        // })));
        // console.log("sacAreaLines", featureCollection(sacAreaPolygons.endGunAreas.flatMap(e => {
        //     const l = e.geometry.coordinates[0].length - 1;
        //     const lines: Feature<LineString>[] = [];
        //     for (let i = 0; i < l / 2; i++) {
        //         lines.push(
        //             lineString([
        //                 e.geometry.coordinates[0][i],
        //                 e.geometry.coordinates[0][l - i],
        //             ])
        //         )
        //     }
        //     return lines;
        // })))
        return sacAreaPolygons;
    }
    
    // public methods
    public generateSimpleSelectGeoJSON() {

        if (this._cached_DrawFeaturesSimple) return this._cached_DrawFeaturesSimple;

        const features: Feature[] = [];

        const irrigatedAreas: Polygon[] = [];
        const wheelTracks: { label?: string; geometry: LineString; }[] = [];
        const sacIrrigatedAreas: Polygon[] = [];
        const endGunAreas: Polygon[] = [];
        const dropIrrigatedAreas: Polygon[] = [];

        // Sac regions
        const sacPolygons = this.getSacAreaPolygon();
        sacPolygons.irrigatedArea.forEach(p => sacIrrigatedAreas.push(p.geometry))
        sacPolygons.guidanceWheelTracks.forEach(p => wheelTracks.push({geometry: p.geometry }));
        sacPolygons.nonGuidanceWheelTracks.forEach(p => wheelTracks.push({geometry: p.geometry }));
        sacPolygons.endGunAreas.forEach(p => endGunAreas.push(p.geometry));
        // sacPolygons.endBoom.forEach(p => features.push(p));

        // Pivot center (Point)
        features.push(
            FeatureHelpers.GetPointDrawFeature(
                this.center,
                {
                    rdpFeatureType: "system/pivotCenter",
                    ...this.propertiesForAll
                }
            )
        )

        // If the system has no radius, we only want to show the pivot center
        if (this.systemRadiusFeetExcludingEos === 0) {
            this._cached_DrawFeaturesSimple = features;
            return this._cached_DrawFeaturesSimple;
        }
        
        for (let i = 0; i < getSpansWithoutSAC(this.system, SideEnum.Flanged).length; i++) {
            let spanSegments: ISpanSegment[];
            try {
                spanSegments = calculateSpanSegments(this.system, i).segments;
            } 
            catch (e) {
                console.log("error", e)
                continue;
            }
            for (let spanSegmentIndex = 0; spanSegmentIndex < spanSegments.length; spanSegmentIndex++) {
                const spanSegment = spanSegments[spanSegmentIndex];
                const outerRing: Position[] = [];
                const innerRing: Position[] = [];
                if (spanSegment.innerRadiusFeet === 0) {
                    innerRing.push(spanSegment.center.coordinates)
                }
                for (
                    let iTheta = 0; 
                    iTheta <= spanSegment.sweepAngle; 
                    iTheta = (iTheta !== spanSegment.sweepAngle && iTheta + 1 > spanSegment.sweepAngle) ? spanSegment.sweepAngle : iTheta + 1
                ) {
                    if (spanSegment.innerRadiusFeet !== 0) {
                        innerRing.push(
                            destination(
                                spanSegment.center,
                                spanSegment.innerRadiusFeet,
                                spanSegment.startBearing + iTheta,
                                { units: 'feet' }
                            ).geometry.coordinates
                        )
                    }
                    outerRing.push(
                        destination(
                            spanSegment.center,
                            spanSegment.outerRadiusFeet,
                            spanSegment.startBearing + iTheta,
                            { units: 'feet' }
                        ).geometry.coordinates
                    )
                }
                const ring = [
                    ...innerRing,
                    ...[ ...outerRing ].reverse(),
                    innerRing[0]
                ];
                if (ring.length >= 4) {
                    if (spanSegments.some(x => x.hasAddedDropSpan)) {
                        dropIrrigatedAreas.push(polygon([ring]).geometry);
                    }
                    else {
                        irrigatedAreas.push(polygon([ring]).geometry);
                    }
                }
                if (outerRing.length > 1) {
                    const wtf = lineString(outerRing);
                    wheelTracks.push({
                        geometry: wtf.geometry,
                        label: (spanSegmentIndex === 0) ? (i + 1).toString() : undefined // only add span label if the first segment
                    });
                }
            }
        }

        // end gun(s):
        // Note: Eng gun only added if SAC not added
        for (const eg of this.nonSacEndGuns) {
            for (const onOff of eg.onOffs) {
                endGunAreas.push(FeatureHelpers.GetAnnulusSectorDrawFeature(
                    eg.center,
                    eg.throwStartFeet,
                    eg.throwEndFeet,
                    onOff.startBearing,
                    onOff.endBearing,
                    {
                        rdpFeatureType: "system/irrigatedArea-endGun",
                        ...this.propertiesForAll
                    },
                    {
                        degreeIncrement: 1,
                        units: 'feet'
                    }
                ).geometry)
            }
        }

        if (irrigatedAreas.length) {
            let u: Feature<Polygon | MultiPolygon> = feature(irrigatedAreas[0]);
            for (const p of irrigatedAreas.slice(1)) {
                u = customUnion(u, p);
            }
            u.properties = {
                rdpFeatureType: "system/irrigatedArea",
                ...this.propertiesForAll
            }
            features.push(u);
        }
        if (wheelTracks.length) {
            const mf = multiLineString(
                wheelTracks.map(x => x.geometry.coordinates), 
                {
                    rdpFeatureType: "system/wheelTrack",
                    ...this.propertiesForAll,
                    rdpSpanNumberLabels: wheelTracks.map(x => x.label)
                })
            features.push(mf);
        }
        if (sacIrrigatedAreas.length) {
            let u: Feature<Polygon | MultiPolygon> = feature(sacIrrigatedAreas[0]);
            for (const p of sacIrrigatedAreas.slice(1)) {
                u = customUnion(u, p);
            }
            u.properties = {
                rdpFeatureType: "system/irrigatedArea-sac",
                ...this.propertiesForAll
            }
            features.push(u);
        }
        if (endGunAreas.length) {
            let u: Feature<Polygon | MultiPolygon> = feature(endGunAreas[0]);
            for (const p of endGunAreas.slice(1)) {
                u = customUnion(u, p);
            }
            u.properties = {
                rdpFeatureType: "system/irrigatedArea-endGun",
                ...this.propertiesForAll
            }
            features.push(u);
        }
        if (dropIrrigatedAreas.length) {            
            let u: Feature<Polygon | MultiPolygon> = feature(dropIrrigatedAreas[0]);
            for (const p of dropIrrigatedAreas.slice(1)) {
                u = customUnion(u, p);
            }
            u.properties = {
                rdpFeatureType: "system/irrigatedArea-dropSpan",
                ...this.propertiesForAll
            }
            features.push(u);
        }
        this._cached_DrawFeaturesSimple = features;
        return this._cached_DrawFeaturesSimple;
    }
}