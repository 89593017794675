import { Text, View } from "@react-pdf/renderer";
import i18next, { t } from "i18next";
import ISystem from "rdptypes/project/ISystem";
import { ProposalSection } from "rdptypes/roe/IPartsCatalogEntry";
import * as React from "react";
import { FC } from "react";
import { partsPackages } from "roedata/data";
import { ISectionGroupedParts } from "roedata/roe_migration/PriceCalculator";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { IAncillaryPart, IGroupedSystemParts, proposalSectionIsAncillary } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import { FEXContext } from "../../foreignExchange";
import { pricingHelper } from "../../helpers/pricingHelper";
import DealerDataCtx from "../../userData/DealerDataCtx";
import { IDocumentType, formatCents } from "../DocumentGenerationHelpers";
import { generateFexWarningBoxFromLines } from "../fexWarning";
import { documentStyles, primaryColor } from "../styles";
import PurchaseOrderTotalPricesTable from "./PurchaseOrderTotalPricesTable";
import TotalPricesTable from "./TotalPricesTable";
import FixedWidthTable, { ICell, IRow } from "./table/FixedWidthTable";

interface Props {
    groupedSystemParts: {partGroups: IGroupedSystemParts, partSections: ISectionGroupedParts};
    quote: QuoteClass;
    system: ISystem;
    docType: IDocumentType;
};

export interface IMainlineValueInfo {
    basePartNumber: string;
    description: string;
    priceUsdCents: number;
    quantity: number;
}

const SystemComponentsTable: FC<Props> = (props) => {
    const user = React.useContext(DealerDataCtx);
    const fex = React.useContext(FEXContext);

    let {groupedSystemParts, quote, system, docType} = props;

    const { roeData } = partsPackages[system.partsPackageId];
    const ph = pricingHelper({ system, user, fex });
    const docDisplayCurrency = ph.helpers.getDocDisplayCurrency(props.docType);
    const showingLocalCurrency = docDisplayCurrency !== "USD";

    let standardRows: IRow[] = [{
        cells: [
            {
                text: t("common-phrases.sltQty"),
                header: true,
                widthPc: 7
            },
            {
                text: docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltPartNumber") : "",
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0,
                header: true
            },
            {
                text: t("common-phrases.sltDescription"),
                header: true,
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
            }, 
            {
                text: "",
                header: true,
                widthPc: 15
            }
        ]
    }];

    let upgradeRows: IRow[] = [{
        cells: [
            {
                text: t("common-phrases.sltQty"),
                header: true,
                widthPc: 7
            },
            {
                text: docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltPartNumber") : "",
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0,
                header: true
            },
            {
                text: t("common-phrases.sltDescription"),
                header: true,
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
            }, 
            {
                text: t("common-phrases.sltPrice"),
                header: true,
                widthPc: 15
            }
        ]
    }];
    let optionRows: IRow[] = [{
        cells: [
            {
                text: t("common-phrases.sltQty"),
                header: true,
                widthPc: 7
            },
            {
                text: docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltPartNumber") : "",
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0,
                header: true
            }, 
            {
                text: t("common-phrases.sltDescription"),
                header: true,
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
            }, 
            {
                text: t("common-phrases.sltPrice"),
                header: true,
                widthPc: 15
            }
        ]
    }];

    let ancillaryRows: IRow[] = [{
        cells: [
            {
                text: t("common-phrases.sltQty"),
                header: true,
                widthPc: 7
            },
            {
                text: docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltPartNumber") : "",
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0,
                header: true
            }, 
            {
                text: t("common-phrases.sltDescription"),
                header: true,
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
            }, 
            {
                text: t("common-phrases.sltPrice"),
                header: true,
                widthPc: 15
            }
        ]
    }];

    let standardPrice = 0;
    let optionsPrice = 0;
    let upgradesPrice = 0;

    let ancillaryPrice = 0;
    let ancillaryParts: IAncillaryPart[] = [];

    for (let sectionId in groupedSystemParts.partSections){
        groupedSystemParts.partSections[sectionId].forEach((p) => {
            let part = roeData.partsCatalog[p.partNumber];

            let desc = (part.customerReportsDescription === null || docType === IDocumentType.irrigationSystemPurchaseOrder || p.groupId.indexOf("Sprinklers_") !== -1) ? 
                part.mapicsDescription : 
                part.customerReportsDescription;
            desc = desc.toUpperCase();

           let cells: ICell[] = [{
                text: p.qty.toString(),
                widthPc: 7
            },
            {
                text: docType === IDocumentType.irrigationSystemPurchaseOrder ? p.partNumber : "",
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0
            },
            {
                child: <View>
                    <Text><Text style={{fontFamily: "Wingdings", color: primaryColor, fontSize: 8,  marginRight: 3}}>y</Text> {desc!}</Text>
                </View>,
                widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
            }];

            let price = "";
            let partPrice = ph.helpers.applySystemDiscountToValueByDocType(docType, user.priceList[p.partNumber].unitPriceUsdCents);
            if (part.proposalSection === ProposalSection.Option){
                price = formatCents(
                    ph.helpers.convertToCurrencyIfNeeded(p.qty * partPrice, "USD", docDisplayCurrency),
                    docDisplayCurrency
                );
                if (proposalSectionIsAncillary(parseInt(sectionId))){
                    ancillaryParts.push({
                        section: parseInt(sectionId),
                        part,
                        qty: p.qty,
                        partPriceCents: partPrice
                    });
                    ancillaryPrice += p.qty * partPrice;
                }
                else {
                    optionsPrice += p.qty * partPrice;
                }
            }
            else if (part.proposalSection === ProposalSection.Upgrade && part.upgradeToPartNumber !== null){
                let standardPartPrice = ph.helpers.applySystemDiscountToValueByDocType(docType, user.priceList[part.upgradeToPartNumber].unitPriceUsdCents);
                price = formatCents(
                    ph.helpers.convertToCurrencyIfNeeded(p.qty * (partPrice - standardPartPrice), "USD", docDisplayCurrency),
                    docDisplayCurrency
                );
                upgradesPrice += p.qty * (partPrice - standardPartPrice);
                //include standard part in standard price
                standardPrice += p.qty * standardPartPrice;
            }
            else {
                standardPrice += p.qty * partPrice;
            }

            cells.push({
                text: price,
                widthPc: 15
            });

            if (part.proposalSection === ProposalSection.Standard){
                standardRows.push({cells});
            }
            else if (part.proposalSection === ProposalSection.Upgrade){
                upgradeRows.push({cells});
            }
            else if (proposalSectionIsAncillary(parseInt(sectionId))){
                ancillaryRows.push({cells});
            }
            else {
                optionRows.push({cells});
            }
        });
    }

    //Mainline Valves

    system.MainlineValveOptions.Valves.MainlineValve.forEach((valve) => {
        let info = quote.MainlineValvesClass.GetMainlineValveInfo(valve, user.priceList);
        let price = ph.helpers.applySystemDiscountToValueByDocType(docType, info.Price());
        let costStr = formatCents(
            ph.helpers.convertToCurrencyIfNeeded(price, "USD", docDisplayCurrency),
            docDisplayCurrency
        );

        if (!isNaN(price)){
            ancillaryPrice += price;
        }
        else {
            costStr = "Unknown";
        }
        
        let wrappedPartNumber = [info.PartNumber()];
        while (wrappedPartNumber[wrappedPartNumber.length - 1].length > 10) {
            const str = wrappedPartNumber[wrappedPartNumber.length - 1];
            wrappedPartNumber[wrappedPartNumber.length - 1] = str.substring(0, 10);
            wrappedPartNumber.push(str.substring(10));
        }
        let cells: ICell[] = [{
            text: info.Quantity.toString(),
            widthPc: 7
        },
        {
            text: docType === IDocumentType.irrigationSystemPurchaseOrder ? wrappedPartNumber.join("\n") : "",
            widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0
        },
        {
            child: <View>
                <Text><Text style={{fontFamily: "Wingdings", color: primaryColor, fontSize: 8,  marginRight: 3}}>y</Text> {info.Description}</Text>
            </View>,
            widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
        },
        {
            text: costStr,
            widthPc: 15
        }];

        ancillaryRows.push({cells});
    });

    let standardCents = standardPrice;
    let specialOrderPartsRows: IRow[] = null;

    if (docType === IDocumentType.irrigationSystemPurchaseOrder && HasSwingArmCorner(quote.System)) {

        // It was decided in a meeting with Reinke on 28/09/2024 that only the dealer should absorb
        // the price of swing arm path creation
        const swingArmPathPriceCents = quote.SwingArmClass.SpecialOrderPriceCents();

        if (swingArmPathPriceCents > 0){
            specialOrderPartsRows = [
                {
                    cells: [
                        {
                            text: t("common-phrases.sltQty"),
                            header: true,
                            widthPc: 7
                        },
                        {
                            text: docType === IDocumentType.irrigationSystemPurchaseOrder ? t("common-phrases.sltPartNumber") : "",
                            widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 16 : 0,
                            header: true
                        }, 
                        {
                            text: t("common-phrases.sltDescription"),
                            header: true,
                            widthPc: docType === IDocumentType.irrigationSystemPurchaseOrder ? 62 : 78
                        }, 
                        {
                            text: t("common-phrases.sltPrice"),
                            header: true,
                            widthPc: 15
                        }
                    ]
                },
                {
                    cells: [
                        {
                            text: "1",
                            widthPc: 7
                        },
                        {
                            text: quote.SwingArmClass.SACPathCreatorPartNum(),
                            widthPc: 16
                        },
                        {
                            child: <View>
                                <Text><Text style={{fontFamily: "Wingdings", color: primaryColor, fontSize: 8,  marginRight: 3}}>y</Text> {quote.SwingArmClass.SACPathCreatorPartDesc()}</Text>
                            </View>,
                            widthPc: 62
                        },
                        {
                            text: formatCents(
                                ph.helpers.convertToCurrencyIfNeeded(swingArmPathPriceCents, "USD", docDisplayCurrency),
                                docDisplayCurrency
                            ),
                            widthPc: 15
                        }
                    ]
                },
                {
                    cells: [{
                        text: t("common-phrases.sltTotal"),
                        header: true,
                        textAlignRight: true,
                        widthPc: 80
                    },
                    {
                        text: "",
                        widthPc: 5
                    },
                    {
                        child: <View style={{borderTop: "1 solid black"}}>
                            <Text style={{fontWeight: "bold"}}>
                                {
                                    formatCents(
                                        ph.helpers.convertToCurrencyIfNeeded(swingArmPathPriceCents, "USD", docDisplayCurrency),
                                        docDisplayCurrency
                                    )
                                }
                            </Text>
                            </View>,
                        widthPc: 15
                    }]
                }
            ];
        }
    }


    if (standardRows.length > 1){
        standardRows.push({
            cells: [{
                text: t("common-phrases.sltStandardSystem") + ":",
                header: true,
                textAlignRight: true,
                widthPc: 80
            },
            {
                text: "",
                widthPc: 5
            },
            {
                child: <View style={{borderTop: "1 solid black"}}>
                    <Text style={{fontWeight: "bold"}}>
                        {
                            formatCents(
                                ph.helpers.convertToCurrencyIfNeeded(standardCents, "USD", docDisplayCurrency),
                                docDisplayCurrency
                            )
                        }
                    </Text>
                </View>,
                widthPc: 15
            }]
        });
    }

    if (optionRows.length > 1){
        optionRows.push({
            cells: [{
                text: t("common-phrases.sltTotalOptions") + ":",
                header: true,
                textAlignRight: true,
                widthPc: 80
            },
            {
                text: "",
                widthPc: 5
            },
            {
                child: <View style={{borderTop: "1 solid black"}}>
                    <Text style={{fontWeight: "bold"}}>
                        {
                            formatCents(
                                ph.helpers.convertToCurrencyIfNeeded(optionsPrice, "USD", docDisplayCurrency),
                                docDisplayCurrency
                            )
                        }
                    </Text>
                    </View>,
                widthPc: 15
            }]
        });
    }

    if (upgradeRows.length > 1){
        upgradeRows.push({
            cells: [{
                text: t("common-phrases.sltTotalUpgrades") + ":",
                header: true,
                textAlignRight: true,
                widthPc: 80
            },
            {
                text: "",
                widthPc: 5
            },
            {
                child: <View style={{borderTop: "1 solid black"}}>
                    <Text style={{fontWeight: "bold"}}>
                        {
                            formatCents(
                                ph.helpers.convertToCurrencyIfNeeded(upgradesPrice, "USD", docDisplayCurrency),
                                docDisplayCurrency
                            )
                        }
                    </Text>
                </View>,
                widthPc: 15
            }]
        });
    }

    if (ancillaryRows.length > 1){
        ancillaryRows.push({
            cells: [{
                text: t("common-phrases.sltTotalAncillary") + ":",
                header: true,
                textAlignRight: true,
                widthPc: 80
            },
            {
                text: "",
                widthPc: 5
            },
            {
                child: <View style={{borderTop: "1 solid black"}}>
                    <Text style={{fontWeight: "bold"}}>
                        {
                            formatCents(
                                ph.helpers.convertToCurrencyIfNeeded(ancillaryPrice, "USD", docDisplayCurrency),
                                docDisplayCurrency
                            )
                        }
                    </Text>
                    </View>,
                widthPc: 15
            }]
        });
    }

    let miscItemsRows: IRow[] = null;
    if ((docType === IDocumentType.irrigationSystemProposal || docType === IDocumentType.irrigationSystemPurchaseAgreement) && ph.miscItems.items.length > 0){
        miscItemsRows = [{
            cells: [
                {
                    text: "",
                    header: true,
                    widthPc: 7
                }, 
                {
                    text: t("common-phrases.sltDescription"),
                    header: true,
                    widthPc: 63
                },{
                    text: i18next.format(t('taxable'), 'capitalize') + "?",
                    header: true,
                    widthPc: 15
                },
                {
                    text: t("common-phrases.sltPrice"),
                    header: true,
                    widthPc: 15
                }
            ]
        }];
        ph.miscItems.items.forEach((mi) => {
            miscItemsRows.push({
                cells: [
                    {
                        text: "",
                        header: true,
                        widthPc: 7
                    }, 
                    {
                        text: mi.description,
                        widthPc: 63
                    }, 
                    {
                        text: mi.taxable ? i18next.format(t('yes'), 'capitalize'): i18next.format(t('no'), 'capitalize'),
                        widthPc: 15
                    },
                    {
                        text: formatCents(
                            ph.helpers.convertToLocalIfNeeded(mi.priceMinorUnits, ph.miscItems.currency),
                            ph.localCurrency?.symbol
                        ),
                        widthPc: 15
                    }
                ]
            })
        });
    }


    return (<View>
            <View wrap={false} style={documentStyles.section}>
                <View style={documentStyles.heading}>
                    <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltSystemComponents")}</Text>
                </View>
                {
                    standardRows.length > 1 && (
                        <>
                            <View style={{paddingLeft: 15, paddingRight: 15}}>
                                <FixedWidthTable data={{rows: standardRows}} />
                            </View>
                            {generateFexWarningBoxFromLines(ph.helpers.generateFexWarningNoteLines(showingLocalCurrency, "USD"))}
                        </>
                    )
                }
            </View>
            {
                upgradeRows.length > 1 && (
                    <View wrap={false} style={documentStyles.section}>
                        <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltUpgrades")}</Text>
                        </View>
                        <View style={{paddingLeft: 15, paddingRight: 15}}>
                            <FixedWidthTable data={{rows: upgradeRows}} />
                        </View>
                        {generateFexWarningBoxFromLines(ph.helpers.generateFexWarningNoteLines(showingLocalCurrency, "USD"))}
                    </View>
                )
            }
            {
                optionRows.length > 1 && (
                    <View wrap={false} style={documentStyles.section}>
                        <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltOptions")}</Text>
                        </View>
                        <View style={{paddingLeft: 15, paddingRight: 15}}>
                            <FixedWidthTable data={{rows: optionRows}} />
                        </View>
                        {generateFexWarningBoxFromLines(ph.helpers.generateFexWarningNoteLines(showingLocalCurrency, "USD"))}
                    </View>
                )
            }
            {
                ancillaryRows.length > 1 && (
                    <View wrap={false} style={documentStyles.section}>
                        <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltAncillary")}</Text>
                        </View>
                        <View style={{paddingLeft: 15, paddingRight: 15}}>
                            <FixedWidthTable data={{rows: ancillaryRows}} />
                        </View>
                        {generateFexWarningBoxFromLines(ph.helpers.generateFexWarningNoteLines(showingLocalCurrency, "USD"))}
                    </View>
                )
            }
            {
                specialOrderPartsRows !== null && (
                    <View wrap={false} style={documentStyles.section}>
                        <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltSpecialOrderPart")}</Text>
                        </View>
                        <View style={{paddingLeft: 15, paddingRight: 15}}>
                            <FixedWidthTable data={{rows: specialOrderPartsRows}} />
                        </View>
                        {generateFexWarningBoxFromLines(ph.helpers.generateFexWarningNoteLines(showingLocalCurrency, "USD"))}
                    </View>
                )
            }
            {
                ph.miscItems.items.length > 0 && miscItemsRows !== null && (
                    <View wrap={false} style={documentStyles.section}>
                        <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltMiscItems")}</Text>
                        </View>
                        <View style={{paddingLeft: 15, paddingRight: 15}}>
                            <FixedWidthTable
                                data={{rows: miscItemsRows}}
                            />
                        </View>
                        {generateFexWarningBoxFromLines(ph.helpers.generateFexWarningNoteLines(showingLocalCurrency, ph.miscItems.currency))}
                    </View>
                )
            }
            {(docType === IDocumentType.irrigationSystemProposal || docType === IDocumentType.irrigationSystemPurchaseAgreement) && 
                <TotalPricesTable
                    ph={ph}
                    quote={quote} system={system}
                    docType={docType}
                />
            }
            {docType === IDocumentType.irrigationSystemPurchaseOrder && 
                <PurchaseOrderTotalPricesTable
                    quote={quote} system={system}
                    ancillaryParts={ancillaryParts}
                    groupedSections={groupedSystemParts.partSections}
                    standardPriceCents={standardPrice} optionsPriceCents={optionsPrice} upgradesPriceCents={upgradesPrice} />
            }
        </View>
    );
};

export default SystemComponentsTable;