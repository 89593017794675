import { Button, DialogActions, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import i18next from "i18next";
import IAction from "rdptypes/IAction";
import { HoseFeedTypes, SystemTypes, TowTypes, WaterFeedTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { productSets } from "roedata/data";
import { createNewMultiAction } from "../../actions/MultiAction";
import { createNewLayoutAction } from "../../actions/NewLayoutAction";
import { calculateRateData, createNewSystemActionV2_WithFEX } from "../../actions/NewSystemActionV2";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import { createSystemDefaultActions } from "../../actions/defaultActions";
import AuthCtx from "../../auth/AuthCtx";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { staticDevSettingsDbProvider } from "../../db/DevSettingsDbProvider";
import IDbProject from "../../db/IDbProject";
import IDbState from "../../db/IDbState";
import { FEXContext } from "../../foreignExchange";

interface Props {
    dbPrj: IDbProject;
    dbState: IDbState;

    onCancel: () => any;
    onConfirm: ( layoutId, systemId) => any;
};

enum MainTypes {
    centerPivot,
    lateral,
    swingArm,
    ancillary,
    pivotingLateral,
    edmp
}

enum PivotTypes{
    standardPivot,
    towable
}

enum LateralTypes{
    canalFeed,
    hoseFeed,
}

enum TowableTypes{
    fourWheelPivotMover,
    kwikTow,
    skidTow
}

enum EdmpTypes {
    centerPivot,
    kwikTow
}

const DesignOnlySection: FC<Props> = ({
    ...props
}) => {
    const ns = "roe.";

    const authState = useContext(AuthCtx);
    const systemDefaults = useContext(DevSettingsCtx).dealerSettings.system;
    const fex = useContext(FEXContext);

    const project = props.dbPrj.state;

    const [mainType, setMainType] = useState<MainTypes | null>(null);
    const [pivotType, setPivotType] = useState<PivotTypes | null>(null);
    const [lateralType, setLateralType] = useState<LateralTypes | null>(null);
    const [towableType, setTowableType] = useState<TowableTypes | null>(null);
    const [waterFeedType, setWaterFeedType] = useState<WaterFeedTypes | null>(null);
    const [edmpType, setEdmpType] = useState<EdmpTypes | null>(null);
    //const [firstSpanType, setFirstSpanType] = useState<SpanTypes | null>(null);
    const [sectionIndex, setSectionIndex] = useState<number>(0);

    const next = () => {
        if (sectionIndex === getStepsRequired()){
            onConfirm();
        }
        else {
            setSectionIndex(sectionIndex + 1);
        }
    }

    useEffect(()=>{
        if (mainType !== null) next();
    }, [mainType])

    useEffect(()=>{
        if (pivotType !== null) next();
    }, [pivotType])

    useEffect(()=>{
        if (edmpType !== null) next();
    }, [edmpType])

    useEffect(()=>{
        if (lateralType !== null) next();
    }, [lateralType])

    useEffect(()=>{
        if (towableType !== null) next();
    }, [towableType])

    useEffect(()=>{
        if (waterFeedType !== null) next();
    }, [waterFeedType])

    const onConfirm = () => {
        if(Object.keys(project.layouts).length === 0){
            //create default layout
            const { layoutId, action } = createNewLayoutAction(authState);
            props.dbPrj.pushAction(action);
        }
        var layoutId = Object.keys(project.layouts)[0];
        var systemId = Object.keys(project.layouts[layoutId].systems).length > 0 ?  Object.keys(project.layouts[layoutId].systems)[0] : undefined;
        const customerSettings = staticDevSettingsDbProvider.customer.get();
        const dealerPricing = undefined;
        if(Object.keys(project.layouts[layoutId].systems).length === 0){
            var createSystemAction = createNewSystemActionV2_WithFEX(
                layoutId, getSystemType(),
                customerSettings, dealerPricing, calculateRateData(customerSettings, fex),
                authState,
                productSets[props.dbPrj.state.productSetId].latestPartsPackageId
            );
            props.dbPrj.pushAction(createSystemAction.action);
            systemId = createSystemAction.systemId;
        }       

        const actions: IAction[] = [];
        if (systemDefaults.useCustom) {
            for (const action of systemDefaults.custom.getSystemProperties(layoutId, systemId, authState, getSystemType(), { isTowable: pivotType === PivotTypes.towable, isEDMP: mainType === MainTypes.edmp })) {
                actions.push(action);
            }
        } else {
            actions.push(...createSystemDefaultActions(layoutId, systemId, authState));
        }

        if(mainType === MainTypes.edmp){
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    `Circle.EngineDrivenMiniPivot`,
                    true,
                    authState
                )
            )
        }

        if(towableType === TowableTypes.fourWheelPivotMover){
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    `Circle.CenterPivot.TowOptions.TowType`,
                    TowTypes.FourWheelPivotMover,
                    authState
                )
            );
        } else if(towableType === TowableTypes.skidTow){
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    `Circle.CenterPivot.TowOptions.TowType`,
                    TowTypes.SkidTow,
                    authState
                )
            );
        } else {
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    `Circle.CenterPivot.TowOptions.TowType`,
                    TowTypes.None,
                    authState
                )
            );
        }

        if (mainType === MainTypes.lateral) {
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    `Lateral.WaterFeed`,
                    waterFeedType,
                    authState
                )
            );
        }

        if (mainType === MainTypes.pivotingLateral) {
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    `Lateral.WaterFeed`,
                    WaterFeedTypes.EndFeed,
                    authState
                )
            );
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    `Lateral.HoseFeed.HoseFeedType`,
                    HoseFeedTypes.PivotingLateral,
                    authState
                )
            );
        }

        props.dbPrj.pushAction(
            createNewMultiAction(actions, authState)
        )
        props.onConfirm(layoutId, systemId);
    };

    const getStepsRequired = (): number => {
        var steps = 1;
        if(mainType === MainTypes.centerPivot && pivotType === PivotTypes.towable){
            steps = 2;
        }

        if(mainType === MainTypes.swingArm || mainType === MainTypes.ancillary || mainType === MainTypes.pivotingLateral){
            steps = 0;
        }

        if (mainType === MainTypes.lateral) {
            steps = 2;
        }

        return steps;
    }

    const getSystemType = (): SystemTypes | null => {
        var type = null;
        switch(mainType){
            case MainTypes.swingArm:
                type = SystemTypes.SwingArmRetro;
                break;
            case MainTypes.ancillary:
                type = SystemTypes.Ancillary;
                break;
            case MainTypes.lateral:
                if(lateralType === LateralTypes.canalFeed){
                    type = SystemTypes.CanalFeedMaxigator;
                }
                if(lateralType === LateralTypes.hoseFeed){
                    type = SystemTypes.HoseFeedMaxigator;
                }
                break;
            case MainTypes.pivotingLateral:
                type = SystemTypes.HoseFeedMaxigator;
                break;
            case MainTypes.centerPivot:                
                if(pivotType === PivotTypes.towable && towableType === TowableTypes.kwikTow){
                    type = SystemTypes.KwikTow;
                }
                if(towableType === TowableTypes.fourWheelPivotMover || towableType == TowableTypes.skidTow || pivotType === PivotTypes.standardPivot){
                    type = SystemTypes.CenterPivot;
                }
                break;       
            case MainTypes.edmp:
                if (edmpType === EdmpTypes.kwikTow) {
                    type = SystemTypes.KwikTow;
                }
                if (edmpType === EdmpTypes.centerPivot) {
                    type = SystemTypes.CenterPivot;
                }
                
        }        

        return type;
    }

    return (
        <>
            <DialogContent>
                <Stack >
                    <FormControl>
                        {
                            sectionIndex === 0 &&
                            <RadioGroup
                                value={mainType}
                                onChange={(ev) => {  
                                    setMainType(Number(ev.target.value));
                                }}>
                                    <FormControlLabel value={MainTypes.centerPivot} control={<Radio />} label={i18next.format(i18next.t(ns + "center-pivot"), 'capitalize')} />
                                    <FormControlLabel value={MainTypes.lateral} control={<Radio />} label={i18next.format(i18next.t(ns + "lateral"), 'capitalize')} />
                                    <FormControlLabel value={MainTypes.pivotingLateral} control={<Radio />} label={i18next.format(i18next.t(ns + "pivoting-lateral"), 'capitalize')} />
                                    <FormControlLabel value={MainTypes.edmp} control={<Radio />} label={i18next.format(i18next.t(ns + "engine-driven-mini-pivot"), 'capitalize')} />
                                    <FormControlLabel value={MainTypes.swingArm} control={<Radio />} label={i18next.format(i18next.t(ns + "retro-swing-arm"), 'capitalize')} />
                                    <FormControlLabel value={MainTypes.ancillary} control={<Radio />} label={i18next.format(i18next.t(ns + "ancillary"), 'capitalize')} />
                            </RadioGroup>
                        }
                        {
                           mainType === MainTypes.centerPivot && sectionIndex === 1 &&
                                <RadioGroup
                                        value={pivotType}
                                        onChange={(ev) => {  
                                            setPivotType(Number(ev.target.value));
                                          }}>
                                        <FormControlLabel value={PivotTypes.standardPivot} control={<Radio />} label={i18next.format(i18next.t(ns + "standard-pivot"), 'capitalize')} />                           
                                        <FormControlLabel value={PivotTypes.towable} control={<Radio />} label={i18next.format(i18next.t(ns + "towable"), 'capitalize')} />
                                </RadioGroup>
                        }
                        {
                           mainType === MainTypes.edmp && sectionIndex === 1 &&
                                <RadioGroup
                                        value={edmpType}
                                        onChange={(ev) => {  
                                            setEdmpType(Number(ev.target.value));
                                          }}>
                                        <FormControlLabel value={EdmpTypes.centerPivot} control={<Radio />} label={i18next.format(i18next.t(ns + "center-pivot"), 'capitalize')} />
                                        <FormControlLabel value={EdmpTypes.kwikTow} control={<Radio />} label={i18next.format(i18next.t(ns + "kwik-tow"), 'capitalize')} />
                                </RadioGroup>
                        }
                        {
                            pivotType === PivotTypes.towable && sectionIndex === 2 &&
                            <RadioGroup 
                                value={towableType}
                                onChange={(ev) => {  
                                        setTowableType(Number(ev.target.value));
                                    }}>
                                <FormControlLabel value={TowableTypes.fourWheelPivotMover} control={<Radio />} label={i18next.format(i18next.t(ns + "four-wheel-pivot-mover"), 'capitalize')} />                           
                                <FormControlLabel value={TowableTypes.kwikTow} control={<Radio />} label={i18next.format(i18next.t(ns + "kwik-tow"), 'capitalize')} /> 
                                <FormControlLabel value={TowableTypes.skidTow} control={<Radio />} label={i18next.format(i18next.t(ns + "skid-tow"), 'capitalize')} />
                            </RadioGroup>
                        }
                        {
                            mainType === MainTypes.lateral && sectionIndex === 1 &&
                            <RadioGroup
                                value={lateralType}
                                onChange={(ev) => {  
                                    setLateralType(Number(ev.target.value));
                                }}>
                                <FormControlLabel value={LateralTypes.canalFeed} control={<Radio />} label={i18next.format(i18next.t(ns + "canal-feed"), 'capitalize')} />                           
                                <FormControlLabel value={LateralTypes.hoseFeed} control={<Radio />} label={i18next.format(i18next.t(ns + "hose-feed"), 'capitalize')}/>
                            </RadioGroup>
                        }
                        {
                            mainType === MainTypes.lateral && sectionIndex === 2 &&
                            <RadioGroup 
                                value={waterFeedType}
                                onChange={(ev) => {  
                                        setWaterFeedType(ev.target.value as WaterFeedTypes);
                                    }}>
                                <FormControlLabel value={WaterFeedTypes.CenterFeed} control={<Radio />} label={i18next.format(i18next.t(ns + "center-feed"), 'capitalize')} />                           
                                <FormControlLabel value={WaterFeedTypes.EndFeed} control={<Radio />} label={i18next.format(i18next.t(ns + "end-feed"), 'capitalize')} />
                            </RadioGroup>
                        }
                    </FormControl>
                </Stack>            
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    if(sectionIndex === 0){
                        props.onCancel();
                    }else{
                        if (sectionIndex === 3) {
                            setTowableType(null);
                        }
                        if(sectionIndex === 2){
                            setLateralType(null);
                            setPivotType(null);
                            setEdmpType(null)
                        }
                        if(sectionIndex === 1){
                            setMainType(null);
                        }
                        setSectionIndex(sectionIndex - 1);
                    }
                }}>{i18next.t("back")}</Button>
                
            </DialogActions>
        </>
    );
};

export default DesignOnlySection;