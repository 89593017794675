import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    Grid,
    Typography
} from "@mui/material";
import { Feature } from "@turf/turf";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import SegmentHelper, { ESegmentClearanceType } from "../../../../../GeometryHelpers/SegmentHelper";
import { createNewUpdateLayoutPropertyAction } from "../../../../../actions/UpdateLayoutProperty";
import AuthCtx from "../../../../../auth/AuthCtx";
import IAuthState from "../../../../../auth/IAuthState";
import PositiveConvertedDecimalField from "../../../../../components/CustomInputComponents/PositiveConvertedDecimalField";
import { IFieldSettings } from "../../../../../components/DealerSettingsDialog/FieldSettings";
import { IMapContext, MapContext } from "../../../../../components/Map/mapContext";
import DbCtx from "../../../../../db/DbCtx";
import DevSettingsCtx from "../../../../../db/DevSettingsCtx";
import IDbProject from "../../../../../db/IDbProject";
import { DisplayLengthUnitBuilder } from "../../../../../helpers/lengths";
import { ISegment } from "../../../../../model/project/ISegment";
import { IMapFeaturePermissions } from "../mapFeaturePermissions";

interface Props {
    open: boolean;
    onClose: () => void;
    feature: Feature;
    projectId: string;
    layoutId: string;
}

const getDefaultClearanceValue = (feature: Feature, fieldSettings: IFieldSettings) => {
    switch (feature.properties.rdpFeatureType) {
        case "wheelObstacle":
        case "obstacle":
            return fieldSettings.minObstacleClearanceFeet;
        case "pivotCenterBoundary":
        case "fieldBoundary":
            return fieldSettings.minBoundaryClearanceFeet;
        case "wetAreaBoundary":
        default:
            return 0;
    }
};

const setAllClearances = (
    feature: Feature,
    dbProject: IDbProject,
    layoutId: string,
    authState: IAuthState,
    map: IMapContext,
    clearances: { edge?: number, sTowerOffset?: number, hTowerOffset?: number }
) => {
    if (clearances.edge === undefined && clearances.sTowerOffset === undefined && clearances.hTowerOffset === undefined) return;
    if (!map) return;
    map.changeMode("simple_select");

    const layout = dbProject.state.layouts[layoutId];
    switch (feature.properties.rdpFeatureType) {
        case "obstacle": {
            const { obstacleIndex } = feature.properties;
            if (obstacleIndex === undefined || layout.obstacles[obstacleIndex] === undefined) return;
            const updated = structuredClone(layout.obstacles);
            updated[obstacleIndex].segments.forEach((s) => {
                if (clearances.edge !== undefined) SegmentHelper.setClearance(clearances.edge, s, ESegmentClearanceType.EquipmentClearance);
                if (clearances.sTowerOffset !== undefined) SegmentHelper.setClearanceOffset(clearances.sTowerOffset, s, ESegmentClearanceType.STowerClearance);
                if (clearances.hTowerOffset !== undefined) SegmentHelper.setClearanceOffset(clearances.hTowerOffset, s, ESegmentClearanceType.HTowerClearance);
            });
            dbProject.pushAction(createNewUpdateLayoutPropertyAction(layoutId, "obstacles", updated, authState));
            break;
        };
        case "wheelObstacle": {
            const { wheelObstacleIndex } = feature.properties;
            if (wheelObstacleIndex === undefined || layout.wheelObstacles[wheelObstacleIndex] === undefined) return;
            const updated = structuredClone(layout.wheelObstacles);
            updated[wheelObstacleIndex].segments.forEach((s) => {
                if (clearances.edge !== undefined) SegmentHelper.setClearance(clearances.edge, s, ESegmentClearanceType.EquipmentClearance);
                if (clearances.sTowerOffset !== undefined) SegmentHelper.setClearanceOffset(clearances.sTowerOffset, s, ESegmentClearanceType.STowerClearance);
                if (clearances.hTowerOffset !== undefined) SegmentHelper.setClearanceOffset(clearances.hTowerOffset, s, ESegmentClearanceType.HTowerClearance);
            });
            dbProject.pushAction(
                createNewUpdateLayoutPropertyAction(layoutId, "wheelObstacles", updated, authState)
            );
            break;
        };
        case "wetAreaBoundary": {
            // wet area should not have clearances
            // const updated = structuredClone(layout.wetAreaBoundary);
            // updated.segments.forEach((s) => {
            //     if (clearances.edge !== undefined) SegmentHelper.setClearance(clearances.edge, s, ESegmentClearanceType.EquipmentClearance);
            // });
            // dbProject.pushAction(
            //     createNewUpdateLayoutPropertyAction(layoutId, "wetAreaBoundary", updated, authState)
            // );
            break;
        };
        case "pivotCenterBoundary": {
            const updated = structuredClone(layout.pivotCenterBoundary);
            updated.segments.forEach((s) => {
                if (clearances.edge !== undefined) SegmentHelper.setClearance(clearances.edge, s, ESegmentClearanceType.EquipmentClearance);
            });
            dbProject.pushAction(
                createNewUpdateLayoutPropertyAction(layoutId, "pivotCenterBoundary", updated, authState)
            );
            break;
        };
        case "fieldBoundary": {
            const updated = structuredClone(layout.fieldBoundary);
            updated.segments.forEach((s) => {
                if (clearances.edge !== undefined) SegmentHelper.setClearance(clearances.edge, s, ESegmentClearanceType.EquipmentClearance);
                if (clearances.sTowerOffset !== undefined) SegmentHelper.setClearanceOffset(clearances.sTowerOffset, s, ESegmentClearanceType.STowerClearance);
                if (clearances.hTowerOffset !== undefined) SegmentHelper.setClearanceOffset(clearances.hTowerOffset, s, ESegmentClearanceType.HTowerClearance);
            });
            dbProject.pushAction(
                createNewUpdateLayoutPropertyAction(layoutId, "fieldBoundary", updated, authState)
            );
            break;
        };
    }
};

const getAllClearances = (
    feature: Feature,
    dbProject: IDbProject,
    layoutId: string,
) => {

    const layout = dbProject.state.layouts[layoutId];
    let segments: ISegment[] = [];
    switch (feature.properties.rdpFeatureType) {
        case "obstacle": {
            const { obstacleIndex } = feature.properties;
            if (obstacleIndex === undefined || layout.obstacles[obstacleIndex] === undefined) break;
            segments = layout.obstacles[obstacleIndex].segments;
            break;
        };
        case "wheelObstacle": {
            const { wheelObstacleIndex } = feature.properties;
            if (wheelObstacleIndex === undefined || layout.wheelObstacles[wheelObstacleIndex] === undefined) break;
            segments = layout.wheelObstacles[wheelObstacleIndex].segments;
            break;
        }
        case "wetAreaBoundary": {
            segments = layout.wetAreaBoundary.segments;
            break;
        }
        case "pivotCenterBoundary": {
            segments = layout.pivotCenterBoundary.segments;
            break;
        }
        case "fieldBoundary": {
            segments = layout.fieldBoundary.segments;
            break;
        }
    }
    let edge: undefined | "various" | number = undefined;
    let sTowerOffset: undefined | "various" | number = undefined;
    let hTowerOffset: undefined | "various" | number = undefined;
    if (segments.length) {
        segments.forEach((s) => {
            const currentEdge = SegmentHelper.getClearance(s, ESegmentClearanceType.EquipmentClearance);
            const currentSTowerOffset = SegmentHelper.getClearanceOffset(s, ESegmentClearanceType.STowerClearance);
            const currentHTowerOffset = SegmentHelper.getClearanceOffset(s, ESegmentClearanceType.HTowerClearance);
            if (edge === undefined) {
                edge = currentEdge;
            }
            else if (edge !== 'various') {
                if (edge !== currentEdge) {
                    edge = 'various'
                }
            }
            if (sTowerOffset === undefined) {
                sTowerOffset = currentSTowerOffset;
            }
            else if (sTowerOffset !== 'various') {
                if (sTowerOffset !== currentSTowerOffset) {
                    sTowerOffset = 'various'
                }
            }
            if (hTowerOffset === undefined) {
                hTowerOffset = currentHTowerOffset;
            }
            else if (hTowerOffset !== 'various') {
                if (hTowerOffset !== currentHTowerOffset) {
                    hTowerOffset = 'various'
                }
            }
        });
    }
    return {
        edge, sTowerOffset, hTowerOffset
    }
};

export const getCanSetAllClearances = (feature: Feature, permissions: IMapFeaturePermissions) => {
    switch (feature.properties.rdpFeatureType) {
        case "obstacle":
        case "wheelObstacle":
            if (!permissions.editObstacles) return false;
            break;
        case "pivotCenterBoundary":
        case "fieldBoundary":
        case "wetAreaBoundary":
            if (!permissions.editBoundaries) return false;
            break;
    }
    const x = getCanSetClearanceTypes(feature);
    return x.equipment || x.sTower || x.hTower;
};

export const getCanSetClearanceTypes = (feature: Feature) => {
    switch (feature.properties.rdpFeatureType) {
        case "wheelObstacle":
        case "obstacle":
        case "fieldBoundary":
            return { equipment: true, sTower: true, hTower: true }
        case "pivotCenterBoundary":
            return { equipment: true, sTower: false, hTower: false }
        case "wetAreaBoundary":
        default:
            return { equipment: false, sTower: false, hTower: false }
    }
};

const SetClearancesDialog: FC<Props> = (props) => {
    const settings = React.useContext(DevSettingsCtx);
    const dbProject = React.useContext(DbCtx).projects[props.projectId];
    const authState = React.useContext(AuthCtx);
    const map = React.useContext(MapContext);

    const defaultClearance = getDefaultClearanceValue(props.feature, settings.fieldSettings.current);

    const handleCancelClearanceDialog = () => {
        props.onClose();
    };
    const handleOKClearanceDialog = () => {
        if (hasError) return;
        setAllClearances(
            props.feature,
            dbProject,
            props.layoutId,
            authState,
            map,
            {
                edge: bEdge ? vEdge : undefined,
                sTowerOffset: bSTower ? vSTowerOffset : undefined,
                hTowerOffset: bHTower ? vHTowerOffset : undefined,
            }
        )
        props.onClose();
    };

    const clearanceTypes = getCanSetClearanceTypes(props.feature);

    const currentValues = getAllClearances(props.feature, dbProject, props.layoutId);
    const unitsString = DisplayLengthUnitBuilder.shortName(
        settings.dealerSettings.display.current.lengths
    )

    const [ bEdge, setBEdge ] = React.useState(false);
    const [ bSTower, setBSTower ] = React.useState(false);
    const [ bHTower, setBHTower ] = React.useState(false);
    const [ vEdge, setVEdge ] = React.useState(
        (currentValues.edge !== undefined && currentValues.edge !== 'various') 
            ? currentValues.edge 
            : defaultClearance
    );

    const [ vSTowerOffset, setVSTowerOffset ] = React.useState(
        (currentValues.sTowerOffset !== undefined && currentValues.sTowerOffset !== 'various') 
            ? currentValues.sTowerOffset
            : 0  
    );
    const [ vHTowerOffset, setVHTowerOffset ] = React.useState(
        (currentValues.hTowerOffset !== undefined && currentValues.hTowerOffset !== 'various') 
            ? currentValues.hTowerOffset
            : 0
    );
    const vSTower = vEdge + vSTowerOffset;
    const vHTower = vEdge + vHTowerOffset;
    const hasError = (!(bEdge || bSTower || bHTower));

    return (
        <Dialog open={props.open} onClose={handleCancelClearanceDialog}>
            <DialogTitle>{t("map-display.speed-dial.set-clearance.title")}</DialogTitle>
            <Grid container px={3} alignItems={'center'} spacing={2}>
                {/* Header row */}
                <Grid item xs={12} />

                {/* Edge row */}
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={clearanceTypes.equipment && bEdge}
                                onChange={(ev) => setBEdge(ev.target.checked)}
                                disabled={!clearanceTypes.equipment}
                            />
                        }
                        label={i18next.format(t('equipment'), 'capitalize-each')}
                    />
                </Grid>
                <Grid item xs={8}>
                    <PositiveConvertedDecimalField
                        textFieldProps={{
                            label: `${i18next.format(t('clearance'), 'capitalize')} (${t(unitsString)})`,
                            disabled: !bEdge
                        }}
                        displayUnit={settings.dealerSettings.display.current.lengths}
                        originalUnit={"feet"}
                        setValue={setVEdge}
                        value={vEdge}
                        fullWidth
                    />
                </Grid>
                
                {/* H Tower row */}
                {
                    clearanceTypes.hTower && (
                        <>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={bHTower}
                                            onChange={(ev) => setBHTower(ev.target.checked)}
                                        />
                                    }
                                    // sx={{ width: 120 }}
                                    label={i18next.format(t('h-tower'), 'capitalize-each') + " *"}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <PositiveConvertedDecimalField
                                    textFieldProps={{
                                        label: `${i18next.format(t("map-display.speed-dial.set-clearance.offset-clearance-label"), 'capitalize')} (${t(unitsString)})`,
                                        disabled: !bHTower
                                    }}
                                    displayUnit={settings.dealerSettings.display.current.lengths}
                                    originalUnit={"feet"}
                                    setValue={setVHTowerOffset}
                                    value={vHTowerOffset}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )
                }
                
                {/* S Tower row */}
                {
                    clearanceTypes.sTower && (
                        <>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={bSTower}
                                            onChange={(ev) => setBSTower(ev.target.checked)}
                                        />
                                    }
                                    label={i18next.format(t('s-tower2'), 'capitalize-each') + " *"}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <PositiveConvertedDecimalField
                                    textFieldProps={{
                                        label: `${i18next.format(t("map-display.speed-dial.set-clearance.offset-clearance-label"), 'capitalize')} (${t(unitsString)})`,
                                        disabled: !bSTower
                                    }}
                                    displayUnit={settings.dealerSettings.display.current.lengths}
                                    originalUnit={"feet"}
                                    setValue={setVSTowerOffset}
                                    value={vSTowerOffset}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )
                }

                {
                    // Note:
                    (clearanceTypes.sTower || clearanceTypes.hTower) && (
                        <Grid item xs={12}>
                            <Typography paragraph>
                                * {i18next.format(t('map-display.speed-dial.set-clearance.note'), 'capitalize')}
                            </Typography>
                        </Grid>
                    )
                }
            </Grid> 


            <DialogActions>
                <Button onClick={handleCancelClearanceDialog}>{t("cancel")}</Button>
                <Button onClick={handleOKClearanceDialog} disabled={hasError}>{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SetClearancesDialog;
