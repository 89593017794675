import IUserData from "rdptypes/IUserData";
import IGrowerBase from "rdptypes/api/IGrowerBase";
import ISystem from "rdptypes/project/ISystem";
import IBoolean from "rdptypes/roe/IBoolean";
import IBooleanGroup from "rdptypes/roe/IBooleanGroup";
import ICard from "rdptypes/roe/ICard";
import IChoice from "rdptypes/roe/IChoice";
import IComplexChoice, { IComplexChoiceOption } from "rdptypes/roe/IComplexChoice";
import IComponent from "rdptypes/roe/IComponent";
import IEndOfSystemTypeChoice from "rdptypes/roe/IEndOfSystemTypeChoice";
import INumber from "rdptypes/roe/INumber";
import IPrecisionEndGunAcres from "rdptypes/roe/IPrecisionEndGunAcres";
import { IRuleResult } from "rdptypes/roe/IRule";
import IProposal from "rdptypes/roe/ISendOrder";
import IShippingDate from "rdptypes/roe/IShippingDate";
import ISideTable from "rdptypes/roe/ISideTable";
import ISprinklerDesign from "rdptypes/roe/ISprinklerDesign";
import ITabs from "rdptypes/roe/ITabs";
import IText from "rdptypes/roe/IText";
import { partsPackages } from "roedata/data";
import { IsCenterFeed, IsDualSided } from "roedata/roe_migration/SystemFunctions";
import { getValue, setValue } from "../../../helpers/objectPathResolver";
import { translateSlt } from "../../../helpers/translation";
import { endOfSystemValidator } from "../componentRenderers/EndOfSystemTypeChoiceRenderer/endOfSystemValidator";

export class SystemValidationResult {
    fields: { [fieldPath: string]: FieldValidationResult } = {};

    getField = (fieldPath: string) => {
        if (!(fieldPath in this.fields)) {
            this.fields[fieldPath] = new FieldValidationResult();
        }
        return this.fields[fieldPath];
    }

    getFieldPaths = () => Object.keys(this.fields);
}

export interface BadValueReasons {
    reasons: string[];
}

export type FieldValidationSeverity = "notset" | "error" | "warning" | undefined;

export class FieldValidationResult {
    severity: FieldValidationSeverity;
    badValues: Map<any, BadValueReasons> = new Map<any, BadValueReasons>();
    pageIds: Set<string> = new Set<string>();

    reccomendChange: boolean = false;
    recommendedValue: any;
    isComplex?: boolean;
}

export interface IValidationContext {
    system: ISystem;
    grower: IGrowerBase;
    user: IUserData;
    fieldRoot: string;
    pageId: string;
    result: SystemValidationResult;
    fast: boolean;
    baseSystemRuleResults: IRuleResult[]
}

export const visitComponents = (cmp: IComponent, fieldRoot: string, sys: ISystem, visitor: (cmp: IComponent, fieldRoot: string) => any) => {
    // Don't visit invisible components or their descendents
    if (cmp.visible && !cmp.visible(sys)) return;
    
    visitor(cmp, fieldRoot);
    switch (cmp.type) {
        case "choice":
            ((cmp as IChoice).additionalBooleans ?? []).forEach(child => visitComponents(child, fieldRoot, sys, visitor));
            break;
        case "booleangroup":
            (cmp as IBooleanGroup).components.forEach(child => visitComponents(child, fieldRoot, sys, visitor));
            break;
        case "card":
        case "box":
            (cmp as ICard).children?.forEach(child => visitComponents(child, fieldRoot, sys, visitor));
            break;
        case "tabs":
            (cmp as ITabs).tabs.forEach(tab => {
                tab.children?.forEach(child => visitComponents(child, fieldRoot, sys, visitor));
            });

            break;
        case "sidetable":
            const st = cmp as ISideTable;
            for (let i = 0; i < sys.FlangedSide.Tower.length; i++) {
                for (const col of st.columns) {
                    if (!col.visible || col.visible(sys)) {
                        visitComponents(col.cmp, `FlangedSide.${col.arrayPath}[${i}].`, sys, visitor);
                    }
                }
            }
            for (let i = 0; i < sys.FlexSide.Tower.length; i++) {
                for (const col of st.columns) {
                    if (!col.visible || col.visible(sys)) {
                        visitComponents(col.cmp, `FlexSide.${col.arrayPath}[${i}].`, sys, visitor);
                    }
                }
            }
            break;
        case "generaltable":
            const table = cmp as ISideTable;               
            if(table.columns.length > 0){
                const values = getValue(sys, table.columns[0].arrayPath);
                for (let i = 0; i < values.length; i++) {
                    table.columns.forEach((col, index) => {
                        if (!col.visible || col.visible(sys)) {
                            visitComponents(col.cmp, `${col.arrayPath}[${i}].`, sys, visitor);
                        }
                    });
                }             
            }
            
        break;
    }
}

export const validateComponent = (cmp: IComponent, ctx: IValidationContext) => {
    switch (cmp.type) {
        case "choice":
            validateChoice(cmp as IChoice, ctx);
            break;
        case "complexChoice":
            validateComplexChoice(cmp as IComplexChoice, ctx);
            break;
        case "boolean":
            validateBoolean(cmp as IBoolean, ctx);
            break;
        case "sidetable":
        
            validateSideTable(cmp as ISideTable, ctx);
            break;
        case "sprinklers":
            validateSprinklers(cmp as ISprinklerDesign, ctx);
            break;
        case "generaltable":
            //Mainline valves are not obligatory to be set
            //Add validation if needed for other tables
            break;
        case "endOfSystemType":
            endOfSystemValidator(cmp as IEndOfSystemTypeChoice, ctx);
            break;
        case "text":
            validateText(cmp as IText, ctx);
            break;
        case "number":
            validateNumber(cmp as IText, ctx);
            break;
        case "shippingDate":
            validateShippingDate(cmp as IShippingDate, ctx);
            break;
        case "proposal":
            validateProposal(cmp as IProposal, ctx);
            break;
        case "precisionEndGunAcres":
            validatePrecisionEndGunAcres(cmp as IPrecisionEndGunAcres, ctx);
            break;
    }
}

const validateSideTable = (cmp: ISideTable, ctx: IValidationContext) => {
    const fvr = ctx.result.getField("FlangedSide.Span");
    fvr.pageIds.add(ctx.pageId);
    if (!ctx.system.FlangedSide.Span.some(x => !x.EndBoom && !x.SwingArm)) {
        fvr.severity = "notset";
    }

    if (IsCenterFeed(ctx.system)) {
        const fvr = ctx.result.getField("FlexSide.Span");
        fvr.pageIds.add(ctx.pageId);
        if (!ctx.system.FlexSide.Span.some(x => !x.EndBoom && !x.SwingArm)) {
            fvr.severity = "notset";
        }
    }
}

const validateSprinklers = (cmp: ISprinklerDesign, ctx: IValidationContext) => {
    const fvr = ctx.result.getField("FlangedSide.SprinklerChart.Outlet");
    fvr.pageIds.add(ctx.pageId);
    if (!ctx.system.FlangedSide.SprinklerChart?.Outlet?.length) {
        fvr.severity = "notset";
    }

    if (IsDualSided(ctx.system)) {
        const fvr = ctx.result.getField("FlexSide.SprinklerChart.Outlet");
        fvr.pageIds.add(ctx.pageId);
        if (!ctx.system.FlexSide.SprinklerChart?.Outlet?.length) {
            fvr.severity = "notset";
        }
    }
}

const validateText = (cmp: IText, ctx: IValidationContext) => {
    const fieldPath = ctx.fieldRoot + cmp.fieldPath;
    const fvr = ctx.result.getField(fieldPath);
    fvr.pageIds.add(ctx.pageId);
    
    const currentValue = getValue(ctx.system, fieldPath);
    if (cmp.required && (currentValue ?? "") === "") {
        fvr.severity = "notset";
    }
}

const validateNumber = (cmp: INumber, ctx: IValidationContext) => {
    const fieldPath = ctx.fieldRoot + cmp.fieldPath;
    const fvr = ctx.result.getField(fieldPath);
    fvr.pageIds.add(ctx.pageId);
    
    const currentValue = getValue(ctx.system, fieldPath);
    if (cmp.required && (currentValue === undefined || isNaN(currentValue))) {
        fvr.severity = "notset";
    }
}

const validateShippingDate = (cmp: IShippingDate, ctx: IValidationContext) => {
    const fieldPath = ctx.fieldRoot + cmp.fieldPath;
    const fvr = ctx.result.getField(fieldPath);
    fvr.pageIds.add(ctx.pageId);
    
    const currentValue = getValue(ctx.system, fieldPath);
    if ((currentValue ?? "") === "") {
        fvr.severity = "notset";
    }
}

const validateProposal = (cmp: IProposal, ctx: IValidationContext) => {
    const fvr = ctx.result.getField("proposalGenerated");
    fvr.pageIds.add(ctx.pageId);
    
    if (!ctx.system.proposalGenerated) {
        fvr.severity = "notset";
    }
}

const validatePrecisionEndGunAcres = (cmp: IPrecisionEndGunAcres, ctx: IValidationContext) => {
    const fieldPath = ctx.fieldRoot + cmp.fieldPath;
    const fvr = ctx.result.getField(fieldPath);
    fvr.pageIds.add(ctx.pageId);

    const currentValue = getValue(ctx.system, fieldPath);
    if ((currentValue ?? "") === "" || currentValue > 100 || currentValue < 0) {
        fvr.severity = "notset";
    }
}

const validateChoice = (cmp: IChoice, ctx: IValidationContext) => {
    if (!cmp.options.length) {
        // This is an empty choice, probably just a container for dropdown booleans. Don't validate it.
        return;
    }

    const fieldPath = ctx.fieldRoot + cmp.fieldPath;
    const fvr = ctx.result.getField(fieldPath);
    fvr.pageIds.add(ctx.pageId);
    
    const currentValue = getValue(ctx.system, fieldPath);
    if (!cmp.options.some(x => x.id === currentValue)) {
        fvr.severity = "notset";
    }

    if (cmp.validate === false) return;

    // Don't validate if disabled
    if (cmp.disabled?.(ctx.system)) return;

    const ignoreRules = cmp.ignoreValidationRules ? new Set<string>(cmp.ignoreValidationRules.map(x => translateSlt(x))) : new Set<string>();
    
    const includeOnlyValidationRules = cmp.includeOnlyValidationRules ? new Set<string>(cmp.includeOnlyValidationRules.map(x => translateSlt(x))) : undefined;
    const currentErrors = getErrors(ctx.system, ctx.grower, ctx.user, new Set<string>([fieldPath]), ignoreRules, includeOnlyValidationRules, ctx.baseSystemRuleResults);

    if (includeOnlyValidationRules && Object.keys(currentErrors).length) fvr.severity = "error";

    if (!cmp.autoChange && !cmp.validateBeforeOpen && ctx.fast) return;

    const optionErrors = new Map<any, RuleFailures>();
    for (const option of cmp.options) {
        setValue(ctx.system, fieldPath, option.id);
        optionErrors.set(option.id, getErrors(ctx.system, ctx.grower, ctx.user, new Set<string>([fieldPath]), ignoreRules, includeOnlyValidationRules));
    }
    setValue(ctx.system, fieldPath, currentValue);

    let bestOption: any = undefined;
    optionErrors.forEach((value, key) => {
        if (bestOption === undefined) {
            bestOption = key;
        } else {
            const score = getScore(optionErrors.get(bestOption), value);
            if (score.score > 0 || (score.score === 0 && score.severity === "error")) {
                bestOption = key;
            }
        }
    });

    optionErrors.forEach((value, key) => {
        const score = getScore(optionErrors.get(bestOption), value);
        if (score.score < 0 && score.severity === "error") {
            fvr.badValues.set(key, {
                reasons: score.reasons
            });
        }

        if (Object.keys(value).length < Object.keys(optionErrors.get(bestOption)).length) {
            bestOption = key;
        }
    });

    if (cmp.options.some(x => x.id === currentValue)) {
        const currentScore = getScore(currentErrors, optionErrors.get(bestOption));
        if (getScore(currentErrors, optionErrors.get(bestOption)).score > 0) {
            // Only automatically change this Choice if autoChange is explicitly true
            // Note that this differs from Boolean components, where autoChange is true by default
            if (cmp.autoChange === true) {
                // Current value is not a best option so recommend change
                fvr.reccomendChange = true;
                fvr.recommendedValue = bestOption;
            }
            fvr.severity = currentScore.severity;
        }
    } else {
        // Only automatically change this Choice if autoChange is explicitly true
        // Note that this differs from Boolean components, where autoChange is true by default
        if (cmp.autoChange === true) {
            // Current value is not a best option so recommend change
            fvr.reccomendChange = true;
            fvr.recommendedValue = bestOption;
        }
    }
}

const validateComplexChoice = (cmp: IComplexChoice, ctx: IValidationContext) => {
    if (!cmp.options.length) {
        // This is an empty choice, probably just a container for dropdown booleans. Don't validate it.
        return;
    }

     // We use JSON.stringify(cmp.options) to identify this component. The renderer must use the same.
    const fieldPath = ctx.fieldRoot + JSON.stringify(cmp.options);

    const fvr = ctx.result.getField(fieldPath);
    fvr.pageIds.add(ctx.pageId);

    const allLeafOptions: IComplexChoiceOption[] = [];
    const populateAllLeafOptions = (opts: IComplexChoiceOption[]) => {
        for (const opt of opts) {
            if (opt.nestedOptions) {
                populateAllLeafOptions(opt.nestedOptions);
            } else {
                allLeafOptions.push(opt);
            }
        }
    }
    populateAllLeafOptions(cmp.options);
    
    const optionSelected = (opt: IComplexChoiceOption) => Object.keys(opt.values).every(fp => getValue(ctx.system, ctx.fieldRoot + fp) === opt.values[fp]);
    const setOption = (opt: IComplexChoiceOption, sys: ISystem) => {
        for (const fp of Object.keys(opt.values)) {
            setValue(sys, ctx.fieldRoot + fp, opt.values[fp]);
        }
    }
    const getOptionFieldPaths = (opt: IComplexChoiceOption) =>
        new Set(Object.keys(opt.values).map(fp => ctx.fieldRoot + fp));

    const currentOption: IComplexChoiceOption | undefined = allLeafOptions.filter(optionSelected)[0];
    if (!currentOption) {
        fvr.severity = "notset";
    }

    if (cmp.validate === false) return;

    // Don't validate if disabled
    if (cmp.disabled?.(ctx.system)) return;

    const ignoreRules = cmp.ignoreValidationRules ? new Set<string>(cmp.ignoreValidationRules.map(x => translateSlt(x))) : new Set<string>();
    const includeOnlyValidationRules = cmp.includeOnlyValidationRules ? new Set<string>(cmp.includeOnlyValidationRules.map(x => translateSlt(x))) : undefined;
    const currentErrors: RuleFailures = currentOption
        ? getErrors(ctx.system, ctx.grower, ctx.user, getOptionFieldPaths(currentOption), ignoreRules, includeOnlyValidationRules, ctx.baseSystemRuleResults)
        : {};

    if (includeOnlyValidationRules && Object.keys(currentErrors).length) fvr.severity = "error";

    if (!cmp.autoChange && !cmp.validateBeforeOpen && ctx.fast) return;

    const optionErrors = new Map<string, RuleFailures>();
    const sysCopy: ISystem = JSON.parse(JSON.stringify(ctx.system));
    for (const opt of allLeafOptions) {
        // We use the JSON option values as a key. The renderer must use the same.
        const optId = JSON.stringify(opt.values);
        setOption(opt, sysCopy);
        optionErrors.set(optId, getErrors(sysCopy, ctx.grower, ctx.user, getOptionFieldPaths(opt), ignoreRules, includeOnlyValidationRules));
    }

    let bestOption: string | undefined = undefined;
    optionErrors.forEach((value, key) => {
        if (bestOption === undefined) {
            bestOption = key;
        } else {
            const score = getScore(optionErrors.get(bestOption), value);
            if (score.score > 0 || (score.score === 0 && score.severity === "error")) {
                bestOption = key;
            }
        }
    });

    optionErrors.forEach((value, key) => {
        const score = getScore(optionErrors.get(bestOption), value);
        if (score.score < 0) {
            fvr.badValues.set(key, {
                reasons: score.reasons
            });
        }

        if (Object.keys(value).length < Object.keys(optionErrors.get(bestOption)).length) {
            bestOption = key;
        }
    });

    if (currentOption) {
        const currentScore = getScore(currentErrors, optionErrors.get(bestOption));
        if (currentScore.score > 0) {
            // Only automatically change this Choice if autoChange is explicitly true
            // Note that this differs from Boolean components, where autoChange is true by default
            if (cmp.autoChange === true) {
                // Current value is not a best option so recommend change
                fvr.reccomendChange = true;
                fvr.recommendedValue = bestOption;
                fvr.isComplex = true;
            }
            fvr.severity = currentScore.severity;
        }
    } else {
        // Only automatically change this Choice if autoChange is explicitly true
        // Note that this differs from Boolean components, where autoChange is true by default
        if (cmp.autoChange === true) {
            // Current value is not a best option so recommend change
            fvr.reccomendChange = true;
            fvr.recommendedValue = bestOption;
            fvr.isComplex = true;
        }
    }
}

const validateBoolean = (cmp: IBoolean, ctx: IValidationContext) => {
    const trueValue = cmp.trueValue !== undefined ? cmp.trueValue : true;
    const falseValue = cmp.falseValue !== undefined ? cmp.falseValue : false;

    const fieldPath = ctx.fieldRoot + cmp.fieldPath;
    const fvr = ctx.result.getField(fieldPath);
    fvr.pageIds.add(ctx.pageId);

    if (cmp.validate === false) return;

    const ignoreRules = cmp.ignoreValidationRules ? new Set<string>(cmp.ignoreValidationRules.map(x => translateSlt(x))) : new Set<string>();
    const includeOnlyValidationRules = cmp.includeOnlyValidationRules ? new Set<string>(cmp.includeOnlyValidationRules.map(x => translateSlt(x))) : undefined;

    const currentValue = getValue(ctx.system, fieldPath) === trueValue ? trueValue : falseValue;
    const currentErrors = getErrors(ctx.system, ctx.grower, ctx.user, new Set<string>([fieldPath]), ignoreRules, includeOnlyValidationRules, ctx.baseSystemRuleResults);

    if (includeOnlyValidationRules && Object.keys(currentErrors).length) fvr.severity = "error";

    const altValue = currentValue === trueValue ? falseValue : trueValue;
    setValue(ctx.system, fieldPath, altValue);
    const altErrors = getErrors(ctx.system, ctx.grower, ctx.user, new Set<string>([fieldPath]), ignoreRules, includeOnlyValidationRules);
    const altScore = getScore(currentErrors, altErrors);
    

    setValue(ctx.system, fieldPath, currentValue);

    if (altScore.score > 0) {
        fvr.badValues.set(currentValue, { reasons: altScore.reasons });
        fvr.severity = altScore.severity;

        // Automatically change this Boolean if autoChange is not explicitly false
        // Note that this differs from Choice components, where autoChange is false by default
        if (cmp.autoChange !== false) {
            fvr.reccomendChange = true;
            fvr.recommendedValue = altValue;
        }
    } else if (altScore.score < 0) {
        fvr.badValues.set(altValue, { reasons: altScore.reasons });
    }
}

type RuleFailures = {[ruleResultId: string]: {
    error: number,
    warning: number,
    info: number,
}};

const getErrors = (
    system: ISystem,
    grower: IGrowerBase,
    user: IUserData,
    fieldPaths: Set<string>,
    ignoreRules: Set<string>,
    includeOnlyValidationRules?: Set<string>,
    ruleResults?: IRuleResult[]
) => {
    const { roeData } = partsPackages[system.partsPackageId];
    
    const result: RuleFailures = {};
    const rrs = ruleResults ?? roeData.rules.flatMap(rule => rule.apply(system, user, grower, true));
    for (const rr of rrs) {
        if ((includeOnlyValidationRules && (includeOnlyValidationRules.has(rr.id) || rr.targets.some(target => fieldPaths.has(target))))
            || (!includeOnlyValidationRules && !ignoreRules.has(rr.id))) {
            let rf = result[rr.id];
            if (!rf) {
                rf = result[rr.id] = {
                    error: 0,
                    warning: 0,
                    info: 0,
                };
            }
            rf[rr.severity]++;
        }
    }
    return result;
}

interface ScoreResult {
    score: number;
    severity?: "error" | "warning";
    reasons: string[];
}

const getScore = (currentErrors: RuleFailures, optionErrors: RuleFailures): ScoreResult => {
    const negativeResult: ScoreResult = {
        score: 0,
        reasons: []
    };
    for (const err of Object.keys(optionErrors)) {
        const optionrf = optionErrors[err];
        let currentrf = currentErrors[err];
        if (!currentrf) {
            currentrf = {
                error: 0,
                warning: 0,
                info: 0,
            };
        }
        
        if (currentrf.error < optionrf.error) {
            negativeResult.score--;
            negativeResult.reasons.push(err);
            negativeResult.severity = "error";
        }
    }

    const positiveResult: ScoreResult = {
        score: 0,
        reasons: []
    };
    for (const err of Object.keys(currentErrors)) {
        const currentrf = currentErrors[err];
        let optionrf = optionErrors[err];
        if (!optionrf) {
            optionrf = {
                error: 0,
                warning: 0,
                info: 0,
            };
        }

        if (optionrf.error < currentrf.error) {
            positiveResult.score++;
            positiveResult.reasons.push(err);
            positiveResult.severity = "error";
        }
    }

    if (positiveResult.score !== 0 && negativeResult.score !== 0) {
        // This option both removes and adds errors to the current option
        // Treat it as equal to the current option
        return {
            score: 0,
            reasons: []
        };
    } else if (positiveResult.score !== 0) {
        return positiveResult;
    } else {
        return negativeResult;
    }
}