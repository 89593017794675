import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC, useState } from "react";
import { createNewUpdateLayoutPropertyAction } from "../../../../../actions/UpdateLayoutProperty";
import AuthCtx from "../../../../../auth/AuthCtx";
import DbCtx from "../../../../../db/DbCtx";
import IAnnotation, { AnnotationType, annotationTypeToString } from "../../../../../model/project/IAnnotation";

interface Props {
    onClose: () => void;
    projectId: string;
    layoutId: string;
    annotationIndex: number;
}

const getTextFromAnnotation = (annotation: IAnnotation) => {
  if (annotation.lineAnnotation) return annotation.lineAnnotation.text;
  if (annotation.pointAnnotation) return annotation.pointAnnotation.text;
  if (annotation.textAnnotation) return annotation.textAnnotation.text;
  return undefined;
}

const EditAnnotationLabelDialog: FC<Props> = (props) => {
  const dbState = React.useContext(DbCtx);
  const authState = React.useContext(AuthCtx);
  const dbProject = dbState.projects[props.projectId];
  const layout = dbProject.state.layouts[props.layoutId];
  const annotation = layout.annotations[props.annotationIndex];
  const currentLabel = getTextFromAnnotation(annotation)
  const [labelText, setLabelText] = useState(currentLabel ?? "");

  const ns = "map-display.speed-dial.rename-annotation.";

  const onCancel = () => {
    props.onClose();
  }

  const onConfirm = () => {
    const updatedAnnotations = structuredClone(layout.annotations);
    if (annotation.type === AnnotationType.label && !labelText) {
      // we dont want an empty label, treat as cancel
      return onCancel();
    }
    if (labelText === currentLabel) {
      // we dont need to update, treat as cancel
      return onCancel();
    }
    if (updatedAnnotations[props.annotationIndex].lineAnnotation) updatedAnnotations[props.annotationIndex].lineAnnotation.text = labelText;
    if (updatedAnnotations[props.annotationIndex].pointAnnotation) updatedAnnotations[props.annotationIndex].pointAnnotation.text = labelText;
    if (updatedAnnotations[props.annotationIndex].textAnnotation) updatedAnnotations[props.annotationIndex].textAnnotation.text = labelText;
    dbProject.pushAction(
        createNewUpdateLayoutPropertyAction(props.layoutId, "annotations", updatedAnnotations, authState)
    );
    props.onClose();
  }

    return (
      <Dialog onClose={onCancel} open={true}>
        <DialogTitle>{t(ns + "title")}</DialogTitle>
          <DialogContent>
              <DialogContentText>
                {t(ns + "type")}: {annotationTypeToString(annotation.type, true)}
              </DialogContentText>
              {
                currentLabel && (
                  <DialogContentText>
                    {t(ns + "label")}: {currentLabel}
                  </DialogContentText>
                )
              }
              <TextField
                autoFocus
                id="label-text"
                fullWidth
                variant="standard"
                value={labelText}
                onChange={(e) => {
                  setLabelText(e.target.value);
                }}
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>{t("cancel")}</Button>
            <Button onClick={onConfirm}>{t("ok")}</Button>
          </DialogActions>
      </Dialog>
    );
  }

  export default EditAnnotationLabelDialog;