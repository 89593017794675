import { IEnhProgress } from "../Progress/IEnhProgress";
import { ProgressScaling } from "../Progress/ProgressScaling";
import { Parallel } from "../Tasks/Parallel";
import { ConstraintCacheStatus } from "./ConstraintCacheStatus";
import { Order1TransitionRange } from "./Order1TransitionRange";
import { SACCentrePivotType } from "./SACCentrePivotType";
import { SACEndGunModel } from "./SACEndGunModel";
import { SACOptimizationProblem } from "./SACOptimizationProblem";
import { SACOptimizationSolution } from "./SACOptimizationSolution";
import { SACOrientation } from "./SACOrientation";
import { TwoWayList } from "./TwoWayList";

import { jsts } from "../jstsLib";

export class QuantizedSACOptimizationState {
    /// <summary>
    /// Do not be tempted to try and reduce this for speed. If it is too small the optimizer can get stuck, unable
    /// to control rate of change of extension angle, because the next step is too far away.
    /// </summary>
    public ExtensionAngleStepCount = 16320 + 1;
    public BestSolution: number[] = [];
    public iPivotAngleMin: number = 0;
    public iPivotAngleMax: number = 0;
    public get MultiThreaded() { return true; }

    private _problem: SACOptimizationProblem;
    public get Problem() { return this._problem; }
    
    private constraintsOrder0Cache = new TwoWayList(
        () => new TwoWayList(() => ConstraintCacheStatus.NotCached)
    );
    private constraintsOrder2Cache: TwoWayList<TwoWayList<TwoWayList<number>>>;

    public constructor(problem: SACOptimizationProblem) {
        this._problem = problem;
    }

    public Initialize(progress: IEnhProgress): boolean {
        progress.SetStatusMessage("Initializing SAC optimization");
        progress.Report(0);

        if (this.Problem.CentrePivotType === SACCentrePivotType.Partial)
        {
            this.iPivotAngleMin = Math.floor(this.Problem.PartialPivotAngleDegreesMin * this.Problem.PivotAngleStepCount / 360.0);
            this.iPivotAngleMax = Math.ceil(this.Problem.PartialPivotAngleDegreesMax * this.Problem.PivotAngleStepCount / 360.0);
            if (this.Problem.PartialPivotAngleDegreesMax < this.Problem.PartialPivotAngleDegreesMin) {
                this.iPivotAngleMax += this.Problem.PivotAngleStepCount;
            }
        }
        else {
            this.iPivotAngleMin = 0;
            this.iPivotAngleMax = this.Problem.PivotAngleStepCount - 1;
        }
        
        this.BestSolution = Array(this.iPivotAngleMax - this.iPivotAngleMin + 1).fill(0);

        this.constraintsOrder0Cache = new TwoWayList(
            () => new TwoWayList(() => ConstraintCacheStatus.NotCached)
        );
        this.constraintsOrder2Cache = new TwoWayList(() => null);

        if (!this.TestOrder1ProblemFeasible(new ProgressScaling(progress, 0, 30))) {
            return false;
        }

        this.InitilizeConstraintsCache(new ProgressScaling(progress, 30, 70));

        return true;
    }

    public GetAlpha(iExtensionAngle: number): number {
        var a = this.Problem.Model.ModelConstraints.MaxExtensionAngleDegrees -
            (this.Problem.Model.ModelConstraints.MaxExtensionAngleDegrees - this.Problem.Model.ModelConstraints.MinExtensionAngleDegrees)
            * iExtensionAngle / (this.ExtensionAngleStepCount - 1);
        a *= Math.PI / 180.0;
        if (this.Problem.Model.Configuration.Orientation === SACOrientation.Leading) {
            return 2 * Math.PI - a;
        }
        else {
            return a;
        }
    }

    public CheckConstraintsOrder0(iPivotAngle: number, iExtensionAngle: number): boolean {
        let cacheList = this.constraintsOrder0Cache.getIndex(iPivotAngle);
        if (cacheList === null) {
            this.constraintsOrder0Cache.setIndex(
                iPivotAngle, 
                new TwoWayList(() => ConstraintCacheStatus.NotCached)
            );
            cacheList = this.constraintsOrder0Cache.getIndex(iPivotAngle);
        }

        let r = cacheList.getIndex(iExtensionAngle);
        if (r === ConstraintCacheStatus.NotCached) {
            const temp = this.CheckConstraintsOrder0NoCache(iPivotAngle, iExtensionAngle) ? ConstraintCacheStatus.Pass : ConstraintCacheStatus.Fail;
            cacheList.setIndex(iExtensionAngle, temp);
            r = temp;
        }

        return r === ConstraintCacheStatus.Pass;
    }

    private getWheelLineStrings(theta: number, alpha: number) {
        const r1 = this.Problem.Model.r(theta - 0.5 * this.Problem.PivotAngleSpacingRadians, alpha);
        const w1 = this.Problem.Model.w(theta - 0.5 * this.Problem.PivotAngleSpacingRadians, alpha);
        const w11 = new jsts.geom.Coordinate(r1.X + w1.X + this.Problem.PivotCentre.x,
            r1.Y + w1.Y + this.Problem.PivotCentre.y);
        const w12 = new jsts.geom.Coordinate(r1.X - w1.X + this.Problem.PivotCentre.x,
            r1.Y - w1.Y + this.Problem.PivotCentre.y);
        
        const r2 = this.Problem.Model.r(theta, alpha);
        const w2 = this.Problem.Model.w(theta, alpha);
        const w21 = new jsts.geom.Coordinate(r2.X + w2.X + this.Problem.PivotCentre.x,
            r2.Y + w2.Y + this.Problem.PivotCentre.y);
        const w22 = new jsts.geom.Coordinate(r2.X - w2.X + this.Problem.PivotCentre.x,
            r2.Y - w2.Y + this.Problem.PivotCentre.y);
        
        const r3 = this.Problem.Model.r(theta + 0.5 * this.Problem.PivotAngleSpacingRadians, alpha);
        const w3 = this.Problem.Model.w(theta + 0.5 * this.Problem.PivotAngleSpacingRadians, alpha);
        const w31 = new jsts.geom.Coordinate(r3.X + w3.X + this.Problem.PivotCentre.x,
            r3.Y + w3.Y + this.Problem.PivotCentre.y);
        const w32 = new jsts.geom.Coordinate(r3.X - w3.X + this.Problem.PivotCentre.x,
            r3.Y - w3.Y + this.Problem.PivotCentre.y);


        const points1: (jsts.geom.Coordinate)[] = [ w11, w21, w31 ];
        const points2: (jsts.geom.Coordinate)[] = [ w12, w22, w32 ];
        
        const ls1 = new jsts.geom.GeometryFactory().createLineString(points1);
        const ls2 = new jsts.geom.GeometryFactory().createLineString(points2);
        
        return [ ls1, ls2 ];
    }

    public CheckConstraintsOrder0NoCache(iPivotAngle: number, iExtensionAngle: number): boolean {
        var theta = this.Problem.GetTheta(iPivotAngle);
        var alpha = this.GetAlpha(iExtensionAngle);

        var collisionPolygonWithSwingSpan = this.Problem.Model.GetCollisionPolygon(
                    theta,
                    alpha,
                    this.Problem.PivotAngleSpacingRadians, // NOTE: 0.5 * is an assumed bug in RDP2
                    true
                    );
        collisionPolygonWithSwingSpan = new jsts.geom.util.AffineTransformation(
            1.0, 0.0, this.Problem.PivotCentre.x,
            0.0, 1.0, this.Problem.PivotCentre.y
        ).transform(collisionPolygonWithSwingSpan);
        
        // Update 2024.04.15: Previously RDP2 did not include the swing arm when considering the equipment boundary
        // collision. This update includes the swing arm in this collision detection.
        if (!this.Problem.ObstacleConstraints.EquipmentBoundary.contains(collisionPolygonWithSwingSpan)) {
            return false;
        }
        
        {
            // Update 2024.11.25: It was discoused in a meeting allowing S-Tower to be
            // allowed to be pushed inside of the H-Tower. Here an additional check is included
            // to account for this.
            let sTowerCollisionPolygon = this.Problem.Model.GetSTowerCollisionPolygon(
                theta,
                alpha,
                this.Problem.PivotAngleSpacingRadians, // NOTE: 0.5 * is an assumed bug in RDP2
                );
            sTowerCollisionPolygon = new jsts.geom.util.AffineTransformation(
                1.0, 0.0, this.Problem.PivotCentre.x,
                0.0, 1.0, this.Problem.PivotCentre.y
            ).transform(sTowerCollisionPolygon);
            if (!this.Problem.ObstacleConstraints.STowerBoundary.contains(sTowerCollisionPolygon)) {
                return false;
            }
        }

        // Update 2024.04.15: Previously RDP2 did no consider swing span when the SAC is retracted to 90 degrees or more.
        // This update has removed that constraint to always consider the swing span:
        for (const obstacle of this.Problem.ObstacleConstraints.SpanObstacles) {
            if (collisionPolygonWithSwingSpan.intersects(obstacle)) {
                return false;
            }
        }

        // NOTE: The wheel detection has been updated since RDP2. RDP2 was
        // only checking for wheel collisions at points (at theta). This
        // update forms a line string between theta +/- PivotAngleSpacingRadians
        const wheelTracks = this.getWheelLineStrings(theta, alpha);   
        for (const obstacle of this.Problem.ObstacleConstraints.WheelObstacles) {
            if (wheelTracks.some(wt => wt.intersects(obstacle))) {
                return false;
            }
        }

        return true;
    }

//         public void DrawOrder0Collision(int iPivotAngle, int iExtensionAngle)
//         {
//             var theta = Problem.GetTheta(iPivotAngle);
//             var alpha = GetAlpha(iExtensionAngle);

//             var collisionPolygon = Problem.Model.GetCollisionPolygon(
//                        theta,
//                        alpha,
//                        0.5 * Problem.PivotAngleSpacingRadians,
//                        true
//                        );
//             collisionPolygon = new NetTopologySuite.Geometries.Utilities.AffineTransformation(new[]
//             {
//                 1.0, 0.0, Problem.PivotCentre.X,
//                 0.0, 1.0, Problem.PivotCentre.Y
//             }).Transform(collisionPolygon);

//             Debug.WriteLine("<svg viewBox=\"{0} {1} {2} {3}\">",
//                 Problem.ObstacleConstraints.EquipmentBoundary.EnvelopeInternal.MinX,
//                 Problem.ObstacleConstraints.EquipmentBoundary.EnvelopeInternal.MinY,
//                 Problem.ObstacleConstraints.EquipmentBoundary.EnvelopeInternal.Width,
//                 Problem.ObstacleConstraints.EquipmentBoundary.EnvelopeInternal.Height
//                 );

//             DrawPolygon(Problem.ParentSystemPolygon.ToPolygon());
//             DrawPolygon(Problem.ObstacleConstraints.EquipmentBoundary);
//             DrawPolygon(collisionPolygon);
        

//             foreach (var obstacle in Problem.ObstacleConstraints.SpanObstacles)
//             {
//                 DrawPolygon(obstacle);
//             }

//             foreach (var obstacle in Problem.ObstacleConstraints.WheelObstacles)
//             {
//                 DrawPolygon(obstacle);
//             }


//             Debug.WriteLine("</svg>");
//         }

//         void DrawPolygon(GeoAPI.Geometries.IGeometry polygon)
//         {
//             Debug.WriteLine("<polyline points=\"");
//             foreach (var coord in polygon.Coordinates)
//             {
//                 Debug.Write(string.Format("{0},{1} ", coord.X, coord.Y));
//             }
//             Debug.Write(string.Format("{0},{1} ", polygon.Coordinates[0].X, polygon.Coordinates[0].Y));
//             Debug.WriteLine("\" style=\"fill:none;stroke:black;stroke-width:1\" />");
//         }

    /// <summary>
    /// Not necessarily the same as ExtensionAngleStepCount - 1 because sometimes the steering rate constraint fails
    /// to maintain extension angle for vary small extension angles.
    /// </summary>
    public iExtensionAngleMaxFeasible: number = 0;

    private TestOrder1ProblemFeasible(progress: IEnhProgress): boolean {
        progress.SetStatusMessage("Scanning for minimum feasible extension angle");
        progress.Report(0);

        if (!this.CheckConstraintsOrder1NoCache(0, 0)) {
            return false;
        }

        for (this.iExtensionAngleMaxFeasible = this.ExtensionAngleStepCount - 1;
            this.iExtensionAngleMaxFeasible >= 1 &&
            (!this.CheckConstraintsOrder1NoCache(this.iExtensionAngleMaxFeasible, this.iExtensionAngleMaxFeasible) ||
            !this.CheckConstraintsOrder1NoCache(this.iExtensionAngleMaxFeasible, this.iExtensionAngleMaxFeasible - 1) ||
            !this.CheckConstraintsOrder1NoCache(this.iExtensionAngleMaxFeasible - 1, this.iExtensionAngleMaxFeasible) ||
            !this.CheckConstraintsOrder2(this.iExtensionAngleMaxFeasible - 1, this.iExtensionAngleMaxFeasible - 1, this.iExtensionAngleMaxFeasible) ||
            !this.CheckConstraintsOrder2(this.iExtensionAngleMaxFeasible, this.iExtensionAngleMaxFeasible - 1, this.iExtensionAngleMaxFeasible - 1));
            this.iExtensionAngleMaxFeasible--)
        {
        }

        return true;
    }

    

    public constraintsOrder1CacheFwd: Order1TransitionRange[] = [];
    public constraintsOrder1CacheRev: Order1TransitionRange[] = [];

    InitilizeConstraintsCache(progress: IEnhProgress): void {
        progress.SetStatusMessage("Preparing order-1 constraints lookup");
        progress.Report(0);

        Parallel.Invoke(
            () => {
                this.constraintsOrder1CacheFwd = Array.from({ length: this.iExtensionAngleMaxFeasible + 1 }, () => new Order1TransitionRange());
                for (let iExtensionAngle = 0; iExtensionAngle <= this.iExtensionAngleMaxFeasible; iExtensionAngle++) {
                    this.constraintsOrder1CacheFwd[iExtensionAngle].iExtensionAngleMin
                        = iExtensionAngle === 0 ? 0 : this.constraintsOrder1CacheFwd[iExtensionAngle - 1].iExtensionAngleMin;
                    while (!this.CheckConstraintsOrder1NoCache(iExtensionAngle, this.constraintsOrder1CacheFwd[iExtensionAngle].iExtensionAngleMin)) {
                        this.constraintsOrder1CacheFwd[iExtensionAngle].iExtensionAngleMin++;
                    }

                    this.constraintsOrder1CacheFwd[iExtensionAngle].iExtensionAngleMax
                        = iExtensionAngle === 0 ? 0 : this.constraintsOrder1CacheFwd[iExtensionAngle - 1].iExtensionAngleMax;
                    while (this.constraintsOrder1CacheFwd[iExtensionAngle].iExtensionAngleMax < this.iExtensionAngleMaxFeasible &&
                        this.CheckConstraintsOrder1NoCache(iExtensionAngle, this.constraintsOrder1CacheFwd[iExtensionAngle].iExtensionAngleMax + 1)) {
                        this.constraintsOrder1CacheFwd[iExtensionAngle].iExtensionAngleMax++;
                    }
                }
            },
            () => {
                this.constraintsOrder1CacheRev = Array.from({ length: this.iExtensionAngleMaxFeasible + 1 }, () => new Order1TransitionRange());
                for (let iExtensionAngle = 0; iExtensionAngle <= this.iExtensionAngleMaxFeasible; iExtensionAngle++) {
                    this.constraintsOrder1CacheRev[iExtensionAngle].iExtensionAngleMin
                        = iExtensionAngle === 0 ? 0 : this.constraintsOrder1CacheRev[iExtensionAngle - 1].iExtensionAngleMin;
                    while (!this.CheckConstraintsOrder1NoCache(this.constraintsOrder1CacheRev[iExtensionAngle].iExtensionAngleMin, iExtensionAngle)) {
                        this.constraintsOrder1CacheRev[iExtensionAngle].iExtensionAngleMin++;
                    }

                    this.constraintsOrder1CacheRev[iExtensionAngle].iExtensionAngleMax
                        = iExtensionAngle === 0 ? 0 : this.constraintsOrder1CacheRev[iExtensionAngle - 1].iExtensionAngleMax;
                    while (this.constraintsOrder1CacheRev[iExtensionAngle].iExtensionAngleMax < this.iExtensionAngleMaxFeasible &&
                        this.CheckConstraintsOrder1NoCache(this.constraintsOrder1CacheRev[iExtensionAngle].iExtensionAngleMax + 1, iExtensionAngle)){
                        this.constraintsOrder1CacheRev[iExtensionAngle].iExtensionAngleMax++;
                    }
                }
            }
        );
    }
    
    private CheckConstraintsOrder1NoCache(iExtensionAngle0: number, iExtensionAngle1: number): boolean {
        const alpha0 = this.GetAlpha(iExtensionAngle0);
        const alpha1 = this.GetAlpha(iExtensionAngle1);
        const alpha = 0.5 * (alpha0 + alpha1);
        const dalpha_dtheta = (alpha1 - alpha0) / this.Problem.PivotAngleSpacingRadians;

        return this.Problem.Model.CheckSpeedConstraint(alpha, dalpha_dtheta) &&
            this.Problem.Model.CheckSteeringAngleConstraint(alpha, dalpha_dtheta);
    }


    public CheckConstraintsOrder1(iExtensionAngle0: number, iExtensionAngle1: number): boolean {
        return iExtensionAngle1 >= this.constraintsOrder1CacheFwd[iExtensionAngle0].iExtensionAngleMin
            && iExtensionAngle1 <= this.constraintsOrder1CacheFwd[iExtensionAngle0].iExtensionAngleMax;
    }

    public GetSteeringRateDegreesPerMetre(iExtensionAngle0: number, iExtensionAngle1: number, iExtensionAngle2: number): number {
        let cacheList1 = this.constraintsOrder2Cache.getIndex(iExtensionAngle0);
        if (cacheList1 === null) {
            this.constraintsOrder2Cache.setIndex(
                iExtensionAngle0, 
                new TwoWayList(() => null)
            );
            cacheList1 = this.constraintsOrder2Cache.getIndex(iExtensionAngle0);
        }
        let cacheList2 = cacheList1.getIndex(iExtensionAngle1);
        if (cacheList2 === null) {
            cacheList1.setIndex(iExtensionAngle1, new TwoWayList(() => Number.NaN, 0));
            cacheList2 = cacheList1.getIndex(iExtensionAngle1);
        }

        let r = cacheList2.getIndex(iExtensionAngle2);
        if (Number.isNaN(r)) {
            cacheList2.setIndex(iExtensionAngle2, this.GetSteeringRateDegreesPerMetreNoCache(iExtensionAngle0, iExtensionAngle1, iExtensionAngle2));
            r = cacheList2.getIndex(iExtensionAngle2);
        }

        return r;
    }

    public CheckConstraintsOrder2(iExtensionAngle0: number, iExtensionAngle1: number, iExtensionAngle2: number): boolean {
        return Math.abs(this.GetSteeringRateDegreesPerMetre(iExtensionAngle0, iExtensionAngle1, iExtensionAngle2))
            <= this.Problem.Model.ModelConstraints.MaxSteeringRateDegreesPerMetre;
    }

    GetSteeringRateDegreesPerMetreNoCache(iExtensionAngle0: number, iExtensionAngle1: number, iExtensionAngle2: number): number {
        const alpha0 = this.GetAlpha(iExtensionAngle0);
        const alpha1 = this.GetAlpha(iExtensionAngle1);
        const alpha2 = this.GetAlpha(iExtensionAngle2);
        const dalpha_dtheta01 = (alpha1 - alpha0) / this.Problem.PivotAngleSpacingRadians;
        const dalpha_dtheta12 = (alpha2 - alpha1) / this.Problem.PivotAngleSpacingRadians;
        const dalpha_dtheta = 0.5 * (dalpha_dtheta01 + dalpha_dtheta12); // Assume average rate of change between each side of the centre point
        const d2alpha_dtheta2 = (dalpha_dtheta12 - dalpha_dtheta01) / this.Problem.PivotAngleSpacingRadians;

        var dbeta_dtheta = (this.Problem.Model.beta(0.5 * (alpha1 + alpha2), dalpha_dtheta12) - this.Problem.Model.beta(0.5 * (alpha0 + alpha1), dalpha_dtheta01))
            * (180.0 / Math.PI) / this.Problem.PivotAngleSpacingRadians;
        return dbeta_dtheta / this.Problem.Model.dr_dtheta_Length(alpha1, dalpha_dtheta);
    }

    public GetStepSACCoverageAreaEstimate(iPivotAngle: number, iExtensionAngle: number): number {
        return Math.max(0, Math.sin(this.GetAlpha(iExtensionAngle) - 0.5 * Math.PI));
    }

    public GetNextPivotAngleIndex(iPivotAngle: number): number {
        if (iPivotAngle === this.iPivotAngleMax) {
            return this.Problem.CentrePivotType === SACCentrePivotType.Full ? this.iPivotAngleMin : -1;
        }
        else {
            return iPivotAngle + 1;
        }
    }

    public GetPrevPivotAngleIndex(iPivotAngle: number): number {
        if (iPivotAngle === this.iPivotAngleMin) {
            return this.Problem.CentrePivotType === SACCentrePivotType.Full ? this.iPivotAngleMax : -1;
        }
        else {
            return iPivotAngle - 1;
        }
    }
    
    public VerifyConstraints(progress: IEnhProgress): boolean {
        progress.SetStatusMessage("Verifying constraints");
        progress.Report(0);

// #if DEBUG
//             Debug.WriteLine("Theta (deg),Extension Angle CW (deg),Collision,HTWR Speed (m/deg),STWR Speed (m/deg),HTWR/STWR Speed ratio,Steering Angle (deg),Steering Angle RoC (deg/m)");
// #endif

        let area = 0;
        for (let iPivotAngle1 = this.iPivotAngleMin; iPivotAngle1 <= this.iPivotAngleMax; iPivotAngle1++) {
            const iPivotAngle0 = this.GetPrevPivotAngleIndex(iPivotAngle1);
            const iPivotAngle2 = this.GetNextPivotAngleIndex(iPivotAngle1);

            const iExtensionAngle0 = iPivotAngle0 !== -1 ? this.BestSolution[iPivotAngle0 - this.iPivotAngleMin] : -1;
            const iExtensionAngle1 = this.BestSolution[iPivotAngle1 - this.iPivotAngleMin];
            const iExtensionAngle2 = iPivotAngle2 !== -1 ? this.BestSolution[iPivotAngle2 - this.iPivotAngleMin] : -1;

// #if DEBUG
//                 const log = string.Format("{0:0.0},{1:0.0},{2:0}",
//                     (Problem.GetTheta(iPivotAngle1) * 180.0 / Math.PI) % 360.0,
//                     (GetAlpha(iExtensionAngle1) * 180.0 / Math.PI),
//                     CheckConstraintsOrder0(iPivotAngle1, iExtensionAngle1) ? 1 : 0
//                     );

//                 if (iExtensionAngle2 !== -1) {
//                     var alpha1 = GetAlpha(iExtensionAngle1);
//                     var alpha2 = GetAlpha(iExtensionAngle2);
//                     var alpha = 0.5 * (alpha1 + alpha2);
//                     var dalpha_dtheta = (alpha2 - alpha1) / Problem.PivotAngleSpacingRadians;

//                     Problem.Model.CheckSteeringAngleConstraint(alpha, dalpha_dtheta);

//                     var dr_dtheta_Length = Problem.Model.dr_dtheta_Length(alpha, dalpha_dtheta);
//                     var lenp = Problem.Model.Configuration.PivotSpanLengthMetres;
//                     var speedRatio = dr_dtheta_Length / lenp;
//                     var steeringAngleDegrees = Problem.Model.beta(alpha, dalpha_dtheta) * 180.0 / Math.PI;

//                     log += string.Format(",{0:0.0},{1:0.0},{2:0.00},{3:0.0}",
//                         lenp * Math.PI / 180.0,
//                         dr_dtheta_Length * Math.PI / 180.0,
//                         speedRatio,
//                         steeringAngleDegrees);


//                     if (iExtensionAngle0 !== -1)
//                     {
//                         var steeringRate = GetSteeringRateDegreesPerMetre(iExtensionAngle0, iExtensionAngle1, iExtensionAngle2);

//                         log += string.Format(",{0:0.0}", steeringRate);
//                     }
//                 }

//                 Debug.WriteLine(log);
// #endif

            if (!this.CheckConstraintsOrder0(iPivotAngle1, iExtensionAngle1))
                return false;
            if (iExtensionAngle2 !== -1 && !this.CheckConstraintsOrder1(iExtensionAngle1, iExtensionAngle2))
                return false;
            if (iExtensionAngle0 !== -1 && iExtensionAngle2 !== -1 && !this.CheckConstraintsOrder2(iExtensionAngle0, iExtensionAngle1, iExtensionAngle2))
                return false;

            area += this.GetStepSACCoverageAreaEstimate(iPivotAngle1, iExtensionAngle1);
        }

        return true;
    }

    public getSmoothedAlphas(alphas: number[]): number[] {
        const maxSmoothChange = Math.abs(this.GetAlpha(0) - this.GetAlpha(1));

        const smoothed = new Array<number>(this.iPivotAngleMax - this.iPivotAngleMin + 1).fill(0);
        smoothed[0] = alphas[0];
        smoothed[1] = alphas[1];
        smoothed[this.iPivotAngleMax - this.iPivotAngleMin - 1] = alphas[this.iPivotAngleMax - this.iPivotAngleMin - 1];
        smoothed[this.iPivotAngleMax - this.iPivotAngleMin] = alphas[this.iPivotAngleMax - this.iPivotAngleMin];
        for (let iPivotAngle = this.iPivotAngleMin + 2; iPivotAngle <= this.iPivotAngleMax - 2; iPivotAngle++) {
            let a = alphas[iPivotAngle - 2 - this.iPivotAngleMin];
            let b = alphas[iPivotAngle - 1 - this.iPivotAngleMin];
            let c = alphas[iPivotAngle - this.iPivotAngleMin];
            let d = alphas[iPivotAngle + 1 - this.iPivotAngleMin];
            let e = alphas[iPivotAngle + 2 - this.iPivotAngleMin];
            let s = (a + b + c + d + e) / 5.0;
            if (this.BestSolution[iPivotAngle - this.iPivotAngleMin] !== 0 &&
                Math.abs(s - alphas[iPivotAngle - this.iPivotAngleMin]) < maxSmoothChange) {
                smoothed[iPivotAngle - this.iPivotAngleMin] = s;
            }
            else {
                smoothed[iPivotAngle - this.iPivotAngleMin] = alphas[iPivotAngle - this.iPivotAngleMin];
            }
        }
        
        return smoothed;
    }

    public GetSolutionRDP(progress: IEnhProgress): SACOptimizationSolution {
        progress.SetStatusMessage("Smoothing SAC solution");
        progress.Report(0);        
        const alphas = this.BestSolution.map(x => this.GetAlpha(x));
        const extensionAngles = this.getSmoothedAlphas(alphas);        

        progress.SetStatusMessage("Generating coverage and wheel tracks");
        progress.Report(33);
        var solution = SACOptimizationSolution.FromAlphas(
            this.Problem,
            this.iPivotAngleMin, this.iPivotAngleMax,
            extensionAngles
        );

        // console.log("best soln")
        // console.log(JSON.stringify(this.BestSolution))

        // console.log("alphas")
        // console.log(JSON.stringify(alphas))
        
        // console.log("smoothed")
        // console.log(JSON.stringify(extensionAngles))

        // console.log("PROBLEM")
        // console.log(JSON.stringify(this.Problem))

        progress.SetStatusMessage("Calculating end gun coverage");
        progress.Report(66);
        const fullyExtended = this.BestSolution.map(i => i === 0);
        var endGunModel = new SACEndGunModel(this.Problem, this.iPivotAngleMin, this.iPivotAngleMax, alphas, fullyExtended);
        var endGuns = endGunModel.GetEndGunData(this.Problem.endGunThrowsMeters, solution.CoverageShape);
        solution.endGuns = endGuns;

        return solution;
    }
}