import { Text, View } from "@react-pdf/renderer";
import * as React from "react";

export const generateFexWarningNoteTextElementsFromLines = (lines: string[]): React.JSX.Element | null => {
    if (!lines.length) return null;
    return (
        <Text style={{fontWeight: "bold"}}>{lines.join(" ") + "."}</Text>
    );
}
export const generateFexWarningBoxFromLines = (lines: string[]): React.JSX.Element | null => {
    if (!lines.length) return null;
    return (
        <View style={{borderTop: "1 solid black"}}>
            {lines.map((t, idx) => <Text key={idx} style={{fontWeight: "bold", textAlign: "center"}}>{t}</Text>)}
        </View>
    );
}