import { t } from "i18next";
import { IFEXContext } from "rdptypes/foreignExchange/interfaces";
import IAction from "rdptypes/IAction";
import IUserData from "rdptypes/IUserData";
import { IFEXRate, ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import { partsPackages } from "roedata/data";
import { createNewMultiAction } from "../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import IAuthState from "../../auth/IAuthState";
import IDbProject from "../../db/IDbProject";
import { IDocumentType } from "../../docGeneration/DocumentGenerationHelpers";
import { calculateDownPayment, IPricingHelperDownPayment } from "./downPaymentPricing";
import { calculateIPrice, IPricingHelperIPrice } from "./iPricePricing";
import { calcualteMiscItems, IPricingHelperMiscItems } from "./miscItemsPricing";
import { calculatePriceComputation, IPricingHelperPriceComputation } from "./priceComputation";
import { calculateSystemPricing, IPricingHelperSystemPricing } from "./systemPricing";
import { calculateTotals, IPricingHelperTotals } from "./totalsPricing";

interface IPricingHelperArgs {
    system: ISystemBase;
    fex: IFEXContext;
    user: IUserData;
}

export type IPricingHelperLocalCurrency = {
    symbol: string;
} & (
    { fexAvailable: false } |
    { 
        fexAvailable: true;
        rate: IFEXRate; 
    }
)
type IPricingHelperFEXUpdateAvailable = {
    available: false;
} | {
    available: true;
    rate: IFEXRate;
}
export interface IPricingHelperResult {
    localCurrency?: IPricingHelperLocalCurrency;
    setLocalCurrency: (localCurrency: string, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => boolean;
    updateLocalCurrencyRate: (dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => boolean;
    localCurrencyRateUpdateAvailable: IPricingHelperFEXUpdateAvailable;
    system: IPricingHelperSystemPricing;
    miscItems: IPricingHelperMiscItems;
    installation: IPricingHelperIPrice;
    freight: IPricingHelperIPrice;
    pivotPad: IPricingHelperIPrice;
    tradeIn: IPricingHelperIPrice;
    totals: IPricingHelperTotals;
    downPayment: IPricingHelperDownPayment;
    priceComputation: IPricingHelperPriceComputation;
    helpers: {
        convertToLocalIfNeeded: (value: number, valueCurrency: string) => number;
        generateFexWarningNoteLines: (displayingLocal: boolean, valueCurrency?: string) => string[];
        getDocDisplayCurrency: (docType: IDocumentType) => string;
        convertToCurrencyIfNeeded: (value: number, valueCurrency: string, requiredCurrency: string) => number;
        applySystemDiscountToValueByDocType: (docType: IDocumentType, price: number) => number;
    }
}

export const pricingHelper = (args: IPricingHelperArgs): IPricingHelperResult => {

    const pre2024110401 = args.system.partsPackageId < "2024110401";
    const systemProposalPricing = args.system.QuoteProperties?.ProposalInformation.pricing;
    const localCurrencySymbol = systemProposalPricing?.currency;
    const salesTaxMultiplier = systemProposalPricing.salesTaxPercent > 0 ? (systemProposalPricing.salesTaxPercent/100) : 1;
    const { roeData } = partsPackages[args.system.partsPackageId];

    let localCurrency: IPricingHelperLocalCurrency | undefined = undefined;
    if (localCurrencySymbol !== "USD") {
        let rate: IFEXRate;
        if (pre2024110401) {
            // then no rate was saved, use current FEX value
            if (args.fex.available && args.fex.validAtTimestampMs) {
                const converted = args.fex.convertTo(1, localCurrencySymbol);
                if (converted.symbol === localCurrencySymbol) {
                    rate = {
                        exchangeRate_1USDToSymbol: converted.value,
                        validAtTimestampMs: args.fex.validAtTimestampMs
                    }
                }
            }
        }
        else {
            rate = systemProposalPricing.rate;
        }
        let fexAvailable = !!rate;
        localCurrency = {
            symbol: localCurrencySymbol,
            fexAvailable,
            rate
        }
    }
    let pre2024110401_Currency = pre2024110401 ? "USD" : (localCurrency?.symbol ?? "USD");

    const getLocalRateUpdateAvailable = (): IPricingHelperFEXUpdateAvailable => {
        if (pre2024110401) return { available: false };
        if (!localCurrency) return  { available: false };
        if (!localCurrency.fexAvailable) return  { available: false };
        const fexUpdateAvailable = args.fex.available && 
            args.fex.validAtTimestampMs &&
            args.fex.validAtTimestampMs > localCurrency.rate.validAtTimestampMs;
        if (!fexUpdateAvailable) return { available: false };
        
        const converted = args.fex.convertTo(1, localCurrency.symbol);
        if (converted.symbol !== localCurrency.symbol) return { available: false };
        return  { 
            available: true,
            rate: {
                validAtTimestampMs: args.fex.validAtTimestampMs,
                exchangeRate_1USDToSymbol: converted.value
            }
        };
    }
    const localCurrencyRateUpdateAvailable = getLocalRateUpdateAvailable();
    const updateLocalCurrencyRate = (dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => {
        if (!localCurrencyRateUpdateAvailable || !localCurrencySymbol) return;
        return setLocalCurrency(localCurrencySymbol, dbPrj, layoutId, systemId, authState);
    }
    const setLocalCurrency = (localCurrency: string, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState): boolean => {
        if (pre2024110401) {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "QuoteProperties.ProposalInformation.pricing.currency",
                localCurrency,
                authState));
            return true;
        }
        if (args.fex.available && args.fex.validAtTimestampMs) {
            if (localCurrency in args.fex.symbols) {
                const converted = args.fex.convertTo(1, localCurrency);
                if (converted.symbol === localCurrency) {
                    args.system.QuoteProperties?.ProposalInformation.pricing.currency
                    args.system.QuoteProperties?.ProposalInformation.pricing.rate.exchangeRate_1USDToSymbol
                    args.system.QuoteProperties?.ProposalInformation.pricing.rate.validAtTimestampMs
                    const actions: IAction[] = [
                        createNewUpdateSystemPropertyAction(
                            layoutId,
                            systemId,
                            "QuoteProperties.ProposalInformation.pricing.currency",
                            localCurrency,
                            authState
                        ),
                        createNewUpdateSystemPropertyAction(
                            layoutId,
                            systemId,
                            "QuoteProperties.ProposalInformation.pricing.rate",
                            { 
                                exchangeRate_1USDToSymbol: converted.value,
                                validAtTimestampMs: args.fex.validAtTimestampMs,
                            },
                            authState
                        )
                    ]
                    dbPrj.pushAction(
                        createNewMultiAction(actions, authState)
                    );
                    return true;
                }
            }
        }
        return false;
    }

    const priceComputation = calculatePriceComputation(systemProposalPricing, pre2024110401_Currency);
    const systemPricing = calculateSystemPricing(
        args.system, 
        roeData, 
        args.user, 
        salesTaxMultiplier, 
        priceComputation,
        localCurrency?.fexAvailable && localCurrency.rate
    );
    const miscPricing = calcualteMiscItems(args.system, pre2024110401_Currency, salesTaxMultiplier);
    const installationPricing = calculateIPrice(
        systemProposalPricing.installation, 
        pre2024110401_Currency,
        salesTaxMultiplier, 
        "QuoteProperties.ProposalInformation.pricing.installation"
    );
    const freightPricing = calculateIPrice(
        systemProposalPricing.freight,
        pre2024110401_Currency, 
        salesTaxMultiplier, 
        "QuoteProperties.ProposalInformation.pricing.freight"
    );
    const pivotPadPricing = calculateIPrice(
        systemProposalPricing.pivotPad,
        pre2024110401_Currency, 
        salesTaxMultiplier, 
        "QuoteProperties.ProposalInformation.pricing.pivotPad"
    );
    const tradeInPricing = calculateIPrice(
        systemProposalPricing.tradeIn,
        pre2024110401_Currency, 
        salesTaxMultiplier, 
        "QuoteProperties.ProposalInformation.pricing.tradeIn"
    );
    const totalPricing = calculateTotals(
        systemPricing, miscPricing, installationPricing,
        freightPricing, pivotPadPricing, tradeInPricing,
        localCurrency?.fexAvailable && localCurrency.rate
    );

    const convertToLocalIfNeeded = (value: number, valueCurrency: string): number => {
        if (!localCurrency || !localCurrency.fexAvailable || !localCurrency.rate) {
            if (valueCurrency === "USD") {
                return value;
            }
            else {
                return NaN;
            }
        }
        else {
            if (valueCurrency === localCurrency.symbol) {
                return value;
            }
            else if (valueCurrency === "USD") {
                return value * localCurrency.rate.exchangeRate_1USDToSymbol;
            }
            else {
                return NaN;
            }
        }
    }

    return {
        localCurrency,
        setLocalCurrency,
        updateLocalCurrencyRate,
        localCurrencyRateUpdateAvailable,
        system: systemPricing,
        miscItems: miscPricing,
        installation: installationPricing,
        freight: freightPricing,
        pivotPad: pivotPadPricing,
        tradeIn: tradeInPricing,
        totals: totalPricing,
        downPayment: calculateDownPayment(systemProposalPricing, pre2024110401_Currency, totalPricing),
        priceComputation,
        helpers: {
            convertToLocalIfNeeded,
            convertToCurrencyIfNeeded: (value: number, valueCurrency: string, requiredCurrency: string): number => {
                if (requiredCurrency === (localCurrency?.symbol ?? "USD")) {
                    return convertToLocalIfNeeded(value, valueCurrency);
                }
                if (requiredCurrency === valueCurrency) {
                    return value;
                }
                if (requiredCurrency !== "USD" || !localCurrency || !localCurrency.fexAvailable || valueCurrency !== localCurrency.symbol) {
                    return NaN;
                }
                return value / localCurrency.rate.exchangeRate_1USDToSymbol;
            },
            generateFexWarningNoteLines: (displayingLocal: boolean, valueCurrency: string = "USD"): string[] => {
                const displayCurrency = displayingLocal
                    ? localCurrency?.symbol ?? "USD"
                    : "USD";
                if (valueCurrency === displayCurrency) {
                    return [];
                }
            
                const lines: string[] = [];
                if (localCurrency?.fexAvailable && localCurrency.rate?.validAtTimestampMs) {
                    lines.push(t("exchange-rate-note"));
                    let secondLine = `(1 USD : ${localCurrency.rate.exchangeRate_1USDToSymbol} ${localCurrency.symbol}`
                    secondLine += ` ${t("exchange-rate-effective-as-of")} ${new Date(localCurrency.rate.validAtTimestampMs).toLocaleString()}`;
                    secondLine += ")";
                    lines.push(secondLine);
                }
                else {
                    lines.push(t("exchange-rate-unavailable-note"));
                }
                return lines;
            },
            getDocDisplayCurrency: (docType: IDocumentType): string => {
                if (!(docType === IDocumentType.irrigationSystemPurchaseAgreement || docType === IDocumentType.irrigationSystemProposal)) {
                    return "USD";
                }
                return localCurrency?.symbol ?? "USD";
            },
            applySystemDiscountToValueByDocType: (docType: IDocumentType, price: number): number => {
                if (docType === IDocumentType.irrigationSystemPurchaseAgreement || docType === IDocumentType.irrigationSystemProposal) {
                    return systemPricing.discountPcDecimal * price;
                }
                else {
                    return price;
                }
            }
        }
    }
}