import { Text, View } from "@react-pdf/renderer";
import i18next, { t } from "i18next";
import ISystem from "rdptypes/project/ISystem";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { documentStyles } from "../styles";
import FixedWidthTable from "./table/FixedWidthTable";

export interface Props {
    system: ISystem;
    quote: QuoteClass;
}

const TermsSection: FC<Props> = (props) => {
    let {system, quote} = props;
    let messages: {text: string; indent: boolean, title?: string}[] = [];
    const ns = "proposals.irrigation-system-purchase-agreement.terms.";

    messages.push({text: t(ns + "delays-price-increases.text"),title: t(ns + "delays-price-increases.title"), indent: false},
        {text: t(ns + "taxes.text"), title: t(ns + "taxes.title"), indent: false},
        {text: t(ns + "risk-of-loss.text"), title: t(ns + "risk-of-loss.title"), indent: false},
        {text: t(ns + "system-performance-warranty.text"), title: t(ns + "system-performance-warranty.title"), indent: false},
        {text: t(ns + "in-no-event.text"), indent: false}, 
        {text: t(ns + "customer-acknowledges.text"), indent: false},
        {text: t(ns + "barricades.text"), title: t(ns + "barricades.title"), indent: false },
        {text: t(ns + "barricades-a.text"), indent: true },
        {text: t(ns + "barricades-b.text"), indent: true },
        {text: `${t(ns + "governing-law.text-a")}_________________${t(ns + "governing-law.text-b")}`, title: t(ns + "governing-law.title"), indent: false },
        {text: t(ns + "entire-agreement.text"), title: t(ns + "entire-agreement.title"), indent: false },
        {text: t(ns + "the-undersigned.text"), indent: false });

    let rowsA = [{cells: [{
        text: t(ns + "customer-signature"),
        textAlignRight: true,
        widthPc: 25
    },
    {
        text: "____________________",
        widthPc: 30
    },
    {
        text: i18next.format(t('date'), 'capitalize'),
        textAlignRight: true,
        widthPc: 10
    },
    {
        text: "____________________",
        widthPc: 30
    }]}];

    let rowsB = [{cells: [{
        text: t(ns + "dealer-signature"),
        textAlignRight: true,
        widthPc: 25
    },
    {
        text: "____________________",
        widthPc: 30
    },
    {
        text: i18next.format(t('date'), 'capitalize'),
        textAlignRight: true,
        widthPc: 10
    },
    {
        text: "____________________",
        widthPc: 30
    }]}];

    return (<View>
            <View style={documentStyles.heading}>
                <Text style={documentStyles.sectionHeader}>{t(ns + "title")}</Text>
            </View>
            <View style={{paddingTop: 10}}>
            {
                messages.map((m) => {
                    return <View style={{marginLeft: m.indent ? 30: 0, paddingLeft: 15, paddingRight: 15, marginBottom: 10}}>
                        <Text>{typeof(m.title) !== "undefined" && <Text style={{fontWeight: "bold"}}>{m.title} </Text>}{m.text}</Text>
                    </View>;
                })
            }</View>
            <View style={{marginBottom: 10, paddingTop: 20}}><FixedWidthTable data={{rows: rowsA}}/></View>
            <FixedWidthTable data={{rows: rowsB}}/>
        </View>
    );
};

export default TermsSection;