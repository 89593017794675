/* Use the class function generateSimpleSelectGeoJSON() to get the System GeoJSON
 * The result will be an array of GeoJSON Features
 * Each item will have the properties passed in the constructor
 */

import * as turf from "@turf/turf";
import { Feature, LineString, MultiPolygon, Polygon, Position, feature, length, lineString, multiLineString, multiPolygon, polygon } from "@turf/turf";
import { SideEnum, getSide } from "../../../helpers/SideEnum";
import * as spanf from "../../../helpers/SpanFunctions.part";
import { getSpansWithoutEndOfSystem, getSpansWithoutSAC } from "../../../helpers/Spans";
import { getAftSide, getFwdSide } from "../../../helpers/system";
import { IsPivotingLateral } from "../../../helpers/SystemFunctions.part";
import { ILateralEndGunInformation } from "../../../project/IEndgunInformation";
import ISystem from "../../../project/ISystem";
import { EndGunTypes, SystemTypes, WaterFeedTypes } from "../../../project/ISystemBase.AutoGenerated";
import { lineSliceAlongCustom } from "../../helpers/turf";
import { getLateralLinePolygons, getLateralLineString, getSegmentLength, getSegmentLine_Line, getSegmentLine_Pivot, lateralLineOffset, trimAndMutateSegments } from "./helpers";
import { EndGunDescription, ILateralEndGunPartV2, ILateralSpanTowerV2, ILineSegment, IPivotSegment, IlateralSegment, PivotDirection } from "./interfaces";

interface ILateralGeometryHelperBaseArgs<T extends turf.Properties> {
    system: ISystem;
    properties: T;
}

export default class LateralGeometryHelperBase<T extends turf.Properties> {

    protected system: ISystem;
    protected isActive = false;    
    protected _flexSideDirectionalSpanTowersFromFeedLine: ILateralSpanTowerV2[] | undefined = undefined;
    protected _rigidSideDirectionalSpanTowersFromFeedLine: ILateralSpanTowerV2[] | undefined = undefined;
    protected _feedLineSegments: ILineSegment[] | undefined = undefined;
    protected _flexSideDirectionalEndGunParts: ILateralEndGunPartV2[] | undefined = undefined;
    protected _rigidSideDirectionalEndGunParts: ILateralEndGunPartV2[] | undefined = undefined;

    private _properties: T;    
    
    constructor(args: ILateralGeometryHelperBaseArgs<T>) {

        const { system } = args;

        this.system = system;

        if (!this.system || !this.system.lateral || (this.system.SystemProperties.SystemType !== SystemTypes.HoseFeedMaxigator
            && this.system.SystemProperties.SystemType !== SystemTypes.CanalFeedMaxigator)) {
            throw new Error("This system is not a lateral");
        }
        this._properties = args.properties;
    }
    
    // public accessors:
    public get propertiesForAll(): T {        
        return this._properties;
    }
    public get isCanalFeed(): boolean {
        return this.system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator;
    }
    public get canalWidthFeet(): number {
        return this.isCanalFeed ? this.system.lateral!.canalWidthFeet! : 0;
    }
    public get distanceFromCanalCenterToFwdSide(): number {
        return this.isCanalFeed ? this.system.lateral!.distanceFromCanalCenterToFwdSide! : 0;
    }
    public get distanceFromCanalCenterToAftSide(): number {
        return this.isCanalFeed ? this.system.lateral!.distanceFromCanalCenterToAftSide! : 0;
    }
    public get directionalFlexSideSpanTowers(): ILateralSpanTowerV2[] {
        if (this._flexSideDirectionalSpanTowersFromFeedLine === undefined) {
            this._calculateDirectionalSpanTowersV2();
        }
        return this._flexSideDirectionalSpanTowersFromFeedLine!;
    }
    public get directionalRigidSideSpanTowers(): ILateralSpanTowerV2[] {
        if (this._rigidSideDirectionalSpanTowersFromFeedLine === undefined) {
            this._calculateDirectionalSpanTowersV2();
        }
        return this._rigidSideDirectionalSpanTowersFromFeedLine!;
    }

    // protected accessors:
    protected get lateralCenterLine() {
        return this.system.lateral!.line;
    }
    protected get canalCenterLine() {
        return this.lateralCenterLine;
    }
    protected get hasFlexSide() {
        return getSpansWithoutEndOfSystem(this.system, SideEnum.Flex).length !== 0;
    }
    protected get hasFlexDrop(): boolean {
        return (!this.isCanalFeed && this.system.Lateral.WaterFeed === WaterFeedTypes.CenterFeed && this.system.Lateral.DropFlexSide);
    }
    
    // private accessors:
    private get aftSideSpanCount(): number {
        // includes end boom if defined
        return getSpansWithoutSAC(this.system, getAftSide(this.system)).length;
    }
    private get anticlockwisePivotIndicies() {
        // Note, as the line is reversed to find the line segments,
        // we also need to invert the indicies here
        const anticlockwisePivotLineIndicies = (this.system.lateral.anticlockwisePivotLineIndicies || []).slice();
        for (let i = 0; i < anticlockwisePivotLineIndicies.length; i++) {
            anticlockwisePivotLineIndicies[i] = this.system.lateral.line.coordinates.length - 1 - anticlockwisePivotLineIndicies[i];
        }
        return anticlockwisePivotLineIndicies;
    }
    private get directionalFlexSideEndGunParts(): ILateralEndGunPartV2[] {
        if (this._flexSideDirectionalEndGunParts === undefined) {
            this._calculateDirectionalSpanTowersV2();
        }
        return this._flexSideDirectionalEndGunParts!;
    }
    private get directionalRigidSideEndGunParts(): ILateralEndGunPartV2[] {
        if (this._rigidSideDirectionalEndGunParts === undefined) {
            this._calculateDirectionalSpanTowersV2();
        }
        return this._rigidSideDirectionalEndGunParts!;
    }

    // protected methods:
    protected feedCenterLine(): LineString {
        if (!this.isCanalFeed) {
            return this.lateralCenterLine;
        }
        else {
            return lateralLineOffset(
                this.canalCenterLine, 
                this.feedCenterLineOffset(), 
                { units: 'feet', arc: 'natural' }
            ).geometry;
        }
    }
    protected flexSideCenterLine(excludeFlexDrop = false) {
        if (!this.isCanalFeed) {
            if (!excludeFlexDrop && this.hasFlexDrop) {
                const start = this.system.Lateral.dropSpanStartRelativeToPreviousSpanStart || 0;
                const end = this.system.Lateral.dropSpanEndRelativeToPreviousSpanEnd || 0;
                if (start === 0 && end === 0) {
                    return this.feedCenterLine();
                }
                const l = length(feature(this.feedCenterLine()), { units: 'feet' });
                return lineSliceAlongCustom(
                    this.feedCenterLine(),
                    l - end, // feed line is in reverse
                    start,
                    { units: "feet" }
                ).geometry
            }
            return this.feedCenterLine();
        }
        else {
            return lateralLineOffset(
                this.canalCenterLine, 
                (this.isRigidSideForward() ? -this.distanceFromCanalCenterToAftSide : this.distanceFromCanalCenterToFwdSide), 
                { units: 'feet', arc: 'natural' }
            ).geometry;
        }
    }
    protected _getFlexSideIrrigatedAreaPolygons(): {
        mainSystem: Feature<Polygon>[];
        drop: Feature<Polygon>[];
    } {
        return this._getIrrigatedAreaPolygonsForSide("flex");
    }
    protected _getRigidSideIrrigatedAreaPolygons(): {
        mainSystem: Feature<Polygon>[];
        drop: Feature<Polygon>[];
    } {
        return this._getIrrigatedAreaPolygonsForSide("flanged");
    }
    protected _calculateDirectionalSpanTowersV2() {
        type PartialSpanTower = {
            lengthFeet: number;
            isDropSpan: false;
            spanTowerIndex: number;
            isEndBoom?: boolean;
        } | {
            lengthFeet: number;
            dropSpanStartRelativeToPreviousSpanStart: number;
            dropSpanEndRelativeToPreviousSpanEnd: number;
            isDropSpan: true;
            spanTowerIndex: number;
            isEndBoom?: boolean;
        }
        const partialSpanTowers: PartialSpanTower[] = [];

        const aftSpans = getSpansWithoutSAC(this.system, getAftSide(this.system));
        const fwdSpans = getSpansWithoutSAC(this.system, getFwdSide(this.system));
        const allSpans = [
            ...[...aftSpans.map((x, spanTowerIndex) => ({ span: x, spanTowerIndex, sideEnum: getAftSide(this.system) }))].reverse(),
            ...fwdSpans.map((x, spanTowerIndex) => ({ span: x, spanTowerIndex, sideEnum: getFwdSide(this.system) })),
        ]

        const returnIfCannotCalculate = () => {
            this._flexSideDirectionalSpanTowersFromFeedLine = [];
            this._rigidSideDirectionalSpanTowersFromFeedLine = [];
            this._flexSideDirectionalEndGunParts = [];
            this._rigidSideDirectionalEndGunParts = [];
            this._feedLineSegments = [];
        }
        for (let i = 0; i < allSpans.length; i++) {
            const {span, spanTowerIndex, sideEnum } = allSpans[i];
            const multiplier = getAftSide(this.system) === sideEnum ? -1 : 1;
            if (!Number.isFinite(spanf.LengthInFeet(getSide(this.system, sideEnum), span))) {
                console.log("Invalid span length");
                return returnIfCannotCalculate();
            };
            if (span.Disconnecting) {
                partialSpanTowers.push({
                    lengthFeet: multiplier * spanf.LengthInFeet(getSide(this.system, sideEnum), span),
                    dropSpanStartRelativeToPreviousSpanStart: span.dropSpanStartRelativeToPreviousSpanStart || 0,
                    dropSpanEndRelativeToPreviousSpanEnd: span.dropSpanEndRelativeToPreviousSpanEnd || 0,
                    isDropSpan: true,
                    spanTowerIndex,
                    isEndBoom: span.EndBoom
                })
            }
            else {
                partialSpanTowers.push({
                    lengthFeet: multiplier * spanf.LengthInFeet(getSide(this.system, sideEnum), span),
                    isDropSpan: false,
                    spanTowerIndex,
                    isEndBoom: span.EndBoom
                })
            }
        }

        const rigidSideFwd = this.isRigidSideForward();

        const anticlockwisePivotLineIndicies = this.anticlockwisePivotIndicies;

        const generateFeedLineSegments = (feedLine: LineString) => {
            const lineSegments: ILineSegment[] = [];

            const findAngleBetween = (p1: Position, p2: Position, p3: Position) => {
                const P1 = { x: p1[0], y: p1[1] }
                const P2 = { x: p2[0], y: p2[1] }
                const P3 = { x: p3[0], y: p3[1] }
                const a1 = Math.atan2(P3.y - P1.y, P3.x - P1.x);
                const a2 = Math.atan2(P2.y - P1.y, P2.x - P1.x);
                const delta_a = a2 - a1;
                let degrees_a = delta_a * 180 / Math.PI;
                degrees_a = (degrees_a + 360) % 360;
                return degrees_a;
            }

            const pivotDirectionFromInsideInformation = (segmentInside: boolean, pivotInside: boolean): PivotDirection => {
                return segmentInside
                    ? pivotInside ? "anticlockwise" : "clockwise"
                    : pivotInside ? "clockwise" : "anticlockwise";
            }

            // NOTE: Due to a change in the direction laterals should be drawn for end feed,
            // the end points of the linestring were flipped after drawing.
            // This was fine with just two points, but now there are multiple points on the
            // linestring, it becomes a little harder to understand.
            // As such, the segments start inside with the feed line reversed when creating segments:
            // TODO: in future, add the line string naturally, but make sure the end feed still renders in the correct
            // direction.
            let previousSegmentInside = true; 
            const reversedFeedLine = feedLine.coordinates.slice().reverse();
            let previousP2direction = pivotDirectionFromInsideInformation(previousSegmentInside, anticlockwisePivotLineIndicies.includes(0));
            for (let iLineSegment = 0; iLineSegment < reversedFeedLine.length - 1; iLineSegment++) {
                const lineSegment = lineString(reversedFeedLine.slice(iLineSegment, iLineSegment + 2));

                const p1inside = anticlockwisePivotLineIndicies.includes(iLineSegment);
                const p2inside = anticlockwisePivotLineIndicies.includes(iLineSegment + 1);

                const pC = reversedFeedLine[iLineSegment];
                const pL = reversedFeedLine[iLineSegment - 1];
                const pR = reversedFeedLine[iLineSegment + 1];
                if (pC && pL && pR) {
                    const angle = findAngleBetween(pC, pR, pL);
                    if (!previousSegmentInside) {
                        if (angle <= 180 && !p1inside) {
                            previousSegmentInside = !previousSegmentInside;
                        }
                        else if (angle > 180 && p1inside) {
                            previousSegmentInside = !previousSegmentInside;
                        }
                    }
                    else {
                        if (angle <= 180 && p1inside) {
                            previousSegmentInside = !previousSegmentInside;
                        }
                        else if (angle > 180 && !p1inside) {
                            previousSegmentInside = !previousSegmentInside;
                        }
                    }
                }

                const p2Direction = pivotDirectionFromInsideInformation(previousSegmentInside, p2inside);
                lineSegments.push({
                    type: 'line',
                    p1: lineSegment.geometry.coordinates[0],
                    p2: lineSegment.geometry.coordinates[1],
                    p1Direction: previousP2direction,
                    p2Direction,
                    inside: previousSegmentInside
                });
                previousP2direction = p2Direction;
            }
            return lineSegments;
        }

        const generateLateralSegments = (feedLine: LineString, spanTowers: PartialSpanTower[], startLength: number, feedDrop?: { start: number, end: number }): ILateralSpanTowerV2[] => {
            const lineSegments: ILineSegment[] = generateFeedLineSegments(feedLine); // Length N

            
            const { startPivot, endPivot } = this.system.lateral;

            const lineSegmentsClone = structuredClone(lineSegments).map(x => ({ ...x, retracing: true }));
            let startPivotSegment: IPivotSegment | undefined = undefined;
            const startLineSegments: ILineSegment[] = [];
            if (startPivot) {
                let { lengthAlongCenterLineFt, angleDegrees, retrace } = startPivot;

                // case 1: retrace the lateral
                // angle and lengthAlongCenterLineFt are ignored:
                if (retrace) {
                    startLineSegments.push(...lineSegmentsClone);
                    lineSegmentsClone.splice(0, lineSegmentsClone.length);
                }
                // case 2: partial retrace
                // angle is ignored
                else if (lengthAlongCenterLineFt) {
                    while (lineSegmentsClone.length && getSegmentLength(lineSegmentsClone[0], 0) < lengthAlongCenterLineFt) {
                        lengthAlongCenterLineFt -= getSegmentLength(lineSegmentsClone[0], 0);
                        const addingSegment = lineSegmentsClone.splice(0, 1)[0];
                        startLineSegments.push(addingSegment);
                    }
                    if (lineSegmentsClone.length && lengthAlongCenterLineFt) {
                        const segmentLine = lineString(getSegmentLine_Line(lineSegmentsClone[0], 0));
                        const trimmedLine = lineSliceAlongCustom(segmentLine, 0, lengthAlongCenterLineFt, { units: 'feet' });
                        startLineSegments.push({
                            type: 'line',
                            p1: trimmedLine.geometry.coordinates[0],
                            p2: trimmedLine.geometry.coordinates[1],
                            p1Direction: lineSegmentsClone[0].p1Direction,
                            p2Direction: lineSegmentsClone[0].p2Direction,
                            inside: lineSegmentsClone[0].inside,
                            retracing: true
                        })
                        lineSegmentsClone[0].p1 = trimmedLine.geometry.coordinates[1];
                    }
                }
                // case 3: pivot only
                else if (angleDegrees && angleDegrees <= 180) {
                    const segment = lineSegmentsClone[0];
                    const type = segment.p1Direction;
                    const center = segment.p1;
                    const offset1 = lineString(getSegmentLine_Line(segment, 100));
                    const o1 = offset1.geometry.coordinates[0];
                    const b2 = turf.bearing(center, o1, { final: true });
                    const b1 = b2 - (type === 'anticlockwise' ? -1 : 1) * angleDegrees;
                    startPivotSegment = {
                        type,
                        b1: b1,
                        b2: b2,
                        center,
                        pivotStart: true
                    }
                }
                
                // if any startLineSegments were generated,
                // we need to reverse them
                startLineSegments.reverse();

                for (let i = 0; i < startLineSegments.length; i++) {
                    const s = startLineSegments[i];
                    
                    // switch the ends:
                    const temp = s.p1;
                    s.p1 = s.p2;
                    s.p2 = temp;

                    // switch the end directions
                    const temp2 = s.p1Direction;
                    s.p1Direction = s.p2Direction;
                    s.p2Direction = temp2;

                    // invert all first directions except the first segment:
                    if (i !== 0) {
                        s.p1Direction = s.p1Direction === 'clockwise' ? 'anticlockwise' : 'clockwise';
                    }
                    
                    // invert all last directions except the last segment:
                    if (i !== startLineSegments.length - 1) {
                        s.p2Direction = s.p2Direction === 'clockwise' ? 'anticlockwise' : 'clockwise';
                    }
                }
            }
            
            let endPivotSegment: IPivotSegment | undefined = undefined;
            const endSegments: ILineSegment[] = [];
            if (endPivot) {
                const retrace = !startPivot?.retrace && endPivot.retrace; // we will only retrace if the start did not retrace already
                let lengthAlongCenterLineFt = !startPivot?.retrace && endPivot.lengthAlongCenterLineFt; // we will only partial retrace if the start pivot did retrace already
                const angleDegrees = (endPivot.retrace || endPivot.lengthAlongCenterLineFt) ? 180 : endPivot.angleDegrees;
                
                // reverse the segments
                lineSegmentsClone.reverse();
                for (let i = 0; i < lineSegmentsClone.length; i++) {
                    const s = lineSegmentsClone[i];
                    
                    // switch the ends:
                    const temp = s.p1;
                    s.p1 = s.p2;
                    s.p2 = temp;

                    // switch the end directions
                    const temp2 = s.p1Direction;
                    s.p1Direction = s.p2Direction;
                    s.p2Direction = temp2;

                    // invert all first directions except the first segment:
                    if (i !== 0) {
                        s.p1Direction = s.p1Direction === 'clockwise' ? 'anticlockwise' : 'clockwise';
                    }
                    
                    // invert all last directions except the last segment:
                    if (i !== startLineSegments.length - 1) {
                        s.p2Direction = s.p2Direction === 'clockwise' ? 'anticlockwise' : 'clockwise';
                    }
                }


                // case 1: retrace the lateral
                // angle and lengthAlongCenterLineFt are ignored:
                if (retrace) {
                    endSegments.push(...lineSegmentsClone);
                    lineSegmentsClone.splice(0, lineSegmentsClone.length);
                }
                // case 2: partial retrace
                // angle is ignored
                else if (lengthAlongCenterLineFt) {
                    while (lineSegmentsClone.length && getSegmentLength(lineSegmentsClone[0], 0) < lengthAlongCenterLineFt) {
                        lengthAlongCenterLineFt -= getSegmentLength(lineSegmentsClone[0], 0);
                        endSegments.push(...lineSegmentsClone.splice(0, 1));
                    }
                    if (lineSegmentsClone.length && lengthAlongCenterLineFt) {
                        const segmentLine = lineString(getSegmentLine_Line(lineSegmentsClone[0], 0));
                        const trimmedLine = lineSliceAlongCustom(segmentLine, 0, lengthAlongCenterLineFt, { units: 'feet' });
                        endSegments.push({
                            type: 'line',
                            p1: trimmedLine.geometry.coordinates[0],
                            p2: trimmedLine.geometry.coordinates[1],
                            p1Direction: lineSegmentsClone[0].p1Direction,
                            p2Direction: lineSegmentsClone[0].p2Direction,
                            inside: lineSegmentsClone[0].inside,
                            retracing: true
                        })
                        lineSegmentsClone[0].p1 = trimmedLine.geometry.coordinates[1];
                    }
                }
                // case 3: pivot only
                else if (angleDegrees && angleDegrees <= 180) {
                    if (!lineSegmentsClone.length) {
                        // if the startPivot used up all the segments, regenerate the last segment to use here
                        lineSegmentsClone.push(structuredClone(lineSegments.slice(-1).map(x => ({ ...x, retracing: true }))[0]));
                        lineSegmentsClone.forEach(s => {
                            const temp = s.p1;
                            s.p1 = s.p2;
                            s.p2 = temp;

                            const temp2 = s.p1Direction;
                            s.p1Direction = s.p2Direction;
                            s.p2Direction = temp2;
                        })
                    }
                    const segment = lineSegmentsClone[0];
                    const type = segment.p1Direction;
                    const center = segment.p1;
                    const offset1 = lineString(getSegmentLine_Line(segment, -100));
                    const o1 = offset1.geometry.coordinates[0];
                    const b1 = turf.bearing(center, o1, { final: true });
                    const b2 = b1 + (type === 'anticlockwise' ? -1 : 1) * angleDegrees;
                    endPivotSegment = {
                        type,
                        b1,
                        b2,
                        center,
                        pivotEnd: true
                    }
                }
            }

            lineSegments.unshift(...startLineSegments);
            lineSegments.push(...endSegments);
            if (lineSegments.length && IsPivotingLateral(this.system)) {
                if (!startPivotSegment) {
                    lineSegments[0].pivotStart = true;
                }
                if (!endPivotSegment) {
                    lineSegments.slice(-1)[0].pivotEnd = true;
                }
            }
            // next gather the radii's:
            const radiusSegments: IPivotSegment[] = []; // Length N - 1
            for (let iLineSegment = 0; iLineSegment < lineSegments.length - 1; iLineSegment++) {
                const leftSegment = lineSegments[iLineSegment];
                const rightSegment = lineSegments[iLineSegment + 1];
                const center = leftSegment.p2;
                const offset1 = lineString(getSegmentLine_Line(leftSegment, 100));
                const offset2 = lineString(getSegmentLine_Line(rightSegment, 100));
                const o1 = offset1.geometry.coordinates[1];
                const o2 = offset2.geometry.coordinates[0];
                const b1 = turf.bearing(center, o1, { final: true });
                const b2 = turf.bearing(center, o2, { final: true });
                const type = lineSegments[iLineSegment].p2Direction;
                radiusSegments.push({ center, b1, b2, type });
            }

            // now combine:
            const combinedSegments: IlateralSegment[] = [
                lineSegments[0]
            ];
            for (let i = 0; i < radiusSegments.length; i++) {
                combinedSegments.push(radiusSegments[i], lineSegments[i + 1]);
            }
            if (startPivotSegment) {
                combinedSegments.unshift(startPivotSegment);
            }
            if (endPivotSegment) {
                combinedSegments.push(endPivotSegment);
            }
            let leftLostSegments: IlateralSegment[] = [];
            let rightLostSegments: IlateralSegment[] = [];

            const result: ILateralSpanTowerV2[] = [];
            let runningLength = startLength;

            if (feedDrop && combinedSegments.length) {
                const res = trimAndMutateSegments(combinedSegments, feedDrop.start, feedDrop.end, runningLength);
                leftLostSegments = res.leftLostSegments;
                rightLostSegments = res.rightLostSegments;
            }

            for (let i = 0; i < spanTowers.length; i++) {
                const spanTower = spanTowers[i];
                result.push({
                    ...spanTower,
                    segments: structuredClone(combinedSegments),
                    leftLostSegments: structuredClone(leftLostSegments),
                    rightLostSegments: structuredClone(rightLostSegments).reverse(),
                    insideRadius: runningLength,
                    outsideRadius: runningLength + spanTower.lengthFeet
                })
                runningLength += spanTower.lengthFeet;
                if (spanTower.isDropSpan && combinedSegments.length) {
                    const res = trimAndMutateSegments(combinedSegments, spanTower.dropSpanStartRelativeToPreviousSpanStart, spanTower.dropSpanEndRelativeToPreviousSpanEnd, runningLength);
                    leftLostSegments = res.leftLostSegments;
                    rightLostSegments = res.rightLostSegments
                }
                else {
                    leftLostSegments = [];
                    rightLostSegments = [];
                }
            }
            return result;
        }

        const generateLateralEndGunSegmentsV2 = (lateralSpanTowers: ILateralSpanTowerV2[], endGunInformations: ILateralEndGunInformation[]) => {
            if (!lateralSpanTowers.length) return [];
            const lastTower = lateralSpanTowers.slice(-1)[0];
            const combinedSegmentsBase = lastTower.segments;
            const fullLength = length(
                feature(getLateralLineString(combinedSegmentsBase, lastTower.outsideRadius)), { units: 'feet' }
            );
            
            const result: ILateralEndGunPartV2[] = [];
            for (let i = 0; i < endGunInformations.length; i++) {
                const endGunInformation = endGunInformations[i];
                for (const onOff of endGunInformation.onOffs) {
                    const combinedSegments = structuredClone(combinedSegmentsBase);
                    let trimStart = onOff.on;
                    while (combinedSegments.length && trimStart >= getSegmentLength(combinedSegments[0], lastTower.outsideRadius)) {
                        trimStart -= getSegmentLength(combinedSegments[0], lastTower.outsideRadius);
                        combinedSegments.splice(0, 1);
                    }
                    if (combinedSegments.length && trimStart) {
                        const startSegment = combinedSegments[0];
                        if (startSegment.type === 'line') {
                            const newLine = getSegmentLine_Line(startSegment, 0, { trimStart });
                            startSegment.p1 = newLine[0];
                        }
                        else {
                            const newLine = getSegmentLine_Pivot(startSegment, lastTower.outsideRadius, { trimStart });
                            const p = newLine[0];
                            startSegment.b1 = turf.bearing(startSegment.center, p, { final: true });
                        }
                    }
                    
                    
                    let trimEnd = fullLength - onOff.off;
                    while (combinedSegments.length && trimEnd >= getSegmentLength(combinedSegments.slice(-1)[0], lastTower.outsideRadius)) {
                        trimEnd -= getSegmentLength(combinedSegments.slice(-1)[0], lastTower.outsideRadius);
                        combinedSegments.splice(-1, 1);
                    }
                    if (combinedSegments.length && trimEnd) {
                        const endSegment = combinedSegments.slice(-1)[0];
                        if (endSegment.type === 'line') {
                            const newLine = getSegmentLine_Line(endSegment, 0, { trimEnd });
                            endSegment.p2 = newLine[1];
                        }
                        else {
                            const newLine = getSegmentLine_Pivot(endSegment, lastTower.outsideRadius, { trimEnd });
                            const p = newLine.slice(-1)[0];
                            endSegment.b2 = turf.bearing(endSegment.center, p, { final: true });
                        }
                    }
                    
                    result.push({
                        segments: combinedSegments,
                        insideRadius: lastTower.outsideRadius,
                        outsideRadius: lastTower.outsideRadius + (lastTower.outsideRadius > 0 ? endGunInformation.throwFeet : -endGunInformation.throwFeet),
                        isPrimary: endGunInformation.isPrimary
                    }); 
                }
            }
            return result;
        }

        const flangedFeedLine = this.lateralCenterLine;// this.feedCenterLine();
        const flexFeedLine = this.lateralCenterLine;// = this.feedCenterLine();// = this.flexSideCenterLine(true);
        const fledDrop = this.hasFlexDrop ?
            {
                start: this.system.Lateral.dropSpanStartRelativeToPreviousSpanStart || 0,
                end: this.system.Lateral.dropSpanEndRelativeToPreviousSpanEnd || 0
            }
            : undefined;

        const partialAftSide = partialSpanTowers.slice(0, this.aftSideSpanCount).reverse();
        const feedLineAft = rigidSideFwd ? flexFeedLine : flangedFeedLine;
        const feedDropAft = rigidSideFwd ? fledDrop : undefined;
        const startLengthAft = this.isCanalFeed
            ? -this.distanceFromCanalCenterToAftSide
            : 0;
        const aftSpanTowers: ILateralSpanTowerV2[] = generateLateralSegments(feedLineAft, partialAftSide, startLengthAft, feedDropAft);

        const partialFwdSide = partialSpanTowers.slice(this.aftSideSpanCount);
        const feedLineFwd = rigidSideFwd ? flangedFeedLine : flexFeedLine;
        const feedDropFwd = rigidSideFwd ? undefined : fledDrop;
        const startLengthFwd = this.isCanalFeed
            ? this.distanceFromCanalCenterToFwdSide
            : 0;
        const fwdSpanTowers: ILateralSpanTowerV2[] = generateLateralSegments(feedLineFwd, partialFwdSide, startLengthFwd, feedDropFwd);
        
        this._flexSideDirectionalSpanTowersFromFeedLine = rigidSideFwd ? aftSpanTowers : fwdSpanTowers.map(x => ({ ...x, lengthFeet: -x.lengthFeet }));
        this._rigidSideDirectionalSpanTowersFromFeedLine = rigidSideFwd ? fwdSpanTowers : aftSpanTowers.map(x => ({ ...x, lengthFeet: -x.lengthFeet }));
        this._feedLineSegments = generateFeedLineSegments(flangedFeedLine);
        this._flexSideDirectionalEndGunParts = generateLateralEndGunSegmentsV2(
            this._flexSideDirectionalSpanTowersFromFeedLine,
            this.system.endGuns?.lateralOnOffsFlex || []
        )
        this._rigidSideDirectionalEndGunParts = generateLateralEndGunSegmentsV2(
            this._rigidSideDirectionalSpanTowersFromFeedLine,
            this.system.endGuns?.lateralOnOffsFlanged || []
        )

        // console.log("fs", this._flexSideDirectionalSpanTowersFromFeedLine)
        // console.log("rs", this._rigidSideDirectionalSpanTowersFromFeedLine)
        // console.log("fe", this._flexSideDirectionalEndGunParts)
        // console.log("re", this._rigidSideDirectionalEndGunParts)
    }
    protected _calculateEndGuns(): {flex: EndGunDescription, rigid: EndGunDescription} {
        const flex = this._calculateEndGunPolygonsForSide('flex');
        const rigid = this._calculateEndGunPolygonsForSide('rigid');
        return {flex, rigid};
    }
    protected getWheelTracksDetailed() {
        let flexSideStart: LineString | undefined = undefined;
        const flexSide: LineString[] = [];
        let rigidSideStart: LineString | undefined = undefined;
        const rigidSide: LineString[] = [];
        let flexEndBoom: LineString | undefined = undefined;
        let rigidEndBoom: LineString | undefined = undefined;
        
        // system area flex side
        if (this.hasFlexSide) {
            flexSideStart = this.flexSideCenterLine();
            for (const spanTower of this.directionalFlexSideSpanTowers) {
                const trackLine = getLateralLineString(spanTower.segments, spanTower.outsideRadius);
                if (!spanTower.isEndBoom) {
                    flexSide.push(trackLine);
                }
                else {
                    flexEndBoom = trackLine;
                }
            }
        }

        // system area rigid side
        {
            rigidSideStart = this.feedCenterLine();
            for (const spanTower of this.directionalRigidSideSpanTowers) {
                const trackLine = getLateralLineString(spanTower.segments, spanTower.outsideRadius);
                if (!spanTower.isEndBoom) {
                    rigidSide.push(trackLine);
                }
                else {
                    rigidEndBoom = trackLine;
                }
            }
        }

        return {
            flexSideStart, flexSide,
            rigidSideStart, rigidSide,
            flexEndBoom, rigidEndBoom
        };
    }
    
    // private methods:
    private feedCenterLineOffset() {
        if (!this.isCanalFeed) {
            return 0;
        }
        else {
            return this.isRigidSideForward() ? this.distanceFromCanalCenterToFwdSide : -this.distanceFromCanalCenterToAftSide;
        }
    }
    private _getIrrigatedAreaPolygonsForSide(side: "flanged" | "flex"): {
        mainSystem: Feature<Polygon>[];
        drop: Feature<Polygon>[];
    } {
        // includes end boom if defined
        const mainSystem: Feature<Polygon>[] = [];
        const drop: Feature<Polygon>[] = [];
        let hasPassedDropSpan = false;
        let u: Feature<MultiPolygon | Polygon> | null = null;
        const towers = side === "flex" ? this.directionalFlexSideSpanTowers : this.directionalRigidSideSpanTowers;
        for (const spanTower of towers) {
            const result = getLateralLinePolygons(
                spanTower.segments,
                spanTower.insideRadius,
                spanTower.outsideRadius,
                u
            )
            u = result.updatedExclude;

            const rdpFeatureType = hasPassedDropSpan
                ? "system/irrigatedArea-dropSpan"
                : (
                    side === "flex"
                        ? "system/irrigatedArea-flexSide"
                        : "system/irrigatedArea"
                );
            for (const p of result.polygons) {
                const irrigatedArea = polygon(
                    p.coordinates,
                    {
                        ...this.propertiesForAll,
                        rdpFeatureType,
                        activeSystem: this.isActive
                    }
                )
                
                if (hasPassedDropSpan) {
                    drop.push(irrigatedArea);
                }
                else {
                    mainSystem.push(irrigatedArea);
                }
            }

            if (!hasPassedDropSpan) {
                hasPassedDropSpan = spanTower.isDropSpan;
            }
        }
        return { mainSystem, drop };
    }
    private _calculateEndGunPolygonsForSide(side: 'rigid' | 'flex'): EndGunDescription {
        const features: {feature: Feature<Polygon>, isPrimary: boolean}[] = [];        
        let endGunInformation: { endGun: EndGunTypes, isPrimary: boolean, onOffs: { on: number, off: number }[], throwFeet: number }[] = [];

        const endGunParts = side === 'rigid'
            ? this.directionalRigidSideEndGunParts
            : this.directionalFlexSideEndGunParts;

        for (const part of endGunParts) {
            const poly = getLateralLinePolygons(part.segments, part.insideRadius, part.outsideRadius);
            features.push(
                ...poly.polygons.map(p => {
                    return {
                        feature: feature(
                            p,
                            {
                                rdpFeatureType: "system/irrigatedArea-endGun",
                                ...this.propertiesForAll
                            }
                        ),
                        isPrimary: part.isPrimary
                    }
                })
            )
        }
        return {
            features,
            endGunInformation
        };
    }

    // public methods:
    public isRigidSideForward() {
        return this.system.flexIsFwd !== true;
    }
    public generateSimpleSelectGeoJSON() {
        const features: Feature[] = [];

        const irrigatedAreasFlanged: Polygon[] = [];
        const irrigatedAreasFlex: Polygon[] = [];
        const wheelTracks: { label?: string; geometry: LineString; }[] = [];
        const endGunAreas: Polygon[] = [];
        const dropIrrigatedAreas: Polygon[] = [];

        // feed line
        features.push(
            feature(
                this.feedCenterLine(),
                {
                    ...this.propertiesForAll,
                    rdpFeatureType: "system/lateral"
                }
            )
        )

        // canal:
        if (this.isCanalFeed) {
            const line1 = lateralLineOffset(this.canalCenterLine, -this.canalWidthFeet * 0.5, { units: 'feet' });
            const line2 = lateralLineOffset(this.canalCenterLine, this.canalWidthFeet * 0.5, { units: 'feet' });
            features.push(
                polygon(
                    [
                        [
                            ...line1.geometry.coordinates,
                            ...line2.geometry.coordinates.reverse(),
                            line1.geometry.coordinates[0]
                        ]
                    ],
                    {
                        ...this.propertiesForAll,
                        rdpFeatureType: "system/canal"
                    }
                )
            )
        }

        // irrigated area flex side
        const flexSide = this._getFlexSideIrrigatedAreaPolygons();
        irrigatedAreasFlex.push(...flexSide.mainSystem.map(x => x.geometry));
        dropIrrigatedAreas.push(...flexSide.drop.map(x => x.geometry));

        // irrigated area rigid side
        // here
        const rigidSide = this._getRigidSideIrrigatedAreaPolygons();
        irrigatedAreasFlanged.push(...rigidSide.mainSystem.map(x => x.geometry));
        dropIrrigatedAreas.push(...rigidSide.drop.map(x => x.geometry));

        // end guns:
        const endguns = this._calculateEndGuns();
        endGunAreas.push(...endguns.flex.features.map(x => x.feature.geometry));
        endGunAreas.push(...endguns.rigid.features.map(x => x.feature.geometry));

        // wheel tracks:
        const detailedWheelTracks = this.getWheelTracksDetailed();
        if (detailedWheelTracks.flexSideStart) {
            wheelTracks.push({ geometry: detailedWheelTracks.flexSideStart });
        }
        detailedWheelTracks.flexSide.forEach((x, idx) => {
            wheelTracks.push({ geometry: x, label: (idx + 1).toString() });
        });
        if (detailedWheelTracks.rigidSideStart) {
            wheelTracks.push({ geometry: detailedWheelTracks.rigidSideStart });
        }
        detailedWheelTracks.rigidSide.forEach((x, idx) => {
            wheelTracks.push({ geometry: x, label: (idx + 1).toString() });
        });
        
        if (irrigatedAreasFlanged.length) {
            const mf = multiPolygon(irrigatedAreasFlanged.map(x => x.coordinates), {
                rdpFeatureType: "system/irrigatedArea",
                ...this.propertiesForAll
            })
            features.push(mf);
        }
        if (irrigatedAreasFlex.length) {
            const mf = multiPolygon(irrigatedAreasFlex.map(x => x.coordinates), {
                rdpFeatureType: "system/irrigatedArea-flexSide",
                ...this.propertiesForAll
            })
            features.push(mf);
        }
        if (wheelTracks.length) {
            const mf = multiLineString(
                wheelTracks.map(x => x.geometry.coordinates), 
                {
                    rdpFeatureType: "system/wheelTrack",
                    ...this.propertiesForAll,
                    rdpSpanNumberLabels: wheelTracks.map(x => x.label)
                })
            features.push(mf);
        }
        if (endGunAreas.length) {
            const mf = multiPolygon(endGunAreas.map(x => x.coordinates), {
                rdpFeatureType: "system/irrigatedArea-endGun",
                ...this.propertiesForAll
            })
            features.push(mf);
        }
        if (dropIrrigatedAreas.length) {
            const mf = multiPolygon(dropIrrigatedAreas.map(x => x.coordinates), {
                rdpFeatureType: "system/irrigatedArea-dropSpan",
                ...this.propertiesForAll
            })
            features.push(mf);
        }

        return features;
    }
}
