import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import ISystem from "rdptypes/project/ISystem";
import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";

// End gun throw ranges were found in the Reinke Systen User Guide pdf

export const getThrowRangeFromEndGunType = (type: EndGunTypes): { radiusOfThrowFeet: { min: number, max: number } } | undefined => {
    switch (type) {
        case "R55i": return { radiusOfThrowFeet: { min: 30, max: 37 } };
        case "R55": return { radiusOfThrowFeet: { min: 33, max: 47 } };
        case "R75": return { radiusOfThrowFeet: { min: 40, max: 56 } };
        case "SingleP85": return { radiusOfThrowFeet: { min: 40, max: 80 } };
        case "SR75": return { radiusOfThrowFeet: { min: 59, max: 107 } };
        case "SR100": return { radiusOfThrowFeet: { min: 75, max: 140 } };
        case "SR100NV": return { radiusOfThrowFeet: { min: 75, max: 140 } };
        case "SimeWing": return { radiusOfThrowFeet: { min: 72, max: 135 } };
        case "TwinMax": return { radiusOfThrowFeet: { min: 65, max: 135 } };
        case "Twin101Ultra": return { radiusOfThrowFeet: { min: 81, max: 146 } };
        default:
            return undefined;
    }
}

export enum EndGunLocation {
    Primary, Secondary
}

export const getEndGunThrow = (system: ISystem, sideEnum: SideEnum, endGunLocation: EndGunLocation): number | undefined => {
    const side = getSide(system, sideEnum);
    const endGunType = endGunLocation === EndGunLocation.Primary 
        ? side.EndOfSystem.EndGun.EndGunTypePrimary
        : side.EndOfSystem.EndGun.EndGunTypeSecondary;
    const throwRange = getThrowRangeFromEndGunType(endGunType);
    if (!throwRange) return undefined;
    
    let throwFeet: number | undefined = undefined;
    let storedValue: number | undefined = undefined;
    if (sideEnum === SideEnum.Flanged) {
        // flanged side
        storedValue = endGunLocation === EndGunLocation.Primary
            ? system.endGuns?.flangedSidePrimaryThrow
            : system.endGuns?.flangedSideSecondaryThrow;
    }
    else {
        // flex side
        storedValue = endGunLocation === EndGunLocation.Primary
            ? system.endGuns?.flexSidePrimaryThrow
            : system.endGuns?.flexSideSecondaryThrow;
    }
    if (!storedValue || storedValue < throwRange.radiusOfThrowFeet.min || storedValue > throwRange.radiusOfThrowFeet.max) {
        throwFeet = Math.round(0.5 * (throwRange.radiusOfThrowFeet.min + throwRange.radiusOfThrowFeet.max));
    }
    else {
        throwFeet = storedValue;
    }
    return throwFeet;
}