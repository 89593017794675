import ISystem from "rdptypes/project/ISystem";
import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import IPage from "rdptypes/roe/IPage";
import * as React from "react";
import AuthCtx from "../../../../auth/AuthCtx";
import ComponentRenderContext from "../../../../components/roe/componentRenderers/ComponentRenderContext";
import { SystemValidationResult } from "../../../../components/roe/validation/SystemValidator";
import IDbProject from "../../../../db/IDbProject";
import IValidationWorkerState, { ValidationWorkerStatus } from "../../../../workers/ValidationWorker/IValidationWorkerState";

interface IArgs {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    getValidationResult?: (system: ISystemBase) => SystemValidationResult;
}
export const useDrawerValidation = (args: IArgs) => {

    const { dbPrj, layoutId, systemId } = args;
    const authState = React.useContext(AuthCtx);
    const getValidationResult = (system: ISystemBase) => args.getValidationResult?.(system) ?? new SystemValidationResult();
    
    const [ vws, setVws ] = React.useState<IValidationWorkerState>({
        status: ValidationWorkerStatus.Validating,
        validate: async (sys, changingFieldPaths, improveScores) => {
            return validate(sys);
        },
        validateCmp: (system, cmp, fieldRoot, pageId) => getValidationResult(system)
    });

    const crCtx = new ComponentRenderContext(
        dbPrj, 
        layoutId, 
        systemId,
        {
            id: "",
            changeRequiresNewSprinklers: true
        } as IPage, 
        vws, 
        authState, 
        false)
    
    const validate = (system: ISystem) => {
        setVws({
            ...vws,
            validationResult: undefined,
            status: ValidationWorkerStatus.Validating
        })
        const validationResult = getValidationResult(system);
        setVws({
            ...vws,
            validationResult,
            status: ValidationWorkerStatus.Validated
        })
        return validationResult
    }

    return {
        ctx: crCtx,
        validate
    }
}