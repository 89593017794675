import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import DeleteIcon from "@mui/icons-material/Delete";
import InputIcon from '@mui/icons-material/Input';
import OutputIcon from '@mui/icons-material/Output';
import {
    Card,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from "@mui/material";
import i18next, { t } from "i18next";
import IAction from 'rdptypes/IAction';
import ISystem, { IPivotingLateralInformation } from "rdptypes/project/ISystem";
import * as React from "react";
import { createNewMultiAction } from "../../../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../../auth/AuthCtx";
import IAuthState from "../../../../../auth/IAuthState";
import { pageStyles } from "../../../../../components/roe/styles";
import IDbProject from "../../../../../db/IDbProject";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
}
type Direction = "inside" | "outside" | "none";

const generateClearDropSpanActions = (system: ISystem, layoutId: string, systemId: string, authState: IAuthState) => {
    const actions: IAction[] = [];
    if (system.FlangedSide.Span.some((x) => x.Disconnecting)) {
        const updatedSpans = structuredClone(system.FlangedSide.Span);
        updatedSpans.forEach((x) => {
            if (x.Disconnecting) {
                x.Disconnecting = false;
                (x.dropSpanEndRelativeToPreviousSpanEnd = undefined),
                    (x.dropSpanStartRelativeToPreviousSpanStart = undefined);
            }
        });
        actions.push(
            createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "FlangedSide.Span",
                updatedSpans,
                authState
            )
        );
    }
    if (system.FlexSide.Span.some((x) => x.Disconnecting)) {
        const updatedSpans = structuredClone(system.FlexSide.Span);
        updatedSpans.forEach((x) => {
            if (x.Disconnecting) {
                x.Disconnecting = false;
                (x.dropSpanEndRelativeToPreviousSpanEnd = undefined),
                    (x.dropSpanStartRelativeToPreviousSpanStart = undefined);
            }
        });
        actions.push(
            createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "FlexSide.Span",
                updatedSpans,
                authState
            )
        );
    }
    return actions;
}
const generateDeleteLateralPivotAction = (lineCoordIndex: number, system: ISystem, layoutId: string, systemId: string, authState: IAuthState) => {
    const actions: IAction[] = [];
    const updatedCoordiantes = structuredClone(system.lateral.line.coordinates);
    updatedCoordiantes.splice(lineCoordIndex, 1);
    actions.push(
        createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "lateral.line.coordinates",
            updatedCoordiantes,
            authState
        )
    );
    const anticlockwisePivotLineIndicies = (system.lateral.anticlockwisePivotLineIndicies || []).filter(x => x !== lineCoordIndex);
    for (let i = 0; i < anticlockwisePivotLineIndicies.length; i++) {
        if (anticlockwisePivotLineIndicies[i] > lineCoordIndex) {
            anticlockwisePivotLineIndicies[i] -= 1;
        }
    }
    actions.push(
        createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "lateral.anticlockwisePivotLineIndicies",
            anticlockwisePivotLineIndicies,
            authState
        )
    );
    return actions;
}
const generateSetDirectionActions = (system: ISystem, lineVertexIndex: number, newDirection: Direction, layoutId: string, systemId: string, authState: IAuthState): IAction[] => {
    const anticlockwisePivotLineIndicies = system.lateral.anticlockwisePivotLineIndicies || [];
    const direction: Direction = isInsideOrOutside(anticlockwisePivotLineIndicies, lineVertexIndex);

    if (newDirection === direction) return [];
    // else we will change:
    const index = anticlockwisePivotLineIndicies.findIndex((idx) => lineVertexIndex === idx);
    const actions: IAction[] = generateClearDropSpanActions(system, layoutId, systemId, authState);
    if (index === -1) {
        if (newDirection === 'inside') {
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "lateral.anticlockwisePivotLineIndicies",
                    [...anticlockwisePivotLineIndicies, lineVertexIndex],
                    authState
                )
            )
        }
    } 
    else {
        const copyOf = structuredClone(anticlockwisePivotLineIndicies);
        copyOf.splice(index, 1);
        actions.push(
            createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "lateral.anticlockwisePivotLineIndicies",
                copyOf,
                authState
            )
        )
    }
    return actions;
}
const generateSetLateralPivotActions = (position: "start" | "end", value: IPivotingLateralInformation | undefined, layoutId: string, systemId: string, authState: IAuthState) => {
    const actions: IAction[] = [];
    const property = position === 'end' 
        ? "lateral.endPivot"
        : "lateral.startPivot";
    actions.push(
        createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            property,
            value,
            authState
        )
    )
    return actions;
}

interface IRow {
    id: string;
    delete: () => void;
    canSetDirection: boolean;
    direction: Direction;
    setDirection: (newDirection: Direction) => void;
    isEnd: boolean;
}
const isInsideOrOutside = (anticlockwisePivotLineIndicies: number[], index: number) => anticlockwisePivotLineIndicies.includes(index) 
    ? "inside" 
    : "outside";

export const PivotingLateralTable: React.FC<Props> = (props) => {
    const authState = React.useContext(AuthCtx);
    const system = props.dbPrj.state.layouts[props.layoutId].systems[props.systemId];
    const anticlockwisePivotLineIndicies = system.lateral.anticlockwisePivotLineIndicies || [];
    const rows: IRow[] = [];
    
    const hasStart = system.lateral.startPivot !== undefined;
    const hasEnd = system.lateral.endPivot !== undefined;
    const canDelete = system.lateral.line.coordinates.length > 2;

    // pivot start:
    {
        const vertexIndex = system.lateral.line.coordinates.length - 1;
        const direction: Direction = hasStart
            ? isInsideOrOutside(anticlockwisePivotLineIndicies, vertexIndex)
            : "none";

        const deletePoint = () => {
            if (!canDelete) return;
            const actions: IAction[] = generateClearDropSpanActions(system, props.layoutId, props.systemId, authState);
            if (system.lateral.startPivot?.retrace && system.lateral.endPivot) {
                actions.push(
                    ...generateSetLateralPivotActions(
                        "end", {
                            ...system.lateral.endPivot,
                            retrace: true
                        }, props.layoutId, props.systemId, authState
                    )
                );
            }
            actions.push(
                ...generateSetLateralPivotActions(
                    "start", undefined, props.layoutId, props.systemId, authState
                )
            );
            actions.push(...generateDeleteLateralPivotAction(vertexIndex, system, props.layoutId, props.systemId, authState));
            props.dbPrj.pushAction(createNewMultiAction(actions, authState));

        }
        const setDirection = (newDirection: Direction) => {
            const actions = generateSetDirectionActions(system, vertexIndex, newDirection, props.layoutId, props.systemId, authState);
            if (newDirection === 'none') {
                actions.push(
                    ...generateSetLateralPivotActions(
                        'start', undefined, props.layoutId, props.systemId, authState
                    )
                )
            }
            else {
                actions.push(
                    ...generateSetLateralPivotActions(
                        'start', {
                            ...(system.lateral.startPivot || {}),
                            angleDegrees: 180
                        }, props.layoutId, props.systemId, authState
                    )
                )
            }
            if (actions.length) {
                console.log("actions", actions)
                // return;
                props.dbPrj.pushAction(
                    createNewMultiAction(
                        actions,
                        authState
                    )
                )
            }
        }

        rows.push({
            id: "1",
            delete: deletePoint,
            direction,
            setDirection,
            isEnd: true,
            canSetDirection: (system.lateral.startPivot || system.lateral.endPivot?.retrace) ? true : false
        })
    }

    for (let i = system.lateral.line.coordinates.length - 2; i >= 1 ; i--) {
        const direction = isInsideOrOutside(anticlockwisePivotLineIndicies, i);
        const deletePoint = () => {
            if (!canDelete) return;
            const actions: IAction[] = generateClearDropSpanActions(system, props.layoutId, props.systemId, authState);
            actions.push(...generateDeleteLateralPivotAction(i, system, props.layoutId, props.systemId, authState));
            props.dbPrj.pushAction(createNewMultiAction(actions, authState));
        };
        const setDirection = (newDirection: Direction) => {
            const actions = generateSetDirectionActions(system, i, newDirection, props.layoutId, props.systemId, authState);
            if (actions.length) {
                props.dbPrj.pushAction(
                    createNewMultiAction(
                        actions,
                        authState
                    )
                )
            }
        }
        
        rows.push({
            id: (system.lateral.line.coordinates.length - 1 - i + 1).toString(),
            delete: deletePoint,
            direction,
            setDirection,
            isEnd: false,
            canSetDirection: true
        })
    }

    
    // pivot end:
    {
        const vertexIndex = 0;
        const direction: Direction = hasEnd
            ? isInsideOrOutside(anticlockwisePivotLineIndicies, vertexIndex)
            : "none";

        const deletePoint = () => {
            if (!canDelete) return;
            const actions: IAction[] = generateClearDropSpanActions(system, props.layoutId, props.systemId, authState);
            if (system.lateral.endPivot?.retrace && system.lateral.startPivot) {
                actions.push(
                    ...generateSetLateralPivotActions(
                        "start", {
                            ...system.lateral.startPivot,
                            retrace: true
                        }, props.layoutId, props.systemId, authState
                    )
                );
            }
            actions.push(
                ...generateSetLateralPivotActions(
                    "end", undefined, props.layoutId, props.systemId, authState
                )
            );
            actions.push(...generateDeleteLateralPivotAction(vertexIndex, system, props.layoutId, props.systemId, authState));
            props.dbPrj.pushAction(createNewMultiAction(actions, authState));

        }
        const setDirection = (newDirection: Direction) => {
            const actions = generateSetDirectionActions(system, vertexIndex, newDirection, props.layoutId, props.systemId, authState);
            if (newDirection === 'none') {
                actions.push(
                    ...generateSetLateralPivotActions(
                        'end', undefined, props.layoutId, props.systemId, authState
                    )
                )
            }
            else {
                actions.push(
                    ...generateSetLateralPivotActions(
                        'end', {
                            ...(system.lateral.endPivot || {}),
                            angleDegrees: 180
                        }, props.layoutId, props.systemId, authState
                    )
                )
            }
            if (actions.length) {
                props.dbPrj.pushAction(
                    createNewMultiAction(
                        actions,
                        authState
                    )
                )
            }
        }

        rows.push({
            id: (system.lateral.line.coordinates.length - 1 + 1).toString(),
            delete: deletePoint,
            direction,
            setDirection,
            isEnd: true,
            canSetDirection: (system.lateral.endPivot || system.lateral.startPivot?.retrace) ? true : false
        })
    }
    
    return (
        <Card variant="outlined" style={pageStyles.cardStyle.outlined}>
            <Typography sx={{ ...pageStyles.cardTitleStyle }}>{i18next.format(t("field-design-drawer.lateral.pivoting-lateral.title"), 'capitalize-each')}</Typography>
            <TableContainer component={Paper} variant="outlined">
                <Table size='small' >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {i18next.format(t("field-design-drawer.lateral.pivoting-lateral.header-pivot-id"), 'capitalize-each')}
                            </TableCell>
                            <TableCell align="right">
                                {i18next.format(t("field-design-drawer.lateral.pivoting-lateral.header-direction"), 'capitalize-each')}
                            </TableCell>
                            <TableCell align="right">
                                {i18next.format(t("field-design-drawer.lateral.pivoting-lateral.header-delete"), 'capitalize-each')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="right">
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={row.direction}
                                        exclusive
                                        onChange={(ev, value: Direction) => row.setDirection(value)}
                                        size="small"
                                    >
                                        {
                                            row.isEnd && (
                                                <ToggleButton value={"none" as Direction} disabled={row.direction === 'none'}>
                                                    <Tooltip title={i18next.format(t("field-design-drawer.lateral.pivoting-lateral.tooltip-none"), 'capitalize-each')}>
                                                        <CancelPresentationIcon />
                                                    </Tooltip>
                                                </ToggleButton>
                                            )
                                        }
                                        <ToggleButton value={"outside" as Direction} disabled={!row.canSetDirection || row.direction === 'outside'}>
                                            <Tooltip title={i18next.format(t("field-design-drawer.lateral.pivoting-lateral.tooltip-outside"), 'capitalize-each')}>
                                                <OutputIcon />
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={"inside" as Direction} disabled={!row.canSetDirection || row.direction === 'inside'}>
                                            <Tooltip title={i18next.format(t("field-design-drawer.lateral.pivoting-lateral.tooltip-inside"), 'capitalize-each')}>
                                                <InputIcon />
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton aria-label="delete" onClick={row.delete} disabled={!canDelete}>
                                        <Tooltip title={i18next.format(t("field-design-drawer.lateral.pivoting-lateral.tooltip-delete"), 'capitalize-each')}>
                                            <DeleteIcon />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <caption>
                        {i18next.format(t("field-design-drawer.lateral.pivoting-lateral.footnote"))}
                    </caption>
                </Table>
            </TableContainer>
        </Card>
    );
};
