import { BoomBackTypes, IBoomBack as BoomBacks, DeviceTypes, DropTypes, IPackage as Package, RegulatorTypes } from "rdptypes/project/ISprinklers";
import CVSection2 from './CVSection2';
import PartsAssembler from "./PartsAssembler";
import { SpansClass } from "./SpansClass";
import * as sysf from "./SystemFunctions";
import { TowersClass } from "./TowersClass";
import { strLanguageText } from "./TranslationsLib";
export class SprinklersBoomBackClass extends CVSection2 {

    public SideKey: "R" | "L";

    private get Packages(): Package[] {
        return this.SideKey === "R" 
            ? this.Quote.System.FlangedSide.Sprinklers.Package
            : this.Quote.System.FlexSide.Sprinklers.Package;
    }
    private get BoomBacks(): BoomBacks {
        return this.SideKey === "R" 
            ? this.Quote.System.FlangedSide.Sprinklers.BoomBacks
            : this.Quote.System.FlexSide.Sprinklers.BoomBacks;
    }
    private get TowersClass(): TowersClass {
        return this.SideKey === "R" 
            ? this.Quote.RightTowersClass
            : this.Quote.LeftTowersClass;
    }
    private get SpansClass(): SpansClass {
        return this.SideKey === "R" 
            ? this.Quote.RightSpansClass
            : this.Quote.LeftSpansClass;
    }

    public DataOK(PA: PartsAssembler, WithWarnings?: boolean, DependencyCheckInProgress?: boolean): void {
        this.DataOKWithReturn(PA, WithWarnings, DependencyCheckInProgress);
    }
    
    public DataOKWithReturn(PA: PartsAssembler, WithWarnings?: boolean, DependencyCheckInProgress?: boolean): boolean {

        for (let i = 0; i < this.Packages.length; i++) {
            const pt: DeviceTypes = this.Packages[i].Device ?? DeviceTypes.None;
            if (pt !== DeviceTypes.None && (this.BoomBacks.BoomBackType ?? BoomBackTypes.None) !== BoomBackTypes.None) {
                switch (pt) {
                    case DeviceTypes.Nelson1518MediumSpacingBrassImpact:
                    case DeviceTypes.Senninger12MediumSpacingPlasticImpact:
                    case DeviceTypes.Nelson15Single3RNBrassImpact:
                    case DeviceTypes.Nelson15Double3RNBrassImpact:
                    case DeviceTypes.Nelson15DoubleFCNBrassImpact:
                    case DeviceTypes.Nelson8Double3RNPlasticImpact:
                    case DeviceTypes.Nelson8DoubleFCNPlasticImpact:
                    case DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact:
                    case DeviceTypes.Senninger6DoubleRedVanePlasticImpact:
                        this.Quote.Warnings.Add(strLanguageText("sltImpactsNotAllowedWithBoomBacks"));
                        return false;
                    case DeviceTypes.NelsonR3000FCNRotatorBlue:
                    case DeviceTypes.NelsonR3000RotatorBlue:
                    case DeviceTypes.NelsonR3000RotatorWhite:
                        this.Quote.Warnings.Add(strLanguageText("sltBlueRotatorsNotAllowedWithBoomBacks"));
                        return false;
                }
                if (this.Packages[i].Drop === DropTypes.None) {
                    this.Quote.Warnings.Add(strLanguageText("sltRegDropsRequiredWithBoomBacks"));
                    return false;
                }
                if (this.Packages[i].Regulator.RegulatorType === RegulatorTypes.None) {
                    this.Quote.Warnings.Add(strLanguageText("sltRegDropsRequiredWithBoomBacks"));
                    return false;
                }
                if (sysf.HasEnergySaverPackage(this.Quote.System)) {
                    this.Quote.Warnings.Add(strLanguageText("sltESPNotAllowedWithBoomBacks"));
                    return false;
                }
            }
        }

        switch (this.BoomBacks.BoomBackType) {
            case BoomBackTypes.Tower:
                if (this.TowersClass.AnyLowProfiles()) {
                    this.Quote.Warnings.Add(strLanguageText("sltBoomBacks"));
                    return false;
                }
                break;
            case BoomBackTypes.Standard:
                if (this.SpansClass.AnyPoly(0, 0)) {
                    this.Quote.Warnings.Add(strLanguageText("sltBoomBacks"));
                    return false;
                }
                break;
        }
        return true;
    }
    
    public WritePO(PO: PartsAssembler): void {
        // pass: nothing to do
    }

}
