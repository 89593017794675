import { ISystemBase, SpanTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule, IRuleResult } from "rdptypes/roe/IRule";
import { IsDualSided, IsEDMP } from "../../../../../roe_migration/SystemFunctions";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "spans",
            title: {"en" :"Spans", "es":  "Tramos"},
            position: 4,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && sys.SystemProperties.SystemType !== SystemTypes.Ancillary,
            components: [
                {
                    type: "sidetable",
                    showButtons: true,
                    columns: [
                        {
                            title: {"en" :"Type", "es": "Tipo"},
                            arrayPath: "Span",
                            cmp: {
                                type: "choice",
                                fieldPath: "SpanType",
                                title: "",
                                options: [
                                    { id: SpanTypes.E2100, title: {"en" :"E2100 10\" Galvanized Pipe", "es": "E2100 10\" Tubería Galvanizada"}, title_centimeters: {"en" :"E2100 25.4cm Galvanized Pipe", "es": "E2100 25.4cm Tubería Galvanizada" }},
                                    { id: SpanTypes.E2085, title: {"en" :"E2085 8 5/8\" Galvanized Pipe", "es": "E2085 8 5/8\ Galvanized Pipe"}, title_centimeters: {"en" :"E2085 21.91cm Galvanized Pipe", "es": "E2085 21.91cm Galvanized Pipe" }},
                                    { id: SpanTypes.E2065, title: {"en" :"E2065 6 5/8\" Galvanized Pipe", "es": "E2065 6 5/8\" Galvanized Pipe"}, title_centimeters: {"en" :"E2065 16.83cm Galvanized Pipe", "es": "E2065 16.83cm Galvanized Pipe"}},
                                    { id: SpanTypes.E2060, title: {"en" :"E2060 6\" Galvanized Pipe", "es": "E2060 6\" Galvanized Pipe"}, title_centimeters: {"en" :"E2060 15.24cm Galvanized Pipe", "es": "E2060 15.24cm Galvanized Pipe"}},
                                    { id: SpanTypes.E2045, title: {"en" :"E2045 4 1/2\" Galvanized Pipe" , "es": "E2045 4 1/2\" Galvanized Pipe" }, title_centimeters: {"en" :"E2045 11.43cm Galvanized Pipe" , "es": "E2045 11.43cm Galvanized Pipe" }},
                                    { id: SpanTypes.E2665, title: {"en" :"E2665 6 5/8\" Painted CN+ Pipe" , "es": "E2665 Tubería 6 5/8\" CN+ Pintada" }, title_centimeters: {"en" :"E2665 16.83cm Painted CN+ Pipe" , "es": "E2665 Tubería 16.83cm CN+ Pintada" }},
                                    { id: SpanTypes.E2660, title: {"en" :"E2660 6\" Painted CN+ Pipe" , "es": "E2660 Tubería 6\" CN+ Pintada" }, title_centimeters: {"en" :"E2660 15.24cm Painted CN+ Pipe" , "es": "E2660 Tubería 15.24cm CN+ Pintada" }},
                                    { id: SpanTypes.AlumIV, title: {"en" :"Alum IV 6\" Aluminium Truss, Tower and Pipe", "es": "Alum IV Estructura de Aluminio 6\", Torre y Tubo"  }, title_centimeters: {"en" :"Alum IV 15.24cm Aluminium Truss, Tower and Pipe", "es": "Alum IV Estructura de Aluminio 15.24cm, Torre y Tubo"  }},
                                    { id: SpanTypes.A80G, title: {"en" :"A80G 8\" Aluminium Pipe", "es": "A80G 8\" Tubería Aluminio"  }, title_centimeters: {"en" :"A80G 20.32cm Aluminium Pipe", "es": "A80G 20.32cm Tubería Aluminio"  }},
                                    { id: SpanTypes.A60G, title: {"en" :"A60G 6\" Aluminium Pipe", "es": "A60G 6\" Tubería Aluminio" }, title_centimeters: {"en" :"A60G 15.24cm Aluminium Pipe", "es": "A60G 15.24cm Tubería Aluminio" }},
                                    { id: SpanTypes.S2085G, title: {"en" :"S2085G 8 5/8\" Stainless Pipe (Unavailable)", "es": "S2085G 8 5/8\" Tubería Inoxidable"  }, title_centimeters: {"en" :"S2085G 21.91cm Stainless Pipe (Unavailable)", "es": "S2085G 21.91cm Tubería Inoxidable"  }},
                                    { id: SpanTypes.S2065G, title: {"en" :"S2065G 6 5/8\" Stainless Pipe" , "es": "S2065G 6 5/8\" Tubería Inoxidable" }, title_centimeters: {"en" :"S2065G 16.83cm Stainless Pipe" , "es": "S2065G 16.83cm Tubería Inoxidable" }},
                                    { id: SpanTypes.PL2085G, title: {"en" :"PL2085G 8 5/8\" Poly-lined", "es": "PL2085G 8 5/8\" Poly-lined"  }, title_centimeters: {"en" :"PL2085G 21.91cm Poly-lined", "es": "PL2085G 21.91cm Poly-lined"  }},
                                    { id: SpanTypes.PL2065G, title: {"en" :"PL2065G 6 5/8\" Poly-lined" , "es": "PL2065G 6 5/8\" Poly-lined" }, title_centimeters: {"en" :"PL2065G 16.83cm Poly-lined" , "es": "PL2065G 16.83cm Poly-lined" }},
                                ],
                                hook: (value: string) => {
                                    if (value) {
                                        // When changing the span type or length, uncheck Large Truss Rods and Stabilizers, to avoid
                                        // making the system unnecessarily expensive.
                                        // These might immediately be re-checked, if required, by the autoChange logic.
                                        return [
                                            { fieldPath: "Stabilizers", value: false },
                                            { fieldPath: "LargeTrussRods", value: false },
                                        ];
                                    }
                                },
                                enumNameHint: "SpanTypes",
                                includeOnlyValidationRules: []
                            },
                        },
                        {
                            title: {"en" :"Length", "es": "Longitud"},
                            arrayPath: "Span",
                            disableDealerSettings: true,
                            cmp: {
                                type: "complexChoice",
                                fieldPath: "Length",
                                title: "",
                                options: [
                                    {  title: {"en": "80'"}, title_meters: {"en": "24.4m"}, values: {"Length": 80, "Extension" : false } },
                                    {  title: {"en": "99'"}, title_meters: {"en": "30.2m"}, values: {"Length": 99, "Extension" : false}  },
                                    {  title: {"en": "118'"}, title_meters: {"en": "36.0m"}, values: {"Length": 118,"Extension" : false} },                                
                                    {  title: {"en": "120'"}, title_meters: {"en": "36.6m"}, values: {"Length": 120,"Extension" : false} },
                                    {  title: {"en": "122' (118' + 4' ext)"}, title_meters: {"en": "37.2m (36.0m + 1.2m ext)"}, values: {"Length": 118, "Extension" : true} },
                                    {  title: {"en": "137'"}, title_meters: {"en": "41.8m"}, values: {"Length": 137,"Extension" : false}  },                              
                                    {  title: {"en": "140'"}, title_meters: {"en": "42.7m"}, values: {"Length": 140,"Extension" : false} },
                                    {  title: {"en": "141' (137' + 4' ext)"}, title_meters: {"en": "43.0m (41.8m + 1.2m ext)"}, values: {"Length": 137, "Extension" : true}  },
                                    {  title: {"en": "156'"}, title_meters: {"en": "47.5m"}, values: {"Length": 156,"Extension" : false}  },
                                    {  title: {"en": "160'"}, title_meters: {"en": "48.8m"}, values: {"Length": 160,"Extension" : false} },
                                    {  title: {"en": "175'"}, title_meters: {"en": "53.3m"}, values: {"Length": 175,"Extension" : false}  },
                                    {  title: {"en": "179' (175' + 4' ext)"}, title_meters: {"en": "54.6m (53.3m + 1.2m ext)"}, values: {"Length": 175, "Extension" : true}  },
                                    {  title: {"en": "180'"}, title_meters: {"en": "54.9m"}, values: {"Length": 180,"Extension" : false} },
                                    {  title: {"en": "186.7'"}, title_meters: {"en": "56.9m"}, values: {"Length": 186.7,"Extension" : false} },
                                    {  title: {"en": "194'"}, title_meters: {"en": "59.1m"}, values: {"Length": 194,"Extension" : false} },
                                    {  title: {"en": "204'"}, title_meters: {"en": "62.2m"}, values: {"Length": 204,"Extension" : false} },
                                    {  title: {"en": "213'"}, title_meters: {"en": "64.9m"}, values: {"Length": 213,"Extension" : false} },
                                ],
                                includeOnlyValidationRules: [],
                                hook: () => {
                                    // When changing the span type or length, uncheck Large Truss Rods and Stabilizers, to avoid
                                    // making the system unnecessarily expensive.
                                    // These might immediately be re-checked, if required, by the autoChange logic.
                                    return [
                                        { fieldPath: "Stabilizers", value: false },
                                        { fieldPath: "LargeTrussRods", value: false },
                                    ];
                                },
                            },
                        },
                        {
                            title: {"en" :"Spacing", "es": "Salidas"},
                            arrayPath: "Span",
                            cmp: {
                                type: "choice",
                                fieldPath: "Spacing",
                                title: "",
                                options: [
                                    { id: 40, title: "40\"", title_centimeters: {"en":"101.6cm"}},
                                    { id: 57, title: "57\"", title_centimeters: {"en":"144.8cm" }},
                                    { id: 114, title: "114\"", title_centimeters: {"en":"289.6cm"}},
                                ],
                                validateBeforeOpen: true
                            },
                        },
                        {
                            title: "Min Cable Gauge",
                            arrayPath: "Span",
                            visible: (sys: ISystemBase) => !IsEDMP(sys),
                            cmp: {
                                type: "choice",
                                fieldPath: "MinCableGauge",
                                title: "",
                                options: [
                                    { id: 12, title: "#12 or larger" },
                                    { id: 10, title: "#10 or larger" },
                                    { id: 8, title: "#8" },
                                ],
                                validateBeforeOpen: true,
                                includeOnlyValidationRules: []
                            },
                        },
                        {
                            title: {"en" :"Ext. Conductors", "es": "Conductores Extra"},
                            arrayPath: "Span",
                            disableDealerSettings: true,
                            visible: (sys: ISystemBase) => !IsEDMP(sys),
                            cmp: {
                                type: "number",
                                isInteger: true,
                                fieldPath: "ExtraCableConductors",
                                title: "",
                                default: 0,
                                units: ""
                            },
                        },
                        {
                            title: {"en" :"Options", "es": "Opciones"},
                            arrayPath: "Span",
                            disableDealerSettings: true,
                            cmp: {
                                type: "choice",
                                fieldPath: "",
                                title: "",
                                options: [
                                ],
                                additionalBooleans: [
                                    { type: "boolean", title: {"en" :"Stabilizers", "es": "Estabilizadores"}, fieldPath: "Stabilizers"},
                                    { type: "boolean", title: {"en" :"Truss Rods 3/4\"", "es": "Tensores de Tramos en 3/4\""}, title_centimeters: {"en" :"Truss Rods 1.91cm", "es": "Tensores de Tramos en 1.91cm"}, fieldPath: "LargeTrussRods"},
                                ],
                            },
                        },
                    ],
                },
                {
                    type: "booleangroup",
                    components: [
                        { type: "boolean", disableDealerSettings: true, title: {"en" :"Alum IV Longitudinal Support Rods", "es": "Barras de Soporte p/Alumigator IV"}, fieldPath: "SpanProperties.AlumIVSupportRod"},
                        { type: "boolean", disableDealerSettings: true, title: {"en" :"Long System Alignment (Recommended on systems longer than 9 spans.)", "es": "Alineamiento p/Sistemas Largos (Recomendado para sistemas de 9 tramos o mas)"}, fieldPath: "SpanProperties.LongSystemAlignment"},
                        { type: "boolean", disableDealerSettings: true, title: "Stainless Steel Cable", fieldPath: "SpanProperties.StainlessSteelCable"},
                        { type: "boolean", title: {"en" :"Stainless Steel Span Joints", "es": "Uniones de Tramo en Acero Inoxidable"}, fieldPath: "SpanProperties.StainlessSteelJoints"},
                        { type: "boolean", title: "Galvanized CN + Span Joints", fieldPath: "SpanProperties.GalvanizedCNJoints"},
                        { type: "boolean", disableDealerSettings: true, title: {"en" :"Galvanized CN + Pipe", "es": "Tubería Galvanizada CN+"}, fieldPath: "SpanProperties.GalvanizedCNPlus"},
                        { type: "boolean", title: "Theft Deterrent Conduit Clip Package", fieldPath: "SpanProperties.TheftDeterrentClip"},
                        { type: "boolean", title: {"en" :"Conduit Clip Package Less Wire Spring Clips (Does Not Meet National Electrical Code)", "es": "Paquete Clips de Counduit menos Clips (no cubre el Código Electico Nacional)"}, fieldPath: "SpanProperties.ConduitClipWOSpringClip"},
                    ],
                },  
            ],
        },
    ],
    rules: [
        {
            apply: (system, user, grower, quick) =>
            system.SpanProperties.GalvanizedCNPlus && !user.permissions.enableCNPlus ? [{
                id: "spans.galvanizedCnPlus.notEnabled",
                description: `The Galvanized CN+ Pipe feature is not currently activated for your dealership. To request access, please contact email reinkedesigncenter@reinke.com or call 402-365-7251.`,
                severity: "error",
                targets: [
                    "SpanProperties.GalvanizedCNPlus"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) => {
                let rr: IRuleResult[] = [];

                if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator){
                    system.FlangedSide.Span.filter(x => x.MinCableGauge !== undefined && x.MinCableGauge !== 10).forEach((span, i) => {
                        rr.push({
                            id: "roeLegacy.MinCableGaugeNot10",
                            description: "Lateral spans must have #10 min cable gauge",
                            severity: "error",
                            targets: [
                                `FlangedSide.Span[${i}].MinCableGauge`
                            ]
                        })
                    });

                    if (IsDualSided(system)){
                        system.FlexSide.Span.filter(x => x.MinCableGauge !== undefined && x.MinCableGauge !== 10).forEach((span, i) => {
                            rr.push({
                                id: "roeLegacy.MinCableGaugeNot10",
                                description: "Lateral spans must have #10 min cable gauge",
                                severity: "error",
                                targets: [
                                    `FlexSide.Span[${i}].MinCableGauge`
                                ]
                            })
                        });
                    }
                }

                return rr;
            }
        }
    ] as IRule[]
};