import { ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "kwikTow",
            title: "Kwik Tow",
            disableDealerSettings: true,
            position: 2,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.KwikTow,
            components: [         
                        {
                            title: { "en": "Tires", "es": "Neumático" },
                            type: "complexChoice",
                            validateBeforeOpen: true,
                            autoChange: false,
                            options: [
                                {
                                    title: "11 x 22.5", nestedOptions: [
                                        { title: { "en": "Recaps", "es": "Recapeadas" }, values: { "Circle.KwikTow.Tires.TireSize": "a11x225", "Circle.KwikTow.Tires.TireType": "Recaps" } }
                                    ]
                                },
                                {
                                    title: "11.2 x 24", nestedOptions: [
                                        { title: { "en": "4-Ply", "es": "4 Capas" }, values: { "Circle.KwikTow.Tires.TireSize": "a112x24", "Circle.KwikTow.Tires.TireType": "FourPly" } }
                                    ]
                                },
                                {
                                    title: "14.9 x 24", nestedOptions: [
                                        { title: { "en": "4-Ply", "es": "4 Capas" }, values: { "Circle.KwikTow.Tires.TireSize": "a149x24", "Circle.KwikTow.Tires.TireType": "FourPly" } },
                                        { title: "Non-Directional", values: { "Circle.KwikTow.Tires.TireSize": "a149x24", "Circle.KwikTow.Tires.TireType": "NonDirectional" } }
                                    ]
                                }
                            ],
                        },
                        {
                            type: "booleangroup",
                            layoutHint: "secondary",
                            components: [
                                { type: "boolean", title: "Reverse Tow", fieldPath: "SystemProperties.ReverseTow"},
                                { type: "boolean", title: "Motorized Center", fieldPath: "Circle.KwikTow.MotorizedCenter"},
                                { type: "boolean", title: "Hydraulic Lift", fieldPath: "Circle.KwikTow.HydraulicLift"},
                                { type: "boolean", title: "Safety Valve", fieldPath: "Circle.KwikTow.SafetyValve"},
                                { type: "boolean", title: "Tubes For Tires", fieldPath: "Circle.KwikTow.Tires.TubesForTires"},
                                { type: "boolean", title: "One Tire Reversed", fieldPath: "Circle.KwikTow.Tires.OneTireMountedInReverse"},
                            ],
                        },  
                        {
                            type: "number",
                            fieldPath: "Circle.KwikTow.ExtraPivotAnchors",
                            title: "Extra Pivot Anchors",
                        },  
                        {
                            type: "number",
                            fieldPath: "Circle.KwikTow.PercentOfCircle",
                            title: "Percent of Circle Irrigated",
                        },                         
            ],
        },
    ],
};