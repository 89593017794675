import * as React from "react";
import { useContext, useEffect, useState } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { v4 as uuidv4 } from "uuid";

import { Alert, Backdrop, DialogContent, TextField } from "@mui/material";
import i18next, { t } from "i18next";
import { setProjectStateAction } from "../../../actions/SetProjectStateAction";
import { createNewUpdateProjectPropertyAction } from "../../../actions/UpdateProjectProperty";
import AuthCtx from "../../../auth/AuthCtx";
import Spinner from "../../../components/Spinner";
import DbCtx from "../../../db/DbCtx";
import IProject from "../../../model/project/IProject";

interface IProps {
    projectId: string;
    onCancel: () => void;
    onCopy: (newProjectId: string) => void;
}

const CopySystemConfirmationDialog: React.FC<IProps> = ({
    projectId, onCancel, onCopy
}) => {
    
    const authState = useContext(AuthCtx);
    const dbState = useContext(DbCtx);    
    const dbPrj = dbState.projects[projectId];

    const [newProjectId, setNewProjectId] = useState(
        undefined as string | undefined
    );

    const [fieldName, setNewFieldName] = useState(dbPrj.state.name);

    const handleCancel = () => {
        onCancel();
    }
    
    useEffect(()=>{
        if (typeof newProjectId !== "undefined" && dbState.projects[newProjectId]) {
            onCopy(newProjectId);
        }
    }, [newProjectId, dbState.projects[newProjectId] ])

    if (newProjectId) {
        return (
            <Backdrop open={true} sx={{ zIndex: 999, backgroundColor: "white" }}>
                <Spinner title="Copying System" />
            </Backdrop>
        );
    }
    return (
        <Dialog open={true} onClose={handleCancel}>
            <DialogTitle>{i18next.format(t('copy-system'), 'capitalize-each')}</DialogTitle>
            <DialogContent>
                <p>{t("nav.systems.confirm-copy-system")}</p>
                <TextField
                    error={fieldName === ""}
                    sx={{ width: 300 }}
                    value={fieldName}
                    onChange={(ev) => setNewFieldName(ev.target.value)}
                    label={t("projects.field-name")}
                />
                {fieldName === "" && <Alert sx={{marginTop: "0.5em"}} severity='error'>{t("nav.systems.field-name-not-blank")}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{t("cancel")}</Button>
                <Button onClick={async() => {
                    const newProject: IProject = JSON.parse(JSON.stringify(dbPrj.state));
                    for (const layout of Object.values(newProject.layouts)) {
                        for (const oldSystemId of Object.keys(layout.systems)) {
                            const system = layout.systems[oldSystemId];

                            // Change system ID since this needs to be unique for MAPICs submission
                            const newSystemId = uuidv4();
                            layout.systems[newSystemId] = system;
                            delete layout.systems[oldSystemId];

                            // This feature should only be used for single-system projects anyway, but for simplicity
                            // just iterate over all systems.
                            system.proposalGenerated = false;
                            system.orderSubmissionStatus = undefined;
                            system.createQuoteStatus = undefined;
                            system.QuoteProperties.ProposalInformation.requestedShippingDate = undefined;
                        }
                    }

                    if (fieldName !== ""){
                        setNewProjectId(dbState.newProject([
                            setProjectStateAction(authState, newProject).action,
                            createNewUpdateProjectPropertyAction("name", fieldName, authState)
                        ]));
                    }
                }}>{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CopySystemConfirmationDialog;