import { getEndOfSystemIfValid } from "rdptypes/helpers/EndOfSystem";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import ISystem from "rdptypes/project/ISystem";
import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../../helpers/lengths";
import { SYSTEM_DESIGN_CONSTANTS } from "../SystemDiagram/constants";
import { IElevationProfile } from "../interfaces";
import { towerDefinitionFromTowerOrPivot } from "../towerDefinition";
import PivotCenter from "./PivotCenter";
import Span, { ISpanDiagramElementProps } from "./Span";
import Tower from "./Tower";

import * as spanf from "roedata/roe_migration/SpanFunctions";
import { getSwingArmLengths } from "../../../reinkeComponentConversions/swingArmLengths";
import EndBoom from "./EndBoom";

interface IProps {
    system: ISystem;
    minElevationFeet: number;
    maxElevationFeet: number;
    elevationProfile: IElevationProfile;
    zoom: boolean;
    justSystemDiagram?: {
        partWidthFeet: number,
        widthInPixels: number    
    };
}

const CenterPivotDiagram: FC<IProps> = ({system, minElevationFeet: incommingMinElevationFeet, maxElevationFeet: incommingMaxElevationFeet, elevationProfile, zoom, justSystemDiagram}) => {
    const settings = React.useContext(DevSettingsCtx);
    // The coordinate reference system uses units of inches:
    //  * X = 0 - Middle of pivot center riser, or aft side of lateral
    //  * X increases towards the end of the system
    //  * Y = 0 - minElevationFeet * 12
    //  * Y increases up (by using transform on svg element)

    if (!elevationProfile) return null;

    const profileTextElements: React.JSX.Element[] = [];
    const textFontStyle = `bold 5em sans-serif`;

    // calculate the total running system length:
    let totalSystemLengthInches = system.FlangedSide.Span.length
        ? spanf.EndingRadius(system, system.FlangedSide, system.FlangedSide.Span[system.FlangedSide.Span.length - 1]) * 12
        : 0;
    const hasEndGun = (system.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary !== EndGunTypes.None || system.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary !== EndGunTypes.None);
    
    let maxSystemHeightFeet = towerDefinitionFromTowerOrPivot(system.centerPivot!).heightFeet;
    const spansWithoutEos = system.FlangedSide.Span.filter(x => !x.EndBoom && !x.SwingArm);
    for (let i = 0; i < spansWithoutEos.length; i++) {
        const span = spansWithoutEos[i];
        const tower = system.FlangedSide.Tower[i];
        if (!Number.isFinite(spanf.LengthInFeet(system.FlangedSide, span))) {
            // then we will not generate a diagram
            console.log("Incomplete system definition, system diagram not rendered");
            return null;
        }
        const towerDefinition = towerDefinitionFromTowerOrPivot(tower);
        maxSystemHeightFeet = Math.max(maxSystemHeightFeet, towerDefinition.heightFeet);
    }
    const eos = getEndOfSystemIfValid(system, SideEnum.Flanged);
    
    // find the profile elevation max/min:
    let minProfileElevationFeet = Number.MAX_VALUE;
    let maxProfileElevationFeet = Number.MIN_VALUE;
    for (const elevationProfileItem of elevationProfile) {
        if (elevationProfileItem.maxDistanceFeet >= 0 && elevationProfileItem.minDistanceFeet <= totalSystemLengthInches * 12) {
            minProfileElevationFeet = Math.min(minProfileElevationFeet, elevationProfileItem.elevationFeet);
            maxProfileElevationFeet = Math.max(maxProfileElevationFeet, elevationProfileItem.elevationFeet);
        }
    }

    const minElevationFeet = zoom ? minProfileElevationFeet : incommingMinElevationFeet;
    const maxElevationFeet = zoom ? maxProfileElevationFeet : incommingMaxElevationFeet;

    /**
     * The distance of the near end of the system from the middle of the pivot center riser pipe.
     * This will normally just be the size of the pivot center frame.
     */
    const nearDistanceFromRiser = 10 * 12;
    const maxDepthBelowGroundInches = 3 * 12; // Show 3 feet of ground even at lowest elevation
    /** The distance of the far end of the system from the middle of the pivot center riser pipe. */
    const farDistanceFromRiser = totalSystemLengthInches + 10 * 12;
    const systemHeightAndSkyFeet = maxSystemHeightFeet + 10;
    const skyUpperElevationInches = (maxElevationFeet + systemHeightAndSkyFeet) * 12; // The elevation for upper sky line
    const baselineGroundUpperElevationInches = minElevationFeet * 12;

    // set up svg viewbox dimenstions:
    const svgLeft = -nearDistanceFromRiser;
    const svgWidth = totalSystemLengthInches === 0 ? 0 : farDistanceFromRiser + nearDistanceFromRiser;
    const svgTop = minElevationFeet * 12 - maxDepthBelowGroundInches;
    const svgHeight = maxDepthBelowGroundInches + (maxElevationFeet - minElevationFeet + systemHeightAndSkyFeet) * 12;
    
    // Generate the diagram elements
    const groundBaselineElement = (
        <rect 
            x={svgLeft} y={minElevationFeet * 12 - maxDepthBelowGroundInches} 
            width={svgWidth} height={maxDepthBelowGroundInches} 
            fill={SYSTEM_DESIGN_CONSTANTS.ground.color} 
            stroke={SYSTEM_DESIGN_CONSTANTS.ground.color}
        />
    )
    
    const skyElement = (
        <rect 
            x={svgLeft} y={baselineGroundUpperElevationInches} 
            width={svgWidth} height={skyUpperElevationInches - baselineGroundUpperElevationInches}
            fill="#D4E6F1"
        />
    )
    
    const groundElements: JSX.Element[] = [];
    const groundSegmentWidthInches = 10 * 12;
    for (let distanceInches = svgLeft; distanceInches < svgWidth + svgLeft; distanceInches += groundSegmentWidthInches) {
        const sectionWidthInches = Math.min(groundSegmentWidthInches, svgWidth + svgLeft - distanceInches);
        const startDistanceFeet = distanceInches / 12;
        const endDistanceFeet = startDistanceFeet + sectionWidthInches / 12;
        const startElevationProfileItem = elevationProfile.find(y => startDistanceFeet < y.maxDistanceFeet && startDistanceFeet >= y.minDistanceFeet);
        const endElevationProfileItem = elevationProfile.find(y => endDistanceFeet < y.maxDistanceFeet && endDistanceFeet >= y.minDistanceFeet);
        if (startElevationProfileItem === undefined || endElevationProfileItem === undefined) {
            // then we will not generate a diagram
            console.log("Incomplete elevation profile, system diagram not rendered 1");
            return null;
        }
        groundElements.push(
            <polygon 
                key={`ground-${distanceInches}`} 
                points={`
                    ${distanceInches} ${minElevationFeet * 12}
                    ${distanceInches} ${startElevationProfileItem.elevationFeet * 12}
                    ${distanceInches + sectionWidthInches} ${endElevationProfileItem.elevationFeet * 12}
                    ${distanceInches + sectionWidthInches} ${minElevationFeet * 12}
                `}
                fill={SYSTEM_DESIGN_CONSTANTS.ground.color}
                stroke={SYSTEM_DESIGN_CONSTANTS.ground.color}
            />
        )
    }    

    // keep track of current distance along system when creating components
    let pivotElevationFt: number = 0;
    let pivotElement: JSX.Element | undefined = undefined;
    {
        const startDistanceFeet = 0;
        const elevationProfileItem = elevationProfile.find(y => startDistanceFeet <= y.maxDistanceFeet && startDistanceFeet >= y.minDistanceFeet);
        if (elevationProfileItem === undefined) {
            // then we will not generate a diagram
            console.log("Incomplete elevation profile, system diagram not rendered 2");
            return null;
        }
        pivotElevationFt = elevationProfileItem.elevationFeet;
        const centerPivotDefinition = towerDefinitionFromTowerOrPivot(system.centerPivot!);
        pivotElement = <PivotCenter elevationFeet={elevationProfileItem.elevationFeet} heightInches={centerPivotDefinition.heightFeet * 12} />;
    }
    
    const spanElements: JSX.Element[] = [];    
    const endGunOnForwardSpans = !eos && hasEndGun;
    let lastSpanBeforeEndBoomArgs: ISpanDiagramElementProps | undefined = undefined;
    for (let i = 0; i < spansWithoutEos.length; i++) {
        const span = spansWithoutEos[i];
        const tower = system.FlangedSide.Tower[i];
        const preceedingSpanTowerTower = i === 0 ? system.centerPivot! : system.FlangedSide.Tower[i-1];

        const spanLengthFeet = spanf.LengthInFeet(system.FlangedSide, span);
        const spanLengthInches = spanLengthFeet * 12;

        const startDistanceFeet = spanf.StartingRadius(system, system.FlangedSide, span);
        const startDistanceInches = startDistanceFeet * 12;

        const endDistanceFeet = spanf.EndingRadius(system, system.FlangedSide, span);
        const endDistanceInches = endDistanceFeet * 12;

        const startElevationProfileItem = elevationProfile.find(y => startDistanceFeet < y.maxDistanceFeet && startDistanceFeet >= y.minDistanceFeet);
        const endElevationProfileItem = elevationProfile.find(y => endDistanceFeet < y.maxDistanceFeet && endDistanceFeet >= y.minDistanceFeet);
        if (startElevationProfileItem === undefined || endElevationProfileItem === undefined) {
            // then we will not generate a diagram
            console.log("Incomplete elevation profile, system diagram not rendered");
            return null;
        }
        
        const endTowerDefinition = towerDefinitionFromTowerOrPivot(tower);
        const startTowerDefinition = towerDefinitionFromTowerOrPivot(preceedingSpanTowerTower);

        lastSpanBeforeEndBoomArgs = {
            spanLengthInches: spanLengthInches,
            nozzleSpacingInches: span.Spacing,
            startDistanceInches: startDistanceInches,
            startHeightInches: startTowerDefinition.heightFeet * 12,
            endHeightInches: endTowerDefinition.heightFeet * 12,
            elevationStartFeet: startElevationProfileItem.elevationFeet,
            elevationEndFeet: endElevationProfileItem.elevationFeet,
            endGun: endGunOnForwardSpans && i === (spansWithoutEos.length - 1),
        }
        spanElements.push(
            <Span 
                key={`span${spanElements.length}`} 
                {...lastSpanBeforeEndBoomArgs}
            />
        );
        spanElements.push(
            <Tower 
                key={`tower${spanElements.length}`} 
                towerHeightInches={endTowerDefinition.heightFeet * 12}
                startDistanceInches={endDistanceInches} 
                elevationFeet={endElevationProfileItem.elevationFeet} 
            />
        )
        profileTextElements.push(
            <text 
                key={`span-label-${i+1}`} 
                x={startDistanceInches + 0.5 * spanLengthInches} 
                y={-(startElevationProfileItem.elevationFeet + endElevationProfileItem.elevationFeet)*0.5*12 - 14*12}
                style={{ font: textFontStyle }}
            >
                {i+1}
            </text>
        )
    }

    if (eos) {
        const endBoomIndex = system.FlangedSide.Span.findIndex(x => x.EndBoom);
        const swingArmIndex = system.FlangedSide.Span.findIndex(x => x.SwingArm);

        if (swingArmIndex > 0) {
            const span = system.FlangedSide.Span[swingArmIndex];
            // NOTE: There is no swing arm tower stored, so we will use the last tower
            const preceedingSpanTowerTower = system.FlangedSide.Tower.length === 0 
                ? system.centerPivot! 
                : system.FlangedSide.Tower[system.FlangedSide.Tower.length - 1];
            const tower = preceedingSpanTowerTower;
    
            // Note: The swing arm is not fully extended, and the length in feet does not account for this.
            // As such, the angular extension length given by start/end radius is used to calculate span length    
            const startDistanceFeet = spanf.StartingRadius(system, system.FlangedSide, span);
            const startDistanceInches = startDistanceFeet * 12;
    
            const endDistanceFeet = spanf.EndingRadius(system, system.FlangedSide, span);
            const endDistanceInches = endDistanceFeet * 12;
            
            const spanLengthInches = endDistanceInches - startDistanceInches;
    
            const startElevationProfileItem = elevationProfile.find(y => startDistanceFeet < y.maxDistanceFeet && startDistanceFeet >= y.minDistanceFeet);
            const endElevationProfileItem = elevationProfile.find(y => endDistanceFeet < y.maxDistanceFeet && endDistanceFeet >= y.minDistanceFeet);
            if (startElevationProfileItem === undefined || endElevationProfileItem === undefined) {
                // then we will not generate a diagram
                console.log("Incomplete elevation profile, system diagram not rendered");
                return null;
            }
            
            const endTowerDefinition = towerDefinitionFromTowerOrPivot(tower);
            const startTowerDefinition = towerDefinitionFromTowerOrPivot(preceedingSpanTowerTower);
            lastSpanBeforeEndBoomArgs = {
                spanLengthInches: spanLengthInches,
                nozzleSpacingInches: span.Spacing,
                startDistanceInches: startDistanceInches,
                startHeightInches: startTowerDefinition.heightFeet * 12,
                endHeightInches: endTowerDefinition.heightFeet * 12,
                elevationStartFeet: startElevationProfileItem.elevationFeet,
                elevationEndFeet: endElevationProfileItem.elevationFeet,
            }
            spanElements.push(
                <Span 
                    key={`span${spanElements.length}`} 
                    {...lastSpanBeforeEndBoomArgs}
                />
            );
            spanElements.push(
                <Tower 
                    key={`tower${spanElements.length}`} 
                    towerHeightInches={endTowerDefinition.heightFeet * 12}
                    startDistanceInches={endDistanceInches} 
                    elevationFeet={endElevationProfileItem.elevationFeet} 
                />
            )
            profileTextElements.push(
                <text 
                    key={`eos-label`} 
                    x={startDistanceInches + 0.5 * spanLengthInches} 
                    y={-(startElevationProfileItem.elevationFeet + endElevationProfileItem.elevationFeet)*0.5*12 - 14*12}
                    style={{ font: textFontStyle }}
                >
                    {
                        getSwingArmLengths("feet").find(x => x.originalValue === system.FlangedSide?.EndOfSystem?.SwingArmLength)?.shortLabel || "SAC"
                    }
                </text>
            )
        }

        if (endBoomIndex > 0 && lastSpanBeforeEndBoomArgs) {
            const span = system.FlangedSide.Span[endBoomIndex];
            // NOTE: There is no swing arm tower stored, so we will use the last tower
            const preceedingSpanTowerTower = system.FlangedSide.Tower.length === 0 
                ? system.centerPivot! 
                : system.FlangedSide.Tower[system.FlangedSide.Tower.length - 1];
    
            // Note: If there is a swing arm, the swing arm and end boom are not fully extended, and the length in feet does not account for this.
            // As such, the angular extension length given by start/end radius is used to calculate span length    
            const startDistanceFeet = spanf.StartingRadius(system, system.FlangedSide, span);
            const startDistanceInches = startDistanceFeet * 12;
    
            const endDistanceFeet = spanf.EndingRadius(system, system.FlangedSide, span);
            const endDistanceInches = endDistanceFeet * 12;
            
            const spanLengthInches = endDistanceInches - startDistanceInches;
    
            const startElevationProfileItem = elevationProfile.find(y => startDistanceFeet < y.maxDistanceFeet && startDistanceFeet >= y.minDistanceFeet);
            const endElevationProfileItem = elevationProfile.find(y => endDistanceFeet < y.maxDistanceFeet && endDistanceFeet >= y.minDistanceFeet);
            if (startElevationProfileItem === undefined || endElevationProfileItem === undefined) {
                // then we will not generate a diagram
                console.log("Incomplete elevation profile, system diagram not rendered");
                return null;
            }
            
            const startTowerDefinition = towerDefinitionFromTowerOrPivot(preceedingSpanTowerTower);
            const endHeightInches = (startTowerDefinition.heightFeet + 2) * 12; 
            spanElements.push(
                <EndBoom 
                    key={`span${spanElements.length}`} 
                    spanLengthInches={spanLengthInches}
                    nozzleSpacingInches={span.Spacing}
                    startDistanceInches={startDistanceInches} 
                    startHeightInches={startTowerDefinition.heightFeet * 12}
                    endHeightInches={endHeightInches}
                    elevationStartFeet={startElevationProfileItem.elevationFeet}
                    elevationEndFeet={endElevationProfileItem.elevationFeet}
                    endGun={hasEndGun}
                    lastSpanBeforeEndBoomArgs={lastSpanBeforeEndBoomArgs}
                />
            );
        }
    }

    const profileElevatonElements = justSystemDiagram ? [] : [
        <line 
            key={'min-profile-elevation'} 
            x1={svgLeft} x2={svgLeft + svgWidth} 
            y1={minProfileElevationFeet * 12} y2={minProfileElevationFeet * 12} 
            stroke='deeppink' strokeWidth={12} />,
        <line 
            key={'max-profile-elevation'} 
            x1={svgLeft} x2={svgLeft + svgWidth} 
            y1={maxProfileElevationFeet * 12} y2={maxProfileElevationFeet * 12} 
            stroke='deeppink' strokeWidth={12} />
    ]
    
    const minProfileRelativeElevation = minProfileElevationFeet - pivotElevationFt;
    const maxProfileRelativeElevation = maxProfileElevationFeet - pivotElevationFt;
    const minLabel = new DisplayLengthUnitBuilder(minProfileRelativeElevation, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(1)
        .appendString(" ")
        .appendShortName()
        .toString();
    const maxLabel = new DisplayLengthUnitBuilder(maxProfileRelativeElevation, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(1)
        .appendString(" ")
        .appendShortName()
        .toString();
    if (!justSystemDiagram) {
        profileTextElements.push(
            <text 
                key={'label-min-profile-elevation'} 
                x={-5*12} 
                y={Math.min(-baselineGroundUpperElevationInches, -minProfileElevationFeet*12 + 10*12)}
                style={{ font: textFontStyle }}
                >{minLabel}</text>,
            <text 
                key={'label-max-profile-elevation'} 
                x={-5*12} 
                y={Math.max(-skyUpperElevationInches + 7*12, -maxProfileElevationFeet*12 - 3 * 12)}
                style={{ font: textFontStyle }}
                >{maxLabel}</text>
        );
    }
    
    const partRanges: { leftInches: number, rightInches: number, height: number, top: number }[] = [];
    if (justSystemDiagram) {

        const pixlesX = justSystemDiagram.widthInPixels;
        const targetInchesX = justSystemDiagram.partWidthFeet * 12;
        const inchesPerPixel = targetInchesX / pixlesX;

        let height = inchesPerPixel * 200;

        // then we will break the SVG into parts
        const partWidthInches = targetInchesX;// partWidthFeet * 12;
        const parts = Math.ceil(svgWidth / partWidthInches);
        for (let i = 0; i < parts; i++) {
            const left = svgLeft + partWidthInches * i
            const leftFeet = left / 12;
            const rightFeet = leftFeet + partWidthInches / 12;
            const ep1 = elevationProfile.find(y => leftFeet < y.maxDistanceFeet && leftFeet >= y.minDistanceFeet);
            const ep2 = elevationProfile.find(y => rightFeet < y.maxDistanceFeet && rightFeet >= y.minDistanceFeet);
            let avg: number;
            if (ep1 && ep2) {
                avg = -(ep1.elevationFeet + ep2.elevationFeet) * 0.5 * 12
            }
            else if (ep1) {
                avg = -ep1.elevationFeet * 12;
            }
            else if (ep2) {
                avg = -ep2.elevationFeet * 12;
            }
            else {
                avg = 0;
            }
            const top = Math.max(avg - 0.5 * height, -skyUpperElevationInches)
            partRanges.push({
                leftInches: left,
                rightInches: partWidthInches,
                height,
                top
            })
        }
    }
    else {
        partRanges.push({
            leftInches: svgLeft,
            rightInches: svgWidth,
            height: svgHeight,
            top: -skyUpperElevationInches
        })
    }
    
    return (
        <div
            style={{ 
                overflowX: zoom ? 'scroll' : undefined,
                overflowY: 'hidden',
                height: "100%"
            }}
        >
            {
                partRanges.map((x,i) => {
                    return (
                        <svg
                            key={`svg-part-${i}`}
                            style={{height: zoom ? 180 : undefined, maxHeight: 200 }}
                            viewBox={`
                                ${x.leftInches} ${x.top} 
                                ${x.rightInches} ${x.height}
                            `}
                        >
                            <g  
                                transform="scale(1,-1)" 
                                x={svgLeft} y={svgTop} width={svgWidth} height={svgHeight}
                            >
                                {skyElement}
                                {groundBaselineElement}
                                {groundElements}
                                {pivotElement}
                                {spanElements}
                                {profileElevatonElements}
                            </g>
                            <g  
                                x={svgLeft} y={svgTop} width={svgWidth} height={svgHeight}
                            >
                                {profileTextElements}
                            </g>
                        </svg>
                    )
                })
            }
        </div>
    );
};

export default CenterPivotDiagram;