// Note, the v1 method incorrectly performs ops in decimal coordinates,
// this new version first converts to meters coordinates

// Method 3 adapted from:
// https://www.redblobgames.com/articles/visibility/

import { Feature, MultiPolygon, Point, Polygon, Position, bbox, distance, feature, polygon } from "@turf/turf";
import { customDifference } from "rdptypes/geometry/helpers/turf";
import { MetersProjectionHelper } from "./sac/projection";

// https://github.com/Silverwolf90/2d-visibility
interface IEndpoint {
    x: number;
    y: number;
    beginsSegment?: boolean;
    segment?: ISegment;
    angle?: number;
}
interface ISegment {
    p1: IEndpoint;
    p2: IEndpoint;
    d: number;
}
  
const interpolate = (pointA: MetersPosition, pointB: MetersPosition, f: number): MetersPosition => {
    return [
        pointA[0]*(1-f) + pointB[0]*f,
        pointA[1]*(1-f) + pointB[1]*f
    ]
};

type MetersPosition = [ number, number ]
type MetersLineString = MetersPosition[];
type MetersPolygon = MetersLineString[];

const lineIntersection = (point1: MetersPosition, point2: MetersPosition, point3: MetersPosition, point4: MetersPosition): MetersPosition | null => {
    const d = (
        (point4[1] - point3[1]) * (point2[0] - point1[0]) -
        (point4[0] - point3[0]) * (point2[1] - point1[1])
    );
    if (d === 0) {
        return null;
    }
    const s = (
      (point4[0] - point3[0]) * (point1[1] - point3[1]) -
      (point4[1] - point3[1]) * (point1[0] - point3[0])
    ) / d;
    
    return [
      point1[0] + s * (point2[0] - point1[0]),
      point1[1] + s * (point2[1] - point1[1])
    ];
};
const getTrianglePoints = (origin: MetersPosition, angle1: number, angle2: number, segment: Segment): [ MetersPosition, MetersPosition ] => {
    const p1 = origin;
    const p2: MetersPosition = [ origin[0] + Math.cos(angle1), origin[1] + Math.sin(angle1) ];
    const p3: MetersPosition = [ 0, 0 ];
    const p4: MetersPosition = [ 0, 0 ];
  
    if (segment) {
      p3[0] = segment.p1.position[0];
      p3[1] = segment.p1.position[1];
      p4[0] = segment.p2.position[0];
      p4[1] = segment.p2.position[1];
    } else {
      p3[0] = origin[0] + Math.cos(angle1) * 500;
      p3[1] = origin[1] + Math.sin(angle1) * 500;
      p4[0] = origin[0] + Math.cos(angle2) * 500;
      p4[1] = origin[1] + Math.sin(angle2) * 500;
    }
  
    const pBegin = lineIntersection(p3, p4, p1, p2);
    if (!pBegin) {
        return [ [Number.NaN, Number.NaN] , [Number.NaN, Number.NaN] ]
    }
  
    p2[0] = origin[0] + Math.cos(angle2);
    p2[1] = origin[1] + Math.sin(angle2);
  
    const pEnd = lineIntersection(p3, p4, p1, p2);
    if (!pEnd) {
        return [ [Number.NaN, Number.NaN] , [Number.NaN, Number.NaN] ]
    }
  
    return [pBegin, pEnd];
};

class Endpoint {
    private _position: MetersPosition;
    private _beginsSegment: boolean;
    private _segment: Segment;
    private _angle: number;

    constructor(tp: MetersPosition, segment: Segment, angle: number, beginsSegment: boolean) {
        this._segment = segment;
        this._position = tp;
        this._angle = angle;
        this._beginsSegment = beginsSegment;
    }

    public get angle() { return this._angle; }
    public get beginsSegment() { return this._beginsSegment; }
    public get segment() { return this._segment; }
    public get position() { return this._position; }

    public static compareTo = (ep1: Endpoint, ep2: Endpoint) => {
        if (ep1.angle! > ep2.angle!) return 1;
        if (ep1.angle! < ep2.angle!) return -1;
        if (!ep1.beginsSegment && ep2.beginsSegment) return 1;
        if (ep1.beginsSegment && !ep2.beginsSegment) return -1;
        return 0;
    }
}
class Segment {
    private _p1: Endpoint;
    private _p2: Endpoint;
    constructor(tp1: MetersPosition, tp2: MetersPosition, tc: MetersPosition) {
        const angles = this._calculateEndPointAngles(tc, tp1, tp2);
        const p1beginsSegment = this._calculateP1BeginsSegment(angles.p1angle, angles.p2Angle);
        this._p1 = new Endpoint(tp1, this, angles.p1angle, p1beginsSegment);
        this._p2 = new Endpoint(tp2, this, angles.p2Angle, !p1beginsSegment);

    }
    
    public get p1() { return this._p1; }
    public get p2() { return this._p2; }

    private _calculateEndPointAngles = (tc: MetersPosition, tp1: MetersPosition, tp2: MetersPosition) => {
        const [ x, y ] = tc;
        const [ p1x, p1y ] = tp1;
        const [ p2x, p2y ] = tp2;
        // const dx = 0.5 * (p1x + p2x) - x;
        // const dy = 0.5 * (p1y + p2y) - y;
        
        return {
            // d: (dx * dx) + (dy * dy),
            p1angle: Math.atan2(p1y - y, p1x - x),
            p2Angle: Math.atan2(p2y - y, p2x - x)
        }
    };
    
    private _calculateP1BeginsSegment = (angle1: number, angle2: number) => {
        let dAngle = angle2 - angle1;
    
        if (dAngle <= -Math.PI) dAngle += 2 * Math.PI;
        if (dAngle >   Math.PI) dAngle -= 2 * Math.PI;
        
        return dAngle > 0;
    };

    private _leftOf = (point: MetersPosition) => {
        const cross = (this.p2.position[0] - this.p1.position[0]) * (point[1] - this.p1.position[1])
                    - (this.p2.position[1] - this.p1.position[1]) * (point[0] - this.p1.position[0]);
        return cross < 0;
    }
    
    public inFrontOf = (segment: Segment, relativePoint: MetersPosition) => {
        const A1 = this._leftOf(interpolate(segment.p1.position, segment.p2.position, 0.01));
        const A2 = this._leftOf(interpolate(segment.p2.position, segment.p1.position, 0.01));
        const A3 = this._leftOf(relativePoint);
        const B1 = segment._leftOf(interpolate(this.p1.position, this.p2.position, 0.01));
        const B2 = segment._leftOf(interpolate(this.p2.position, this.p1.position, 0.01));
        const B3 = segment._leftOf(relativePoint);
    
        if (B1 === B2 && B2 !== B3) return true;
        if (A1 === A2 && A2 === A3) return true;
        if (A1 === A2 && A2 !== A3) return false;
        if (B1 === B2 && B2 === B3) return false;
    
        return false;
    };
}

const generateSegments = (args: {
    lineSegments: MetersLineString[],
    center: MetersPosition
}) => {

    const segments: Segment[] = [];
    // convert lines into segments:
    for (const l of args.lineSegments) {
        const segment = new Segment(l[0], l[1], args.center);
        segments.push(segment);
    }

    return segments;
}

const extractEndpoints = (segments: Segment[]) => {
    const endpoints = segments.flatMap(s => [ s.p1, s.p2 ]);
    endpoints.sort(Endpoint.compareTo);
    return endpoints;
}

const findTrianglePositionsByCenter = (center: MetersPosition, segments: Segment[], endpoints: Endpoint[]): [ MetersPosition, MetersPosition ][] => {

    let openSegments: Segment[] = [];
    let output: [ MetersPosition, MetersPosition][] = [];
    let beginAngle = 0;
  
    for(let pass = 0; pass < 2; pass += 1) {
      for (let i = 0; i < endpoints.length; i += 1) {
        const endpoint = endpoints[i];
        const openSegment = openSegments.length ? openSegments[0] : undefined;
        
        if (endpoint.beginsSegment) {
          let index = 0
          let segment = index < openSegments.length ? openSegments[index] : undefined;
          while (segment && endpoint.segment.inFrontOf(segment, center)) {
            index += 1;
            segment = openSegments[index]
          }
  
          if (!segment) {
            openSegments.push(endpoint.segment);
          } else {
            openSegments.splice(index, 0, endpoint.segment);
          }
        } else {
          const index = openSegments.indexOf(endpoint.segment)
          if (index > -1) openSegments.splice(index, 1);
        }
        
        if (openSegment !== openSegments[0]) {
          if (openSegment && pass === 1) {
            const trianglePoints = getTrianglePoints(center, beginAngle, endpoint.angle, openSegment);
            if (
                !(
                    Number.isNaN(trianglePoints[0][0]) || Number.isNaN(trianglePoints[0][1]) ||
                    Number.isNaN(trianglePoints[1][0]) || Number.isNaN(trianglePoints[1][1])  
                )          
            ) {
                output.push(trianglePoints);
            }
          }
          beginAngle = endpoint.angle;
        }
      }
    }
    return output;
}

export interface ITriangleSector {
    start: {
        position: MetersPosition;
        bearing: number;
    }
    end: {
        position: MetersPosition;
        bearing: number;
    }
    minimum: {
        position: MetersPosition;
        bearing: number;
        distance: number;
    }
}

const len = (x: MetersPosition): number => {
    return Math.sqrt(x[0] * x[0] + x[1] * x[1]);
}
const closestPointFromLine = (A: MetersPosition, B: MetersPosition, P: MetersPosition): MetersPosition => {
    const dx = (B[0] - A[0]);
    const dy = (B[1] - A[1]); 

    let p: MetersPosition;
    if (dx === 0 && dy === 0) {
        // point is coincendent
        p = [ A[0], A[1] ];
    }
    else if (dx === 0) {
        // points lie on line x = c
        // thus tangent is along y axis
        p = [ A[0], 0 ];
    }
    else if (dy === 0) {
        // points lie on line y = c
        // thus tangent is along x axis
        p = [ 0, A[1] ];
    }
    else {
        const m = dy / dx;
        // const c = A[1] - m * A[0];
        const c = B[1] - m * B[0];
        const m1 = -1 / m;
    
        // let y = m1 x => y = mx + c
        const xp = -c / (m - m1);
        const yp = m1 * xp;
        p = [ xp, yp ] as MetersPosition;
    }
    const d1 = len(A);
    const d2 = len(B);
    const dp = len(p);
    if (dp <= d1 && dp <= d2) {
        const x1 = Math.min(A[0], B[0]);
        const y1 = Math.min(A[1], B[1]);
        const x2 = Math.max(A[0], B[0]);
        const y2 = Math.max(A[1], B[1]);
        if (x1 <= p[0] && p[0] <= x2 && y1 <= p[1] && p[1] <= y2) {
            return p;
        }
    }    
    if (d2 < d1) {
        return B;
    }
    else {
        return A;
    }

}

const calcBearing = (p: MetersPosition): number => {
    const angle = Math.atan2(p[0], p[1]);
    let bearing = angle * 180 / Math.PI;
    return (bearing + 360) % 360;
}


const convertTrianglePositionsToTriangleSectors = (trianglePositions: [ MetersPosition, MetersPosition ][]): ITriangleSector[] => {
    
    const output = trianglePositions
        .reverse()
        .map(([endPosition, startPosition]) => {
            const startBearing = calcBearing(startPosition);
            const endBearing = calcBearing(endPosition);
            const minimumPosition = closestPointFromLine(startPosition, endPosition, [ 0, 0 ]);
            const minPointBearing = calcBearing(minimumPosition);
            const minimumDistance = len(minimumPosition);
            return {
                start: {
                    position: startPosition,
                    bearing: startBearing
                },
                end: {
                    position: endPosition,
                    bearing: endBearing
                },
                minimum: {
                    position: minimumPosition,
                    bearing: minPointBearing,
                    distance: minimumDistance
                }
            }
        });
    return output
}

const positionToMetersPosition = (position: Position, mph: MetersProjectionHelper) => {
    const c = mph.wgs84ToMeters(position[0], position[1]);
    return c as MetersPosition;
}
const polygonToMetersPolygon = (polygon: Polygon, mph: MetersProjectionHelper): MetersPolygon => {
    return polygon.coordinates.map(ring => {
        const ringMeters: MetersLineString = ring.map(position => {
            const metersPosition: MetersPosition = positionToMetersPosition(position, mph);
            return metersPosition;
        });
        return ringMeters;
    })
}

interface IArgs {
    center: Point;
    lineSegments: [Position, Position][];
};

export interface IResult {
    sectors: ITriangleSector[],
    mph: MetersProjectionHelper,
    metersCenter: MetersPosition
}

interface IArgs2 {
    boundaryPolygon: Polygon;
    obstacles: Polygon[];
    minSystemRadiusFt?: number
};
const roundMetersPosition = (mp: MetersPosition): MetersPosition => {
    // return mp;
    const T = 10000;
    return [
        Math.round(mp[0] * T) / T,
        Math.round(mp[1] * T) / T,
    ]
}
export interface ILosV3Input {
    holedBoundary: Polygon;
    lineSegments: [ Position, Position ][];
}
export const generateLineOfSightV3Input = (args: IArgs2): ILosV3Input[] => {
    let u2: Feature<Polygon | MultiPolygon> | null = feature(args.boundaryPolygon);
    if (!u2) return [];
    for (const obs of args.obstacles) {
        u2 = customDifference(u2, obs);
        if (!u2) return [];
    }
    
    const boundaries: ILosV3Input[] = [];
    const newBoundaries = u2.geometry.type === 'MultiPolygon'
        ? u2.geometry.coordinates
        : [ u2.geometry.coordinates ];
    for (const b of newBoundaries) {
        // sometimes a ring is incorrectly only 2 points, we will skip these:
        if (!b.length || b[0].length <= 2) continue;
        if (args.minSystemRadiusFt) {
            // if we are passed a min system radius, we will filter out too smaller regions by the evelope polygon
            const [minX, minY, maxX, maxY] = bbox(polygon(b));
            const d = distance([minX, minY], [maxX, maxY], { units: 'feet' });
            if (d < args.minSystemRadiusFt) {
                // console.log("wfo.main...ignoring boundary")
                continue;
            }
        }
        // accumulate all lines, trucating each coordinate
        const lineSegments: [Position, Position][] = [];
        for (let o = 0; o < b.length; o++) {
            const obs = b[o];
            for (let i = 0; i < obs.length - 1; i++) {
                lineSegments.push([ obs[i], obs[i + 1] ]);
            }
        }
        boundaries.push({ 
            lineSegments,
            holedBoundary: polygon(b).geometry
        });
    }

    // console.log("FFFFFF", JSON.stringify(featureCollection(boundaries.map(b => feature(b.holedBoundary)))))

    return boundaries;

}
const convertWgs84LineStringsToMeters = (args: IArgs) => {
    let maxD = 0;
    args.lineSegments.forEach(([p1,p2]) => {
        maxD = Math.max(maxD, distance(args.center, p1, { units: 'meters' }));
        maxD = Math.max(maxD, distance(args.center, p2, { units: 'meters' }))
    });
    const mph = MetersProjectionHelper.fromWgs80(args.center.coordinates[0], args.center.coordinates[1], maxD);
    const metersCenter = positionToMetersPosition(args.center.coordinates, mph);
    const metersLineSegments: MetersLineString[] = args.lineSegments.map(ls => {
        const mp1 = positionToMetersPosition(ls[0], mph);
        const mp2 = positionToMetersPosition(ls[1], mph);
         return [
            roundMetersPosition(mp1), roundMetersPosition(mp2)
        ]
    });
    return {
        metersCenter,
        metersLineSegments, 
        mph
    }

}
export const generateLineOfSightTrianglesV3 = (args: IArgs, debug: boolean = false): IResult => {

    // const tpp = createTimerLog("cpo.postbuffer.pp.optimize.consider.optimize.los");

    const metersInput = convertWgs84LineStringsToMeters(args);

    // tpp.logDeltaMS("geom");
    const segments = generateSegments({
        lineSegments: metersInput.metersLineSegments,
        center: metersInput.metersCenter
    });
    
    // tpp.logDeltaMS("segments");
    const endpoints = extractEndpoints(segments);
    // tpp.logDeltaMS("endpoints");
    const trianglePositions = findTrianglePositionsByCenter(metersInput.metersCenter, segments, endpoints)
    // tpp.logDeltaMS("triangle positions");
    const triangleSectors = convertTrianglePositionsToTriangleSectors(trianglePositions);
    // tpp.logDeltaMS("triangle sectors");

    if (debug) {
        console.log("segmetns", segments)
        console.log("endpoints", endpoints)
        console.log("trianglePositions", trianglePositions)
        console.log("triangleSectors", triangleSectors)
    }
    return {
        sectors: triangleSectors,
        mph: metersInput.mph,
        metersCenter: metersInput.metersCenter
    };
}

export const v3TriangleSectorsToWgs80 = (v3: IResult) => {
    
    return v3.sectors.map(v3s => {

        const startPosition = v3.mph.metersToWgs84(v3s.start.position[0], v3s.start.position[1]);
        const endPosition = v3.mph.metersToWgs84(v3s.end.position[0], v3s.end.position[1]);
        return {
            start: {
                ...v3s.start,
                position: startPosition,
            },
            end: {
                ...v3s.end,
                position: endPosition
            },
            // minimum: {
            //     ...v3s.minimum,
            //     distance: convertLength(v3s.minimum.distance, 'meters', 'feet'),
            //     position: v3.mph.metersToWgs84(v3s.minimum.position[0], v3s.minimum.position[1])
            // },
            // line: lineString([ startPosition, endPosition ]).geometry
        }
    })
}