import { Snackbar } from "@mui/material";
import { Feature, MultiPolygon, Polygon, lineString, polygon, union } from "@turf/turf";
import IAction from "rdptypes/IAction";
import { ISACOptimizationReducedPartialPivotRDP3, ISacOptimizerResultModel, ISacResultGeometry } from "rdptypes/project/ISacOptimizerResultModel";
import * as React from "react";
import { PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { createNewMultiAction } from "../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import AuthCtx from "../../auth/AuthCtx";
import IAuthState from "../../auth/IAuthState";
import DbCtx from "../../db/DbCtx";
import { staticDevSettingsDbProvider } from "../../db/DevSettingsDbProvider";
import IDbProject from "../../db/IDbProject";
import IDbState from "../../db/IDbState";
import IProject from "../../model/project/IProject";
import { IResult } from "../../optimization/centerPivot/sac/SACOptimizer";
import { ISACOptimizationResultGeometryRDP3 } from "../../optimization/centerPivot/sac/SACOptimizer/SAC/ISACOptimizationSolutionRDP3";
import { IArgs as IProjectionArgs, MetersProjectionHelper } from "../../optimization/centerPivot/sac/projection";
import { IProjectsMap, findModifiedSystems } from "./utils/findModifiedSystems";
import { ISacWorkerInputMessage_Run, ISacWorkerOutputMessage } from "./worker";

const handleCanReduce = (args: {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    authState: IAuthState;
    reduce: ISACOptimizationReducedPartialPivotRDP3
}) => {
    const { reduce, dbPrj, layoutId, systemId, authState } = args;
    const system = dbPrj.state.layouts[layoutId].systems[systemId];

    const clockwiseCompassHeadingStart =
        (Math.ceil((reduce.newPartialPivotAngleDegreesMin + 360) % 360) * 10) / 10;
    const clockwiseCompassHeadingEnd =
        (Math.floor((reduce.newPartialPivotAngleDegreesMax + 360) % 360) * 10) / 10;
    const confirmAction = confirm(
        "A SAC solution could not be found, but a solution may be obtained by reducing the pivot angles.\n\n" +
            `- clockwiseCompassHeadingStart from ${system.Circle!.CenterPivot!.clockwiseCompassHeadingStart?.toFixed(
                1
            )}° to ${clockwiseCompassHeadingStart.toFixed(1)}°\n` +
            `- clockwiseCompassHeadingEnd from ${system.Circle!.CenterPivot!.clockwiseCompassHeadingEnd?.toFixed(
                1
            )}° to ${clockwiseCompassHeadingEnd.toFixed(1)}°\n\n` +
            "Would you like to reduce the pivot angles?"
    );
    if (confirmAction) {
        const actions: IAction[] = [
            createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "Circle.CenterPivot.isPartialPivot",
                true,
                authState
            ),
            createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "Circle.CenterPivot.clockwiseCompassHeadingStart",
                clockwiseCompassHeadingStart,
                authState
            ),
            createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "Circle.CenterPivot.clockwiseCompassHeadingEnd",
                clockwiseCompassHeadingEnd,
                authState
            )
        ];
        dbPrj.pushAction(
            createNewMultiAction(
                actions,
                authState
            )
        );
    }
}

const generateSacGeometry = (result: {
    success: true;
    geometry: ISACOptimizationResultGeometryRDP3;
    projection: IProjectionArgs;
}): ISacResultGeometry => {
    const sacAreaPolygons: ISacResultGeometry = {
        irrigatedArea: [],
        endGunAreas: [],
        wheelTracksGuidance: [],
        wheelTracksNonGuidance: [],
        endBoomTrack: [],
    }
    const metersProjectionHelper = new MetersProjectionHelper(result.projection);

    const irrigatedAreas = result.geometry.coverageShapeVerticies.map(poly => {
        return polygon(
            poly.map(ring => {
                return ring.map(v => metersProjectionHelper.metersToWgs84(v[0], v[1]))
            })
        ).geometry;
    });
    sacAreaPolygons.irrigatedArea.push(...irrigatedAreas);
    
    const wheelTracksGuidance = result.geometry.guidanceWheelTrack.map(line => {
        return lineString(
            line.map(v => metersProjectionHelper.metersToWgs84(v[0], v[1]))
        ).geometry;
    });
    const wheelTracksNonGuidance = result.geometry.nonGuidanceWheelTrack.map(line => {
        return lineString(
            line.map(v => metersProjectionHelper.metersToWgs84(v[0], v[1]))
        ).geometry;
    });
    sacAreaPolygons.wheelTracksGuidance.push(...wheelTracksGuidance);
    sacAreaPolygons.wheelTracksNonGuidance.push(...wheelTracksNonGuidance);
    
    const endBoomTrack = result.geometry.endBoomTrack.map(line => {
        return lineString(
            line.map(v => metersProjectionHelper.metersToWgs84(v[0], v[1]))
        ).geometry;
    });
    sacAreaPolygons.endBoomTrack.push(...endBoomTrack);

    const endGunAreas = result.geometry.endGunVerticies.map(endGun => {
        const polys = endGun.map(poly => {
            return polygon(
                poly.map(ring => {
                    return ring.map(v => metersProjectionHelper.metersToWgs84(v[0], v[1]))
                })
            );
        })
        if (polys.length === 0) return [];
        let combined: Feature<Polygon | MultiPolygon> = polys[0];
        for (const p of polys.slice(1)) {
            combined = union(combined, p);
        }
        if (combined.geometry.type === 'Polygon') {
            return [ combined.geometry ];
        }
        else {
            const separated = combined.geometry.coordinates.map(p => polygon(p).geometry);
            return separated;
        }
    });
    sacAreaPolygons.endGunAreas.push(...endGunAreas);


    return sacAreaPolygons;
}

const handleSacWorkerResult = (args: {
    result: IResult;
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    authState: IAuthState;
}) => {
    const { result, dbPrj, layoutId, systemId, authState } = args;
    let sacOptimizerResult: ISacOptimizerResultModel;
    if (result.success === true) {
        const geometry = generateSacGeometry(result);
        sacOptimizerResult = {
            success: true,
            geometry,
        }
    }
    else {
        sacOptimizerResult = {
            ...result
        };
    }

    dbPrj.pushAction({
        ...createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "sacOptimizerResult",
            sacOptimizerResult,
            authState
        ),
        undoMode: "include", // Include this in undo
    });
};
const handleClearSystem = (args: {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    authState: IAuthState;
}) => {
    const { dbPrj, layoutId, systemId, authState } = args;
    dbPrj.pushAction({
        ...createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "sacOptimizerResult",
            undefined,
            authState
        ),
        undoMode: "include", // Include this in undo
    });
};

interface ISacWorkerItem {
    projectId: string;
    layoutId: string;
    systemId: string;
    worker: Worker;
}


const projectMapFromDbState = (dbState: IDbState, projectId: string, layoutId: string): IProjectsMap => {
    const m: IProjectsMap = {};
        Object.entries(dbState.projects).filter(([pid]) => pid === projectId).forEach(([k,v]) => {
            const state: IProject = structuredClone({
                ...v.state,
                layouts: {}
            });
            for (const [ lid, layout ] of Object.entries(v.state.layouts)) {
                if (layoutId === lid) {
                    state.layouts[lid] = structuredClone(layout);
                }
            }
            m[k] = {
                state,
                pushAction: async () => state,
                readonly: false,
            }
        })
        return m;
}

export interface Props {
    projectId: string;
    layoutId: string;
}

const SacWorkerContext: React.FC<PropsWithChildren<Props>> = ({ 
    children, projectId, layoutId
}) => {
    
    const authState = useContext(AuthCtx);
    const dbState = useContext(DbCtx);

    const [previousProjects, setPreviousProjects] = useState<IProjectsMap>(projectMapFromDbState(dbState, projectId, layoutId));
    const sacWorkers = useRef<ISacWorkerItem[]>([]);
    const [snackWorkers, setSnackWorkers] = useState(0);
    
    const terminateSacWorker = (args: { 
        layoutId: string; systemId: string; projectId: string;
    }) => {
        const { layoutId, systemId, projectId } = args;
        const workerItemIdx = sacWorkers.current.findIndex(i => (
            i.layoutId === layoutId &&
            i.projectId === projectId &&
            i.systemId === systemId
        ));
        if (workerItemIdx !== -1) {
            sacWorkers.current[workerItemIdx].worker.terminate();
            sacWorkers.current.splice(workerItemIdx, 1);
            setSnackWorkers(prev => prev - 1);
        }
    }

    useEffect(() => {
        const needsUpdate = findModifiedSystems(previousProjects, dbState.projects, projectId, layoutId);
        // console.log("SacWorkerContext: needsUpdate", needsUpdate);
        for (const update of needsUpdate) {
            const { layoutId, systemId, projectId } = update;
            const dbPrj = dbState.projects[projectId];
            
            terminateSacWorker({ layoutId, systemId, projectId });

            if (update.shouldClear) {
                handleClearSystem({
                    dbPrj,
                    authState,
                    layoutId,
                    systemId
                })
            }
            else {
                setSnackWorkers(prev => prev + 1);
                const worker = new Worker(
                    new URL("./worker.ts", import.meta.url),
                    { type: "module" }
                )
                sacWorkers.current.push({
                    layoutId, systemId, projectId, worker
                })
                worker.onmessage = (
                    ev: MessageEvent<ISacWorkerOutputMessage>
                ) => {
                    if (ev.data.type === "result") {
                        const result = ev.data.payload.result;
                        terminateSacWorker({ layoutId, systemId, projectId });
                        handleSacWorkerResult({
                            authState,
                            systemId: systemId,
                            result,
                            layoutId,
                            dbPrj
                        })
                        if (result.success === false) {
                            if (result.canReduce) {
                                // turned off the ability to reduce - let the user do this with dragging instead
                                // handleCanReduce({
                                //     dbPrj, layoutId, systemId, authState, reduce: result.reduce
                                // })
                            }
                            else {
                                // silently fail
                            }
                        }
                    }
                    else {
                        console.log("Some other message", ev)
                    }
                };
                const optimizationArgs: ISacWorkerInputMessage_Run = {
                    project: dbPrj.state,
                    layoutId: layoutId!,
                    systemId: systemId!,
                    sacType: update.sacType, // TODO: Check why red
                    endGunThrowsMeters: update.endGunThrowsMeters, // TODO: Check why red
                    sacOptimizerSettings: staticDevSettingsDbProvider.sacOptimizer.get(),
                };
                console.log("posting run to sac worker", optimizationArgs)
                worker.postMessage(optimizationArgs);
            }
        }
        setPreviousProjects(projectMapFromDbState(dbState, projectId, layoutId));
    }, [dbState, projectId, layoutId]);


    return (
        <>
            {children}
            <Snackbar
                open={snackWorkers !== 0}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{
                    marginTop: 5
                }}
                message={`There are currently ${snackWorkers} SAC optimizers in progress`}
            />
        </>
    );
}

export default SacWorkerContext;