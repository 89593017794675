import { IPricing } from "rdptypes/project/ISystemBase.AutoGenerated";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import IAuthState from "../../auth/IAuthState";
import IDbProject from "../../db/IDbProject";
import { IPricingHelperTotals } from "./totalsPricing";

interface IPriceAndPercentage {
    minorUnits: number;
    percent?: number;
}
type IPriceAndPercentageSettable = IPriceAndPercentage & {
    setMinorUnits: (value: number, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => void;
    setPercent: (value: number, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => void;
}
export interface IPricingHelperDownPayment {
    method: "Percent" | "Price";
    setMethod: (value: "Percent" | "Price", dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => void;
    currency: string;
    downPayment: IPriceAndPercentageSettable;
    dueOnDelivery: IPriceAndPercentageSettable;
    dueOnInstallation: IPriceAndPercentage;
}

export const calculateDownPayment = (
    pricing: IPricing, currency: string, totals: IPricingHelperTotals
): IPricingHelperDownPayment => {

    const method = pricing.paymentComputationMethod ?? "Price";
    const subTotalMinusTaxLocalCurrency = totals.subTotalMinusTaxMinorUnits(currency, {
        system: true,
        installation: true,
        freight: true,
        pivotPad: true,
        miscItems: true,
        tradeIn: true
    });
    const taxLocalCurrency = totals.taxMinorUnits(currency, {
        system: true,
        installation: true,
        freight: true,
        pivotPad: true,
        miscItems: true,
        tradeIn: true
    });
    const totalLocalCurrency = subTotalMinusTaxLocalCurrency + taxLocalCurrency;
    
    let downPayment: IPriceAndPercentageSettable = { 
        minorUnits: 0,
        setMinorUnits(value, dbPrj, layoutId, systemId, authState) {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "QuoteProperties.ProposalInformation.pricing.downPayment.usdCents",
                value,
                authState));
        },
        setPercent(value, dbPrj, layoutId, systemId, authState) {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "QuoteProperties.ProposalInformation.pricing.downPayment.percent",
                value,
                authState));
        }
    };
    let dueOnDelivery: IPriceAndPercentageSettable = { 
        minorUnits: 0,
        setMinorUnits(value, dbPrj, layoutId, systemId, authState) {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "QuoteProperties.ProposalInformation.pricing.dueOnDelivery.usdCents",
                value,
                authState));
        },
        setPercent(value, dbPrj, layoutId, systemId, authState) {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "QuoteProperties.ProposalInformation.pricing.dueOnDelivery.percent",
                value,
                authState));
        }
    };
    let dueOnInstallation: IPriceAndPercentage = { 
        minorUnits: 0
    };
    if (method === "Percent")
    {
        downPayment.percent = pricing?.downPayment?.percent ?? 0;
        dueOnDelivery.percent = pricing?.dueOnDelivery?.percent ?? 0;
        downPayment.minorUnits = downPayment.percent / 100 * totalLocalCurrency;
        dueOnDelivery.minorUnits = dueOnDelivery.percent / 100 * totalLocalCurrency;
        dueOnInstallation.minorUnits = totalLocalCurrency - downPayment.minorUnits - dueOnDelivery.minorUnits;
        dueOnInstallation.percent = dueOnInstallation.minorUnits / totalLocalCurrency * 100;
    }
    else if (method === "Price"){
        downPayment.minorUnits = pricing?.downPayment?.usdCents ?? 0;
        dueOnDelivery.minorUnits = pricing?.dueOnDelivery?.usdCents ?? 0;
        dueOnInstallation.minorUnits = totalLocalCurrency - downPayment.minorUnits - dueOnDelivery.minorUnits;
    }

    const result: IPricingHelperDownPayment = {
        method: pricing.paymentComputationMethod ?? "Price",
        setMethod(value, dbPrj, layoutId, systemId, authState) {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "QuoteProperties.ProposalInformation.pricing.paymentComputationMethod",
                value,
                authState));
        },
        currency,
        downPayment,
        dueOnDelivery,
        dueOnInstallation
    }

    return result;
}
