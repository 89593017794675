import { sum } from "lodash";
import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import IAuthState from "../../auth/IAuthState";
import IDbProject from "../../db/IDbProject";

export interface IPricingHelperMiscItem {
    id: string;
    description: string;
    priceMinorUnits: number;
    taxable: boolean;
}

export interface IPricingHelperMiscItems {
    saveItem: (item: IPricingHelperMiscItem, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => void;
    deleteItem: (id: string, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => void;
    currency: string;
    items: IPricingHelperMiscItem[];
    preTaxTotalCostMinorUnits: number;
    totalTaxMinorUnits: number;
    anyTaxable: boolean;
}

export const calcualteMiscItems = (system: ISystemBase, currency: string, salesTaxMultiplier: number): IPricingHelperMiscItems => {
    const sysMiscItems = system.QuoteProperties?.MiscItems || [];
    const phMiscItems: IPricingHelperMiscItem[] = sysMiscItems.map((x) => {
        return {
            id: x.id,
            description: x.description,
            priceMinorUnits: x.priceCents ?? 0,
            taxable: x.taxable
        }
    });
    const miscItems = {
        saveItem: (item: IPricingHelperMiscItem, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => {
            let foundItem = sysMiscItems.find(x => x.id === item.id);
            if (!foundItem) {
                foundItem = {
                    id: item.id,
                    description: item.description,
                    taxable: item.taxable,
                    priceCents: 0
                };
                sysMiscItems.push(foundItem);
            }
            foundItem.description = item.description;
            foundItem.taxable = item.taxable;
            foundItem.priceCents = item.priceMinorUnits;
    
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "QuoteProperties.MiscItems",
                sysMiscItems,
                authState));
        },
        deleteItem: (id: string, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "QuoteProperties.MiscItems",
                sysMiscItems.filter(x => x.id !== id),
                authState));
        },
        items: phMiscItems,
        currency,
        preTaxTotalCostMinorUnits: sum(phMiscItems.map(x => x.priceMinorUnits)),
        totalTaxMinorUnits: sum(phMiscItems.filter(x => x.taxable).map(x => x.priceMinorUnits * salesTaxMultiplier)),
        anyTaxable: phMiscItems.filter(x => x.taxable).length > 0
    }
    return miscItems;
}