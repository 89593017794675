import { IPrice } from "rdptypes/project/ISystemBase.AutoGenerated";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import IAuthState from "../../auth/IAuthState";
import IDbProject from "../../db/IDbProject";

export interface IPricingHelperIPrice {
    priceMinorUnits: number;
    taxMinorUnits: number;
    isTaxable: boolean;
    currency: string;
    savePrice: (value: number, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => void;
    saveIsTaxable: (value: boolean, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => void;
}

export const calculateIPrice = (
    iPrice: IPrice, currency: string, salesTaxMultiplier: number, sysPath: string
): IPricingHelperIPrice => {
    const priceMinorUnits = iPrice.priceUsdCents ?? 0;

    return {
        priceMinorUnits,
        taxMinorUnits: iPrice.isTaxable ? priceMinorUnits * salesTaxMultiplier : 0,
        currency,
        isTaxable: iPrice.isTaxable,
        savePrice: (value: number, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                `${sysPath}.priceUsdCents`,
                value,
                authState));
        },
        saveIsTaxable: (value: boolean, dbPrj: IDbProject, layoutId:string, systemId: string, authState: IAuthState) => {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                `${sysPath}.isTaxable`,
                value,
                authState));
        }
    }
}
