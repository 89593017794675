import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { BoomBackDevices, BoomBackTypes, PipeMaterialTypes } from "rdptypes/project/ISprinklers";
import ISystem from "rdptypes/project/ISystem";
import { AnyPolyLineSpans } from "roedata/roe_migration/OtherHelpers";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasPowerTowerEndBoom, IsDualSided } from "roedata/roe_migration/SystemFunctions";
import { BoomBackDeviceOptionOk, BoomBackMaterialOptionOk, BoomBackOptionOk } from "roedata/roe_migration/Valids.Valids";
import { resetChart } from "./helpers";
import { IFnExecutePropertyValueMap, IPropertyValueMap, ISprinklerBoomBackValidator, ISprinklerValidatorChoiceFieldWithSet, ISprinklerValidatorFieldWithSet } from "./interfaces";

const getQcSide = (qc: QuoteClass, side: SideEnum) => {
    if (side === SideEnum.Flanged) {
        return {
            spansClass: qc.RightSpansClass,
            towersClass: qc.RightTowersClass,
        }
    }
    else {
        return {
            spansClass: qc.LeftSpansClass,
            towersClass: qc.LeftTowersClass,
        }
    }
}

const setBoomBackProperty = (sys: ISystem, side: SideEnum, property: string, value: any, propertyMap: IPropertyValueMap): IPropertyValueMap => {
    const base = side === SideEnum.Flanged
        ? "FlangedSide.Sprinklers.BoomBacks."
        : "FlexSide.Sprinklers.BoomBacks.";
    const sysSide = getSide(sys, side);
    sysSide.Sprinklers.BoomBacks[property] = value;
    let newPropertyMap = {
        ...propertyMap
    }
    newPropertyMap[base + property] = value;

    newPropertyMap = resetChart(sys, newPropertyMap);
    
    if (property !== "PipeMaterial") {
        const boomBackMaterial = pipeMaterialValidator(sys, side, null);
        console.log("sc", boomBackMaterial)
        if (boomBackMaterial.isError) {
            if (boomBackMaterial.allowableValues.length === 1) {
                newPropertyMap = setBoomBackProperty(sys, side, "PipeMaterial", boomBackMaterial.allowableValues[0], newPropertyMap);
            }
            else {
                newPropertyMap = setBoomBackProperty(sys, side, "PipeMaterial", undefined, newPropertyMap);
            }
        }
    }

    return newPropertyMap;
}

const boomBackTypeValidator = (sys: ISystem, side: SideEnum, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorChoiceFieldWithSet<BoomBackTypes> => {
    const sysSide = getSide(sys, side);
    const value = sysSide.Sprinklers.BoomBacks.BoomBackType;
    const { towersClass } = getQcSide(new QuoteClass(sys), side);

    const getAllowableValues = () => {                                 
        return (Object.keys(BoomBackTypes) as BoomBackTypes[]).filter(x => (
            BoomBackOptionOk(
                x, { 
                    AnyPoly: AnyPolyLineSpans(sys),
                    AnyLowProfiles: towersClass.AnyLowProfiles()
                }
            )
        ));
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();

    const set = (v: BoomBackTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setBoomBackProperty(clonedSys, side, "BoomBackType", v, pvm);
            switch (v) {
                case BoomBackTypes.None:
                    break;
                case BoomBackTypes.Standard:
                    pvm = setBoomBackProperty(clonedSys, side, "QtyPerTower", undefined, pvm);
                    break;
                case BoomBackTypes.Tower:
                    pvm = setBoomBackProperty(clonedSys, side, "QtyPerTower", 1, pvm);
                    break;
            }
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}

const boomBackDeviceValidator = (sys: ISystem, side: SideEnum, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorChoiceFieldWithSet<BoomBackDevices> => {
    const sysSide = getSide(sys, side);
    const value = sysSide.Sprinklers.BoomBacks.Device;

    const getAllowableValues = () => {                                 
        return (Object.keys(BoomBackDevices) as BoomBackDevices[]).filter(x => (
            BoomBackDeviceOptionOk(x, {
                BoomBackType: sysSide.Sprinklers.BoomBacks.BoomBackType,
                SprinklerManufacturerType: sys.SprinklerProperties.SprinklerEngineCode,
            })
        ));
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (sysSide.Sprinklers.BoomBacks.BoomBackType === BoomBackTypes.None) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();

    const set = (v: BoomBackDevices) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setBoomBackProperty(clonedSys, side, "Device", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}

const pipeMaterialValidator = (sys: ISystem, side: SideEnum, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorChoiceFieldWithSet<PipeMaterialTypes> => {
    const sysSide = getSide(sys, side);
    const value = sysSide.Sprinklers.BoomBacks.PipeMaterial;
    const { spansClass } = getQcSide(new QuoteClass(sys), side);

    const getAllowableValues = () => {                                 
        return (Object.keys(PipeMaterialTypes) as PipeMaterialTypes[]).filter(x => (
            BoomBackMaterialOptionOk(
                x, {
                    Country: sys.DealerProperties.Country,
                    AnyAlum: spansClass.AnyAluminum(),
                    BoomBackType: sysSide.Sprinklers.BoomBacks.BoomBackType,
                    HasValvesAndControls: sysSide.Sprinklers.BoomBacks.Valves,
                }
            )
        ));
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (sysSide.Sprinklers.BoomBacks.BoomBackType === BoomBackTypes.None) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();

    const set = (v: PipeMaterialTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setBoomBackProperty(clonedSys, side, "PipeMaterial", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}

const firstTowerValidator = (sys: ISystem, side: SideEnum, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorChoiceFieldWithSet<number> => {
    const sysSide = getSide(sys, side);
    const value = sysSide.Sprinklers.BoomBacks.FirstTower;

    const getAllowableValues = () => {                                 
        return Array.from({ length: sysSide.Tower.length }, (v, k) => k + 1);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (sysSide.Sprinklers.BoomBacks.BoomBackType === BoomBackTypes.None) return false;
        if (!isFinite(value) || value === null) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();

    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setBoomBackProperty(clonedSys, side, "FirstTower", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}

const qtyPerTowerValidator = (sys: ISystem, side: SideEnum, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorChoiceFieldWithSet<number> => {
    const sysSide = getSide(sys, side);
    const value = sysSide.Sprinklers.BoomBacks.QtyPerTower;

    const getAllowableValues = () => {                                 
        return sysSide.Sprinklers.BoomBacks.BoomBackType === BoomBackTypes.Standard ? [2, 4, 6] : [1];
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (sysSide.Sprinklers.BoomBacks.BoomBackType === BoomBackTypes.None) return false;
        if (!isFinite(value) || value === null) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();

    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setBoomBackProperty(clonedSys, side, "QtyPerTower", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}

const valvesValidator = (sys: ISystem, side: SideEnum, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorFieldWithSet<boolean> => {
    const sysSide = getSide(sys, side);
    const value = sysSide.Sprinklers.BoomBacks.Valves;

    const getError = () => {
        return false;
    }

    const isError = getError();

    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setBoomBackProperty(clonedSys, side, "Valves", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

export const boomBackValidator = (sys: ISystem, side: SideEnum, executePvm: IFnExecutePropertyValueMap): ISprinklerBoomBackValidator => {
    
    const validators = {
        boomBackType: boomBackTypeValidator(sys, side, executePvm),
        boomBackDevice: boomBackDeviceValidator(sys, side, executePvm),
        pipeMaterial: pipeMaterialValidator(sys, side, executePvm),
        firstTower: firstTowerValidator(sys, side, executePvm),
        qtyPerTower: qtyPerTowerValidator(sys, side, executePvm),
        valves: valvesValidator(sys, side, executePvm)
    };
    
    return {
        ...validators,
        isError: (side === SideEnum.Flanged || (IsDualSided(sys) && !HasPowerTowerEndBoom(sys)))
            ? Object.values(validators).some(x => x.isError)
            : false
    }
}