/* 
 * NOTE:
 * 
 * This .part.ts file contains functions partly extracted from
 *   roedata/roe_migration/SystemFunctions.ts
 *
 */

import { HoseFeedTypes, ISystemBase, SystemTypes } from "../project/ISystemBase.AutoGenerated";

export const IsPivotingLateral = (sys: ISystemBase): boolean => sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && sys.Lateral?.HoseFeed?.HoseFeedType === HoseFeedTypes.PivotingLateral;