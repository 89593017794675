import { Point, Polygon, bearing, booleanContains, booleanEqual, destination, lineIntersect, point, polygon } from "@turf/turf";
import FeatureHelpers from "rdptypes/geometry/helpers/features";
import { IEndgunOnOffs } from "rdptypes/project/IEndgunInformation";
import { ILosV3Input, generateLineOfSightTrianglesV3, generateLineOfSightV3Input, v3TriangleSectorsToWgs80 } from "../lineOfSightTriangles.v3";

interface IArgs {
    boundary: Polygon;
    obstacles: Polygon[];
    system: {
        center: Point;
        radiusFeet: number;
        minBearing: number;
        maxBearing: number;
    };
    throwDistanceFeet: number;
}


const calculate = (args: IArgs): IEndgunOnOffs[] => {

    // the inbetween points of the arc are > r. Modify r so that they
    // are r at the extreme points
    let r = args.system.radiusFeet + args.throwDistanceFeet;
    r = 2 * r - r * Math.cos(0.5 * 0.1 * Math.PI / 180);
    const throwLimitArc = FeatureHelpers.GetLineArcDrawFeature(
        args.system.center, 
        r, 
        args.system.minBearing, 
        args.system.maxBearing, 
        null,
        { units: 'feet', degreeIncrement: 0.1 }
    );

    // const losTriangles = generateLineOfSightTrianglesV2({
    //     center: args.system.center,
    //     obstacles: args.obstacles,
    //     boundaryPolygon: args.boundary
    // });
    const losV3Input = generateLineOfSightV3Input({
        obstacles: args.obstacles,
        boundaryPolygon: args.boundary
    })
    const losV3InputByCenter: ILosV3Input[] = losV3Input.filter(input => {
        return booleanContains(input.holedBoundary, args.system.center);
    })
    if (losV3InputByCenter.length === 0) return [];
    if (losV3InputByCenter.length !== 1) {
        console.warn("generateLineOfSightV3Input generated multiple boundaries which includes this center", losV3InputByCenter, args.system.center);
        return [];
    }
    const losTrianglesV3 = generateLineOfSightTrianglesV3({
        center: args.system.center,
        lineSegments: losV3InputByCenter[0].lineSegments
    });
    const losTriangles = v3TriangleSectorsToWgs80(losTrianglesV3);

    if (losTriangles.length === 0) {
        // then we cant form a singular line of sight polygon,
        // and so we cannot calculate end gun on/offs. 
        return [];
    }


    // we want to create a single los polygon, this looks messy but it is done this way as
    // the los boundary triangle sometimes create start/end points that are very very close, but not identical
    // just add all the points to be safe
    const losBoundaryPoints: Point[] = [
        point(losTriangles[0].start.position).geometry
    ];
    for (const l of losTriangles) {
        const startPoint = point(l.start.position).geometry;
        const endPoint = point(l.end.position).geometry;
        if (!booleanEqual(losBoundaryPoints[losBoundaryPoints.length - 1], startPoint)) {
            losBoundaryPoints.push(startPoint)
        }
        if (!booleanEqual(losBoundaryPoints[losBoundaryPoints.length - 1], endPoint)) {
            losBoundaryPoints.push(endPoint)
        }
    }
    if (
        losBoundaryPoints[losBoundaryPoints.length - 1].coordinates[0] !== losBoundaryPoints[0].coordinates[0] &&
        losBoundaryPoints[losBoundaryPoints.length - 1].coordinates[1] !== losBoundaryPoints[0].coordinates[1]
    ) {
        losBoundaryPoints.push(losBoundaryPoints[0]);
    }

    if (losBoundaryPoints.length < 4) {
        // invalid polygon 
        return [];
    }
    const losPolygon = polygon([[
        ...losBoundaryPoints.map(x => x.coordinates)
    ]])

    // find where the throw limit arc intersects with the los boundary
    const throwLimitIntersections = lineIntersect(losPolygon, throwLimitArc);

    // now find the bearings of these intersections and sort
    const intersectionBearings = throwLimitIntersections.features.map(x => bearing(args.system.center, x));
    
    // normalize the bearings from minBearing
    for (let i = 0; i < intersectionBearings.length; i++) {
        intersectionBearings[i] -= args.system.minBearing;
        intersectionBearings[i] += 2 * 360
        intersectionBearings[i] %= 360
    }

    if (args.system.minBearing === 0 && (args.system.maxBearing === 360 || args.system.maxBearing === 0)) {
        // full pivot
        if (!intersectionBearings.includes(0)) {
            intersectionBearings.push(0)
        }
        if (!intersectionBearings.includes(360)) {
            intersectionBearings.push(360)
        }
    }
    else {
        // partial pivot
        // we need to add the start and end bearings to the intersection points
        const min = 0;
        let max = args.system.maxBearing - args.system.minBearing;
        max += 2 * 360
        max %= 360
        if (!intersectionBearings.includes(min)) {
            intersectionBearings.push(min)
        }
        if (!intersectionBearings.includes(max)) {
            intersectionBearings.push(max)
        }
    }

    // now sort these bearings ascending clockwise
    intersectionBearings.sort((a, b) => {
        return a- b;
    })
    

    const result: IEndgunOnOffs[] = []
    let crntStartBearing: number | undefined = undefined;
    let crntEndBearing: number | undefined = undefined;
    for (let i = 0; i < intersectionBearings.length - 1; i++) {
        const crntIntersectionBearing = intersectionBearings[i] + args.system.minBearing;
        const nextIntersectionBearing = intersectionBearings[i + 1] + args.system.minBearing;
        let midpointBearing: number;
        if (nextIntersectionBearing > crntIntersectionBearing) {
            midpointBearing = 0.5 * (crntIntersectionBearing + nextIntersectionBearing);
        }
        else {
            midpointBearing = 0.5 * (crntIntersectionBearing + nextIntersectionBearing) + 180;
        }
        const midpointInsideLos = booleanContains(
            losPolygon, 
            destination(
                args.system.center, 
                args.system.radiusFeet + args.throwDistanceFeet, 
                midpointBearing, 
                { units: 'feet'}
            )
        );

        if (midpointInsideLos) {
            if (!crntStartBearing) {
                crntStartBearing = crntIntersectionBearing;
            }
            crntEndBearing = nextIntersectionBearing;
        }
        else {
            if (crntStartBearing !== undefined && crntEndBearing !== undefined) {
                result.push({
                    startBearing: crntStartBearing,
                    endBearing: crntEndBearing
                })
            }
            crntStartBearing = undefined;
            crntEndBearing = undefined;
        }
    }
    if (crntStartBearing !== undefined && crntEndBearing !== undefined) {
        result.push({
            startBearing: crntStartBearing,
            endBearing: crntEndBearing
        })
    }
    return result;
}

export default calculate;