import { Box } from "@mui/material";
import i18next, { t } from "i18next";
import { isCenterPivot } from "rdptypes/helpers/system";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { GeometryCtx } from "../../../GeometryHelpers/GeometryProvider";
import { centerPivotElevationProfiler } from "../../../components/SystemDiagramView/centerPivotElevationProfiler";
import { IElevationProfile } from "../../../components/SystemDiagramView/interfaces";
import { lateralElevationProfiler } from "../../../components/SystemDiagramView/lateralElevationProfiler";
import DbCtx from "../../../db/DbCtx";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../../helpers/lengths";
import { documentStyles } from "../../styles";

//this is effectively a copy of System Diagram but it renders an ElevationTable as opposed to the SystemDiagram
//TODO: pull out the elevation state code

interface IElevationState {
    profiles: IElevationProfile[];
    minimum: {
        elevationFeet: number;
    };
    maximum: {
        elevationFeet: number;
    };
    system: {
        totalRunningLengthFeet: number;
        feedLineLengthFeet?: number;
        elevationFeet: number;
    };
}

interface IProps {
    projectId: string;
    layoutId: string;
    systemId: string;
    fontSize?: string;
}

const ElevationTable: React.FC<IProps> = ({
    projectId,
    layoutId,
    systemId,
    fontSize
}) => {
    const [bearingOrOffset, setBearingOrOffset] = useState<number>(0);
    const [elevationState, setElevationState] = useState<
        IElevationState | undefined
    >(undefined);

    const geometryState = useContext(GeometryCtx);
    const dbState = useContext(DbCtx);
    const dbPrj = dbState.projects[projectId!];
    const project = dbPrj.state;
    const layout = project.layouts[layoutId!];
    const system = layout.systems[systemId!];

    useEffect(() => {
        if (!geometryState || !geometryState?.elevation || !system) {
            setElevationState(undefined);
            return;
        }

        if (isCenterPivot(system)) {
            const profilerResult = centerPivotElevationProfiler({
                system, project, elevation: geometryState?.elevation
            })
            if (profilerResult) {
                setElevationState({
                    system: {
                        totalRunningLengthFeet: profilerResult.systemLength,
                        elevationFeet: profilerResult.pivotCenter.elevationfeet
                    },
                    profiles: profilerResult.profiles,
                    minimum: profilerResult.minimumElevation,
                    maximum: profilerResult.maximumElevation,
                })
                setBearingOrOffset(profilerResult.normalizedStartBearing);
            }
            else {
                setElevationState(undefined);
            }
        }
        else {
            const profilerResult = lateralElevationProfiler({
                systemId, layoutId, project, elevation: geometryState?.elevation
            })
            if (profilerResult) {
                setElevationState({
                    system: {
                        totalRunningLengthFeet: profilerResult.systemLength,
                        feedLineLengthFeet: profilerResult.normalizedEndOffsetFeet,
                        elevationFeet: profilerResult.lateralStart.elevationFeet
                    },
                    profiles: profilerResult.profiles,
                    minimum: profilerResult.minimumElevation,
                    maximum: profilerResult.maximumElevation
                })
                setBearingOrOffset(profilerResult.normalizedStartOffsetFeet);
            }
            else {
                setElevationState(undefined);
            }
        }
    }, [system, geometryState, geometryState?.elevation, dbState]);

    let elevationProfile: IElevationProfile | undefined = undefined;
    if (elevationState) {
        if (system.SystemProperties.SystemType === SystemTypes.CenterPivot && elevationState.profiles.length === 360) {
            elevationProfile = elevationState.profiles[
                (bearingOrOffset + 360) % 360
            ]
        }
        else if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator
            || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
            elevationProfile = elevationState.profiles[bearingOrOffset]
        }
    }

    if (!system) return null;

    let colWidth = 100/2;
    let oneColWidth = colWidth + "%";
    let twoColWidth = colWidth * 2 + "%";
    let cellStyle = {...documentStyles.cell, justifyContent: "left", padding: 2, width: oneColWidth, fontSize};

    const settings = React.useContext(DevSettingsCtx);

    let highestPoint = elevationProfile !== undefined ? new DisplayLengthUnitBuilder(elevationState.maximum.elevationFeet - elevationState.system.elevationFeet, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendShortName()
        .toString() : i18next.format(t("calculating"), 'capitalize') + "...";

    let lowestPoint = elevationProfile !== undefined ? new DisplayLengthUnitBuilder(elevationState.minimum.elevationFeet - elevationState.system.elevationFeet, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendShortName()
        .toString() : i18next.format(t("calculating"), 'capitalize') + "...";

    return <Box style={{border: "1px solid black", marginBottom: 20}}>
    <Box style={documentStyles.heading}>
        <Box style={{...documentStyles.sectionHeader, fontSize: 14}}>{isCenterPivot(system) ? t("reports.elevation.relative-elevation-from-center-point") : i18next.format(t("elevation"), 'capitalize')}</Box>
    </Box>
    <Box style={{paddingLeft: 15, paddingRight: 15}}>
        <Box style={{...documentStyles.table, marginBottom: 0}}>
            <div style={documentStyles.row}>
                <div style={{ ...cellStyle }}>
                    <strong style={{fontFamily: "Arial Black"}}>{i18next.format(t("highest"), 'capitalize')}</strong>
                </div>
                <div style={{ ...cellStyle }}>
                    {highestPoint}
                </div>
            </div>
            <div style={documentStyles.row}>
                <div style={{ ...cellStyle }}>
                    <strong style={{fontFamily: "Arial Black"}}>{i18next.format(t("lowest"), 'capitalize')}</strong>
                </div>
                <div style={{ ...cellStyle }}>
                    {lowestPoint}
                </div>
            </div>
        </Box>
        </Box>
    </Box>
};

export default ElevationTable;
