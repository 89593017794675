import IPartsPackage from "rdptypes/roe/IPartsPackage";
import IRoeData from "rdptypes/roe/IRoeData";

import page_ancillaryEquipment from "./pages/ancillaryEquipment/page";
import page_beginningOfSystem from "./pages/beginningOfSystem/page";
import page_dualSprinklerPackage from "./pages/dualSprinklerPackage/page";
import page_endOfSystem from "./pages/endOfSystem/page";
import page_endOfSystemFlex from "./pages/endOfSystemFlex/page";
import page_kwikTow from "./pages/kwikTow/page";
import page_lateral from "./pages/lateral/page";
import page_lateralGuidance from "./pages/lateralGuidance/page";
import page_mainControlPanel from "./pages/mainControlPanel/page";
import page_mcpVoltage from "./pages/mcpVoltage/page";
import page_miscItems from "./pages/miscItems/page";
import page_options from "./pages/options/page";
import page_productLine from "./pages/productLine/page";
import page_proposal from "./pages/proposal/page";
import page_proposalInformation from "./pages/proposalInformation/page";
import page_sac from "./pages/sac/page";
import page_sacRetro from "./pages/sacRetro/page";
import page_sendOrder from "./pages/sendOrder/page";
import page_spans from "./pages/spans/page";
import page_sprinklers from "./pages/sprinklers/page";
import page_towers from "./pages/towers/page";
import page_vri from "./pages/vri/page";

import jumboRule from "./jumboRule";
import partsCatalog from "./partsCatalog";
import partsListGenerator from "./partsListGenerator";
import poSchema from "./poSchema";

const modules = [
    page_ancillaryEquipment,
    page_beginningOfSystem,
    page_endOfSystem,
    page_endOfSystemFlex,
    page_kwikTow,
    page_lateral,
    page_lateralGuidance,
    page_mainControlPanel,
    page_mcpVoltage,
    page_miscItems,
    page_options,
    page_productLine,
    page_proposal,
    page_proposalInformation,
    page_sac,
    page_sacRetro,
    page_sendOrder,
    page_spans,
    page_dualSprinklerPackage,
    page_sprinklers,
    page_towers,
    page_vri,

    jumboRule,
    partsCatalog,
    partsListGenerator,
];

const mergeObject = (x: any, data: IRoeData) => {
    if (x.pages) {
        data.pages = [...data.pages, ...x.pages];
    }
    if (x.rules) {
        data.rules = [...data.rules, ...x.rules];
    }
    if (x.partsListGenerators) {
        data.partsListGenerators = [...data.partsListGenerators, ...x.partsListGenerators];
    }
};

const roeData: IRoeData = {
    pages: [],
    rules: [],
    partsCatalog,
    poSchema,
    partsListGenerators: []
};

for (const x of modules) {
    mergeObject(x, roeData);
}

roeData.pages.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));

export default {
    id: "2024060101",
    roeData
} as IPartsPackage;