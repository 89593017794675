import {
    CollectorRingTypes,
    ElectricalFrequencies,
    EndTowerPositioningTypes,
    GeneratorFrameTypes,
    GeneratorModels,
    GeneratorOptions,
    HoseFeedTypes,
    JackKitTypes,
    LightTypes,
    IOptions as Options,
    PanelModels,
    SpanTypes,
    SystemTypes,
    TowTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import { enableJackKit } from '../SystemHelpers';
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import * as ohf from "./OtherHelpers";
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as sysf from "./SystemFunctions";
import { strLanguageText } from "./TranslationsLib";
import { CollectorReelICCIOptionCountOk, ControlPanelOptionOK, FuelTankOptionOk, GeneratorModelOk, GeneratorOptionOK, GeneratorSlideRailsOptionOk } from "./Valids.Valids";
import { CollectorReelICIIOptionCountOkDto } from './Valids.dto.Valids';
export class OptionsClass extends CSection2 {
    private isPivotingLateral: boolean;

    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): boolean => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
        let ePanelModel: PanelModels;
        let bTowable: boolean;
        let bEDMP: boolean;
        let b4WPM: boolean;
        let IsA100: boolean;
        let bGenSwitch: boolean;
        let q = this.Quote;
        const systemType = q.System.SystemProperties.SystemType;
        let hoseFeedType: HoseFeedTypes;
        if (systemType === SystemTypes.HoseFeedMaxigator) {
            if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                this.isPivotingLateral = true;
            }
            hoseFeedType = this.Quote.System.Lateral.HoseFeed.HoseFeedType;
        }
        bTowable = q.IsTowable();
        bEDMP = sysf.IsEDMP(this.Quote.System);
        IsA100 = q.IsA100_CF200();
        if (q.ControlPanelClass.FieldSet.DataValid()) {
            let cp = q.System.ControlPanel;
            ePanelModel = cp.PanelModel;
            bGenSwitch = cp.GenStartControl;
        }
        let o: Options = this.Quote.System.Options;
        switch (systemType) {
            case SystemTypes.CenterPivot:
                if (bTowable) {
                    if (this.Quote.System.Circle.CenterPivot.TowOptions.TowType === TowTypes.FourWheelPivotMover) {
                        b4WPM = true;
                    }
                }
                break;
        }
        if (o.FlushValve) {
            if (this.Quote.EndOfSystemCount() === 0) {
                this.Quote.Warnings.Add(strLanguageText("sltFlushValve"));
                return false;
            }
        }
        if (o.IntakeDrainValve) {
            if (systemType !== SystemTypes.CanalFeedMaxigator) {
                this.Quote.Warnings.Add(strLanguageText("sltIntakeDrainValve"));
                return false;
            }
        }
        if (o.ReinkeSignMount) {
            if (IsA100) {
                this.Quote.Warnings.Add(strLanguageText("sltReinkeSign"));
                return false;
            }
        }
        if (o.DoubleEndFeedSafetyValve) {
            switch (systemType) {
                case SystemTypes.HoseFeedMaxigator:
                    switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
                        case HoseFeedTypes.DoubleEndFeed:
                            break;
                        default:
                            this.Quote.Warnings.Add(strLanguageText("sltDEFSafetyValve"));
                            return false;
                            break;
                    }
                    break;
                default:
                    this.Quote.Warnings.Add(strLanguageText("sltDEFSafetyValve"));
                    return false;
                    break;
            }
        }
        let bSpecialCasePivotingMaxi: boolean;
        if (!(bTowable) && systemType === SystemTypes.HoseFeedMaxigator && this.Quote.System.Lateral.HoseFeed.PivotOption) {
            bSpecialCasePivotingMaxi = true;
        }
        switch (o.JackKit) {
            case JackKitTypes.None:
                if (o.JackFootKit) {
                    this.Quote.Warnings.Add(strLanguageText("sltJackFootKit"));
                    return false;
                }
                break;
            case JackKitTypes.Manual:
                if (enableJackKit(this.Quote.System) && !bTowable && !bSpecialCasePivotingMaxi) {
                    this.Quote.Warnings.Add(strLanguageText("sltJackKit"));
                    return false;
                }
                break;
        }
        if (o.ElectricTowKit > 0) {
            if (bEDMP) {
                this.Quote.Warnings.Add(strLanguageText("sltElectricTowKit"));
                return false;
            }
            switch (systemType) {
                case SystemTypes.CenterPivot:
                    if (this.Quote.System.Circle.CenterPivot.TowOptions.TowType === TowTypes.None) {
                        this.Quote.Warnings.Add(strLanguageText("sltElectricTowKit"));
                        return false;
                    }
                    break;
                case SystemTypes.KwikTow:
                    break;
                default:
                    this.Quote.Warnings.Add(strLanguageText("sltElectricTowKit"));
                    return false;
                    break;
            }
            if (o.ElectricTowKit > 9) {
                this.Quote.Warnings.Add(strLanguageText("sltElectricTowKit"));
                return false;
            }
        }
        if (o.MovableBarricades > 0) {
            if (this.Quote.RightTowersClass.FieldSet.DataValid()) {
                if (this.Quote.RightTowersClass.AllSugargators()) {
                    if (this.Quote.LeftTowersClass.FieldSet.DataValid()) {
                        if (this.Quote.LeftTowersClass.AllSugargators()) {
                            this.Quote.Warnings.Add(strLanguageText("sltMovable"));
                            return false;
                        }
                    }
                    else {
                        this.Quote.Warnings.Add(strLanguageText("sltMovable"));
                        return false;
                    }
                }
            }
            if (o.MovableBarricades > 9) {
                this.Quote.Warnings.Add(strLanguageText("sltMovable"));
                return false;
            }
            if (!((this.Quote.HasTowerAutoReverse() || this.Quote.HasTowerAutoStop()))) {
                this.Quote.Warnings.Add(strLanguageText("sltMovable"));
                return false;
            }
        }
        if (o.StandardLowProfileStationaryBarricades > 0) {
            if (this.Quote.RightTowersClass.FieldSet.DataValid()) {
                if (this.Quote.RightTowersClass.AllSugargators()) {
                    if (this.Quote.LeftTowersClass.FieldSet.DataValid()) {
                        if (this.Quote.LeftTowersClass.AllSugargators()) {
                            this.Quote.Warnings.Add(strLanguageText("sltStandardLowProfileStationary"));
                            return false;
                        }
                    }
                    else {
                        this.Quote.Warnings.Add(strLanguageText("sltStandardLowProfileStationary"));
                        return false;
                    }
                }
            }
            if (o.StandardLowProfileStationaryBarricades > 9) {
                this.Quote.Warnings.Add(strLanguageText("sltStandardLowProfileStationary"));
                return false;
            }
            if (!((this.Quote.HasTowerAutoReverse() || this.Quote.HasTowerAutoStop()))) {
                this.Quote.Warnings.Add(strLanguageText("sltStandardLowProfileStationary"));
                return false;
            }
        }
        if (o.SugargatorStationaryBarricades > 0) {
            if (this.Quote.RightTowersClass.FieldSet.DataValid()) {
                if (!(this.Quote.RightTowersClass.AnySugargators())) {
                    if (this.Quote.LeftTowersClass.FieldSet.DataValid()) {
                        if (!(this.Quote.LeftTowersClass.AnySugargators())) {
                            this.Quote.Warnings.Add(strLanguageText("sltSugargatorStationary"));
                            return false;
                        }
                    }
                    else {
                        this.Quote.Warnings.Add(strLanguageText("sltSugargatorStationary"));
                        return false;
                    }
                }
            }
            if (o.SugargatorStationaryBarricades > 9) {
                this.Quote.Warnings.Add(strLanguageText("sltSugargatorStationary"));
                return false;
            }
            if (!((this.Quote.HasTowerAutoReverse() || this.Quote.HasTowerAutoStop()))) {
                this.Quote.Warnings.Add(strLanguageText("sltSugargatorStationary"));
                return false;
            }
        }
        if (!(ControlPanelOptionOK(o.EngDigitalPanel, {
            Country: this.Quote.System.DealerProperties.Country,
            GeneratorOption: o.GeneratorOption,
            GeneratorModel: o.GeneratorModel,
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltMurphyPanel"));
            return false;
        }
        if (!(GeneratorOptionOK(o.GeneratorOption, {
            ElectricalFrequency: ElectricalFrequencies.a60,
            SystemType: systemType,
            HoseFeedType: hoseFeedType,
            IsEDMP: sysf.IsEDMP(this.Quote.System),
            HasGenStart: bGenSwitch,
            Country: this.Quote.System.DealerProperties.Country,
        }))) {
            this.Quote.Warnings.AddWithTargets(["Options.GeneratorOption"],strLanguageText("sltGenerator"));
            return false;
        }
        if (o.GeneratorOption !== GeneratorOptions.None && !(GeneratorModelOk(o.GeneratorModel, {
            GeneratorOption: o.GeneratorOption,
            Country: this.Quote.System.DealerProperties.Country,
            MinimumKW: PA.EC.TotalKilowatts + PA.EC.PumpPowerConsumption.PowerKWatts,
            GeneratorFrameType: o.GeneratorFrameType,
            HasEngDigitalPanel: o.EngDigitalPanel,
            IsPivotingLateral: this.isPivotingLateral
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltGeneratorModel"));
            return false;
        }
        if (!(GeneratorSlideRailsOptionOk(o.GeneratorSlideRails, {
            GeneratorOption: o.GeneratorOption
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltGeneratorSlideRails"));
            return false;
        }
        if (!FuelTankOptionOk(o.FuelTank, {
            GeneratorOption: o.GeneratorOption,
            IsEDMP: bEDMP,
            SystemType: systemType,
        })) {
            this.Quote.Warnings.Add(strLanguageText("sltFuelTank"));
            return false;
        }
        
        if (o.StandardLowProfileStationaryBarricades > 0 || o.SugargatorStationaryBarricades > 0 || o.MovableBarricades > 0) {
            this.Quote.Warnings.Add(strLanguageText("sltBarricadesWarning"), WarningLevels.Decision);
        }
        else if (this.Quote.HasTowerAutoReverse() || this.Quote.HasTowerAutoStop() || this.Quote.HasSpanDisc() || this.Quote.HasWrapSpan() || this.Quote.IsPartialCircle()) {
            this.Quote.Warnings.Add(strLanguageText("lsltBarricadesMissingWarning1"), WarningLevels.Decision);
        }
        if (!(CollectorReelICCIOptionCountOk(this.Quote, (dto: CollectorReelICIIOptionCountOkDto) => {
            this.Quote.Warnings.Add(dto.FormattedMessage(), WarningLevels.Critical, true);
            return false;
        }
        ))) {
        }
        return true;
    }
    public WritePO = (PO: PartsAssembler) => {
        let eEndTowerPositioning: EndTowerPositioningTypes;
        let panelModel: PanelModels;
        let is4WhlPivotMover: boolean;
        let systemType: SystemTypes;
        let iNumberOfRightTowers: number;
        let iJackKitQty: number;
        let o = this.Quote.System.Options;
        if (this.Quote.CenterPivotClass.FieldSet.DataValid()) {
            is4WhlPivotMover = this.Quote.System.Circle.CenterPivot.TowOptions.TowType === TowTypes.FourWheelPivotMover;
        }
        if (this.Quote.ControlPanelClass.FieldSet.DataValid()) {
            eEndTowerPositioning = this.Quote.System.ControlPanel.EndTowerPositioning;
            panelModel = this.Quote.System.ControlPanel.PanelModel;
        }
        if (o.ChemTankMountKit) {
            PO.InsertPart(schema.groups.OptionsandAccessories_FuelTank.groupId, "396017", 1); // MTG KIT-CHEM TANK
        }
        systemType = this.Quote.System.SystemProperties.SystemType;
        if (this.Quote.RightTowersClass.FieldSet.DataValid()) {
            iNumberOfRightTowers = sidef.NumberOfTowers(this.Quote.System.FlangedSide);
        }
        if (this.Quote.RightSpansClass.FieldSet.DataValid()) {
            switch (ohf.IsInternational(this.Quote.System.DealerProperties)) {
                case true:
                    if (this.Quote.RightSpansClass.AnyPoly() || (this.Quote.LeftSpansClass.FieldSet.DataValid() && this.Quote.LeftSpansClass.AnyPoly())) {
                        PO.InsertPart(schema.groups.OptionsandAccessories_WarrantyPacket.groupId, "352798PLI", 1); // INSTALL-DOC WAR POLY INT
                    }
                    else {
                        PO.InsertPart(schema.groups.OptionsandAccessories_WarrantyPacket.groupId, "352798-I", 1); // INSTALL-DOC WAR INTERNAT
                    }
                    break;
                case false:
                    if (this.Quote.RightSpansClass.AnySteel() || (this.Quote.LeftSpansClass.FieldSet.DataValid() && this.Quote.LeftSpansClass.AnySteel())) {
                        PO.InsertPart(schema.groups.OptionsandAccessories_WarrantyPacket.groupId, "352798", 1); // INSTALL-DOC WARR US/CANADA
                    }
                    if (this.Quote.RightSpansClass.AnyStainless() || (this.Quote.LeftSpansClass.FieldSet.DataValid() && this.Quote.LeftSpansClass.AnyStainless())) {
                        PO.InsertPart(schema.groups.OptionsandAccessories_WarrantyPacket.groupId, "352798SS", 1); // INSTALL-DOC WAR STNLS US/CAN
                    }
                    if (this.Quote.RightSpansClass.AnyAluminum() || (this.Quote.LeftSpansClass.FieldSet.DataValid() && this.Quote.LeftSpansClass.AnyAluminum())) {
                        PO.InsertPart(schema.groups.OptionsandAccessories_WarrantyPacket.groupId, "352798-A", 1); // INSTALL-DOC WAR ALUM US/CAN
                    }
                    if (this.Quote.RightSpansClass.AnyPoly() || (this.Quote.LeftSpansClass.FieldSet.DataValid() && this.Quote.LeftSpansClass.AnyPoly())) {
                        PO.InsertPart(schema.groups.OptionsandAccessories_WarrantyPacket.groupId, "352798PL", 1); // INSTALL-DOC WAR POLY US/CAN
                    }
                    break;
            }
        }
        if (o.MovableBarricades > 0) {
            PO.InsertPart(schema.groups.OptionsandAccessories_MovableBarricades.groupId, "352251", o.MovableBarricades); // TOWER AS/AR BARRICADES(1 ASSY)
            PO.InsertPart(schema.groups.OptionsandAccessories_MovableBarricades.groupId, "352246", o.MovableBarricades); // TRAILING TIRE PLATE OPT-1 EACH
        }
        if (o.StandardLowProfileStationaryBarricades > 0) {
            PO.InsertPart(schema.groups.OptionsandAccessories_StationaryBarricades.groupId, "352115", o.StandardLowProfileStationaryBarricades); // BARRICADE-STANDARD-A/S-A/R-EII
        }
        if (o.SugargatorStationaryBarricades > 0) {
            PO.InsertPart(schema.groups.OptionsandAccessories_StationaryBarricades.groupId, "352114", o.SugargatorStationaryBarricades); // BARRICADE-SG-A/S-A/R-EII
        }
        if (o.FlushValve) {
            if (this.Quote.RightEOSClass.FieldSet.DataValid()) {
                switch (this.Quote.RightEOSClass.EndBoomLength()) {
                    case 85:
                    case 105:
                        PO.InsertPart(schema.groups.OptionsandAccessories_FlushValve.groupId, "354556", 1); // VALVE-FLUSH-85'~105'-END BOOM
                        break;
                    case 1:
                        if (!(this.Quote.IsDEF())) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_FlushValve.groupId, "354555", 1); // VALVE-FLUSH-3'~61'-END BOOM
                        }
                        break;
                    default:
                        PO.InsertPart(schema.groups.OptionsandAccessories_FlushValve.groupId, "354555", 1); // VALVE-FLUSH-3'~61'-END BOOM
                        break;
                }
            }
            if (this.Quote.LeftEOSClass.FieldSet.DataValid()) {
                switch (this.Quote.LeftEOSClass.EndBoomLength()) {
                    case 85:
                    case 105:
                        PO.InsertPart(schema.groups.OptionsandAccessories_FlushValve.groupId, "354556", 1); // VALVE-FLUSH-85'~105'-END BOOM
                        break;
                    default:
                        PO.InsertPart(schema.groups.OptionsandAccessories_FlushValve.groupId, "354555", 1); // VALVE-FLUSH-3'~61'-END BOOM
                        break;
                }
            }
        }
        if (o.IntakeDrainValve) {
            PO.InsertPart(schema.groups.OptionsandAccessories_IntakeDrainValve.groupId, "392819", 1); // DRAIN VALVE OPT-CANAL FEED
        }
        if (o.ReinkeSignMount) {
            PO.InsertPart(schema.groups.OptionsandAccessories_ReinkeSignMount.groupId, "353745", 1); // SIGN-REINKE 24 X 32-PVT CTR
        }
        if (o.DoubleEndFeedSafetyValve) {
            PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352163", 1); // SLEEVE VALVE-6"WTR SHUTDN-MAXI
        }
        let bSpecialCasePivotingMaxi: boolean;
        if (!(this.Quote.IsTowable()) && systemType === SystemTypes.HoseFeedMaxigator && this.Quote.System.Lateral.HoseFeed.PivotOption) {
            bSpecialCasePivotingMaxi = true;
        }
        if (bSpecialCasePivotingMaxi) {
            iJackKitQty = 1;
        }
        else {
            iJackKitQty = iNumberOfRightTowers;
            if (systemType === SystemTypes.HoseFeedMaxigator) {
                if (this.Quote.HoseFeedClass.FieldSet.DataValid()) {
                    switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
                        case HoseFeedTypes.Standard:
                        case HoseFeedTypes.A100:
                            iJackKitQty += 1;
                            break;
                        case HoseFeedTypes.DoubleEndFeed:
                            iJackKitQty += 2;
                            break;
                    }
                }
            }
        }
        if (enableJackKit(this.Quote.System) && o.JackKit === JackKitTypes.Manual) {
            if (this.Quote.IsA100_Only()) {
                PO.InsertPart(schema.groups.OptionsandAccessories_JackKits.groupId, "395957", 1); // JACK OPT-POWER TOWER-A100 MAXI
                PO.InsertPart(schema.groups.OptionsandAccessories_JackKits.groupId, "355301", 1); // MANUAL JACK KIT-COMPLETE-TOWER
            } else if (this.Quote.IsCF200()) {
                // Begin Kit for Power Tower
                PO.InsertPart(schema.groups.OptionsandAccessories_JackKits.groupId, "395957", 1);  // JACK OPT-POWER TOWER-A100 MAXI
                PO.InsertPart(schema.groups.OptionsandAccessories_JackFootKit.groupId, "355302", 1);  // JACK FOOT KIT-SNGL SPAN MAXI
                // End Kit for Power Tower
                PO.InsertPart(schema.groups.OptionsandAccessories_JackKits.groupId, "355301", iJackKitQty);  // MANUAL JACK KIT-COMPLETE-TOWER
            } else {
                PO.InsertPart(schema.groups.OptionsandAccessories_JackKits.groupId, "355301", iJackKitQty); // MANUAL JACK KIT-COMPLETE-TOWER
            }
        }
        if (o.JackFootKit) {
            PO.InsertPart(schema.groups.OptionsandAccessories_JackFootKit.groupId, "355302", iJackKitQty); // JACK FOOT KIT-SNGL SPAN MAXI
        }
        if (o.SpareBoltPackage) {
            if (this.Quote.RightSpansClass.FieldSet.DataValid()) {
                switch (this.Quote.System.FlangedSide.Span[1 - 1].SpanType) {
                    case SpanTypes.E2045:
                        PO.InsertPart(schema.groups.OptionsandAccessories_SpareBoltPackage.groupId, "351499", 1); // SPARE BOLTS 2045 PKG - IRR SYS
                        break;
                    default:
                        PO.InsertPart(schema.groups.OptionsandAccessories_SpareBoltPackage.groupId, "351500", 1); // SPARE BOLTS PKG-IRRIG SYSTEMS
                        break;
                }
            }
            else {
                PO.InsertPart(schema.groups.OptionsandAccessories_SpareBoltPackage.groupId, "351500", 1); // SPARE BOLTS PKG-IRRIG SYSTEMS
            }
        }
        if (o.ElectricTowKit > 0) {
            switch (systemType) {
                case SystemTypes.CanalFeedMaxigator:
                case SystemTypes.HoseFeedMaxigator:
                    if (panelModel) {
                        PO.InsertPart(schema.groups.OptionsandAccessories_ElectricTowKits.groupId, "391002", o.ElectricTowKit); // TOW BOX W/HARDWARE-4 X 8 CONN
                        PO.InsertPart(schema.groups.OptionsandAccessories_ElectricTowCable.groupId, "392615", 1); // 10 COND WIRE X 40' W/4X8 PLUG
                    }
                    else {
                        PO.InsertPart(schema.groups.OptionsandAccessories_ElectricTowKits.groupId, "391002-R", o.ElectricTowKit); // TOW BOX W/HARDWARE-4 X 8 CONN
                        PO.InsertPart(schema.groups.OptionsandAccessories_ElectricTowCable.groupId, "392615-R", 1); // 14 COND WIRE X 40' W/4X8 PLUG
                    }
                    break;
                default:
                    PO.InsertPart(schema.groups.OptionsandAccessories_ElectricTowCable.groupId, "392615", 1); // 10 COND WIRE X 40' W/4X8 PLUG
                    if (eEndTowerPositioning !== EndTowerPositioningTypes.Resolver) {
                        PO.InsertPart(schema.groups.OptionsandAccessories_ElectricTowKits.groupId, "391002", o.ElectricTowKit); // TOW BOX W/HARDWARE-4 X 8 CONN
                    }
                    else {
                        PO.InsertPart(schema.groups.OptionsandAccessories_ElectricTowKits.groupId, "391002-R", o.ElectricTowKit); // TOW BOX W/HARDWARE-4 X 8 CONN
                    }
                    break;
            }
        }
        const isA100 = this.Quote.IsA100_Only();

        if (o.GeneratorOption === GeneratorOptions.GeneratorSet) {
            switch (systemType) {
                case SystemTypes.CenterPivot:
                    if (is4WhlPivotMover) {
                        switch (o.GeneratorModel) {
                            case GeneratorModels.Lima_10_0_kW:
                                this.GeneratorSelectorTier4FAndExport(o, PO, 127, 251, 255);
                                break;
                            case GeneratorModels.Lima_12_5_kW:
                                break;
                                break;
                            case GeneratorModels.Lima_15_0_kW:
                                // ROE-810 - New Lima Gen Sets for USA / Canada
                                this.GeneratorSelectorByOpenClosedFrame(o, PO,
                                    300, // 353034	KUBOTA/LIMA-15KW-DSE-OPEN-4WPM
                                    301); // 353038	KUBOTA/LIMA-15KW-DSE-ENCL-4WPM
                                break;
                            case GeneratorModels.Lima_20_0_kW:
                                break;
                            case GeneratorModels.Lima_25_0_kW:
                                this.GeneratorSelectorByPanelOption(o, PO, 197, 198);
                                break;
                            case GeneratorModels.Lima_30_0_kW:
                                this.GeneratorSelectorByPanelOption(o, PO, 242, 292);
                                break;
                            case GeneratorModels.MeccAlte_05_0_kW:
                                this.GeneratorSelectorTier4FAndExport(o, PO, 216, 243, 253);
                                break;
                            case GeneratorModels.MeccAlte_07_5_kW:
                                this.GeneratorSelectorTier4FAndExport(o, PO, 241, 244, 254);
                                break;
                            case GeneratorModels.MeccAlte_10_0_kW:
                                this.GeneratorSelectorTier4FAndExport(o, PO, 137, 252, 256);
                                break;
                            case GeneratorModels.MeccAlte_15_0_kW:
                                this.GeneratorSelectorByPanelOption(o, PO, 167, 168);
                                break;
                            case GeneratorModels.MeccAlte_18_0_kW:
                                this.GeneratorSelectorByOpenClosedFrame(o, PO, 268, 272);
                                break;
                            case GeneratorModels.MeccAlte_25_0_kW:
                                this.GeneratorSelectorByPanelOption(o, PO, 207, 208);
                                break;
                            case GeneratorModels.MeccAlte_27_0_kW:
                                this.GeneratorSelectorByOpenClosedFrame(o, PO, 277, 280);
                                break;
                            case GeneratorModels.MeccAlte_30_0_kW:
                                this.GeneratorSelectorByPanelOption(o, PO, 220, 293);
                                break;
                        }
                    }
                    else {
                        switch (o.GeneratorModel) {
                            case GeneratorModels.Lima_10_0_kW:
                                this.GeneratorSelectorTier4FAndExport(o, PO, 123, 262, 274);
                                break;
                            case GeneratorModels.Lima_12_5_kW:
                                break;
                                break;
                            case GeneratorModels.Lima_15_0_kW:
                                // ROE-810 - New Lima Gen Sets for USA / Canada
                                this.GeneratorSelectorByOpenClosedFrame(o, PO,
                                    302, // 353035	KUBOTA/LIMA-15KW-DSE-OPEN-PVT
                                    303); // 353039	KUBOTA/LIMA-15KW-DSE-ENCL-PVT
                                break;
                            case GeneratorModels.Lima_20_0_kW:
                                break;
                                break;
                            case GeneratorModels.Lima_25_0_kW:
                                this.GeneratorSelectorByPanelOption(o, PO, 193, 194);
                                break;
                            case GeneratorModels.Lima_30_0_kW:
                                this.GeneratorSelectorByPanelOption(o, PO, 258, 294);
                                break;
                            case GeneratorModels.MeccAlte_05_0_kW:
                                this.GeneratorSelectorTier4FAndExport(o, PO, 217, 257, 259);
                                break;
                            case GeneratorModels.MeccAlte_07_5_kW:
                                this.GeneratorSelectorTier4FAndExport(o, PO, 218, 260, 261);
                                break;
                            case GeneratorModels.MeccAlte_10_0_kW:
                                this.GeneratorSelectorTier4FAndExport(o, PO, 133, 263, 275);
                                break;
                            case GeneratorModels.MeccAlte_15_0_kW:
                                this.GeneratorSelectorByPanelOption(o, PO, 163, 164);
                                break;
                            case GeneratorModels.MeccAlte_18_0_kW:
                                this.GeneratorSelectorByOpenClosedFrame(o, PO, 270, 273);
                                break;
                            case GeneratorModels.MeccAlte_25_0_kW:
                                this.GeneratorSelectorByPanelOption(o, PO, 203, 204);
                                break;
                            case GeneratorModels.MeccAlte_27_0_kW:
                                this.GeneratorSelectorByOpenClosedFrame(o, PO, 278, 281);
                                break;
                            case GeneratorModels.MeccAlte_30_0_kW:
                                this.GeneratorSelectorByPanelOption(o, PO, 222, 295);
                                break;
                        }
                    }
                    break;
                case SystemTypes.KwikTow:
                    switch (o.GeneratorModel) {
                        case GeneratorModels.Lima_10_0_kW:
                            this.GeneratorSelectorTier4FAndExport(o, PO, 121, 232, 247);
                            break;
                        case GeneratorModels.Lima_12_5_kW:
                            break;
                            break;
                        case GeneratorModels.Lima_15_0_kW:
                            // ROE-810 - New Lima Gen Sets for USA / Canada
                            this.GeneratorSelectorByOpenClosedFrame(o, PO,
                                296, // 353032	KUBOTA/LIMA-15KW-DSE-OPEN-KT
                                297); // 353036	KUBOTA/LIMA-15KW-DSE-ENCL-KT
                            break;
                        case GeneratorModels.Lima_20_0_kW:
                            break;
                            break;
                        case GeneratorModels.Lima_25_0_kW:
                            this.GeneratorSelectorByPanelOption(o, PO, 284, 282);
                            break;
                        case GeneratorModels.Lima_30_0_kW:
                            this.GeneratorSelectorByPanelOption(o, PO, 288, 286);
                            break;
                        case GeneratorModels.MeccAlte_05_0_kW:
                            this.GeneratorSelectorTier4FAndExport(o, PO, 201, 230, 245);
                            break;
                        case GeneratorModels.MeccAlte_07_5_kW:
                            this.GeneratorSelectorTier4FAndExport(o, PO, 211, 231, 246);
                            break;
                        case GeneratorModels.MeccAlte_10_0_kW:
                            this.GeneratorSelectorTier4FAndExport(o, PO, 131, 233, 248);
                            break;
                        case GeneratorModels.MeccAlte_15_0_kW:
                            this.GeneratorSelectorByPanelOption(o, PO, 161, 162);
                            break;
                        case GeneratorModels.MeccAlte_18_0_kW:
                            this.GeneratorSelectorByOpenClosedFrame(o, PO, 269, 271);
                            break;
                        case GeneratorModels.MeccAlte_25_0_kW:
                            this.GeneratorSelectorByPanelOption(o, PO, 285, 283);
                            break;
                        case GeneratorModels.MeccAlte_27_0_kW:
                            this.GeneratorSelectorByOpenClosedFrame(o, PO, 276, 279);
                            break;
                        case GeneratorModels.MeccAlte_30_0_kW:
                            this.GeneratorSelectorByPanelOption(o, PO, 289, 287);
                            break;
                    }
                    break;
                case SystemTypes.HoseFeedMaxigator:
                    if (isA100) {
                        break;
                    }
                    switch (o.GeneratorModel) {
                        case GeneratorModels.Lima_10_0_kW:
                            this.GeneratorSelectorTier4FAndExport(o, PO, 125, 237, 239);
                            break;
                        case GeneratorModels.Lima_12_5_kW:
                            break;
                            break;
                        case GeneratorModels.Lima_15_0_kW:
                            // ROE-810 - New Lima Gen Sets for USA / Canada
                            this.GeneratorSelectorByOpenClosedFrame(o, PO,
                                298, // 353033	KUBOTA/LIMA-15KW-DSE-OPEN-MAXI
                                299); // 353037	KUBOTA/LIMA-15KW-DSE-ENCL-MAXI
                            break;
                        case GeneratorModels.Lima_20_0_kW:
                            break;
                            break;
                        case GeneratorModels.Lima_25_0_kW:
                            this.GeneratorSelectorByPanelOption(o, PO, 195, 196);
                            break;
                        case GeneratorModels.Lima_30_0_kW:
                            this.GeneratorSelectorByPanelOption(o, PO, 234, 290);
                            break;
                        case GeneratorModels.MeccAlte_05_0_kW:
                            this.GeneratorSelectorTier4FAndExport(o, PO, 212, 235, 249);
                            break;
                        case GeneratorModels.MeccAlte_07_5_kW:
                            this.GeneratorSelectorTier4FAndExport(o, PO, 215, 236, 250);
                            break;
                        case GeneratorModels.MeccAlte_10_0_kW:
                            this.GeneratorSelectorTier4FAndExport(o, PO, 135, 238, 240);
                            break;
                        case GeneratorModels.MeccAlte_15_0_kW:
                            this.GeneratorSelectorByPanelOption(o, PO, 165, 166);
                            break;
                        case GeneratorModels.MeccAlte_18_0_kW:
                            this.GeneratorSelectorByOpenClosedFrame(o, PO, 264, 265);
                            break;
                        case GeneratorModels.MeccAlte_25_0_kW:
                            this.GeneratorSelectorByPanelOption(o, PO, 205, 206);
                            break;
                        case GeneratorModels.MeccAlte_27_0_kW:
                            this.GeneratorSelectorByOpenClosedFrame(o, PO, 266, 267);
                            break;
                        case GeneratorModels.MeccAlte_30_0_kW:
                            this.GeneratorSelectorByPanelOption(o, PO, 219, 291);
                            break;
                    }
                    break;
                default:
                    break;
                    break;
            }
        }
        if (o.GeneratorOption === GeneratorOptions.GeneratorOnly) {
            switch (o.GeneratorModel) {
                case GeneratorModels.Lima_10_0_kW:
                    PO.InsertPart(schema.groups.OptionsandAccessories_GeneratorAssembly.groupId, "351013", 1); // GENERATOR ASY-10 KW-LIMA
                    break;
                case GeneratorModels.Lima_12_5_kW:
                    PO.InsertPart(schema.groups.OptionsandAccessories_GeneratorAssembly.groupId, "351045", 1); // GENERATOR ASY-12-1/2 KW-LIMA
                    break;
                case GeneratorModels.Lima_15_0_kW:
                    PO.InsertPart(schema.groups.OptionsandAccessories_GeneratorAssembly.groupId, "351044", 1); // GENERATOR ASY-15 KW-LIMA
                    break;
                case GeneratorModels.Lima_20_0_kW:
                    PO.InsertPart(schema.groups.OptionsandAccessories_GeneratorAssembly.groupId, "351058", 1); // GENERATOR ASY-20 KW-LIMA
                    break;
                case GeneratorModels.Lima_25_0_kW:
                    // RDC-81 - Disable the 25KW generator option as inventory of existing model is now ZERO. This option
                    // is disabled by a rule in page.ts. Keeping this case for old systems.
                    PO.InsertPart(schema.groups.OptionsandAccessories_GeneratorAssembly.groupId, "351059", 1); // GENERATOR ASY-25 KW-LIMA
                    break;
                case GeneratorModels.Lima_30_0_kW:
                    PO.InsertPart(schema.groups.OptionsandAccessories_GeneratorAssembly.groupId, "351061", 1); // GENERATOR ASY-30 KW-LIMA
                    break;
                case GeneratorModels.MeccAlte_10_0_kW:
                    PO.InsertPart(schema.groups.OptionsandAccessories_GeneratorAssembly.groupId, "392896", 1)  // GENERATOR ASY-10KW-MECC
                    break;
                case GeneratorModels.MeccAlte_15_0_kW:
                    PO.InsertPart(schema.groups.OptionsandAccessories_GeneratorAssembly.groupId, "392897", 1)  // GENERATOR ASY-15KW-MECC
                    break;
                case GeneratorModels.MeccAlte_05_0_kW:
                case GeneratorModels.MeccAlte_07_5_kW:
                case GeneratorModels.MeccAlte_18_0_kW:
                case GeneratorModels.MeccAlte_25_0_kW:
                case GeneratorModels.MeccAlte_27_0_kW:
                case GeneratorModels.MeccAlte_30_0_kW:
                    break;
                    break;
            }
        }
        if (o.GeneratorOption !== GeneratorOptions.None) {
            if (this.isPivotingLateral) {
                if (this.Quote.System.ControlPanel.GenStartControl) {
                    PO.InsertPart(schema.groups.OptionsandAccessories_GeneratorAssembly.groupId, "395990", 1); // GEN SET MTG KIT-PIVOT-LATERAL
                }
            }
        }
        if (o.GeneratorSlideRails) {
            PO.InsertPart(schema.groups.OptionsandAccessories_GeneratorSlideRail.groupId, "351030", 1); // GENERATOR SLIDE RAIL
        }
        if (o.FuelTank) {
            switch (systemType) {
                case SystemTypes.CenterPivot:
                case SystemTypes.KwikTow:
                    PO.InsertPart(schema.groups.OptionsandAccessories_FuelTank.groupId, "352157", 1); // FUEL TANK ASY-80 GAL-KWIK TOW
                    break;
                case SystemTypes.HoseFeedMaxigator:
                    if (this.Quote.HoseFeedClass.FieldSet.DataValid()) {
                        switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
                            case HoseFeedTypes.Standard:
                            case HoseFeedTypes.DoubleEndFeed:
                            case HoseFeedTypes.A100:
                            case HoseFeedTypes.CF200:
                                PO.InsertPart(schema.groups.OptionsandAccessories_FuelTank.groupId, "352206", 1); // FUEL TANK ASY-80 GAL-MAXI
                                break;
                            case HoseFeedTypes.PivotingLateral:
                                PO.InsertPart(schema.groups.OptionsandAccessories_FuelTank.groupId, "352206", 1); // FUEL TANK ASY-80 GAL-MAXI
                                PO.InsertPart(schema.groups.OptionsandAccessories_PivotingLateralOptions.groupId, "395991", 1); // FUEL TANK MTG KIT-80G-PIV LAT
                                break;
                            default:
                                PO.InsertPart(schema.groups.OptionsandAccessories_FuelTank.groupId, "352161", 1); // FUEL TANK ASY-80 GAL-4WD MAXI
                                break;
                        }
                    }
                    break;
            }
        }
        let bPartCircleWithNoCollector: boolean = false;
        if (!(sysf.IsEDMP(this.Quote.System)) && sysf.FieldSets(this.Quote.System).ControlPanel.DataValid()) {
            bPartCircleWithNoCollector = this.Quote.IsPartialCircle() && this.Quote.System.ControlPanel.CollectorRing === CollectorRingTypes.None;
        }
        switch (o.PivotLight) {
            case LightTypes.Standard:
                if (o.PivotLightRAMSConstantOn) {
                    PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352508-R", 1); // PIVOT LIGHT ASY-CONST ON-RAMS
                }
                else {
                    switch (systemType) {
                        case SystemTypes.CenterPivot:
                            if (bPartCircleWithNoCollector) {
                                PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352561", 1); // PIVOT LIGHT ASY-KWIK TOW&MAXI
                            }
                            else {
                                PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352508", 1); // PIVOT LIGHT ASSY-ELECTRO/ALUMI
                            }
                            break;
                        case SystemTypes.HoseFeedMaxigator:
                            if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                                PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352508", 1); // PIVOT LIGHT ASSY-ELECTRO/ALUMI
                            }
                            else {
                                PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352561", 1); // PIVOT LIGHT ASY-KWIK TOW&MAXI
                            }
                            break;
                        default:
                            PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352561", 1); // PIVOT LIGHT ASY-KWIK TOW&MAXI
                            break;
                    }
                }
                break;
            case LightTypes.StrobeClear:
            case LightTypes.StrobeAmber:
            case LightTypes.StrobeBlue:
            case LightTypes.StrobeGreen:
            case LightTypes.StrobeRed:
                if (o.PivotLightRAMSConstantOn) {
                    PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352509-R", 1); // STROBE LIGHT ASY-CONST ON-RAMS
                }
                else {
                    switch (systemType) {
                        case SystemTypes.CenterPivot:
                            if (bPartCircleWithNoCollector) {
                                PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352558", 1); // STROBE LIGHT ASY-PIVOT-KT&MAXI
                            }
                            else {
                                PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352509", 1); // STROBE LIGHT ASY-PIVOT-EA
                            }
                            break;
                        case SystemTypes.HoseFeedMaxigator:
                            if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                                PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352509", 1); // STROBE LIGHT ASY-PIVOT-EA
                            }
                            else {
                                PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352558", 1); // STROBE LIGHT ASY-PIVOT-KT&MAXI
                            }
                            break;
                        default:
                            PO.InsertPart(schema.groups.OptionsandAccessories_PivotLight.groupId, "352558", 1); // STROBE LIGHT ASY-PIVOT-KT&MAXI
                            break;
                    }
                }
                switch (o.PivotLight) {
                    case LightTypes.StrobeClear:
                        PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352757-C", 1); // BULB-CLEAR STROBE-120V SOCKET
                        break;
                    case LightTypes.StrobeAmber:
                        PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352757-A", 1); // BULB-AMBER STROBE-120V SOCKET
                        break;
                    case LightTypes.StrobeBlue:
                        PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352757-B", 1); // BULB-BLUE STROBE -120V SOCKET
                        break;
                    case LightTypes.StrobeGreen:
                        PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352757-G", 1); // BULB-GREEN STROBE-120V SOCKET
                        break;
                    case LightTypes.StrobeRed:
                        PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352757-R", 1); // BULB-RED STROBE-120V SOCKET
                        break;
                }
                break;
            case LightTypes.Strobe400Clear:
                switch (systemType) {
                    case SystemTypes.CenterPivot:
                        if (o.PivotLightRAMSConstantOn) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352986-C", 1); // END-STROBE-CONST ON 400ECP-CLR
                        }
                        else if (bPartCircleWithNoCollector) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-C", 1); // PVT LGT-STROBE-400ECP-20-CLR
                        }
                        else {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352990-C", 1); // PVT LGT ASSY-STROBE-400ECP-CLR
                        }
                        break;
                    case SystemTypes.HoseFeedMaxigator:
                        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352990-C", 1); // PVT LGT ASSY-STROBE-400ECP-CLR
                        }
                        else {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-C", 1); // PVT LGT-STROBE-400ECP-20-CLR
                        }
                        break;
                    default:
                        PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-C", 1); // PVT LGT-STROBE-400ECP-20-CLR
                        break;
                }
                break;
            case LightTypes.Strobe400Amber:
                switch (systemType) {
                    case SystemTypes.CenterPivot:
                        if (o.PivotLightRAMSConstantOn) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352986-A", 1); // END-STROBE-CONST ON 400ECP-AMB
                        }
                        else if (bPartCircleWithNoCollector) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-A", 1); // PVT LGT-STROBE-400ECP-20-AMB
                        }
                        else {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352990-A", 1); // PVT LGT ASSY-STROBE-400ECP-AMB
                        }
                        break;
                    case SystemTypes.HoseFeedMaxigator:
                        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352990-A", 1); // PVT LGT ASSY-STROBE-400ECP-AMB
                        }
                        else {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-A", 1); // PVT LGT-STROBE-400ECP-20-AMB
                        }
                        break;
                    default:
                        PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-A", 1); // PVT LGT-STROBE-400ECP-20-AMB
                        break;
                }
                break;
            case LightTypes.Strobe400Blue:
                switch (systemType) {
                    case SystemTypes.CenterPivot:
                        if (o.PivotLightRAMSConstantOn) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352986-B", 1); // END-STROBE-CONST ON 400ECP-BLU
                        }
                        else if (bPartCircleWithNoCollector) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-B", 1); // PVT LGT-STROBE-400ECP-20-BLU
                        }
                        else {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352990-B", 1); // PVT LGT ASSY-STROBE-400ECP-BLU
                        }
                        break;
                    case SystemTypes.HoseFeedMaxigator:
                        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352990-B", 1); // PVT LGT ASSY-STROBE-400ECP-BLU
                        }
                        else {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-B", 1); // PVT LGT-STROBE-400ECP-20-BLU
                        }
                        break;
                    default:
                        PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-B", 1); // PVT LGT-STROBE-400ECP-20-BLU
                        break;
                }
                break;
            case LightTypes.Strobe400Green:
                switch (systemType) {
                    case SystemTypes.CenterPivot:
                        if (o.PivotLightRAMSConstantOn) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352986-G", 1); // END-STROBE-CONST ON 400ECP-GRN
                        }
                        else if (bPartCircleWithNoCollector) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-G", 1); // PVT LGT-STROBE-400ECP-20-GRN
                        }
                        else {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352990-G", 1); // PVT LGT ASSY-STROBE-400ECP-GRN
                        }
                        break;
                    case SystemTypes.HoseFeedMaxigator:
                        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352990-G", 1); // PVT LGT ASSY-STROBE-400ECP-GRN
                        }
                        else {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-G", 1); // PVT LGT-STROBE-400ECP-20-GRN
                        }
                        break;
                    default:
                        PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-G", 1); // PVT LGT-STROBE-400ECP-20-GRN
                        break;
                }
                break;
            case LightTypes.Strobe400Red:
                switch (systemType) {
                    case SystemTypes.CenterPivot:
                        if (o.PivotLightRAMSConstantOn) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352986-R", 1); // END-STROBE-CONST ON 400ECP-RED
                        }
                        else if (bPartCircleWithNoCollector) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-R", 1); // PVT LGT-STROBE-400ECP-20-RED
                        }
                        else {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352990-R", 1); // PVT LGT ASSY-STROBE-400ECP-RED
                        }
                        break;
                    case SystemTypes.HoseFeedMaxigator:
                        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352990-R", 1); // PVT LGT ASSY-STROBE-400ECP-RED
                        }
                        else {
                            PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-R", 1); // PVT LGT-STROBE-400ECP-20-RED
                        }
                        break;
                    default:
                        PO.InsertPart(schema.groups.OptionsandAccessories_StrobeLight.groupId, "352989-R", 1); // PVT LGT-STROBE-400ECP-20-RED
                        break;
                }
                break;
        }
        if (o.AuxAccessStep) {
            PO.InsertPart(schema.groups.OptionsandAccessories_PivotingLateralOptions.groupId, "396018", 1); // AUX STEP-FUEL TANK
        }
        if (o.ProxSwitchBarricades > 0) {
            PO.InsertPart(schema.groups.OptionsandAccessories_PivotingLateralOptions.groupId, "395999", o.ProxSwitchBarricades); // BARRICADE OPT-PROX SWITCH
        }
    }
/*private EvaluateGeneratorPartNumbers = (PO: PartsAssembler) => {
}
*/private GeneratorSelectorTier4FAndExport = (o: Options, PO: PartsAssembler, iterWithDigitalPanel: number, iterOpenFrame: number, iterEnclosedFrame: number) => {
        if (o.EngDigitalPanel) {
            PO.InsertPartByIteration(schema.groups.OptionsandAccessories_DieselEngineGeneratorSets.groupId, iterWithDigitalPanel, 1);
        }
        else {
            switch (o.GeneratorFrameType) {
                case GeneratorFrameTypes.OpenFrame:
                    PO.InsertPartByIteration(schema.groups.OptionsandAccessories_DieselEngineGeneratorSets.groupId, iterOpenFrame, 1);
                    break;
                case GeneratorFrameTypes.EnclosedFrame:
                    PO.InsertPartByIteration(schema.groups.OptionsandAccessories_DieselEngineGeneratorSets.groupId, iterEnclosedFrame, 1);
                    break;
            }
        }
    }
    private GeneratorSelectorByOpenClosedFrame = (o: Options, PO: PartsAssembler, iterOpenFrame: number, iterEnclosedFrame: number) => {
        switch (o.GeneratorFrameType) {
            case GeneratorFrameTypes.OpenFrame:
                PO.InsertPartByIteration(schema.groups.OptionsandAccessories_DieselEngineGeneratorSets.groupId, iterOpenFrame, 1);
                break;
            case GeneratorFrameTypes.EnclosedFrame:
                PO.InsertPartByIteration(schema.groups.OptionsandAccessories_DieselEngineGeneratorSets.groupId, iterEnclosedFrame, 1);
                break;
        }
    }
    private GeneratorSelectorByPanelOption = (o: Options, PO: PartsAssembler, iterWithDigitalPanel: number, iterWithDeepSeaPanel: number) => {
        if (o.EngDigitalPanel) {
            PO.InsertPartByIteration(schema.groups.OptionsandAccessories_DieselEngineGeneratorSets.groupId, iterWithDigitalPanel, 1);
        }
        else {
            PO.InsertPartByIteration(schema.groups.OptionsandAccessories_DieselEngineGeneratorSets.groupId, iterWithDeepSeaPanel, 1);
        }
    }
}