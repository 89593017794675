import IAction from "rdptypes/IAction";
import { IFEXContext } from "rdptypes/foreignExchange/interfaces";
import { EndOfSystemTypes, SystemTypes, WaterFeedTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { ISpan, ITower } from "rdptypes/project/Types";
import { productSets } from "roedata/data";
import { calculateRateData, createNewSystemActionV2_WithFEX } from "../../actions/NewSystemActionV2";
import { createSetEndOfSystemAction } from "../../actions/SetEndOfSystemAction";
import { createSetSpanTowersActionV2 } from "../../actions/SetSpansV2";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import { createSystemDefaultActions } from "../../actions/defaultActions";
import IAuthState from "../../auth/IAuthState";
import { staticDevSettingsDbProvider } from "../../db/DevSettingsDbProvider";
import IDbProject from "../../db/IDbProject";
import * as optimizeLateralModule from "../../optimization/lateral";

type IArgs = ({
    optimizedSystem: optimizeLateralModule.IOptimizedSystem;
    layoutId: string;
    authState: IAuthState;
    systemDefaults?: (layoutId: string, systemId: string, authState: IAuthState, systemType: SystemTypes) => IAction[];
    systemType: SystemTypes.HoseFeedMaxigator;
    endFeed?: boolean;
    dbPrj: IDbProject;
} | {
    optimizedSystem: optimizeLateralModule.IOptimizedSystem;
    layoutId: string;
    authState: IAuthState;
    systemDefaults?: (layoutId: string, systemId: string, authState: IAuthState, systemType: SystemTypes) => IAction[];
    systemType: SystemTypes.CanalFeedMaxigator;
    canalFeed: {
        canalWidthFeet: number;
        distanceFromCanalCenterToFwdSide: number;
        distanceFromCanalCenterToAftSide: number;
    }
    endFeed?: boolean;
    dbPrj: IDbProject;
}) & {
    fex?: IFEXContext;
}

export const createLateralActionsFromOptimizedSystem = (args: IArgs) => {
    const { layoutId, authState, optimizedSystem, systemType, systemDefaults, dbPrj } = args;
    const customerSettings = staticDevSettingsDbProvider.customer.get();
    const dealerPricing = undefined;
    const rateData = args.fex ? calculateRateData(customerSettings, args.fex) : undefined;
    var { systemId, action } = createNewSystemActionV2_WithFEX(
        layoutId!, systemType, 
        customerSettings, dealerPricing, rateData,
        authState,
        productSets[dbPrj.state.productSetId].latestPartsPackageId
    );
    const useSpanDefaults = staticDevSettingsDbProvider.span.getUseCustom();
    const spanDefaults: ISpan | undefined = useSpanDefaults
        ? staticDevSettingsDbProvider.span.get(false)
        : undefined;
    const useTowerDefaults = staticDevSettingsDbProvider.tower.getUseCustom();
    const towerDefaults: ITower | undefined = useTowerDefaults
        ? staticDevSettingsDbProvider.tower.get(false)
        : undefined;
        
    const actions: IAction[] = [action];

    if (systemDefaults) {
        for (const def of systemDefaults(layoutId, systemId, authState, systemType)) {
            actions.push(def);
        }
    }
    else {
        actions.push(...createSystemDefaultActions(layoutId, systemId, authState));
    }

    actions.push(createNewUpdateSystemPropertyAction(
        layoutId!,
        systemId,
        "lateral.line",
        optimizedSystem.feedLine,
        authState
    ));
    if (systemType === SystemTypes.CanalFeedMaxigator) {
        const canalFeed = args.canalFeed;
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId!,
            systemId,
            "lateral.canalWidthFeet",
            canalFeed.canalWidthFeet,
            authState
        ));
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId!,
            systemId,
            "lateral.distanceFromCanalCenterToFwdSide",
            canalFeed.distanceFromCanalCenterToFwdSide,
            authState
        ));
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId!,
            systemId,
            "lateral.distanceFromCanalCenterToAftSide",
            canalFeed.distanceFromCanalCenterToAftSide,
            authState
        ));
    }
    
    actions.push(createNewUpdateSystemPropertyAction(
        layoutId!,
        systemId,
        "Lateral.WaterFeed",
        args.endFeed ? WaterFeedTypes.EndFeed : WaterFeedTypes.CenterFeed,
        authState
    ));

    if (optimizedSystem) {
        const aftSpans =  optimizedSystem.spans.slice(0, optimizedSystem.aftSideSpanCount).reverse();
        const fwdSpans =  optimizedSystem.spans.slice(optimizedSystem.aftSideSpanCount);

        actions.push(
            createSetSpanTowersActionV2(
                layoutId,
                systemId,
                {
                    forwardSpans: fwdSpans.map(x => {
                        return {
                            span: {
                                ...spanDefaults,
                                Length: x.lengthFeet,
                                Extension: x.extension,
                                dropSpanStartRelativeToPreviousSpanStart: x.dropSpan?.systemConfigurationHeadingFrom,
                                dropSpanEndRelativeToPreviousSpanEnd: x.dropSpan?.systemConfigurationHeadingTo
                            },
                            tower: {
                                ...towerDefaults
                            }
                        }
                    }),
                    aftSpans: aftSpans.map(x => {
                        return {
                            span: {
                                ...spanDefaults,
                                Length: x.lengthFeet,
                                Extension: x.extension,
                                dropSpanStartRelativeToPreviousSpanStart: x.dropSpan?.systemConfigurationHeadingFrom,
                                dropSpanEndRelativeToPreviousSpanEnd: x.dropSpan?.systemConfigurationHeadingTo
                            },
                            tower: {
                                ...towerDefaults
                            }
                        }
                    })
                },
                authState
            )
        )
        
        if (optimizedSystem.aftEndBoom) {
            actions.push(
                createSetEndOfSystemAction(
                    layoutId,
                    systemId,
                    'aft',
                    {
                        endOfSystemType: EndOfSystemTypes.EndBoom,
                        endBoomLengthFeet: optimizedSystem.aftEndBoom
                    },
                    authState
                )
            )
        }
        if (optimizedSystem.fwdEndBoom) {
            actions.push(
                createSetEndOfSystemAction(
                    layoutId,
                    systemId,
                    'fwd',
                    {
                        endOfSystemType: EndOfSystemTypes.EndBoom,
                        endBoomLengthFeet: optimizedSystem.fwdEndBoom
                    },
                    authState
                )
            )
        }

        {
            // end guns flanged side:
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
                    optimizedSystem.primaryEndGun,        
                    authState
                )
            )
            
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary",
                    optimizedSystem.secondaryEndGun,        
                    authState
                )
            )
            
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "endGuns.flangedSidePrimaryThrow",
                    optimizedSystem.primaryEndGunThrow,        
                    authState
                )
            )
            
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "endGuns.flangedSideSecondaryThrow",
                    optimizedSystem.secondaryEndGunThrow,        
                    authState
                )
            )
            
            // end guns flex side:
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "FlexSide.EndOfSystem.EndGun.EndGunTypePrimary",
                    optimizedSystem.primaryEndGun,        
                    authState
                )
            )
            
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "FlexSide.EndOfSystem.EndGun.EndGunTypeSecondary",
                    optimizedSystem.secondaryEndGun,        
                    authState
                )
            )
            
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "endGuns.flexSidePrimaryThrow",
                    optimizedSystem.primaryEndGunThrow,        
                    authState
                )
            )
            
            actions.push(
                createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "endGuns.flexSideSecondaryThrow",
                    optimizedSystem.secondaryEndGunThrow,        
                    authState
                )
            )
        }
    }
    

    return {
        systemId,
        actions,
    };
};