import { Feature, Geometry, MultiPolygon, Polygon, difference, feature, lineDistance, multiPolygon } from "@turf/turf";
import { v4 } from "uuid";
import { BoundaryHelper } from "../../GeometryHelpers/BoundaryHelper";
import { ObstacleHelper } from "../../GeometryHelpers/ObstacleHelper";
import { ESegmentClearanceType } from "../../GeometryHelpers/SegmentHelper";
import { staticDevSettingsDbProvider } from "../../db/DevSettingsDbProvider";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import IAnnotation, { annotationTypeToString } from "../../model/project/IAnnotation";
import IFieldBoundary from "../../model/project/IFieldBoundary";
import { IMeasurement } from "../../model/project/IMeasurement";
import IObstacle from "../../model/project/IObstacle";
import IPivotCenterBoundary from "../../model/project/IPivotCenterBoundary";
import IProject from "../../model/project/IProject";
import IWetAreaBoundary from "../../model/project/IWetAreaBoundary";
import { applyObstacleClearanceTo } from "../../optimization/helpers/base";

const createBufferedObstacleFeatures = (obstacle: IObstacle, properties: { [ name: string]: any } = {}) => {
    if (!staticDevSettingsDbProvider.mapSettings.showClearances.get()) return [];
    const features: Feature[] = [];
    const clearance = ObstacleHelper.getPolygonMinusClearancePolygon(obstacle);
    const sTowerClearance = ObstacleHelper.getClearancePolygonMinusSTowerClearance(obstacle);
    const hTowerClearance = ObstacleHelper.getClearancePolygonMinusHTowerClearance(obstacle);
    if (clearance) {
        const f = feature(
            clearance,
            {
                ...properties,
                rdpFeatureType: "obstacleClearance",
                rdpClearanceType: ESegmentClearanceType.EquipmentClearance
            }
        );
        features.push(f);
    }
    if (sTowerClearance) {
        const f = feature(
            sTowerClearance,
            {
                ...properties,
                rdpFeatureType: "obstacleClearance",
                rdpClearanceType: ESegmentClearanceType.STowerClearance
            }
        );
        features.push(f);
    }
    if (hTowerClearance) {
        const f = feature(
            hTowerClearance,
            {
                ...properties,
                rdpFeatureType: "obstacleClearance",
                rdpClearanceType: ESegmentClearanceType.HTowerClearance
            }
        );
        features.push(f);
    }
    return features;
}

export const createFieldSettingsBufferedObstacleFeatures = (p: Polygon, project: IProject, properties: { [ name: string]: any } = {}) => {
    if (!staticDevSettingsDbProvider.mapSettings.showClearances.get()) return [];
    const bufferedPolygon = applyObstacleClearanceTo(p, project.systemClearance);
    let noGoArea: Feature<Polygon | MultiPolygon> | null = bufferedPolygon.length === 1
        ? feature(bufferedPolygon[0])
        : multiPolygon(bufferedPolygon.map(x => x.coordinates));
    noGoArea = difference(noGoArea, p);
    if (!noGoArea) return [];
    noGoArea.properties = { ...properties }
    noGoArea.properties.rdpFeatureType = "obstacleClearance";
    noGoArea.properties.rdpClearanceType = ESegmentClearanceType.EquipmentClearance
    return [ noGoArea ];
}
const createBufferedBoundaryFeatures = (boundary: IFieldBoundary, properties: { [ name: string]: any } = {}) => {
    if (!staticDevSettingsDbProvider.mapSettings.showClearances.get()) return [];
    const features: Feature[] = [];
    const clearance = BoundaryHelper.getPolygonMinusClearancePolygon(boundary);
    const sTowerClearance = BoundaryHelper.getClearancePolygonMinusSTowerClearance(boundary);
    const hTowerClearance = BoundaryHelper.getClearancePolygonMinusHTowerClearance(boundary);
    if (clearance) {
        const f = feature(
            clearance,
            {
                ...properties,
                rdpFeatureType: "boundaryClearance",
                rdpClearanceType: ESegmentClearanceType.EquipmentClearance
            }
        );
        features.push(f);
    }
    if (sTowerClearance) {
        const f = feature(
            sTowerClearance,
            {
                ...properties,
                rdpFeatureType: "boundaryClearance",
                rdpClearanceType: ESegmentClearanceType.STowerClearance
            }
        );
        features.push(f);
    }
    if (hTowerClearance) {
        const f = feature(
            hTowerClearance,
            {
                ...properties,
                rdpFeatureType: "boundaryClearance",
                rdpClearanceType: ESegmentClearanceType.HTowerClearance
            }
        );
        features.push(f);
    }
    return features;
}

export const createFeatureFromGeometry = (geometry: Geometry, featureType: string, title?: string, fid = v4()) => {
    let feature = {
        type: "Feature",
        geometry: geometry,
        id: fid,
        properties: {
            rdpFeatureType: featureType
        },
    } as Feature;

    if (title)
    {
        feature.properties!["title"] = title;
    }
    return feature;
}

const getObstacleDrawFeature = (obstacle: IObstacle, obstacleIndex: number): Feature<Polygon> => {
    const p = ObstacleHelper.getPolygon(obstacle);
    const f = createFeatureFromGeometry(p, "obstacle", undefined, `obstacle-${obstacleIndex}`) as Feature<Polygon>;
    f.properties.obstacleIndex = obstacleIndex;
    f.properties.definition = obstacle;
    return f;
}

const getWheelObstacleDrawFeature = (obstacle: IObstacle, wheelObstacleIndex: number): Feature<Polygon> => {
    const p = ObstacleHelper.getPolygon(obstacle);
    const f = createFeatureFromGeometry(p, "wheelObstacle", undefined, `wheelObstacle-${wheelObstacleIndex}`) as Feature<Polygon>;
    f.properties.wheelObstacleIndex = wheelObstacleIndex;
    f.properties.definition = obstacle;
    return f;
}

const getFieldBoundaryDrawFeatures = (boundary: IFieldBoundary): Feature[] => {
    const b = createFeatureFromGeometry(BoundaryHelper.getPolygon(boundary), "fieldBoundary", undefined, 'fieldBoundary');
    b.properties.definition = boundary;
    return [
        b,
        ...createBufferedBoundaryFeatures(boundary, { parentId: b.id })
    ];
}

const getWetAreaBoundaryDrawFeatures = (boundary: IWetAreaBoundary): Feature[] => {
    const b = createFeatureFromGeometry(BoundaryHelper.getPolygon(boundary), "wetAreaBoundary", undefined, 'wetAreaBoundary');
    b.properties.definition = boundary;
    return [
        b,
        // wet area boundary should not have any clearances
        ...createBufferedBoundaryFeatures(boundary, { parentId: b.id }) 
    ];
}

const getPivotCenterBoundaryDrawFeatures = (boundary: IPivotCenterBoundary): Feature[] => {
    const b = createFeatureFromGeometry(BoundaryHelper.getPolygon(boundary), "pivotCenterBoundary", undefined, 'pivotCenterBoundary');
    b.properties.definition = boundary;
    return [
        b,
        ...createBufferedBoundaryFeatures(boundary, { parentId: b.id })
    ];
}

const getAnnotationDrawFeature = (annotation: IAnnotation, annotationIndex: number): Feature => {
    let label = annotationTypeToString(annotation.type, false).replace(/ /g,'').toLowerCase();
    let f: Feature;
    if (annotation.textAnnotation) {
        f = createFeatureFromGeometry(annotation.textAnnotation!.point, label, annotation.textAnnotation!.text, `annotation-${annotationIndex}`);
    }
    else if (annotation.pointAnnotation) {
        f = createFeatureFromGeometry(annotation.pointAnnotation!.point, label, annotation.pointAnnotation.text, `annotation-${annotationIndex}`);
    }
    else {
        f = createFeatureFromGeometry(annotation.lineAnnotation!.line, label, annotation.lineAnnotation.text, `annotation-${annotationIndex}`);
    }
    f.properties.annotationIndex = annotationIndex;
    return f;
}
const getMeasurementDrawFeature = (measurement: IMeasurement, measurementIndex: number): Feature => {
    const f: Feature = createFeatureFromGeometry(
        measurement.line, 
        "measurement", 
        "", 
        `measurement-${measurementIndex}`
    );
    const label = new DisplayLengthUnitBuilder(lineDistance(f, { units: 'feet' }), 'feet')
        .convert(staticDevSettingsDbProvider.display.get().lengths)
        .appendValue(2)
        .appendString(" ")
        .appendShortName()
        .toString()
    f.properties.measurementIndex = measurementIndex;
    f.properties.label = label;
    return f;
}

export const getProjectDrawFeatures = (project: IProject): Feature[] => {
    const r: Feature[] = [];
    return r;
}

export const getLayoutDrawFeatures = (project: IProject, layoutId: string): Feature[] => {
    const r: Feature[] = [];
    const layout = project.layouts[layoutId];
    if (layout.fieldBoundary) {
        const isEditable = Object.keys(layout.systems).length === 0; // Editable if there are no systems in the layout
        r.push(...getFieldBoundaryDrawFeatures(layout.fieldBoundary).map(x => {
            x.properties.rdpIsEditable = isEditable;
            return x;
        }));
    }
    if (layout.pivotCenterBoundary) {
        r.push(...getPivotCenterBoundaryDrawFeatures(layout.pivotCenterBoundary));
    }
    if (layout.wetAreaBoundary) {
        r.push(...getWetAreaBoundaryDrawFeatures(layout.wetAreaBoundary));
    }
    let i = 0;
    for (let obstacleIndex = 0; obstacleIndex < layout.obstacles.length; obstacleIndex++) {
        const obstacle = layout.obstacles[obstacleIndex];
        const o = getObstacleDrawFeature(obstacle, obstacleIndex);
        r.push(o);
        r.push(...createBufferedObstacleFeatures(obstacle, {
            parentId: o.id
        }));
        i++;
    }
    for (let wheelObstacleIndex = 0; wheelObstacleIndex < layout.wheelObstacles.length; wheelObstacleIndex++) {
        const wheelObstacle = layout.wheelObstacles[wheelObstacleIndex];
        const o = getWheelObstacleDrawFeature(wheelObstacle, wheelObstacleIndex);
        r.push(o);
        r.push(...createBufferedObstacleFeatures(wheelObstacle, {
            parentId: o.id
        }));
        i++;
    }
    for (let annotationIndex = 0; annotationIndex < layout.annotations.length; annotationIndex++) {
        const annotation = layout.annotations[annotationIndex];
        r.push(getAnnotationDrawFeature(annotation, annotationIndex));
        i++;
    }
    for (let measurementIndex = 0; measurementIndex < layout.measurements.length; measurementIndex++) {
        const measurement = layout.measurements[measurementIndex];
        r.push(getMeasurementDrawFeature(measurement, measurementIndex));
        i++;
    }
    return r;
}