import { Box, Card, CardContent, Typography } from '@mui/material';
import i18next, { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import { isCenterPivot } from "rdptypes/helpers/system";
import * as React from "react";
import { FC } from 'react';
import { IrrigatedAreaHelper } from "../../../GeometryHelpers/IrrigatedAreaHelper";
import LayoutMap from '../../../components/LayoutMap';
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { DisplayAreaUnitBuilder } from "../../../helpers/areas";
import { IProjectWithId } from "../../../routes/pages/ProjectList";
import { formatDateShort } from "../../DocumentGenerationHelpers";
import { documentStyles, primaryColor } from "../../styles";
import DealerInformationLine from "./DealerInformationLine";

interface Props {
    layoutId: string;
    systemIds: string[];
    grower: IDbGrower;
    pwid: IProjectWithId;
    insideFullReport: boolean;
    showLogoInsteadOfMiniMap: boolean;
}

const SimpleSystemContent: FC<Props> = React.forwardRef((props) => {
    const {layoutId, grower, pwid, systemIds, insideFullReport, showLogoInsteadOfMiniMap} = props;
    const reinkeLogo = new URL('../../../assets/reinkeLogo/whiteFull.svg', import.meta.url);

    const irrigatedAreaHelper = React.useMemo(() => {
        const iah = new IrrigatedAreaHelper({
            project: pwid.project, layoutId
        })
        return iah;
    }, [ pwid.project, layoutId])

    const getLine = (title: string, value: string, indent: boolean) => {
        return <div style={{marginBottom: "0.25em", marginLeft: indent ? "1.5em" : 0}}><strong style={{textDecoration: "underline"}}>{title}:</strong> {value}</div>;
    }
    
    let layout = pwid.project.layouts[layoutId];

    const settings = React.useContext(DevSettingsCtx);

    let fieldArea = new DisplayAreaUnitBuilder(irrigatedAreaHelper.getFieldAcres(), 'acres')
      .convert(settings.dealerSettings.display.current.areas)
      .appendValue(2)
      .appendString(" ")
      .appendFullName()
      .toString();

    const ns = "reports.";

    let systemArea = 0;
    let primaryEndGunArea = 0;
    let swingArmArea = 0;
    let wrapSpanArea = 0;
    let dropSpanArea = 0;
    let secondaryEndGunArea = 0;
    let overlappingArea = 0;

    let lateralCount = 0;
    
    systemIds.forEach((systemId) => {
        const rdpSystem = layout.systems[systemId];
        const systemIrrigatedInfo = irrigatedAreaHelper.getSystem(systemId);

        if (isCenterPivot(rdpSystem)) {
            systemArea += systemIrrigatedInfo.centerPivotInfo.pivotAreaAcres;
            swingArmArea += systemIrrigatedInfo.centerPivotInfo.swingArmArea;

            systemIrrigatedInfo.centerPivotInfo.endGunInfo.forEach((eg) => {
                let area = 0;
                eg.areas.forEach((a) => {
                    area += a;
                });

                if (eg.isPrimary){
                    primaryEndGunArea += area;
                }
                else {
                    secondaryEndGunArea += area;
                }
            });

            let wrapAcres = typeof(systemIrrigatedInfo.centerPivotInfo.wrapSpanAcres) === "undefined" ? 0 : systemIrrigatedInfo.centerPivotInfo.wrapSpanAcres;
            wrapSpanArea += wrapAcres;

            let dropSpanAcres = typeof(systemIrrigatedInfo.centerPivotInfo.dropSpanArea) === "undefined" ? 0 : systemIrrigatedInfo.centerPivotInfo.dropSpanArea;
            dropSpanArea += dropSpanAcres;
        }
        else {
            //Lateral specific information
            lateralCount ++;
            systemArea += systemIrrigatedInfo.lateralInfo.flangedArea;
            systemArea += systemIrrigatedInfo.lateralInfo.flexArea;

            systemIrrigatedInfo.lateralInfo.flangedEndGunInfo.forEach((eg) => {
                let area = 0;
                eg.areas.forEach((a) => {
                    area += a;
                });
                if (eg.isPrimary){
                    primaryEndGunArea += area;
                }
                else {
                    secondaryEndGunArea += area;
                }
            });

            systemIrrigatedInfo.lateralInfo.flexEndGunInfo.forEach((eg) => {
                let area = 0;
                eg.areas.forEach((a) => {
                    area += a;
                });
                if (eg.isPrimary){
                    primaryEndGunArea += area;
                }
                else {
                    secondaryEndGunArea += area;
                }
            });

            let dropSpanAcres = typeof(systemIrrigatedInfo.lateralInfo.flangedDropSpanArea) === "undefined" ? 0 : systemIrrigatedInfo.lateralInfo.flangedDropSpanArea;
            dropSpanArea += dropSpanAcres;
            dropSpanAcres = typeof(systemIrrigatedInfo.lateralInfo.flexDropSpanArea) === "undefined" ? 0 : systemIrrigatedInfo.lateralInfo.flexDropSpanArea;
            dropSpanArea += dropSpanAcres;
        }
        if (isCenterPivot(rdpSystem) && systemIrrigatedInfo.overlappingArea > 0) {
            //don't do anything with this at the moment but just show that we can keep track of overlapping area
            overlappingArea += systemIrrigatedInfo.overlappingArea;
        }
    });

    let allLaterals = systemIds.length === lateralCount;

    let sA = new DisplayAreaUnitBuilder(systemArea, 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(systemArea > 0 ? 2 : 0)
        .appendString(" ")
        .appendFullName()
        .toString();

    let peA = new DisplayAreaUnitBuilder(primaryEndGunArea, 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(primaryEndGunArea > 0 ? 2 : 0)
        .appendString(" ")
        .appendFullName()
        .toString();

    let seA = new DisplayAreaUnitBuilder(secondaryEndGunArea, 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(secondaryEndGunArea > 0 ? 2 : 0)
        .appendString(" ")
        .appendFullName()
        .toString();

    let saA = new DisplayAreaUnitBuilder(swingArmArea, 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(swingArmArea > 0 ? 2 : 0)
        .appendString(" ")
        .appendFullName()
        .toString();

    let dsA = new DisplayAreaUnitBuilder(dropSpanArea, 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(dropSpanArea > 0 ? 2 : 0)
        .appendString(" ")
        .appendFullName()
        .toString();

    let wsA = new DisplayAreaUnitBuilder(wrapSpanArea, 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(wrapSpanArea > 0 ? 2 : 0)
        .appendString(" ")
        .appendFullName()
        .toString();

    let totalIA = new DisplayAreaUnitBuilder(irrigatedAreaHelper.getIrrigatedAcres(systemIds), 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(irrigatedAreaHelper.getIrrigatedAcres(systemIds) > 0 ? 2 : 0)
        .appendString(" ")
        .appendFullName()
        .toString();

    let header = t("common-phrases.sltDesignDetails");
    if (systemIds.length === 1){
        header += " - " + layout.systems[systemIds[0]].name;
    }

    let table = 
        <Box style={{border: "1px solid black", marginBottom: 20}}>
            <Box style={documentStyles.heading}>
                <Box style={{...documentStyles.sectionHeader, fontSize: 14}}>{header}</Box>
            </Box>
            <Box style={{paddingLeft: 15, paddingRight: 15}}>
                <Box style={{...documentStyles.table}}>
                    <Box style={{...documentStyles.row}}>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('grower'), 'capitalize')}`}</Typography>
                            <Typography>{`: ${grower.name}`}</Typography>
                        </Box>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t(ns + 'field-area'), 'capitalize-each')}`}</Typography>
                            <Typography>{`: ${fieldArea}`}</Typography>
                        </Box>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('s-tower'), 'capitalize-each')}`}</Typography>
                            <Typography>{`: ${allLaterals ? "N/A" : saA}`}</Typography>
                        </Box>
                    </Box>
                    <Box style={{...documentStyles.row}}>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('date'), 'capitalize')}`}</Typography>
                            <Typography>{`: ${formatDateShort(new Date())}`}</Typography>
                        </Box>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('map-display.irrigated-area'), 'capitalize-each')}`}</Typography>
                            <Typography>{`: ${totalIA}`}</Typography>
                        </Box>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('wrap-span'), 'capitalize-each')}`}</Typography>
                            <Typography>{`: ${allLaterals ? "N/A" : wsA}`}</Typography>
                        </Box>
                    </Box>
                    <Box style={{...documentStyles.row}}>
                    <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('field'), 'capitalize')}`}</Typography>
                            <Typography>{`: ${pwid.project.name}`}</Typography>
                        </Box>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('system'), 'capitalize-each')}`}</Typography>
                            <Typography>{`: ${sA}`}</Typography>
                        </Box>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('drop-span'), 'capitalize-each')}`}</Typography>
                            <Typography>{`: ${dsA}`}</Typography>
                        </Box>
                    </Box>        
                    <Box style={{...documentStyles.row}}>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('layout'), 'capitalize')}`}</Typography>
                            <Typography>{`: ${layout.name}`}</Typography>
                        </Box>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('common-phrases.sltPrimaryEndGun'), 'capitalize-each')}`}</Typography>
                            <Typography>{`: ${peA}`}</Typography>
                        </Box>
                        <Box style={{...documentStyles.cell, width: "33%", justifyContent: "left", alignItems: "baseline"}}>
                            <Typography fontWeight="bold" fontFamily="Arial Black" fontSize={14}>{`${i18next.format(t('common-phrases.sltSecondaryEndGun'), 'capitalize-each')}`}</Typography>
                            <Typography>{`: ${seA}`}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
    </Box>;

    let content = <div>
        {table}
        <Box
            className="mapClass"
            sx={{
                display: "flex",
                height: 585,
                overflow: "hidden",
                justifyContent: "center"
            }}>
            <Box sx={{ width: 600, position: "relative" }}>
                <LayoutMap
                    projectId={pwid.id!}
                    layoutId={layoutId!}
                    interactive={false}
                    activeSystemIds={systemIds}
                />
            </Box>
        </Box>
    </div>;

    if (insideFullReport){
        return <>{content}</>;
    }

    return (
        <div className="printable-page" style={{height: "279mm", width: "216mm", fontFamily: "Arial"}}>
            <Card style={{width: "100%", height: "279mm", position: "relative"}}>
                <CardContent style={{padding: 0}}>
                    <Box style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 15, padding: 30, backgroundColor: primaryColor}}>
                        <Box style={{flex: 2}}>
                            <Typography style={{color: "white", fontWeight: "bold", fontFamily: "Arial Black"}}>{i18next.format(t("reports.general-field-design"), "uppercase")}</Typography>
                        </Box>
                        <Box style={{flex: 1}}>
                            <img src={reinkeLogo.toString()} style={{ width: 100, float: "right" }} />
                        </Box>
                    </Box>
                    <Box style={{padding: 15}}>
                        {content}
                    </Box>
                    <DealerInformationLine/>
                </CardContent>
            </Card>
        </div>);
});

export default SimpleSystemContent;