import { Box, Card, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { useContext, useState } from "react";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import AuthCtx from "../../auth/AuthCtx";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import IDbProject from "../../db/IDbProject";
import { FEXContext } from "../../foreignExchange";
import { IPricingHelperResult, pricingHelper } from "../../helpers/pricingHelper";
import { CustomerPriceAmmendmentType, getCpatFromPricing } from "../../helpers/pricingHelper/priceComputation";
import DealerDataCtx from "../../userData/DealerDataCtx";
import { pageStyles } from "../roe/styles";

interface Props {
    proposalSettings?: {
        layoutId: string;
        systemId: string;
        dbPrj: IDbProject;
    }
}

const CustomerSettingsRenderer: React.FC<Props> = (props) => {
    const ns = "dealer-settings.";
    const subns = "customer-settings-options.";
        
    const authState = useContext(AuthCtx);
    const devSettingsState = useContext(DevSettingsCtx);
    const fex = useContext(FEXContext);
    const user = useContext(DealerDataCtx);
    let withinProposal = typeof props.proposalSettings !== "undefined";

    let ph: IPricingHelperResult | undefined = undefined;
    let cpat: CustomerPriceAmmendmentType;
    let num_: number;
    let currency: string;
    let systemIsTaxable: boolean;
    let freightIsTaxable: boolean;
    let installationIsTaxable: boolean;
    let pivotPadIsTaxable: boolean;
    let tradeinIsTaxable: boolean;
    let salesTaxPercent: number;
    let onlyPrintTotalPrice: boolean;
    let printListPrice: boolean;
    let majorUnitsCurrency: string;
    if (withinProposal) {
        const system =  props.proposalSettings.dbPrj.state.layouts[props.proposalSettings.layoutId].systems[props.proposalSettings.systemId];
        ph = pricingHelper({ system, user, fex });
        systemIsTaxable = ph.system.isTaxable;
        freightIsTaxable = ph.freight.isTaxable;
        installationIsTaxable = ph.installation.isTaxable;
        pivotPadIsTaxable = ph.pivotPad.isTaxable;
        tradeinIsTaxable = ph.tradeIn.isTaxable;
        salesTaxPercent = system.QuoteProperties.ProposalInformation.pricing.salesTaxPercent;
        onlyPrintTotalPrice = system.QuoteProperties.ProposalInformation.pricing.onlyPrintTotalPrice;
        printListPrice = system.QuoteProperties.ProposalInformation.pricing.printListPrice;

        cpat = ph.priceComputation.customerPriceAmmendmentType;
        num_ = ph.priceComputation.value;
        currency = ph.localCurrency?.symbol || "USD";
        majorUnitsCurrency = ph.priceComputation.currency;
    }
    else {
        systemIsTaxable = devSettingsState.dealerSettings.customer.custom.formState.systemIsTaxable;
        freightIsTaxable = devSettingsState.dealerSettings.customer.custom.formState.freightIsTaxable;
        installationIsTaxable = devSettingsState.dealerSettings.customer.custom.formState.installationIsTaxable;
        pivotPadIsTaxable = devSettingsState.dealerSettings.customer.custom.formState.pivotPadIsTaxable;
        tradeinIsTaxable = devSettingsState.dealerSettings.customer.custom.formState.tradeinIsTaxable;
        salesTaxPercent = devSettingsState.dealerSettings.customer.custom.formState.salesTaxPercent;
        onlyPrintTotalPrice = devSettingsState.dealerSettings.customer.custom.formState.onlyPrintTotalPrice;
        printListPrice = devSettingsState.dealerSettings.customer.custom.formState.printListPrice;

        const listPriceDiscountPercent = devSettingsState.dealerSettings.customer.custom.formState.listPriceDiscountPercent;
        const markupPercentOverDealerCost = devSettingsState.dealerSettings.customer.custom.formState.markupPercentOverDealerCost;
        const markupDollarOverDealerCost = devSettingsState.dealerSettings.customer.custom.formState.markupDollarOverDealerCost;
        const quotedPriceDollar = undefined;
        const cpatFromPricing = getCpatFromPricing(
            listPriceDiscountPercent, markupPercentOverDealerCost,
            quotedPriceDollar, markupDollarOverDealerCost
        )
        cpat = cpatFromPricing.cpat;
        num_ = cpatFromPricing.value;
        currency = devSettingsState.dealerSettings.customer.custom?.formState?.currency;
        majorUnitsCurrency = currency;
    }

    const [customerPriceAmmendmentType, setCustomerPriceAmendmentType] = useState(cpat);
    const [num, setNum] = useState(num_)

    return (
        <Box sx={{ overflow: "auto", padding: 2 }}>
            {
                withinProposal && <Typography style={{marginBottom: 10}}>
                    The following settings will apply to this proposal <em>only</em>. You can modify your defaults from within dealer settings.
                </Typography>
            }
            <FormControl style={{ marginBottom: 20 }}>
                <InputLabel id="currency-select-label">{t("currency")}</InputLabel>
                <Select
                    value={currency || "USD"}
                    labelId="currency-select-label"
                    id="currency-select"
                    label={t("currency")}
                    onChange={(e) => {
                        if (withinProposal) {
                            ph?.setLocalCurrency(
                                e.target.value, 
                                props.proposalSettings.dbPrj,
                                props.proposalSettings.layoutId,
                                props.proposalSettings.systemId,
                                authState
                            )
                        }
                        else {
                            let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                            settings.currency = e.target.value;
                            devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                        }
                    }}
                >
                    {
                        Object.entries(fex.symbols).map(([ currencyCode, currencyString ]) => <MenuItem key={currencyCode} value={currencyCode}>{`${currencyCode} (${currencyString})`}</MenuItem>)
                    }
                </Select>
            </FormControl>
            <Card variant="outlined" style={{ width: "fit-content", marginBottom: 20, padding: 10 }}>
                <Typography sx={pageStyles.cardTitleStyle}>
                    {t(ns + subns + "price-computation")}
                </Typography>

                <Stack direction="row" style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
                    <RadioGroup 
                        value={customerPriceAmmendmentType}
                        onChange={(e) => {
                            if(withinProposal) {
                                ph.priceComputation.resetCpat(
                                    props.proposalSettings.dbPrj, 
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    authState
                                );
                            }
                            else {
                                //reset
                                let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                                settings.markupPercentOverDealerCost = 0;
                                settings.markupDollarOverDealerCost = 0;
                                settings.listPriceDiscountPercent = 0;
                                devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                            }
                            setNum(0);

                            if (e.target.value === "listPrice") setCustomerPriceAmendmentType(CustomerPriceAmmendmentType.listPriceDiscountPercent);
                            else if (e.target.value === "markupPercentOverDealerCostPc") setCustomerPriceAmendmentType(CustomerPriceAmmendmentType.markupOverDealerCostPercent);
                            else if (e.target.value === "markupPercentOverDealerCostDollar") setCustomerPriceAmendmentType(CustomerPriceAmmendmentType.markupOverDealerCostDollar);
                            else if (e.target.value === "quotedPriceDollar") setCustomerPriceAmendmentType(CustomerPriceAmmendmentType.quotedPriceDollar);
                        }}
                    >
                        <FormControlLabel 
                            value="listPrice" 
                            control={<Radio />} 
                            label={t(ns + subns + "discount-off-list-price-pc")} 
                        />
                        <FormControlLabel 
                            value="markupPercentOverDealerCostPc" 
                            control={<Radio />} 
                            label={t(ns + subns + "markup-over-dealer-cost-pc")} 
                        />
                        <FormControlLabel 
                            value="markupPercentOverDealerCostDollar" 
                            control={<Radio />} 
                            label={`${t(ns + subns + "markup-over-dealer-cost-major-units")} ${majorUnitsCurrency}`} 
                        />
                        {
                            withinProposal && (
                                <FormControlLabel 
                                    value="quotedPriceDollar" 
                                    control={<Radio />} 
                                    label={`${t(ns + subns + "quoted-price-major-units")} ${majorUnitsCurrency}`} 
                                />
                            )
                        }
                    </RadioGroup>
                    <TextField
                        sx={{ marginRight: 5 }}
                        type="number"
                        value={num}
                        onChange={(e) => {
                            setNum(parseFloat(e.target.value));
                        }}
                        onBlur={(e) => {
                            if (withinProposal){
                                //update proposal
                                ph.priceComputation.setCpat(
                                    num, customerPriceAmmendmentType,
                                    props.proposalSettings.dbPrj,
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    authState
                                );
                            }
                            else {
                                //update dev settings
                                let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                                settings.listPriceDiscountPercent = 0;
                                settings.markupPercentOverDealerCost = 0;
                                settings.markupDollarOverDealerCost = 0;
    
                                if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.listPriceDiscountPercent) {
                                    settings.listPriceDiscountPercent = num;
                                }
                                else if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.markupOverDealerCostPercent) {
                                    settings.markupPercentOverDealerCost = num;
                                }
                                else if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.markupOverDealerCostDollar) {
                                    settings.markupDollarOverDealerCost = num;
                                }
                                devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                            }
                        }}
                    />
                </Stack>
            </Card>
            <Card variant="outlined" style={{ marginBottom: 20, padding: 10 }}>
                    <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={printListPrice} onChange={(e) => {
                        if (withinProposal){
                            props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                props.proposalSettings.layoutId,
                                props.proposalSettings.systemId,
                                "QuoteProperties.ProposalInformation.pricing.printListPrice",
                                e.target.checked,
                                authState));
                        }
                        else {
                            let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                            settings.printListPrice = e.target.checked;
                            devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                        }
                    }} />} label={t(ns + subns + "print-list-price")} />
                    <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={onlyPrintTotalPrice} onChange={(e) => {
                        if (withinProposal){
                            props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                props.proposalSettings.layoutId,
                                props.proposalSettings.systemId,
                                "QuoteProperties.ProposalInformation.pricing.onlyPrintTotalPrice",
                                e.target.checked,
                                authState));
                        }
                        else {
                            let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                            settings.onlyPrintTotalPrice = e.target.checked;
                            devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                        }
                    }} />} label={t(ns + subns + "only-print-total-price")} />
            </Card>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <TextField
                    sx={{ marginRight: 5 }}
                    type="number"
                    label={t(ns + subns + "sales-tax-pc")}
                    value={salesTaxPercent}
                    onChange={(e) => {
                        let numVal = parseFloat(e.target.value);//TODO: validate number
                        if (withinProposal){
                            props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                props.proposalSettings.layoutId,
                                props.proposalSettings.systemId,
                                "QuoteProperties.ProposalInformation.pricing.salesTaxPercent",
                                numVal,
                                authState));
                        }
                        else {
                            let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                            settings.salesTaxPercent = numVal;
                            devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                        }
                    }}
                />
            </Box>
            <Card variant="outlined" style={{ marginTop: 20, padding: 10 }}>
                <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={systemIsTaxable} onChange={(e) => {
                    if (withinProposal){
                        ph.system.saveIsTaxable(
                            e.target.checked,
                            props.proposalSettings.dbPrj,
                            props.proposalSettings.layoutId,
                            props.proposalSettings.systemId,
                            authState
                        );
                    }
                    else {
                        let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                        settings.systemIsTaxable = e.target.checked;
                        devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                    }
                }} />} label={t(ns + subns + "system-taxable")} />
                <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={installationIsTaxable} onChange={(e) => {
                    if (withinProposal){
                        ph.installation.saveIsTaxable(
                            e.target.checked,
                            props.proposalSettings.dbPrj,
                            props.proposalSettings.layoutId,
                            props.proposalSettings.systemId,
                            authState
                        );
                    }
                    else {
                        let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                        settings.installationIsTaxable = e.target.checked;
                        devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                    }
                }} />} label={t(ns + subns + "installation-taxable")} />
                <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={freightIsTaxable} onChange={(e) => {
                    if (withinProposal){
                        ph.freight.saveIsTaxable(
                            e.target.checked,
                            props.proposalSettings.dbPrj,
                            props.proposalSettings.layoutId,
                            props.proposalSettings.systemId,
                            authState
                        );
                    }
                    else {
                        let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                        settings.freightIsTaxable = e.target.checked;
                        devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                    }
                }} />} label={t(ns + subns + "freight-taxable")} />
                <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={pivotPadIsTaxable} onChange={(e) => {
                    if (withinProposal){
                        ph.pivotPad.saveIsTaxable(
                            e.target.checked,
                            props.proposalSettings.dbPrj,
                            props.proposalSettings.layoutId,
                            props.proposalSettings.systemId,
                            authState
                        );
                    }
                    else {
                        let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                        settings.pivotPadIsTaxable = e.target.checked;
                        devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                    }
                }} />} label={t(ns + subns + "pivot-pad-taxable")} />
                <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={tradeinIsTaxable} onChange={(e) => {
                    if (withinProposal) {
                        ph.tradeIn.saveIsTaxable(
                            e.target.checked,
                            props.proposalSettings.dbPrj,
                            props.proposalSettings.layoutId,
                            props.proposalSettings.systemId,
                            authState
                        );
                    }
                    else {
                        let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                        settings.tradeinIsTaxable = e.target.checked;
                        devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                    }
                }} />} label={t(ns + subns + "system-trade-in-taxable")} />
            </Card>
        </Box>
    );
}

export default CustomerSettingsRenderer;