import DeleteIcon from '@mui/icons-material/Delete';
import { Checkbox, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import * as React from "react";
import { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { getCurrencySymbol } from '../../../../docGeneration/DocumentGenerationHelpers';
import { IPricingHelperMiscItem } from '../../../../helpers/pricingHelper/miscItemsPricing';
import DollarFormattedInput from "../DollarFormattedInput";

interface Props {
    idx: number;
    deleteMiscItem: (id: string) => void;
    saveMiscItem: (item: IPricingHelperMiscItem) => void;
    miscItem: IPricingHelperMiscItem | null;
    itemCurrency: string;
};

const MiscItemRow: FC<Props> = (props) => {
    const {miscItem, idx, deleteMiscItem, saveMiscItem} = props;
    const [description, setDescription] = useState(miscItem ? miscItem.description : "");
    const [priceCurrency, setPriceCurrency] = useState<number| null>(miscItem ? miscItem.priceMinorUnits / 100 : null);
    const [taxable, setIsTaxable] = useState(miscItem ? miscItem.taxable : false);
    const currencySymbol = getCurrencySymbol(props.itemCurrency);

    const saveMiscItem_ = (description_: string, priceCents_: number, taxable_: boolean) => {
        let priceCents = priceCents_ * 100;
        saveMiscItem({
            description: description_, 
            priceMinorUnits: priceCents, 
            taxable: taxable_, 
            id: miscItem?.id ?? uuidv4()
        });
    }

    return (
        <>
            <TableRow>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>
                    <TextField size="small" value={description} onChange={(e) => {setDescription(e.target.value)}} onBlur={(e) => {
                        let val = e.target.value;
                        let priceCents_ = priceCurrency;
                        if (priceCents_ === null) {
                            priceCents_ = 0;;
                        }
                        setDescription(val);
                        setPriceCurrency(priceCents_)
                        saveMiscItem_(val, priceCents_, taxable);
                    }}/>
                </TableCell>
                <TableCell>
                    <TextField size="small" value={priceCurrency === null ? "" : priceCurrency} InputProps={{
                        inputComponent: DollarFormattedInput as any, inputProps: { prefix: currencySymbol}}} onChange={(e) => {
                            if (e.target.value === ""){
                                setPriceCurrency(null);
                            }
                            let val = parseFloat(e.target.value);
                            if (isNaN(val)) return;
                            setPriceCurrency( val );
                        }} 
                        onBlur={(e) => {
                            let val = e.target.value;
                            let valNum = parseFloat(val.replace(currencySymbol, "").replace(",", ""));
                            if (val !== "" && isNaN(valNum)) return;

                            let stateVal = val === "" ? null : valNum;
                            let description_ = description === "" ? "Misc item description" : description;
                            setDescription(description_);
                            setPriceCurrency(stateVal === null ? 0 : stateVal);
                            saveMiscItem_(description_, stateVal, taxable);
                    }}/>
                </TableCell>
                <TableCell>
                    <Checkbox checked={taxable} onChange={(e) => {
                        let checked = e.target.checked;
                        let description_ = description === "" ? "Misc item description" : description;
                        let priceCents_ = priceCurrency === null ? 0 : priceCurrency;
                        setIsTaxable(checked);
                        saveMiscItem_(description_, priceCents_, checked);
                    }}/>
                </TableCell>
                <TableCell>
                    <IconButton aria-label="delete" disabled={miscItem === null} color="primary" onClick={() => {deleteMiscItem(miscItem?.id)}}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    );
};

export default MiscItemRow;