export { Cosineof162Degrees } from "rdptypes/helpers/CommonConstants.part";

export const MaxAmpsFor10GWire = 30;
export const MaxAmpsFor12GWire = 15;
export const MaximumSpans = 24;
export const MaxPackages = 3;

export enum SystemMotionEnum {
Pivot,
Lateral
}

export const MaxVRIZones = 150;
export const MaxControlledVRIZones = 84;
export const MAXVRIValveBoxes = MaxControlledVRIZones / 6; // as integer in VB : 84 / 6 = 14