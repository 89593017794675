import { Dialog, DialogTitle } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import IDbProject from "../../../../db/IDbProject";
import DealerPricingModalBody from "./DealerPricingModalBody";

interface Props {
    onClose: () => void;
    show: boolean;
    layoutId: string;
    systemId: string;
    dbPrj: IDbProject;
}

const DealerPricingModal: React.FC<Props> = ({
    onClose, show, layoutId, systemId, dbPrj
}) => {


    return (
        <Dialog
            open={show}
            onClose={() => { /* prevent close on backdrop click */ }}
        >
            <DialogTitle id="dealer-pricing-modal-title">
                {i18next.format(t('common-phrases.sltDealerPricing'), 'capitalize-each')}
            </DialogTitle>
            <DealerPricingModalBody proposalSettings={{layoutId, systemId, dbPrj}} onClose={onClose}/>
        </Dialog>
    );
}

export default DealerPricingModal;