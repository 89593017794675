// NOTE:
// The end of system can be set by side enum OR fwd/aft
// fwd/aft has been added, as when optimizing laterals, it is more
// logical to define the end of system in a direction, as the user
// has no way of knowing which side will be flange/flex before
// optimization
import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { getSwingArmAndEndBoomLengthFeet } from 'rdptypes/helpers/EndOfSystem';
import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import ISystem from "rdptypes/project/ISystem";
import { EndOfSystemTypes, ISystemBase, SwingArmLengths, SystemTypes } from 'rdptypes/project/ISystemBase.AutoGenerated';
import { ISide, ISpan } from 'rdptypes/project/Types';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';
import { renumberSpanAndTowers } from './helpers/spans';

const actionTypeId = "SetEndOfSystem";

interface IActionDataBase extends IActionData {
    layoutId: string;
    systemId: string;
    side: SideEnum | 'fwd' | 'aft';
}

type IActionDataData = {
    clear: true;
} | {
    endOfSystemType?: EndOfSystemTypes;
    endBoomLengthFeet?: number;
    swingArmLength?: SwingArmLengths;
    updateSacIsLeading?: {
        sacIsLeading: boolean;
    }
}
type ActionData = IActionDataBase & IActionDataData;

const isLastSpanEndOfSystemType = (spans: ISpan[]) => {
    if (!spans.length) return false;
    const lastSpan = spans[spans.length - 1];
    return lastSpan.EndBoom || lastSpan.SwingArm;
}
const removeEndOfSystemType = (system: ISystem, side: ISide) => {
    side.EndOfSystem.EndOfSystemType = undefined;
    side.EndOfSystem.SwingArmLength = undefined;
    while (isLastSpanEndOfSystemType(side.Span)) {
        side.Span.pop();
    }
    system.sacOptimizerResult = undefined;
    side.EndOfSystem.EndBoomLengthSelected = false;
}

const isLateral = (system: ISystemBase) => {
    const sysType = system.SystemProperties?.SystemType;
    return sysType === SystemTypes.CanalFeedMaxigator || sysType === SystemTypes.HoseFeedMaxigator;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const layout = state.layouts[data.layoutId];
    if (!layout){
        return;
    }
    const sys = layout.systems[data.systemId];

    let sideEnum: SideEnum;
    if (data.side === 'fwd') {
        if (isLateral(sys)) {
            sideEnum = sys.flexIsFwd ? SideEnum.Flex : SideEnum.Flanged;
        }
        else {
            sideEnum = SideEnum.Flanged;
        }
    }
    else if (data.side === 'aft') {
        if (isLateral(sys)) {
            sideEnum = sys.flexIsFwd ? SideEnum.Flanged : SideEnum.Flex;
        }
        else {
            // there is not a aft side of a lateral
            // silently fail
            return;
        }
    }
    else if (data.side === SideEnum.Flex) {
        sideEnum = SideEnum.Flex;
    }
    else if (data.side === SideEnum.Flanged) {
        sideEnum = SideEnum.Flanged;
    }
    else {
        // silently fail:
        return;
    }
    const side = getSide(sys, sideEnum);

    if ('clear' in data) {
        if (data.clear) {
            removeEndOfSystemType(sys, side);
        }
    }
    else {
        removeEndOfSystemType(sys, side);
        side.EndOfSystem.EndOfSystemType = data.endOfSystemType;

        switch (data.endOfSystemType) {
            case EndOfSystemTypes.EndBoom: {
                if (data.endBoomLengthFeet !== undefined) {
                    if (data.endBoomLengthFeet !== 0) {
                        side.Span.push({ EndBoom: true, Length: data.endBoomLengthFeet });
                    }
                    side.EndOfSystem.EndBoomLengthSelected = true;
                }
                break;
            }
            case EndOfSystemTypes.SAC: {
                side.EndOfSystem.SwingArmLength = data.swingArmLength ?? SwingArmLengths.None;
                const swingArmAndEndBoomLength = getSwingArmAndEndBoomLengthFeet(data.swingArmLength);
                if (swingArmAndEndBoomLength) {
                    side.Span.push({ SwingArm: true, Length: swingArmAndEndBoomLength.swingArmLengthFeet });
                    side.Span.push({ EndBoom: true, Length: swingArmAndEndBoomLength.endBoomLengthFeet });
                }
                if (data.updateSacIsLeading) {
                    sys.Circle.SwingArm.LeadingSwingArm = data.updateSacIsLeading.sacIsLeading;
                }
                break;
            }
            default:
                break;
        }
    }
    
    sys.sacOptimizerResult = undefined;

    renumberSpanAndTowers(side);
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});

export const createSetEndOfSystemAction = (
    layoutId: string,
    systemId: string,
    side: SideEnum | 'fwd' | 'aft',
    data: IActionDataData,
    authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            side,
            ...data
        } as ActionData,
        authState);