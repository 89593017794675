import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { DeviceTypes, IPackage, RegulatorPressures, RegulatorTypes, ThreadTypes } from "rdptypes/project/ISprinklers";
import ISystem from "rdptypes/project/ISystem";
import { SprinklerManufacturerTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { DeterminePackageTypeFromDeviceType } from "roedata/roe_migration/OtherHelpers";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { RegulatorTypeOptionKometOk, RegulatorTypeOptionNelsonOk, RegulatorTypeOptionSenningerOk, ThreadOptionNelsonOk } from "roedata/roe_migration/Valids.Valids";
import { setSprinklerPackage } from ".";
import { GetRegulatorOptionFromPackageType, GetRegulatorPressureFromRegulatorType } from "../../helpers/Rules";
import { getInitialPackageState } from "../../helpers/SprinklerHelper";
import { IFnExecutePropertyValueMap, IPropertyValueMap, ISprinklerPackageRegulatorsValidator, ISprinklerValidatorChoiceFieldWithSetAndClear } from "../interfaces";


const regulatorTypeValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<RegulatorTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.Regulator.RegulatorType;

    const getAllowableValues = () => {                   
        const packageType = DeterminePackageTypeFromDeviceType(sprinklerPackage);
        var regulatorTypeOptionsBase: RegulatorTypes[] = GetRegulatorOptionFromPackageType(packageType);  
        const regulatorTypeOptions = regulatorTypeOptionsBase.filter(x => {
            let ok: boolean;
            switch (sys.SprinklerProperties.SprinklerEngineCode) {
                case SprinklerManufacturerTypes.Nelson: {
                    ok = RegulatorTypeOptionNelsonOk(x, {
                        DeviceType: sprinklerPackage.Device,
                        HasSAC: HasSwingArmCorner(sys)
                    });
                    break;
                }
                case SprinklerManufacturerTypes.Senninger: {
                    ok = RegulatorTypeOptionSenningerOk(x, {
                        DeviceType: sprinklerPackage.Device,
                        HasSAC: HasSwingArmCorner(sys)
                    });
                    break;
                }
                case SprinklerManufacturerTypes.Komet: {
                    ok = RegulatorTypeOptionKometOk(x, {
                        DeviceType: sprinklerPackage.Device,
                        HasSAC: HasSwingArmCorner(sys)
                    });
                    break;
                }
            
                default:
                    ok = false;
                    break;
            }
            return ok;
        })            
        return regulatorTypeOptions;
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: RegulatorTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Regulator: {
                    ...sprinklerPackage.Regulator,
                    RegulatorType: v,
                    AsNeeded: (v ?? RegulatorTypes.None) === RegulatorTypes.None
                        ? false
                        : sprinklerPackage.Regulator.AsNeeded
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).Regulator.RegulatorType);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}

const pressureValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<RegulatorPressures> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.Regulator.Pressure;

    const getAllowableValues = () => {
        const pressureOptions: RegulatorPressures[] = GetRegulatorPressureFromRegulatorType(
            sprinklerPackage, 
            sys.SprinklerProperties.SprinklerEngineCode
        );       
        return pressureOptions;
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if ((sprinklerPackage.Regulator.RegulatorType ?? RegulatorTypes.None) === RegulatorTypes.None) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: RegulatorPressures) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Regulator: {
                    ...sprinklerPackage.Regulator,
                    Pressure: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).Regulator.Pressure);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}

const asNeededValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.Regulator.AsNeeded;

    const getAllowableValues = (): boolean[] => {
        if ((sprinklerPackage.Regulator.RegulatorType ?? RegulatorTypes.None) === RegulatorTypes.None) return [];
        if (sprinklerPackage.Device === DeviceTypes.Senninger12MediumSpacingPlasticImpact && !HasSwingArmCorner(sys)) {
            return [true];
        }
        return [false, true];
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if ((sprinklerPackage.Regulator.RegulatorType ?? RegulatorTypes.None) === RegulatorTypes.None) return false;
        return !allowableValues.includes(value || false)
    }
    const isError = getError();
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Regulator: {
                    ...sprinklerPackage.Regulator,
                    AsNeeded: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).Regulator.AsNeeded);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}

const threadValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<ThreadTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.Regulator.Thread;

    const getAllowableValues = () => {
        const threadTypes = [];
        [ThreadTypes.Square, ThreadTypes.Female].forEach(tt => {
            if(ThreadOptionNelsonOk(tt, {
                DeviceType: sprinklerPackage.Device,
                RegulatorType: sprinklerPackage.Regulator.RegulatorType,
                RegulatorPressure: sprinklerPackage.Regulator.Pressure,
                UseNelsonAD3030MT: sprinklerPackage.UseNelsonAD3030MT,
            })){
                threadTypes.push(tt);
            }
        });
        return threadTypes;
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if ((sprinklerPackage.Regulator.RegulatorType ?? RegulatorTypes.None) === RegulatorTypes.None) return false;
        if (sys.SprinklerProperties.SprinklerEngineCode !== SprinklerManufacturerTypes.Nelson) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: ThreadTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Regulator: {
                    ...sprinklerPackage.Regulator,
                    Thread: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).Regulator.Thread);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}

export const regulatorsValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerPackageRegulatorsValidator => {
    const options = {
        regulatorType: regulatorTypeValidator(sys, side, packageIdx, executePvm, excludeProperties),
        pressure: pressureValidator(sys, side, packageIdx, executePvm, excludeProperties),
        asNeeded: asNeededValidator(sys, side, packageIdx, executePvm, excludeProperties),
        thread: threadValidator(sys, side, packageIdx, executePvm, excludeProperties),
    }
    return {
        ...options,
        isError: Object.values(options).some(x => x.isError)
    }
}