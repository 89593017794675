import { Box, Card, Typography } from "@mui/material";
import i18next, { t } from "i18next";
import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import ISystem from "rdptypes/project/ISystem";
import IComponent from "rdptypes/roe/IComponent";
import IPage from "rdptypes/roe/IPage";
import * as React from "react";
import { partsPackages } from "roedata/data";
import { createNewUpdateSystemPropertyAction } from "../../../../../actions/UpdateSystemProperty";
import IAuthState from "../../../../../auth/IAuthState";
import { EndGunThrowSlider } from "../../../../../components/OptimizeSystemDialog/EndGunType";
import IComponentRenderContext from "../../../../../components/roe/componentRenderers/IComponentRenderContext";
import PageRenderer from "../../../../../components/roe/componentRenderers/PageRenderer";
import { pageStyles } from "../../../../../components/roe/styles";
import IDbProject from "../../../../../db/IDbProject";
import { EndGunLocation, getEndGunThrow, getThrowRangeFromEndGunType } from "../../../../../model/project/IEndGun";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    endGunComponent: IComponent;
    side: SideEnum;
    endGunLocation: EndGunLocation;
    system: ISystem;
    authState: IAuthState;
    ctx: IComponentRenderContext;
}

const EndGunForSide: React.FC<Props> = ({
    endGunComponent, endGunLocation, dbPrj, layoutId, systemId, system, authState, side, ctx
}) => {
    const { roeData } = partsPackages[system.partsPackageId];
    
    const title = endGunLocation === EndGunLocation.Primary
        ? i18next.format(t("common-phrases.sltPrimaryEndGun"), "capitalize-each")
        : i18next.format(t("common-phrases.sltSecondaryEndGun"), "capitalize-each");

    const systemSide = getSide(system, side);
    const throwFeet = getEndGunThrow(system, side, endGunLocation);
    const throwRange = endGunLocation === EndGunLocation.Primary
        ? getThrowRangeFromEndGunType(systemSide.EndOfSystem.EndGun.EndGunTypePrimary)
        : getThrowRangeFromEndGunType(systemSide.EndOfSystem.EndGun.EndGunTypeSecondary);
    let path: string;
    if (side === SideEnum.Flanged) {
        if (endGunLocation === EndGunLocation.Primary) {
            path = "endGuns.flangedSidePrimaryThrow";
        }
        else {
            path = "endGuns.flangedSideSecondaryThrow";
        }
    }
    else {
        if (endGunLocation === EndGunLocation.Primary) {
            path = "endGuns.flexSidePrimaryThrow";
        }
        else {
            path = "endGuns.flexSideSecondaryThrow";
        }
    }

    return (
        <Card style={{padding: 10, marginBottom: 14 }} variant="outlined">
            <Typography sx={pageStyles.cardTitleStyle}>{title}</Typography>
            <PageRenderer 
                page={{
                    position: 0,
                    title: "",
                    components: [ endGunComponent ]
                } as IPage} 
                rules={roeData.rules}
                dbPrj={dbPrj} 
                layoutId={layoutId} 
                systemId={systemId}
                ctx={ctx}
                oneColArrangement={true}
            />

            {/* Primary End Gun Throw */}
            {
                throwFeet && (
                    <Box px={1}>
                        <EndGunThrowSlider
                            value={throwFeet}
                            min={throwRange?.radiusOfThrowFeet.min}
                            max={throwRange?.radiusOfThrowFeet.max}
                            setValue={(value) => {
                                dbPrj.pushAction(
                                    createNewUpdateSystemPropertyAction(
                                        layoutId,
                                        systemId,
                                        path,
                                        value,
                                        authState
                                    )
                                )
                            }}
                        />
                    </Box>
                )
            }
        </Card>
    )
}

export default EndGunForSide;