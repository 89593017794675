import {
    BuckBoostTypes,
    CouplerTypes,
    EndTowerPositioningTypes,
    GearDriveTypes,
    GuidanceLocations,
    GuidanceTypes,
    HoseFeedTypes,
    PanelModels,
    SpanTypes,
    SystemTypes,
    TireSizes,
    TireTypes,
    TowerHeights,
    WaterFeedTypes,
    WheelGearSeries,
    WrapAroundSpanTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import { CenterDriveText, LeadType, TireAndWheelText, TireSelector } from "./CommonFunctions";
import * as hff from "./HoseFeedFunctions";
import * as ohf from "./OtherHelpers";
import PartsAssembler, { LocationCodes } from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import * as sysf from "./SystemFunctions";
import * as towerf from "./TowerFunctions";
import { BoxTypes, idxLeadType } from "./TowerFunctions";
import { strLanguageText } from "./TranslationsLib";
import { Side, Tower } from "./Types";
import { AgriTracOK, AquaValvesOK, GearDriveOK, HeavyDutyLegsOK, StiffenerStabilizersOK, StiffenerTrussToPipeOK, ThreeWheelFlexTowerOK, ThreeWheelTowerOK, TireSizeTowersOK, TireTypeOK, TowerAutoReverseOK, TowerAutoStopOK, TowerCrossBraceOK, TowerTypeOK, WrapAroundSpanOK } from "./Valids.Valids";
import { TowerTypeOKDto } from './Valids.dto.Valids';
import { StringFormat } from "./VbCompat";
export class TowersClass extends CSection2 {
    public Side: Side;
    private bRightSide: boolean;
    public bLeftSide: boolean;
    private bBBoxWarning: boolean;
    private bBBoxOrdered: boolean;
    private bPivotingKit;
    public StiffenedSpanType = (i: number): SpanTypes => {
        return this.Side.Span[this.StiffenedSpan(i) - 1].SpanType;
    }
    public StiffenedSpanLength = (i: number): number => {
        return this.Side.Span[this.StiffenedSpan(i) - 1].Length;
    }
    private StiffenedSpanEndingLocation = (i: number): number => {
        return spanf.EndingLocation(this.Quote.System, this.Side, this.Side.Span[this.StiffenedSpan(i) - 1]);
    }
    public StiffenedSpan = (i: number): number => {
        if (sysf.IsMaxigator(this.Quote.System) && this.bRightSide && i < this.Quote.RigidSpan()) {
            return i + 1;
        }
        else {
            return i;
        }
    }
    public CenterDriveType = (cCenterDriveType: string) => {
        if (cCenterDriveType === "M") {
            return cCenterDriveType;
        }
        let LastTower: number = sidef.NumberOfTowers(this.Side);
        for (let i = 1; i <= LastTower; i++) {
            const s = CenterDriveText(this.Side.Tower[i - 1].CenterDrive, sysf.IsEDMP(this.Quote.System));
            if (cCenterDriveType === "") {
                cCenterDriveType = s;
            }
            else if (cCenterDriveType !== s) {
                cCenterDriveType = "M";
                return cCenterDriveType;
            }
        }

        return cCenterDriveType;
    }
    public TiresType = (cTiresType: string) => {
        let s: string;
        let i: number;
        if (cTiresType === "M/Turf") {
            return cTiresType;
        }
        let LastTower: number = sidef.NumberOfTowers(this.Side);
        for (i = 1; i <= LastTower; i++) {
            s = TireAndWheelText(this.Side.Tower[i - 1].Tires);
            if (cTiresType === "") {
                return s;
            }
            else if (cTiresType !== s) {
                if (s.indexOf("Turf") !== -1 || cTiresType.indexOf("Turf") !== -1) {
                    return "M/Turf";
                }
                else {
                    return "M";
                }
            }
        }

        return cTiresType;
    }
    public TowersType = () => {
        let s: string;
        if (this.AllSugargators()) {
            s = "SG";
        }
        else if (this.AllLowProfiles()) {
            s = "LP";
        }
        else if (this.AllSupergators()) {
            s = "SP";
        }
        else {
            s = "G";
        }
        /*if (cTowerType === "") {
        cTowerType = s;
        }
        else if (cTowerType !== s) {
        cTowerType = "M";
        }*/

        return s;
    }
    public AnyWrapAroundSpans = (): boolean => {
        for (let i = 1; i <= sidef.NumberOfTowers(this.Side); i++) {
            if (this.Side.Tower[i - 1].WrapAroundSpan !== WrapAroundSpanTypes.None) {
                return true;
            }
        }
        return false;
    }

    public AnySugargators = (): boolean => {
        for (let i: number = 1; i <= sidef.NumberOfTowers(this.Side); i++) {
            if (this.Side.Tower[i - 1].TowerType === TowerHeights.Sugargator) {
                return true;
            }
        }
        return false;
    }

    public AnySupergators = (): boolean => {
        for (let i: number = 1; i <= sidef.NumberOfTowers(this.Side); i++) {
            if (this.Side.Tower[i - 1].TowerType === TowerHeights.Supergator) {
                return true;
            }
        }
        return false;
    }

    public TowerCount = (): number => {
        return sidef.NumberOfTowers(this.Side);
    }
    public Tower = (towerNumber: number): Tower => {
        return this.Side.Tower[towerNumber - 1];
    }
    public AnyLowProfiles = (): boolean => {
        for (let i: number = 1; i <= sidef.NumberOfTowers(this.Side); i++) {
            if (this.Side.Tower[i - 1].TowerType === TowerHeights.LowProfile) {
                return true;
            }
        }
        return false;
    }
/*public AnyAgriTrac = (): boolean => {
for (let i: number = 1; i <= sidef.NumberOfTowers(this.Side); i++
) {
if (this.Side.Tower[i - 1].AgriTrac) {
return true;
}
}
return false;
}
*/public AllSugargators = (): boolean => {
        for (let i: number = 1; i <= sidef.NumberOfTowers(this.Side); i++
        ) {
            if (this.Side.Tower[i - 1].TowerType !== TowerHeights.Sugargator) {
                return false;
            }
        }
        return true;
    }
    public AllSupergators = (): boolean => {
        for (let i: number = 1; i <= sidef.NumberOfTowers(this.Side); i++
        ) {
            if (this.Side.Tower[i - 1].TowerType !== TowerHeights.Supergator) {
                return false;
            }
        }
        return true;
    }
    public IsLastLowProfile = (): boolean => {
        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro) {
            return false;
        }
        if (this.Side && sysf.FieldSets(this.Quote.System).FlangedTowers.DataValid()) {
            if (this.Side.Tower[sidef.NumberOfTowers(this.Side) - 1].TowerType === TowerHeights.LowProfile) {
                return true;
            }
        }
        if (!(this.Side) && sysf.FieldSets(this.Quote.System).FlexTowers.DataValid()) {
            if (this.Side.Tower[sidef.NumberOfTowers(this.Side) - 1].TowerType === TowerHeights.LowProfile) {
                return true;
            }
        }
        return false;
    }

    public IsLastSupergator = (): boolean => {
        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro) {
            return false;
        }
        if (this.Side && sysf.FieldSets(this.Quote.System).FlangedTowers.DataValid()) {
            if (this.Side.Tower[sidef.NumberOfTowers(this.Side) - 1].TowerType === TowerHeights.Supergator) {
                return true;
            }
        }
        if (!(this.Side) && sysf.FieldSets(this.Quote.System).FlexTowers.DataValid()) {
            if (this.Side.Tower[sidef.NumberOfTowers(this.Side) - 1].TowerType === TowerHeights.Supergator) {
                return true;
            }
        }
        return false;
    }
    public AllLowProfiles = (): boolean => {
        for (let i: number = 1; i <= sidef.NumberOfTowers(this.Side); i++) {
            if (this.Side.Tower[i - 1].TowerType !== TowerHeights.LowProfile) {
                return false;
            }
        }
        return true;
    }

    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): boolean => {
        if (!this.FieldSet.DataValid()) return;

        let bTowerReverseTow: boolean;
        let bMaxigatorGuidanceFound: boolean;
        let bStopAfterWASFound: boolean;
        let bWASFound: boolean;
        let bBuckBoostFound: boolean;
        let i: number;
        let intUndefinedHeightTowerCount: number = 0;
        let intStandardTowerCount: number = 0;
        let intLowProfileTowerCount: number = 0;
        let intSugargatorTowerCount: number = 0;
        let intSupergatorTowerCount: number = 0;
        let ePanelType: PanelModels;
        let eEndTowerPositioning: EndTowerPositioningTypes;
        let bTowerAutoStopOrTowerAutoReverseFound: boolean;
        let iWrapAroundTotal: number;
        let iNumberOfSpans: number = sidef.NumberOfSpans(this.Side);
        let LastTower: number = sidef.NumberOfTowers(this.Side);

        let systemType: SystemTypes;
        let hoseFeedType: HoseFeedTypes;

        systemType = this.Quote.System.SystemProperties.SystemType;
        if (systemType === SystemTypes.HoseFeedMaxigator && this.Quote.HoseFeedClass.FieldSet.DataValid()) {
            hoseFeedType = this.Quote.System.Lateral.HoseFeed.HoseFeedType;
        }

        if (this.Quote.ControlPanelClass.FieldSet.DataValid()) {
            ePanelType = this.Quote.System.ControlPanel.PanelModel;
            eEndTowerPositioning = this.Quote.System.ControlPanel.EndTowerPositioning;
        }
        bMaxigatorGuidanceFound = false;
        let NumberofRightTowers: number;
        let bAgriTracOnATower: boolean;
        let bAgriTracNotOnATower: boolean;
        if (this.bLeftSide) {
            if (this.Quote.RightTowersClass.FieldSet.DataValid()) {
                NumberofRightTowers = sidef.NumberOfTowers(this.Quote.System.FlangedSide);
                for (i = 1; i <= NumberofRightTowers; i++
                ) {
                    if (this.Quote.System.FlangedSide.Tower[i - 1].Guidance) {
                        bMaxigatorGuidanceFound = true;
                    }
                    if (this.Quote.System.FlangedSide.Tower[i - 1].AgriTrac) {
                        bAgriTracOnATower = true;
                    }
                    else {
                        bAgriTracNotOnATower = true;
                    }
                }
            }
        }
        const bMaxi = sysf.IsMaxigator(this.Quote.System);
        const IsA100 = this.Quote.IsA100_Only();
        let ePowerTowerTireSize: TireSizes;
        let ePowerTowerTireType: TireTypes;
        let bSpansOutOfSync: boolean = false;
        switch (this.Quote.System.SystemProperties.SystemType) {
            case SystemTypes.HoseFeedMaxigator:
                let t = this.Quote.System.Lateral.Tires;
                ePowerTowerTireSize = t.TireSize;
                ePowerTowerTireType = t.TireType;
                if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed) {
                    if (iNumberOfSpans > 0 && LastTower !== iNumberOfSpans - 1) {
                        this.Quote.Warnings.Add(StringFormat(strLanguageText("sltTheNumberOfTowersSpans"), iNumberOfSpans - 1), WarningLevels.SectionBlock);
                        bSpansOutOfSync = true;
                    }
                }
                else {
                    if (LastTower !== iNumberOfSpans) {
                        this.Quote.Warnings.Add(StringFormat(strLanguageText("sltTheNumberOfTowersEqualSpans"), iNumberOfSpans), WarningLevels.SectionBlock);
                        bSpansOutOfSync = true;
                    }
                }
                break;
            default:
                if (LastTower !== iNumberOfSpans) {
                    this.Quote.Warnings.Add(StringFormat(strLanguageText("sltTheNumberOfTowersEqualSpans"), iNumberOfSpans), WarningLevels.SectionBlock);
                    bSpansOutOfSync = true;
                }
                break;
        }
        switch (this.Quote.System.TowerProperties.Coupler) {
            case CouplerTypes.HiTorque:
            case CouplerTypes.CXCoupler:
                if (sysf.IsReverseTow(this.Quote.System)) {
                    this.Quote.Warnings.Add(strLanguageText("sltTheCouplerOptionIsNotValidRTS"));
                }
                break;
        }
        if (this.Quote.System.TowerProperties.DrainCouplers && iNumberOfSpans) {
            if (this.Side.Span[iNumberOfSpans - 1].SpanType === SpanTypes.E2045) {
                this.Quote.Warnings.Add(strLanguageText("sltDrainCouplers"));
            }
        }
        if (!(this.Quote.System.TowerProperties.UMCCoverKit)) {
            if (IsA100) {
                this.Quote.Warnings.Add(strLanguageText("sltUMCCoverKit"));
            }
        }
        if (IsA100) {
            if (this.Quote.System.Lateral.Tires.TubesForTires !== this.Quote.System.TowerProperties.TireProperties.TubesForTires) {
                this.Quote.Warnings.Add(strLanguageText("sltTubesForTires"));
            }
        }
        let eStiffenedSpanType: SpanTypes;
        let eStiffenedSpanLength: number;
        let iLastTowerForOptions: number;
        if (this.bRightSide) {
            iLastTowerForOptions = sidef.FirstDisconnectingSpan(this.Quote.System.FlangedSide);
        }
        else {
            iLastTowerForOptions = sidef.FirstDisconnectingSpan(this.Quote.System.FlexSide);
        }
        if (iLastTowerForOptions === 0) {
            iLastTowerForOptions = LastTower;
        }
        else {
            iLastTowerForOptions -= 2;
        }
        let bHeavyByChoice: boolean;
        for (i = 1; i <= LastTower; i++
        ) {
            if (!(bSpansOutOfSync)) {
                eStiffenedSpanType = this.StiffenedSpanType(i);
                eStiffenedSpanLength = this.StiffenedSpanLength(i);
                if (i !== this.StiffenedSpan(i)) {
                    bTowerReverseTow = this.Quote.IsTowable();
                }
                else {
                    bTowerReverseTow = sysf.IsReverseTow(this.Quote.System);
                }
            }
            let t: Tower = this.Side.Tower[i - 1];
            if (!(bSpansOutOfSync)) {
                if (!(TowerTypeOK(t.TowerType, new TowerTypeOKDto(
                    sysf.Is230V(this.Quote.System),
                    sysf.IsEDMP(this.Quote.System),
                    this.Quote.IsSingleSpanMaxi(),
                    this.Side.Span[i - 1].Disconnecting,
                    this.Quote.IsTowable(),
                    bTowerReverseTow,
                    sidef.Spans(this.Quote.System, this.Side).SpanCount(),
                    eStiffenedSpanLength,
                    eStiffenedSpanType,
                    this.Quote.System.SystemProperties.SystemType,
                    hoseFeedType,
                    sysf.GetSourceTowerHeight(this.Quote.System),
                    sidef.GetTowerDetails(this.Side, i + 1),
                    i,
                    sidef.GetTowerDetails(this.Side, i - 1),
                    t.WrapAroundSpan ?? WrapAroundSpanTypes.None
                )))) {
                    let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";

                    this.Quote.Warnings.AddWithTargets(
                        [`${side}Side.Tower[${t.TowerNumber - 1}].TowerType`],
                        `${side} tower #${t.TowerNumber} height ${t.TowerType} not allowed in this location`);
                }
                if (!(GearDriveOK(t.CenterDrive, {
                    SystemType: systemType,
                    HoseFeedType: hoseFeedType,
                    SpanType: eStiffenedSpanType,
                    IsSingleSpanMaxi: this.Quote.IsSingleSpanMaxi(),
                    Is230Volt: sysf.Is230V(this.Quote.System),
                }))) {
                    let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";

                    this.Quote.Warnings.AddWithTargets(
                        [`${side}Side.Tower[${t.TowerNumber - 1}].CenterDrive`],
                        `${strLanguageText("sltCenterDrive")} (${i})`);
                }
                if (
                    t.Tires.TireSize &&
                    !(TireSizeTowersOK(t.Tires.TireSize, {
                        PowerTowerTireSize: ePowerTowerTireSize,
                        IsA100: this.Quote.IsA100_Only(),
                        SpanType: eStiffenedSpanType,
                        SpanLength: eStiffenedSpanLength,
                        TowerCenterDrive: t.CenterDrive,
                        WheelGearType: this.Quote.System.TowerProperties.WheelGear ?? WheelGearSeries.None,
                        IsSingleSpanMaxigator: this.Quote.IsSingleSpanMaxi(),
                    }))) {
                        let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                        this.Quote.Warnings.AddWithTargets(
                            [`${side}Side.Tower[${t.TowerNumber - 1}].Tires.TireSize`],
                            `Tire size for tower ${i} is not OK`);
                }
                if (
                    t.Tires.TireSize
                    && t.Tires.TireType
                    &&
                    !(TireTypeOK(t.Tires.TireType, {
                        TowerHeight: t.TowerType,
                        PowerTowerTireSize: ePowerTowerTireType,
                        TireSize: t.Tires.TireSize,
                        IsA100: this.Quote.IsA100_Only(),
                        IsSingleSpanMaxigator: this.Quote.IsSingleSpanMaxi(),
                        IsSACTower: false,
                        IsInternational: ohf.IsInternational(this.Quote.System.DealerProperties)
                    }))) {
                        let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                        this.Quote.Warnings.AddWithTargets(
                            [`${side}Side.Tower[${t.TowerNumber - 1}].Tires.TireType`],
                            `Tire type for tower ${i} is not OK`);
                }
                if (i < iNumberOfSpans) {
                    if (!(WrapAroundSpanOK(t.WrapAroundSpan, {
                        SystemType: this.Quote.System.SystemProperties.SystemType,
                        Is230Volt: sysf.Is230V(this.Quote.System),
                        IsEDMP: sysf.IsEDMP(this.Quote.System),
                        PrevSpanType: this.Side.Span[i - 1].SpanType,
                        NextSpanType: this.Side.Span[i].SpanType,
                        IsNextSpanDisconnecting: this.Side.Span[i].Disconnecting,
                        TowerCount: iNumberOfSpans,
                        TowerNumber: i,
                        IsTowable: this.Quote.IsTowable(),
                        TowerHeight: t.TowerType,
                        PanelModel: ePanelType,
                        EndTowerPositioning: eEndTowerPositioning,
                        anticlockwiseWrapAngleRelativeToPreviousSpanDegrees: t.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
                        clockwiseWrapAngleRelativeToPreviousSpanDegrees: t.clockwiseWrapAngleRelativeToPreviousSpanDegrees
                    }))) {
                        let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                        this.Quote.Warnings.AddWithTargets(
                            [`${side}Side.Tower[${t.TowerNumber - 1}].WrapAroundSpan`],
                            strLanguageText("sltWrapAroundSpan"));
                    }
                }
                else if (t.WrapAroundSpan !== WrapAroundSpanTypes.None) {
                    let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                    this.Quote.Warnings.AddWithTargets(
                        [`${side}Side.Tower[${t.TowerNumber - 1}].WrapAroundSpan`],
                        `${StringFormat(strLanguageText("sltTowerOptionsBadTower"), strLanguageText("sltWrapAroundSpan"))} (${i})`
                    );
                }
                switch (t.WrapAroundSpan) {
                    case WrapAroundSpanTypes.TenDegree:
                        iWrapAroundTotal += 10;
                        bWASFound = true;
                        break;
                    case WrapAroundSpanTypes.NinetyDegree:
                        iWrapAroundTotal += 90;
                        bWASFound = true;
                        break;
                }
                if (iWrapAroundTotal > 180) {
                    this.Quote.Warnings.Add(`${strLanguageText("sltWrapAroundSpan")} (${i})`);
                }
            }
            if (t.BuckBoost !== BuckBoostTypes.aNone && (sysf.IsEDMP(this.Quote.System) || sysf.Is230V(this.Quote.System))) {
                this.Quote.Warnings.Add(`${strLanguageText("sltBuckandBoostTransformer1")} (${i})`);
            }
            if (!(AgriTracOK(t.AgriTrac, { TowerHeight: t.TowerType, SpanType: eStiffenedSpanType, IsTowable: this.Quote.IsTowable(), TireSize: t.Tires.TireSize, TireType: t.Tires.TireType }))) {
                let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                this.Quote.Warnings.AddWithTargets(
                    [`${side}Side.Tower[${t.TowerNumber - 1}].AgriTrac`],
                    `${strLanguageText("sltAgriTrac")} (${i})`);
            }
            if (t.AgriTrac) {
                bAgriTracOnATower = true;
            }
            else {
                bAgriTracNotOnATower = true;
            }
            if (t.BuckBoost !== BuckBoostTypes.aNone) {
                if (this.Quote.IsA100_CF200()) {
                    this.Quote.Warnings.Add(`${strLanguageText("sltBuckandBoostTransformer")} (${i})`);
                }
                if (bBuckBoostFound) {
                    this.Quote.Warnings.Add(`${strLanguageText("sltOnlyOneTowerBuckBoost")} (${i})`);
                }
                bBuckBoostFound = true;
            }
            if (!(bSpansOutOfSync)) {
                if (!(HeavyDutyLegsOK(t.HeavyDuty, { TowerHeight: t.TowerType, SpanType: eStiffenedSpanType, SpanLength: eStiffenedSpanLength }))) {
                    const side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                    this.Quote.Warnings.AddWithTargets(
                        [`${side}Side.Tower[${t.TowerNumber - 1}].HeavyDuty`],
                        `${strLanguageText("sltHeavyDutyLegs")}, ${strLanguageText("sltTower")} (${i})`, WarningLevels.SectionBlock, false, "sltHeavyDutyLegs");
                }
                if (!(TowerCrossBraceOK(t.TowerCrossBrace, { TowerHeight: t.TowerType, SpanType: eStiffenedSpanType, IsA100: IsA100 }))) {
                    let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                    this.Quote.Warnings.AddWithTargets(
                        [`${side}Side.Tower[${t.TowerNumber - 1}].TowerCrossBrace`],
                        `${strLanguageText("sltTowerCrossBrace")}, ${strLanguageText("sltTower")} (${i})`, WarningLevels.SectionBlock, false, "sltTowerCrossBrace");
                }
                bHeavyByChoice = HeavyDutyLegsOK(false, { TowerHeight: t.TowerType, SpanType: eStiffenedSpanType, SpanLength: eStiffenedSpanLength }) && t.HeavyDuty;
                if (!(StiffenerTrussToPipeOK(t.StiffenerTrussToPipe, {
                    SystemType: systemType,
                    HosefeedType: hoseFeedType,
                    TowerHeight: t.TowerType,
                    SpanType: eStiffenedSpanType,
                    IsA100: this.Quote.IsA100_Only(),
                    SpanLength: eStiffenedSpanLength,
                    IsMaxi: bMaxi,
                    IsSingleSpanMaxigator: this.Quote.IsSingleSpanMaxi(),
                }))) {
                    let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                    this.Quote.Warnings.AddWithTargets(
                        [`${side}Side.Tower[${t.TowerNumber - 1}].StiffenerTrussToPipe`],
                        `${strLanguageText("sltStiffenerTrussToPipe")}, ${strLanguageText("sltTower")} (${i})`, WarningLevels.SectionBlock, false, "sltStiffenerTrussToPipe");
                }
                if (!(StiffenerStabilizersOK(t.StiffenerStabilizers, { TowerHeight: t.TowerType, SpanType: eStiffenedSpanType, IsMaxi: bMaxi, IsHeavyDutyByChoice: bHeavyByChoice, SpanLength: eStiffenedSpanLength, IsStiffenerTrussToPipe: t.StiffenerTrussToPipe }))) {
                    let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                    this.Quote.Warnings.AddWithTargets(
                        [`${side}Side.Tower[${t.TowerNumber - 1}].StiffenerStabilizers`],
                        `${strLanguageText("sltStiffenerStabilizers")}, ${strLanguageText("sltTower")} (${i})`, WarningLevels.SectionBlock, false, "sltStiffenerStabilizers");
                }
            }
            if (!(TowerAutoReverseOK(t.TowerAutoReverse, {
                SystemType: this.Quote.System.SystemProperties.SystemType,
                HoseFeedType: hoseFeedType,
                IsEDMP: sysf.IsEDMP(this.Quote.System),
                WrapAroundSpanType: t.WrapAroundSpan ?? WrapAroundSpanTypes.None,
                HasTowerAutoStop: t.TowerAutoStop,
            }))) {
                let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                this.Quote.Warnings.AddWithTargets(
                    [`${side}Side.Tower[${t.TowerNumber - 1}].TowerAutoReverse`],
                    `${strLanguageText("sltTowerAutoReverse")} (${i})`);
            }
            if (t.TowerAutoReverse) {
                if (bWASFound) {
                    bStopAfterWASFound = true;
                }
                bTowerAutoStopOrTowerAutoReverseFound = true;
            }
            if (!(TowerAutoStopOK(t.TowerAutoStop, {
                HasTowerAutoReverse: t.TowerAutoReverse,
                IsEDMP: sysf.IsEDMP(this.Quote.System),
                WrapAroundSpanType: t.WrapAroundSpan ?? WrapAroundSpanTypes.None,
                IsA100: IsA100,
            }))) {
                let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                this.Quote.Warnings.AddWithTargets(
                    [`${side}Side.Tower[${t.TowerNumber - 1}].TowerAutoStop`],
                    `${strLanguageText("sltTowerAutoStop")} (${i})`);
            }
            if (t.TowerAutoStop) {
                if (t.WrapAroundSpan === WrapAroundSpanTypes.None) {
                    if (bWASFound) {
                        bStopAfterWASFound = true;
                    }
                }
                bTowerAutoStopOrTowerAutoReverseFound = true;
            }
            if (!(ThreeWheelTowerOK(t.ThreeTires, { TowerHeight: t.TowerType, SpanType: eStiffenedSpanType, IsTowable: this.Quote.IsTowable(), IsEDMP: sysf.IsEDMP(this.Quote.System), IsAgriTrac: t.AgriTrac }))) {
                let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                this.Quote.Warnings.AddWithTargets(
                    [`${side}Side.Tower[${t.TowerNumber - 1}].ThreeTires`],
                    `${strLanguageText("sltThreeWheelTower")} (${i})`);
            }
            if (!(ThreeWheelFlexTowerOK(t.ThreeTiresFlex, { IsThreeWheelTower: t.ThreeTires }))) {
                let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                this.Quote.Warnings.AddWithTargets(
                    [`${side}Side.Tower[${t.TowerNumber - 1}].ThreeTiresFlex`],
                    `${strLanguageText("sltFlexTower")} (${i})`);
            }
            if (!(AquaValvesOK(t.AutomatedValves, { WrapAroundSpanType: t.WrapAroundSpan }))) {
                let side = sidef.FlangedSide(this.Quote.System, this.Side) ? "Flanged" : "Flex";
                this.Quote.Warnings.AddWithTargets(
                    [`${side}Side.Tower[${t.TowerNumber - 1}].AutomatedValves`],
                    `${strLanguageText("sltAquaValves")} (${i})`);
            }
            if (t.Guidance) {
                if (bMaxigatorGuidanceFound) {
                    this.Quote.Warnings.Add(`${strLanguageText("sltOnlyOneTowerMaxigator")} (${i})`);
                }
                bMaxigatorGuidanceFound = true;
                if (i > iLastTowerForOptions) {
                    this.Quote.Warnings.Add(`${StringFormat(strLanguageText("sltTowerOptionsBadTower"), strLanguageText("sltGuidance"))} (${i})`);
                }
                switch (this.Quote.System.SystemProperties.SystemType) {
                    case SystemTypes.HoseFeedMaxigator:
                    case SystemTypes.CanalFeedMaxigator:
                        if (this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.PowerTower) {
                            this.Quote.Warnings.Add(`${strLanguageText("sltMaxigatorNotConfiguredOuter")} (${i})`);
                        }
                        if (i === 1) {
                            switch (this.Quote.System.Lateral.Guidance.GuidanceType) {
                                case GuidanceTypes.BuriedWire:
                                case GuidanceTypes.NavigatorGPS:
                                    if (sysf.IsHoseFeedEndFeed(this.Quote.System) && !(systemType === SystemTypes.HoseFeedMaxigator && hoseFeedType === HoseFeedTypes.CF200)) {
                                        this.Quote.Warnings.Add(`${strLanguageText("sltBWGFirstSpan")} (${i})`);
                                    }
                                    break;
                            }
                        }
                        if (i === iNumberOfSpans && !(systemType === SystemTypes.HoseFeedMaxigator && hoseFeedType === HoseFeedTypes.CF200)) {
                            switch (this.Quote.System.Lateral.Guidance.GuidanceType) {
                                case GuidanceTypes.BuriedWire:
                                case GuidanceTypes.NavigatorGPS:
                                    this.Quote.Warnings.Add(`${strLanguageText("sltBWGLastSpan")} (${i})`);
                                    break;
                            }
                        }
                        break;
                    default:
                        this.Quote.Warnings.Add(`${strLanguageText("sltMaxigatorNotConfiguredSystem")} (${i})`);
                        break;
                }
            }
            if (this.Quote.System.SystemProperties.SystemType === SystemTypes.CenterPivot) {
                switch (t.TowerType) {
                    case undefined:
                        intUndefinedHeightTowerCount += 1;
                        break;
                    case TowerHeights.Standard:
                        intStandardTowerCount += 1;
                        break;
                    case TowerHeights.LowProfile:
                        intLowProfileTowerCount += 1;
                        break;
                    case TowerHeights.Sugargator:
                        intSugargatorTowerCount += 1;
                        break;
                    case TowerHeights.Supergator:
                        intSupergatorTowerCount += 1;
                        break;
                }
            }
        }
        if (bWASFound && !(bStopAfterWASFound)) {
            this.Quote.Warnings.Add(strLanguageText("sltTASorTARRequiredWithWAS"), WarningLevels.SectionBlock);
        }
        if (this.Quote.System.TowerProperties.LPDrainKit) {
            /*
            TODO Icon
            if (this.Quote.SprinklerConfigClass.DataInvalid) {
                this.Quote.Warnings.Add(strLanguageText("sltLPDrainWarning"), WarningLevels.Informational);
            }
            else {
                if (!(this.Quote.SprinklerConfigClass.AllHoseDrops)) {
                    this.Quote.Warnings.Add(strLanguageText("sltLPDrainWarning"), WarningLevels.Decision);
                }
            }*/
        }
        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.CenterPivot) {
            switch (this.Quote.System.Circle.CenterPivot.PivotCenterHeight) {
                case TowerHeights.Standard:
                    if (LastTower !== intStandardTowerCount && intUndefinedHeightTowerCount === 0) {
                        this.Quote.Warnings.Add(strLanguageText("sltTowerInconsistentWithCenterPivot"), WarningLevels.Decision);
                    }
                    break;
                case TowerHeights.LowProfile:
                    if (LastTower !== intLowProfileTowerCount && intUndefinedHeightTowerCount === 0) {
                        this.Quote.Warnings.Add(strLanguageText("sltTowerInconsistentWithCenterPivot"), WarningLevels.Decision);
                    }
                    break;
                case TowerHeights.Sugargator:
                    if (LastTower !== intSugargatorTowerCount && intUndefinedHeightTowerCount === 0) {
                        this.Quote.Warnings.Add(strLanguageText("sltTowerInconsistentWithCenterPivot"), WarningLevels.Decision);
                    }
                    break;
                case TowerHeights.Supergator:
                    if (LastTower !== intSupergatorTowerCount && intUndefinedHeightTowerCount === 0) {
                        this.Quote.Warnings.Add(strLanguageText("sltTowerInconsistentWithCenterPivot"), WarningLevels.Decision);
                    }
                    break;
            }
        }
        if (this.bRightSide) {
            switch (this.Quote.System.SystemProperties.SystemType) {
                case SystemTypes.HoseFeedMaxigator:
                case SystemTypes.CanalFeedMaxigator:
                    if (this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.OuterTower) {
                        if (!(bMaxigatorGuidanceFound)) {
                            const targets: string[] = [];
                            for (let i = 0; i < this.Quote.System.FlangedSide.Tower.length; i++) {
                                if (this.Side.Span[i]?.Disconnecting){
                                    continue;
                                }
                                if (this.Side.Span[i + 1]?.Disconnecting) {
                                    continue
                                }
                                if (this.Quote.System.Lateral.Guidance.GuidanceType === GuidanceTypes.BuriedWire || 
                                    this.Quote.System.Lateral.Guidance.GuidanceType === GuidanceTypes.NavigatorGPS) {
                                    if (i === this.Side.Span.length - 1){
                                        continue;
                                    }
                                    if (i === 0 && sysf.IsHoseFeedEndFeed(this.Quote.System)){
                                        continue;
                                    }
                                }
                                const hf = [HoseFeedTypes.Standard, HoseFeedTypes.DoubleEndFeed, HoseFeedTypes.FourWheelDrive, HoseFeedTypes.Sugargator];
                                if (this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator &&
                                    hf.indexOf(this.Quote.System.Lateral.HoseFeed.HoseFeedType) !== -1 && 
                                    this.Quote.System.Lateral.WaterFeed === WaterFeedTypes.EndFeed &&
                                    i === 0) {
                                    
                                    continue;
                                }

                                targets.push(`FlangedSide.Tower[${i}].Guidance`);
                            }
                            this.Quote.Warnings.AddWithTargets(
                                targets,
                                strLanguageText("sltMaxigatorRequiredOuter"), WarningLevels.SectionBlock);
                        }
                    }
                    break;
            }
        }
        if (this.bRightSide) {
            if (bAgriTracOnATower && bAgriTracNotOnATower) {
                this.Quote.Warnings.Add(strLanguageText("sltAgriTRACsInconsistent"), WarningLevels.Decision);
            }
        }
        if (bWASFound) {
            this.Quote.Warnings.Add(strLanguageText("sltWASWarning"), WarningLevels.Informational);
        }
        let bPartCircle: boolean;
        switch (this.Quote.System.SystemProperties.SystemType) {
            case SystemTypes.CenterPivot:
                if (ohf.PercentOfCircle(this.Quote.System) < 100) {
                    bPartCircle = true;
                }
                break;
            case SystemTypes.KwikTow:
                if (ohf.PercentOfCircle(this.Quote.System) < 100) {
                    bPartCircle = true;
                }
                break;
        }
        if (bPartCircle || (sysf.IsMaxigator(this.Quote.System) && !(IsA100))) {
            if (!(bTowerAutoStopOrTowerAutoReverseFound)) {
                this.Quote.Warnings.Add(strLanguageText("sltTARTASWarning"), WarningLevels.Decision);
            }
        }
        /*
        TODO Icon
        if (!(bSpansOutOfSync)) {
            if (this.bBBoxWarning) {
                this.Quote.Warnings.Add(strLanguageText("sltBBoxWarning"), WarningLevels.Decision);
            }
            if (!(this.bBBoxOrdered) && iNumberOfSpans > 1) {
                if (!(sysf.IsCenterFeed(this.Quote.System)) || !(this.bRightSide)) {
                    this.Quote.Warnings.Add(strLanguageText("sltBBoxMissing"), WarningLevels.Decision);
                }
            }
            if (sysf.FieldSets(this.Quote.System).Guidance.DataValid()) {
                let iGuidanceTower: number = sidef.GuidanceTower(this.Side);
                let iEOSToGuidanceDistance: number;
                if (iGuidanceTower > 0) {
                    iEOSToGuidanceDistance = spanf.EndingLocation(this.Side.Span[sidef.Spans(this.Side).Count - 1]) - spanf.EndingLocation(this.Side.Span[iGuidanceTower - 1]);
                }
                else {
                    iEOSToGuidanceDistance = spanf.EndingLocation(this.Side.Span[sidef.Spans(this.Side).Count - 1]);
                }
                switch (this.Quote.System.Lateral.Guidance.GuidanceType) {
                    case GuidanceTypes.Furrow:
                        if (iEOSToGuidanceDistance > 1800) {
                            this.Quote.Warnings.Add(StringFormat(strLanguageText("sltDistanceFromGuidanceToEOSTooLong"), FeetToMeters(1800)) + " " + strLanguageText("sltContactReinkeBeforeQuoting"), WarningLevels.Decision, true);
                        }
                        break;
                    case GuidanceTypes.Cable:
                    case GuidanceTypes.BuriedWire:
                    case GuidanceTypes.NavigatorGPS:
                        if (iEOSToGuidanceDistance > 2200) {
                            this.Quote.Warnings.Add(StringFormat(strLanguageText("sltDistanceFromGuidanceToEOSTooLong"), FeetToMeters(2200)) + " " + strLanguageText("sltContactReinkeBeforeQuoting"), WarningLevels.Decision, true);
                        }
                        break;
                }
            }
        }
        */
    }
/*private DetectPOWarnings = () => {
let LocalPO: PartsAssembler;
LocalPO.SpecialBOM = new BillOfMaterials();
this.bBBoxWarning = false;
this.WritePO(LocalPO);
}
*/public WritePO = (PO: PartsAssembler) => {
        const spanBoxTypes: BoxTypes[] = Array(this.Side.Tower.length);
        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.Ancillary) return;
        let iTower: number;
        let bSAC: boolean;
        let bDEF: boolean;
        let bCenterFeed: boolean;
        let bSSM: boolean;
        let bMaxi: boolean;
        let bHoseFeedEndFeed: boolean;
        let bTowerUL: boolean;
        let bTowerReverseTow: boolean;
        let bHeavyDuty: boolean;
        let bHingeTower: boolean;
        let bTowerCrossBrace: boolean;
        let eLeadType: idxLeadType;
        let bDisconnect: boolean;
        let eGuidance: GuidanceTypes;
        let iSelectedTire: number;
        let LastTower: number = sidef.NumberOfTowers(this.Side);
        let LastTowerInclDEF: number;
        let eStiffenedSpanType: SpanTypes;
        let bTubesForTires: boolean;
        let bOneTireReversed: boolean;
        let RigidSpan: number;
        let bFiveHPBooster: boolean;
        bTubesForTires = this.Quote.System.TowerProperties.TireProperties.TubesForTires;
        bOneTireReversed = this.Quote.System.TowerProperties.TireProperties.OneTireMountedInReverse;

        const SystemType = this.Quote.System.SystemProperties.SystemType;
        let HoseFeedType: HoseFeedTypes;
        if (SystemType === SystemTypes.HoseFeedMaxigator && this.Quote.HoseFeedClass.FieldSet.DataValid()) {
            HoseFeedType = this.Quote.System.Lateral.HoseFeed.HoseFeedType;
        }

        let bHoseFeedPivot: boolean;
        RigidSpan = this.Quote.RigidSpan();
        bDEF = this.Quote.IsDEF();
        if (bDEF) {
            LastTowerInclDEF = LastTower + 1;
        }
        else {
            LastTowerInclDEF = LastTower;
        }
        bCenterFeed = sysf.IsCenterFeed(this.Quote.System);
        bSSM = this.Quote.IsSingleSpanMaxi();
        bMaxi = sysf.IsMaxigator(this.Quote.System);
        bSAC = sysf.HasSwingArmCorner(this.Quote.System);
        bHoseFeedEndFeed = sysf.IsHoseFeedEndFeed(this.Quote.System);
        if (this.Quote.GuidanceClass.FieldSet.DataValid()) {
            eGuidance = this.Quote.System.Lateral.Guidance.GuidanceType;
        }
        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
            if (this.Quote.HoseFeedClass.FieldSet.DataValid()) {
                bHoseFeedPivot = hff.Pivoting(this.Quote.System.Lateral.HoseFeed);
            }
        }

        bTowerUL = this.Quote.IsTowerUL();
        bFiveHPBooster = sysf.HasFiveHPBoosterPump(this.Quote.System);
        let iStartTower: number;
        if (bMaxi && this.bRightSide && !(sysf.IsPivotingLateral(this.Quote.System))) {
            iStartTower = 0;
        }
        else {
            iStartTower = 1;
        }
        let eLocationCode: LocationCodes;
        if (this.bRightSide) {
            eLocationCode = LocationCodes.TowersRight;
        }
        else {
            eLocationCode = LocationCodes.TowersLeft;
        }
        let bCBoxOrdered: boolean;
        let k: number;
        let j: number;
        let bLongEndBoom: boolean;
        if (sidef.Spans(this.Quote.System, this.Side).Count && this.Side.Span[sidef.Spans(this.Quote.System, this.Side).Count - 1].EndBoom) {
            switch (this.Side.Span[sidef.Spans(this.Quote.System, this.Side).Count - 1].Length) {
                case 85:
                case 105:
                    bLongEndBoom = true;
                    break;
            }
        }
        this.bBBoxOrdered = false;
        if (!(this.bRightSide)) {
            j = sidef.Towers(this.Quote.System.FlangedSide).Count;
            for (k = 1; k <= j; k++
            ) {
                // TODO this is wrong!
                if (spanBoxTypes[k - 1] === BoxTypes.BBox) {
                    this.bBBoxOrdered = true;
                    break;
                }
            }
        }
        for (iTower = LastTowerInclDEF; iTower >= 1; iTower += -1) {
            if (!(bCBoxOrdered)) {
                if (iTower === LastTowerInclDEF) {
                    if (!(bDEF)) {
                        spanBoxTypes[iTower - 1] = BoxTypes.CBox;
                    }
                    bCBoxOrdered = true;
                }
            }
            else if (!(this.bBBoxOrdered)) {
                bDisconnect = false;
                if (iTower < LastTower) {
                    if (this.Side.Span[iTower + 1 - 1].Disconnecting) {
                        bDisconnect = true;
                    }
                }
                const firstDisconnectingSpan = sidef.FirstDisconnectingSpan(this.Side);
                if ((iTower < firstDisconnectingSpan - 1) || (firstDisconnectingSpan === 0)) {
                    if (this.Side.Tower[iTower - 1].Guidance) {
                        // If special case then on a Guidance tower for a CF200 that should
                        // add a Modified-BBox on the first tower when there are ONLY 2 spans.
                        if (this.Quote.IsCF200() && iTower === 1 && sidef.NumberOfTowers(this.Side) === 2) {
                            spanBoxTypes[iTower - 1] = BoxTypes.BBox;
                            this.bBBoxOrdered = true;
                        } else {
                            spanBoxTypes[iTower - 1] = BoxTypes.ABox;
                        }
                    }
                    else if (!(bDisconnect)) {
                        spanBoxTypes[iTower - 1] = BoxTypes.BBox;
                        this.bBBoxOrdered = true;
                        if (spanf.EndingRadius(this.Quote.System, this.Side, this.Side.Span[sidef.Spans(this.Quote.System, this.Side).Count - 1]) > 1200) {
                            if (spanf.EndingRadius(this.Quote.System, this.Side, this.Side.Span[sidef.Spans(this.Quote.System, this.Side).Count - 1]) - spanf.EndingRadius(this.Quote.System, this.Side, this.Side.Span[iTower - 1]) > 1000) {
                                this.bBBoxWarning = true;
                            }
                        }
                    }
                    else {
                        spanBoxTypes[iTower - 1] = BoxTypes.ABox;
                    }
                }
                else {
                    spanBoxTypes[iTower - 1] = BoxTypes.ABox;
                }
            }
            else {
                spanBoxTypes[iTower - 1] = BoxTypes.ABox;
            }
        }
        let iToSpan: number;
        for (iTower = iStartTower; iTower <= LastTowerInclDEF; iTower++) {
            // eStiffenedSpanLength and dStiffedSpanEndingLocation not used if iTower === 0
            let eStiffenedSpanLength: number = iTower === 0 ? 0 : this.StiffenedSpanLength(iTower);
            let dStiffedSpanEndingLocation: number = iTower === 0 ? 0 : this.StiffenedSpanEndingLocation(iTower);

            if (iTower !== 0) {
                eStiffenedSpanType = this.StiffenedSpanType(iTower);
            }
            iToSpan = this.StiffenedSpan(iTower);
            if (iTower !== iToSpan) {
                bTowerReverseTow = this.Quote.IsTowable();
            }
            else {
                bTowerReverseTow = sysf.IsReverseTow(this.Quote.System);
            }
            bHingeTower = iTower === LastTower && bSAC;
            let IsA100: boolean = this.Quote.IsA100_CF200();
            let bStiffenerStabilizer: boolean = false;
            if (iTower > 0 && (!(bDEF) || iTower < LastTowerInclDEF)) {
                bStiffenerStabilizer = this.Side.Tower[iTower - 1].StiffenerStabilizers;
                if (iTower === LastTowerInclDEF) {
                    if (!(sysf.HasSwingArmCorner(this.Quote.System))) {
                        if (this.Side.Span[sidef.Spans(this.Quote.System, this.Side).Count - 1].EndBoom) {
                            if (this.Side.Span[sidef.Spans(this.Quote.System, this.Side).Count - 1].Length === 85) {
                                if (this.Side.Span[sidef.Spans(this.Quote.System, this.Side).Count - 1 - 1].Length === 175) {
                                    bStiffenerStabilizer = true;
                                }
                            }
                        }
                    }
                }
                if ([TowerHeights.Sugargator, TowerHeights.Supergator].indexOf(this.Side.Tower[iTower - 1].TowerType) !== -1) {
                    bStiffenerStabilizer = true;
                    switch (eStiffenedSpanType) {
                        case SpanTypes.A60G:
                            bStiffenerStabilizer = false;
                            break;
                    }
                }
                if (bHingeTower) {
                    bStiffenerStabilizer = true;
                    switch (eStiffenedSpanType) {
                        case SpanTypes.A60G:
                        case SpanTypes.AlumIV:
                            bStiffenerStabilizer = false;
                            break;
                    }
                }
                if (bMaxi) {
                    bStiffenerStabilizer = true;
                }
                if (!(this.bRightSide) || (iToSpan === iTower) || (iToSpan !== iTower && iToSpan !== RigidSpan)) {
                    switch (eStiffenedSpanType) {
                        case SpanTypes.E2100:
                        case SpanTypes.E2085:
                        case SpanTypes.S2085G:
                        case SpanTypes.PL2085G:
                            bStiffenerStabilizer = false;
                            break;
                    }
                    switch (eStiffenedSpanLength) {
                        case 194:
                        case 204:
                        case 213:
                            bStiffenerStabilizer = false;
                            break;
                    }
                }
                if (IsA100) {
                    bStiffenerStabilizer = false;
                }
            }
            else if (iTower === 0) {
                bStiffenerStabilizer = false;
            }
            if (bMaxi) {
                switch (eStiffenedSpanType) {
                    case SpanTypes.A60G:
                        PO.InsertPart(schema.groups.TowerAssemblies_StiffenerSetsforLateralAluminumSpan.groupId, "395800", 1, { locationCode: eLocationCode, locationPosition: iTower }); // CABLE/BRACE/STIFF-A60G-MAXI
                        break;
                    case SpanTypes.AlumIV:
                        PO.InsertPart(schema.groups.TowerAssemblies_StiffenerSetsforLateralAluminumSpan.groupId, "395830", 1, { locationCode: eLocationCode, locationPosition: iTower }); // CABLE/BRACE/STIFF PKG-MAXI/A4
                        break;
                }
            }
            if (bStiffenerStabilizer) {
                switch (eStiffenedSpanType) {
                    case SpanTypes.E2085:
                    case SpanTypes.S2085G:
                    case SpanTypes.PL2085G:
                        PO.InsertPart(schema.groups.TowerAssemblies_StiffenerStabilizers.groupId, "394374", 1, { locationCode: eLocationCode, locationPosition: iTower }); // STIFFENER STABILIZER OPT 8-5/8
                        break;
                    case SpanTypes.E2065:
                    case SpanTypes.E2060:
                    case SpanTypes.E2665:
                    case SpanTypes.E2660:
                    case SpanTypes.S2065G:
                    case SpanTypes.PL2065G:
                        PO.InsertPart(schema.groups.TowerAssemblies_StiffenerStabilizers.groupId, "394373", 1, { locationCode: eLocationCode, locationPosition: iTower }); // STIFFENER STAB OPT 6" / 6-5/8"
                        break;
                    case SpanTypes.A80G:
                        PO.InsertPart(schema.groups.TowerAssemblies_StiffenerStabilizers.groupId, "395801", 1, { locationCode: eLocationCode, locationPosition: iTower }); // STIFF/STAB OPT-A80G-MAXI
                        break;
                    case SpanTypes.A60G:
                    case SpanTypes.AlumIV:
                        if (!(bMaxi)) {
                            PO.InsertPart(schema.groups.TowerAssemblies_StiffenerStabilizers.groupId, "395904", 1, { locationCode: eLocationCode, locationPosition: iTower }); // STIFF-STAB OPT-A60G, A4
                        }
                        break;
                }
            }
            let bTrussToPipePresent: boolean = false;
            let bStiffenerTrussToPipe: boolean = false;
            if (iTower > 0 && (!(bDEF) || iTower < LastTowerInclDEF)) {
                bStiffenerTrussToPipe = this.Side.Tower[iTower - 1].StiffenerTrussToPipe;
                if (bStiffenerTrussToPipe) {
                    bTrussToPipePresent = true;
                }
                switch (eStiffenedSpanType) {
                    case SpanTypes.A60G:
                    case SpanTypes.AlumIV:
                    case SpanTypes.A80G:
                        bTrussToPipePresent = true;
                        break;
                }
                if ([TowerHeights.Sugargator, TowerHeights.Supergator].indexOf(this.Side.Tower[iTower - 1].TowerType) !== -1) {
                    bStiffenerTrussToPipe = false;
                    bTrussToPipePresent = true;
                }
                if (bHingeTower) {
                    bStiffenerTrussToPipe = false;
                    bTrussToPipePresent = true;
                }
                if (iTower === LastTowerInclDEF) {
                    if (!(sysf.HasSwingArmCorner(this.Quote.System))) {
                        if (this.Side.Span[sidef.Spans(this.Quote.System, this.Side).Count - 1].EndBoom) {
                            switch (this.Side.Span[sidef.Spans(this.Quote.System, this.Side).Count - 1].Length) {
                                case 85:
                                case 105:
                                    bStiffenerTrussToPipe = false;
                                    bTrussToPipePresent = true;
                                    break;
                            }
                        }
                    }
                }
            }
            else if (iTower === 0) {
                bStiffenerTrussToPipe = false;
                bTrussToPipePresent = true;
            }
            if (bStiffenerTrussToPipe) {
                switch (eStiffenedSpanType) {
                    case SpanTypes.A80G:
                        PO.InsertPart(schema.groups.TowerAssemblies_StiffenerTrusstoPipe.groupId, "394378", 1, { locationCode: eLocationCode, locationPosition: iTower }); // STIFF OPT-TRUSS-PIPE-A60G/A80G
                        break;
                    case SpanTypes.A60G:
                        if (!(bMaxi)) {
                            PO.InsertPart(schema.groups.TowerAssemblies_StiffenerTrusstoPipe.groupId, "394378", 1, { locationCode: eLocationCode, locationPosition: iTower }); // STIFF OPT-TRUSS-PIPE-A60G/A80G
                        }
                        break;
                    case SpanTypes.AlumIV:
                        if (!(bMaxi)) {
                            PO.InsertPart(schema.groups.TowerAssemblies_StiffenerTrusstoPipe.groupId, "395903", 1, { locationCode: eLocationCode, locationPosition: iTower }); // STIFF OPT-TRUSS TO PIPE OPT-A4
                        }
                        break;
                    default:
                        PO.InsertPart(schema.groups.TowerAssemblies_StiffenerTrusstoPipe.groupId, "394377", 1, { locationCode: eLocationCode, locationPosition: iTower }); // STIFFENER TRUSS-PIPE OPT EII
                        break;
                }
            }
            else {
                switch (eStiffenedSpanType) {
                    case SpanTypes.A80G:
                        PO.InsertPart(schema.groups.TowerAssemblies_StiffenerTrusstoPipe.groupId, "395902", 1, { locationCode: eLocationCode, locationPosition: iTower }); // ROD OPT-TRUSS TO PIPE-A80G
                        break;
                    case SpanTypes.A60G:
                        PO.InsertPart(schema.groups.TowerAssemblies_StiffenerTrusstoPipe.groupId, "395901", 1, { locationCode: eLocationCode, locationPosition: iTower }); // ROD OPT-TRUSS TO PIPE-A60G
                        break;
                    case SpanTypes.AlumIV:
                        PO.InsertPart(schema.groups.TowerAssemblies_StiffenerTrusstoPipe.groupId, "395900", 1, { locationCode: eLocationCode, locationPosition: iTower }); // ROD OPT-TRUSS TO PIPE-A4
                        break;
                }
            }
            if (bMaxi) {
                if ((iTower === 0 || (iTower === 1 && sysf.IsPivotingLateral(this.Quote.System))) && this.Quote.GuidanceClass.FieldSet.DataValid()
                    && !this.Quote.IsCF200()
                ) {
                    if (this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.OuterTower) {
                        PO.InsertPart(schema.groups.TowerAssemblies_BoxMountOptWireAlignment.groupId, "396338", 1, { locationCode: eLocationCode, locationPosition: iTower }); // G-BOX MNT OPT-WIRE ALIGN-MAXI
                    }
                }
                if (iTower !== LastTowerInclDEF) {
                    if (iTower !== 0 || bCenterFeed) {
                        if (this.Quote.System.SpanProperties.StainlessSteelCable) {
                            switch (eStiffenedSpanType) {
                                case SpanTypes.S2085G:
                                case SpanTypes.PL2085G:
                                case SpanTypes.E2085:
                                    PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignment.groupId, "396341SS", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WIRE ALIGN-SS-CTRL BOX-8 5/8"
                                    break;
                                case SpanTypes.A80G:
                                    PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignment.groupId, "396349SS", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WIRE ALIGN-SS-CTRL BOX-8" SPAN
                                    break;
                                case SpanTypes.E2065:
                                case SpanTypes.E2665:
                                case SpanTypes.S2065G:
                                case SpanTypes.PL2065G:
                                    PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignment.groupId, "396348SS", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WIRE ALIGN-SS-CTRL BOX-6 5/8"
                                    break;
                                case SpanTypes.E2060:
                                case SpanTypes.E2660:
                                case SpanTypes.A60G:
                                case SpanTypes.AlumIV:
                                    PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignment.groupId, "396347SS", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WIRE ALIGN-SS-CTRL BOX-6" SPAN
                                    break;
                                case SpanTypes.E2045:
                                    PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignment.groupId, "396131SS", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WIRE ALIGN-SS-CTRL BOX-4-1/2"
                                    break;
                            }
                        }
                        else {
                            switch (eStiffenedSpanType) {
                                case SpanTypes.S2085G:
                                case SpanTypes.PL2085G:
                                case SpanTypes.E2085:
                                    PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignment.groupId, "396341", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WIRE ALIGN-CTRL BOX-8 5/8" SPN
                                    break;
                                case SpanTypes.A80G:
                                    PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignment.groupId, "396349", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WIRE ALIGN-CTRL BOX-8" SPAN
                                    break;
                                case SpanTypes.E2065:
                                case SpanTypes.E2665:
                                case SpanTypes.S2065G:
                                case SpanTypes.PL2065G:
                                    PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignment.groupId, "396348", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WIRE ALIGN-CTRL BOX-6 5/8"SPAN
                                    break;
                                case SpanTypes.E2060:
                                case SpanTypes.E2660:
                                case SpanTypes.A60G:
                                case SpanTypes.AlumIV:
                                    PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignment.groupId, "396347", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WIRE ALIGN-CONTROL BOX-6" SPAN
                                    break;
                                case SpanTypes.E2045:
                                    PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignment.groupId, "396131", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WIRE ALIGN-CTRL BOX-4-1/2"SPAN
                                    break;
                            }
                        }
                        if (bTrussToPipePresent) {
                            if (this.Quote.System.SpanProperties.StainlessSteelCable) {
                                PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignmentCableOption.groupId, "396333SS", 1, { locationCode: eLocationCode, locationPosition: iTower }); // CABLE-SS-3/32 X 40' LSA
                            }
                            else {
                                PO.InsertPart(schema.groups.TowerAssemblies_ControlBoxWireAlignmentCableOption.groupId, "396333", 1, { locationCode: eLocationCode, locationPosition: iTower }); // CABLE OPT-3/32 X 40' LSA
                            }
                        }
                    }
                }
            }
            if (iTower > 0 && this.Quote.System.TowerProperties.CattleGuards) {
                switch (this.Side.Tower[iTower - 1].TowerType) {
                    case TowerHeights.Standard:
                        PO.InsertPart(schema.groups.TowerAssemblies_CattleGuard.groupId, "356143", 1, { locationCode: eLocationCode, locationPosition: iTower }); // GUARD-STD MTR LD-CATTLE
                        break;
                    case TowerHeights.LowProfile:
                        PO.InsertPart(schema.groups.TowerAssemblies_CattleGuard.groupId, "356241", 1, { locationCode: eLocationCode, locationPosition: iTower }); // GUARD-LP MTR LD-CATTLE
                        break;
                    case TowerHeights.Sugargator:
                        PO.InsertPart(schema.groups.TowerAssemblies_CattleGuard.groupId, "354360", 1, { locationCode: eLocationCode, locationPosition: iTower }); // GUARD-SG MTR LD-CATTLE
                        break;
                    case TowerHeights.Supergator:
                        break;
                }
            }
            if (iTower > 0 && (!(bDEF) || iTower < LastTowerInclDEF)) {
                bDisconnect = false;
                if (iTower < LastTower) {
                    if (this.Side.Span[iTower + 1 - 1].Disconnecting) {
                        bDisconnect = true;
                    }
                }
                if (this.Side.Tower[iTower - 1].TowerBaseAccessStep) {
                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBaseAccessStep.groupId, "394350-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // STEP-TOWER BASE ACCESS-ASSY
                }
                bHeavyDuty = this.Side.Tower[iTower - 1].HeavyDuty;
                switch (eStiffenedSpanType) {
                    case SpanTypes.E2100:
                    case SpanTypes.A80G:
                    case SpanTypes.E2085:
                    case SpanTypes.S2085G:
                    case SpanTypes.PL2085G:
                        bHeavyDuty = true;
                        break;
                    case SpanTypes.E2065:
                    case SpanTypes.E2665:
                    case SpanTypes.PL2065G:
                        switch (eStiffenedSpanLength) {
                            case 175:
                            case 180:
                            case 186.7:
                            case 194:
                            case 204:
                                bHeavyDuty = true;
                                break;
                        }
                        break;
                    case SpanTypes.E2060:
                    case SpanTypes.E2660:
                        switch (eStiffenedSpanLength) {
                            case 180:
                            case 186.7:
                            case 194:
                            case 204:
                            case 213:
                                bHeavyDuty = true;
                                break;
                        }
                        break;
                }
                if (!(bHingeTower)) {
                    if (!(bTowerReverseTow)) {
                        switch (this.Side.Tower[iTower - 1].TowerType) {
                            case TowerHeights.Standard:
                                switch (eStiffenedSpanType) {
                                    case SpanTypes.AlumIV:
                                        if (!(this.Quote.IsTowable())) {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394761", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-ALUMI 4-NT
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394762", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-ALUMI 4-TOW
                                        }
                                        break;
                                    case SpanTypes.A80G:
                                        if (!(this.Quote.IsTowable())) {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394261-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TWR ASY-HD-NT-A80G GALV
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394262-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TWR ASY-HD-TOW-A80G GALV
                                        }
                                        break;
                                    case SpanTypes.A60G:
                                        if (!(this.Quote.IsTowable())) {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394771-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-NON TOW-A60 G-GALV
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394772-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-TOWABLE-A60 G-GALV
                                        }
                                        break;
                                    case SpanTypes.E2045:
                                        if (!(this.Quote.IsTowable())) {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394327", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-NT-E2045
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394328", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-TOW-E2045
                                        }
                                        break;
                                    default:
                                        if (!(bHeavyDuty)) {
                                            if (!(this.Quote.IsTowable())) {
                                                if (!(this.Side.Tower[iTower - 1].ThreeTires)) {
                                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394361-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-NT-STD EII-GALV
                                                }
                                                else {
                                                    if (!(this.Side.Tower[iTower - 1].ThreeTiresFlex)) {
                                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394358", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASY-3 WHEEL-STD-EII
                                                    }
                                                    else {
                                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394356", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASY-3 WHL FLX-EII
                                                    }
                                                }
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394362-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-TOW-STD EII-GALV
                                            }
                                        }
                                        else {
                                            if (!(this.Quote.IsTowable())) {
                                                if (!(this.Side.Tower[iTower - 1].ThreeTires)) {
                                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394161-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-NT-HD EII-GALV
                                                }
                                                else {
                                                    if (!(this.Side.Tower[iTower - 1].ThreeTiresFlex)) {
                                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394359", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASY-3 WHEEL-HD-EII
                                                    }
                                                    else {
                                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394356", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASY-3 WHL FLX-EII
                                                    }
                                                }
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394162-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-TOW-HD EII-GALV
                                            }
                                        }
                                        break;
                                }
                                break;
                            case TowerHeights.LowProfile:
                                switch (eStiffenedSpanType) {
                                    case SpanTypes.A80G:
                                    case SpanTypes.A60G:
                                        if (!(this.Quote.IsTowable())) {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394773-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASY LOW PROFILE NT A60G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394774-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-LP TOW-A60 G-GALV
                                        }
                                        break;
                                    default:
                                        if (!(this.Quote.IsTowable())) {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394363-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-LP-NT-EII GALV
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394364-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-LP-TOW-EII GALV
                                        }
                                        break;
                                }
                                break;
                            case TowerHeights.Sugargator:
                                switch (eStiffenedSpanType) {
                                    case SpanTypes.A80G:
                                        if (!(this.Quote.IsTowable())) {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394777-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASY NT A80G SUGARGATOR
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394778-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASY TOW A80G SUGARGATOR
                                        }
                                        break;
                                    case SpanTypes.A60G:
                                        if (!(this.Quote.IsTowable())) {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394775-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASY NT A60G SUGARGATOR
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394776-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASY TOW A60G SUGARGATOR
                                        }
                                        break;
                                    default:
                                        if (!(this.Quote.IsTowable())) {
                                            if (iTower === LastTower && bLongEndBoom) {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394385", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TWR NT SG NO/TRUSS-PIPE ST EII
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394365-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER NT SG EII GALV
                                            }
                                        }
                                        else {
                                            if (iTower === LastTower && bLongEndBoom) {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394386", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TWR TOW SG NO/TRUSS-PIPE EII
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394366-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER TOW SG 6/6-5/8 EII GALV
                                            }
                                        }
                                        break;
                                }
                                break;
                            case TowerHeights.Supergator:
                                PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394267", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER SUPERGATOR
                                break;
                        }
                    }
                    else {
                        if (iTower !== LastTower || bDEF) {
                            switch (eStiffenedSpanType) {
                                case SpanTypes.E2085:
                                case SpanTypes.S2085G:
                                case SpanTypes.PL2085G:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394864-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TWR ASY-INT-E2085-REV TOW-EII
                                    break;
                                case SpanTypes.E2045:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394329", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TWR ASSY-REV TOW-KT/MAXI-E2045
                                    break;
                                default:
                                    if (!(bHeavyDuty)) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394861-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-REV TOW-KT/MAXI-EII
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394866-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-INT-E2060 HD-REV T
                                    }
                                    break;
                            }
                        }
                        else {
                            if (eStiffenedSpanType === SpanTypes.E2045) {
                                if (this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394331", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TWR ASY-LAST-REV TOW-MAX-E2045
                                }
                                else {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394330", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TWR ASY-LAST-REV TOW-KT-E2045
                                }
                            }
                            else {
                                let bTowCablePresent: boolean = sysf.IsReverseTow(this.Quote.System) && !(bDEF) && !(bSSM) && (dStiffedSpanEndingLocation > 685 || eStiffenedSpanLength === 175 || eStiffenedSpanLength === 180 || eStiffenedSpanLength === 186.7 || eStiffenedSpanLength === 194 || eStiffenedSpanLength === 204 || eStiffenedSpanLength === 213);
                                if ((this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && !((bSSM && sysf.IsReverseTow(this.Quote.System)))) || bTowCablePresent) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394863-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-LAST-REV TOW-MAXI-E
                                }
                                else {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394862-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER ASSY-LAST-REV TOW-KT-EII
                                }
                            }
                        }
                    }
                }
                else {
                    if (!(this.Side.Tower[iTower - 1].ThreeTires)) {
                        switch (this.Side.Tower[iTower - 1].TowerType) {
                            case TowerHeights.Standard:
                                switch (eStiffenedSpanType) {
                                    case SpanTypes.A60G:
                                    case SpanTypes.AlumIV:
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394965-G", 1, { locationCode: eLocationCode, locationPosition: iTower }); // A60G/A4 SYS-E60 H TOWER UHMW
                                        break;
                                    default:
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394960", 1, { locationCode: eLocationCode, locationPosition: iTower }); // ASY-H TWR & HINGE ASY-UHMW
                                        break;
                                }
                                break;
                            case TowerHeights.Sugargator:
                                PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394970", 1, { locationCode: eLocationCode, locationPosition: iTower }); // H TWR SAC UHMW HNG EII GALV SG
                                break;
                        }
                    }
                    else {
                        if (!(this.Side.Tower[iTower - 1].ThreeTiresFlex)) {
                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394357", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HINGE-UHMC-HTWR-3WHL DRV-EII
                        }
                        else {
                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAssembly.groupId, "394355", 1, { locationCode: eLocationCode, locationPosition: iTower }); // H-TWR-UHMW-3 WHL FLX-EII
                        }
                    }
                }
                bTowerCrossBrace = this.Side.Tower[iTower - 1].TowerCrossBrace;
                switch (eStiffenedSpanType) {
                    case SpanTypes.A80G:
                    case SpanTypes.A60G:
                    case SpanTypes.AlumIV:
                        bTowerCrossBrace = false;
                        break;
                }
                if ([TowerHeights.Sugargator, TowerHeights.Supergator].indexOf(this.Side.Tower[iTower - 1].TowerType) !== -1) {
                    bTowerCrossBrace = false;
                }
                if (bHingeTower) {
                    bTowerCrossBrace = false;
                }
                if (bTowerCrossBrace) {
                    PO.InsertPart(schema.groups.TowerAssemblies_TowerCrossBrace.groupId, "394376", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER CROSS BRACE OPTION EII
                }
                switch (this.Side.Tower[iTower - 1].BuckBoost) {
                    case BuckBoostTypes.a15KVA:
                        PO.InsertPart(schema.groups.TowerAssemblies_BuckAndBoostTransformer.groupId, "352857", 1, { locationCode: eLocationCode, locationPosition: iTower }); // BUCK/BOOST TRANS-15 KVA-EII
                        break;
                    case BuckBoostTypes.a225KVA:
                        PO.InsertPart(schema.groups.TowerAssemblies_BuckAndBoostTransformer.groupId, "352856", 1, { locationCode: eLocationCode, locationPosition: iTower }); // BUCK/BOOST TRANS-22.5 KVA-EII
                        break;
                }
                iSelectedTire = TireSelector(this.Side.Tower[iTower - 1].Tires.TireSize, this.Side.Tower[iTower - 1].Tires.TireType, bOneTireReversed, bTubesForTires, this.Side.Tower[iTower - 1].ThreeTires, this.Side.Tower[iTower - 1].Tires.TwelveLugs);
                PO.InsertPartByIteration(schema.groups.TowerAssemblies_TireandWheelAssembly.groupId, iSelectedTire, 1, { locationCode: eLocationCode, locationPosition: iTower });
                if (this.Quote.IsTowable()) {
                    if (bTowerReverseTow) {
                        PO.InsertPart(schema.groups.TowerAssemblies_TowHub.groupId, "352141", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOW HUB OPTION
                        if (this.Quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourtyFive) {
                            PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, "352131", 1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GR OPT 2.5" XHD NON TOW
                        }
                        else if (this.Quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourty) {
                            PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, "352127", 1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GEAR OPTION -NON TOW
                        }
                        else if (this.Quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourtyAD) {
                            //ROE-871 - Part Swap - Tower Gears
                            PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, 
                                this.Quote.System.partsPackageId < "2024081501" ? "352153" : "352155", 
                                1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GEAR 1016-NON TOW
                        }
                    }
                    else {
                        PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, "352147", 1, { locationCode: eLocationCode, locationPosition: iTower });  // WHEEL GEAR-TNT-REINKE-BRZ
                    }
                }
                else {
                    if (this.Side.Tower[iTower - 1].AgriTrac) {
                        PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, "352135", 1, { locationCode: eLocationCode, locationPosition: iTower }); // WHL GEAR OPT-NON TOW-AGRITRAC
                    }
                    else if (this.Side.Tower[iTower - 1].ThreeTires) {
                        if (this.Quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourtyFive) {
                            PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, "352137", 1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GEAR OPT-HD 3WD-NON TOW
                        }
                        else if (this.Quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourtyAD) {
                            //ROE-871 - Part Swap - Tower Gears
                            PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, 
                                this.Quote.System.partsPackageId < "2024081501" ? "352154" : "352156",
                                1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GEAR 1016-3WD-NON TOW
                        }
                        else {
                            PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, "352136", 1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GEAR OPT-3WD-NON TOW
                        }
                    }
                    else {
                        if (this.Quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourtyFive) {
                            PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, "352131", 1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GR OPT 2.5" XHD NON TOW
                        }
                        else if (this.Quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourty) {
                            PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, "352127", 1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GEAR OPTION -NON TOW
                        }
                        else if (this.Quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourtyAD) {
                            //ROE-871 - Part Swap - Tower Gears
                            PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, 
                                this.Quote.System.partsPackageId < "2024081501" ? "352153" : "352155",
                                1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GEAR 1016-NON TOW
                        }
                        else {
                            if (eStiffenedSpanType === SpanTypes.E2045) {
                                PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, "352140", 1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GEAR OPTION-MOD DUTY
                            }
                            else {
                                PO.InsertPart(schema.groups.TowerAssemblies_WheelGear.groupId, "352127", 1, { locationCode: eLocationCode, locationPosition: iTower }); // REINKE GEAR OPTION -NON TOW
                            }
                        }
                    }
                }
                if (this.Side.Tower[iTower - 1].AgriTrac) {
                    PO.InsertPart(schema.groups.TowerAssemblies_Agritrac.groupId, "352290", 1, { locationCode: eLocationCode, locationPosition: iTower }); // AGRI-TRAC 11 X 22.5-PER TWR
                }
                if (!(bTowerReverseTow) && !(this.Side.Tower[iTower - 1].ThreeTiresFlex)) {
                    switch (this.Quote.System.TowerProperties.Coupler) {
                        case CouplerTypes.HiTorque:
                            switch (eStiffenedSpanType) {
                                case SpanTypes.AlumIV:
                                    if (iTower !== LastTower || !(bSAC)) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352045", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HIGH TORQUE COUP OPT/TWR-NT-A4
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352041", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HIGH TORQUE COUP/TWR-E-EII
                                    }
                                    break;
                                case SpanTypes.A80G:
                                case SpanTypes.A60G:
                                    switch (this.Side.Tower[iTower - 1].TowerType) {
                                        case TowerHeights.Standard:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352441", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HIGH TORQUE COUP OPT/TWR-N T-E
                                            break;
                                        case TowerHeights.LowProfile:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352443", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HIGH TORQUE COUP OPT/TWR-NT-LP
                                            break;
                                        case TowerHeights.Sugargator:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352427", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HI TORQUE COUP/TWR-TOW-SGRGATR
                                            break;
                                    }
                                    break;
                                default:
                                    switch (this.Side.Tower[iTower - 1].TowerType) {
                                        case TowerHeights.Standard:
                                            if (this.Side.Tower[iTower - 1].ThreeTires) {
                                                PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352042", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HI TQ CPLR-3 WHL TWR BASE-EII
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352041", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HIGH TORQUE COUP/TWR-E-EII
                                            }
                                            break;
                                        case TowerHeights.LowProfile:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352043", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HIGH TORQUE COUP/TWR-NT-LP-EII
                                            break;
                                        case TowerHeights.Sugargator:
                                        case TowerHeights.Supergator:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352027", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HTC/DRVSHFT TOW SG
                                            break;
                                    }
                                    break;
                            }
                            break;
                        case CouplerTypes.CXCoupler:
                            switch (eStiffenedSpanType) {
                                case SpanTypes.AlumIV:
                                    if (iTower !== LastTower || !(bSAC)) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352015", 1, { locationCode: eLocationCode, locationPosition: iTower }); // C-X COUPLER OPT/TWR-NT-A4
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352012", 1, { locationCode: eLocationCode, locationPosition: iTower }); // C-X COUPLER/TWR-E-EII
                                    }
                                    break;
                                case SpanTypes.A80G:
                                case SpanTypes.A60G:
                                    switch (this.Side.Tower[iTower - 1].TowerType) {
                                        case TowerHeights.Standard:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352032", 1, { locationCode: eLocationCode, locationPosition: iTower }); // C-X COUPLER OPT/TWR-NT-E65
                                            break;
                                        case TowerHeights.LowProfile:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352033", 1, { locationCode: eLocationCode, locationPosition: iTower }); // C-X COUPLER/TWR-TOW-LP
                                            break;
                                        case TowerHeights.Sugargator:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352031", 1, { locationCode: eLocationCode, locationPosition: iTower }); // C-X COUPLER/TWR-TOW-SG
                                            break;
                                    }
                                    break;
                                default:
                                    switch (this.Side.Tower[iTower - 1].TowerType) {
                                        case TowerHeights.Standard:
                                            if (this.Side.Tower[iTower - 1].ThreeTires) {
                                                PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352013", 1, { locationCode: eLocationCode, locationPosition: iTower }); // C-X COUPLER/3 WHL-RGD BASE-EII
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352012", 1, { locationCode: eLocationCode, locationPosition: iTower }); // C-X COUPLER/TWR-E-EII
                                            }
                                            break;
                                        case TowerHeights.LowProfile:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352014", 1, { locationCode: eLocationCode, locationPosition: iTower }); // C-X COUPLER/TWR-NT-LP-EII
                                            break;
                                        case TowerHeights.Sugargator:
                                        case TowerHeights.Supergator:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352010", 1, { locationCode: eLocationCode, locationPosition: iTower }); // C-X COUPLER/DRVSHFT TOW SG
                                            break;
                                    }
                                    break;
                            }
                            break;
                        case CouplerTypes.SteelUJoints:
                            switch (eStiffenedSpanType) {
                                case SpanTypes.AlumIV:
                                    if (iTower !== LastTower || !(bSAC)) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352025", 1, { locationCode: eLocationCode, locationPosition: iTower }); // U-JOINTS-PER TOWER-ALUM-4
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352021", 1, { locationCode: eLocationCode, locationPosition: iTower }); // U-JOINTS-STD-PER TOWER-E-EII
                                    }
                                    break;
                                case SpanTypes.A80G:
                                case SpanTypes.A60G:
                                    switch (this.Side.Tower[iTower - 1].TowerType) {
                                        case TowerHeights.Standard:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352421", 1, { locationCode: eLocationCode, locationPosition: iTower }); // U-JOINTS-STD-PER TOWER-E
                                            break;
                                        case TowerHeights.LowProfile:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352423", 1, { locationCode: eLocationCode, locationPosition: iTower }); // U-JOINTS-LOW-PROF-PER TOWER-E
                                            break;
                                        case TowerHeights.Sugargator:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352428", 1, { locationCode: eLocationCode, locationPosition: iTower }); // U JOINTS-PER TOWER-SG
                                            break;
                                    }
                                    break;
                                default:
                                    switch (this.Side.Tower[iTower - 1].TowerType) {
                                        case TowerHeights.Standard:
                                            if (this.Side.Tower[iTower - 1].ThreeTires) {
                                                PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352022", 1, { locationCode: eLocationCode, locationPosition: iTower }); // U-JOINTS-3 WHEEL TWR BASE-EII
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352021", 1, { locationCode: eLocationCode, locationPosition: iTower }); // U-JOINTS-STD-PER TOWER-E-EII
                                            }
                                            break;
                                        case TowerHeights.LowProfile:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352023", 1, { locationCode: eLocationCode, locationPosition: iTower }); // U-JOINTS LP PER TOWER EII
                                            break;
                                        case TowerHeights.Sugargator:
                                        case TowerHeights.Supergator:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CouplerAssembly.groupId, "352028", 1, { locationCode: eLocationCode, locationPosition: iTower }); // STL-COUPLER/DRVSHFT SG
                                            break;
                                    }
                                    break;
                            }
                            break;
                    }
                }
                eLeadType = LeadType(this.Side.Tower[iTower - 1].TowerType, bSAC && (iTower === LastTower));
                if (bFiveHPBooster) {
                    PO.InsertPart(schema.groups.TowerAssemblies_FuseKitAssembly.groupId, "352360", 1, { locationCode: eLocationCode, locationPosition: iTower }); // FUSE KIT-6 AMP F/5HP BP-1/TWR
                }
                if (sysf.IsEDMP(this.Quote.System)) {
                    switch (this.Side.Tower[iTower - 1].CenterDrive) {
                        case GearDriveTypes.Standard:
                            PO.InsertPart(schema.groups.TowerAssemblies_CenterGear.groupId, "352260", 1, { locationCode: eLocationCode, locationPosition: iTower }); // UMC GEAR-60 TO 1 RATIO
                            break;
                        case GearDriveTypes.High:
                            PO.InsertPart(schema.groups.TowerAssemblies_CenterGear.groupId, "352240", 1, { locationCode: eLocationCode, locationPosition: iTower }); // UMC GEAR-40 TO 1 RATIO
                            break;
                        case GearDriveTypes.Low:
                            PO.InsertPart(schema.groups.TowerAssemblies_CenterGear.groupId, "352220", 1, { locationCode: eLocationCode, locationPosition: iTower }); // UMC GEAR-30 TO 1 RATIO
                            break;
                    }
                }
                else if (bSSM) {
                    switch (this.Side.Tower[iTower - 1].CenterDrive) {
                        case GearDriveTypes.Standard:
                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392490", 1, { locationCode: eLocationCode, locationPosition: iTower }); // MOTOR-STD HEL-25' W/O THERM
                            break;
                        case GearDriveTypes.High:
                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392491", 1, { locationCode: eLocationCode, locationPosition: iTower }); // MOTOR-HI HEL-25' W/O THERM
                            break;
                    }
                }
                else {
                    switch (eStiffenedSpanType) {
                        case SpanTypes.AlumIV:
                            switch (this.Side.Tower[iTower - 1].CenterDrive) {
                                case GearDriveTypes.Low:
                                    PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392478", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GR OPT LO SPD A-4
                                    break;
                                case GearDriveTypes.Standard:
                                    PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392479", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GEAR OPT STD SPD A-4
                                    break;
                                case GearDriveTypes.High:
                                    PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392480HDC", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GEAR OPT HI SPD A-4
                                    break;
                            }
                            break;
                        default:
                            switch (eLeadType) {
                                case idxLeadType.ltStandard:
                                    switch (this.Side.Tower[iTower - 1].CenterDrive) {
                                        case GearDriveTypes.Low:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392487", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GEAR OP LOW SPD EII
                                            break;
                                        case GearDriveTypes.Standard:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392488", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GEAR OPT STD SPD EII
                                            break;
                                        case GearDriveTypes.High:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392489HDC", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GEAR OPT HI SPD EII
                                            break;
                                        case GearDriveTypes.TwoThirtyVolt:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "352513", 1, { locationCode: eLocationCode, locationPosition: iTower }); // MOTOR/GR OPT-1HP-1PH-60:1-EII
                                            break;
                                    }
                                    break;
                                case idxLeadType.ltLowProfile:
                                    switch (this.Side.Tower[iTower - 1].CenterDrive) {
                                        case GearDriveTypes.Low:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392484", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GEAR OPT LOW SPD EIILP
                                            break;
                                        case GearDriveTypes.Standard:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392485", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GEAR OPT STD SPD EIILP
                                            break;
                                        case GearDriveTypes.High:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392486HDC", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GEAR OPT HI SPD EIILP
                                            break;
                                        case GearDriveTypes.TwoThirtyVolt:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "352514", 1, { locationCode: eLocationCode, locationPosition: iTower }); // MOTOR/GR OPT-1PH-60:1-LP-EII
                                            break;
                                    }
                                    break;
                                case idxLeadType.ltSugargator:
                                    switch (this.Side.Tower[iTower - 1].CenterDrive) {
                                        case GearDriveTypes.Low:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392475", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GR OPT LOW SPD EIISG
                                            break;
                                        case GearDriveTypes.Standard:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392476", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GR OPT STD SPD EIISG
                                            break;
                                        case GearDriveTypes.High:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392477HDC", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GR OPT HI SPD EIISG
                                            break;
                                    }
                                    break;
                                case idxLeadType.ltLong:
                                    switch (this.Side.Tower[iTower - 1].CenterDrive) {
                                        case GearDriveTypes.Low:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392481", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GR OPT LO SPD 203.5/H
                                            break;
                                        case GearDriveTypes.Standard:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392482", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GR OPT STD SPD 203.5/H
                                            break;
                                    }
                                    break;
                                case idxLeadType.ltSupergator:
                                    switch (this.Side.Tower[iTower - 1].CenterDrive) {
                                        case GearDriveTypes.Low:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392444", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GR OPT LOW EII SUPGTR
                                            break;
                                        case GearDriveTypes.Standard:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392445", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GR OPT STD EII SUPGTR
                                            break;
                                        case GearDriveTypes.High:
                                            PO.InsertPart(schema.groups.TowerAssemblies_CenterDrive.groupId, "392446", 1, { locationCode: eLocationCode, locationPosition: iTower }); // HELICAL GR OPT HI EII SUPGTR
                                            break;
                                    }
                                    break;
                            }
                            break;
                    }
                }
                if (eStiffenedSpanType === SpanTypes.AlumIV || this.Side.Tower[iTower - 1].ThreeTiresFlex) {
                    eLeadType = idxLeadType.ltSugargator;
                }
                if (this.Side.Tower[iTower - 1].TowerAutoReverse) {
                    switch (eLeadType) {
                        case idxLeadType.ltStandard:
                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoReverse.groupId, "352106", 1, { locationCode: eLocationCode, locationPosition: iTower }); // OVR HD TWR A/R-STD TWR-RAM-EII
                            break;
                        case idxLeadType.ltLowProfile:
                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoReverse.groupId, "352105", 1, { locationCode: eLocationCode, locationPosition: iTower }); // OVR HD TWR A/R-LO PRO-RAMS-EII
                            break;
                        case idxLeadType.ltLong:
                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoReverse.groupId, "352120", 1, { locationCode: eLocationCode, locationPosition: iTower }); // OVR HD TAR-203.5'/H-RAMS-EII
                            break;
                        case idxLeadType.ltSugargator:
                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoReverse.groupId, "352108", 1, { locationCode: eLocationCode, locationPosition: iTower }); // OVR HD TWR A/R-RAMS-SUGAR-EII
                            break;
                        case idxLeadType.ltSupergator:
                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoReverse.groupId, "352123", 1, { locationCode: eLocationCode, locationPosition: iTower }); // OVR HD TWR A/R-RAMS-SUPERGATOR
                            break;
                    }
                }
                if (this.Side.Tower[iTower - 1].TowerAutoStop) {
                    switch (this.Side.Tower[iTower - 1].WrapAroundSpan) {
                        case WrapAroundSpanTypes.None:
                            switch (eLeadType) {
                                case idxLeadType.ltStandard:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352517", 1, { locationCode: eLocationCode, locationPosition: iTower }); // OVR HD TWR A/S-STD
                                    break;
                                case idxLeadType.ltLowProfile:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352516", 1, { locationCode: eLocationCode, locationPosition: iTower }); // OVR HD TWR A/S LO PRO
                                    break;
                                case idxLeadType.ltLong:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352111", 1, { locationCode: eLocationCode, locationPosition: iTower }); // KIT-OVR HD TWR A/S-203.5' SPAN
                                    break;
                                case idxLeadType.ltSugargator:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352518", 1, { locationCode: eLocationCode, locationPosition: iTower }); // OVR HD TWR A/S-SUGARGATOR
                                    break;
                                case idxLeadType.ltSupergator:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352519", 1, { locationCode: eLocationCode, locationPosition: iTower }); // OVR HD TWR A/S-SUPERGATOR
                                    break;
                            }
                            break;
                        case WrapAroundSpanTypes.NinetyDegree:
                            if (this.Side.Tower[iTower - 1].TowerType === TowerHeights.Sugargator) {
                                PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352122", 1, { locationCode: eLocationCode, locationPosition: iTower }); // OVR HD TAR-SUG 90 PVT TWR
                            }
                            else {
                                PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352121", 1, { locationCode: eLocationCode, locationPosition: iTower }); // AUTO REVERSE-KIT-90 DEG-TOWER
                            }
                            if (this.Quote.System.partsPackageId < "2024081901") {
                                if (this.Side.Tower[iTower - 1].ControlLineBoosterPump) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352615", 1); // CONTROL LINE-BOOSTER PUMP-OPT
                                }
                                if (this.Side.Tower[iTower - 1].AirCompressor) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352911", 1); // AIR COMP OPT-JENNY-TWR MNT
                                }
                            }
                            else {
                                const controlLineBoosterPumpTower = towerf.getTowerForControlLineBoosterPump_post2024081901(this.Quote.System, this.Side);
                                if (controlLineBoosterPumpTower.controlLineBoosterPump) {
                                    if (
                                        // insert if at this tower, or if it is checked but there is no tower for it, insert at first tower:
                                        (controlLineBoosterPumpTower.towerIdx === (iTower - 1)) ||
                                        (controlLineBoosterPumpTower.towerIdx === -1 && (iTower - 1) === 0)
                                    ) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352615", 1); // CONTROL LINE-BOOSTER PUMP-OPT
                                    }
                                }
                                const airCompressorTower = towerf.getTowerForAirCompressor_post2024081901(this.Quote.System, this.Side);
                                if (airCompressorTower.airCompressor) {
                                    if (
                                        // insert if at this tower, or if it is checked but there is no tower for it, insert at first tower:
                                        (airCompressorTower.towerIdx === (iTower - 1)) ||
                                        (airCompressorTower.towerIdx === -1 && (iTower - 1) === 0)
                                    ) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352911", 1); // AIR COMP OPT-JENNY-TWR MNT
                                    }
                                }
                            }
                            break;
                        default:
                            PO.InsertPart(schema.groups.TowerAssemblies_TowerAutoStop.groupId, "352113", 1, { locationCode: eLocationCode, locationPosition: iTower }); // KIT-OVR HD TWR A/S-PIVOT TOWER
                            break;
                    }
                }
                if (!(sysf.IsEDMP(this.Quote.System))) {
                    if (bDEF && iTower === LastTower) {
                        if (!(bTowerUL)) {
                            switch (this.Quote.System.Lateral.GearDrive) {
                                case GearDriveTypes.Standard:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352585", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-C-MAXI-DOUBLE END FD
                                    break;
                                case GearDriveTypes.High:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352585-H", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TWR BOX-C-MAXI-DBL END FD-HDOL
                                    break;
                            }
                        }
                        else {
                            switch (this.Quote.System.Lateral.GearDrive) {
                                case GearDriveTypes.Standard:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352779", 1, { locationCode: eLocationCode, locationPosition: iTower }); // T BOX-STD-MOD C-DEF-MAX-UL/C
                                    break;
                                case GearDriveTypes.High:
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352779-H", 1, { locationCode: eLocationCode, locationPosition: iTower }); // T BOX-H D-MOD C-DEF-MAX-UL/C
                                    break;
                            }
                        }
                    }
                    switch (spanBoxTypes[iTower - 1]) {
                        case BoxTypes.CBox:
                            if (bSAC) {
                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352762-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-H SAC-NO OL-UL/C
                            }
                            else if (bSSM) {
                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352725", 1, { locationCode: eLocationCode, locationPosition: iTower }); // C BOX SINGLE SPAN MAXI CSA
                            }
                            else if (this.Side.Tower[iTower - 1].Guidance) {
                                if (SystemType === SystemTypes.HoseFeedMaxigator && HoseFeedType === HoseFeedTypes.CF200) {
                                    // ROE-790 - CF200 
                                    if (!bTowerUL) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352564-U", 1, { locationCode: eLocationCode, locationPosition: iTower });  // TOWER BOX-C-MAXI-US MOTORS
                                    } else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352753-U", 1, { locationCode: eLocationCode, locationPosition: iTower });  // TOWER BOX-C-NO OL-MAXI-UL/C UL
                                    }
                                } else {
                                    if (!(bTowerUL)) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352756-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-G/C-MAXI-US MOTORS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352778-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-G/C MAXI-L OL-UL/C
                                    }
                                }
                            }
                            else if (bMaxi) {
                                if (!(bTowerUL)) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352564-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-C-MAXI-US MOTORS
                                }
                                else {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352753-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-C-NO OL-MAXI-UL/C UL
                                }
                            }
                            else {
                                if (!(bTowerUL)) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352684-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-C-E60-US MOTOR
                                }
                                else {
                                    if (this.Side.Tower[iTower - 1].CenterDrive === GearDriveTypes.TwoThirtyVolt) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352688", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-C-SINGLE PHASE
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352744-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-C-NO OL-UL/C
                                    }
                                }
                            }
                            break;
                        case BoxTypes.BBox:
                            if (this.Side.Tower[iTower - 1].Guidance) {

                                if (SystemType === SystemTypes.HoseFeedMaxigator && HoseFeedType === HoseFeedTypes.CF200) {
                                    // ROE-790 - CF200 
                                    if (iTower === 1) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352740", 1, { locationCode: eLocationCode, locationPosition: iTower });  // MOD B-BOX-2 SPAN MAXI US MTR
                                    } else {
                                        if (!bTowerUL) {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352682-U", 1, { locationCode: eLocationCode, locationPosition: iTower });  // TOWER BOX-B-E60-US MOTOR
                                        } else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352743-U", 1, { locationCode: eLocationCode, locationPosition: iTower });  // TOWER BOX-B-NO OL-UL/C
                                        }
                                    }
                                } else {
                                    if (!(bTowerUL)) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352755-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-G/B-MAXI-US MOTORS
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352775-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-G/B MAXI-L OL-UL/C
                                    }
                                }
                            }
                            else if (iTower === 1 && bMaxi && !(bCenterFeed)) {
                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352740", 1, { locationCode: eLocationCode, locationPosition: iTower }); // MOD B-BOX-2 SPAN MAXI US MTR
                            }
                            else {
                                if (!(bTowerUL)) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352682-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-B-E60-US MOTOR
                                }
                                else {
                                    if (this.Side.Tower[iTower - 1].CenterDrive === GearDriveTypes.TwoThirtyVolt) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352687", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-B-SINGLE PHASE
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352743-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-B-NO OL-UL/C
                                    }
                                }
                            }
                            break;
                        case BoxTypes.ABox:
                            if (bHoseFeedPivot && iTower === 1) {
                                if (!(bTowerUL)) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "395869-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // BOX-TWR A-W/O OL-PVT MX-UL/CUL
                                }
                                else {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "395868-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // BOX-TWR A-W/O OL-PVT MX-UL/CUL
                                }
                            }
                            else if (bHoseFeedEndFeed && iTower === 1) {
                                if (!(bTowerUL)) {
                                    if (sysf.IsPivotingLateral(this.Quote.System) || this.Quote.System.Lateral.HoseFeed.CenterLift || this.Quote.System.Lateral.HoseFeed.PivotOption) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "395869-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // BOX-TWR A-W/O OL-PVT MX-UL/CUL
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352581-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-A-1ST MAXI-US MOTORS
                                    }
                                }
                                else {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352751-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-A-NO OL-#1MAX-UL/C
                                }
                            }
                            else if (this.Side.Tower[iTower - 1].Guidance) {
                                switch (eGuidance) {
                                    case GuidanceTypes.Cable:
                                    case GuidanceTypes.Furrow:

                                        if (SystemType === SystemTypes.HoseFeedMaxigator && HoseFeedType === HoseFeedTypes.CF200) {
                                            // ROE-790 - CF200 
                                            if (!bTowerUL) {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352681-U", 1, { locationCode: eLocationCode, locationPosition: iTower });  // TOWER BOX-A-E60-US MOTOR
                                            } else {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352742-U", 1, { locationCode: eLocationCode, locationPosition: iTower });  // TOWER BOX-A-NO OL-UL/C
                                            }
                                        } else {
                                            if (!(bTowerUL)) {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352556-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-G/A MAXI-US MOTORS
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352774-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-G/A MAXI-L OL-UL/C
                                            }
                                        }
                                        break;
                                    case GuidanceTypes.BuriedWire:
                                        if (!(bTowerUL)) {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352555-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TWR BOX-G/A-BWG-MAXI-US MOTORS
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352553-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // BOX-G/A-BURIED WIRE MAXI-CSA
                                        }
                                        break;
                                    case GuidanceTypes.NavigatorGPS:

                                        if (SystemType === SystemTypes.HoseFeedMaxigator && HoseFeedType === HoseFeedTypes.CF200) {
                                            // ROE-790 - CF200 
                                            if (!bTowerUL) {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352681-U", 1, { locationCode: eLocationCode, locationPosition: iTower });  // TOWER BOX-A-E60-US MOTOR
                                            } else {
                                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352742-U", 1, { locationCode: eLocationCode, locationPosition: iTower });  // TOWER BOX-A-NO OL-UL/C
                                            }
                                        } else {
                                            PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352773-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-G/A-GPS GUIDANCE
                                        }
                                        break;
                                }
                            }
                            else if (bDisconnect) {
                                if (bCenterFeed && this.bLeftSide) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352747-RH", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-A/C-RH-MAXI-UL/CUL
                                }
                                else if (bMaxi) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352767-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // BOX-TWR A/C NO OL MAXI UL/CUL
                                }
                                else {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "353100-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // A/C BOX PIVOT NO OL UL/CUL
                                }
                            }
                            else if (this.Side.Tower[iTower - 1].WrapAroundSpan === WrapAroundSpanTypes.NinetyDegree) {
                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352878-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // A-BOX-90 DEG PIVOT TWR OPT-CSA
                            }
                            else if (this.Side.Tower[iTower - 1].WrapAroundSpan !== WrapAroundSpanTypes.None) {
                                PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352877-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // A-BOX-PIVOT TOWER OPT-CSA
                            }
                            else {
                                if (!(bTowerUL)) {
                                    PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352681-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-A-E60-US MOTOR
                                }
                                else {
                                    if (this.Side.Tower[iTower - 1].CenterDrive === GearDriveTypes.TwoThirtyVolt) {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352686", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-A-SINGLE PHASE
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.TowerAssemblies_TowerBoxes.groupId, "352742-U", 1, { locationCode: eLocationCode, locationPosition: iTower }); // TOWER BOX-A-NO OL-UL/C
                                    }
                                }
                            }
                            break;
                    }
                }
            }
        }
        if (this.Quote.System.TowerProperties.UMCCoverKit) {
            sidef.Towers(this.Side).ToList().filter((t) => [GearDriveTypes.Low, GearDriveTypes.Standard].indexOf(t.CenterDrive) !== -1).forEach((t) => {
                PO.InsertPart(schema.groups.TowerAssemblies_DriveShield.groupId, "352466", 1); // COVER KIT-UMC PS4 CENTER DRIVE
            });
            sidef.Towers(this.Side).ToList().filter((t) => t.CenterDrive === GearDriveTypes.High).forEach((t) => {
                PO.InsertPart(schema.groups.TowerAssemblies_DriveShield.groupId, "352465", 1); // COVER KIT-UMC PS CENTER DRIVE
            });
        }
        if (sysf.IsEDMP(this.Quote.System)) {
            PO.InsertPart(schema.groups.TowerAssemblies_EDMPMotor.groupId, "357400", 1, { locationCode: eLocationCode, locationPosition: 1 }); // ENGINE DRIVE-1SP-CTR-PVT-EII
        }
    }
}