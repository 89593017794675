import CollapseIcon from '@mui/icons-material/ExpandLess';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import {
    Link,
    TableCell,
    TableRow,
} from "@mui/material";
import ISystem from "rdptypes/project/ISystem";
import * as React from "react";
import { IProjectWithId } from ".";
import {
    formatDateWithTime,
    formatProjectType,
} from "../../../docGeneration/DocumentGenerationHelpers";
import ILayout from "../../../model/project/ILayout";
import { ProjectType } from "../../../model/project/IProject";
import DeleteItemButton from './DeleteItemButton';
import DownloadFieldButton from "./DownloadFieldButton";

interface Props {
  projectWithId: IProjectWithId;
  highlightText: (text: string) => string | JSX.Element;
  onSelect: (projectId: string, layoutId?: string, systemId?: string) => void;
  showExportButton?: boolean;
  showProjectState: boolean;
}

const ProjectListTableRow: React.FC<Props> = (props) => {
  const {projectWithId, onSelect, highlightText} = props;
  const showExportButton = props.showExportButton || false;
  const [expanded, setExpanded] = React.useState(false);
  let layouts: {layout: ILayout, id: string}[] = [];
  for (let layoutId in projectWithId.project.layouts) {
    layouts.push({layout: projectWithId.project.layouts[layoutId], id: layoutId});
  }

  let linkStyle = {
    cursor: "pointer"
  }

  if (expanded && layouts.length > 1){
    let rows: JSX.Element[] = [];
    layouts.forEach((lwid, i) => {
      let systems: {id: string, system: ISystem}[] = [];
      for (let systemId in lwid.layout.systems) {
        systems.push({id: systemId, system: lwid.layout.systems[systemId]});
      }
      if (systems.length > 0){
        //multiple layouts, multiple systems
        systems.forEach((swid, j) => {
          let cellStyle={
            '&.MuiTableCell-root':{
              borderBottom: `1px solid rgba(224, 224, 224, ${(i === layouts.length - 1 && j === systems.length -1) ? 1 : 0})`
            },
          };
          rows.push(
            <TableRow key={swid.id}>
            <TableCell sx={{
              width: "2em", 
              '&.MuiTableCell-root':{
                borderBottom: `1px solid rgba(224, 224, 224, ${(i === layouts.length - 1 && j === systems.length -1) ? 1 : 0})`
              }
            }}>{(i === 0 && j === 0) ? <CollapseIcon sx={linkStyle} onClick={() => {setExpanded(false);}} style={{width: "2em"}}/> : ""}</TableCell>
            <TableCell sx={cellStyle}>{(i === 0 && j === 0) ? <span>{highlightText(projectWithId.project.name)}</span> : ""}</TableCell>
            {
              props.showProjectState && <TableCell sx={cellStyle}>{(i === 0 && j === 0) ? <span>{highlightText(projectWithId.project.projectState)}</span> : ""}</TableCell>
            }
            <TableCell sx={cellStyle}>{j === 0 ? <Link sx={linkStyle} onClick={() => {
                onSelect(projectWithId.id, lwid.id);}}>{lwid.layout.name}</Link> : ""}</TableCell>
            <TableCell sx={cellStyle}>{<Link sx={linkStyle} onClick={() => {onSelect(projectWithId.id, lwid.id, swid.id)}}>{swid.system.name}</Link>}</TableCell>
            <TableCell sx={cellStyle}>{formatProjectType(projectWithId.project.projectType)}</TableCell>
            <TableCell sx={cellStyle}>
              {formatDateWithTime(new Date(projectWithId.project.lastModifiedTimestamp))}
            </TableCell>
            <TableCell sx={cellStyle}>{projectWithId.project.lastModifiedUserId}</TableCell>
            {
              showExportButton 
                ? (
                  <TableCell sx={cellStyle}>
                    {
                      (i === 0 && j === 0) && <DownloadFieldButton projectWithId={projectWithId} />
                    }
                  </TableCell>
                )
                : null
            }
            <TableCell sx={cellStyle} onClick={(ev) => { ev.stopPropagation(); }}>
                {
                    j === 0 && <DeleteItemButton projectId={projectWithId.id} layoutId={lwid.id}/>
                }
            </TableCell>
          </TableRow>);
        });
      }
      else {
        let cellStyle={
          '&.MuiTableCell-root':{
            borderBottom: `1px solid rgba(224, 224, 224, ${i === layouts.length - 1 ? 1 : 0})`
          },
        };
        //multiple layouts, no systems for current layout
        rows.push(
          <TableRow key={lwid.id}>
          <TableCell sx={{width: "2em", '&.MuiTableCell-root':{
            borderBottom: `1px solid rgba(224, 224, 224, ${i === layouts.length - 1 ? 1 : 0})`
          }}}>{i===0 && <CollapseIcon sx={linkStyle} onClick={() => {setExpanded(false);}} style={{width: "2em"}}/>}</TableCell>
          <TableCell sx={cellStyle}>{i === 0 ? highlightText(projectWithId.project.name) : ""}</TableCell>
          {
            props.showProjectState && <TableCell sx={cellStyle}>{i === 0? <span>{highlightText(projectWithId.project.projectState)}</span> : ""}</TableCell>
          }
          <TableCell sx={{
              '&.MuiTableCell-root':{
                borderBottom: `1px solid rgba(224, 224, 224, ${i === layouts.length - 1 ? 1 : 0})`
              },
            }}>{<Link sx={linkStyle} onClick={() => {onSelect(projectWithId.id, lwid.id)}}>{lwid.layout.name}</Link>}</TableCell>
          <TableCell sx={cellStyle}/>
          <TableCell sx={cellStyle}>{formatProjectType(projectWithId.project.projectType)}</TableCell>
          <TableCell sx={cellStyle}>
            {formatDateWithTime(new Date(projectWithId.project.lastModifiedTimestamp))}
          </TableCell>
          <TableCell sx={cellStyle}>{projectWithId.project.lastModifiedUserId}</TableCell>
          {
            showExportButton 
              ? (
                <TableCell sx={cellStyle}>
                  {
                    (i === 0) && <DownloadFieldButton projectWithId={projectWithId} />
                  }
                </TableCell>
              )
              : null
          }
          <TableCell sx={cellStyle} onClick={(ev) => { ev.stopPropagation(); }}>
            <DeleteItemButton projectId={projectWithId.id} layoutId={lwid.id}/>
          </TableCell>
        </TableRow>
        );
      }
    });

    return (
     <>{rows}</>
    );
  }
  else {
    let systems: {id: string, system: ISystem}[] = [];
    if (layouts.length === 1){
      for (let systemId in layouts[0].layout.systems) {
        systems.push({id: systemId, system: layouts[0].layout.systems[systemId]});
      }
    }

    return (
      //either single layout for current field or unexpanded
      <TableRow
        sx={{
          '&.MuiTableRow-root:hover':{
            cursor: layouts.length > 1 ? 'pointer' : 'auto'
          },
        }}
        hover={layouts.length > 1}
        key={projectWithId.id}
        onClick={() => {layouts.length > 1 ? setExpanded(true) : () => {}}}
      >
        <TableCell sx={{width: "2em"}}>{layouts.length > 1 ? <ExpandIcon sx={{width: "2em"}}/> : <></>}</TableCell>
        <TableCell>{highlightText(projectWithId.project.name)}</TableCell>
        {
          props.showProjectState && <TableCell>{highlightText(projectWithId.project.projectState)}</TableCell>
        }
        <TableCell>
            <span>
                {
                    projectWithId.project.projectType === ProjectType.DesignOnly 
                        ? <span style={{color: "rgba(224, 224, 224,1)"}}>
                            Design only
                        </span> 
                        : (layouts.length > 1 || layouts.length === 0)
                            ? layouts.length  
                            : <Link 
                                sx={linkStyle} 
                                onClick={() => {onSelect(projectWithId.id, layouts[0].id)}}
                            >
                                {layouts[0].layout.name}
                            </Link>
                }
            </span>
        </TableCell>
        <TableCell>
            {
                systems.length > 1 
                    ? systems.length 
                    : systems.length === 1 
                        ? <Link 
                            sx={linkStyle} 
                            onClick={() => {onSelect(projectWithId.id, layouts[0].id, systems[0].id)}}
                            >
                                {systems[0].system.name}
                            </Link> 
                        : ""
            }
        </TableCell>
        <TableCell>{formatProjectType(projectWithId.project.projectType)}</TableCell>
        <TableCell>
          {formatDateWithTime(new Date(projectWithId.project.lastModifiedTimestamp))}
        </TableCell>
        <TableCell>{projectWithId.project.lastModifiedUserId}</TableCell>
        {
          showExportButton 
            ? (
              <TableCell>
                <DownloadFieldButton projectWithId={projectWithId} />
              </TableCell>
            )
            : null
        }
        <TableCell onClick={(ev) => { ev.stopPropagation(); }}>
          <DeleteItemButton projectId={projectWithId.id} />
        </TableCell>
      </TableRow>
    );
  }
};

export default ProjectListTableRow;
