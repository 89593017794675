/* 
    SUPERCEEDED, not removed so to not crash any DB relient on this action
    USE: NewSystemActionV2
*/
import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { AgriInjectTypes, Countries, GeneratorOptions, SystemTypes } from 'rdptypes/project/ISystemBase.AutoGenerated';
import { staticDevSettingsDbProvider } from '../db/DevSettingsDbProvider';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';

export const actionTypeId = "NewSystem";

interface ActionData extends IActionData {
    layoutId: string;

    systemId: string;

    systemType: SystemTypes;

    country: Countries;
}

const getBaseName = (systemType: SystemTypes) => {
    return systemType === SystemTypes.SwingArmRetro ? "Swing Arm Retro" : systemType === SystemTypes.Ancillary ? "Ancillary" : systemType === SystemTypes.CanalFeedMaxigator || systemType === SystemTypes.HoseFeedMaxigator ? "Lateral" : "Center Pivot";
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    let baseName = getBaseName(data.systemType);

    const customerSettings = staticDevSettingsDbProvider.customer.get();
    const dealerPricing = staticDevSettingsDbProvider.dealerPricing.get();
    
    let sameSystemTypeCount = 0;
    for (var systemId in state.layouts[data.layoutId].systems) {
        if (getBaseName(state.layouts[data.layoutId].systems[systemId].SystemProperties.SystemType) === baseName) {
            sameSystemTypeCount ++;
        }
    }

    state.layouts[data.layoutId].systems[data.systemId] = {
        // Always use the initial parts package ID. This action will never be used for systems created
        // after a newer parts package existed.
        partsPackageId: "2024060101",

        name: `${baseName} ${sameSystemTypeCount + 1}`,

        lastModifiedTimestamp: 0, // Updated to the actual timestamp in recordSystemChange
        lastModifiedUserId: "", // Updated to the actual user ID in recordLayoutChange

        SystemProperties: {
            SystemType: data.systemType
        },

        DealerProperties: {
            Country: data.country ?? Countries.UnitedStates
        },

        MainlineValveOptions: {
            Valves: {
                MainlineValve: []
            }
        },

        Ontrac: {
            Radio: {},
            SatellitePlus: {
                SatellitePlusUnit: []
            },
            Cell: {}
        },

        SprinklerProperties: {
            VRIZones: {
                Zone: []
            }
        },

        Options: {
            GeneratorOption: GeneratorOptions.None
        },

        FlangedSide: {
            Span: [],
            Tower: [],
            EndOfSystem: {
                EndGun: {}
            },
            Sprinklers: {
                BoomBacks: {},
                Package: []
            },
            SprinklerChart: {
                IrrigationProperties: {
                    EndGunIrrigationProperties: {},
                    SecondaryEndGunIrrigationProperties: {}
                },
                Outlet: []
            }
        },

        FlexSide: {
            Span: [],
            Tower: [],
            EndOfSystem: {
                EndGun: {}
            },
            Sprinklers: {
                BoomBacks: {},
                Package: []
            },
            SprinklerChart: {
                IrrigationProperties: {
                    EndGunIrrigationProperties: {},
                    SecondaryEndGunIrrigationProperties: {}
                },
                Outlet: []
            }
        },

        AgriInject: {
            AgriInjectType: AgriInjectTypes.None
        },
        SprinklerLube: {},
        HeatExchangers: {},
        ClemonsFilters: {},
        CheckValves: {},
        FlowmeterComponents: {},
        RC10: {},
        Circle: {
            CenterPivot: {
                TowOptions: {
                    Tires: {}
                }
            },
            KwikTow: {
                Tires: {}
            },
            SwingArm: {
                Tires: {}
            },
            SACRetro: {}
        },

        CropX: {},

        Lateral: {
            Tires: {},
            HoseFeed: {
                ElectricDragCordLength: 0, //setting a temporary default value here as currently page cmp defaults are not being used (defaultActions.tsx)
                HardHoseAdapters: 1 //setting a temporary default value here as currently page cmp defaults are not being used (defaultActions.tsx) - be careful because we need to check that Hose and Couplers type is not None (verified where we add PO records)
            },
            CanalFeed: {},
            Guidance: {
                FurrowOptions: {},
                CableOptions: {},
                BuriedWireOptions: {
                    Wire: {}
                }
            }
        },

        ControlPanel: {
            RemotePanelOptions: {}
        },

        SpanProperties: {},

        TowerProperties: {
            TireProperties: {}
        },

        mcp: {
        },

        QuoteProperties: {
            ProposalInformation: {
                pricing: {
                    installation: {
                        isTaxable: customerSettings.installationIsTaxable
                    },
                    freight: {
                        isTaxable: customerSettings.freightIsTaxable
                    },
                    pivotPad: {
                        isTaxable: customerSettings.pivotPadIsTaxable
                    },
                    tradeIn: {
                        isTaxable: customerSettings.tradeinIsTaxable
                    },
                    system: {
                        isTaxable: customerSettings.systemIsTaxable
                    },
                    onlyPrintTotalPrice: customerSettings.onlyPrintTotalPrice,
                    printListPrice: customerSettings.printListPrice,
                    markupPercentOverDealerCost: customerSettings.markupPercentOverDealerCost,
                    markupDollarOverDealerCost: customerSettings.markupDollarOverDealerCost,
                    listPriceDiscountPercent: customerSettings.listPriceDiscountPercent,
                    quotedPriceDollar: 0,
                    salesTaxPercent: customerSettings.salesTaxPercent,
                    deliveryDiscountPercent: dealerPricing ? dealerPricing.deliveryDiscountPercent : undefined,
                    tmDiscountPercent:  0,
                    programDiscountPercent:  dealerPricing ? dealerPricing.programDiscountPercent : undefined,
                    currency: customerSettings.currency
                }
            }
        }
    };
   
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});