import { Box, Card, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment } from "@mui/material";
import { Stack } from "@mui/system";
import i18next, { t } from "i18next";
import { EndGunTypes, EndOfSystemTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import { DEFAULT_CPA_RATIO } from "../../optimization/objectives/defaultCpaRatio";
import PositiveConvertedDecimalField from "../CustomInputComponents/PositiveConvertedDecimalField";
import { pageStyles, secondaryColor } from "../roe/styles";
import EndGunType, { EndGunTypeThrowSlider } from "./EndGunType";
import { EndOfSystemOptimizationType } from "./EndOfSystemOptimizationType";
import { EndOfSystemOptimizationType_Type } from "./EndOfSystemOptimizationType_Type";
import { NumberChoiceControl } from "./NumberChoiceControl";
import { PartialSpanLengthItem, RestrictSpanLengthsControl } from "./RestrictSpanLengthsControl";

export interface IOptimizeCenterPivotFormState {
    optimizeSystem: boolean;
    allowPartialPivots: boolean;
    allowWrapSpans: boolean;
    maxNumberofWraps: number;
    optimizeCenterPivotLocation: boolean;
    restrictSpanLengths: boolean;
    restrictedSpanLengths: PartialSpanLengthItem[];
    allowDropSpans: boolean;
    maxNumberOfDropSpans: number;
    endOfSystemType?: EndOfSystemTypes;
    restrictedEndBoomLengths: number[];
    sacType?: SwingArmLengths;
    sacIsLeading?: boolean;

    // clearances:
    setClearances?: boolean;
    sTowerClearanceFt?: number;
    hTowerClearanceFt?: number;

    // end guns:
    primaryEndGun: EndGunTypes;
    secondaryEndGun: EndGunTypes;
    primaryEndGunThrow?: number;
    secondaryEndGunThrow?: number;

    // sys radius:
    restrictMaxRadius?: boolean;
    maxRadiusFt?: number;
    restrictMinRadius?: boolean;
    minRadiusFt?: number;
    
    // whole field optimization
    maxNumberOfSystems?: number;
    cpaRatio?: number;
}

export const OPTIMIZATION_CENTER_PIVOT_FORM_STATE_DEFAULT: IOptimizeCenterPivotFormState = {
    optimizeSystem: false,
    allowPartialPivots: false,
    allowWrapSpans: false,
    maxNumberofWraps: 1,
    optimizeCenterPivotLocation: false,
    restrictSpanLengths: false,
    restrictedSpanLengths: [],
    allowDropSpans: false,
    maxNumberOfDropSpans: 1,
    endOfSystemType: undefined,
    restrictedEndBoomLengths: [],
    sacType: undefined,
    sacIsLeading: undefined,
    primaryEndGun: EndGunTypes.None,
    secondaryEndGun: EndGunTypes.None,
    cpaRatio: DEFAULT_CPA_RATIO
};

interface Props {
    formState: IOptimizeCenterPivotFormState;
    setFormState: (updatedFormState: IOptimizeCenterPivotFormState) => void;
    hasClearancesBeenSet: boolean;
}

const COLS_FULL = 12;
const COLS_LEFT_FULL = 4;
const COLS_LEFT_PARTIAL_1 = 1;
const COLS_LEFT_PARTIAL_2 = COLS_LEFT_FULL - COLS_LEFT_PARTIAL_1;
const COLS_RIGHT = COLS_FULL - COLS_LEFT_FULL;

const MAX_ALLOW_SYSTEMS_CHOICE = [
    1,2,3,4,5,6,7,8,9,10, 
    20, 30, 40, 50
]

const OptimizeCenterPivotForm: React.FC<Props> = ({ formState, setFormState, hasClearancesBeenSet }) => {
    const {
        optimizeSystem,
        allowPartialPivots,
        allowWrapSpans,
        maxNumberofWraps,
        optimizeCenterPivotLocation,
        restrictSpanLengths,
        restrictedSpanLengths,
        allowDropSpans,
        maxNumberOfDropSpans,
        restrictedEndBoomLengths,
        endOfSystemType,
        sacType,
        sacIsLeading,
        setClearances,
        sTowerClearanceFt,
        hTowerClearanceFt,
        primaryEndGun,
        secondaryEndGun,
        primaryEndGunThrow,
        secondaryEndGunThrow,
        restrictMaxRadius, maxRadiusFt,
        restrictMinRadius, minRadiusFt,
        maxNumberOfSystems,
        cpaRatio
    } = formState;
    const setOptimizeSystem = (optimizeSystem: boolean) => setFormState({ ...formState, optimizeSystem });
    const setAllowPartialPivots = (allowPartialPivots: boolean) => setFormState({ ...formState, allowPartialPivots });
    const setAllowWrapSpans = (allowWrapSpans: boolean) => setFormState({ ...formState, allowWrapSpans });
    const setMaxNumberOfWraps = (maxNumberofWraps: number) => setFormState({ ...formState, maxNumberofWraps });
    const setOptimizeCenterPivotLocation = (optimizeCenterPivotLocation: boolean) =>
        setFormState({ ...formState, optimizeCenterPivotLocation });
    const setRestrictSpanLengths = (restrictSpanLengths: boolean) =>
        setFormState({ ...formState, restrictSpanLengths });
    const setAllowDropSpans = (allowDropSpans: boolean) => setFormState({ ...formState, allowDropSpans });
    const setMaxNumberOfDropSpans = (maxNumberOfDropSpans: number) =>
        setFormState({ ...formState, maxNumberOfDropSpans });
    const setRestrictedSpanLengths = (restrictedSpanLengths: PartialSpanLengthItem[]) =>
        setFormState({ ...formState, restrictedSpanLengths });

    const setSetClearances = (b: boolean) => setFormState({ ...formState, setClearances: b });
    const setSTowerClearanceFt = (v: number) => setFormState({ ...formState, sTowerClearanceFt: v });
    const setHTowerClearanceFt = (v: number) => setFormState({ ...formState, hTowerClearanceFt: v });

    const setPrimaryEndGun = (v: EndGunTypes) => setFormState({ ...formState, primaryEndGun: v });
    const setSecondaryEndGun = (v: EndGunTypes) => setFormState({ ...formState, secondaryEndGun: v });
    const setPrimaryEndGunThrow = (v?: number) => setFormState({ ...formState, primaryEndGunThrow: v });
    const setSecondaryEndGunThrow = (v?: number) => setFormState({ ...formState, secondaryEndGunThrow: v });

    const setEndOfSystem = (args: {
        endOfSystemType?: EndOfSystemTypes;
        restrictedEndBoomLengths: number[];
        sacType?: SwingArmLengths;
        sacIsLeading?: boolean;
    }) => {
        setFormState({
            ...formState,
            ...args,
        });
    };
    const setMaxNumberOfSystems = (v?: number) => setFormState({ ...formState, maxNumberOfSystems: v });

    const uiDisableOptimizeCenterPivotLocation = !optimizeSystem;
    const uiOptimizeCenterPivotLocation = !uiDisableOptimizeCenterPivotLocation && optimizeCenterPivotLocation;

    const uiEnableAllowWrapSpan = allowPartialPivots && optimizeSystem;

    const settings = React.useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;
    const canSetClearances =
        !hasClearancesBeenSet &&
        endOfSystemType === EndOfSystemTypes.SAC &&
        sacType !== undefined &&
        sacType !== SwingArmLengths.None;

    const ns = "optimization-dialog.";
    const subns = "center-pivot.";

    let formInputLabelStyle = {
        ...pageStyles.formInputLabelStyle,
        color: secondaryColor
    };

    const bEndOfSystem = !!endOfSystemType;
    const setBEndOfSystem = (b: boolean) => {
        setEndOfSystemType(b ? EndOfSystemTypes.EndBoom : undefined);
    }
    const setEndOfSystemType = (endOfSystemType?: EndOfSystemTypes) => {
        setEndOfSystem({
            endOfSystemType,
            restrictedEndBoomLengths: []
        })
    }
    const setWrapSpans = (n: number) => {
        setFormState({
            ...formState,
            allowWrapSpans: n > 0,
            maxNumberofWraps: n
        })
    }

    const setRestrictMaxRadius = (b: boolean) => setFormState({ ...formState, restrictMaxRadius: b });
    const setRestrictMinRadius = (b: boolean) => setFormState({ ...formState, restrictMinRadius: b });
    const setMaxRadius = (v: number) => setFormState({ ...formState, maxRadiusFt: v });
    const setMinRadius = (v: number) => setFormState({ ...formState, minRadiusFt: v });

    const uiCpaRatioHidden = uiDisableOptimizeCenterPivotLocation || !optimizeCenterPivotLocation || ((maxNumberOfSystems ?? 1) === 1);

    const setCpaRatio = (v: number) => setFormState({ ...formState, cpaRatio: v });

    return (
        <Stack spacing={1}>
            <Card variant="outlined" style={pageStyles.cardStyle.outlined}>
                <Grid container columns={COLS_FULL} justifyContent={"center"} spacing={1}>
                    <Grid item xs={COLS_FULL}>
                        <FormControlLabel
                            label={t(ns + "optimize-system")}
                            control={
                                <Checkbox
                                    checked={optimizeSystem}
                                    onChange={(ev) => setOptimizeSystem(ev.target.checked)}
                                />
                            }
                        />
                    </Grid>

                    <Grid item xs={COLS_FULL}>
                        <FormControlLabel
                            label={t(ns + subns + "optimize-center-pivot-location")}
                            control={
                                <Checkbox
                                    disabled={uiDisableOptimizeCenterPivotLocation}
                                    checked={uiOptimizeCenterPivotLocation}
                                    onChange={(ev) => setOptimizeCenterPivotLocation(ev.target.checked)}
                                />
                            }
                        />
                    </Grid>
                    
                    {/* <Grid item xs={COLS_RIGHT}>
                        <NumberOfSystemsControl
                            disabled={!uiOptimizeCenterPivotLocation}
                            currentValue={maxNumberOfSystems}
                            setter={setMaxNumberOfSystems}
                            choices={MAX_ALLOW_SYSTEMS_CHOICE}
                            title={t(ns + subns + "max-system-number")}
                            max
                        />
                    </Grid> */}
                    
                    {
                        // !uiCpaRatioHidden && (
                        //     <>
                        //         <Grid item xs={COLS_LEFT_PARTIAL_1}>
                        //         </Grid>
                        //         <Grid item xs={COLS_LEFT_PARTIAL_2}>
                        //             <FormLabel>{t(ns + subns + "cost-per-acre-ratio")}</FormLabel>
                        //             {/* <FormControlLabel
                        //                 label={t(ns + subns + "cost-per-acre-ratio")}
                        //             /> */}
                        //         </Grid>
                                
                        //         <Grid item xs={COLS_RIGHT}>
                        //             <Slider
                        //                 disabled={uiCpaRatioHidden}
                        //                 value={cpaRatio || DEFAULT_CPA_RATIO}
                        //                 shiftStep={1}
                        //                 step={0.1}
                        //                 min={1.1}
                        //                 max={5}
                        //                 onChange={(ev, v) => {
                        //                     if (typeof v === "number") {
                        //                         setCpaRatio(v);
                        //                     }
                        //                 }}
                        //                 valueLabelDisplay="auto"
                        //             />
                        //             <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        //             <Typography
                        //                 variant="body2"
                        //                 onClick={() => setCpaRatio(1.1)}
                        //                 sx={{ cursor: 'pointer' }}
                        //             >
                        //                 Conservative (1.1)
                        //             </Typography>
                        //             <Typography
                        //                 variant="body2"
                        //                 onClick={() => setCpaRatio(5)}
                        //                 sx={{ cursor: 'pointer' }}
                        //             >
                        //                 Liberal (5.0)
                        //             </Typography>
                        //             </Box>
                        //         </Grid>
                        //     </>
                        // )
                    }

                </Grid>
                

            </Card>

            <Card variant="outlined" style={pageStyles.cardStyle.outlined}>
                <FormControl disabled={!optimizeSystem}>
                    <Grid container columns={COLS_FULL} justifyContent={"center"} spacing={1}>

                        <Grid item xs={COLS_FULL}>
                            <FormControlLabel
                                label={t(ns + subns + "allow-partial-pivots")}
                                control={
                                    <Checkbox
                                        checked={allowPartialPivots}
                                        onChange={(ev) => setAllowPartialPivots(ev.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        
                        <Grid item xs={COLS_LEFT_PARTIAL_1} />
                        <Grid item xs={COLS_LEFT_PARTIAL_2}>
                            <FormControlLabel
                                label={t(ns + subns + "allow-wrap-spans")}
                                control={
                                    <Checkbox
                                        disabled={!uiEnableAllowWrapSpan}
                                        checked={allowPartialPivots && allowWrapSpans}
                                        onChange={(ev) => setAllowWrapSpans(ev.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT}>
                            <NumberChoiceControl
                                currentValue={(allowPartialPivots && allowWrapSpans) ? maxNumberofWraps : 0}
                                setter={setWrapSpans}
                                disabled={!uiEnableAllowWrapSpan || !allowWrapSpans}
                                choices={[1,2,3]}
                                title={t(ns + subns + "max-wrap-number")}
                            />
                        </Grid>
                        
                        <Grid item xs={COLS_LEFT_FULL}>
                            <FormControlLabel
                                label={t(ns + "choose-span-lengths")}
                                control={
                                    <Checkbox
                                        checked={restrictSpanLengths}
                                        onChange={(ev) => setRestrictSpanLengths(ev.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT}>
                            <RestrictSpanLengthsControl
                                restrictSpanLengths={restrictSpanLengths && optimizeSystem}
                                restrictedSpanLengths={restrictedSpanLengths}
                                onChangeRestrictedSpanLengths={setRestrictedSpanLengths}
                                options={{
                                    type: "Span"
                                }}
                            />
                        </Grid>

                        <Grid item xs={COLS_FULL}>
                            <FormControlLabel
                                label={i18next.format(t(ns + subns + "end-of-system"), "capitalize")}
                                control={
                                    <Checkbox
                                        checked={bEndOfSystem}
                                        onChange={(ev) => setBEndOfSystem(ev.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_LEFT_PARTIAL_1} />
                        <Grid item xs={COLS_LEFT_PARTIAL_2}>
                            <EndOfSystemOptimizationType_Type
                                sacType={sacType}
                                endOfSystemType={endOfSystemType}
                                restrictedEndBoomLengths={restrictedEndBoomLengths}
                                onChangeEndOfSystem={setEndOfSystem}
                                canHaveSac={true}
                                disabled={!optimizeSystem || !bEndOfSystem}
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT}>
                            <EndOfSystemOptimizationType
                                sacType={sacType}
                                endOfSystemType={endOfSystemType}
                                restrictedEndBoomLengths={restrictedEndBoomLengths}
                                onChangeEndOfSystem={setEndOfSystem}
                                canHaveSac={true}
                                disabled={!optimizeSystem}
                                sacIsLeading={sacIsLeading}
                                showTypeChoice={false}
                            />
                        </Grid>

                        <Grid item xs={COLS_FULL}>
                            <FormControlLabel
                                label={t("common-phrases.sltPrimaryEndGun")}
                                control={
                                    <Checkbox
                                        checked={primaryEndGun !== EndGunTypes.None}
                                        onChange={(ev) => setPrimaryEndGun(ev.target.checked ? EndGunTypes.SR100 : EndGunTypes.None)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_LEFT_PARTIAL_1} />
                        <Grid item xs={COLS_LEFT_PARTIAL_2}>
                            <EndGunType
                                type={primaryEndGun}
                                setType={(v) => setPrimaryEndGun(v)}
                                throwFeet={primaryEndGunThrow}
                                setThrowFeet={setPrimaryEndGunThrow}
                                disabled={!optimizeSystem || primaryEndGun === EndGunTypes.None}
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT} sx={{ minHeight: 60 }}>
                            <EndGunTypeThrowSlider
                                type={primaryEndGun}
                                throwFeet={primaryEndGunThrow}
                                setThrowFeet={setPrimaryEndGunThrow}
                                disabled={!optimizeSystem || primaryEndGun === EndGunTypes.None}
                            />
                        </Grid>

                        <Grid item xs={COLS_FULL}>
                            <FormControlLabel
                                label={t("common-phrases.sltSecondaryEndGun")}
                                control={
                                    <Checkbox
                                        checked={secondaryEndGun !== EndGunTypes.None}
                                        onChange={(ev) => setSecondaryEndGun(ev.target.checked ? EndGunTypes.R55 : EndGunTypes.None)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_LEFT_PARTIAL_1} />
                        <Grid item xs={COLS_LEFT_PARTIAL_2}>
                            <EndGunType
                                type={secondaryEndGun}
                                setType={(v) => setSecondaryEndGun(v)}
                                throwFeet={secondaryEndGunThrow}
                                setThrowFeet={setSecondaryEndGunThrow}
                                isSecondary
                                disabled={!optimizeSystem || secondaryEndGun === EndGunTypes.None}
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT} sx={{ minHeight: 60 }}>
                            <EndGunTypeThrowSlider
                                type={secondaryEndGun}
                                throwFeet={secondaryEndGunThrow}
                                setThrowFeet={setSecondaryEndGunThrow}
                                disabled={!optimizeSystem || secondaryEndGun === EndGunTypes.None}
                            />
                        </Grid>
                        
                        <Grid item xs={COLS_LEFT_FULL}>
                            <FormControlLabel
                                label={t(ns + subns + "restrict-min-system-radius")}
                                control={
                                    <Checkbox
                                        checked={restrictMinRadius}
                                        onChange={(ev) => setRestrictMinRadius(ev.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT}>
                            <PositiveConvertedDecimalField
                                displayUnit={displaySettings.lengths}
                                originalUnit="feet"
                                value={minRadiusFt || 0}
                                setValue={setMinRadius}
                                textFieldProps={{
                                    disabled: !(optimizeSystem && restrictMinRadius),
                                    fullWidth: true
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {t(DisplayLengthUnitBuilder.shortName(displaySettings.lengths))}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        
                        <Grid item xs={COLS_LEFT_FULL}>
                            <FormControlLabel
                                label={t(ns + subns + "restrict-max-system-radius")}
                                control={
                                    <Checkbox
                                        checked={restrictMaxRadius}
                                        onChange={(ev) => setRestrictMaxRadius(ev.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT}>
                            <PositiveConvertedDecimalField
                                displayUnit={displaySettings.lengths}
                                originalUnit="feet"
                                value={maxRadiusFt || 0}
                                setValue={setMaxRadius}
                                textFieldProps={{
                                    disabled: !(optimizeSystem && restrictMaxRadius),
                                    fullWidth: true
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {t(DisplayLengthUnitBuilder.shortName(displaySettings.lengths))}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                    </Grid>
                </FormControl>

            </Card>


            <Card variant="outlined" style={pageStyles.cardStyle.outlined}>
                <FormControl disabled={!optimizeSystem} fullWidth>
                    <Grid container columns={COLS_FULL} justifyContent={"center"}>

                        <Grid item xs={COLS_LEFT_FULL}>
                            <FormControl disabled={!optimizeSystem || !canSetClearances}>
                                <FormControlLabel
                                    label={t("map-display.speed-dial.set-clearance.title")}
                                    control={
                                        <Checkbox
                                            checked={canSetClearances && setClearances}
                                            onChange={(ev) => setSetClearances(ev.target.checked)}
                                        />
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={COLS_RIGHT} justifyContent={'center'} minHeight={45}>
                        {canSetClearances ? (
                            <Stack direction={'row'} spacing={1}>
                                <FormControl fullWidth disabled={!optimizeSystem || !setClearances || !canSetClearances}>
                                    <PositiveConvertedDecimalField
                                        textFieldProps={{
                                            disabled: !setClearances,
                                            label: `${i18next.format(t("h-tower"), "capitalize-each")} (${t(
                                                DisplayLengthUnitBuilder.shortName(displaySettings.lengths)
                                            )})`,
                                        }}
                                        value={hTowerClearanceFt || 0}
                                        setValue={setHTowerClearanceFt}
                                        originalUnit="feet"
                                        displayUnit={displaySettings.lengths}
                                    />
                                </FormControl>
                                <FormControl fullWidth disabled={!optimizeSystem || !setClearances || !canSetClearances}>
                                    <PositiveConvertedDecimalField
                                        textFieldProps={{
                                            disabled: !setClearances,
                                            label: `${i18next.format(t("s-tower2"), "capitalize-each")} (${t(
                                                DisplayLengthUnitBuilder.shortName(displaySettings.lengths)
                                            )})`,
                                        }}
                                        value={sTowerClearanceFt || 0}
                                        setValue={setSTowerClearanceFt}
                                        originalUnit="feet"
                                        displayUnit={displaySettings.lengths}
                                    />
                                </FormControl>
                            </Stack>
                        ) : (
                            <Box sx={{ color: "rgba(0, 0, 0, 0.38)", alignSelf: "center" }}>
                                <em>
                                    {endOfSystemType === EndOfSystemTypes.SAC
                                        ? t(ns + subns + "clearances-previously-set")
                                        : t(ns + subns + "clearances-only-for-sac")}
                                </em>
                            </Box>
                        )}
                        </Grid>
                       

                    </Grid>
                </FormControl>

            </Card>
        </Stack>
    );
};

export default OptimizeCenterPivotForm;
