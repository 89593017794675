import { EndGunTypes } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import { HasEnergySaverPackage, HasSwingArmCorner, IsDualSided, IsEDMP } from "../SystemFunctions";
import { VRIOptionOK } from "../Valids.Valids";

export default class SprinklersConfigForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        let sprinklerProperties = this.Quote.System.SprinklerProperties;

        if (sprinklerProperties.VRIZones && sprinklerProperties.VRIZones.Zone.length){
            //VRI zones enabled
            let towerCount = this.Quote.System.FlangedSide.Tower.length;
            if (IsDualSided(this.Quote.System)) {
                towerCount += this.Quote.System.FlexSide.Tower.length;
            }

            let controlPanel = this.Quote.System.ControlPanel;
            let hasSecondEndGun = false;
            if (this.Quote.System.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary && 
                this.Quote.System.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary !== EndGunTypes.None){
                hasSecondEndGun = true;
            }

            if (controlPanel && !VRIOptionOK({
                TowerCount: towerCount,
                IsEDMP: IsEDMP(this.Quote.System),
                ElectricalFrequency: controlPanel.ElectricalFrequency, 
                    HasSwingArmCorner: HasSwingArmCorner(this.Quote.System),
                    HasESP: HasEnergySaverPackage(this.Quote.System),
                    PanelModel: controlPanel.PanelModel,
                    EndTowerPositioningType: controlPanel.EndTowerPositioning,
                    SystemType: this.Quote.System.SystemProperties.SystemType,
                    HoseFeedType: this.Quote.System.Lateral?.HoseFeed?.HoseFeedType, 
                    AnyDisconnecting: this.Quote.RightSpansClass.AnyDisconnecting(),
                    HasEndGunSecondary: hasSecondEndGun,
                })){
                this.Quote.Warnings.Add(`Current system configuration does not support VRI zones.`);
            }

        }
    }
}