import { Alert, Box, Button, DialogActions, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import IAction from "rdptypes/IAction";
import * as React from "react";
import { useContext, useState } from "react";
import { getDiscountConfigFromUserData } from "roedata/ProposalHelpers";
import { createNewMultiAction } from "../../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import DealerDataCtx from "../../../../userData/DealerDataCtx";

interface Props {
    proposalSettings: {
        layoutId: string;
        systemId: string;
        dbPrj: IDbProject;
    }
    onClose: () => void;
}

interface ValidStatuses {
    programDiscountPercentValid: boolean;
    deliveryDiscountPercentValid: boolean;
    tmDiscountPercentValid: boolean;
  }
  

const DealerPricingModalBody: React.FC<Props> = (props) => {
    const ns = "dealer-settings.";
    const subns = "dealer-pricing-options.";
        
    const authState = useContext(AuthCtx);
    const user = useContext(DealerDataCtx);

    const system =  props.proposalSettings.dbPrj.state.layouts[props.proposalSettings.layoutId].systems[props.proposalSettings.systemId];
    const discountConfig = getDiscountConfigFromUserData(user, system);
    const programDiscountPercent = discountConfig.ProgramDiscount;
    const deliveryDiscountPercent = discountConfig.DeliveryDiscount;
    const tmDiscountPercent = discountConfig.TerritoryManagerDiscount;

    const [tmValue, setTmValue] = useState(tmDiscountPercent);
    const [deliveryValue, setDeliveryValue] = useState(deliveryDiscountPercent);
    const [programValue, setProgramValue] = useState(programDiscountPercent);

    const sanitizedTmValue = isNaN(tmValue) ? 0 : tmValue;
    const sanitizedDeliveryValue = isNaN(deliveryValue) ? 0 : deliveryValue;
    const sanitizedProgramValue = isNaN(programValue) ? 0 : programValue;

    const validStatuses: ValidStatuses = {
        programDiscountPercentValid: sanitizedProgramValue <= user.discounts.program,
        deliveryDiscountPercentValid: sanitizedDeliveryValue <= user.discounts.delivery,
        tmDiscountPercentValid: sanitizedTmValue <= user.discounts.tm
    }
    const isValid = (validStatuses.programDiscountPercentValid && validStatuses.deliveryDiscountPercentValid && validStatuses.tmDiscountPercentValid);
    const hasChanges = sanitizedTmValue !== tmDiscountPercent || sanitizedDeliveryValue !== deliveryDiscountPercent || sanitizedProgramValue !== programDiscountPercent;
    const canSave = isValid;
    
    const handleSave = () => {
        if (!canSave) return;
        if (hasChanges) {
            const actions: IAction[] = [
                createNewUpdateSystemPropertyAction(
                    props.proposalSettings.layoutId,
                    props.proposalSettings.systemId,
                    "QuoteProperties.ProposalInformation.pricing.programDiscountPercent",
                    sanitizedProgramValue,
                    authState),
                createNewUpdateSystemPropertyAction(
                    props.proposalSettings.layoutId,
                    props.proposalSettings.systemId,
                    "QuoteProperties.ProposalInformation.pricing.deliveryDiscountPercent",
                    sanitizedDeliveryValue,
                    authState),
                createNewUpdateSystemPropertyAction(
                    props.proposalSettings.layoutId,
                    props.proposalSettings.systemId,
                    "QuoteProperties.ProposalInformation.pricing.tmDiscountPercent",
                    sanitizedTmValue,
                    authState)
            ]
            props.proposalSettings.dbPrj.pushAction(
                createNewMultiAction(actions, authState)
            );
        }
        props.onClose();
    }

    return (
        <>
            <DialogContent>
                <Box sx={{ overflow: "auto", padding: 2, minWidth: 500 }}>
                    <Typography style={{marginBottom: 10}}>
                        The following settings will apply to this proposal <em>only</em>.
                    </Typography>
                    <Stack spacing={2} style={{maxWidth: 200, marginBottom: 10}}>
                        <TextField
                            type="number"
                            label={t(ns + subns + "program-discount-pc")}
                            value={programValue}
                            error={!validStatuses.programDiscountPercentValid}
                            onChange={(e) => {
                                let numVal = parseFloat(e.target.value);
                                setProgramValue(numVal);
                            }}
                            onBlur={(e) => {
                                let numVal = parseFloat(e.target.value);
                                if (isNaN(numVal)) numVal = 0;
                                setProgramValue(numVal);
                            }}
                        />
                        <TextField
                            type="number"
                            label={t(ns + subns + "delivery-discount-pc")}
                            value={deliveryValue}
                            error={!validStatuses.deliveryDiscountPercentValid}
                            onChange={(e) => {
                                let numVal = parseFloat(e.target.value);
                                setDeliveryValue(numVal);
                            }}
                            onBlur={(e) => {
                                let numVal = parseFloat(e.target.value);
                                if (isNaN(numVal)) numVal = 0;
                                setDeliveryValue(numVal);
                            }}
                        />
                        <TextField
                            type="number"
                            label={t(ns + subns + "tm-discount-pc")}
                            value={tmValue}
                            error={!validStatuses.tmDiscountPercentValid}
                            onChange={(e) => {
                                let numVal = parseFloat(e.target.value);
                                setTmValue(numVal);
                            }}
                            onBlur={(e) => {
                                let numVal = parseFloat(e.target.value);
                                if (isNaN(numVal)) numVal = 0;
                                setTmValue(numVal);
                            }}
                        />

                    </Stack>
                    {
                        (!isValid) && <Typography>
                            Your changes cannot be saved because of the following error(s):
                        </Typography>
                    }
                    {
                        !validStatuses.programDiscountPercentValid && <Alert style={{marginTop: 10}} severity="error">Program discount cannot be higher than {user.discounts.program}%</Alert>
                    }
                    {
                        !validStatuses.deliveryDiscountPercentValid && <Alert style={{marginTop: 10}} severity="error">Delivery discount cannot be higher than {user.discounts.delivery}%</Alert>
                    }
                    {
                        !validStatuses.tmDiscountPercentValid && <Alert style={{marginTop: 10}} severity="error">TM discount cannot be higher than {user.discounts.tm}%</Alert>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={handleSave} disabled={!canSave} >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}

export default DealerPricingModalBody;