import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Position, area, booleanEqual, convertArea, feature, lineString, point } from "@turf/turf";
import { Feature, Geometry } from "geojson";
import IAction from "rdptypes/IAction";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import LateralGeometryHelper from "../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { getSystemValidity } from "../../GeometryHelpers/SystemGeometryHelpers/getSystemValidity";
import { createNewMultiAction } from "../../actions/MultiAction";
import { createNewLayoutAction } from "../../actions/NewLayoutAction";
import { createNewUpdateLayoutPropertyAction } from "../../actions/UpdateLayoutProperty";
import { createBlankProject, executeAction } from "../../actions/actionExecutorRegistry";
import IAuthState from "../../auth/IAuthState";
import { staticDevSettingsDbProvider } from "../../db/DevSettingsDbProvider";
import IDbProject from "../../db/IDbProject";
import { DisplayAreaUnitBuilder, DisplayAreaUnits } from "../../helpers/areas";
import { createLateralActionsFromOptimizedSystem } from "../../helpers/optimizedSystemHelpers/createLateralActionsFromOptimizedSystem";
import IProject from "../../model/project/IProject";
import * as optimizeLateralModule from "../../optimization/lateral";
import * as CONSTANTS from "./copied-from-mapbox-gl-draw/constants";

const SimpleSelectMode = MapboxDraw.modes.simple_select;
export const DynamicLateralOptimizationMode = { ...SimpleSelectMode };
export default DynamicLateralOptimizationMode;

const DEV_TEST = false;
const DEV_TEST_BEGIN = undefined;
const DEV_TEST_END = undefined;

// const DEV_TEST = true;
// const DEV_TEST_BEGIN = [-97.71082152974576, 40.148621663344244];
// const DEV_TEST_END = [-97.71214470168592, 40.151343824288205];

interface IOptions {
    optimizationSettings: optimizeLateralModule.IOptimizationSettings;
    project: IProject;
    layoutId: string;
    authState: IAuthState;
    systemType: SystemTypes.CanalFeedMaxigator | SystemTypes.HoseFeedMaxigator;
}

export interface IDrawUpdateExtEvent_DynamicLateralOptimizationResult {
    action: "dynamic_lateral_optimization_result";
    definition: {
        optimizedSystem: optimizeLateralModule.IOptimizedSystem;
        systemType: SystemTypes.CanalFeedMaxigator | SystemTypes.HoseFeedMaxigator;
        canalFeed?: {
            canalWidthFeet: number;
            distanceFromCanalCenterToFwdSide: number;
            distanceFromCanalCenterToAftSide: number;
        };
        endFeed?: boolean;
    };
}

interface IArgs {
    position: Position;
    optimizationSettings: optimizeLateralModule.IOptimizationSettings;
    project: IProject;
    layoutId: string;
    authState: IAuthState;
    startPosition: Position;
    endPosition: Position;
    systemType: SystemTypes.CanalFeedMaxigator | SystemTypes.HoseFeedMaxigator;
    canalFeed?: {
      canalWidthFeet: number;
      distanceFromCanalCenterToFwdSide: number;
      distanceFromCanalCenterToAftSide: number;
  };
}
const handlePreviewOptimizedDynamicSystem = (args: IArgs, areaDisplayUnits: DisplayAreaUnits) => {
    const features: Feature<Geometry>[] = [];
    // const t = createTimerLog("inner");
    const optimizedSystem = booleanEqual(point(args.endPosition), point(args.startPosition))
        ? undefined
        : optimizeLateralModule.optimize({
            project: args.project,
            layoutId: args.layoutId,
            optimizationSettings: {
                ...args.optimizationSettings,
                // NOTE: reverse the points so that fwd side if up if line drawn left->right in deshler, NE
                feedLine: lineString([args.endPosition, args.startPosition]).geometry,
            },
        });
    // t.logDeltaMS("optimization")
    let irrigatedAreaLabel = "-";
    if (optimizedSystem) {
        const fakeState = createBlankProject();
        const dbPrjFaker: IDbProject = {
            state: fakeState,
            pushAction: async (action: IAction) => {
                executeAction(action, fakeState);
                return fakeState;
            },
            readonly: false,
        };
        const newLayoutAction = createNewLayoutAction(args.authState);
        dbPrjFaker.pushAction(newLayoutAction.action);
        dbPrjFaker.pushAction(
            createNewUpdateLayoutPropertyAction(
                newLayoutAction.layoutId,
                "fieldBoundary",
                args.project.layouts[args.layoutId].fieldBoundary,
                args.authState
            )
        );
        const { systemId, actions } = createLateralActionsFromOptimizedSystem({
            optimizedSystem,
            authState: args.authState,
            layoutId: newLayoutAction.layoutId,
            canalFeed: args.canalFeed,
            systemType: args.systemType,
            endFeed: args.optimizationSettings.endFeed,
            dbPrj: dbPrjFaker,
            fex: undefined
        });
        if (actions.length) {
            dbPrjFaker.pushAction(createNewMultiAction(actions, args.authState));
        }
        const f = LateralGeometryHelper.createSingleGeoJSONFeature(
            dbPrjFaker.state.layouts[newLayoutAction.layoutId].systems[systemId]
        )
        if (f.flangedSidePolygon) {
            features.push(
                feature(
                    f.flangedSidePolygon,
                    {    
                        rdpFeatureType: "dynamicOptimization/irrigatedArea-flangedSide",
                    }
                )
            )
        }
        if (f.flexSidePolygon) {
            features.push(
                feature(
                    f.flexSidePolygon,
                    {    
                        rdpFeatureType: "dynamicOptimization/irrigatedArea-flexSide",
                    }
                )
            )
        }
        if (f.geometry.type === 'LineString') {
            features.push(
                feature(
                    f.geometry,
                    {    
                        rdpFeatureType: "dynamicOptimization/feedLine-invalid"
                    }
                )
            )
        }
        if (f.wheelTracks) {
            features.push(
                ...f.wheelTracks.map(wt => (
                    feature(
                        wt,
                        {  
                            rdpFeatureType:"dynamicOptimization/wheelTrack"
                        }
                    )
                ))
            )

        }
        // t.logDeltaMS("draw")

        const irrigatedAreaFlangedSideM2 = f.flangedSidePolygon?.type === 'Polygon' ? area(f.flangedSidePolygon) : 0;
        const irrigatedAreaFlexSideM2 = f.flexSidePolygon?.type === 'Polygon' ? area(f.flexSidePolygon) : 0;
        const irrigatedAreaM2 = irrigatedAreaFlangedSideM2 + irrigatedAreaFlexSideM2;
        if (irrigatedAreaM2 > 0) {
            const irrigatedAcres = convertArea(irrigatedAreaM2, 'meters', 'acres');
            irrigatedAreaLabel = new DisplayAreaUnitBuilder(irrigatedAcres, 'acres')
                .convert(areaDisplayUnits)
                .appendValue(2)
                .appendString(" ")
                .appendShortName()
                .toString();
            
        }
        if (DEV_TEST && f.geometry.type !== 'LineString') {
            dbPrjFaker.pushAction(
                createNewUpdateLayoutPropertyAction(
                    newLayoutAction.layoutId,
                    "obstacles",
                    args.project.layouts[args.layoutId].obstacles,
                    args.authState
                )
            );
            dbPrjFaker.pushAction(
                createNewUpdateLayoutPropertyAction(
                    newLayoutAction.layoutId,
                    "wheelObstacles",
                    args.project.layouts[args.layoutId].wheelObstacles,
                    args.authState
                )
            );
            dbPrjFaker.state.systemClearance = args.project.systemClearance;
            const a = new LateralGeometryHelper({ project: dbPrjFaker.state, layoutId: newLayoutAction.layoutId, systemId }).getSystemValidityArgs();
            const validity = getSystemValidity(
                "dyanmic",
                "dyanmic",
                a
            )
            // console.log("v", validity, args.position)
            if (validity !== 'valid') {
                console.log("v", validity, args.startPosition, args.position)
            }
        }
    }
    return {
        features,
        optimizedSystem,
        irrigatedAreaLabel
    };
};

// Notes:
DynamicLateralOptimizationMode.onSetup = function (this, options: IOptions) {
    const startPoint = this.newFeature({
        type: CONSTANTS.geojsonTypes.FEATURE,
        properties: {
            rdpFeatureType: "dynamicCenterValid"
        },
        geometry: {
            type: CONSTANTS.geojsonTypes.POINT,
            coordinates: [],
        },
    });
    const endPoint = this.newFeature({
        type: CONSTANTS.geojsonTypes.FEATURE,
        properties: {
            rdpFeatureType: "dynamicCenterInvalid",
            rdpDynamicOptimizationLabel: true
        },
        geometry: {
            type: CONSTANTS.geojsonTypes.POINT,
            coordinates: [],
        },
    });

    this.addFeature(startPoint);
    this.addFeature(endPoint);

    // turn the opts into state.
    const state = {
        dragMoveLocation: null,
        boxSelectStartLocation: null,
        boxSelectElement: undefined,
        boxSelecting: false,
        canBoxSelect: false,
        dragMoving: false,
        canDragMove: false,
        initiallySelectedFeatureIds: [],

        // custom:
        options,
        optimizationFeatureIds: [],
        optimizedSystem: undefined,

        startPoint,
        endPoint,
        
        displaySettings: staticDevSettingsDbProvider.display.get()
    };

    this.fireActionable();

    this.setActionableState({
        combineFeatures: false,
        uncombineFeatures: false,
        trash: true,
    });

    return state;
};

DynamicLateralOptimizationMode.toDisplayFeatures = function (
    this: MapboxDraw.DrawCustomModeThis,
    state,
    feature: Feature,
    display
) {
    if (
        feature.properties.id === state.startPoint.id &&
        (feature.geometry.type !== "Point" || feature.geometry.coordinates.length !== 2)
    ) {
        return;
    }
    if (
        feature.properties.id === state.endPoint.id &&
        (feature.geometry.type !== "Point" || feature.geometry.coordinates.length !== 2)
    ) {
        return;
    }
    display(feature);
};

DynamicLateralOptimizationMode.onMouseMove = function (this, state, e) {
    // console.log("onMouseMove start")
    if (!state.startPoint.coordinates.length) {
        this.updateUIClasses({ mouse: CONSTANTS.cursors.ADD });
        return;
    }

    // const t = createTimerLog("onMouseMove");
    const { lngLat } = e;
    const movedPoint = (DEV_TEST && DEV_TEST_END)
        ? DEV_TEST_END
        : [lngLat.lng, lngLat.lat];
    const { authState, layoutId, optimizationSettings, project, systemType } = state.options as IOptions;
    state.optimizationFeatureIds.forEach((id) => this.deleteFeature(id));
    state.optimizationFeatureIds = [];
    state.optimizedSystem = undefined;
    state.endPoint.incomingCoords([e.lngLat.lng, e.lngLat.lat]);

    const result = handlePreviewOptimizedDynamicSystem({
        authState,
        layoutId,
        optimizationSettings,
        position: movedPoint,
        project,
        startPosition: state.startPoint.coordinates,
        endPosition: movedPoint,
        canalFeed: systemType === SystemTypes.CanalFeedMaxigator ? state.options.canalFeed : undefined,
        systemType
    }, state.displaySettings.areas);

    state.optimizationFeatureIds = result.features.map((f) => {
        const r = this.newFeature(f);
        this.addFeature(r);
        return r.id;
    });
    state.optimizedSystem = result.optimizedSystem;

    if (result.optimizedSystem && result.optimizedSystem.spans.length) {
        this.updateUIClasses({ mouse: CONSTANTS.cursors.ADD });
        state.startPoint.setProperty("rdpFeatureType", "dynamicCenterValid");
        state.endPoint.setProperty("rdpFeatureType", "dynamicCenterValid");
        state.endPoint.setProperty("title", result.irrigatedAreaLabel);
    } else {
        this.updateUIClasses({ mouse: CONSTANTS.cursors.POINTER });
        state.startPoint.setProperty("rdpFeatureType", "dynamicCenterInvalid");
        state.endPoint.setProperty("rdpFeatureType", "dynamicCenterInvalid");
        state.endPoint.setProperty("title", "");
    }
    // t.logMS();
    return false;
};

DynamicLateralOptimizationMode.onStop = function (this, state) {
    state.optimizationFeatureIds.forEach((id) => this.deleteFeature(id));
    state.optimizationFeatureIds = [];
    this.deleteFeature(state.startPoint.id)
    this.deleteFeature(state.endPoint.id)
};

DynamicLateralOptimizationMode.onTap = DynamicLateralOptimizationMode.onClick = function (this, state, e) {

    if (e.originalEvent.button === 0) {
        // left mouse: accept
        if (!state.startPoint.coordinates.length) {
            if (DEV_TEST && DEV_TEST_BEGIN) {
                state.startPoint.incomingCoords(DEV_TEST_BEGIN);
            }
            else {
                state.startPoint.incomingCoords([e.lngLat.lng, e.lngLat.lat]);
            }            
            return;
        }
        if (state.optimizedSystem && state.optimizedSystem.spans.length) {
            this.map.fire("draw.update_ext", {
                action: "dynamic_lateral_optimization_result",
                definition: {
                    optimizedSystem: state.optimizedSystem,
                    systemType: state.options.systemType,
                    canalFeed: state.options.canalFeed,
                    endFeed: (state.options as IOptions).optimizationSettings.endFeed
                },
            } as IDrawUpdateExtEvent_DynamicLateralOptimizationResult);
        }
    }
    this.changeMode(CONSTANTS.modes.SIMPLE_SELECT, {});
};
