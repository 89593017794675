import { CenterPivotTypes, ElectricalFrequencies, HoseFeedTypes, ISystemBase, LightTypes, SystemTypes, TowerHeights, TowTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule } from "rdptypes/roe/IRule";
import { IsEDMP } from "../../../../../roe_migration/SystemFunctions";
import { strLanguageText } from "../../../../../roe_migration/TranslationsLib";
import { AnchorOK } from "../../../../../roe_migration/Valids.Valids";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "beginningOfSystem",
            title: {"en" : "Beginning of System", "es" : "Principio de Sistema"},
            position: 2,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.CenterPivot,
            components: [
                                   
                {
                    // Box shown if towable or from RDP or EDMP
                    visible: (sys: ISystemBase) => sys.Circle?.CenterPivot?.TowOptions?.TowType !== TowTypes.None
                        || (sys as any).centerPivot?.point
                        || IsEDMP(sys),
                    type: "card",
                    title:{ "en": "Towable Options", "es": "Remolcables"},
                    children: [
                        {
                            // Towable choice is enabled if from RDP or EDMP
                            disabled: (sys: ISystemBase) => !((sys as any).centerPivot?.point || IsEDMP(sys)),
                            type: "complexChoice",
                            fieldPath: "Circle.CenterPivot.TowOptions.TowType",
                            title: { "en" :"Tow Type","es": "Tipo de Remolcado"},
                            options: [
                                {
                                    title: "None", values: {
                                        "SystemProperties.SystemType": "CenterPivot",
                                        "Circle.CenterPivot.TowOptions.TowType": "None"
                                    }
                                },
                                //This is how we can enable kwik tow but we will need to make substantial changes
                                //to geometry helpers (for mapping) to make this work
                                /*{
                                    title: "Kwik Tow", values: {
                                        "SystemProperties.SystemType": "KwikTow",
                                        "Circle.CenterPivot.TowOptions.TowType": undefined
                                    }
                                },*/
                                {
                                    title: "Skid Tow", values: {
                                        "SystemProperties.SystemType": "CenterPivot",
                                        "Circle.CenterPivot.TowOptions.TowType": "SkidTow"
                                    }
                                },
                                {
                                    title: "4 Wheel Pivot Mover", values: {
                                        "SystemProperties.SystemType": "CenterPivot",
                                        "Circle.CenterPivot.TowOptions.TowType": "FourWheelPivotMover"
                                    }
                                }
                            ],
                            enumNameHint: "TowTypes",
                            includeOnlyValidationRules: [],
                            hook: (values?: {[key: string]: any}, sys?: ISystemBase): ({ fieldPath: string; value: any; }[]) | undefined => {
                                const incommingValues = values ?? {};
                                const result: { fieldPath: string; value: any; }[] = [];
                                if (incommingValues["Circle.CenterPivot.TowOptions.TowType"] === TowTypes.None) {
                                    // clear fields hidden by the UI for TowType None
                                    if (sys?.SystemProperties?.ReverseTow) {
                                        result.push({ fieldPath: "SystemProperties.ReverseTow", value: false});
                                    }
                                    if (sys?.Circle?.CenterPivot?.TowOptions?.ExtraHitch) {
                                        result.push({ fieldPath: "Circle.CenterPivot.TowOptions.ExtraHitch", value: false});
                                    }
                                    if (sys?.Circle?.CenterPivot?.TowOptions?.Tires?.TubesForTires) {
                                        result.push({ fieldPath: "Circle.CenterPivot.TowOptions.Tires.TubesForTires", value: false});
                                    }
                                    if (sys?.Circle?.CenterPivot?.TowOptions?.Tires?.TireSize) {
                                        result.push({ fieldPath: "Circle.CenterPivot.TowOptions.Tires.TireSize", value: undefined});
                                    }
                                    if (sys?.Circle?.CenterPivot?.TowOptions?.Tires?.TireType) {
                                        result.push({ fieldPath: "Circle.CenterPivot.TowOptions.Tires.TireType", value: undefined});
                                    }
                                }
                                return result.length ? result : undefined;
                            }
                        },
                        { type: "boolean", title:{ "en" : "Reverse Tow", "es": "Remolcable Reversible"}, fieldPath: "SystemProperties.ReverseTow"},
                        { type: "boolean", title:{ "en" : "Tubes for Tires", "es": "Cámara para Neumático"}, fieldPath: "Circle.CenterPivot.TowOptions.Tires.TubesForTires"},
                        { type: "boolean", title:{ "en" : "Extra Hitch Assembly", "es": "Ensamble Extra de Tiro"}, fieldPath: "Circle.CenterPivot.TowOptions.ExtraHitch"},
                          
                        {
                            type: "complexChoice",
                            visible: (sys: ISystemBase) => sys.Circle.CenterPivot.TowOptions.TowType === TowTypes.FourWheelPivotMover,
                            title:  "Tires",
                            options: [
                                { title:  "11 x 22.5 Recaps", values: {
                                    "Circle.CenterPivot.TowOptions.Tires.TireSize": "a11x225",
                                    "Circle.CenterPivot.TowOptions.Tires.TireType": "Recaps",
                                } },
                                { title:  "11.2 x 24 4-Ply", values: {
                                    "Circle.CenterPivot.TowOptions.Tires.TireSize": "a112x24",
                                    "Circle.CenterPivot.TowOptions.Tires.TireType": "FourPly",
                                } },
                            ],
                            includeOnlyValidationRules: []
                        },    
                    ]
                },     
                // NOTE: Due to validate false on the following, EDMP vs else has been created:
                {
                    type: "choice",
                    fieldPath: "Circle.CenterPivot.CenterPivotType",
                    visible: (sys: ISystemBase) => !IsEDMP(sys),
                    title: {"en" : "Center Pivot Type (Span Compatibility)", "es": "Punto Pivote (Tramos Utilizados Con)"},
                    options: [
                        { id: "E2065", title: {"en" : 'E2065 (for ALL 6 5/8", 6" and 4 1/2" spans)'}, title_centimeters: {"en": 'E2065 (for ALL 16.83cm, 15.24cm and 11.43cm spans)'}},
                        { id: "E2100", title: {"en" : 'E2100 (for 10" Galvanized spans)'}, title_centimeters: {"en": 'E2100 (for 25.4cm Galvanized spans)'}},
                        { id: "E2085", title: {"en" : 'E2085 (for 8 5/8" Galvanized spans)'}, title_centimeters: {"en": 'E2085 (for 21.91cm Galvanized spans)'}},
                        { id: "A80G", title: {"en" : 'A80G (for 8" Aluminimum spans)'}, title_centimeters: {"en": 'A80G (for 20.32cm Aluminimum spans)'}},
                        { id: "S80G", title: {"en" : 'S80G (w/ SS Pipe for 8" Aluminium spans)'}, title_centimeters: {"en": 'S80G (w/ SS Pipe for 20.32cm Aluminium spans)'}},
                        { id: "S2085G", title: {"en" : 'S2085G (w/ SS Pipe for ALL 8 5/8" spans)'}, title_centimeters: {"en": 'S2085G (w/ SS Pipe for ALL 21.91cm spans)'}},
                        { id: "S2065G", title: {"en" : 'S2065G (w/ SS Pipe for ALL 6 5/8" and 6" spans)'}, title_centimeters: {"en": 'S2065G (w/ SS Pipe for ALL 18.83cm and 15.24cm spans)'}},
                        { id: "E2045", title: {"en" : 'E2045 (for ALL 6 5/8", 6" and 4 1/2" spans)'}, title_centimeters: {"en": 'E2045 (for ALL 16.83cm, 15.24cm and 11.43cm spans)'}},
                    ],
                    enumNameHint: "CenterPivotTypes",
                    validate: false
                },
                {
                    type: "choice",
                    fieldPath: "Circle.CenterPivot.CenterPivotType",
                    visible: (sys: ISystemBase) => IsEDMP(sys),
                    title: {"en" : "Center Pivot Type (Span Compatibility)", "es": "Punto Pivote (Tramos Utilizados Con)"},
                    options: [
                        { id: "E2065", title: {"en" : 'E2065 (for ALL 6 5/8", 6" and 4 1/2" spans)'}, title_centimeters: {"en": 'E2065 (for ALL 16.83cm, 15.24cm and 11.43cm spans)'}},
                        { id: "S2065G", title: {"en" : 'S2065G (w/ SS Pipe for ALL 6 5/8" and 6" spans)'}, title_centimeters: {"en": 'S2065G (w/ SS Pipe for ALL 18.83cm and 15.24cm spans)'}},
                        { id: "E2045", title: {"en" : 'E2045 (for ALL 6 5/8", 6" and 4 1/2" spans)'}, title_centimeters: {"en": 'E2045 (for ALL 16.83cm, 15.24cm and 11.43cm spans)'}},
                    ],
                    enumNameHint: "CenterPivotTypes",
                    validate: false
                },
                {
                    type: "choice",
                    fieldPath: "Circle.CenterPivot.PivotCenterHeight",
                    title: {"en" :"Pivot Center Height", "es": "Altura"},
                    options: [
                        { id: "Standard", title: {"en" : "Standard", "es" : "Estándar"}, default: "true" },
                        { id: "LowProfile",  title: { "en" : "Low Profile", "es": "Bajo Perfil"} },
                        { id: "Sugargator", title: "Sugargator" },
                    ],
                    enumNameHint: "TowerHeights",
                    includeOnlyValidationRules: []
                },
                {
                    type: "choice",
                    fieldPath: "Circle.CenterPivot.Gasket",
                    title: {"en" : "Riser Pipe Gasket", "es": "Empaque"},
                    options: [
                        { id: "Standard", title: { "en" : "Standard (Above 50psi)", "es": "Estándar"}, title_bar: { "en" : "Standard (Above 3.45 bar)", "es": "Estándar"}, default: "true" },
                        { id: "TripleLip", title: { "en":"Triple Lip (Below 50psi)", "es": "Triple Labio" }, title_bar: { "en":"Triple Lip (Below 3.45 bar)", "es": "Triple Labio" }},
                    ],
                    enumNameHint: "GasketTypes",
                },
                {
                    type: "booleangroup",
                    layoutHint: "secondary",
                    components: [
                        { type: "boolean", title: {"en":"E2045 Foundation Form Kit", "es": "Kit de hormas para Base E2045"}, fieldPath: "Circle.CenterPivot.E2045FoundationFormKit"},
                        { type: "boolean", title: { "en" : "Pivot Center Walkway", "es": "Centro de Pivote \"Walkaway\""}, fieldPath: "Circle.CenterPivot.Walkway"},
                    ],
                },
                {
                    type: "card",
                    children: [
                    {
                        type: "choice",
                        fieldPath: "Circle.CenterPivot.PipeBottomFitting",
                        title: {"en" :"Pipe Bottom Fitting", "es": "Coneccion del codo de entrada"},
                        options: [
                            { id: "ElbowPlain", title: {"en" : 'Elbow/Plain', "es": "Codo/Liso"}},
                            { id: "ElbowSAE", title: {"en" : 'Elbow/SAE', "es": "Codo/SAE"}},
                            { id: "Elbow8Inch", title: {"en" : 'Elbow 8" Coupler', "es": "Codo de acoplamiento 8\""}, title_centimeters: {"en" : 'Elbow 20.32cm Coupler', "es": "Codo de acoplamiento 20.32cm"}},
                            { id: "ElbowReducer", title: {"en" : 'Elbow 8" to 6 5/8" SAE', "es": "Codo 8\" a 6 5/8\" SAE"}, title_centimeters: {"en" : 'Elbow 20.32cm to 16.83cm SAE', "es": "Codo 20.32cm a 16.83cm SAE"}},
                            { id: "ExtPipe", title: { "en" :'Extension Pipe', "es": "Tubo de extension"}},
                            { id: "Filter475GPM", title: '475 GPM Filter', title_m3h: {"en": '107.89 M3/H Filter'}},
                            { id: "Filter700GPM", title: '700 GPM Filter', title_m3h: {"en": '158.99 M3/H Filter'}},
                            { id: "Filter1200GPM", title: '1200 GPM Filter', title_m3h: {"en": '272.55 M3/H Filter'}},
                            { id: "Filter2000GPM", title: '2000 GPM Filter', title_m3h: {"en": '454.26 M3/H Filter'}},
                            { id: "None", title: {"en" : 'None', "es": "Ninguno"}},
                        ],
                        enumNameHint: "PipeBottomFittingTypes",
                    },
                    
                    { type: "boolean", title: "Male Ring Lock", fieldPath: "Circle.CenterPivot.MaleRingLock"},
                    { type: "boolean", title: {"en":'Adapter 8" to 6" Straightener Pipe'}, title_centimeters: {"en": 'Adapter 20.32cm to 15.24cm Straightener Pipe'}, fieldPath: "Circle.CenterPivot.PipeBottomAdapter8to6"},
                    { type: "boolean", title: { "en" :'Adapter 6-5/8" to 8"', "es": "Adaptador 6-5/8\" a 8\""}, title_centimeters: {"en": 'Adapter 6-5/20.32cm to 20.32cm'}, fieldPath: "Circle.CenterPivot.PipeBottomAdapter"},
                    { type: "boolean", title: 'Filter Perpendicular Offset', fieldPath: "Circle.CenterPivot.PerpendicularOffset"},
                    ]
                },
                {
                    type: "card",
                    children: [
                        {
                            type: "choice",
                            fieldPath: "Circle.CenterPivot.RiserPipeType",
                            title: { "en" :"Pivot Riser Pipe","es": "Tubería de Elevación"},
                            options: [
                                { id: "LongPipe", title: { "en" :'Long', "es": "Largo"}, },
                                { id: "ShortPipe", title: { "en" :'Short', "es": "Corto"}},     
                                { id: "ExtraShortPipe", title: { "en" :'Extra Short', "es": "Extra Corto"}},     
                            ],
                            enumNameHint: "RiserPipeTypes",
                        },
                        { type: "boolean", title: { "en" :"For 6\" Valve", "es": "Para válvula 6\""}, title_centimeters: {"en": "For 15.24cm Valve"}, fieldPath: "Circle.CenterPivot.RiserPipeFor6InchValve"},
                        { type: "boolean", title: "For 6\" x 19.7\" Flanged Device", title_centimeters: {"en": "For 15.24cm x 50cm Flanged Device"}, fieldPath: "Circle.CenterPivot.RiserPipeFor6x19FlangeDevice"},
                        { type: "boolean", title: "For 8\" x 23.6\" Flanged Device", title_centimeters: {"en": "For 20.32cm x 59.94cm Flanged Device"}, fieldPath: "Circle.CenterPivot.RiserPipeFor8x23FlangeDevice"},
                    ]
                },                       
                {
                    type: "choice",
                    fieldPath: "Circle.CenterPivot.AnchorPackage",
                    title: { "en" :"Anchor Package","es": "Packete de Anclas"},
                    options: [
                        { id: "None", title: { "en" :'None', "es": "Ninguno"}},
                        { id: "Bolt", title: { "en" :'Bolt', "es": "Tornillo"}, },
                        { id: "Skid", title: { "en" :'Skid Pkg. For Existing Pad', "es": "Kit de patines extra"}},
                        { id: "CompetitivePadAdapter", title: { "en" :'Competitive Pad Adapter', "es": "Adaptador de Plato Competitivo"}},
                        { id: "CompetitivePadAdapterWithSkids", title: { "en" :'Competitive Pad Adapter w/Skids', "es": "Adaptador p/ Base de Concreto de la Competencia c/Patines"}},
                        { id: "CompetitivePadAdapterWithChannel", title: { "en" :'Competitive Pad Adapter w/Channel Kit', "es": "Kit Adaptador para Base de la Competencia con Canales"}},
                        { id: "EIIPadAdapter", title: { "en" :'Adapt EII SG to E60 SG Pad', "es": "Adapte EII SG al Teclado E60 SG."}},
                        { id: "Chain17", title: { "en" :"17\" Chain (on pad)", "es": "Cadena de 17\" (dentro de base)" }, title_centimeters: { "en" :"43.18cm Chain (on pad)", "es": "Cadena de 43.18cm (dentro de base)"}},
                        { id: "Chain25", title: { "en" :"25\" Chain (off pad)", "es": "Cadena de 25\" (fuera de base)" }, title_centimeters: { "en" :"63.5cm Chain (off pad)", "es": "Cadena de 63.5cm (fuera de base)"}},
                        { id: "Turnbuckle", title: "Turnbuckle" },
                    ],
                    enumNameHint: "AnchorPackageTypes",
                },
                {
                    type: "choice",
                    fieldPath: "Circle.CenterPivot.PadBoltType",
                    title: { "en" :"Pad Adapter Bolt", "es": "Almohadilla adaptadora de tornillo"},
                    visible: (sys: ISystemBase) => AnchorOK(sys.Circle.CenterPivot.AnchorPackage, sys.Circle.CenterPivot.TowOptions.TowType, sys.Circle.CenterPivot.CenterPivotType, sys.Circle.CenterPivot.PivotCenterHeight),
                    options: [
                        { id: "None", title: { "en" :'None', "es": "Ninguno"}, },
                        { id: "a34x10", title: "3/4\" x 10\"", title_centimeters: {"en": "1.9cm x 25.4cm"},},
                        { id: "a1x9", title: "1\" x 9\"", title_centimeters: {"en" : "2.54cm x 22.86cm"}},
                    ],
                    enumNameHint: "PadBoltTypes",
                },
                {
                    type: "choice",
                    fieldPath: "Options.PivotLight",
                    title: {"en" : "Pivot Light", "es": "Lámpara en Pivote"},
                    options: [
                        { id: "None", title: {"en" : 'None', "es": "Ninguno"}, },                                            
                        { id: "Standard", title: {"en" : 'Standard', "es": "Estándar" }},                                        
                        { id: "StrobeClear", title: {"en" : 'Strobe - Std 50 ECP - Clear', "es": "Estrobo - Std 50 ECP - Borran" }},
                        { id: "StrobeAmber", title: {"en" : 'Strobe - Std 50 ECP - Amber', "es": "Estrobo - Std 50 ECP - Ámbar"  }},    
                        { id: "StrobeBlue", title: {"en" : 'Strobe - Std 50 ECP - Blue', "es": "Estrobo - Std 50 ECP - Azul" }},   
                        { id: "StrobeGreen", title: {"en" : 'Strobe - Std 50 ECP - Green' , "es": "Estrobo - Std 50 ECP - Verde"}},   
                        { id: "StrobeRed", title: {"en" : 'Strobe - Std 50 ECP - Red', "es": "Estrobo - Std 50 ECP - Rojo" }},   
                        { id: "Strobe400Clear", title: {"en" : 'Strobe - 400 ECP - Clear', "es": "Estrobo - Std 400 ECP - Borran" }},   
                        { id: "Strobe400Amber", title: {"en" : 'Strobe - 400 ECP - Amber', "es": "Estrobo - Std 400 ECP - Ámbar" }},   
                        { id: "Strobe400Blue", title: {"en" : 'Strobe - 400 ECP - Blue', "es": "Estrobo - Std 400 ECP - Azul"  }},   
                        { id: "Strobe400Green", title: {"en" : 'Strobe - 400 ECP - Green', "es": "Estrobo - Std 400 ECP - Verde" }},   
                        { id: "Strobe400Red", title: {"en" : 'Strobe - 400 ECP - Red', "es": "Estrobo - Std 400 ECP - Rojo" }},                                             
                    ],
                    enumNameHint: "LightTypes",
                    includeOnlyValidationRules: []
                },      
                { type: "boolean", title: {"en" : "Pivot Light Constant On Kit", "es": "Kit Prendido Cte."}, fieldPath: "Options.PivotLightRAMSConstantOn"},
                {
                    type: "card",
                    title: "Partial Pivot Barriers (Used to calculate irrigated acres)",
                    visible: (sys: ISystemBase) => !IsEDMP(sys),
                    disableDealerSettings: true,
                    children: [
                        { 
                            type: "boolean", 
                            title: {"en":"Partial Pivot", "es": "Partial Pivot"}, 
                            fieldPath: "Circle.CenterPivot.isPartialPivot",
                            validate: false,
                            hook: (value: boolean, sys?: ISystemBase): ({ fieldPath: string; value: any; }[]) | undefined => {
                                if (sys?.FlangedSide?.Span?.some(x => x.Disconnecting)) {
                                    return [
                                        { 
                                            fieldPath: "FlangedSide.Span",
                                            value: sys.FlangedSide.Span.map(x => ({
                                                ...x,
                                                Disconnecting: false,
                                                dropSpanEndRelativeToPreviousSpanEnd: undefined,
                                                dropSpanStartRelativeToPreviousSpanStart: undefined
                                            }))
                                        }
                                    ]
                                }
                            }
                        },  
                        {
                            visible: (sys: ISystemBase) => sys.Circle.CenterPivot.isPartialPivot,
                            type: "number",
                            fieldPath: "Circle.CenterPivot.clockwiseCompassHeadingEnd",
                            title: { "en" :"Forward", "es": "Inicio"},
                            icon: "rotate-right",
                            default: 0,
                            units: "°",
                            decimalPlaces: 1,
                            min: 0,
                            max: 360
                        },
                        {
                            visible: (sys: ISystemBase) => sys.Circle.CenterPivot.isPartialPivot,
                            type: "number",
                            fieldPath: "Circle.CenterPivot.clockwiseCompassHeadingStart",
                            title:{ "en" : "Reverse", "es": "Final"},
                            icon: "rotate-left",
                            default: 0,
                            units: "°",
                            decimalPlaces: 1,
                            min: 0,
                            max: 360
                        },
                        {
                            visible: (sys: ISystemBase) => sys.Circle.CenterPivot.isPartialPivot,
                            type: "calculator",
                            title: "Sweep",
                            expr: (sys: ISystemBase) => {
                                const ccw = sys.Circle.CenterPivot.clockwiseCompassHeadingStart;
                                const cw = sys.Circle.CenterPivot.clockwiseCompassHeadingEnd;

                                if (typeof ccw === "number" && !isNaN(ccw) && typeof cw === "number" && !isNaN(cw)) {
                                    let sweep = cw - ccw;
                                    while (sweep < 0) {
                                        sweep += 360;
                                    }
                                    while (sweep >= 360) {
                                        sweep -= 360;
                                    }
                                    return sweep;
                                } else {
                                    return "";
                                }
                            }
                        }
                    ]
                },         
                {
                    type: "card",
                    title: "Power Supply",
                    visible: (sys: ISystemBase) => !IsEDMP(sys),
                    children: [
                        {
                            type: "choice",
                            fieldPath: "ControlPanel.ElectricalFrequency",
                            enumNameHint: 'ElectricalFrequencies',
                            options: [
                                { id: ElectricalFrequencies.a50, title: "50Hz" },
                                { id: ElectricalFrequencies.a60, title: "60Hz" }
                            ],
                            title: { en: "Electrical Frequency", es: "Frecuencia Eléctrica" },
                            hook: (value: string, sys: ISystemBase) => {
                                if (sys.Circle.SinglePhase230VoltSystem && value === ElectricalFrequencies.a60) {                                  
                                    return [
                                        { fieldPath: "ControlPanel.SourceVoltage", value: 230 },   
                                        { fieldPath: "ControlPanel.MinVoltage", value: 208 },                                       
                                    ]
                                }
                                if (!sys.Circle.SinglePhase230VoltSystem && value === ElectricalFrequencies.a60) {                         
                                    return [
                                        { fieldPath: "ControlPanel.SourceVoltage", value: 480 },  
                                        { fieldPath: "ControlPanel.MinVoltage", value: 440 },                                      
                                    ]
                                }
                                if (!sys.Circle.SinglePhase230VoltSystem && value === ElectricalFrequencies.a50) {
                                    return [
                                        { fieldPath: "ControlPanel.SourceVoltage", value: 380 },   
                                        { fieldPath: "ControlPanel.MinVoltage", value: 360 },                                       
                                    ]
                                }
                            },
                            includeOnlyValidationRules: [],
                        },
                        {
                            type: "choice",
                            fieldPath: "Circle.SinglePhase230VoltSystem",
                            enumNameHint: 'CurrentType',
                            includeOnlyValidationRules: [],
                            options: [
                                { id: true, title: { en: "AC Single Phase", es: "Corriente Alterna Monofásica" } },
                                { id: false, title: { en: "AC Three Phase", es: "Corriente Alterna Trifásica" } }
                            ],
                            title: { en: "Current Type", es: "Tipo de Corriente" },
                            hook: (value: string, sys: ISystemBase) => {
                                if (value && sys.ControlPanel.ElectricalFrequency === ElectricalFrequencies.a60) {
                                    return [
                                        { fieldPath: "ControlPanel.SourceVoltage", value: 230 },   
                                        { fieldPath: "ControlPanel.MinVoltage", value: 208 },                                       
                                    ]
                                }
                                if (!value && sys.ControlPanel.ElectricalFrequency === ElectricalFrequencies.a60) {
                                    return [
                                        { fieldPath: "ControlPanel.SourceVoltage", value: 480 },  
                                        { fieldPath: "ControlPanel.MinVoltage", value: 440 },                                        
                                    ]
                                }
                                if (!value && sys.ControlPanel.ElectricalFrequency === ElectricalFrequencies.a50) {
                                    return [
                                        { fieldPath: "ControlPanel.SourceVoltage", value: 380 },   
                                        { fieldPath: "ControlPanel.MinVoltage", value: 360 },                                       
                                    ]
                                }
                            },
                        }
                    ],
                },             
            ],
        },
    ],
    rules: [
        {
            apply: (system, user, grower, quick) => {
                if (system.SystemProperties?.SystemType === SystemTypes.CenterPivot && IsEDMP(system)) {
                    const options = system.Options;
                    if ((options?.PivotLight ?? LightTypes.None) !== LightTypes.None) {
                        return [
                            {
                                id: "roeLegacy.OptionsClass.PivotLight",
                                targets: [ "Options.PivotLight" ],
                                severity: "error",
                                description: "Engine driven mini pivot systems cannot have a pivot light"
                            }
                        ]
                    }
                }
                return [];
            }
        },
        {
            apply: (system, user, grower, quick) => {
                
                const o = system.Options;
                if (o.PivotLightRAMSConstantOn) {
                    let invalid = ((o.PivotLight ?? LightTypes.None) === LightTypes.None);
                    invalid ||= ![SystemTypes.CenterPivot, SystemTypes.HoseFeedMaxigator].includes(system.SystemProperties.SystemType)
                    invalid ||= (system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral);
                    if (invalid) {
                        return [
                            {
                                id: "roeLegacy.OptionsClass.PivotLightConstantOnOK",
                                targets: [ "Options.PivotLightRAMSConstantOn" ],
                                severity: "error",
                                description: strLanguageText("sltRAMSConstantOn")
                            }
                        ]
                    }
                }
                return [];                
            }
        },
        {
            apply: (system, user, grower, quick) => {
                if (system.SystemProperties?.SystemType === SystemTypes.CenterPivot && IsEDMP(system)) {
                    const cp = system.Circle.CenterPivot;
                    if (cp.CenterPivotType) {
                        if (![CenterPivotTypes.E2065, CenterPivotTypes.S2065G, CenterPivotTypes.E2045].includes(cp.CenterPivotType)) {
                            return [
                                {
                                    id: "roeLegacy.OptionsClass.EDMPOK.CenterPivotType",
                                    targets: [ "Circle.CenterPivot.CenterPivotType", "Circle.EngineDrivenMiniPivot" ],
                                    severity: "error",
                                    description: "Engine driven mini pivot system is not compatible with this Center Pivot Type"
                                }
                            ]
                        }
                    }
                }
                return [];                
            }
        },
        {
            apply: (system, user, grower, quick) => {
                if (system.SystemProperties?.SystemType === SystemTypes.CenterPivot && IsEDMP(system)) {
                    const cp = system.Circle.CenterPivot;
                    if ((cp.PivotCenterHeight ?? TowerHeights.Standard) !== TowerHeights.Standard) {
                        return [
                            {
                                id: "roeLegacy.OptionsClass.EDMPOK.PivotCenterHeight",
                                targets: [ "Circle.CenterPivot.PivotCenterHeight", "Circle.EngineDrivenMiniPivot" ],
                                severity: "error",
                                description: "Engine driven mini pivot system is not compatible with this Pivot Center Height"
                            }
                        ]
                    }
                }
                return [];                
            }
        }
    ] as IRule[]
};