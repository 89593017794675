import { StyleSheet, View } from "@react-pdf/renderer";
import i18next, { t } from "i18next";
import { ElectricalFrequencies } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import { getSystemVoltageatPowerSource } from "roedata/ProposalHelpers";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { getBoosterPumpDescription, getCenterDriveType, getEffectiveGunThrow, getEndGunPlusSACAreaAcres, getEndGunPsi, getIntensityApplicationRatePerHourInches, getIrrigationRatePerDayInches, getIrrigationRatePerMonthInches, getLastTowerDistanceFeet, getMaxGunThrow, getOverallTireSize, getPrimaryEndGunFlowGPM, getPrimaryEndGunModel, getSecondaryEndGunModel, getSprinklerBrand, getSprinklerDiameterFeet, getSprinklerGroundClearanceFeet, getSystemAreaAcres, getSystemElectricalFrequencyHertz, getSystemElevationFeet, getSystemEndPressurePsi, getSystemGPM, getSystemIrrigatedAcres, getSystemLengthFeet, getSystemMaxGPM, getSystemMinGPM, getSystemModel, getSystemTopOfInletPressurePsi, getTowerSpeedFeetPerMinute, getValuesFromFieldDesign } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import IProject from "../../model/project/IProject";
import { acresToHectares, feetToMeters, gpmToM3ph, inchesToMm, psiToBar } from "../DocumentGenerationHelpers";
import { documentStyles } from "../styles";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

export interface Props {
    quote: QuoteClass;
    systemId: string;
    layoutId: string;
    project: IProject;
}

const PerformanceSummaryInformationSection: FC<Props> = (props) => {
    const settings = React.useContext(DevSettingsCtx);
    let {quote, systemId, layoutId, project } = props;
    
    let tableViews: JSX.Element[] = [];

    let rows: IRow[] = [{cells: [{
        text: i18next.format(t('reports.system-model'), 'capitalize-each') + ":",
        header: false,
        widthPc: 23
    },{
        text: getSystemModel(quote, settings),
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: i18next.format(t('reports.system-length'), 'capitalize-each') + ":",
        header: false,
        widthPc: 23
    }, {
        text: `${getSystemLengthFeet(quote)}' (${feetToMeters(getSystemLengthFeet(quote)).toFixed(2)} m)`,
        widthPc: 23
    }]}];

    rows.push({cells: [{
        text: i18next.format(t('tower'), 'capitalize-each') + ":",
        header: false,
        widthPc: 23
    },{
        text: (quote.System.FlangedSide.Tower.length + quote.System.FlexSide.Tower.length).toFixed(),
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltPowerSupply') + ":",
        header: false,
        widthPc: 23
    }, {
        text: `${getSystemVoltageatPowerSource(quote)}V / ${getSystemElectricalFrequencyHertz(quote) === ElectricalFrequencies.a50 ? "50" : "60"} Hz`,
        widthPc: 23
    }]});

    let valuesFromFieldDesign = getValuesFromFieldDesign(quote, systemId, layoutId, project);

    rows.push({cells: [{
        text: t('common-phrases.sltSystemArea') + ":",
        header: false,
        widthPc: 23
    },{
        text: `${getSystemAreaAcres(quote, valuesFromFieldDesign).toFixed(1)} ${t("acres")} (${acresToHectares(getSystemAreaAcres(quote, valuesFromFieldDesign)).toFixed(2)} ${t("hectares")})`,
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltTotalArea') + ":",
        header: false,
        widthPc: 23
    }, {
        text: `${getSystemIrrigatedAcres(quote, valuesFromFieldDesign).toFixed(1)} ${t("acres")} (${acresToHectares(getSystemIrrigatedAcres(quote, valuesFromFieldDesign)).toFixed(2)} ${t("hectares")})`,
        widthPc: 23
    }]});

    rows.push({cells: [{
        text: t('common-phrases.sltSacandEndGunArea') + ":",
        header: false,
        widthPc: 23
    },{
        text: `${getEndGunPlusSACAreaAcres(quote, valuesFromFieldDesign).toFixed(1)} ${t("acres")} (${acresToHectares(getEndGunPlusSACAreaAcres(quote, valuesFromFieldDesign)).toFixed(2)} ${t("hectares")})`,
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltElevation') + ":",
        header: false,
        widthPc: 23
    }, {
        text: `${getSystemElevationFeet(quote).toFixed()}' (${feetToMeters(getSystemElevationFeet(quote)).toFixed(1)} m)`,
        widthPc: 23
    }]});

    tableViews.push(<View style={styles.table}>
        <FixedWidthTable data={{rows}}/>
    </View>);

    let systemGPM = getSystemGPM(quote);
    let systemM3ph = typeof systemGPM === "number" ? gpmToM3ph(systemGPM) : undefined;
    let flowByAcre = typeof systemGPM === "number" ? systemGPM / getSystemIrrigatedAcres(quote, valuesFromFieldDesign) : undefined;
    let flowByHectare = typeof flowByAcre === "number" ? systemM3ph / acresToHectares(getSystemIrrigatedAcres(quote, valuesFromFieldDesign)) : undefined;

    let maxSystemGPM = getSystemMaxGPM(quote);
    let minSystemGPM = getSystemMinGPM(quote);

    let topOfInletPressure = getSystemTopOfInletPressurePsi(quote);

    rows = [{cells: [{
        text: t('common-phrases.sltDesignSystemFlow') + ":",
        header: false,
        widthPc: 23
    },{
        text: typeof systemGPM === "number" ? `${systemGPM.toFixed(2)} GPM (${systemM3ph.toFixed(2)} M3/h)` : "N/A",
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltDesignedFlowByArea') + ":",
        header: false,
        widthPc: 23
    }, {
        text: typeof flowByAcre === "number" ? `${flowByAcre.toFixed(2)} GPM (${flowByHectare.toFixed(2)} M3/h)` : "N/A",
        widthPc: 23
    }]}];

    rows.push({cells: [{
        text: t('common-phrases.sltMaxFlow') + ":",
        header: false,
        widthPc: 23
    },{
        text: typeof maxSystemGPM === "number" ? `${maxSystemGPM.toFixed(2)} GPM (${gpmToM3ph(maxSystemGPM).toFixed(2)} M3/h)` : "N/A",
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltMinFlow') + ":",
        header: false,
        widthPc: 23
    }, {
        text: typeof minSystemGPM === "number" ? `${minSystemGPM.toFixed(2)} GPM (${gpmToM3ph(minSystemGPM).toFixed(2)} M3/h)` : "N/A",
        widthPc: 23
    }]});

    rows.push({cells: [{
        text: t('common-phrases.sltPivotTopPressure') + ":",
        header: false,
        widthPc: 23
    },{
        text: typeof topOfInletPressure === "number" ? `${topOfInletPressure.toFixed(2)} PSI (${psiToBar(topOfInletPressure).toFixed(2)} Bar)` : "N/A",
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltEndPressure1') + ":",
        header: false,
        widthPc: 23
    }, {
        text: `${getSystemEndPressurePsi(quote).toFixed(2)} PSI (${psiToBar(getSystemEndPressurePsi(quote)).toFixed(2)} Bar)`,
        widthPc: 23
    }]});

    tableViews.push(<View style={styles.table}>
        <FixedWidthTable data={{rows}}/>
    </View>);

    rows = [{cells: [{
        text: t('common-phrases.sltPrimaryEndGunFlowRate') + ":",
        header: false,
        widthPc: 23
    },{
        text: `${getPrimaryEndGunFlowGPM(quote).toFixed(2)} GPM (${gpmToM3ph(getPrimaryEndGunFlowGPM(quote)).toFixed(2)} M3/h)`,
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltBoosterPump') + "/" + t('common-phrases.sltEndGunPressure') + ":",
        header: false,
        widthPc: 23
    }, {
        text: `${getBoosterPumpDescription(quote)} ${getEndGunPsi(quote).toFixed(2)} PSI ${psiToBar(getEndGunPsi(quote)).toFixed(2)} Bar`,
        widthPc: 23
    }]}];

    rows.push({cells: [{
        text: t('common-phrases.sltPrimaryEndGunModel') + ":",
        header: false,
        widthPc: 23
    },{
        text: `${getPrimaryEndGunModel(quote)}`,
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltSecondaryEndGunModel') + ":",
        header: false,
        widthPc: 23
    }, {
        text: `${getSecondaryEndGunModel(quote)}`,
        widthPc: 23
    }]});

    let primaryGunThrow = getEffectiveGunThrow(quote, false);
    let secondaryGunThrow = getEffectiveGunThrow(quote, true);

    rows.push({cells: [{
        text: t('common-phrases.sltEffectiveGunThrow') + ":",
        header: false,
        widthPc: 23
    },{
        text: typeof primaryGunThrow === "number" ? `${primaryGunThrow.toFixed(2)}' (${feetToMeters(primaryGunThrow).toFixed(2)} m)` : "N/A",
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltEffectiveGunThrow') + ":",
        header: false,
        widthPc: 23
    }, {
        text: typeof secondaryGunThrow === "number" ? `${secondaryGunThrow.toFixed(2)}' (${feetToMeters(secondaryGunThrow).toFixed(2)} m)` : "N/A",
        widthPc: 23
    }]});

    let primaryMaxGunThrow = getMaxGunThrow(quote, false);
    let secondaryMaxGunThrow = getMaxGunThrow(quote, true);

    rows.push({cells: [{
        text: t('common-phrases.sltMaxGunThrow') + ":",
        header: false,
        widthPc: 23
    },{
        text: typeof primaryMaxGunThrow === "number" ? `${primaryMaxGunThrow.toFixed(2)}' (${feetToMeters(primaryMaxGunThrow).toFixed(2)} m)` : "N/A",
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltMaxGunThrow') + ":",
        header: false,
        widthPc: 23
    }, {
        text: typeof secondaryMaxGunThrow === "number" ? `${secondaryMaxGunThrow.toFixed(2)}' (${feetToMeters(secondaryMaxGunThrow).toFixed(2)} m)` : "N/A",
        widthPc: 23
    }]});

    tableViews.push(<View style={styles.table}>
        <FixedWidthTable data={{rows}}/>
    </View>);

    let clearance = getSprinklerGroundClearanceFeet(quote);
    let diameter = getSprinklerDiameterFeet(quote);
    let intensity = getIntensityApplicationRatePerHourInches(quote);
    let towerSpeed = getTowerSpeedFeetPerMinute(quote);

    rows = [{cells: [{
        text: t('common-phrases.sltSprinklerBrand') + ":",
        header: false,
        widthPc: 23
    },{
        text: `${getSprinklerBrand(quote)}`,
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltSprinklerGroundClearance') + ":",
        header: false,
        widthPc: 23
    }, {
        text: typeof clearance === "number" ? `${(clearance as number).toFixed(1)}' (${feetToMeters((clearance as number)).toFixed(1)} m)` : "N/A",
        widthPc: 23
    }]}];

    rows.push({cells: [{
        text: t('common-phrases.sltSprinklerDiameter') + ":",
        header: false,
        widthPc: 23
    },{
        text: typeof diameter === "number" ? `${diameter.toFixed(1)}' (${feetToMeters(diameter).toFixed(1)} m)` : "N/A",
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltIntensityApplicationRate') + ":",
        header: false,
        widthPc: 23
    }, {
        text: typeof intensity === "number" ? `${intensity.toFixed(2)}" (${inchesToMm(intensity).toFixed(2)} mm)` : "N/A",
        widthPc: 23
    }]});

    const irrigationRatePerDayInches = getIrrigationRatePerDayInches(quote, valuesFromFieldDesign);
    const irrigationRatePerMonthInches = getIrrigationRatePerMonthInches(quote, valuesFromFieldDesign);

    rows.push({cells: [{
        text: t('common-phrases.sltIrrigationRatePerDay') + ":",
        header: false,
        widthPc: 23
    },{
        text: typeof irrigationRatePerDayInches === "undefined" ? "N/A" : `${irrigationRatePerDayInches.toFixed(2)}" (${inchesToMm(irrigationRatePerDayInches / 10).toFixed(2)} cm)`,
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltIrrigationRatePerMonth') + ":",
        header: false,
        widthPc: 23
    }, {
        text: typeof irrigationRatePerMonthInches === "undefined" ? "N/A" : `${irrigationRatePerMonthInches.toFixed(2)}" (${inchesToMm(irrigationRatePerMonthInches / 10).toFixed(2)} cm)`,
        widthPc: 23
    }]});

    tableViews.push(<View style={styles.table}>
        <FixedWidthTable data={{rows}}/>
    </View>);

    rows = [{cells: [{
        text: t('common-phrases.sltTireSize') + ":",
        header: false,
        widthPc: 23
    },{
        text: `${getOverallTireSize(quote)}`,
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltLastTowerDistance') + ":",
        header: false,
        widthPc: 23
    }, {
        text: `${getLastTowerDistanceFeet(quote).toFixed(1)}' (${feetToMeters(getLastTowerDistanceFeet(quote)).toFixed(1)} m)`,
        widthPc: 23
    }]}];

    rows.push({cells: [{
        text: t('common-phrases.sltCenterDrive') + ":",
        header: false,
        widthPc: 23
    },{
        text: `${getCenterDriveType(quote)}`,
        widthPc: 23
    }, {
        text: "",
        widthPc: 4
    }, {
        text: t('common-phrases.sltTowerSpeed') + ":",
        header: false,
        widthPc: 23
    }, {
        text: typeof towerSpeed === "number" ? `${towerSpeed.toFixed(2)}' (${feetToMeters(towerSpeed).toFixed(2)} m) / minute` : "N/A",
        widthPc: 23
    }]});

    tableViews.push(<View style={styles.table}>
        <FixedWidthTable data={{rows}}/>
    </View>);

    return (<View style={documentStyles.section}>
            {tableViews}
        </View>
    );
};

const styles = StyleSheet.create({
    table: {
        paddingLeft: 15,
        paddingRight: 15
    }
});

export default PerformanceSummaryInformationSection;