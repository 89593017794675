import IAction from "rdptypes/IAction";
import ISystem from "rdptypes/project/ISystem";
import IPage from "rdptypes/roe/IPage";
import { createNewMultiAction } from "../../../actions/MultiAction";
import { executeAction } from "../../../actions/actionExecutorRegistry";
import IAuthState from "../../../auth/IAuthState";
import IDbProject from "../../../db/IDbProject";
import IProject from "../../../model/project/IProject";
import IValidationWorkerState from "../../../workers/ValidationWorker/IValidationWorkerState";
import { improveScores as runImproveScores } from "../../../workers/ValidationWorker/improveScores";
import IComponentRenderContext from "./IComponentRenderContext";
import { generateChart } from "./Sprinklers/SprinklerChart/SprinklerChart";

export default class ComponentRenderContext implements IComponentRenderContext {
    #system: ISystem;

    vws: IValidationWorkerState;

    fieldRoot = "";
    changeFieldRoots = [""];

    constructor(
        private dbPrj: IDbProject,
        private layoutId: string,
        private systemId: string,
        private page: IPage,
        vws: IValidationWorkerState,
        private authState: IAuthState,
        public editingDealerDefaults: boolean,
    ) {

        const project = dbPrj.state;
        const layout = project.layouts[this.layoutId];
        this.#system = layout.systems[this.systemId];

        this.vws = vws;
    }

    pushActionAndImproveScores = async (action: IAction, changingFieldPaths: string[], improveScores: boolean) => {
        const requiresSprinklersClear = this.page.changeRequiresNewSprinklers &&
            (this.#system.FlangedSide.SprinklerChart?.Outlet.length
                || this.#system.FlexSide.SprinklerChart?.Outlet.length
                || (this.#system.SprinklerEngineResultWarnings ?? []).length !== 0);


        // Create a dummy copy of the project, containing only this system, and apply the action
        const projCopy: IProject = {
            layouts: {}
        } as any;
        projCopy.layouts[this.layoutId] = {
            systems: {}
        } as any;
        projCopy.layouts[this.layoutId].systems[this.systemId] = JSON.parse(JSON.stringify(this.#system));
        executeAction(action, projCopy);
        const updatedSystem = projCopy.layouts[this.layoutId].systems[this.systemId];

        if (requiresSprinklersClear) {
            // Currently we always recalculate the sprinkler chart as soon as it's been generated once
            // and no longer confirm when this is happening.
            /*if (!confirm("This change will recalculate the Sprinkler Chart on the Sprinklers page.")) {
                return;
            }*/

            var actions = generateChart(this.authState, this.dbPrj, this.layoutId, this.systemId, updatedSystem);

            action = createNewMultiAction(
                [ action, ...actions ],
                this.authState
            );
        }

        const svr = await this.vws.validate(projCopy.layouts[this.layoutId].systems[this.systemId], changingFieldPaths, this.page.id);
        if (improveScores) {
            const improveAction = runImproveScores(updatedSystem, this.authState, this.dbPrj, this.layoutId, this.systemId, svr, changingFieldPaths);
            if (improveAction) {
                action = createNewMultiAction(
                    [ action, improveAction ],
                    this.authState
                );
            }
        }
        this.dbPrj.pushAction(action);
    }
}
