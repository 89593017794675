import { ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "sprinklers",
            title: { "en" : "Sprinklers", "es": "Aspersores"},
            position: 8.2,
            disableDealerSettings: true,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && sys.SystemProperties.SystemType !== SystemTypes.Ancillary,
            components: [
                {
                    type: "sprinklers",
                },
            ],
        },
    ],
};