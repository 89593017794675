import { Feature, LineString, MultiPolygon, Polygon, area, booleanContains, booleanDisjoint, clone, coordAll, difference, feature, intersect, length, lineString, polygon } from "@turf/turf";
import { alongCustom, booleanContainsCustom, loCustom, ptldCustom } from "rdptypes/geometry/helpers/turf";
import { PartialSpanLengthItem, partialToSpanLengthItem } from "../../../components/OptimizeSystemDialog/RestrictSpanLengthsControl";
import { isEndboomOk } from "../../../helpers/validation/endboom";
import { getAvailableSpanLengthsWithExtension } from "../../../helpers/validation/spanExtensions";
import { IFillSystemSpan } from "../../spans/optimizeSpansCommon";
import { optimizeSpansForLateral } from "../../spans/optimizeSpansForLateral";
import { MAX_N_SPANS } from "../../spans/spanCombinationsCache";

interface IArgs {
    line: LineString;
    direction: 'fwd' | 'aft';
    obstacles: Polygon[];
    boundary: Polygon;

    wheelObstacles: Polygon[];
    allowableSpanLengths: PartialSpanLengthItem[];
    endBoom?: number;
    currentNumberOfSpans: number;
}

export interface IDropOptimizerResult {
    spanLengths: IFillSystemSpan[];
    polygon: Polygon;
    area: number;
    systemConfigurationHeadingFrom: number;
    systemConfigurationHeadingTo: number;
}

const trySpans = (_incommingSpans: IFillSystemSpan[], lastSpanLine: LineString, directionMultiplier: number, fullAllowableAreaPolygon: Feature<Polygon | MultiPolygon>,
    wheelObstacles: Polygon[] = [], restrictions: { from: number, to: number } | undefined, endBoom: number, currentNumberOfSpans: number) => {
    // NOTE: Span length starts at 1ft here, this is to handle the last span of system (if not and end boom) is 1 foot longer
    // as found in spanf LengthInFeet function
    if (_incommingSpans.length === 0) return undefined;
    const incommingSpans: IFillSystemSpan[] = [];
    let i = 0;
    while (i < _incommingSpans.length && incommingSpans.length + currentNumberOfSpans < MAX_N_SPANS) {
        incommingSpans.push(_incommingSpans[i]);
        i++;
    }
    const spans: IFillSystemSpan[] = [
        { spanLength: 1, spanExtension: false, spanAndExtensionLength: 1 },
        ...incommingSpans
    ]
    let best: IDropOptimizerResult | undefined = undefined;
    let spanLength = 0;
    let dropTowerLine = lastSpanLine;
    for (const l of spans) {
        spanLength += l.spanAndExtensionLength;
        dropTowerLine = loCustom(dropTowerLine, l.spanAndExtensionLength * directionMultiplier, { units: 'feet' }).geometry;
    }
    if (endBoom) {
        const lastSpan = incommingSpans.slice(-1)[0];
        if (!isEndboomOk(endBoom, { SpanLength: lastSpan.spanLength, IsSpanExt: lastSpan.spanExtension })) {
            return undefined;
        }
        dropTowerLine = loCustom(dropTowerLine, endBoom * directionMultiplier, { units: 'feet' }).geometry;
    }

    const dropFullPolygon = polygon([
        [
            ...lastSpanLine.coordinates,
            ...[...dropTowerLine.coordinates].reverse(),
            lastSpanLine.coordinates[0]
        ]
    ]);

    const allowableAreaPolygon = intersect(dropFullPolygon, fullAllowableAreaPolygon);
    if (!allowableAreaPolygon) return;

    const dropAllowablePolygonCoords = coordAll(allowableAreaPolygon);
    const leadingLine = lineString([
        lastSpanLine.coordinates[0], dropTowerLine.coordinates[0]
    ])
    const lastSpanLength = length(feature(lastSpanLine), { units: 'feet' });
    const dropAllowablePolygonCoordDistances = [ 
        ...new Set([
                ...dropAllowablePolygonCoords
                    .map(x => ptldCustom(x, leadingLine, { units: 'feet' })),
                0, lastSpanLength
        ])
    ].sort((a,b) => a - b)
    .filter(x => {
        if (!restrictions) return true;
        return (x > restrictions.from) && (x < lastSpanLength - restrictions.to)
    });
    for (let i = 0; i < dropAllowablePolygonCoordDistances.length - 1; i++) {
        const d1 = dropAllowablePolygonCoordDistances[i] === 0
            ? dropAllowablePolygonCoordDistances[i]
            : dropAllowablePolygonCoordDistances[i] + 0.5; // increase by x ft to avoid collison due to line measurements
        const d2 = dropAllowablePolygonCoordDistances[i + 1] === lastSpanLength
            ? dropAllowablePolygonCoordDistances[i + 1]
            : dropAllowablePolygonCoordDistances[i + 1] - 0.5; // decrease by x ft to avoid collison due to line measurements
        if (d2 - d1 < 1) {
            // dont consider drops less then 1 ft wide
            continue;
        }
        const p1 = alongCustom(lastSpanLine, d1, { units: 'feet' }).geometry.coordinates;
        const p2 = alongCustom(lastSpanLine, d2, { units: 'feet' }).geometry.coordinates;
        const o1 = alongCustom(dropTowerLine, d1, { units: 'feet' }).geometry.coordinates;
        const o2 = alongCustom(dropTowerLine, d2, { units: 'feet' }).geometry.coordinates;
        const irrigatedPolygon = polygon(
            [
                [
                    p1,p2,o2,o1,p1
                ]
            ]
        )
        if (!booleanContainsCustom(fullAllowableAreaPolygon, irrigatedPolygon)) {
            continue;
        }
        if (wheelObstacles.length) {            
            const wt = lineString([ o1, o2 ])
            // if (incommingSpans.length === 5 && incommingSpans.every((x,i) => x === [213,213,213,213,80][i])) {
            //     console.log("clash", featureCollection([
            //         ...wheelObstacles.map(o => feature(o)),
            //         wt,
            //         fullAllowableAreaPolygon,
            //         irrigatedPolygon
            //     ]))
            //     console.log("wheelObstacles",wheelObstacles)
            // }
            if (wheelObstacles.some(wo => !booleanDisjoint(wo, wt))) {
                continue;
            }
            
        }

        const parea = area(irrigatedPolygon);
        if (!best || best.area < parea)  {
            const systemConfigurationHeadingFrom = Math.ceil(d1 * 10) / 10;
            const systemConfigurationHeadingTo = Math.ceil((lastSpanLength - d2) * 10) / 10;
            best = {
                area: parea,
                spanLengths: spans.slice(1), // remove the first zero span
                polygon: irrigatedPolygon.geometry,
                systemConfigurationHeadingFrom,
                systemConfigurationHeadingTo,
            }
        }
    }
    return best;
}

const calculateDropSpanPolygonsForSide = (args: IArgs): IDropOptimizerResult | undefined => {

    if (args.currentNumberOfSpans >= MAX_N_SPANS) return undefined;
    const lastSpanLine = args.line;

    let fullAllowableAreaPolygonMP: Feature<Polygon | MultiPolygon> | null = feature(args.boundary);
    for (const obs of args.obstacles) {
        if (!fullAllowableAreaPolygonMP) {
            return undefined;
        }
        fullAllowableAreaPolygonMP = difference(fullAllowableAreaPolygonMP, obs);
    }
    if (!fullAllowableAreaPolygonMP) return undefined;
    
    // can only be in a full allowable area:
    let fullAllowableAreaPolygon: Feature<Polygon>;
    if (fullAllowableAreaPolygonMP.geometry.type === 'Polygon') {
        fullAllowableAreaPolygon = fullAllowableAreaPolygonMP as Feature<Polygon>;
    }
    else {
        const found = fullAllowableAreaPolygonMP.geometry.coordinates.find(ring => {
            const p = polygon(ring);
            return booleanContains(p, lastSpanLine);
        })
        if (!found) {
            // Why not?
            console.log("Could not find feed line in multipolygon")
            return undefined;
        }
        fullAllowableAreaPolygon = polygon(found);
    }
    let directionMultiplier = args.direction === 'fwd' ? 1 : -1;

    let best: IDropOptimizerResult | undefined = undefined;

    // first try a drop span considering wheel obstacles as spanable:
    const newSpans = optimizeSpansForLateral({
        boundary: args.boundary,
        feedLine: args.line,
        wheelObstacles: args.wheelObstacles,
        allowableSpanLengths: args.allowableSpanLengths,
        side: args.direction,
        obstacles: [],
        isFlangedSideAndAttachedToCart: false,
        isHoseFeed: false,
        currentNumberOfSpans: args.currentNumberOfSpans
    });
    // console.log("fffffffffffff", featureCollection([fullAllowableAreaPolygon, feature(args.line)]) )
    for (let i = 0; i < newSpans.spans.length; i++) {
        const thisBest = trySpans(
            newSpans.spans.slice(0, i + 1),
            lastSpanLine,
            directionMultiplier,
            fullAllowableAreaPolygon,
            [],
            undefined,
            args.endBoom,
            args.currentNumberOfSpans
        )
        if (thisBest && (!best || best.area < thisBest.area)) {
            best = thisBest;
        }
    }
    

    const spanLengthsFeetToUse: IFillSystemSpan[] = (
        args.allowableSpanLengths
            ? args.allowableSpanLengths.map(partialToSpanLengthItem) 
            : getAvailableSpanLengthsWithExtension(false, false)).map(x => {
                return ({
                    spanLength: x.spanLength, spanExtension: x.extension, spanAndExtensionLength: x.totalLength
                })
            }
    );
    spanLengthsFeetToUse.sort((a, b) => a.spanAndExtensionLength - b.spanAndExtensionLength);
    // next try a drop span considering wheel obstacles as a wheel obstacles:
    let best2: IDropOptimizerResult | undefined = undefined;
    if (spanLengthsFeetToUse.length) {
        let doEnd = true;
        const spans: IFillSystemSpan[] = [];
        do {
            doEnd = true;
            let innerAllowableSpanI = 0;
            spans.push({ spanLength: 0, spanExtension: false, spanAndExtensionLength: 0 });
            let restrictions: { from: number, to: number } | undefined = best2
                ? { from: best2.systemConfigurationHeadingFrom, to: best2.systemConfigurationHeadingTo }
                : undefined;
            do {
                spans[spans.length - 1] = spanLengthsFeetToUse[innerAllowableSpanI];
                const thisBest = trySpans(
                    spans,
                    lastSpanLine,
                    directionMultiplier,
                    fullAllowableAreaPolygon,
                    args.wheelObstacles,
                    restrictions,
                    args.endBoom,
                    args.currentNumberOfSpans
                )
                if (thisBest && (!best2 || best2.area < thisBest.area)) {
                    best2 = thisBest;
                    doEnd = false;
                }

                innerAllowableSpanI++;
    
            } while (innerAllowableSpanI < spanLengthsFeetToUse.length);    
        } while (!doEnd);
    }

    // next try a drop span considering wheel obstacles as obstacles:
    let fullAllowableAreaPolygonWithWheelsAsObs: Feature<Polygon | MultiPolygon> | null = clone(fullAllowableAreaPolygon);
    for (const obs of args.wheelObstacles) {
        if (fullAllowableAreaPolygonWithWheelsAsObs) {
            fullAllowableAreaPolygonWithWheelsAsObs = difference(fullAllowableAreaPolygonWithWheelsAsObs, obs);
        }
    }
    let best3: IDropOptimizerResult | undefined = undefined;
    if (fullAllowableAreaPolygonWithWheelsAsObs && spanLengthsFeetToUse.length) {
        let doEnd = true;
        const spans: IFillSystemSpan[] = [];
        let restrictions: { from: number, to: number } | undefined = best3
            ? { from: best3.systemConfigurationHeadingFrom, to: best3.systemConfigurationHeadingTo }
            : undefined;
        do {
            doEnd = true;
            let innerAllowableSpanI = 0;
            spans.push({ spanLength: 0, spanExtension: false, spanAndExtensionLength: 0 });
            do {
                spans[spans.length - 1] = spanLengthsFeetToUse[innerAllowableSpanI];
                const thisBest = trySpans(
                    spans,
                    lastSpanLine,
                    directionMultiplier,
                    fullAllowableAreaPolygonWithWheelsAsObs,
                    [],
                    restrictions,
                    args.endBoom,
                    args.currentNumberOfSpans
                )
                if (thisBest && (!best3 || best3.area < thisBest.area)) {
                    best3 = thisBest;
                    doEnd = false;
                }
                // console.log("...spans",...spans, ":", thisBest?.area, Math.max(thisBest?.area || 0, best3?.area || 0 ))
                innerAllowableSpanI++;
    
            } while (innerAllowableSpanI < spanLengthsFeetToUse.length)
    
        } while (!doEnd);
    }

    let veryBest: IDropOptimizerResult | undefined = undefined;
    if (best) veryBest = best;
    if (!veryBest || (best2 && best2.area > veryBest.area)) veryBest = best2;
    if (!veryBest || (best3 && best3.area > veryBest.area)) veryBest = best3;
    
    return veryBest;
}

const optimize = (args: IArgs) => {
    return calculateDropSpanPolygonsForSide(args)
}

export default optimize;