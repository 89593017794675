/* 
 * NOTE:
 * 
 * This .part.ts file contains functions partly extracted from
 *   roedata/roe_migration/SpanFunctions.ts
 *
 */

import { ISystemBase, SystemTypes } from "../project/ISystemBase.AutoGenerated";
import { ISide, ISpan } from "../project/Types";
import { Cosineof162Degrees } from "./CommonConstants.part";

export const additionalSpanLength = (spanLength: number) => 
    ((spanLength !== 1 && spanLength <= 61) || spanLength === 85 || spanLength === 105) 
        ? 1 
        : 0;
export const LengthInFeet = (side: ISide, span: ISpan): number => {
    const l = span.Length + additionalSpanLength(span.Length);
    return l + (span.Extension ? 4 : 0) + ((span === side.Span[side.Span.length - 1] && !span.EndBoom) ? 1 : 0);
} 

export const StartingLocation = (sys: ISystemBase, side: ISide, span: ISpan): number => {
    if (!span) {
        throw new Error("span not set");
    }
    if (span === side.Span[0]) {
        if (sys.SystemProperties.SystemType === SystemTypes.CenterPivot || sys.SystemProperties.SystemType === SystemTypes.KwikTow) {
            return 2;
        } else {
            return 0;
        }
    } else {
        const spanIndex = side.Span.indexOf(span);
        return EndingLocation(sys, side, side.Span[spanIndex - 1]);
    }
}

export const EndingLocation = (sys: ISystemBase, side: ISide, span: ISpan): number =>
    StartingLocation(sys, side, span) + LengthInFeet(side, span);

export const StartingRadius = (sys: ISystemBase, side: ISide, span: ISpan): number => {
    if (span === side.Span[0]) {
        if (sys.SystemProperties.SystemType === SystemTypes.CenterPivot || sys.SystemProperties.SystemType === SystemTypes.KwikTow) {
            return 2;
        } else {
            return 0;
        }
    } else {
        const spanIndex = side.Span.indexOf(span);
        return EndingRadius(sys, side, side.Span[spanIndex - 1]);
    }
}

export const EndingRadius = (sys: ISystemBase, side: ISide, span: ISpan): number => {
    if (span === side.Span[0]) {
        return StartingRadius(sys, side, span) + LengthInFeet(side, span);
    } else {
        let spanIndex = side.Span.indexOf(span);
        const prevSpan = side.Span[spanIndex - 1];
        const bSwingArm = span.SwingArm || (span.EndBoom && prevSpan.SwingArm);
        if (bSwingArm) {
            let Lp: number;
            let Lsac: number;
            if (span.EndBoom) {
                Lp = StartingLocation(sys, side, prevSpan);
                Lsac = LengthInFeet(side, span) + LengthInFeet(side, prevSpan);
            } else {
                Lp = StartingLocation(sys, side, span);
                Lsac = LengthInFeet(side, span);
            }
            return Math.sqrt(Lp ** 2 + Lsac ** 2 - 2 * Lp * Lsac * Cosineof162Degrees)
        } else {
            return StartingRadius(sys, side, span) + LengthInFeet(side, span);
        }
    }
}