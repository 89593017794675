import { Feature, MultiPolygon, Point, Polygon, bbox, booleanClockwise, booleanContains, distance, ellipse, feature, intersect, point, polygon } from "@turf/turf";
import { customDifference } from "rdptypes/geometry/helpers/turf";
import { EndGunTypes, EndOfSystemTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";
import { endBoomLengthsFeet, spanLengthsFeet } from "rdptypes/reinkeComponents";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import { BoundaryHelper } from "../../GeometryHelpers/BoundaryHelper";
import { ObstacleHelper } from "../../GeometryHelpers/ObstacleHelper";
import { ESegmentClearanceType } from "../../GeometryHelpers/SegmentHelper";
import { PartialSpanLengthItem } from "../../components/OptimizeSystemDialog/RestrictSpanLengthsControl";
import IProject from "../../model/project/IProject";
import { applyObstacleClearanceTo, getExistingSystemPolygons } from "../helpers/base";
import { compareSystems } from "../objectives/compareSystems";
import { DEFAULT_CPA_RATIO, DEFAULT_CPA_RATIO_FOR_SINGLE_SYSTEM } from "../objectives/defaultCpaRatio";
import { sumOptimizedSpans } from "../spans/optimizeSpansCommon";
import { optimizeSpansForCenterPivot, optimizeSpansForPartialPivot } from "../spans/optimizeSpansForCenterPivot";
import { MAX_N_SPANS } from "../spans/spanCombinationsCache";
import { OPTIMIZATION_CONSTANTS } from "./constants";
import fullPivotOptimizer from "./fullPivot";
import partialPivotOptimizer from "./partialPivot";
import wrapSpanOptimizer from "./wrapSpan";

export interface IOptimizationSettings {
    center?: Point;
    allowPartialPivots?: boolean;
    allowWrapSpans?: boolean;
    maxNumberOfWrapSpans?: number;
    allowableSpanLengths?: PartialSpanLengthItem[];

    forcePartialPivot?: boolean; // using for debug
    
    allowDropSpans?: boolean;
    maxNumberOfDropSpans?: number;

    allowableEndBoomLengths?: number[];
    sacType?: SwingArmLengths;
    endOfSystemType?: EndOfSystemTypes;
    sacIsLeading?: boolean;

    primaryEndGun: EndGunTypes;
    secondaryEndGun: EndGunTypes;
    primaryEndGunThrow?: number;
    secondaryEndGunThrow?: number;

    maxSystemRadiusFt?: number;
    minSystemRadiusFt?: number;

    cpaRatio: number;
}

export interface IArgs {
    optimizationSettings?: IOptimizationSettings;
    optimizationArgs: IOptimizationArgs;
}

export interface IProgress {
    message: string;
    iStep: number;
    nTotalSteps: number;
    state: IOptimizedSystem[];
}

export interface IOptimizedSystem {
    center: Point;
    clockwiseCompassHeadingStart?: number;
    clockwiseCompassHeadingEnd?: number;
    spanTowers: { 
        spanLength: number;
        clockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
        anticlockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
        extension: boolean;
    }[];
    endBoom?: number;
    sac?: SwingArmLengths;
    sacIsLeading?: boolean;
    primaryEndGun: EndGunTypes;
    secondaryEndGun: EndGunTypes;
    primaryEndGunThrow?: number;
    secondaryEndGunThrow?: number;
}

export interface IOptions {
    nCells?: number;
    quick?: boolean;
}

export interface IOptimizationArgsInput {
    project: IProject;
    layoutId: string;
    sacType?: SwingArmLengths;
}
export interface IOptimizationArgs {
    bufferedCenterBoundaries: Polygon[],
    bufferedFieldBoundaries: Polygon[],
    bufferedWheelObstacles: Polygon[],
    bufferedObstacles: Polygon[],
    boundaryPointAngles: {
        p: Point;
        angle: number;
    }[];
}
export const createOptimizationArgs = (args: IOptimizationArgsInput): undefined | IOptimizationArgs => {
    // const t = createTimerLog("cpo");

    const layout = args.project.layouts[args.layoutId];
    if (!layout.fieldBoundary) {
        // cannot optimize without a defined field boundary
        return undefined;
    }
    
    // If we are adding a SAC, the system needs to be contained within the H Tower boundary:
    const bNoSac = (!args.sacType || args.sacType === SwingArmLengths.None);
    const bufferedFieldBoundaries = bNoSac
        ? BoundaryHelper.getClearancePolygons(layout.fieldBoundary)
        : BoundaryHelper.getClearancePolygons(layout.fieldBoundary, ESegmentClearanceType.HTowerClearance);
    if (bufferedFieldBoundaries.length === 0) {
        // cannot optimize without a defined buffered field boundary
        return undefined;
    }
    const bufferedCenterBoundaries = layout.pivotCenterBoundary
            ? BoundaryHelper.getClearancePolygons(layout.pivotCenterBoundary)
            : [ undefined ];
            
    if (bufferedCenterBoundaries.length === 0) {
        // cannot optimize without a defined buffered center boundary (note, undefined is an array entry for no center boundary)
        return undefined;
    }
    
    // TODO: Note, this is a quick fix for a SAC system, considering all obstacles as H Tower obstacles. In reality only the last tower should consider these,
    // and all other towers should consider the equipment obstacles
    const bufferedWheelObstacles = bNoSac
        ? layout.wheelObstacles.flatMap(x => ObstacleHelper.getClearancePolygons(x))
        : layout.wheelObstacles.flatMap(x => ObstacleHelper.getClearancePolygons(x, ESegmentClearanceType.HTowerClearance));
        
    const bufferedObstacles: Polygon[] = bNoSac
        ? [
            ...layout.obstacles.flatMap(o => ObstacleHelper.getClearancePolygons(o)),
            ...getExistingSystemPolygons(args.project, args.layoutId).flatMap(p => applyObstacleClearanceTo(p, args.project.systemClearance))
        ]
        : [
            ...layout.obstacles.flatMap(o => ObstacleHelper.getClearancePolygons(o, ESegmentClearanceType.HTowerClearance)),
            ...getExistingSystemPolygons(args.project, args.layoutId).flatMap(p => applyObstacleClearanceTo(p, args.project.systemClearance))
        ];

    const boundaryPointAngles: {
        p: Point;
        angle: number;
    }[] = [];
    for (const poly of bufferedFieldBoundaries) {
        for (const ring of poly.coordinates) {
            const cw = booleanClockwise(ring);
            for (let i = 0; i < ring.length - 1; i++) {
                let pm1 = i === 0 ? ring[ring.length - 2] : ring[i-1]; // -2 due to closing point of ring
                let pp1 = ring[i+1];
                let p = ring[i];
                const a1 = [ pm1[0] - p[0], pm1[1] - p[1]];
                const a2 = [ pp1[0] - p[0], pp1[1] - p[1]];
                const aa1 = Math.atan2(a1[1], a1[0]);
                const aa2 = Math.atan2(a2[1], a2[0]);
                let d = (cw ? aa2 - aa1 : aa1 - aa2) * 180 / Math.PI;
                d = (d + 360) % 360;
                boundaryPointAngles.push({
                    p: point(p).geometry,
                    angle: d
                })
            }
        }
    }
    
    return {
        bufferedCenterBoundaries,
        bufferedFieldBoundaries,
        bufferedObstacles,
        bufferedWheelObstacles,
        boundaryPointAngles
    }

}

export const optimize = async (args: IArgs, options?: IOptions): Promise<IOptimizedSystem | undefined> => {
    // const t = createTimerLog("cpo");
    const optimizationSettings: IOptimizationSettings = args.optimizationSettings || {
        primaryEndGun: EndGunTypes.None,
        secondaryEndGun: EndGunTypes.None,
        cpaRatio: DEFAULT_CPA_RATIO
    };
    // console.log("CPA RATIO", optimizationSettings.cpaRatio,DEFAULT_CPA_RATIO_FOR_SINGLE_SYSTEM)
    // optimizationSettings.forcePartialPivot = true;
    if (optimizationSettings.allowDropSpans) {
        console.log("TODO: DropSpans")
        console.log("optimizationSettings.allowDropSpans", optimizationSettings.allowDropSpans)
        console.log("optimizationSettings.allowDropSpans", optimizationSettings.maxNumberOfDropSpans)
    }

    
    let best: IOptimizedSystem | undefined = undefined;
    for (const bufferedFieldBoundary of args.optimizationArgs.bufferedFieldBoundaries) {
        for (const bufferedCenterBoundary of args.optimizationArgs.bufferedCenterBoundaries) {
            const currentSpanAndWheelObs = await _optimizePostBuffer({
                bufferedCenterBoundary,
                bufferedFieldBoundary,
                bufferedWheelObstacles: args.optimizationArgs.bufferedWheelObstacles,
                bufferedObstacles: args.optimizationArgs.bufferedObstacles,
                optimizationSettings,
                boundaryPointAngles: args.optimizationArgs.boundaryPointAngles
            }, options);
            best = compareSystems(best, currentSpanAndWheelObs, DEFAULT_CPA_RATIO_FOR_SINGLE_SYSTEM, args.optimizationArgs.boundaryPointAngles);

            if (args.optimizationArgs.bufferedWheelObstacles.length) {
                // a wheel obstacle can effectivly become a span obstacle based on the 
                // pivot location if the wheel obstacle is large enough.
                // As a quick fix, we will run the post buffer optimizer again.
                // This time considering all wheel obs as span obs
                // Note: This will not find the optimal solution, but increases the chance
                // of a more optimal solution being found when large wheel obstacles exist
                const currentSpanObsOnly = await _optimizePostBuffer({
                    bufferedCenterBoundary,
                    bufferedFieldBoundary,
                    bufferedWheelObstacles: [],
                    bufferedObstacles: [ ...args.optimizationArgs.bufferedObstacles, ...args.optimizationArgs.bufferedWheelObstacles ],
                    optimizationSettings,
                    boundaryPointAngles: args.optimizationArgs.boundaryPointAngles
                }, options);
                best = compareSystems(best, currentSpanObsOnly, DEFAULT_CPA_RATIO_FOR_SINGLE_SYSTEM, args.optimizationArgs.boundaryPointAngles);
            }
            
        }
    }
    // t.logMS();
    return best;
}


interface IArgsPostBuffer {
    bufferedFieldBoundary: Polygon;
    bufferedCenterBoundary?: Polygon;
    optimizationSettings: IOptimizationSettings;
    bufferedObstacles: Polygon[];
    bufferedWheelObstacles: Polygon[];
    boundaryPointAngles: {
        p: Point;
        angle: number;
    }[];
}
const _optimizePostBuffer = async (args: IArgsPostBuffer, options?: IOptions): Promise<IOptimizedSystem | undefined> => {

    // const t = createTimerLog("cpo.postbuffer");
    const { 
        optimizationSettings, 
        bufferedFieldBoundary, bufferedCenterBoundary, 
        bufferedWheelObstacles
    } = args;

    // if a center is provided, then it must:
    //      - be within the field boundary
    //      - be within the center pivot boundary (if defined)
    if (optimizationSettings.center) {
        if (
            !booleanContains(bufferedFieldBoundary, optimizationSettings.center!) || // center out of bounds
            (bufferedCenterBoundary && !booleanContains(bufferedCenterBoundary, optimizationSettings.center!)) // center not in pivot center boundary
        ) {
            console.log("The chosen center is not valid.")
            return undefined;
        }
    }

    // lets remove and consider only the obstacle parts that are within the boundary:
    const bufferedObstacles: Polygon[] = [];
    for (const obs of args.bufferedObstacles) {
        const o = intersect(bufferedFieldBoundary, obs);
        if (!o) continue;
        if (o.geometry.type === 'Polygon') {
            bufferedObstacles.push(o.geometry)
        }
        else {
            for (const r of o.geometry.coordinates) {
                const rr = polygon(r);
                bufferedObstacles.push(rr.geometry)
            }
        }
    }

    // if a center is provided, then it must:
    //      - no be in an obstacle
    if (optimizationSettings.center) {
        if (
            bufferedObstacles.some(obs => booleanContains(obs, optimizationSettings.center!)) // center inside obstacle or other system
        ) {
            console.log("The chosen center is not valid.")
            return undefined;
        }
    }

    // is there any space in this field??
    let boundaryLeft: Feature<Polygon | MultiPolygon> | null = feature(
        bufferedCenterBoundary
            ? bufferedCenterBoundary
            : bufferedFieldBoundary
    );
    for (const obs of bufferedObstacles) {
        boundaryLeft = customDifference(boundaryLeft, obs);
        if (boundaryLeft === null) {
            console.log("No valid available space in field in which to optimize");
            return undefined;
        }
    }
    

    let optimizedFullPivot: IOptimizedSystem | undefined = undefined;
    let optimizedFullPivotRadius: number = 0;
    const checkIfSpaceForFullPivot = () => {
        if (args.optimizationSettings.minSystemRadiusFt) {
            // if we are passed a min system radius, we will filter out too smaller regions by the evelope polygon
            const [minX, minY, maxX, maxY] = bbox(bufferedFieldBoundary);
            const d = distance([minX, minY], [maxX, maxY], { units: 'feet' });
            if (d < args.optimizationSettings.minSystemRadiusFt * 2) {
                console.log("Field envelope is smaller then the 2x min radius for a full pivot");
                return false;
            }    
        }
        return true;
    }
    if (!optimizationSettings.forcePartialPivot && checkIfSpaceForFullPivot()) {
        // if there is a min radius, if the envelope of the field large enought:
        if (optimizationSettings.center) {
            const optimizedSpans = optimizeSpansForCenterPivot({
                center: optimizationSettings.center,
                boundary: bufferedFieldBoundary,
                obstacles: bufferedObstacles,
                allowableSpanLengths: optimizationSettings.allowableSpanLengths,
                wheelObstacles: bufferedWheelObstacles,
                allowableEndBoomLengths: optimizationSettings.endOfSystemType === EndOfSystemTypes.EndBoom 
                    ? optimizationSettings.allowableEndBoomLengths
                    : undefined,
                maxSystemRadiusFt: args.optimizationSettings.maxSystemRadiusFt
            });
            optimizedFullPivotRadius = sumOptimizedSpans(optimizedSpans);
            if (optimizationSettings.minSystemRadiusFt && optimizedFullPivotRadius < optimizationSettings.minSystemRadiusFt) {
                console.log("Full pivot solution system radius (after fitting spans) is smaller than the required minimum system radius");
            }
            else if (optimizedSpans.spans.length > 0) {
                optimizedFullPivot = {
                    center: optimizationSettings.center,
                    spanTowers: optimizedSpans.spans.map(x => ({ spanLength: x.spanLength, extension: x.spanExtension })),
                    endBoom: optimizedSpans.endBoom,
                    primaryEndGun: optimizationSettings.primaryEndGun,
                    secondaryEndGun: optimizationSettings.secondaryEndGun,
                    primaryEndGunThrow: optimizationSettings.primaryEndGunThrow,
                    secondaryEndGunThrow: optimizationSettings.secondaryEndGunThrow
                }
            }
        }
        else {
            const fullPivot = fullPivotOptimizer({
                boundary: bufferedFieldBoundary,
                obstacles: bufferedObstacles,
                centerBoundary: bufferedCenterBoundary
            });
            if (!fullPivot || !fullPivot.radiusFeet) {
                console.log("Could not find a full pivot solution");
            }
            else if (optimizationSettings.minSystemRadiusFt && fullPivot.radiusFeet < optimizationSettings.minSystemRadiusFt) {
                // end early before fitting spans, we will need to check again once the spans are fitted
                console.log("Full pivot solution system radius (before fitting spans) is smaller than the required minimum system radius");
            }
            else {
                const boundary = ellipse(fullPivot.center, fullPivot.radiusFeet, fullPivot.radiusFeet, {
                    units: 'feet', steps: OPTIMIZATION_CONSTANTS.SECTOR_STEPS
                });
                
                const optimizedSpans = optimizeSpansForCenterPivot({
                    obstacles: bufferedObstacles,
                    center: fullPivot.center,
                    boundary: boundary.geometry,
                    allowableSpanLengths: optimizationSettings.allowableSpanLengths,
                    wheelObstacles: bufferedWheelObstacles,
                    allowableEndBoomLengths: optimizationSettings.endOfSystemType === EndOfSystemTypes.EndBoom 
                        ? optimizationSettings.allowableEndBoomLengths
                        : undefined,
                    maxSystemRadiusFt: args.optimizationSettings.maxSystemRadiusFt
                });
                optimizedFullPivotRadius = sumOptimizedSpans(optimizedSpans);
                if (optimizationSettings.minSystemRadiusFt && optimizedFullPivotRadius < optimizationSettings.minSystemRadiusFt) {
                    console.log("Full pivot solution system radius (after fitting spans) is smaller than the required minimum system radius");
                }
                else if (optimizedSpans.spans.length > 0) {
                    optimizedFullPivot = {
                        center: fullPivot.center,
                        spanTowers: optimizedSpans.spans.map(x => ({ spanLength: x.spanLength, extension: x.spanExtension })),
                        endBoom: optimizedSpans.endBoom,
                        primaryEndGun: optimizationSettings.primaryEndGun,
                        secondaryEndGun: optimizationSettings.secondaryEndGun,
                        primaryEndGunThrow: optimizationSettings.primaryEndGunThrow,
                        secondaryEndGunThrow: optimizationSettings.secondaryEndGunThrow
                    }
                }
            }

        }
    }
    // t.logDeltaMS("fp");

    let optimizedPartialPivot: IOptimizedSystem | undefined = undefined;
    // t.logDeltaMS("init");
    if (optimizationSettings.forcePartialPivot || optimizationSettings.allowPartialPivots) {
        // const tpp = createTimerLog("cpo.postbuffer.pp");
        const minSystemRadiusFt = Math.max(
            args.optimizationSettings?.minSystemRadiusFt ?? 0,
            optimizedFullPivotRadius
        )
        let maxSystemRadiusFt = args.optimizationSettings?.maxSystemRadiusFt;
        if (!maxSystemRadiusFt) {
            // If no max has been set, we calculate the max theoritcal radius based on max N spans so 
            // to make the pp optimizer result more optimal
            const maxSpanLength = (args.optimizationSettings.allowableSpanLengths ?? [ { spanLength: spanLengthsFeet.slice(-1)[0], extension: false} ])
                .reduce((prev, crnt) => Math.max(prev, crnt.spanLength + (crnt.extension ? 4 : 0)) + spanf.additionalSpanLength(crnt.spanLength),0);
            maxSystemRadiusFt = MAX_N_SPANS * maxSpanLength;
            if (args.optimizationSettings.endOfSystemType === EndOfSystemTypes.EndBoom) {
                const maxEndBoomLength = (args.optimizationSettings.allowableEndBoomLengths ?? [ endBoomLengthsFeet.slice(-1)[0] ])
                    .reduce((prev, crnt) => Math.max(prev, crnt + spanf.additionalSpanLength(crnt)),0);
                maxSystemRadiusFt += maxEndBoomLength;
            }
            else {
                maxSystemRadiusFt += 4;
            }
            maxSystemRadiusFt += 2 + 1;
        }
        const partialPivot = await partialPivotOptimizer({
            boundary: bufferedFieldBoundary,
            obstacles: bufferedObstacles,
            center: optimizationSettings.center,
            centerBoundary: bufferedCenterBoundary,
            maxSystemRadiusFt: maxSystemRadiusFt,
            minSystemRadiusFt: minSystemRadiusFt
        }, options);
        // tpp.logDeltaMS("optimize");

        if (!partialPivot || !partialPivot.radiusFeet) {
            console.log("Could not find a partial pivot solution");
        }
        else if (partialPivot.minBearing === 0 && partialPivot.maxBearing === 360) {
            console.log("Partial pivot solution is a full pivot");
        }
        else if (optimizationSettings.minSystemRadiusFt && partialPivot.radiusFeet < optimizationSettings.minSystemRadiusFt) {
            // end early before fitting spans, we will need to check again once the spans are fitted
            console.log("Partial pivot solution system radius (before fitting spans) is smaller than the required minimum system radius");
        }
        else {                
            // TEMP FIX:
            // sometimes the pivot overlaps an obstacle
            let minBearing = partialPivot.minBearing;
            let maxBearing = partialPivot.maxBearing;
            let minCount = 3;
            let maxCount = 3;
            // NOTE: The los optmizer had a bug which sometimes
            // returned overlapping solutions. This bug is believed to be fixed,
            // as such. For speed improvement. This check has now been removed.
            // I have left commented incase it needs re-instating
            // const filteredObs = bufferedObstacles.filter(obs => {
            //     for (const ring of obs.coordinates) {
            //         const ptld = pointToLineDistance(partialPivot.center, lineString(ring), { units: 'feet' });
            //         if (ptld < partialPivot.radiusFeet + 10) {
            //             return true;
            //         }
            //     }
            //     return false;
            // })
            const hasOverlap = () => {
                return false;
                // const s = FeatureHelpers.GetSectorDrawFeature(
                //     partialPivot.center, 
                //     partialPivot.radiusFeet, 
                //     minBearing - 0.05, maxBearing + 0.05, // These are extended by half the reducing offset to be conservative (sometimes it failed to find collisions otherwise)
                //     null, { units: 'feet', degreeIncrement: 1 } 
                // );
                // return (
                //     filteredObs.some(o => booleanOverlap(o, s)) ||
                //     !booleanContains(bufferedFieldBoundary, s)
                // );
            }
            while (hasOverlap() && (minCount > 0 || maxCount > 0)) {
                if (maxCount > minCount) {
                    maxBearing -= 0.1;
                    maxCount--;
                } 
                else {
                    minBearing += 0.1;
                    minCount--;
                }
            }
            // tpp.logDeltaMS("reduce overlap");
            if (!hasOverlap()) {
                // console.log("no overlap", featureCollection([
                //     FeatureHelpers.GetSectorDrawFeature(
                //         partialPivot.center, 
                //         partialPivot.radiusFeet, 
                //         minBearing, maxBearing, 
                //         null, { units: 'feet', degreeIncrement: 0.1 } 
                //     ),
                //     ...bufferedObstacles.map(o => feature(o)),
                //     feature(bufferedFieldBoundary)
                // ]))
                partialPivot.minBearing = minBearing;
                partialPivot.maxBearing = maxBearing;
                const optimizedSpans = optimizeSpansForPartialPivot({
                    obstacles: [],
                    center: partialPivot.center,
                    radiusFeet: partialPivot.radiusFeet,
                    minBearing: partialPivot.minBearing,
                    maxBearing: partialPivot.maxBearing,
                    allowableSpanLengths: optimizationSettings.allowableSpanLengths,
                    wheelObstacles: bufferedWheelObstacles,
                    allowableEndBoomLengths: optimizationSettings.endOfSystemType === EndOfSystemTypes.EndBoom 
                        ? optimizationSettings.allowableEndBoomLengths
                        : undefined,
                });
                // tpp.logDeltaMS("spans");
    
                if (optimizationSettings.minSystemRadiusFt && sumOptimizedSpans(optimizedSpans) < optimizationSettings.minSystemRadiusFt) {
                    console.log("Partial pivot solution system radius (after fitting spans) is smaller than the required minimum system radius");
                }
                else if (optimizedSpans.spans.length > 0) {
                    optimizedPartialPivot = {
                        center: partialPivot.center,
                        clockwiseCompassHeadingStart: partialPivot.minBearing,
                        clockwiseCompassHeadingEnd: partialPivot.maxBearing,
                        spanTowers: optimizedSpans.spans.map(x => ({ spanLength: x.spanLength, extension: x.spanExtension })),
                        endBoom: optimizedSpans.endBoom,
                        primaryEndGun: optimizationSettings.primaryEndGun,
                        secondaryEndGun: optimizationSettings.secondaryEndGun,
                        primaryEndGunThrow: optimizationSettings.primaryEndGunThrow,
                        secondaryEndGunThrow: optimizationSettings.secondaryEndGunThrow
                    }
                }
            }
            else {
                console.log("Partial pivot solution could not be reduced to a non overlapping pivot");
            }
                
        }
    }

    // t.logDeltaMS("pp");

    if (!optimizedPartialPivot && !optimizedFullPivot) {
        return undefined;
    }

    // wraps:
    if (optimizedPartialPivot && optimizationSettings.allowWrapSpans) {
        optimizedPartialPivot = wrapSpanOptimizer({
            maxNumberOfWrapSpans: optimizationSettings.maxNumberOfWrapSpans,
            optimizedPartialPivot,
            bufferedWheelObstacles,
            bufferedObstacles,
            bufferedFieldBoundary
        })
        // t.logDeltaMS("wraps");
    }

    const x = compareSystems(optimizedFullPivot, optimizedPartialPivot, DEFAULT_CPA_RATIO_FOR_SINGLE_SYSTEM, args.boundaryPointAngles);
    // t.logDeltaMS("finialize");
    if (!x) return undefined;
    if (optimizationSettings.endOfSystemType === EndOfSystemTypes.SAC) {
        x.sac = optimizationSettings.sacType || SwingArmLengths.None;
        x.sacIsLeading = optimizationSettings.sacIsLeading || false;
    }
    return x;
}