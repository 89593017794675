// NOTE:
// End Boom optimizer retrofit!
// The end boom optimizer pops of the last span and adds the largest
// end of system <= to the popped span length
// TODO: Update the optimizer to account for end spans while optimizing

import { LineString, Polygon, booleanContains, booleanDisjoint, feature, length, lineSliceAlong, polygon } from "@turf/turf";
import IProject from "../../model/project/IProject";
import { optimizeSpansForLateral } from "../spans/optimizeSpansForLateral";

import { loCustom } from "rdptypes/geometry/helpers/turf";
import { EndGunTypes, EndOfSystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { BoundaryHelper } from "../../GeometryHelpers/BoundaryHelper";
import { ObstacleHelper } from "../../GeometryHelpers/ObstacleHelper";
import { PartialSpanLengthItem } from "../../components/OptimizeSystemDialog/RestrictSpanLengthsControl";
import { getAvailableSpanLengthsWithExtension } from "../../helpers/validation/spanExtensions";
import { applyObstacleClearanceTo, getExistingSystemPolygons } from "../helpers/base";
import { IFillSystemSpan, sum } from "../spans/optimizeSpansCommon";
import dropSpanOptimizer, { IDropOptimizerResult } from "./dropSpan";

export interface IOptimizationSettings {
    feedLine?: LineString;
    allowableSpanLengths?: PartialSpanLengthItem[];
    endFeed?: boolean;
    canalFeed?: {
        canalCenterToFwdSide: number;
        canalCenterToAftSide: number;
    }
    allowDropSpans?: boolean;
    maxNumberOfDropSpans?: number;
    
    endOfSystemTypeFwd?: EndOfSystemTypes.EndBoom;
    restrictedEndBoomLengthsFwd: number[];
    endOfSystemTypeAft?: EndOfSystemTypes.EndBoom;
    restrictedEndBoomLengthsAft: number[];
    
    primaryEndGun: EndGunTypes;
    secondaryEndGun: EndGunTypes;    
    primaryEndGunThrow?: number;
    secondaryEndGunThrow?: number;
}

interface IArgs {
    project: IProject;
    layoutId: string;
    optimizationSettings: IOptimizationSettings;
    systemId?: string;
}

interface IOptSys_Span {
    lengthFeet: number;
    dropSpan?: {
        systemConfigurationHeadingFrom: number;
        systemConfigurationHeadingTo: number;
    };
    extension: boolean;
};
export interface IOptimizedSystem {
    feedLine: LineString;
    spans: IOptSys_Span[];
    aftSideSpanCount: number;
    aftEndBoom?: number;
    fwdEndBoom?: number;
    primaryEndGun: EndGunTypes;
    secondaryEndGun: EndGunTypes;    
    primaryEndGunThrow?: number;
    secondaryEndGunThrow?: number;
}

export const optimize = (args: IArgs): IOptimizedSystem | undefined => {

    const optimizationSettings = args.optimizationSettings;

    const layout = args.project.layouts[args.layoutId];
    if (!layout.fieldBoundary) {
        // cannot optimize without a defined field boundary
        return undefined;
    }
    const bufferedFieldBoundaries = BoundaryHelper.getClearancePolygons(layout.fieldBoundary);
    if (bufferedFieldBoundaries.length === 0) {
        // cannot optimize without a defined buffered field boundary
        return undefined;
    }

    if (!optimizationSettings.feedLine) {
        // cannot optimize a lateral without a feedline
        return undefined;
    }
    const feedLine = optimizationSettings.feedLine;
    const fwdFeedLine = optimizationSettings.canalFeed && optimizationSettings.canalFeed.canalCenterToFwdSide
        ? loCustom(feedLine, optimizationSettings.canalFeed.canalCenterToFwdSide, { units: 'feet' }).geometry
        : feedLine;
    const aftFeedLine = optimizationSettings.canalFeed && optimizationSettings.canalFeed.canalCenterToAftSide
        ? loCustom(feedLine, -optimizationSettings.canalFeed.canalCenterToAftSide, { units: 'feet' }).geometry
        : feedLine;
    const canalPoly = optimizationSettings.canalFeed
        ? polygon([
            [
                ...fwdFeedLine.coordinates,
                ...[ ...aftFeedLine.coordinates ].reverse(),
                fwdFeedLine.coordinates[0]
            ]
        ]).geometry
        : undefined;

    
    const bufferedWheelObstacles = layout.wheelObstacles.flatMap(x => ObstacleHelper.getClearancePolygons(x));
    
    const bufferedObstacles: Polygon[] = [
        ...layout.obstacles.flatMap(x => ObstacleHelper.getClearancePolygons(x)),
        ...getExistingSystemPolygons(args.project, args.layoutId).flatMap(p => applyObstacleClearanceTo(p, args.project.systemClearance))
    ];
    
    // the feedline cannot be in an obstacle or wheel obstacle:
    if (
        bufferedObstacles.some(obs => !booleanDisjoint(obs, feedLine)) || 
        bufferedWheelObstacles.some(obs => !booleanDisjoint(obs, feedLine)) ||
        bufferedObstacles.some(obs => !booleanDisjoint(obs, fwdFeedLine)) || 
        bufferedWheelObstacles.some(obs => !booleanDisjoint(obs, fwdFeedLine)) ||
        bufferedObstacles.some(obs => !booleanDisjoint(obs, aftFeedLine)) || 
        bufferedWheelObstacles.some(obs => !booleanDisjoint(obs, aftFeedLine)) ||
        (canalPoly && bufferedObstacles.some(obs => !booleanDisjoint(obs, canalPoly))) ||
        (canalPoly && bufferedWheelObstacles.some(obs => !booleanDisjoint(obs, canalPoly)))
    ) {
        console.log("The chosen feedline is not valid.")
        return undefined;
    }

    // the feedline can only possibly be in one of the bufferedFieldBoundaries:
    const bufferedFieldBoundary = bufferedFieldBoundaries.find(bfb => booleanContains(bfb, feedLine));
    if (!bufferedFieldBoundary) {
        console.log("The chosen feedline is not valid.")
        return undefined;
    }
    
    // the feedline cannot be in an obstacle or wheel obstacle:
    if (
        !booleanContains(bufferedFieldBoundary, fwdFeedLine) ||
        !booleanContains(bufferedFieldBoundary, aftFeedLine) ||
        (canalPoly && !booleanContains(bufferedFieldBoundary, canalPoly))
    ) {
        console.log("The chosen feedline is not valid.")
        return undefined;
    }
    
    // TODO: What happens if we add an end boom and drops?
    const mainResult = optimizeMainLateral({
        optimizationSettings,
        obstacles: bufferedObstacles,
        boundary: bufferedFieldBoundary,
        wheelObstacles: bufferedWheelObstacles,
        endBoomLengths: optimizationSettings.restrictedEndBoomLengthsFwd
    });
    if (!mainResult) return;

    if (!args.optimizationSettings.allowDropSpans || !args.optimizationSettings.maxNumberOfDropSpans) {
        const fwdSpans = args.optimizationSettings.endOfSystemTypeFwd === EndOfSystemTypes.EndBoom
            ? mainResult.withEndboomFwd.spans
            : mainResult.withoutEndboomFwd.spans;
        const aftSpans = args.optimizationSettings.endOfSystemTypeAft === EndOfSystemTypes.EndBoom
            ? (mainResult.withEndboomAft?.spans || [])
            : (mainResult.withoutEndboomAft?.spans || []);
        const fwdEndBoom = args.optimizationSettings.endOfSystemTypeFwd === EndOfSystemTypes.EndBoom
            ? mainResult.withEndboomFwd.endBoom
            : undefined;
        const aftEndBoom = args.optimizationSettings.endOfSystemTypeAft === EndOfSystemTypes.EndBoom
            ? mainResult.withEndboomAft?.endBoom
            : undefined;
        const fwdSpansRet: IOptSys_Span[] = fwdSpans.map(x => ({
            lengthFeet: x.spanLength,
            extension: x.spanExtension
        }))
        const aftSpansRet: IOptSys_Span[] = aftSpans.map(x => ({
            lengthFeet: x.spanLength,
            extension: x.spanExtension
        })).reverse();
        return {
            feedLine: args.optimizationSettings.feedLine,
            spans: [
                ...aftSpansRet, ...fwdSpansRet
            ],
            aftSideSpanCount: aftSpansRet.length,
            primaryEndGun: args.optimizationSettings.primaryEndGun,
            primaryEndGunThrow: args.optimizationSettings.primaryEndGunThrow,
            secondaryEndGun: args.optimizationSettings.secondaryEndGun,
            secondaryEndGunThrow: args.optimizationSettings.secondaryEndGunThrow,
            aftEndBoom,
            fwdEndBoom
        }
    }

    // The optimize lateral last span line does not include the additional 1ft.
    // The drop span optimizer will consider this 1ft.
    let aftSpans = mainResult.withoutEndboomAft?.spans || [];
    let fwdSpans = mainResult.withoutEndboomFwd.spans || [];
    const fwdSpansRet: { spans: IOptSys_Span[], startLine: LineString }[] = [{
        spans: fwdSpans.map(x => ({
            lengthFeet: x.spanLength,
            extension: x.spanExtension
        })),
        startLine: fwdFeedLine
    }];
    const aftSpansRet: { spans: IOptSys_Span[], startLine: LineString }[] = [{
        spans: aftSpans.map(x => ({
            lengthFeet: x.spanLength,
            extension: x.spanExtension
        })),
        startLine: aftFeedLine
    }];
    let runningAftLastSpanLine = calculateLastSpanLine(aftFeedLine, aftSpans.map(s => s.spanAndExtensionLength), 'aft');
    let runningFwdLastSpanLine = calculateLastSpanLine(fwdFeedLine, fwdSpans.map(s => s.spanAndExtensionLength), 'fwd');
    let runningAftSystemHeadingOffset = 0;
    let runningFwdSystemHeadingOffset = 0;
    for (let i = 0; i < args.optimizationSettings.maxNumberOfDropSpans; i++) {
        let aftDropResults: IDropOptimizerResult | undefined = undefined;
        let fwdDropResults: IDropOptimizerResult | undefined = undefined;
        let currentNumberOfSpans = 0;
        aftSpansRet.forEach(asr => currentNumberOfSpans += asr.spans.length);
        fwdSpansRet.forEach(asr => currentNumberOfSpans += asr.spans.length);
        if (runningAftLastSpanLine) {
            aftDropResults = dropSpanOptimizer({
                line: runningAftLastSpanLine,
                boundary: bufferedFieldBoundary,
                direction: 'aft',
                obstacles: bufferedObstacles,
                wheelObstacles: bufferedWheelObstacles,
                allowableSpanLengths: optimizationSettings.allowableSpanLengths || getAvailableSpanLengthsWithExtension(false, false),
                currentNumberOfSpans
            })
        }
        if (runningFwdLastSpanLine) {
            fwdDropResults = dropSpanOptimizer({
                line: runningFwdLastSpanLine,
                boundary: bufferedFieldBoundary,
                direction: 'fwd',
                obstacles: bufferedObstacles,
                wheelObstacles: bufferedWheelObstacles,
                allowableSpanLengths: optimizationSettings.allowableSpanLengths || getAvailableSpanLengthsWithExtension(false, false),
                currentNumberOfSpans
            })
        }

        if (!aftDropResults && !fwdDropResults) continue;

        const aftArea = aftDropResults ? aftDropResults.area : 0;
        const fwdArea = fwdDropResults ? fwdDropResults.area : 0;
        if (aftArea === 0 && fwdArea === 0) continue;

        const isFwdBest = fwdArea >= aftArea;
        const dropResult = isFwdBest ? fwdDropResults! : aftDropResults!;
        const currentLastSpanLine = isFwdBest ? runningFwdLastSpanLine! : runningAftLastSpanLine!;

        if (isFwdBest) {
            const prev = fwdSpansRet[fwdSpansRet.length - 1];
            prev.spans[prev.spans.length - 1].dropSpan = {
                systemConfigurationHeadingFrom: dropResult.systemConfigurationHeadingFrom,
                systemConfigurationHeadingTo: dropResult.systemConfigurationHeadingTo
            }
            fwdSpansRet.push({
                spans: dropResult.spanLengths.map(s => ({ lengthFeet: s.spanLength, extension: s.spanExtension })),
                startLine: runningFwdLastSpanLine
            })
            const offsetLine = calculateLastSpanLine(currentLastSpanLine, dropResult.spanLengths.map(x => x.spanAndExtensionLength), 'fwd');
            const offsetLineLength = length(feature(offsetLine), { units: 'feet' })
            runningFwdLastSpanLine = lineSliceAlong(
                offsetLine!,
                dropResult.systemConfigurationHeadingFrom,
                offsetLineLength - dropResult.systemConfigurationHeadingTo,
                { units: 'feet'}
            ).geometry;
            runningFwdSystemHeadingOffset += dropResult.systemConfigurationHeadingFrom;
        }
        else {
            const prev = aftSpansRet[aftSpansRet.length - 1];
            prev.spans[prev.spans.length - 1].dropSpan = {
                systemConfigurationHeadingFrom: dropResult.systemConfigurationHeadingFrom,
                systemConfigurationHeadingTo: dropResult.systemConfigurationHeadingTo
            }
            aftSpansRet.push({
                spans: dropResult.spanLengths.map(s => ({ lengthFeet: s.spanLength, extension: s.spanExtension })),
                startLine: runningAftLastSpanLine
            })
            const offsetLine = calculateLastSpanLine(currentLastSpanLine, dropResult.spanLengths.map(x => x.spanAndExtensionLength), 'aft');
            const offsetLineLength = length(feature(offsetLine), { units: 'feet' })
            runningAftLastSpanLine = lineSliceAlong(
                offsetLine!,
                dropResult.systemConfigurationHeadingFrom,
                offsetLineLength - dropResult.systemConfigurationHeadingTo,
                { units: 'feet'}
            ).geometry;
            runningAftSystemHeadingOffset += dropResult.systemConfigurationHeadingFrom;
        }
    }
    // console.log("aft", structuredClone(aftSpansRet))
    // console.log("fwd", structuredClone(fwdSpansRet))
    // Now that we have the drops, we must check if the last drop should be fitted with an endboom
    const spans: IOptSys_Span[] = [];
    let aftEndBoom: number | undefined = undefined;
    let fwdEndBoom: number | undefined = undefined;
    let aftSideSpanCount = 0;
    if (aftSpansRet.length && args.optimizationSettings.endOfSystemTypeAft === EndOfSystemTypes.EndBoom) {
        if (aftSpansRet.length === 1) {
            // then no drop was fitted, we will use the endboom optimized version
            let aftSpans = mainResult.withEndboomAft?.spans || [];
            aftEndBoom = mainResult.withEndboomAft?.endBoom;
            spans.push(
                ...aftSpans.map(x => ({
                    lengthFeet: x.spanLength,
                    extension: x.spanExtension
                })).reverse()
            )
            aftSideSpanCount = spans.length;
        }
        else {
            // we pop the last result and re-optimize with an endboom:
            const prevSpans = aftSpansRet[aftSpansRet.length - 1];
            const twicePrevSpans = aftSpansRet[aftSpansRet.length - 2];
            
            let best: IDropOptimizerResult | undefined = undefined;
            let bestEndBoom: number | undefined = undefined;
            let currentNumberOfSpans = -prevSpans.spans.length;
            aftSpansRet.forEach(asr => currentNumberOfSpans += asr.spans.length);
            fwdSpansRet.forEach(asr => currentNumberOfSpans += asr.spans.length);
            for (const endBoom of args.optimizationSettings.restrictedEndBoomLengthsAft) {
                const dropResults = dropSpanOptimizer({
                    line: prevSpans.startLine,
                    boundary: bufferedFieldBoundary,
                    direction: 'aft',
                    obstacles: bufferedObstacles,
                    wheelObstacles: bufferedWheelObstacles,
                    allowableSpanLengths: optimizationSettings.allowableSpanLengths || getAvailableSpanLengthsWithExtension(false, false),
                    endBoom,
                    currentNumberOfSpans
                });
                if (!dropResults) continue;
                if (!best || dropResults.area > best.area) {
                    best = dropResults;
                    bestEndBoom = endBoom;
                }
            }
            // console.log("aft main", best, spans.length)
            if (best) {
                spans.push(
                    ...best.spanLengths.map(x => ({
                        lengthFeet: x.spanLength,
                        extension: x.spanExtension
                    })).reverse()
                )
                aftSideSpanCount = spans.length;
                twicePrevSpans.spans[twicePrevSpans.spans.length - 1].dropSpan = {
                    systemConfigurationHeadingFrom: best.systemConfigurationHeadingFrom,
                    systemConfigurationHeadingTo: best.systemConfigurationHeadingTo
                }
                aftEndBoom = bestEndBoom;
            }
            else {
                spans.push(
                    ...prevSpans.spans.reverse()
                )
                aftSideSpanCount = spans.length;
            }
            for (let i = aftSpansRet.length - 2; i >= 0; i--) {
                const y = aftSpansRet[i];
                spans.push(
                    ...y.spans.reverse()
                )
                aftSideSpanCount += y.spans.length;
            }
        }
    }
    else {
        for (let i = aftSpansRet.length - 1; i >= 0; i--) {
            const y = aftSpansRet[i];
            spans.push(
                ...y.spans.reverse()
            )
            aftSideSpanCount += y.spans.length;
        }
    }
    if (fwdSpansRet.length && args.optimizationSettings.endOfSystemTypeFwd === EndOfSystemTypes.EndBoom) {
        if (fwdSpansRet.length == 1) {
            // then no drop was fitted, we will use the endboom optimized version
            let fwdSpans = mainResult.withEndboomFwd?.spans || [];
            fwdEndBoom = mainResult.withEndboomFwd?.endBoom;
            spans.push(
                ...fwdSpans.map(x => ({
                    lengthFeet: x.spanLength,
                    extension: x.spanExtension
                }))
            )
        }
        else {
            for (let i = 0; i < fwdSpansRet.length - 1; i++) {
                const y = fwdSpansRet[i];
                spans.push(
                    ...y.spans
                )
            }
            // we pop the last result and re-optimize with an endboom:
            const prevSpans = fwdSpansRet[fwdSpansRet.length - 1];
            
            let best: IDropOptimizerResult | undefined = undefined;
            let bestEndBoom: number | undefined = undefined;
            let currentNumberOfSpans = aftSpans.length + fwdSpans.length + spans.length;
            for (const endBoom of args.optimizationSettings.restrictedEndBoomLengthsFwd) {
                const dropResults = dropSpanOptimizer({
                    line: prevSpans.startLine,
                    boundary: bufferedFieldBoundary,
                    direction: 'fwd',
                    obstacles: bufferedObstacles,
                    wheelObstacles: bufferedWheelObstacles,
                    allowableSpanLengths: optimizationSettings.allowableSpanLengths || getAvailableSpanLengthsWithExtension(false, false),
                    endBoom,
                    currentNumberOfSpans
                });
                if (!dropResults) continue;
                if (!best || dropResults.area > best.area) {
                    best = dropResults;
                    bestEndBoom = endBoom;
                }
            }
            // console.log("fwd main", best, spans.length)
            if (best) {
                spans[spans.length - 1].dropSpan = {
                    systemConfigurationHeadingFrom: best.systemConfigurationHeadingFrom,
                    systemConfigurationHeadingTo: best.systemConfigurationHeadingTo
                }
                spans.push(
                    ...best.spanLengths.map(x => ({
                        lengthFeet: x.spanLength,
                        extension: x.spanExtension
                    })).reverse()
                )
                fwdEndBoom = bestEndBoom;
            }
            else {
                spans.push(
                    ...prevSpans.spans
                )
            }
        }
    }
    else {
        for (let i = 0; i < fwdSpansRet.length; i++) {
            const y = fwdSpansRet[i];
            spans.push(
                ...y.spans
            )
        }
    }
    // console.log("Spans", spans)
    // console.log("aftCount", aftSideSpanCount)
    // console.log("aftEndBoom", aftEndBoom)
    // console.log("fwdEndBoom", fwdEndBoom)
    // console.log("TEST", featureCollection([
    //     ...aftSpansRet.flatMap(x => feature(x.startLine)),
    //     ...fwdSpansRet.flatMap(x => feature(x.startLine))
    // ]))

    // Due to the fact that the feedline is reversed on the system obj.
    // The from/to drops need to be swapped
    spans.forEach(s => {
        if (s.dropSpan) {
            const temp = s.dropSpan.systemConfigurationHeadingFrom;
            s.dropSpan.systemConfigurationHeadingFrom = s.dropSpan.systemConfigurationHeadingTo;
            s.dropSpan.systemConfigurationHeadingTo = temp;
        }
    })
    return {
        feedLine: args.optimizationSettings.feedLine,
        spans,
        aftSideSpanCount,
        primaryEndGun: args.optimizationSettings.primaryEndGun,
        primaryEndGunThrow: args.optimizationSettings.primaryEndGunThrow,
        secondaryEndGun: args.optimizationSettings.secondaryEndGun,
        secondaryEndGunThrow: args.optimizationSettings.secondaryEndGunThrow,
        aftEndBoom,
        fwdEndBoom
    }
}

const maxLTE = (value: number, selectFrom: number[]): number | undefined => {
    let found: number | undefined = undefined;
    for (const s of selectFrom) {
        if (s > value) continue;
        if (found === undefined || s > found) {
            found = s;
        }
    }
    return found;
}

const calculateLastSpanLine = (startLine: LineString, spanLengths: number[], direction: 'fwd' | 'aft') => {
    let lastSpanLine: LineString | undefined = undefined;
    if (spanLengths.length > 0) {
        const multiplier = direction === 'fwd' ? 1 : -1;
        lastSpanLine = startLine;
        for (const spanLength of spanLengths) {
            lastSpanLine = loCustom(lastSpanLine, multiplier * spanLength, { units: 'feet' }).geometry;
        }
    }
    return lastSpanLine;
}

interface IArgs2 {
    obstacles: Polygon[];
    boundary: Polygon;
    optimizationSettings: IOptimizationSettings;
    wheelObstacles: Polygon[];
    endBoomLengths: number[];
}
type IOptimizeMainLateralResult = (IOptimizedSystem & { aftLastSpanLine?: LineString; fwdLastSpanLine?: LineString }) | undefined;
type IOptimizeMainLateralResult2 = {
    withEndboomFwd: {
        spans: IFillSystemSpan[];
        endBoom?: number;
    },
    withoutEndboomFwd: {
        spans: IFillSystemSpan[];
        endBoom?: number;
    }
    withEndboomAft?: {
        spans: IFillSystemSpan[];
        endBoom?: number;
    },
    withoutEndboomAft?: {
        spans: IFillSystemSpan[];
        endBoom?: number;
    }
};
const optimizeMainLateral = (args: IArgs2): IOptimizeMainLateralResult2 => {
    const { obstacles, boundary, optimizationSettings, wheelObstacles } = args;
    const endFeed = args.optimizationSettings.endFeed || false;
    const hoseFeed = args.optimizationSettings.canalFeed !== undefined;

    if (!optimizationSettings.feedLine) {
        // cannot optimize a lateral without a feedline
        return undefined;
    }

    // Simple case: Rigid side only. This is based on the line direction.
    if (endFeed) {
        let feedLine = optimizationSettings.feedLine;
        if (optimizationSettings.canalFeed) {
            feedLine = loCustom(feedLine, optimizationSettings.canalFeed.canalCenterToFwdSide, { units: 'feet' }).geometry;
        }
        const optimizedSpansFwdSide_withEndboom = optimizeSpansForLateral({
            obstacles,
            feedLine: feedLine,
            boundary: boundary,
            allowableSpanLengths: args.optimizationSettings?.allowableSpanLengths,
            side: 'fwd',
            wheelObstacles,
            isFlangedSideAndAttachedToCart: true,
            isHoseFeed: hoseFeed,
            allowableEndBoomLengths: args.endBoomLengths,
            currentNumberOfSpans: 0
        });
        const optimizedSpansFwdSide_withoutEndboom = optimizeSpansForLateral({
            obstacles,
            feedLine: feedLine,
            boundary: boundary,
            allowableSpanLengths: args.optimizationSettings?.allowableSpanLengths,
            side: 'fwd',
            wheelObstacles,
            isFlangedSideAndAttachedToCart: true,
            isHoseFeed: hoseFeed,
            currentNumberOfSpans: 0
        });
        return {
            withEndboomFwd: optimizedSpansFwdSide_withEndboom,
            withoutEndboomFwd: optimizedSpansFwdSide_withoutEndboom
        }
    }

    // Complex Case: Flex and rigid side.
    // Two scenarios: 
    //  - s1: no canal feed
    //  - s2: canal feed
    let aftFeedLine: LineString;
    let fwdFeedLine: LineString;
    if (optimizationSettings.canalFeed) {
        aftFeedLine = loCustom(
            optimizationSettings.feedLine,
            -optimizationSettings.canalFeed.canalCenterToAftSide,
            { units: 'feet' }
        ).geometry;
        fwdFeedLine = loCustom(
            optimizationSettings.feedLine,
            optimizationSettings.canalFeed.canalCenterToFwdSide,
            { units: 'feet' }
        ).geometry;
    }
    else {
        aftFeedLine = optimizationSettings.feedLine;
        fwdFeedLine = optimizationSettings.feedLine;
    }

    // For the flanged side, we reserve at least one span for the flex side
    // by setting the currentNumberofSpans to 1
    const optimizedSpansAftSideFlanged_WithEndboom = optimizeSpansForLateral({
        obstacles,
        feedLine: aftFeedLine,
        boundary: boundary,
        allowableSpanLengths: optimizationSettings?.allowableSpanLengths,
        side: 'aft',
        wheelObstacles,
        isFlangedSideAndAttachedToCart: true,
        isHoseFeed: hoseFeed,
        allowableEndBoomLengths: args.endBoomLengths,
        currentNumberOfSpans: 1
    });
    const optimizedSpansAftSideFlanged_WithoutEndboom = optimizeSpansForLateral({
        obstacles,
        feedLine: aftFeedLine,
        boundary: boundary,
        allowableSpanLengths: optimizationSettings?.allowableSpanLengths,
        side: 'aft',
        wheelObstacles,
        isFlangedSideAndAttachedToCart: true,
        isHoseFeed: hoseFeed,
        currentNumberOfSpans: 1
    });
    const optimizedSpansFwdSideFlanged_WithEndboom = optimizeSpansForLateral({
        obstacles,
        feedLine: fwdFeedLine,
        boundary: boundary,
        allowableSpanLengths: optimizationSettings?.allowableSpanLengths,
        side: 'fwd',
        wheelObstacles,
        isFlangedSideAndAttachedToCart: true,
        isHoseFeed: hoseFeed,
        allowableEndBoomLengths: args.endBoomLengths,
        currentNumberOfSpans: 1
    });
    const optimizedSpansFwdSideFlanged_WithoutEndboom = optimizeSpansForLateral({
        obstacles,
        feedLine: fwdFeedLine,
        boundary: boundary,
        allowableSpanLengths: optimizationSettings?.allowableSpanLengths,
        side: 'fwd',
        wheelObstacles,
        isFlangedSideAndAttachedToCart: true,
        isHoseFeed: hoseFeed,
        currentNumberOfSpans: 1
    });

    // NOTE: Taking the max of with/without endboom to pass into flex side may result in suobtimal result
    const maxAftFlangedSpans = Math.max(optimizedSpansAftSideFlanged_WithEndboom.spans.length, optimizedSpansAftSideFlanged_WithoutEndboom.spans.length);
    const maxFwdFlangedSpans = Math.max(optimizedSpansFwdSideFlanged_WithEndboom.spans.length, optimizedSpansFwdSideFlanged_WithoutEndboom.spans.length);
        
    const optimizedSpansAftSideFlex_WithEndboom = optimizeSpansForLateral({
        obstacles,
        feedLine: aftFeedLine,
        boundary: boundary,
        allowableSpanLengths: optimizationSettings?.allowableSpanLengths,
        side: 'aft',
        wheelObstacles,
        isFlangedSideAndAttachedToCart: false,
        isHoseFeed: hoseFeed,
        allowableEndBoomLengths: args.endBoomLengths,
        currentNumberOfSpans: maxFwdFlangedSpans
    });
    const optimizedSpansAftSideFlex_WithoutEndboom = optimizeSpansForLateral({
        obstacles,
        feedLine: aftFeedLine,
        boundary: boundary,
        allowableSpanLengths: optimizationSettings?.allowableSpanLengths,
        side: 'aft',
        wheelObstacles,
        isFlangedSideAndAttachedToCart: false,
        isHoseFeed: hoseFeed,
        currentNumberOfSpans: maxFwdFlangedSpans
    });
    const optimizedSpansFwdSideFlex_WithEndboom = optimizeSpansForLateral({
        obstacles,
        feedLine: fwdFeedLine,
        boundary: boundary,
        allowableSpanLengths: optimizationSettings?.allowableSpanLengths,
        side: 'fwd',
        wheelObstacles,
        isFlangedSideAndAttachedToCart: false,
        isHoseFeed: hoseFeed,
        allowableEndBoomLengths: args.endBoomLengths,
        currentNumberOfSpans: maxAftFlangedSpans
    });
    const optimizedSpansFwdSideFlex_WithoutEndboom = optimizeSpansForLateral({
        obstacles,
        feedLine: fwdFeedLine,
        boundary: boundary,
        allowableSpanLengths: optimizationSettings?.allowableSpanLengths,
        side: 'fwd',
        wheelObstacles,
        isFlangedSideAndAttachedToCart: false,
        isHoseFeed: hoseFeed,
        currentNumberOfSpans: maxAftFlangedSpans
    });
    const sum_optimizedSpansAftSideFlex_WithoutEndboom = sum(optimizedSpansAftSideFlex_WithoutEndboom.spans.map(x => x.spanAndExtensionLength));
    const sum_optimizedSpansAftSideFlanged_WithoutEndboom = sum(optimizedSpansAftSideFlanged_WithoutEndboom.spans.map(x => x.spanAndExtensionLength));
    const sum_optimizedSpansFwdSideFlex_WithoutEndboom = sum(optimizedSpansFwdSideFlex_WithoutEndboom.spans.map(x => x.spanAndExtensionLength));
    const sum_optimizedSpansFwdSideFlanged_WithoutEndboom = sum(optimizedSpansFwdSideFlanged_WithoutEndboom.spans.map(x => x.spanAndExtensionLength));

    const is_aftFlex_fwdFlanged_valid = sum_optimizedSpansFwdSideFlanged_WithoutEndboom >= sum_optimizedSpansAftSideFlex_WithoutEndboom;
    const is_aftFlanged_fwdFlex_valid = sum_optimizedSpansAftSideFlanged_WithoutEndboom >= sum_optimizedSpansFwdSideFlex_WithoutEndboom;

    let spans: IOptimizeMainLateralResult2 | undefined;
    if (is_aftFlex_fwdFlanged_valid && is_aftFlanged_fwdFlex_valid) {
        const aftFlex_fwdFlanged = sum_optimizedSpansAftSideFlex_WithoutEndboom + sum_optimizedSpansFwdSideFlanged_WithoutEndboom;
        const aftFlanged_fwdFlex = sum_optimizedSpansAftSideFlex_WithoutEndboom + sum_optimizedSpansFwdSideFlanged_WithoutEndboom;
        //console.log(aftFlex_fwdFlanged >= aftFlanged_fwdFlex ? "A" : "B")
        spans = aftFlex_fwdFlanged >= aftFlanged_fwdFlex
            ? {
                withEndboomAft: optimizedSpansAftSideFlex_WithEndboom,
                withEndboomFwd: optimizedSpansFwdSideFlanged_WithEndboom,
                
                withoutEndboomAft: optimizedSpansAftSideFlex_WithoutEndboom,
                withoutEndboomFwd: optimizedSpansFwdSideFlanged_WithoutEndboom,
            }
            : {
                withEndboomAft: optimizedSpansAftSideFlanged_WithEndboom,
                withEndboomFwd: optimizedSpansFwdSideFlex_WithEndboom,
                
                withoutEndboomAft: optimizedSpansAftSideFlanged_WithoutEndboom,
                withoutEndboomFwd: optimizedSpansFwdSideFlex_WithoutEndboom
            }
    }
    else if (is_aftFlex_fwdFlanged_valid) {
        //console.log("C")
        spans = {
            withEndboomAft: optimizedSpansAftSideFlex_WithEndboom,
            withEndboomFwd: optimizedSpansFwdSideFlanged_WithEndboom,
            
            withoutEndboomAft: optimizedSpansAftSideFlex_WithoutEndboom,
            withoutEndboomFwd: optimizedSpansFwdSideFlanged_WithoutEndboom
        }
    }
    else if (is_aftFlanged_fwdFlex_valid) {
        //console.log("D")
        spans = {
            withEndboomAft: optimizedSpansAftSideFlanged_WithEndboom,
            withEndboomFwd: optimizedSpansFwdSideFlex_WithEndboom,
            
            withoutEndboomAft: optimizedSpansAftSideFlanged_WithoutEndboom,
            withoutEndboomFwd: optimizedSpansFwdSideFlex_WithoutEndboom
        }
    }
    else {
        spans = undefined;
    }

    return spans;
}
