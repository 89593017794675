import { GeneratorOptions, InletTypes, SystemTypes } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import { ControlPanelOptionOK, GeneratorFrameTypeOk, JackKitOptionOK, ReinkeSignOptionOk } from "../Valids.Valids";

export default class OptionsForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        let opts = this.Quote?.System?.Options;
        if (opts){
            if ((opts.GeneratorOption ?? GeneratorOptions.None) === GeneratorOptions.GeneratorSet){
                if (!GeneratorFrameTypeOk(opts.GeneratorFrameType, {
                    GeneratorOption: opts.GeneratorOption,
                    HasControlPanelOption: opts.EngDigitalPanel
                })){
                    this.Quote.Warnings.Add(`Generator frame type invalid`);
                }

                let country = this.Quote.System.DealerProperties.Country;
                if (!ControlPanelOptionOK(opts.EngDigitalPanel, {
                    GeneratorOption: opts.GeneratorOption,
                    Country: country,
                    GeneratorModel: opts.GeneratorModel,
                })){
                    this.Quote.Warnings.Add(`Cannot have a Murphy Control Panel with the current generator setup`);
                }
            }

                if (!ReinkeSignOptionOk(opts.ReinkeSignMount, {
                    IsA100: this.Quote.IsA100_CF200()
                })){
                    this.Quote.Warnings.Add(`Cannot have Reinke sign/leg mount option with an A100 system`);
                }

            let inletType: InletTypes = null;
            if (this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && this.Quote.HoseFeedClass.FieldSet.DataValid()){
                inletType = this.Quote.System.Lateral.HoseFeed.InletType;
            }
            
            if (!JackKitOptionOK(opts.JackKit, {
                IsA100: this.Quote.IsA100_CF200(),
                InletType: inletType,
                IsTowable: this.Quote.IsTowable(),
            })){
                this.Quote.Warnings.Add(`Jack kit options invalid`);
            }
        }
    }
}