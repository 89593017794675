import { EndGunTypes, EndTowerPositioningTypes, GuidanceLocations, GuidanceTypes, HoseFeedTypes, ISystemBase, PanelLocations, PanelModels, SACPipeTypes, SpanTypes, SystemTypes, WaterFeedTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { SelectDualSprinklerPackageValveBoxTowerNumber } from "./CommonFunctions";
import * as otherh from "./OtherHelpers";
import * as sidef from "./SideFunctions";
import * as sysf from "./SystemFunctions";
import { Side, Span } from "./Types";
export { additionalSpanLength, EndingLocation, EndingRadius, LengthInFeet, StartingLocation, StartingRadius } from "rdptypes/helpers/SpanFunctions.part";

export enum CableTypes {
    FirstSpanPivot10C8G,
    FirstSpanPivot10C10G,
    FirstSpanPivot10C12G,
    FirstSpanPivot11C10G,
    FirstSpanPivot14C10G,
    FirstSpanKwikTow10C8G,
    FirstSpanKwikTow10C10G,
    FirstSpanKwikTow10C12G,
    FirstSpanKwikTow11C10G,
    FirstSpanKwikTow14C10G,
    FirstSpanCanalCenter10C8G,
    FirstSpanCanalCenter10C10G,
    FirstSpanMaxiEnd10C8G,
    FirstSpanMaxiEnd10C10G,
    FirstSpanMaxi11C8G,
    FirstSpanMaxi11C10G,
    FirstSpanMaxi14C10G,
    Disc10C8G,
    Disc10C10G,
    Disc10C10GLastSpanSAC,
    Standard10C8G,
    Standard10C10G,
    Standard10C12G,
    Standard11C10G,
    Standard14C10G,
    FirstSpanPivotingLateralC14,
    FirstSpanCF200C14,
};

export enum idxCableConductors {
    cc10,
    cc11,
    cc14
}

export enum SpanPipeTypes {
    Galvanized,
    Painted,
    Aluminum,
    Stainless,
    Poly
}

export const ConductorsRequired = (sys: ISystemBase, Side: Side, span: Span): number => ConductorCount(sys, Side, span);

export const CenterFeedConductorCalc = (System: ISystemBase, FlangedSide: boolean): number => {
    const eControlPanelLocation = System.ControlPanel.Location;
    switch (eControlPanelLocation) {
        case PanelLocations.FlangedSide:
        default:
            return FlangedSide ? 10 : 9;
        case PanelLocations.FlexSide:
            return FlangedSide ? 9 : 10;
    }
}

export const ConductorCount = (sys: ISystemBase, Side: Side, span: Span, FundamentalCount = false): number => {
    if (sysf.IsEDMP(sys)) return 0;
    let bElectricEOS: boolean = sidef.ElectricEOS(Side);
    let eGuidanceType: GuidanceTypes;
    let eGuidanceLocation: GuidanceLocations;
    let iGuidanceTower: number = 0;
    let iConductorsNeededToGuidanceTower: number = 0;
    let ConductorsNeededToSprinklerBox: number = 0;
    let ConductorsNeededToEOS: number = 0;
    let eSystemType: SystemTypes = sys.SystemProperties.SystemType;
    let sprinklerBoxTowerNumber: number = 0;

    const fs = sysf.FieldSets(sys);

    if (fs.Guidance.DataValid()) {
    if (fs.FlangedTowers.DataValid() || fs.FlexTowers.DataValid()) {
            eGuidanceType = sys.Lateral.Guidance.GuidanceType;
            eGuidanceLocation = sys.Lateral.Guidance.Location;
            if (FundamentalCount) {
                iGuidanceTower = 1;
            } else {
                iGuidanceTower = sidef.GuidanceTower(Side);
            }
            if (eGuidanceLocation === GuidanceLocations.OuterTower) {
                switch (eGuidanceType) {
                    case GuidanceTypes.BuriedWire:
                    case GuidanceTypes.NavigatorGPS:
                        if (eSystemType === SystemTypes.HoseFeedMaxigator && sys.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200) {
                            iConductorsNeededToGuidanceTower = 3;
                        } else {
                            iConductorsNeededToGuidanceTower = 2;
                        }
                        break;
                    case GuidanceTypes.Furrow:
                        if (eSystemType === SystemTypes.HoseFeedMaxigator && sys.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200) {
                            iConductorsNeededToGuidanceTower = 3;
                        } else {
                            iConductorsNeededToGuidanceTower = 1;
                        }
                        break;
                }
            }

            //Mid-Mount = OuterTower (means towards the center of the machines, NOT LAST TOWER) 
            //ROE-199 - Dual Sprinkler Valve Components for Pivoting Lateral
            //Already tested for FlangedTowers.DataValid() so no need here. Testing FieldSets.SprinklerConfig validates SprinklerProperties
            if (fs.SprinklerConfig.DataValid() && sys.SprinklerProperties.DualSprinklerPackage) {
                ConductorsNeededToSprinklerBox += 1
                if (sys.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary !== undefined
                    && sys.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary !== EndGunTypes.None) {
                    ConductorsNeededToEOS += 1
                }
                sprinklerBoxTowerNumber = SelectDualSprinklerPackageValveBoxTowerNumber(sys, sys.FlangedSide)
            }
        }
    }

    let TotalConductors: number = 0;
    let LastSpan: number;
    let SpanNum: number;
    if (FundamentalCount) {
        SpanNum = 1;
        LastSpan = 1;
    } else {
        SpanNum = span.SpanNumber;
        LastSpan = sidef.NumberOfSpans(Side);
    }

    if (SpanNum < LastSpan) { // Get downstream count
        TotalConductors = ConductorsRequired(sys, Side, Side.Span[SpanNum])
    }

    switch (eSystemType) { // Add in conductors we need
        case SystemTypes.CenterPivot:
        case SystemTypes.KwikTow:
            if (SpanNum === LastSpan) {
                if (sysf.Is230V(sys)) {
                    TotalConductors += 8;
                } else {
                    TotalConductors += 9; // Base system needs 9 conductors
                }
            }
            break;
        case SystemTypes.HoseFeedMaxigator:
            if (!fs.HoseFeed.DataValid()) return 0;
            switch (sys.Lateral.WaterFeed) {
                case WaterFeedTypes.EndFeed:
                    // Don't use a case statement here in case we have a one span system
                    if (SpanNum === LastSpan) {
                        TotalConductors += 9; // Base system needs 9 conductors
                    }

                    // Conductor for Pivoting is handled here
                    if (otherh.Pivoting(sys.Lateral.HoseFeed) || sys.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                        if (SpanNum === LastSpan) {
                            TotalConductors += ConductorsNeededToEOS;
                        }

                        if (SpanNum === sprinklerBoxTowerNumber &&
                            sprinklerBoxTowerNumber > iGuidanceTower) TotalConductors += ConductorsNeededToSprinklerBox;

                        switch (eGuidanceLocation) {
                            case GuidanceLocations.PowerTower:
                                // One conductor for pivoting when guidance is on power tower
                                if (SpanNum === 1) {
                                    TotalConductors += 1;
                                    // The A-Box on Tower 1 will share a conductor with Sprinkler Box
                                    if (sprinklerBoxTowerNumber > 0) TotalConductors -= ConductorsNeededToSprinklerBox;
                                }
                                break;
                            case GuidanceLocations.OuterTower:
                                switch (eGuidanceType) {
                                    case GuidanceTypes.BuriedWire:
                                    case GuidanceTypes.NavigatorGPS:
                                        iConductorsNeededToGuidanceTower += 1;
                                        break;
                                    case GuidanceTypes.Furrow:
                                        if (SpanNum === 1) {
                                            TotalConductors += 1
                                        }
                                        break;
                                }
                                break;
                        }
                    }

                    if (SpanNum === 1) {
                        // Two conductors for PowerTowerGuidance. The reason behind this is that hose draggers will not move unless the first tower tells it to, because the hose drag power tower may slip.
                        if (eGuidanceLocation === GuidanceLocations.PowerTower) {
                            TotalConductors += 2;
                        }
                    }
                    break;
                case WaterFeedTypes.CenterFeed:
                    if (SpanNum === LastSpan) {
                        /// 9 or 10 conductors required based on panel location and side
                        TotalConductors += CenterFeedConductorCalc(sys, sidef.FlangedSide(sys, Side));
                    }
                    break;
            }
            break;
        case SystemTypes.CanalFeedMaxigator:
            if (!fs.CanalFeed.DataValid()) return 0;
            switch (sys.Lateral.WaterFeed) {
                case WaterFeedTypes.CenterFeed:
                    if (SpanNum === LastSpan) {
                        // 9 or 10 conductors required based on panel location and side
                        TotalConductors += CenterFeedConductorCalc(sys, sidef.FlangedSide(sys, Side));
                    }
                    break;
                    default:
                case WaterFeedTypes.EndFeed:
                    if (SpanNum === LastSpan) {
                        TotalConductors += 9; // Base system needs 9 conductors
                    }
                    break;
            }
            break;
    }

    // Guidance
    if (SpanNum === iGuidanceTower) {
        TotalConductors += iConductorsNeededToGuidanceTower;
        if (iGuidanceTower < sprinklerBoxTowerNumber) { // Sprinkler Box will SHARE 1 conductor with the Guidance Box from the Power Tower
            TotalConductors -= ConductorsNeededToSprinklerBox;
        }
    }

    // Electric End Gun Switch requires 1 conductors up to end of system
    if (bElectricEOS) {
        if (SpanNum === LastSpan) {
            TotalConductors += 1;
            if (eSystemType === SystemTypes.HoseFeedMaxigator
                && sys.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200
                && (Side.EndOfSystem.EndGun.EndGunTypeSecondary ?? EndGunTypes.None) !== EndGunTypes.None) {
                    // NOTE: Conductors were added up to the Guidance Tower. If we have
                    // a secondary End Gun, then we need to add another conductor after the Guidance Tower.
                    TotalConductors += 1;
                }
        }
    }

    if (!FundamentalCount) {
        // Extra Conductors
        let ecc = Side.Span[SpanNum - 1].ExtraCableConductors;
        if (!isNaN(ecc)){
            TotalConductors += ecc;
        }
        // Only add in the incremental change in Extra Conductors
        if (SpanNum < LastSpan) {
            let ecc2 = Side.Span[SpanNum].ExtraCableConductors;
            if (!isNaN(ecc2)){
                TotalConductors -= ecc2;
            }
        }
    }

    if (eSystemType === SystemTypes.HoseFeedMaxigator && SpanNum === 1 &&
        sys.Lateral.WaterFeed === WaterFeedTypes.EndFeed && eGuidanceLocation === GuidanceLocations.OuterTower) {
        // This is a cop-out.  10C is ok on regular hose feed end feed but
        // Reinke is has great difficulty generating span cable part numbers
        // So in this case, pretend 10C cable does not exist, so ensure that we get 11C
        if (TotalConductors < 11) TotalConductors = 11;
    }

    // ROE-515 - Add extra conductor when 10 under specific conditions
    //    This is a HACK, but correctly placed in the ConductorCount() 
    //   method so it's universal. 
    // NOTE: This should be integrated in the section above marked by 
    //   (ROE-515): there may be a case when 11 conductors are used
    //   first and then adding 1 might bump the requirement to 12 
    //   which results in the selection of a 14 conductor cable.
    if (eSystemType === SystemTypes.CenterPivot) {
        let PanelModel = PanelModels.RPMBasic;
        let EndTowerPositioningType = EndTowerPositioningTypes.None;
        if (fs.ControlPanel.DataValid()) {
            PanelModel = sys.ControlPanel.PanelModel;
            EndTowerPositioningType = sys.ControlPanel.EndTowerPositioning;
        }
        let hasVRI = false;
        if (fs.SprinklerConfig.DataValid()) {
            hasVRI = sys.SprinklerProperties.VRIZones.Zone.length > 0;
        }
        let EndGun2Type = EndGunTypes.None;
        if (fs.FlangedEndOfSystem.DataValid()) {
            EndGun2Type = Side.EndOfSystem.EndGun.EndGunTypeSecondary;
        }

        if (PanelModel === PanelModels.RPMPreferred &&
            EndTowerPositioningType === EndTowerPositioningTypes.GPS &&
                hasVRI &&
                EndGun2Type !== EndGunTypes.None &&
                TotalConductors === 10) {
                    // ( For both SAC and Non-SAC )
            TotalConductors += 1
        }
    }
    // END ROE-515

    return TotalConductors;
}

export const CableConductors = (sys: ISystemBase, side: Side, span: Span): idxCableConductors => {
    const cr = ConductorsRequired(sys, side, span);
    if (cr <= 10) {
        return idxCableConductors.cc10;
    } else if (cr == 11) {
        return idxCableConductors.cc11;
    } else if (cr <= 14) {
        return idxCableConductors.cc14;
    } else {
        console.warn("Too many conductors - " + cr);
        //TODO: fix this - was previously erroring.
        //presumably we should just be validating this? could we create a new enum val idxCableConductors.tooMany and
        //validate this later?
    }
}

export const PipeOutsideDiameter = (span: Span): number => {
    switch (span.SpanType) {
        case SpanTypes.E2100:
            return 10;
        case SpanTypes.E2085:
            return 8.625;
        case SpanTypes.A80G:
            return 8;
        case SpanTypes.A60G:
            return 6;
        case SpanTypes.S2085G:
            return 8.625;
        case SpanTypes.PL2085G:
            return 8.625;
        case SpanTypes.E2065:
            return 6.625;
        case SpanTypes.E2665:
            return 6.625;
        case SpanTypes.S2065G:
            if (span.EndBoom) {
                switch (span.Length) {
                    case 1:
                    case 13:
                    case 23:
                        return 3;
                    case 32:
                    case 42:
                    case 51:
                    case 61:
                        return 6.625;
                    case 85:
                    case 105:
                        return 4.5;
                }
            } else {
                return 6.625;
            }
        case SpanTypes.PL2065G:
            return 6.625;
        case SpanTypes.E2060:
            if (span.EndBoom) {
                switch (span.Length) {
                    case 1:
                    case 13:
                    case 23:
                        return 3;
                    case 32:
                    case 42:
                    case 51:
                    case 61:
                        return 6;
                    case 85:
                    case 105:
                        return 4.5;
                }
            } else {
                return 6;
            }
        case SpanTypes.E2660:
            if (span.EndBoom) {
                switch (span.Length) {
                    case 1:
                    case 13:
                    case 23:
                        return 3;
                    case 32:
                    case 42:
                    case 51:
                    case 61:
                        return 6;
                    case 85:
                    case 105:
                        return 4.5;
                }
            } else {
                return 6;
            }
        case SpanTypes.AlumIV:
            if (span.EndBoom) {
                switch (span.Length) {
                    case 1:
                    case 13:
                    case 23:
                        return 4.5;
                    case 32:
                    case 42:
                    case 51:
                    case 61:
                        return 6;
                    case 85:
                    case 105:
                        return 4.5;
                }
            } else {
                return 6;
            }
        case SpanTypes.E2045:
            if (span.EndBoom) {
                switch (span.Length) {
                    case 1:
                    case 13:
                    case 23:
                        return 3;
                    case 32:
                    case 42:
                    case 51:
                    case 61:
                        return 4.5;
                }
            } else {
                return 4.5;
            }
    }
}

const ComputeSwingArmType = (sys:ISystemBase): SpanTypes => {
    switch (sys.Circle.SwingArm.PipeCoating) {
        case SACPipeTypes.GalvanizedCNPlus:
        case SACPipeTypes.PaintedCNPlus:
            return SpanTypes.E2660;
        case SACPipeTypes.Galvanized:
        default:
            return SpanTypes.E2060;
    }
}

        const ComputeEndBoomType = (sys: ISystemBase, side: Side, span: Span): SpanTypes => {
        if (sysf.HasSwingArmCorner(sys)) {
            return ComputeSwingArmType(sys);
        }
        else {
            let iDependentSideNumberOfSpans: number = sidef.NumberOfSpans(side);
            let sp: Span = sysf.HasPowerTowerEndBoom(sys) ? sys.FlangedSide.Span[0] : side.Span[iDependentSideNumberOfSpans - 1];

            if (!sp) {
                // Can happen if we choose the end of system type before adding any spans (system defaults)
                return SpanTypes.E2065;
            }

            const dependentSideSpanType = SpanType(sys, side, sp);

            switch (dependentSideSpanType) {
                case SpanTypes.E2045:
                    return SpanTypes.E2045;
                    break;
                case SpanTypes.E2060:
                case SpanTypes.E2065:
                    return SpanTypes.E2060;
                    break;
                case SpanTypes.E2660:
                case SpanTypes.E2665:
                    return SpanTypes.E2660;
                    break;
                case SpanTypes.AlumIV:
                case SpanTypes.A60G:
                    return SpanTypes.AlumIV;
                    break;
                case SpanTypes.S2065G:
                    return SpanTypes.S2065G;
                    break;
                case SpanTypes.PL2065G:
                    return SpanTypes.PL2065G;
                    break;
            }
        }
        }

export const SpanType = (sys: ISystemBase, side: Side, span: Span): SpanTypes =>
    span.EndBoom ? ComputeEndBoomType(sys, side, span) : span.SwingArm ? ComputeSwingArmType(sys) : span.SpanType;

export const PipeInsideDiameter = (sys: ISystemBase, side: Side, span: Span): number => {
    const spanType = SpanType(sys, side, span);

    switch (spanType) {
        case SpanTypes.E2100:
            return 9.8;
        case SpanTypes.E2085:
            return 8.43;
        case SpanTypes.A80G:
            return 7.8;
        case SpanTypes.A60G:
            return 5.8;
        case SpanTypes.S2085G:
            return 8.425;
        case SpanTypes.PL2085G:
            return 7.935;
        case SpanTypes.E2065:
            return 6.43;
        case SpanTypes.E2665:
            return 6.415;
        case SpanTypes.S2065G:
            if (span.EndBoom) {
                switch (span.Length) {
                    case 1:
                    case 13:
                    case 23:
                        return 2.8;
                    case 32:
                    case 42:
                    case 51:
                    case 61:
                        return 6.425;
                    case 85:
                    case 105:
                        return 4.3;
                }
            } else {
                return 6.425;
            }
        case SpanTypes.PL2065G:
            return 5.989;
        case SpanTypes.E2060:
            if (span.EndBoom) {
                switch (span.Length) {
                    case 1:
                    case 13:
                    case 23:
                        return 2.8;
                    case 32:
                    case 42:
                    case 51:
                    case 61:
                        return 5.8;
                    case 85:
                    case 105:
                        return 4.3;
                }
            } else {
                return 5.8;
            }
        case SpanTypes.E2660:
            if (span.EndBoom) {
                switch (span.Length) {
                    case 1:
                    case 13:
                    case 23:
                        return 2.79;
                    case 32:
                    case 42:
                    case 51:
                    case 61:
                        return 5.79;
                    case 85:
                    case 105:
                        return 4.29;
                }
            } else {
                return 5.79;
            }
        case SpanTypes.AlumIV:
            if (span.EndBoom) {
                switch (span.Length) {
                    case 1:
                    case 13:
                    case 23:
                        return 4.3;
                    case 32:
                    case 42:
                    case 51:
                    case 61:
                        return 5.8;
                    case 85:
                    case 105:
                        return 4.3;
                }
            } else {
                return 5.8;
            }
        case SpanTypes.E2045:
            if (span.EndBoom) {
                switch (span.Length) {
                    case 1:
                    case 13:
                    case 23:
                        return 2.8;
                    case 32:
                    case 42:
                    case 51:
                    case 61:
                        return 4.3;
                }
            } else {
                return 4.3;
            }
    }
}

export const CFactor = (sys: ISystemBase, side: Side, span: Span): number => {
    const spanType = SpanType(sys, side, span);

    switch (spanType) {
        case SpanTypes.E2100:
            return 135;
        case SpanTypes.E2085:
            return 135;
        case SpanTypes.A80G:
            return 150;
        case SpanTypes.A60G:
            return 150;
        case SpanTypes.S2085G:
            return 150;
        case SpanTypes.PL2085G:
            return 150;
        case SpanTypes.E2065:
            return 135;
        case SpanTypes.E2665:
            return 140;
        case SpanTypes.S2065G:
            return 150;
        case SpanTypes.PL2065G:
            return 150;
        case SpanTypes.E2060:
            return 135;
        case SpanTypes.E2660:
            return 140;
        case SpanTypes.AlumIV:
            return 150;
        case SpanTypes.E2045:
            return 135;
    }
}

export const PipeType = (sys: ISystemBase, side: Side, span: Span): SpanPipeTypes => {
    const spanType = SpanType(sys, side, span);

    switch (spanType) {
        case SpanTypes.E2100:
        case SpanTypes.E2085:
        case SpanTypes.E2065:
        case SpanTypes.E2060:
        case SpanTypes.E2045:
            return SpanPipeTypes.Galvanized;
        case SpanTypes.E2665:
        case SpanTypes.E2660:
            return SpanPipeTypes.Painted;
        case SpanTypes.A80G:
        case SpanTypes.A60G:
        case SpanTypes.AlumIV:
            return SpanPipeTypes.Aluminum;
        case SpanTypes.PL2065G:
        case SpanTypes.PL2085G:
            return SpanPipeTypes.Poly;
        default:
            return SpanPipeTypes.Stainless;
    }
};