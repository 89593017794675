import { booleanDisjoint, distance, intersect, lineString, nearestPointOnLine, Point, polygon, Polygon } from "@turf/turf";
import FeatureHelpers from "rdptypes/geometry/helpers/features";
import { PartialSpanLengthItem, partialToSpanLengthItem } from "../../components/OptimizeSystemDialog/RestrictSpanLengthsControl";
import { getAvailableSpanLengthsWithExtension } from "../../helpers/validation/spanExtensions";
import { fillSystemWithEndBoom, IAvoidWheelRegion, IFillSystemSpan } from "./optimizeSpansCommon";

interface IArgs {
    center: Point;
    boundary: Polygon;
    obstacles: Polygon[];
    allowableSpanLengths?: PartialSpanLengthItem[];
    wheelObstacles?: Polygon[];
    allowableEndBoomLengths?: number[];
    maxSystemRadiusFt?: number;
}

export const optimizeSpansForCenterPivot = (args: IArgs): {spans: IFillSystemSpan[], endBoom?: number} => {
    const { center, boundary, obstacles, allowableSpanLengths, wheelObstacles, allowableEndBoomLengths } = args;

    const sortedAllowableSpanLengths = (
        allowableSpanLengths 
            ? allowableSpanLengths.map(partialToSpanLengthItem)
            : [ ...getAvailableSpanLengthsWithExtension(false, false) ]
        );
    sortedAllowableSpanLengths.reverse();
    
    const sortedAllowableEndBoomLengths = (allowableEndBoomLengths ? [ ...allowableEndBoomLengths ] : [ ]);
    sortedAllowableEndBoomLengths.sort((a, b) => b - a);

    if (sortedAllowableSpanLengths.length === 0) {
        console.log("Cannot optimize a system with no allowable spans");
        return { spans: [] };
    }

    // find min distance to boundary:
    let systemTo = nearestPointOnLine(lineString(boundary.coordinates[0]), center, { units: 'feet' }).properties.dist!;
    if (args.maxSystemRadiusFt && systemTo > args.maxSystemRadiusFt) {
        systemTo = args.maxSystemRadiusFt;
    }
    if (!sortedAllowableEndBoomLengths.length) {
        systemTo -= 1; // if not an end boom, we need an extra 1ft (see span functions)
    }

    // consider obstacles:
    const obstaclesInBoundary = obstacles
        .map((o): (Polygon[] | null) => {
            const i = intersect(boundary, o);
            if (!i) return null;
            if (i.geometry.type === 'MultiPolygon') {
                return i.geometry.coordinates.map(p => polygon(p).geometry)
            }
            else {
                return [i.geometry]
            }
        })
        .filter((o): o is Polygon[] => o !== null)
        .flatMap(o => o);

    obstaclesInBoundary.forEach(o => {
        const outerRing = o.coordinates[0];
        systemTo = Math.min(
            systemTo,
            nearestPointOnLine(lineString(outerRing), center, { units: 'feet' }).properties.dist!
        );
    })
    // accumulate wheel obstacle regions:
    let avoidWheelRegions: IAvoidWheelRegion[] = [];
    if (wheelObstacles) {
        const wheelObstaclesInBoundary = wheelObstacles
            .filter(x => !booleanDisjoint(x, boundary));

        avoidWheelRegions = wheelObstaclesInBoundary.map(o => {
            let from = Number.MAX_VALUE;
            let to = Number.MIN_VALUE;
            const outerRing = o.coordinates[0];
            from = Math.min(
                from,
                nearestPointOnLine(lineString(outerRing), center, { units: 'feet' }).properties.dist!
            );
            for (const p of outerRing.slice(0,-1)) { // dont need to do the last point as it will equal the first point
                to = Math.max(to, distance(center, p, { units: 'feet'}))
            }
            return { from, to };
        })
    }

    // add 2 foot to the starting location for center pivots
    return fillSystemWithEndBoom(avoidWheelRegions, sortedAllowableSpanLengths, systemTo, sortedAllowableEndBoomLengths, false, 2, 0);
}

interface IArgs2 {
    center: Point;
    radiusFeet: number;
    minBearing: number;
    maxBearing: number;
    obstacles: Polygon[];
    allowableSpanLengths?: PartialSpanLengthItem[];
    wheelObstacles?: Polygon[];
    allowableEndBoomLengths?: number[];
}
export const optimizeSpansForPartialPivot = (args: IArgs2): {spans: IFillSystemSpan[], endBoom?: number} => {
    const { center, obstacles, allowableSpanLengths, wheelObstacles, allowableEndBoomLengths, radiusFeet, minBearing, maxBearing } = args;

    const sortedAllowableSpanLengths = (
        allowableSpanLengths 
            ? allowableSpanLengths.map(partialToSpanLengthItem)
            : [ ...getAvailableSpanLengthsWithExtension(false, false) ]
        );
    sortedAllowableSpanLengths.reverse();
    
    const sortedAllowableEndBoomLengths = (allowableEndBoomLengths ? [ ...allowableEndBoomLengths ] : [ ]);
    sortedAllowableEndBoomLengths.sort((a, b) => b - a);

    if (sortedAllowableSpanLengths.length === 0) {
        console.log("Cannot optimize a system with no allowable spans");
        return { spans: [] };
    }

    // find min distance to boundary:
    let systemTo = radiusFeet - (sortedAllowableEndBoomLengths.length ? 0 : 1); // if there is an end boom, the length must be -1ft (see span functions)

    const boundaryFull = FeatureHelpers.GetSectorDrawFeature(
        center,
        radiusFeet,
        minBearing - 1,
        maxBearing + 1,
        null,
        { units: 'feet' }
    );

    // consider obstacles:
    const obstaclesInBoundary = obstacles
        .map((o): (Polygon[] | null) => {
            const i = intersect(boundaryFull, o);
            if (!i) return null;
            if (i.geometry.type === 'MultiPolygon') {
                return i.geometry.coordinates.map(p => polygon(p).geometry)
            }
            else {
                return [i.geometry]
            }
        })
        .filter((o): o is Polygon[] => o !== null)
        .flatMap(o => o);

    obstaclesInBoundary.forEach(o => {
        const outerRing = o.coordinates[0];
        systemTo = Math.min(
            systemTo,
            nearestPointOnLine(lineString(outerRing), center, { units: 'feet' }).properties.dist!
        );
    })
    // accumulate wheel obstacle regions:
    let avoidWheelRegions: IAvoidWheelRegion[] = [];
    if (wheelObstacles) {
        const wheelObstaclesInBoundary = wheelObstacles
            .filter(x => !booleanDisjoint(x, boundaryFull));

        avoidWheelRegions = wheelObstaclesInBoundary.map(o => {
            let from = Number.MAX_VALUE;
            let to = Number.MIN_VALUE;
            const outerRing = o.coordinates[0];
            from = Math.min(
                from,
                nearestPointOnLine(lineString(outerRing), center, { units: 'feet' }).properties.dist!
            );
            for (const p of outerRing.slice(0,-1)) { // dont need to do the last point as it will equal the first point
                to = Math.max(to, distance(center, p, { units: 'feet'}))
            }
            return { from, to };
        })
    }

    // add 2 foot to the starting location for center pivots
    return fillSystemWithEndBoom(avoidWheelRegions, sortedAllowableSpanLengths, systemTo, sortedAllowableEndBoomLengths, false, 2, 0);
}