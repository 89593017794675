import ISystem from "rdptypes/project/ISystem";
import IComponent from "rdptypes/roe/IComponent";
import { SystemValidationResult } from "../../components/roe/validation/SystemValidator";

export enum ValidationWorkerStatus {
    Validating,
    Validated
}

export default interface IValidationWorkerState {
    status: ValidationWorkerStatus;
    validationResult?: SystemValidationResult;
    validate: (system: ISystem, changingFieldPaths: string[], mainPageId: string) => Promise<SystemValidationResult>;
    validateCmp: (system: ISystem, cmp: IComponent, fieldRoot: string, pageId: string) => SystemValidationResult;
}