import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import i18next, { t } from "i18next";
import { IOutlet } from "rdptypes/project/ISprinklerChart";
import { DeviceTypes } from "rdptypes/project/ISprinklers";
import { IFlangedSide, IFlexSide, ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import { StartingLocation } from "roedata/roe_migration/SpanFunctions";
import DevSettingsCtx from "../../../../../db/DevSettingsCtx";
import { gpmToM3ph, psiToBar } from "../../../../../docGeneration/DocumentGenerationHelpers";
import { pageStyles, secondaryColor } from "../../../styles";
import { getDeviceTypeLabel } from "../helpers/SprinklerHelper";


interface Props {
    outlets: IOutlet[];
    sys: ISystemBase;
    side: IFlexSide | IFlangedSide;
};

const OutletsTable: FC<Props> = (props) => {
    
    useEffect(() => {
       
    }, []);

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    formInputLabelStyle["color"] = secondaryColor;

    const PrevLocationa = ( Outlet: number): number => {
    var start: number = Outlet - 1;
    var i: number;

        for(i = start; i >=1; i--){
            if(props.outlets[i].Device !== DeviceTypes.None){
                return props.outlets[i].Location;
            }
        }

       return StartingLocation(props.sys, props.side, props.side.Span[0]);
    
    }

    const getSpace = (i: number): string => {
        if(!props.outlets[i].Device){
            return "-";
        }
        const dPrevLocation = PrevLocationa(i);
        return (props.outlets[i].Location - dPrevLocation).toFixed(1);
    }

    const getPackageLabel = (outlet: IOutlet) => {
        if(outlet.Device){
            return `${getDeviceTypeLabel(outlet.Device)}`
        }else{
            return "Plug";
        }        
    }

    let dealtWithHookLocations: number[] = [];

    const settings = useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    return (
        <Stack direction="column">
             <TableContainer component={Paper} className="sprinkler-results-table">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: 'center'}}><b>Outlet</b></TableCell>
                            <TableCell style={{textAlign: 'center'}}><b>Loc.</b></TableCell>
                            <TableCell style={{textAlign: 'center'}}><b>Package</b></TableCell>
                            <TableCell style={{textAlign: 'center'}}><b>Nozz</b></TableCell>
                            <TableCell style={{textAlign: 'center'}}><b>Space</b></TableCell>
                            <TableCell style={{textAlign: 'center'}}><b>{displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : i18next.format(t("common-phrases.sltBar"), "capitalize")}</b></TableCell>
                            <TableCell style={{textAlign: 'center'}}><b>{displaySettings.irrigationRates === "gpm" ? "GPMR" : "m3/hR"}</b></TableCell>
                            <TableCell style={{textAlign: 'center'}}><b>{displaySettings.irrigationRates === "gpm" ? "GPMD": "m3/hD"}</b></TableCell>
                            <TableCell style={{textAlign: 'center'}}><b>{displaySettings.irrigationRates === "gpm" ? "GPML": "m3/hL"}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.outlets.map((outlet, i) => {
                        let elems: JSX.Element[] = [];
                        elems.push(
                        <TableRow  key={i}>
                            <TableCell  style={{textAlign: 'center'}}><b>{i + 1}</b></TableCell>
                            <TableCell style={{textAlign: 'center'}}>{outlet.Location.toFixed(1)}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{getPackageLabel(outlet)}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{outlet.NozzleIndex}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{getSpace(i)}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{outlet.Pressure ? displaySettings.pressures === "psi" ? outlet.Pressure.toFixed(1) : psiToBar(outlet.Pressure).toFixed(2) : "-"}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{outlet.GPMRequired ? displaySettings.irrigationRates === "gpm" ? outlet.GPMRequired.toFixed(1) : gpmToM3ph(outlet.GPMRequired).toFixed(1) : "-"}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{outlet.GPMDelivered ? displaySettings.irrigationRates === "gpm" ? outlet.GPMDelivered.toFixed(1) : gpmToM3ph(outlet.GPMDelivered).toFixed(1) : "-"}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{outlet.DownstreamGPM ? displaySettings.irrigationRates === "gpm" ? outlet.DownstreamGPM.toFixed(1) : gpmToM3ph(outlet.DownstreamGPM).toFixed(1): "-"}</TableCell>
                        </TableRow>);
                        if (outlet.HookLocation && dealtWithHookLocations.indexOf(outlet.HookLocation) === -1){
                            elems.push( <TableRow  key={i}>
                                <TableCell  style={{textAlign: 'center'}}><b>{(i === props.outlets.length - 1 ? "End" : "Hook")}</b></TableCell>
                                <TableCell style={{textAlign: 'center'}}>{outlet.HookLocation.toFixed(1)}</TableCell>
                                <TableCell style={{textAlign: 'center'}}></TableCell>
                                <TableCell style={{textAlign: 'center'}}></TableCell>
                                <TableCell style={{textAlign: 'center'}}></TableCell>
                                <TableCell style={{textAlign: 'center'}}></TableCell>
                                <TableCell style={{textAlign: 'center'}}></TableCell>
                                <TableCell style={{textAlign: 'center'}}></TableCell>
                                <TableCell style={{textAlign: 'center'}}></TableCell>
                            </TableRow>)
                            dealtWithHookLocations.push(outlet.HookLocation);
                        }
                        return elems;
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};

export default OutletsTable;