import { booleanEqual } from "@turf/turf";
import IAction from "rdptypes/IAction";
import { createNewMultiAction } from "../../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import IAuthState from "../../../../auth/IAuthState";
import { IDrawUpdateExtEvent_LateralUpdated } from "../../../../components/Map";
import { generateChart } from "../../../../components/roe/componentRenderers/Sprinklers/SprinklerChart/SprinklerChart";
import IDbProject from "../../../../db/IDbProject";

export const handleLateralUpdated = (
    event: IDrawUpdateExtEvent_LateralUpdated,
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState
) => {
    const actions: IAction[] = [];
    const { systemId } = event.definition;
    const updatedSystem = event.updatedSystem;
    const project = dbPrj.state;
    const layout = project.layouts[layoutId!];
    const system = layout.systems[systemId!];
    if (!booleanEqual(updatedSystem.lateral!.line, system.lateral!.line)) {
        console.log("Updating system feed line");
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "lateral.line",
            updatedSystem.lateral!.line,
            authState
        ))
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "lateral.anticlockwisePivotLineIndicies",
            updatedSystem.lateral!.anticlockwisePivotLineIndicies,
            authState
        ))
    }
    if (JSON.stringify(updatedSystem.lateral.startPivot) !== JSON.stringify(system.lateral.startPivot)) {
        console.log("Updating system start pivot");
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "lateral.startPivot",
            updatedSystem.lateral.startPivot,
            authState
        ))
    }
    if (JSON.stringify(updatedSystem.lateral.endPivot) !== JSON.stringify(system.lateral.endPivot)) {
        console.log("Updating system end pivot");
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "lateral.endPivot",
            updatedSystem.lateral.endPivot,
            authState
        ))
    }
    if (system.Lateral.dropSpanStartRelativeToPreviousSpanStart !== updatedSystem.Lateral.dropSpanStartRelativeToPreviousSpanStart) {
        console.log("Updating system flex drop start");
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "Lateral.dropSpanStartRelativeToPreviousSpanStart",
            updatedSystem.Lateral.dropSpanStartRelativeToPreviousSpanStart,
            authState
        ))
    }
    if (system.Lateral.dropSpanEndRelativeToPreviousSpanEnd !== updatedSystem.Lateral.dropSpanEndRelativeToPreviousSpanEnd) {
        console.log("Updating system flex drop end");
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "Lateral.dropSpanEndRelativeToPreviousSpanEnd",
            updatedSystem.Lateral.dropSpanEndRelativeToPreviousSpanEnd,
            authState
        ))
    }
    const sides = [
        { sideString: "FlangedSide", currnetSpans: system.FlangedSide, updatedSpans: updatedSystem.FlangedSide },
        { sideString: "FlexSide", currnetSpans: system.FlexSide, updatedSpans: updatedSystem.FlexSide },
    ]
    for (const side of sides) {
        for (let i = 0; i < side.currnetSpans.Span.length; i++) {
            const span = side.currnetSpans.Span[i];
            const updatedSpan = side.updatedSpans.Span[i];
            if (
                span.dropSpanStartRelativeToPreviousSpanStart !== updatedSpan.dropSpanStartRelativeToPreviousSpanStart
            ) {
                console.log("Updating system span towers drop start",i);
                actions.push(createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    `${side.sideString}.Span[${i}].dropSpanStartRelativeToPreviousSpanStart`,
                    updatedSpan.dropSpanStartRelativeToPreviousSpanStart,
                    authState
                ))
            }
            if (
                span.dropSpanEndRelativeToPreviousSpanEnd !== updatedSpan.dropSpanEndRelativeToPreviousSpanEnd
            ) {
                console.log("Updating system span towers drop end",i);
                actions.push(createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    `${side.sideString}.Span[${i}].dropSpanEndRelativeToPreviousSpanEnd`,
                    updatedSpan.dropSpanEndRelativeToPreviousSpanEnd,
                    authState
                ))
            }
        }

    }
    if (actions.length) {
        // Note: these updated spans do not change length, so no switching of flanged/flex should need to occur
        actions.push(...generateChart(authState, dbPrj, layoutId, systemId, updatedSystem));
        dbPrj.pushAction(
            createNewMultiAction(actions, authState)
        )
    }
}