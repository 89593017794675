import { Feature, MultiPolygon, Polygon, bearing, difference, feature, lineString, multiPolygon, point, polygon } from "@turf/turf";
import FeatureHelpers from "rdptypes/geometry/helpers/features";
import { loCustom } from "rdptypes/geometry/helpers/turf";
import IFieldBoundary from "../model/project/IFieldBoundary";
import IPivotCenterBoundary from "../model/project/IPivotCenterBoundary";
import IWetAreaBoundary from "../model/project/IWetAreaBoundary";
import SegmentHelper, { ESegmentClearanceType } from "./SegmentHelper";
import SegmentPolygonHelper from "./SegmentPolygonHelper";

type IBoundary = IFieldBoundary | IPivotCenterBoundary | IWetAreaBoundary;
export class BoundaryHelper extends SegmentPolygonHelper {

    static getClearancePolygons = (boundary: IBoundary, clearanceType: ESegmentClearanceType = ESegmentClearanceType.EquipmentClearance): Polygon[] => {
        if (!BoundaryHelper.booleanValidPolygon(boundary)) return [];

        if (clearanceType === ESegmentClearanceType.EquipmentClearance && boundary.segments.every(s => SegmentHelper.getClearance(s, clearanceType) === 0)) {
            return [ BoundaryHelper.getPolygon(boundary) ];
        }

        const polys: Feature<Polygon>[] = [];
        for (let i = 0; i < boundary.segments.length; i++) {
            const leftSegment = boundary.segments[i];
            const rightSegment = boundary.segments[i === boundary.segments.length - 1 ? 0 : i + 1];

            const leftSegmentLine = lineString([ leftSegment.start, leftSegment.end ]);

            const centerPoint = point(leftSegment.end);
            
            const lb = bearing(centerPoint, leftSegment.start, { final: true });
            const rb = bearing(centerPoint, rightSegment.end, { final: true });

            const leftClearance = SegmentHelper.getClearance(leftSegment, clearanceType);
            if (leftClearance) {
                const leftOffsetSegmentLine = loCustom(leftSegmentLine, leftClearance, { units: 'feet' });
                const offsetPolygon = polygon([
                    [
                        ...leftSegmentLine.geometry.coordinates,
                        ...leftOffsetSegmentLine.geometry.coordinates.slice().reverse(),
                        leftSegmentLine.geometry.coordinates[0]
                    ]
                ])
                polys.push(offsetPolygon);
                
                const leftSector = FeatureHelpers.GetSectorDrawFeature(
                    centerPoint.geometry,
                    leftClearance,
                    rb, lb,
                    null,
                    { units: 'feet', degreeIncrement: 10 }
                )
                polys.push(leftSector);
            }

            const rightClearance = SegmentHelper.getClearance(rightSegment, clearanceType);
            if (rightClearance) {                
                const rightSector = FeatureHelpers.GetSectorDrawFeature(
                    centerPoint.geometry,
                    rightClearance,
                    rb, lb,
                    null,
                    { units: 'feet', degreeIncrement: 10 }
                )
                polys.push(rightSector);
            }
        }
        let bufferResult: Feature<Polygon | MultiPolygon> | null = feature(BoundaryHelper.getPolygon(boundary));
        for (const p of polys) {
            bufferResult = difference(bufferResult, p);
            if (!bufferResult) return [];
        }
        if (!bufferResult) return [];
        if (bufferResult.geometry.type === 'Polygon') {
            return [ bufferResult.geometry ];
        }
        return bufferResult.geometry.coordinates.map(ring => polygon(ring).geometry);
    }

    static getPolygonMinusClearancePolygon = (boundary: IBoundary): Polygon | MultiPolygon | null => {
        const clearancePolygons = BoundaryHelper.getClearancePolygons(boundary);
        if (clearancePolygons.length === 0) {
            // if no clearance polygon was returned, then there is no space left:
            return BoundaryHelper.getPolygon(boundary);
        }
        const clearanceMultiPolygon: Feature<Polygon | MultiPolygon> | null = clearancePolygons.length === 1
            ? feature(clearancePolygons[0])
            : multiPolygon(clearancePolygons.map(x => x.coordinates));
        const polygonMinusClearance = difference(BoundaryHelper.getPolygon(boundary), clearanceMultiPolygon);
        if (polygonMinusClearance === null) {
            // if this diff resulted in null, then the full field is available, and so there is no clearance
            return null;
        }
        return polygonMinusClearance.geometry;
    }
    static getClearancePolygonMinusSTowerClearance = (boundary: IBoundary): Polygon | MultiPolygon | null => {
        const clearancePolygons = BoundaryHelper.getClearancePolygons(boundary);
        if (clearancePolygons.length === 0) {
            // if no clearance polygon was returned, then there is no space left,
            // as such there is no swing arm clearance polygon
            return null;
        }

        const availableAfterClearance = BoundaryHelper.getPolygonMinusClearancePolygon(boundary);
        const available = availableAfterClearance
            ? difference(BoundaryHelper.getPolygon(boundary), availableAfterClearance)
            : feature(BoundaryHelper.getPolygon(boundary));
        if (!available) {
            // then the clearance polygon must have swallowed up the space:
            // as such there is no swing arm clearance polygon
            return null;
        }

        const swingArmClearancePolygons = BoundaryHelper.getClearancePolygons(boundary, ESegmentClearanceType.STowerClearance);
        if (swingArmClearancePolygons.length === 0) {
            // if no clearance polygon was returned, then there is no space left:
            return available.geometry;
        }

        let swingArmClearance = multiPolygon(swingArmClearancePolygons.map(p => p.coordinates));
        const diff = difference(available, swingArmClearance);
        if (!diff) {
            // if this diff resulted in null, then the full field is available, and so there is no swing arm clearance
            return null;
        }
        return diff.geometry;
    }
    static getClearancePolygonMinusHTowerClearance = (boundary: IBoundary): Polygon | MultiPolygon | null => {
        const clearancePolygons = BoundaryHelper.getClearancePolygons(boundary);
        if (clearancePolygons.length === 0) {
            // if no clearance polygon was returned, then there is no space left,
            // as such there is no swing arm clearance polygon
            return null;
        }

        const availableAfterClearance = BoundaryHelper.getPolygonMinusClearancePolygon(boundary);
        const available = availableAfterClearance
            ? difference(BoundaryHelper.getPolygon(boundary), availableAfterClearance)
            : feature(BoundaryHelper.getPolygon(boundary));
        if (!available) {
            // then the clearance polygon must have swallowed up the space:
            // as such there is no swing arm clearance polygon
            return null;
        }

        const swingArmClearancePolygons = BoundaryHelper.getClearancePolygons(boundary, ESegmentClearanceType.HTowerClearance);
        if (swingArmClearancePolygons.length === 0) {
            // if no clearance polygon was returned, then there is no space left:
            return available.geometry;
        }

        let swingArmClearance = multiPolygon(swingArmClearancePolygons.map(p => p.coordinates));
        const diff = difference(available, swingArmClearance);
        if (!diff) {
            // if this diff resulted in null, then the full field is available, and so there is no swing arm clearance
            return null;
        }
        return diff.geometry;
    }
}