import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Collapse, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import i18next, { t } from "i18next";
import { isCenterPivot } from "rdptypes/helpers/system";
import * as React from "react";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import { GeometryCtx } from "../../../../GeometryHelpers/GeometryProvider";
import { SystemIrrigatedInfo } from "../../../../GeometryHelpers/IrrigatedAreaHelper";
import { DisplayAreaUnitBuilder, DisplayAreaUnits } from "../../../../helpers/areas";
import { DisplayLengthUnitBuilder, DisplayLengthUnits } from "../../../../helpers/lengths";
import IProject from "../../../../model/project/IProject";

interface Props {
    project: IProject;
    layoutId: string;
    systemId: string;
}


const createAreaRows = (systemIrrigatedInfo: SystemIrrigatedInfo, project: IProject, layoutId: string, systemId: string, displayUnits: DisplayAreaUnits): { 
    name: string; 
    value: string; 
    isTotal?: boolean;
}[] => {
    const system = project.layouts[layoutId]?.systems[systemId];
    if (!system) return [];

    const rows: { name: string, value: number | undefined, isTotal?: boolean }[] = [];
    if (isCenterPivot(system)) {
        let endGunAcres = 0;
        systemIrrigatedInfo.centerPivotInfo.endGunInfo.forEach((eg) => {
            let area = 0;
            eg.areas.forEach((a) => {
                area += a;
            });

            endGunAcres += area;
        });
        rows.push({ name: "Pivot", value: systemIrrigatedInfo.centerPivotInfo.pivotAreaAcres });
        rows.push({ name: "End Guns", value: endGunAcres });
        rows.push({ name: "Swing Arm", value: systemIrrigatedInfo.centerPivotInfo.swingArmArea });
        rows.push({ name: "Wrap Spans", value: systemIrrigatedInfo.centerPivotInfo.wrapSpanAcres });
        rows.push({ name: "Drop Spans", value: systemIrrigatedInfo.centerPivotInfo.dropSpanArea });
    }
    else {
        rows.push({ name: "Flanged Side", value: systemIrrigatedInfo.lateralInfo.flangedArea });
        rows.push({ name: "Flex Side", value: systemIrrigatedInfo.lateralInfo.flexArea });
        let totalEndgunAcres = 0;
        systemIrrigatedInfo.lateralInfo.flangedEndGunInfo.forEach(egi => {
            egi.areas.forEach(a => totalEndgunAcres += a);
        })
        systemIrrigatedInfo.lateralInfo.flexEndGunInfo.forEach(egi => {
            egi.areas.forEach(a => totalEndgunAcres += a);
        })
        rows.push({ name: "End Guns", value: totalEndgunAcres });
        rows.push({ 
            name: "Drop Spans", 
            value: systemIrrigatedInfo.lateralInfo.flangedDropSpanArea + systemIrrigatedInfo.lateralInfo.flexDropSpanArea 
        });
    }
    if (systemIrrigatedInfo.overlappingArea) {
        rows.push({ name: "Overlapping Area", value: -systemIrrigatedInfo.overlappingArea });
    }
    rows.push({ name: "Total", value: systemIrrigatedInfo.irrigatedArea - systemIrrigatedInfo.overlappingArea, isTotal: true });

    return rows.map(x => {
        return {
            ...x,
            value: x.value 
                ? new DisplayAreaUnitBuilder(x.value, 'acres')
                    .convert(displayUnits)
                    .appendValue(2)
                    .toString()
                : "-"
        }
    });
}

const createLengthRows = (project: IProject, layoutId: string, systemId: string, displayLengthUnits: DisplayLengthUnits): { 
    name: string; 
    value: string; 
    value2: string;
    isTotal?: boolean;
}[] => {
    const system = project.layouts[layoutId]?.systems[systemId];
    if (!system) return [];

    // modify the rows for the correct units:
    let flangedRow: { name: string, value: string, value2: string, isTotal?: boolean } | undefined = undefined;
    let flexRow: { name: string, value: string, value2: string, isTotal?: boolean } | undefined = undefined;

    // add system lengths:
    // Flanged Side: Always show
    {
        const lastSpan = system.FlangedSide.Span.filter(x => !x.EndBoom && !x.SwingArm).slice(-1)[0];
        let lastSpanValue = "-";
        if (lastSpan) {
            const v = spanf.EndingRadius(system, system.FlangedSide, lastSpan);
            if (isFinite(v)) {
                lastSpanValue = new DisplayLengthUnitBuilder(v, 'feet')
                    .convert(displayLengthUnits)
                    .appendValue(1)
                    .toString();
            }
        }
        flangedRow = {
            name: "Flanged Side",
            value: lastSpanValue,
            value2: "-"
        };
        
        const lastSpanWithEos = system.FlangedSide.Span.filter(x => x.EndBoom || x.SwingArm).slice(-1)[0];
        if (lastSpanWithEos) {
            const v = spanf.EndingRadius(system, system.FlangedSide, lastSpanWithEos);
            if (isFinite(v)) {
                flangedRow.value2 = new DisplayLengthUnitBuilder(v, 'feet')
                    .convert(displayLengthUnits)
                    .appendValue(1)
                    .toString();
            }
        }
    }
    
    if (system.FlexSide.Span.length) {
        const lastSpan = system.FlexSide.Span.filter(x => !x.EndBoom && !x.SwingArm).slice(-1)[0];
        let lastSpanValue = "-";
        if (lastSpan) {
            const v = spanf.EndingRadius(system, system.FlexSide, lastSpan);
            if (isFinite(v)) {
                lastSpanValue = new DisplayLengthUnitBuilder(v, 'feet')
                    .convert(displayLengthUnits)
                    .appendValue(1)
                    .toString();
            }
        }
        flexRow = {
            name: "Flex Side",
            value: lastSpanValue,
            value2: "-"
        };
        
        const lastSpanWithEos = system.FlexSide.Span.filter(x => x.EndBoom || x.SwingArm).slice(-1)[0];
        if (lastSpanWithEos) {
            const v = spanf.EndingRadius(system, system.FlexSide, lastSpanWithEos);
            if (isFinite(v)) {
                flexRow.value2 = new DisplayLengthUnitBuilder(v, 'feet')
                    .convert(displayLengthUnits)
                    .appendValue(1)
                    .toString();
            }
        }
    }

    const returnRows = [flangedRow];
    if (flexRow) {
        returnRows.push(flexRow);
    }
    return returnRows;
}

const SystemInformationCard: React.FC<Props> = ({
    project, layoutId, systemId
}) => {
    const [expand, setExpand] = React.useState(false);

    const geometryCtx = React.useContext(GeometryCtx);
    const systemIrrigatedInfo = geometryCtx.system.geometryHelpers[systemId]?.irrigatedInfo;
    if (!systemIrrigatedInfo) return null;

    const displaySettings = React.useContext(DevSettingsCtx).dealerSettings.display.current;
    const areaRows = createAreaRows(systemIrrigatedInfo, project, layoutId, systemId, displaySettings.areas);
    const lengthRows = createLengthRows(project, layoutId, systemId, displaySettings.lengths);
    if (!areaRows.length && !lengthRows.length) return null;

    const ns = "map-display.left-drawer."

    return (
        <Paper variant="outlined" sx={{ padding: expand ? 2 : 0 }}>
            {
                !expand && (
                    <Stack direction={"row"}>
                        <Typography gutterBottom variant="subtitle2" component="div" sx={{ textAlign: 'center', alignSelf: "center", flex: 1, opacity: 0.5}}>
                            <b>{t(ns + "irrigated-areas")} / {t(ns + "system-lengths")}</b>
                        </Typography>
                        <Tooltip title={t("expand")}>
                            <IconButton onClick={() => setExpand(!expand)}>
                                <ArrowDropUpIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            }

            <Collapse in={expand}>
                <Stack direction={"row"}>
                    <Typography gutterBottom variant="subtitle2" component="div" sx={{ textAlign: 'center', alignSelf: "center", flex: 1 }}>
                        <b>{t(ns + "irrigated-areas")}</b>
                    </Typography>
                    <Tooltip title={t("collapse")}>
                        <IconButton onClick={() => setExpand(!expand)}>
                            <ArrowDropDownIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
                
                <TableContainer component={Paper}>
                    <Table aria-label="irrigated-areas-table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>{i18next.format(t('component'), 'capitalize')}</b></TableCell>
                                <TableCell align="right"><b>{i18next.format(t('area'), 'capitalize')} ({t(DisplayAreaUnitBuilder.longName(displaySettings.areas))})</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {areaRows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0, backgroundColor: 'aliceblue' } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {
                                            row.isTotal ? <b>{row.name}</b> : row.name
                                        }
                                    </TableCell>
                                    <TableCell align="right">
                                        {
                                            row.isTotal ? <b>{row.value}</b> : row.value
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                

                <Typography gutterBottom variant="subtitle2" component="div" sx={{ textAlign: 'center', paddingTop: 2}}>
                    <b>{t(ns + "system-lengths")}</b>
                </Typography>
                
                <TableContainer component={Paper}>
                    <Table aria-label="system-lengths-table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>{i18next.format(t('component'), 'capitalize')}</b></TableCell>
                                <TableCell align="right"><b>{i18next.format(t('length'), 'capitalize')} ({t(DisplayLengthUnitBuilder.shortName(displaySettings.lengths))})</b></TableCell>
                                <TableCell align="right"><b>{i18next.format(t(ns + "including-eos"), 'capitalize')} ({t(DisplayLengthUnitBuilder.shortName(displaySettings.lengths))})</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lengthRows.map((row) => (
                                <TableRow
                                    key={row.name}
                                >
                                    <TableCell component="th" scope="row" width="40%">
                                        {
                                            row.isTotal ? <b>{row.name}</b> : row.name
                                        }
                                    </TableCell>
                                    <TableCell align="right">
                                        {
                                            row.isTotal ? <b>{row.value}</b> : row.value
                                        }
                                    </TableCell>
                                    <TableCell align="right">
                                        {
                                            row.isTotal ? <b>{row.value2}</b> : row.value2
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
        </Paper>
    )
}

export default SystemInformationCard;