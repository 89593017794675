import { Document, Page, Text, View } from '@react-pdf/renderer';
import { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import ISystem from "rdptypes/project/ISystem";
import * as React from "react";
import { FC } from 'react';
import QuoteClass from "roedata/roe_migration/QuoteClass";
import VRI_Zones from "roedata/roe_migration/VRIClass.VRI_Zones";
import { configurationValid, customPricingApplied } from '../../components/roe/componentRenderers/Proposal/ProposalHelpers';
import { IDocumentType } from '../DocumentGenerationHelpers';
import DocumentFirstPageHeader from '../components/DocumentFirstPageHeader';
import VRIInformationSection from '../components/VRIInformationSection';
import VRIValveBoxesSection from '../components/VRIValveBoxesSection';
import VRIZonesSection from '../components/VRIZonesSection';
import { documentStyles } from '../styles';

interface Props {
    system: ISystem;
    grower: IDbGrower;
}

const PAGE_SIZE = "LETTER";

const VRIReport: FC<Props> = (props) => {
    const {system, grower} = props;
    const vriZones = new VRI_Zones(system);

    const quote = new QuoteClass(system);
    
    return (
        <>
            <Document>
                <Page size={PAGE_SIZE} style={documentStyles.page}>
                    <DocumentFirstPageHeader documentType={IDocumentType.vriReport}/>
                    {
                        customPricingApplied(system) && <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>CUSTOM PRICING APPLIED</Text>
                        </View>
                    }
                    {                    
                        !configurationValid(system) && <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>INCOMPLETE PROPOSAL</Text>
                        </View>
                    }
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <VRIInformationSection quote={quote} system={system} vriZones={vriZones}/>
                        <VRIZonesSection vriZones={vriZones}/>
                        <VRIValveBoxesSection vriZones={vriZones}/>
                        <View style={{paddingLeft: 30, paddingRight: 30}}>
                            <Text>{t("common-phrases.lsltDesignDisclaimer")}</Text>                    
                        </View>
                    </View>
                    <View fixed style={documentStyles.footer}>             
                        <Text style={{textAlign: "center"}} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>   
                </Page>
            </Document>
        </>
    );
};

export default VRIReport;