import { Fab, Stack, Tooltip } from "@mui/material";
import { Feature } from "@turf/turf";
import i18next, { t } from "i18next";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { useContext, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import AuthCtx from "../../../auth/AuthCtx";
import IAuthState from "../../../auth/IAuthState";
import LayoutMap from "../../../components/LayoutMap";
import { IEditMode, SEGMENT_SELECT } from "../../../components/Map/SegmentSelectMode";
import DbCtx from "../../../db/DbCtx";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { FEXContext } from "../../../foreignExchange";
import BottomDrawer from "./BottomDrawer";
import LayoutMapAppBar from './LayoutMapAppBar';
import LeftDrawer from "./LeftDrawer";
import MapSpeedDial from "./MapSpeedDial";
import { MapViewContainer } from "./MapViewContainer";
import { handleDrawCreate } from "./handleDrawCreate";
import { handleDrawUpdateExt } from "./handleDrawUpdateExt";
import { IInteractionState, Interaction } from "./interactionState";
import { ISelectedItem } from "./selectedItem";

const getDrawModeFromInteraction = (ia: Interaction) => {
    switch (ia) {
        case Interaction.DrawPivotSystem:
        case Interaction.DrawPointAnnotation:
        case Interaction.DrawPumpAnnotation:
        case Interaction.DrawLabelAnnotation:
        case Interaction.DrawPivotCenter_ForOptimizedFullPivot:
        case Interaction.DrawPivotCenter_ForOptimizedPartialPivot:
        case Interaction.DrawPivotCenter_ForOptimizedFullOrPartialPivot:
            return "draw_point";
        case Interaction.DrawLineAnnotation:
        case Interaction.DrawElectricLineAnnotation:
        case Interaction.DrawCanalAnnotation:
        case Interaction.DrawWaterLineAnnotation:
        case Interaction.DrawLateralSystem:
        case Interaction.Measure:
            return "draw_line_string";
        case Interaction.DrawObstacle:
        case Interaction.DrawWheelObstacle:
        case Interaction.DrawBoundary:
        case Interaction.DrawPivotCenterBoundary:
        case Interaction.DrawWetAreaBoundary:
            return "draw_polygon";
        case Interaction.DirectSelect:
            return "direct_select";
        case Interaction.CenterPivotSelect:
            return "center_pivot_select";
        case Interaction.LateralSelect:
            return "lateral_select";
        case Interaction.SimpleSelect:
            return "simple_select";
        case Interaction.DynamicCenterPivotOptimization:
            return "dynamic_center_pivot_optimization";
        case Interaction.DynamicLateralOptimization:
            return "dynamic_lateral_optimization";
        default:
            return "simple_select";
    }
}

const BOTTOM_DRAWER_HEIGHT = 300;

const LayoutMapPage = () => {

    const authState = useContext(AuthCtx);
    const dbState = useContext(DbCtx);
    const settingsState = useContext(DevSettingsCtx);
    const fex = useContext(FEXContext);
    const navigate = useNavigate();

    const { projectId, layoutId } = useParams();
    const dbProject = dbState.projects[projectId!];
    const project = dbProject.state;

    const [additionalFeatures, setAdditionalFeature] = useState<Feature[]>([]);
    
    const [ currentSegmentSelectEditMode, setCurrentSegmentSelectEditMode ] = useState<IEditMode | undefined>(undefined);
    const [interactionState, setInteractionState] = useState<IInteractionState>({
        interactionType: Interaction.SimpleSelect
    });
    

    const [ selectedItem, setSelectedItem ] = useState<ISelectedItem>({ item: 'none' });
    

    // START: left drawer logic (system options view):
    // state:
    const [leftDrawerWidth, setLeftDrawerWidth] = useState(0);
    // logic:
    const isLeftDrawerOpen = selectedItem.item === 'system';
    // END: left drawer logic (system options view):

    // START: bottom drawer logic (system side view):
    // state:
    const [showBottomDrawer, setShowBottomDrawer] = useState(false);
    // logic:
    const isBottomDrawerPossible = selectedItem.item === 'system';
    const isBottomDrawerOpen = isBottomDrawerPossible && showBottomDrawer;
    const toggleBottomDrawer = () => {
        setShowBottomDrawer(!isBottomDrawerOpen)
    }
    // END: bottom drawer logic (system side view):

    const autoNavigateToLayoutId = useRef(undefined as string | undefined);

    // START: Systems logic:
    const navigateFromSystem = () => {
        setInteractionState({ interactionType: Interaction.SimpleSelect });
        setSelectedItem({ item: 'none' });
    }
    const navigateToSystemDesign = () => {
        if (selectedItem.item === 'system') {
            navigate(`../systems/${selectedItem.id}/design`)
        }
    }

    // END: Systems logic:

    // START: Layouts logic:
    const autoNavigateToAnotherLayout = (nextLayoutId?: string) => {
        setSelectedItem({ item: 'none' });
        setInteractionState({ interactionType: Interaction.SimpleSelect });
        autoNavigateToLayoutId.current = nextLayoutId;
    }
    // END: Layouts logic:


    // START: Auto navigate logic:
    if (autoNavigateToLayoutId.current === layoutId) {
        autoNavigateToLayoutId.current = undefined;
    } else if (autoNavigateToLayoutId.current && autoNavigateToLayoutId.current in project.layouts) {
        return <Navigate to={`/projects/${projectId}/layouts/${autoNavigateToLayoutId.current}/map`} />;
    }
    // END: Auto navigate logic

    const fieldLayoutSystemDefaults = settingsState.dealerSettings.system.useCustom 
        ? (layoutId: string, systemId: string, authState: IAuthState, systemType: SystemTypes) => settingsState.dealerSettings.system.custom
            .getSystemProperties(layoutId, systemId, authState, systemType, { isTowable: true, isEDMP: false })
            // When applying system defaults on field design, ignore end of system actions
            .filter(action => action.actionTypeId !== "SetEndOfSystem")
        : undefined;

    let draw_system_mode: string | undefined = undefined;
    if (interactionState.interactionType === Interaction.CenterPivotSelect) {
        draw_system_mode = 'center_pivot_select'
    }
    else if (interactionState.interactionType === Interaction.LateralSelect) {
        draw_system_mode = 'lateral_select'
    }

    return (
        <>
            <MapViewContainer 
                leftDrawerOpen={isLeftDrawerOpen}
                bottomDrawerOpen={isBottomDrawerOpen}
                leftDrawerWidth={leftDrawerWidth}
                bottomDrawerHeight={BOTTOM_DRAWER_HEIGHT}
            >
                <LayoutMap
                    showProposalGeneratedWarning
                    projectId={projectId!}
                    layoutId={layoutId!}
                    drawMode={(() => {
                        const drawMode = getDrawModeFromInteraction(interactionState.interactionType);
                        if (
                            interactionState.interactionType === Interaction.DynamicCenterPivotOptimization ||
                            interactionState.interactionType === Interaction.DynamicLateralOptimization
                        ) {
                            return {
                                mode: drawMode,
                                options: interactionState
                            }
                        }
                        else {
                            return drawMode
                        }
                    })()}
                    draw_system_mode={draw_system_mode}
                    onDrawModeChanged={(drawMode) => {
                        // This event is not triggered in response to programmatic changes of draw mode,
                        // so we expect that it will only ever be changed to simple_select or
                        // direct_select.
                        if (drawMode === "simple_select") {
                            setSelectedItem({ item: 'none' });
                            setInteractionState({ interactionType: Interaction.SimpleSelect });
                        } else if (drawMode === "direct_select") {
                            setSelectedItem({ item: 'none' });
                            setInteractionState({ interactionType: Interaction.DirectSelect });
                        } else if (drawMode === "center_pivot_select") {
                            setInteractionState({ interactionType: Interaction.CenterPivotSelect });
                        } else if (drawMode === "lateral_select") {
                            setInteractionState({ interactionType: Interaction.LateralSelect });
                        } else if (drawMode === SEGMENT_SELECT) {
                            setInteractionState({ interactionType: Interaction.SegmentSelect });
                        } else {
                            throw new Error(`Unexpected change of draw mode to ${drawMode}`);
                        }
                    }}
                    onDrawCreate={(features, mapContext) => handleDrawCreate(
                        interactionState,
                        features,
                        dbProject,
                        layoutId!,
                        authState,
                        mapContext,
                        settingsState.fieldSettings.current,
                        fex,
                        fieldLayoutSystemDefaults
                    )}
                    onDrawUpdateExt={(event) => {
                        if (event.action === 'segment_select_enter') {
                            setCurrentSegmentSelectEditMode(event.definition.editMode);
                        }
                        else {
                            handleDrawUpdateExt(
                                interactionState.interactionType,
                                event,
                                dbProject,
                                layoutId!,
                                authState,
                                setSelectedItem,
                                fex,
                                fieldLayoutSystemDefaults
                            )
                        }
                    }}
                    onDrawSelectionChange={(args) => {
                        if (selectedItem.item === 'system') {
                            // pass
                            // we will recieve selection change events from the map
                            // while editing a system. We do not want to react to them here.
                        }
                        else {
                            if (args.features.length === 1) {
                                setSelectedItem({ item: 'feature', feature: args.features[0] });
                            }
                            else {
                                setSelectedItem({ item: 'none' });
                            }
                        }
                    }}
                    activeSystemIds={selectedItem.item === 'system' ? [selectedItem.id] : undefined}
                    additionalFeatures={additionalFeatures}
                >
                    <LayoutMapAppBar
                        projectId={projectId}
                        layoutId={layoutId}
                        selectedItem={selectedItem} setSelectedItem={setSelectedItem}
                        interactionState={interactionState} setInteractionState={setInteractionState}
                        autoNavigateToAnotherLayout={autoNavigateToAnotherLayout}
                    />
                    
                    <MapSpeedDial
                        projectId={projectId}
                        layoutId={layoutId}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        setInteractionState={setInteractionState}
                        navigateFromSystem={navigateFromSystem}
                        currentSegmentSelectEditMode={currentSegmentSelectEditMode}
                        renderAdditionalFeatures={setAdditionalFeature}
                    />
                </LayoutMap>
                
                <Stack 
                    sx={{
                        position: 'absolute', 
                        bottom: 16,
                        alignItems: 'center',
                        width: 108,
                        left: "calc(50% - 54px)"
                    }}
                >
                    <Tooltip title={`${isBottomDrawerOpen ? i18next.format(t('close'), 'capitalize') : i18next.format(t('open'), 'capitalize')} ${t("side-view")}`} placement="top">
                        <Fab 
                            variant="extended"
                            disabled={!isBottomDrawerPossible}
                            color='primary'
                            aria-label="side-view" 
                            sx={{
                                // The disabled color is not readable against a typical map background,
                                // create a custom disabled color here
                                "&:disabled": {
                                    color: 'rgba(255,255,255,0.5)',
                                    backgroundColor: 'rgba(0,0,0,0.5)'
                                }
                            }}
                            onClick={toggleBottomDrawer}
                        >
                            {t("side-view")}
                            {/* {isBottomDrawerOpen ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />} */}
                        </Fab>
                    </Tooltip>
                </Stack>
                

            </MapViewContainer>

            {
                isLeftDrawerOpen && (
                    <LeftDrawer
                        open={isLeftDrawerOpen}
                        navigateToSystemDesign={navigateToSystemDesign}
                        isBottomDrawerOpen={isBottomDrawerOpen}
                        setLeftDrawerWidth={setLeftDrawerWidth}
                        bottomDrawerHeight={BOTTOM_DRAWER_HEIGHT}
                        systemId={selectedItem.item === 'system' ? selectedItem.id : undefined}
                        layoutId={layoutId!}
                        dbPrj={dbProject}
                        projectId={projectId!}
                    />
                )
            }

            <BottomDrawer 
                projectId={projectId!}
                layoutId={layoutId!}
                systemId={selectedItem.item === 'system' ? selectedItem.id : undefined}
                open={isBottomDrawerOpen}
            />
        </>
    );
}




export default LayoutMapPage;