import { Box, Button, Drawer, Stack } from "@mui/material";
import IGrowerBase from "rdptypes/api/IGrowerBase";
import ISystem from "rdptypes/project/ISystem";
import * as React from "react";
import LiveProposal from "./LiveProposal";

interface Props {
    system: ISystem;
    grower: IGrowerBase;
    isOpen: boolean;
    toggle: () => void;
    sideViewOpen: boolean;
    hasSideView: boolean;
}

export default function ProposalDrawerNew(props: Props) {
  const drawerHeight = !props.sideViewOpen ? (window.innerHeight - 48) : (window.innerHeight - 350);
  return (
      <div onClick={props.toggle} style={{display: "flex", cursor: "pointer"}}>
        <Drawer open variant="permanent" anchor="right" PaperProps={{
          sx: {maxWidth: "500px", top: "48px", position: "absolute", height: drawerHeight + "px"}
        }}>
        <Stack direction="row">
          <div style={{alignItems: "center", marginTop: "40px", top: "40px", marginLeft: "10px", marginRight: "10px", position: "sticky", height: "fit-content"}}>
              <Box style={{display: "inline-block", verticalAlign: "middle"}} >
                  <Button sx={{textTransform: "initial", textOrientation: 'sideways', writingMode: 'vertical-lr', color: "rgba(0, 0, 0, 0.75)", padding: "2px 4px", minWidth: "40px"}}>
                      Live proposal
                  </Button>
              </Box>
          </div>
          {
            props.isOpen && <LiveProposal system={props.system} grower={props.grower} />
          }
        </Stack></Drawer>
      </div>
  );
}