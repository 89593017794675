import ISystem from "rdptypes/project/ISystem";
import { ISpan, ITower } from "rdptypes/project/Types";
import { SACType } from "../../../optimization/centerPivot/sac/SACOptimizer/ThinkGeo/OptimizeUtilities";
import { isValidSac } from "./isValidSac";

type IShouldGenerateSacReturn = {
    shouldGenerate: false;
    shouldClear?: boolean;
} | {
    shouldGenerate: true;
    sacType: SACType;
    endGunThrowsMeters: number[];
}

export const shouldGenerateSac = (previousSystem: ISystem | undefined, incommingSystem: ISystem, forceUpdate = false): IShouldGenerateSacReturn => {
    const incommingSac = isValidSac(incommingSystem);
    // if there is no previous system, we can instantly decide what to do:
    if (!previousSystem || forceUpdate) {
        if (incommingSac.isSac && incommingSac.isValid) {
            return {
                shouldGenerate: true,
                sacType: incommingSac.sacType,
                endGunThrowsMeters: incommingSac.endGunThrowsMeters
            }
        }
        else {
            return {
                shouldGenerate: false
            }
        }
    }
    
    // Next we short cut. If there should be a SAC on this system but for some reason it was never drawn:
    // add a no solution to sac solution
    const incommingHasExistingSacSolution = incommingSystem.sacOptimizerResult !== undefined;
    if (incommingSac.isSac && incommingSac.isValid) {
        if (!incommingHasExistingSacSolution) {
            return {
                shouldGenerate: true,
                sacType: incommingSac.sacType,
                endGunThrowsMeters: incommingSac.endGunThrowsMeters
            }
        }
    }
    else {
        // the incomming system is not a sac, but there is a sac solution on the model:
        if (incommingHasExistingSacSolution) {
            return {
                shouldGenerate: false,
                shouldClear: true
            }
        }
    }

    // now we are sure that there is a previous system:
    const previousSac = isValidSac(previousSystem);

    // if the incomming system is not a sac, or not a valid sac, we can decide what to do:
    if (!incommingSac.isSac || !incommingSac.isValid) {
        if (previousSac.isSac && previousSac.isValid) {
            return {
                shouldGenerate: false,
                shouldClear: true
            }
        }
        else {
            return {
                shouldGenerate: false
            }
        }
    }
    
    // if the incomming system is a valid sac, and the previous system was not, we can decide what to do:
    if (incommingSac.isValid) {
        if (!previousSac.isSac || !previousSac.isValid) {
            return {
                shouldGenerate: true,
                sacType: incommingSac.sacType,
                endGunThrowsMeters: incommingSac.endGunThrowsMeters
            }
        }
    }

    // At this point we can be sure that both the incmming system and the previous system have valid sacs.
    // we must compare the sac dependent fields to decide if we should redraw    
    const fieldGetters = [
        (a: ISystem) => a.Circle?.CenterPivot?.isPartialPivot,
        (a: ISystem) => a.Circle?.CenterPivot?.clockwiseCompassHeadingStart,
        (a: ISystem) => a.Circle?.CenterPivot?.clockwiseCompassHeadingEnd,
        (a: ISystem) => a.Circle?.SwingArm?.LeadingSwingArm,
        (a: ISystem) => a.centerPivot?.point?.coordinates[0],
        (a: ISystem) => a.centerPivot?.point?.coordinates[1],
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypePrimary,
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary,
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.SwingArmLength,
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.EndOfSystemType,
        (a: ISystem) => a.FlangedSide?.Span?.length,        
        (a: ISystem) => a.FlangedSide?.Tower?.length,    
        (a: ISystem) => a.endGuns?.flangedSidePrimaryThrow,    
        (a: ISystem) => a.endGuns?.flangedSideSecondaryThrow  
    ]
    if (fieldGetters.some(fieldGetter => fieldGetter(incommingSystem) !== fieldGetter(previousSystem))) {
        return {
            shouldGenerate: true,
            sacType: incommingSac.sacType,
            endGunThrowsMeters: incommingSac.endGunThrowsMeters
        }
    }

    // we will compare each span tower for a change. The fieldGetters check has already checked that the 
    // number of spans is the same between previous system and incomming system. Even so, we will compare
    // with a nullable spanTower incase some tower is undefined at an index.
    const spanFieldGetters = [
        (a?: ISpan) => a?.Length,
        (a?: ISpan) => a?.Extension,
        (a?: ISpan) => a?.dropSpanEndRelativeToPreviousSpanEnd,
        (a?: ISpan) => a?.dropSpanStartRelativeToPreviousSpanStart,
        (a?: ISpan) => a?.Disconnecting
    ]
    for (let i = 0; i < incommingSystem.FlangedSide?.Span?.length || 0; i++) {
        if (spanFieldGetters.some(fieldGetter => fieldGetter(incommingSystem.FlangedSide?.Span[i]) !== fieldGetter(previousSystem.FlangedSide?.Span[i]))) {
            return {
                shouldGenerate: true,
                sacType: incommingSac.sacType,
                endGunThrowsMeters: incommingSac.endGunThrowsMeters
            }
        }
    }

    // we will compare each span tower for a change. The fieldGetters check has already checked that the 
    // number of spans is the same between previous system and incomming system. Even so, we will compare
    // with a nullable spanTower incase some tower is undefined at an index.
    const towerFieldGetters = [
        (a?: ITower) => a?.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
        (a?: ITower) => a?.clockwiseWrapAngleRelativeToPreviousSpanDegrees,
        (a?: ITower) => a?.WrapAroundSpan,
    ]
    for (let i = 0; i < incommingSystem.FlangedSide?.Span?.length || 0; i++) {
        if (towerFieldGetters.some(fieldGetter => fieldGetter(incommingSystem.FlangedSide?.Tower[i]) !== fieldGetter(previousSystem.FlangedSide?.Tower[i]))) {
            return {
                shouldGenerate: true,
                sacType: incommingSac.sacType,
                endGunThrowsMeters: incommingSac.endGunThrowsMeters
            }
        }
    }

    // if the path data has changed then we will trigger a regeneration:
    const incommingPathData = incommingSystem.pathData || [];
    const previousPathData = previousSystem.pathData || [];
    if (incommingPathData.length !== previousPathData.length) {
        return {
            shouldGenerate: true,
            sacType: incommingSac.sacType,
            endGunThrowsMeters: incommingSac.endGunThrowsMeters
        }
    }
    else {
        for (let i = 0; i < incommingPathData.length; i++) {
            const crntIncomming = incommingPathData[i];
            const crntPrevious = previousPathData[i];
            if (crntIncomming.angle !== crntPrevious.angle || crntIncomming.radius !== crntPrevious.radius) {
                return {
                    shouldGenerate: true,
                    sacType: incommingSac.sacType,
                    endGunThrowsMeters: incommingSac.endGunThrowsMeters
                }
            }
        }
    }
    
    
    // If we have gotten this far, then the systems are the same interms of SAC
    return {
        shouldGenerate: false
    }

}