/**
 * List changes here...
 * 
 * Adding rate to SystemBase
 */

import IPartsPackage from "rdptypes/roe/IPartsPackage";
import partsPackage_2024081901 from "../2024081901";

export default {
    ...partsPackage_2024081901,
    id: "2024110401"
} as IPartsPackage;