import {
    AnchorPackageTypes,
    ICircleCenterPivot as CenterPivot,
    CenterPivotTypes,
    EndTowerPositioningTypes,
    FlowmeterTypes,
    GasketTypes,
    ISystemBase,
    PadBoltTypes,
    PipeBottomFittingTypes,
    RiserPipeTypes,
    SpanTypes,
    TowTypes,
    TowerHeights,
    ValveSizes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import { TireSelector } from "./CommonFunctions";
import * as ohf from "./OtherHelpers";
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import * as sysf from "./SystemFunctions";
import { strLanguageText } from "./TranslationsLib";
import { Tires } from "./Types";
import { AnchorOK, E2045FoundationFormKitOK, For6InchValveOK, For6x19FlangeDeviceOK, For8x23FlangeDeviceOK, GasketOK, PadBoltOK, PerpendicularOffsetOK, PipeBottomAdapter8to6OK, PipeBottomAdapterOK, PipeBottomFittingOK, PivotCenterOK, PivotRiserPipeOK, PivotWalkwayOK, TowTypeOK, TubesOK, TwoThirtyVoltSinglePhaseOK } from "./Valids.Valids";
export class CenterPivotClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        let cp: CenterPivot = this.Quote.System.Circle.CenterPivot;
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(strLanguageText("sltCenterPivotSection"));
        return false;
        }*/
        if (!(PivotCenterOK(cp.PivotCenterHeight, cp.CenterPivotType))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.PivotCenterHeight"], strLanguageText("sltPivotCenter"));
        }
        if (!(PipeBottomFittingOK(cp.PipeBottomFitting, {
            CenterPivotType: cp.CenterPivotType,
            PivotCenterHeight: cp.PivotCenterHeight
        }))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.PipeBottomFitting"], strLanguageText("sltPipeBottomFitting"));
        }
        if (!(PipeBottomAdapterOK(cp.PipeBottomAdapter, {
            PipeBottomFittingType: cp.PipeBottomFitting,
            IsAdapterChecked: false,
            IsAdapter8to6Checked: false,
        }))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.PipeBottomAdapter"], strLanguageText("sltAdapterReducer"));
        }
        if (!(PipeBottomAdapter8to6OK(cp.PipeBottomAdapter8to6, {
            PipeBottomFittingType: cp.PipeBottomFitting,
            IsAdapterChecked: false,
            IsAdapter8to6Checked: false,
        }))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.PipeBottomAdapter8to6"], strLanguageText("sltAdapterReducer"));
        }
        if (!(PerpendicularOffsetOK(cp.PerpendicularOffset, cp.PipeBottomFitting))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.PerpendicularOffset"], strLanguageText("sltPipeBottomFitting"));
        }
        if (!(PivotRiserPipeOK(cp.RiserPipeType, {
            PipeBottomFittingType: cp.PipeBottomFitting,
            CenterPivotType: cp.CenterPivotType,
            PivotCenter: cp.PivotCenterHeight
        }))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.RiserPipeType"], strLanguageText("sltPivotRisePipe"));
        }
        let hasVerticalFlowmeter: boolean = false;
        let s: ISystemBase = this.Quote.System;
        if (sysf.FieldSets(this.Quote.System).ControlPanel.DataValid()) {
            hasVerticalFlowmeter = s.ControlPanel.Flowmeter === FlowmeterTypes.Vertical;
        }
        if (!(TowTypeOK(cp.TowOptions.TowType, {
            CenterPivotType: cp.CenterPivotType,
            PivotCenterHeight: cp.PivotCenterHeight,
            RiserPipeType: cp.RiserPipeType,
            PipeBottomFittingType: cp.PipeBottomFitting,
            HasVerticalFlowmeter: hasVerticalFlowmeter
        }))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.TowOptions.TowType"], strLanguageText("sltTowType"));
        }
        if (!(For6InchValveOK(cp.RiserPipeFor6InchValve, {
            RiserPipeType: cp.RiserPipeType,
            CenterPivotType: cp.CenterPivotType,
            PivotCenter: cp.PivotCenterHeight,
            TowType: cp.TowOptions.TowType,
            PipeBottomFittingType: cp.PipeBottomFitting,
            Is6x19Checked: cp.RiserPipeFor6x19FlangeDevice,
            Is8x23Checked: cp.RiserPipeFor8x23FlangeDevice,
            HasVerticalFlowmeter: hasVerticalFlowmeter
        }))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.RiserPipeFor6InchValve"], strLanguageText("sltFor6InchValve"));
        }
        if (!(For6x19FlangeDeviceOK(cp.RiserPipeFor6x19FlangeDevice, {
            RiserPipeType: cp.RiserPipeType,
            CenterPivotType: cp.CenterPivotType,
            PivotCenter: cp.PivotCenterHeight,
            TowType: cp.TowOptions.TowType,
            PipeBottomFittingType: cp.PipeBottomFitting,
            Is8x23Checked: cp.RiserPipeFor8x23FlangeDevice,
            Is6InchValveChecked: cp.RiserPipeFor6InchValve,
            HasVerticalFlowmeter: hasVerticalFlowmeter
        }))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.RiserPipeFor6x19FlangeDevice"], strLanguageText("sltFor6x19FlangeDevice"));
        }
        if (!(For8x23FlangeDeviceOK(cp.RiserPipeFor8x23FlangeDevice, {
            RiserPipeType: cp.RiserPipeType,
            CenterPivotType: cp.CenterPivotType,
            PivotCenter: cp.PivotCenterHeight,
            TowType: cp.TowOptions.TowType,
            PipeBottomFittingType: cp.PipeBottomFitting,
            Is6x19Checked: cp.RiserPipeFor6x19FlangeDevice,
            Is6InchValveChecked: cp.RiserPipeFor6InchValve,
            HasVerticalFlowmeter: hasVerticalFlowmeter
        }))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.RiserPipeFor8x23FlangeDevice"], strLanguageText("sltFor8x23FlangeDevice"));
        }
        if (!(E2045FoundationFormKitOK(cp.E2045FoundationFormKit, cp.CenterPivotType))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.E2045FoundationFormKit"], strLanguageText("sltE2045FoundationFormKit"));
        }
        if (!(PivotWalkwayOK(cp.Walkway, this.Quote.System.Circle.EngineDrivenMiniPivot, cp.CenterPivotType))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.Walkway"], strLanguageText("sltPivotCenterWalkway"));
        }
        if (!(TwoThirtyVoltSinglePhaseOK(this.Quote.System.Circle.SinglePhase230VoltSystem, this.Quote.System.SystemProperties.SystemType, cp.CenterPivotType,
            this.Quote.System.Circle.EngineDrivenMiniPivot,
            ohf.IsInternational(this.Quote.System.DealerProperties) // TODO Icon - Check this makes sense
        ))) {
            this.Quote.Warnings.AddWithTargets(["Circle.SinglePhase230VoltSystem"], strLanguageText("slt230VoltSinglePhase"));
        }
        if (!(GasketOK(cp.Gasket, cp.CenterPivotType))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.Gasket"], strLanguageText("sltGasket"));
        }
        if ((cp.TowOptions.TowType ?? TowTypes.None) !== TowTypes.None) {
            this.Quote.Warnings.Add(strLanguageText("sltDontTowDownhill"), WarningLevels.Informational);
        }
        if (this.Quote.System.SystemProperties.ReverseTow) {
            if (cp.TowOptions.TowType === TowTypes.None || cp.TowOptions.TowType === TowTypes.FourWheelPivotMover || cp.CenterPivotType === CenterPivotTypes.E2100) {
                this.Quote.Warnings.Add(strLanguageText("sltReverseTow"));
            }
        }
        if (!(AnchorOK(cp.AnchorPackage, cp.TowOptions.TowType, cp.CenterPivotType, cp.PivotCenterHeight))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.AnchorPackage"], strLanguageText("sltAnchorBoltPackage"));
        }
        if (!(PadBoltOK(cp.PadBoltType, cp.AnchorPackage))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.PadBoltType"], strLanguageText("sltPadAdapterBolt"));
        }
        if (cp.TowOptions.ExtraHitch) {
            if (cp.TowOptions.TowType === TowTypes.None || cp.TowOptions.TowType === TowTypes.SkidTow) {
                this.Quote.Warnings.Add(strLanguageText("sltXtraHitchAssy"));
            }
        }
        if (!(TubesOK(cp.TowOptions.Tires.TubesForTires, {
            TireSize: cp.TowOptions.Tires.TireSize,
            TireType: cp.TowOptions.Tires.TireType,
        }))) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.TowOptions.Tires.TubesForTires"], strLanguageText("sltTubesForTires"));
        }
        if (cp.TowOptions.Tires.TubesForTires) {
            switch (cp.TowOptions.TowType) {
                case TowTypes.FourWheelPivotMover:
                    break;
                default:
                    this.Quote.Warnings.Add(strLanguageText("sltTubesForTires"));
                    break;
            }
        }


        if (cp.RiserPipeFor6InchValve
            && this.Quote.System.MainlineValveOptions?.Valves?.MainlineValve?.length
            && (this.Quote.System.MainlineValveOptions.Valves.MainlineValve[0].Size ?? ValveSizes.a6Inch) !== ValveSizes.a6Inch) {
            this.Quote.Warnings.Add(strLanguageText("slt6InchValveWarning"), WarningLevels.Decision, false);
        }

        switch (cp.PivotCenterHeight) {
            case TowerHeights.Standard:
                if (this.Quote.RightTowersClass.AnyLowProfiles() || this.Quote.RightTowersClass.AnySugargators()) {
                    this.Quote.Warnings.Add(strLanguageText("sltTowerInconsistentWithCenterPivot"), WarningLevels.Decision);
                }
                break;
            case TowerHeights.Sugargator:
                if (!(this.Quote.RightTowersClass.AllSugargators())) {
                    this.Quote.Warnings.Add(strLanguageText("sltTowerInconsistentWithCenterPivot"), WarningLevels.Decision);
                }
                break;
            case TowerHeights.LowProfile:
                if (!(this.Quote.RightTowersClass.AllLowProfiles())) {
                    this.Quote.Warnings.Add(strLanguageText("sltTowerInconsistentWithCenterPivot"), WarningLevels.Decision);
                }
                break;
        }
    }
    public WritePO = (PO: PartsAssembler) => {
        let bResolver: boolean;
        let bAnyPoly: boolean;
        let sLastWheelTrack: number;
        let bStainlessSteelSpanJoints: boolean;
        let bRemotePanel: boolean;
        let i: number;
        let iSelectedTire: number;
        let cp: CenterPivot = this.Quote.System.Circle.CenterPivot;
        bResolver = false;
        if (this.Quote.ControlPanelClass.FieldSet.DataValid()) {
            bRemotePanel = this.Quote.System.ControlPanel.RemotePanelOptions.RemotePanel;
            switch (this.Quote.System.ControlPanel.EndTowerPositioning) {
                case EndTowerPositioningTypes.Resolver:
                    bResolver = true;
                    break;
            }
        }
        let eFirstSpanType: SpanTypes = SpanTypes.E2100;
        let eFirstSpanLength: number = 80;
        sLastWheelTrack = 0;
        if (this.Quote.RightSpansClass.FieldSet.DataValid()) {
            i = sidef.NumberOfSpans(this.Quote.System.FlangedSide);
            if (i > 0) {
                eFirstSpanType = this.Quote.System.FlangedSide.Span[1 - 1].SpanType;
                eFirstSpanLength = this.Quote.System.FlangedSide.Span[1 - 1].Length;
                sLastWheelTrack = spanf.EndingLocation(this.Quote.System, this.Quote.System.FlangedSide, this.Quote.System.FlangedSide.Span[i - 1]);
                bAnyPoly = this.Quote.RightSpansClass.AnyPoly();
                bStainlessSteelSpanJoints = this.Quote.System.SpanProperties.StainlessSteelJoints;
            }
        }
        switch (cp.CenterPivotType) {
            case CenterPivotTypes.E2100:
                switch (cp.PivotCenterHeight) {
                    case TowerHeights.Standard:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394101-GR", 1); // PVT CTR 10" RAMS EII GALV
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394101-G", 1); // PVT CTR 10" EII GALV
                        }
                        break;
                    case TowerHeights.LowProfile:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394102-GR", 1); // PVT CTR 10" LP RAMS EII GALV
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394102-G", 1); // PVT CTR 10" LP EII GALV
                        }
                        break;
                    case TowerHeights.Sugargator:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394103-GR", 1); // PVT CTR 10" RAMS SG EII GALV
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394103-G", 1); // PVT CTR 10" SG EII GALV
                        }
                        break;
                }
                break;
            case CenterPivotTypes.E2085:
                switch (cp.PivotCenterHeight) {
                    case TowerHeights.Standard:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394401-GR", 1); // PVT CTR 8-5/8" RAMS MF GALV
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394401-G", 1); // PVT CTR 8-5/8" MF GALV
                        }
                        break;
                    case TowerHeights.LowProfile:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394402-GR", 1); // PVT CTR 8-5/8" LP RAMS MF G
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394402-G", 1); // PVT CTR 8-5/8" LP MF GALV
                        }
                        break;
                    case TowerHeights.Sugargator:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394403-GR", 1); // PVT CTR 8-5/8" SG RAM MF GLV
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394403-G", 1); // PVT CTR 8-5/8" SG MF GALV
                        }
                        break;
                }
                break;
            case CenterPivotTypes.A80G:
                switch (cp.PivotCenterHeight) {
                    case TowerHeights.Standard:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394201-GR", 1); // PVT CTR 8" RAMS MF GALV
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394201-G", 1); // PVT CTR 8" MF GALV
                        }
                        break;
                    case TowerHeights.LowProfile:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394202-GR", 1); // PVT CTR 8" LP RAMS MF GALV
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394202-G", 1); // PVT CTR 8" LP MF GALV
                        }
                        break;
                    case TowerHeights.Sugargator:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394203-GR", 1); // PVT CTR 8" SG RAMS MF GALV
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394203-G", 1); // PVT CTR 8" SG MF GALV
                        }
                        break;
                }
                break;
            case CenterPivotTypes.E2065:
                switch (cp.PivotCenterHeight) {
                    case TowerHeights.Standard:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394301-GR", 1); // PVT CTR 6-5/8" RAMS MF GALV
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394301-G", 1); // PVT CTR 6-5/8" MF GALV
                        }
                        break;
                    case TowerHeights.LowProfile:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394302-GR", 1); // PVT CTR 6-5/8" LP RAMS MF G
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394302-G", 1); // PVT CTR 6-5/8" LP MF GALV
                        }
                        break;
                    case TowerHeights.Sugargator:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394303-GR", 1); // PVT CTR 6-5/8 RAMS SG MF G
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394303-G", 1); // PVT CTR 6-5/8" SG MF GALV
                        }
                        break;
                }
                break;
            case CenterPivotTypes.S2085G:
                switch (cp.PivotCenterHeight) {
                    case TowerHeights.Standard:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394199", 1); // PVT CTR 8-5/8 RAMS SS MF
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394200", 1); // PVT CTR 8-5/8 SS MF
                        }
                        break;
                    case TowerHeights.LowProfile:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394108", 1); // PVT CTR 8-5/8" LP S2085 RAMS
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394107", 1); // PVT RISER 10" LONG CTAR EII
                        }
                        break;
                    case TowerHeights.Sugargator:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394403RSS", 1); // PVT CTR 8-5/8" SG MF RAMS SS
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394403SS", 1); // PVT CTR 8-5/8" SG MF SS
                        }
                        break;
                }
                break;
            case CenterPivotTypes.A80G:
                switch (cp.PivotCenterHeight) {
                    case TowerHeights.Standard:
                        if (bResolver) {
                        }
                        break;
                    case TowerHeights.LowProfile:
                        if (bResolver) {
                        }
                        break;
                    case TowerHeights.Sugargator:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394203RSS", 1); // PVT CTR 8" SG MF RAMS SS
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394109", 1); // PVT CTR 8-5/8" SG S2085
                        }
                        break;
                }
                break;
            case CenterPivotTypes.S80G:
                switch (cp.PivotCenterHeight) {
                    case TowerHeights.Standard:
                        switch (eFirstSpanType) {
                            case SpanTypes.A80G:
                                if (bResolver) {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394201RSS", 1); // PVT CTR 8" RAMS SS
                                }
                                else {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394201SS", 1); // PVT CTR 8" SS MF
                                }
                                break;
                            default:
                                if (bResolver) {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394199", 1); // PVT CTR 8-5/8 RAMS SS MF
                                }
                                else {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394200", 1); // PVT CTR 8-5/8 SS MF
                                }
                                break;
                        }
                        break;
                    case TowerHeights.LowProfile:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394108", 1); // PVT CTR 8-5/8" LP S2085 RAMS
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394107", 1); // PVT RISER 10" LONG CTAR EII
                        }
                        break;
                    case TowerHeights.Sugargator:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394203RSS", 1); // PVT CTR 8" SG MF RAMS SS
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394203SS", 1); // PVT CTR 8" SG MF SS
                        }
                        break;
                }
                break;
            case CenterPivotTypes.S2065G:
                switch (cp.PivotCenterHeight) {
                    case TowerHeights.Standard:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394301RSS", 1); // PVT CTR 6-5/8" RAMS SS MF
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394301SS", 1); // PVT CTR 6-5/8" MF SS
                        }
                        break;
                    case TowerHeights.LowProfile:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394302RSS", 1); // PVT CTR 6-5/8" LP SS RAMS MF
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394302SS", 1); // PVT CTR 6-5/8" LP SS
                        }
                        break;
                    case TowerHeights.Sugargator:
                        if (bResolver) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394303RSS", 1); // PVT CTR 6-5/8 RAMS SG MF SS
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394303SS", 1); // PVT CTR 6 5/8" SG MF SS
                        }
                        break;
                }
                break;
            case CenterPivotTypes.E2045:
                PO.InsertPart(schema.groups.CenterPivot_PivotCenterAssembly.groupId, "394501", 1); // PVT CTR 6-5/8" 3 LEGS E2045
                break;
        }
        switch (cp.PivotCenterHeight) {
            case TowerHeights.Standard:
                switch (cp.CenterPivotType) {
                    case CenterPivotTypes.E2100:
                        switch (cp.RiserPipeType) {
                            case RiserPipeTypes.LongPipe:
                                PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394105", 1); // PIVOT RISER PIPE-LONG-10"- EII
                                break;
                            case RiserPipeTypes.ShortPipe:
                                PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394106", 1); // PIVOT RISER PIPE SHORT-10" EII
                                break;
                        }
                        break;
                    case CenterPivotTypes.E2085:
                    case CenterPivotTypes.A80G:
                    case CenterPivotTypes.E2065:
                        switch (cp.RiserPipeType) {
                            case RiserPipeTypes.LongPipe:
                                if (cp.RiserPipeFor6InchValve) {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394337", 1); // RISER PIPE-8" PVT-6" SLV VLV
                                }
                                else if (cp.RiserPipeFor6x19FlangeDevice) {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "354151", 1); // LONG 6" FLANGED RISER PIPE
                                }
                                else if (cp.RiserPipeFor8x23FlangeDevice) {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "354154", 1); // LONG 8" FLANGED RIDER PIPE
                                }
                                else {
                                    if (this.Quote.ControlPanelClass.DataValid() && this.Quote.System.ControlPanel.Flowmeter === FlowmeterTypes.Vertical && sysf.VerticalFlowTubeKitAllowed(this.Quote.System)) {
                                        PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "353284", 1); // LONG 8" RISER PIPE-FLOW METER
                                    }
                                    else {
                                        if (cp.TowOptions.TowType === TowTypes.FourWheelPivotMover && [PipeBottomFittingTypes.Filter700GPM, PipeBottomFittingTypes.Filter1200GPM].indexOf(cp.PipeBottomFitting) !== -1) {
                                            PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "392353", 1); // PIVOT RISER 8" 4WPM W/FILTERS
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394305", 1); // PIVOT RISER PIPE- LONG-8"- EII
                                        }
                                    }
                                }
                                break;
                            case RiserPipeTypes.ShortPipe:
                                if (this.Quote.ControlPanelClass.DataValid() && this.Quote.System.ControlPanel.Flowmeter === FlowmeterTypes.Vertical && sysf.VerticalFlowTubeKitAllowed(this.Quote.System)) {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "353283", 1); // SHORT 8" RISER PIPE-FLOW METER
                                }
                                else {
                                    if (cp.RiserPipeFor6InchValve) {
                                        PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394337", 1); // RISER PIPE-8" PVT-6" SLV VLV
                                    }
                                    else if (cp.RiserPipeFor6x19FlangeDevice) {
                                        PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "354150", 1); // SHORT 6" FLANGED RISER PIPE
                                    }
                                    else if (cp.RiserPipeFor8x23FlangeDevice) {
                                        PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "354153", 1); // SHORT 8" FLANGED RISER PIPE
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394306", 1); // PIVOT RISER PIPE-SHORT-8"- EII
                                    }
                                }
                                break;
                        }
                        break;
                    case CenterPivotTypes.S2085G:
                    case CenterPivotTypes.S2065G:
                    case CenterPivotTypes.S80G:
                        switch (cp.RiserPipeType) {
                            case RiserPipeTypes.LongPipe:
                                PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394305SS", 1); // PVT RISER PIPE-LONG-8" SS EII
                                break;
                            case RiserPipeTypes.ShortPipe:
                                if (bAnyPoly) {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394307", 1); // PIVOT RISER PIPE-SHT 8" 316SS
                                }
                                else {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394306SS", 1); // PVT RISER PIPE-SHORT 8" SS EII
                                }
                                break;
                        }
                        break;
                    case CenterPivotTypes.E2045:
                        switch (cp.RiserPipeType) {
                            case RiserPipeTypes.LongPipe:
                                PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394339", 1); // RISER PIPE-LONG-6" EII
                                break;
                            case RiserPipeTypes.ShortPipe:
                                PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394338", 1); // RISER PIPE-SHORT-6" EII
                                break;
                        }
                        break;
                }
                break;
            case TowerHeights.Sugargator:
                if ([CenterPivotTypes.E2065, CenterPivotTypes.E2085, CenterPivotTypes.A80G].indexOf(cp.CenterPivotType) !== -1) {
                    switch (cp.RiserPipeType) {
                        case RiserPipeTypes.LongPipe:
                            if (cp.RiserPipeFor6x19FlangeDevice) {
                                PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "354156", 1); // LONG 6" FLANGED SG RISER PIPE
                            }
                            else {
                                PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394387", 1); // PIVOT RISER PIPE-LONG-8-SG-EII
                            }
                            break;
                        case RiserPipeTypes.ShortPipe:
                            if (cp.RiserPipeFor6x19FlangeDevice) {
                                PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "354155", 1); // SHORT 6" FLANGED SG RISER PIPE
                            }
                            else {
                                PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "394379", 1); // PIVOT RISER PIPE-SHRT-8-SG-EII
                            }
                            break;
                    }
                }
                break;
        }
        if (cp.E2045FoundationFormKit) {
            PO.InsertPart(schema.groups.CenterPivot_E2045FoundationFormKit.groupId, "394511", 1); // FORM KIT E2045 PVT FOUNDATION
        }
        switch (eFirstSpanType) {
            case SpanTypes.E2100:
                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396125", 1); // PIVOT JOINT ASSY 10 EII
                break;
            case SpanTypes.E2085:
                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396225", 1); // PIVOT JOINT ASSY 8-5/8 EII
                break;
            case SpanTypes.E2065:
            case SpanTypes.E2060:
            case SpanTypes.E2665:
            case SpanTypes.E2660:
                if (bStainlessSteelSpanJoints) {
                    PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396024SS", 1); // PIVOT JOINT ASY 6-5/8 SS EII
                }
                else {
                    if (this.Quote.RightSpansClass.FieldSet.DataValid() && this.Quote.System.SpanProperties.GalvanizedCNJoints) {
                        PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396594", 1); // JOINT PIVOT E2665G
                    }
                    else {
                        if ([SpanTypes.E2665, SpanTypes.E2660].indexOf(eFirstSpanType) !== -1) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396594", 1); // JOINT PIVOT E2665G
                        }
                        else {
                            PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396324", 1); // JOINT PIVOT E2065
                        }
                    }
                }
                break;
            case SpanTypes.AlumIV:
            case SpanTypes.A60G:
                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396725", 1); // PIVOT JOINT ASSY-ALUMI 4
                break;
            case SpanTypes.A80G:
                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "397025", 1); // PIVOT JOINT ASY-A80-G
                break;
            case SpanTypes.S2085G:
                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "397107SS", 1); // PIVOT JOINT ASY S2085 EII
                break;
            case SpanTypes.S2065G:
                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396024SS", 1); // PIVOT JOINT ASY 6-5/8 SS EII
                break;
            case SpanTypes.PL2065G:
                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396549", 1); // PIVOT JOINT ASSY PL2065
                break;
            case SpanTypes.E2045:
                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "397034", 1); // JOINT PIVOT E2045 CN+
                break;
            case SpanTypes.PL2085G:
                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396137", 1); // PIVOT JOINT ASY PL2085
                break;
        }
        switch (cp.Gasket) {
            case GasketTypes.Standard:
                switch (cp.CenterPivotType) {
                    case CenterPivotTypes.E2100:
                        PO.InsertPart(schema.groups.CenterPivot_GasketPackage.groupId, "394325", 1); // GASKET 10 INCH PVT SINGLE LIP
                        break;
                    default:
                        PO.InsertPart(schema.groups.CenterPivot_GasketPackage.groupId, "394309", 1); // GASKET 8 INCH PIVOT STANDARD
                        break;
                }
                break;
            case GasketTypes.TripleLip:
                switch (cp.CenterPivotType) {
                    case CenterPivotTypes.E2100:
                        PO.InsertPart(schema.groups.CenterPivot_GasketPackage.groupId, "394324", 1); // GASKET 10 INCH PVT TRIPLE LIP
                        break;
                    case CenterPivotTypes.E2045:
                        break;
                    default:
                        PO.InsertPart(schema.groups.CenterPivot_GasketPackage.groupId, "394310", 1); // GASKET 8 INCH PIVOT TRIPLE LIP
                        break;
                }
                break;
        }
        switch (cp.PipeBottomFitting) {
            case PipeBottomFittingTypes.ElbowPlain:
                switch (cp.CenterPivotType) {
                    case CenterPivotTypes.E2100:
                        PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394318-G", 1); // ELBOW-BTM-PLAIN END-10" GALV
                        break;
                    case CenterPivotTypes.E2085:
                    case CenterPivotTypes.A80G:
                    case CenterPivotTypes.E2065:
                        PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394311-G", 1); // ELBOW-BTM-PLAIN END-8" GALV
                        if (cp.MaleRingLock) {
                            PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394410", 1); // 8" MALE RING LOCK OPT
                        }
                        break;
                    case CenterPivotTypes.S2065G:
                    case CenterPivotTypes.S2085G:
                    case CenterPivotTypes.S80G:
                        PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394311SS", 1); // ELBOW-BTM-PLAIN END-8" SS
                        break;
                    case CenterPivotTypes.E2045:
                        PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394326", 1); // ELBOW-BTM-PLAIN END 6" GALV
                        if (cp.MaleRingLock) {
                            PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394409", 1); // 6" MALE RING LOCK OPT
                        }
                        break;
                }
                break;
            case PipeBottomFittingTypes.ElbowSAE:
                switch (cp.CenterPivotType) {
                    case CenterPivotTypes.E2100:
                        PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394322-G", 1); // ELBOW-BTM-SAE FLG-10"
                        break;
                    case CenterPivotTypes.E2085:
                    case CenterPivotTypes.A80G:
                    case CenterPivotTypes.E2065:
                        PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394312-G", 1); // ELBOW-BTM-SAE FLG-8" GALV
                        break;
                    case CenterPivotTypes.S2065G:
                    case CenterPivotTypes.S2085G:
                    case CenterPivotTypes.S80G:
                        PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394312SS", 1); // ELBOW-BTM-SAE FLG-8" SS
                        break;
                    case CenterPivotTypes.E2045:
                        PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394335", 1); // ELBOW-BTM-6" SAE FLG GALV
                        break;
                }
                break;
            case PipeBottomFittingTypes.Elbow8Inch:
                PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394413-G", 1); // ELBOW-BTM W/8" COUPLER GALV
                break;
            case PipeBottomFittingTypes.ElbowReducer:
                PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394308", 1); // REDCR BTM ELBOW-8 X 6 5/8
                break;
            case PipeBottomFittingTypes.ExtPipe:
                PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394313-G", 1); // PIPE-STR-BTM PVT-8" GALV
                break;
            case PipeBottomFittingTypes.Filter475GPM:
                if (cp.PerpendicularOffset) {
                    PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394342", 1); // FILTER-RMCI 6" 475 GPM OFFSET
                }
                else {
                    PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394343", 1); // FILTER-RMCI 6" 475 GPM
                }
                break;
            case PipeBottomFittingTypes.Filter700GPM:
                PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394314", 1); // FLTR-INL 8" -700 GRM-3/32 RMCI
                break;
            case PipeBottomFittingTypes.Filter1200GPM:
                PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394315", 1); // FLTR-INL 8"-1200 GRM-3/32 RMCI
                break;
            case PipeBottomFittingTypes.Filter2000GPM:
                switch (cp.CenterPivotType) {
                    case CenterPivotTypes.E2100:
                        PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394317", 1); // FLTR-INL 10"-2000GPM-3/32 RMCI
                        break;
                    case CenterPivotTypes.E2085:
                    case CenterPivotTypes.A80G:
                    case CenterPivotTypes.E2065:
                    case CenterPivotTypes.S80G:
                    case CenterPivotTypes.S2065G:
                    case CenterPivotTypes.S2085G:
                        PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394316", 1); // FLTR-INL 8"-2000 GPM-3/32 RMCI
                        break;
                }
                break;
        }
        if (cp.PipeBottomAdapter) {
            PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "394323", 1); // ADAPTER-6 5/8 TO 8
        }
        if (cp.PipeBottomAdapter8to6) {
            PO.InsertPart(schema.groups.CenterPivot_PipeBottomFitting.groupId, "354152", 1); // HORIZ. 6" FLANGED RISER PIPE
        }
        if (cp.TowOptions.TowType === TowTypes.SkidTow) {
            switch (cp.PivotCenterHeight) {
                case TowerHeights.Standard:
                    PO.InsertPart(schema.groups.CenterPivot_TowSkidPackage.groupId, "392303-G", 1); // TOW SKID-PVT CTR-EII/A4
                    break;
                case TowerHeights.LowProfile:
                    PO.InsertPart(schema.groups.CenterPivot_TowSkidPackage.groupId, "392304-G", 1); // TOW SKID PKG-LP-ELECT-ALUMI/GA
                    break;
                case TowerHeights.Sugargator:
                    PO.InsertPart(schema.groups.CenterPivot_TowSkidPackage.groupId, "392305-G", 1); // TOW SKID PACKAGE-SG GAL
                    break;
            }
        }
        if (!(bRemotePanel) && cp.CenterPivotType !== CenterPivotTypes.E2045) {
            switch (cp.PivotCenterHeight) {
                case TowerHeights.Standard:
                    PO.InsertPart(schema.groups.CenterPivot_MCPMounts.groupId, "394371", 1); // MOUNTS-MCP STD HEIGHT PVT CTR
                    break;
                case TowerHeights.LowProfile:
                    PO.InsertPart(schema.groups.CenterPivot_MCPMounts.groupId, "394370", 1); // MOUNTS-MCP LOW PROFILE PVT CTR
                    break;
                case TowerHeights.Sugargator:
                    PO.InsertPart(schema.groups.CenterPivot_MCPMounts.groupId, "394372", 1); // MOUNTS-MCP SG HEIGHT PVT CTR
                    break;
            }
        }
        if ([TowTypes.SkidTow, TowTypes.FourWheelPivotMover].indexOf(cp.TowOptions.TowType) !== -1) {
            switch (eFirstSpanLength) {
                case 80:
                case 99:
                case 118:
                case 120:
                case 137:
                case 140:
                case 156:
                    PO.InsertPart(schema.groups.CenterPivot_TowCableAssembly.groupId, "351021", 1); // TOW KIT ACCESSORY #1 156FT SPN
                    break;
                case 160:
                case 175:
                    PO.InsertPart(schema.groups.CenterPivot_TowCableAssembly.groupId, "351040", 1); // TOW KIT ACCESSORY #1 175FT SPN
                    break;
                case 180:
                case 186.7:
                case 194:
                    PO.InsertPart(schema.groups.CenterPivot_TowCableAssembly.groupId, "351101", 1); // TOW KIT ACCESSORY #1 194FT SPN
                    break;
                case 204:
                    PO.InsertPart(schema.groups.CenterPivot_TowCableAssembly.groupId, "351102", 1); // TOW KIT ACCESSORY #1 204FT SPN
                    break;
                case 213:
                    PO.InsertPart(schema.groups.CenterPivot_TowCableAssembly.groupId, "351100", 1); // TOW KIT ACCESSORY #1 213' SPAN
                    break;
            }
        }
        if (cp.Walkway) {
            PO.InsertPart(schema.groups.CenterPivot_PivotCenterWalkway.groupId, "394321", 1); // WALKWAY-PVT CTR-EII
        }
        if (cp.TowOptions.TowType === TowTypes.FourWheelPivotMover) {
            if ([PipeBottomFittingTypes.Filter700GPM, PipeBottomFittingTypes.Filter1200GPM].indexOf(cp.PipeBottomFitting) !== -1) {
                PO.InsertPart(schema.groups.CenterPivot_4WheelPivotMoverAssembly.groupId, "392352", 1); // PIVOT MOVER-4-WHL W/FILTER EII
            }
            else {
                PO.InsertPart(schema.groups.CenterPivot_4WheelPivotMoverAssembly.groupId, "392351-G", 1); // PIVOT MOVER-4-WHEELED EII
            }
        }
        if (cp.TowOptions.TowType === TowTypes.FourWheelPivotMover) {
            let t: Tires = cp.TowOptions.Tires;
            iSelectedTire = TireSelector(t.TireSize, t.TireType, t.OneTireMountedInReverse, t.TubesForTires);
            PO.InsertPartByIteration(schema.groups.CenterPivot_TireAndWheelAssembly.groupId, iSelectedTire, 2);
        }
        if (cp.TowOptions.ExtraHitch) {
            PO.InsertPart(schema.groups.CenterPivot_ExtraHitchAssembly.groupId, "392307-G", 1); // EXTRA HITCH OPTION-4WPM EII
        }
        switch (cp.AnchorPackage) {
            case AnchorPackageTypes.Bolt:
                switch (cp.CenterPivotType) {
                    case CenterPivotTypes.E2100:
                        PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "392101", 1); // ANCHOR BOLT PKG-NON TOW-E1060
                        break;
                    case CenterPivotTypes.E2045:
                        PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "392310", 1); // ANCHOR BLT PK-NT-E2045
                        break;
                    default:
                        PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "392301", 1); // ANCHOR BLT PK-NT-E88/65/A4/SUG
                        break;
                }
                break;
            case AnchorPackageTypes.Chain17:
                if (cp.CenterPivotType === CenterPivotTypes.E2100) {
                    PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "392102", 1); // ANCHOR CHAIN PKG-TOW-E1060
                }
                else {
                    PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "392302", 1); // ANCHOR CHAIN-PK-TOW-88/65/A/SG
                }
                break;
            case AnchorPackageTypes.Chain25:
                if (cp.CenterPivotType === CenterPivotTypes.E2100) {
                    PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "392103", 1); // ANCHOR CHAIN PKG-E1060-4WPM
                }
                else {
                    PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "392306", 1); // ANCHOR CHAIN PK-E60/88/A4-4WPM
                }
                break;
            case AnchorPackageTypes.Skid:
                PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "392309-G", 1); // TOW SKID PACKAGE-NON-TOW PIVOT
                break;
            case AnchorPackageTypes.Turnbuckle:
                PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "392308", 1); // TURNBUCKLE PACKAGE-4 WH PVT MV
                break;
            case AnchorPackageTypes.CompetitivePadAdapter:
                PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "394320-G", 1); // ADAPTER CHAIN TIE DOWN-EII
                break;
            case AnchorPackageTypes.CompetitivePadAdapterWithSkids:
                PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "392309-G", 1); // TOW SKID PACKAGE-NON-TOW PIVOT
                PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "394320-G", 1); // ADAPTER CHAIN TIE DOWN-EII
                break;
            case AnchorPackageTypes.CompetitivePadAdapterWithChannel:
                PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "394319-G", 1); // ADAPTER-COMPETITOR-PVT PADS
                break;
            case AnchorPackageTypes.EIIPadAdapter:
                PO.InsertPart(schema.groups.CenterPivot_AnchorPackage.groupId, "394332", 1); // ADPTR KIT-EII SG TO E60 SG PAD
                break;
        }
        switch (cp.PadBoltType) {
            case PadBoltTypes.a34x10:
                PO.InsertPart(schema.groups.CenterPivot_PadBolt.groupId, "354332", 1); // ANCHOR BOLT PKG (4) 3/4 X 10
                break;
            case PadBoltTypes.a1x9:
                PO.InsertPart(schema.groups.CenterPivot_PadBolt.groupId, "354333", 1); // ANCHOR BOLT PKG (4) 1 X 9
                break;
        }
    }
}