import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Feature } from "geojson";

import {
    bearing,
    booleanPointInPolygon,
    destination,
    multiLineString,
    point,
    sector
} from "@turf/turf";
import { ISpanVertex } from "rdptypes/geometry/systems/centerPivot/systemCoordinates";
import CenterPivotGeometryHelper, { IProperties } from "../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import * as Constants from "./copied-from-mapbox-gl-draw/constants";
import createVertex from "./copied-from-mapbox-gl-draw/create_vertex";
import doubleClickZoom from "./copied-from-mapbox-gl-draw/double_click_zoom";

import { isPartialPivot } from "rdptypes/helpers/system";
import { ECircleDirection, getWrapAngle } from "rdptypes/helpers/Wraps";
import ISystem from "rdptypes/project/ISystem";
import { WrapAroundSpanTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { v4 as uuidV4 } from "uuid";
import { IDrawUpdateExtEvent_CenterPivotUpdated, rdpFunctions } from ".";
import { IGeoJSONFeatureResult } from "../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper/interfaces";
import { getSystemValidity } from "../../GeometryHelpers/SystemGeometryHelpers/getSystemValidity";
import { IGetSystemValidityArgs } from "../../GeometryHelpers/SystemGeometryHelpers/interfaces";

const DirectSelectMode = MapboxDraw.modes.direct_select;
export const CenterPivotSelectMode = { ...DirectSelectMode };
export default CenterPivotSelectMode;

// Notes:
// This extension extends direct_select mode.
// It is intended to handle all interaction with center pivot systems
// Since the center pivot system comprises of multiple components/geometries (tracks, wraps, pivot center, etc..),
// it is perhaps easier to not render any of these system components, and instead render a simplified geometry which
// can be interacted with.
// So, onSetup (called when entering this mode), will create any additional geometry required. This geometry is deleted
// when leaving this mode.
// An custom event (draw.update_ext) is fired when leaving this mode, this can be used to react to the drawn changes

interface IOpts {
    definition: IProperties;
    startPos: any;
    coordPath: any;
}

interface IState {
    systemId: string;
    layoutId: string;
    systemValidityArgs: IGetSystemValidityArgs;
    
    featureId?: string;
    verticies?: ISpanVertex[];
    definition?: IProperties;
    wheelTrackFeatureId?: string;

    selectedCoordPaths: any;
    dragMoving: boolean;

    fire_center_pivot_updated?: boolean;

    dragId?: string;
    system: ISystem;
}

CenterPivotSelectMode.onSetup = function(this, opts: IOpts) {

    if (!opts.definition || !opts.definition.isCenterPivot || !opts.definition.systemId || !opts.definition.layoutId) {
        throw new Error('You must provide a center pivot feature to enter center_pivot_select mode');
    }
    const { systemId, layoutId } = opts.definition;

    const project = rdpFunctions(this).getProject();
    const system = project?.layouts[layoutId]?.systems[systemId];
    if (!system) {
        throw new Error('You must provide a valid center pivot system to enter center_pivot_select mode');
    }
    const gh = new CenterPivotGeometryHelper({ project, systemId, layoutId });
    const systemValidityArgs = gh.getSystemValidityArgs();

    const state = {
        dragMoveLocation: opts.startPos || null,
        dragMoving: false,
        canDragMove: false,
        selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
        systemId: systemId,
        layoutId: layoutId,
        systemValidityArgs,
        system: structuredClone(system)
    } as IState;

    this.setSelectedCoordinates([]);
    // this.setSelected(featureId);
    doubleClickZoom.disable(this);

    this.setActionableState({
        trash: true
    });
    
    this.map.fire("draw.update_ext", {
        action: "center_pivot_selected",
        definition:  opts.definition
    });
    return state;

  };

  CenterPivotSelectMode.toDisplayFeatures = function(this: MapboxDraw.DrawCustomModeThis, state: IState, feature: Feature, display) { 
    if (feature.properties.user_rdpFeatureType === 'obstacleClearance' && feature.properties.user_systemId === state.systemId) {
        // dont render the system obstacle boundary in this mode
    }
    else {
        display(feature);
    }
    const mp = rdpFunctions(this as any).getMapPermissions();
    if (feature.properties.user_isCenterPivotSelect &&  feature.properties.user_systemId === state.systemId && mp.editSystem(state.systemId)) {
        const definition = CenterPivotGeometryHelper.getDefinition(feature as any);
        if (definition) {
            feature.properties.active = Constants.activeStates.ACTIVE;
            const result = CenterPivotGeometryHelper.createSingleGeoJSONFeature(state.system, state.system.centerPivot.point);
            if (result) {

                const { verticies } = result;
                const supplementaryPoints = verticies.map((x, idx) => {
                    const vertex = createVertex(feature.properties.id, x.handle.coordinates, `0.${idx}`, true) as Feature;
                    switch (x.type) {
                        case 'anticlockwiseWrapSpan': {
                            const wrapAngle = getWrapAngle(state.system, state.system.FlangedSide.Tower[x.spanIndex], ECircleDirection.REV);
                            const label = `${wrapAngle.toFixed(1)}°`;
                            vertex.properties.user_label = label;
                            break;
                        }
                        case 'clockwiseWrapSpan': {
                            const wrapAngle = getWrapAngle(state.system, state.system.FlangedSide.Tower[x.spanIndex], ECircleDirection.FWD);
                            const label = `${wrapAngle.toFixed(1)}°`;
                            vertex.properties.user_label = label;
                            break;
                        }
                    }
                    return vertex;
                })
                supplementaryPoints.forEach(display);
                state.featureId = feature.properties.id;
                state.verticies = verticies;
                state.definition = definition;
                state.wheelTrackFeatureId = feature.properties.user_wheelTrackFeatureId;
            }
            if (!state.dragId) {
                const project = rdpFunctions(this).getProject();
                const system = project?.layouts[state.layoutId]?.systems[state.systemId];
                if (system) {
                    state.system = structuredClone(system);
                }
            }
        }
    }
    this.fireActionable(state);
};

CenterPivotSelectMode.onStop = function(this, state: IState) {
    doubleClickZoom.enable(this);
    this.clearSelectedCoordinates();    
};

CenterPivotSelectMode.onDrag = function(this,state: IState,e) {
    const { selectedCoordPaths } = state;
    const { lngLat } = e;
    if (selectedCoordPaths.length !== 1) return;

    const definition = state.definition;
    if (!definition) return;

    const selectedVertexIndexString = selectedCoordPaths[0].split(".")[1];
    if (!selectedVertexIndexString) return;

    const selectedVertex = state.verticies[parseInt(selectedVertexIndexString)] as ISpanVertex;
    if (!selectedVertex) return;

    const feature = this.getFeature(state.featureId);
    if (!feature) return;

    const movedPoint = point([ lngLat.lng, lngLat.lat ]);
    let undo: () => void = () => {};
    switch (selectedVertex.type) {
        case 'pivotCenter':
            const saved = state.system.centerPivot.point;
            state.system.centerPivot.point = movedPoint.geometry;
            const saved2 = state.systemValidityArgs;
            state.systemValidityArgs.sac = undefined;
            state.systemValidityArgs.center = movedPoint.geometry;
            undo = () => {
                state.system.centerPivot.point = saved;
                state.systemValidityArgs = saved2;
                state.systemValidityArgs.center = saved;
            }
            break;
        case 'clockwiseCompassHeadingStart': {
            let movedBearing = bearing(
                selectedVertex.center,
                movedPoint,
                { final: true }
            )
            movedBearing = Math.round(movedBearing * 10) / 10;
            const saved = state.system.Circle!.CenterPivot!.clockwiseCompassHeadingStart;
            state.system.Circle!.CenterPivot!.clockwiseCompassHeadingStart = movedBearing;
            const saved2 = state.systemValidityArgs;
            state.systemValidityArgs.sac = undefined;
            undo = () => {
                state.system.Circle!.CenterPivot!.clockwiseCompassHeadingStart = saved;
                state.systemValidityArgs = saved2;
            }
            break;
        }
        case 'clockwiseCompassHeadingEnd': {
            let movedBearing = bearing(
                selectedVertex.center,
                movedPoint,
                { final: true }
            )
            movedBearing = Math.round(movedBearing * 10) / 10;
            const saved = state.system.Circle!.CenterPivot!.clockwiseCompassHeadingEnd;
            state.system.Circle!.CenterPivot!.clockwiseCompassHeadingEnd = movedBearing;
            const saved2 = state.systemValidityArgs;
            state.systemValidityArgs.sac = undefined;
            undo = () => {
                state.system.Circle!.CenterPivot!.clockwiseCompassHeadingEnd = saved;
                state.systemValidityArgs = saved2;
            }
            break;
        }
        case 'anticlockwiseWrapSpan': {
            const startBearing = bearing(
                selectedVertex.center,
                selectedVertex.handle,
                { final: true }
            )
            const movedBearing = bearing(
                selectedVertex.center,
                movedPoint,
                { final: true }
            )
            const otherWrapAngle = state.system.FlangedSide.Tower[selectedVertex.spanIndex].clockwiseWrapAngleRelativeToPreviousSpanDegrees || 0;
            const wrapMax = otherWrapAngle <= 90 ? 110 : 90;
            const startWrapAngle = state.system.FlangedSide.Tower[selectedVertex.spanIndex].anticlockwiseWrapAngleRelativeToPreviousSpanDegrees || 0;
            let movedWrapAngle = startWrapAngle - movedBearing + startBearing;
            movedWrapAngle = (movedWrapAngle + 360) % 360;
            movedWrapAngle = Math.round(movedWrapAngle * 10) / 10;
            if (movedWrapAngle < 0) movedWrapAngle = 0;
            if (movedWrapAngle > wrapMax) movedWrapAngle = wrapMax;
            const saved2 = state.systemValidityArgs;
            state.systemValidityArgs.sac = undefined;
            const saved = state.system.FlangedSide.Tower[selectedVertex.spanIndex].anticlockwiseWrapAngleRelativeToPreviousSpanDegrees;
            const savedType = state.system.FlangedSide.Tower[selectedVertex.spanIndex].WrapAroundSpan;
            state.system.FlangedSide.Tower[selectedVertex.spanIndex].anticlockwiseWrapAngleRelativeToPreviousSpanDegrees = movedWrapAngle;
            const maxWrapAngle = Math.max(otherWrapAngle, movedWrapAngle);
            if (maxWrapAngle <= 10) {
                state.system.FlangedSide.Tower[selectedVertex.spanIndex].WrapAroundSpan = WrapAroundSpanTypes.TenDegree;
            }
            else {
                state.system.FlangedSide.Tower[selectedVertex.spanIndex].WrapAroundSpan = WrapAroundSpanTypes.NinetyDegree;
            }
            undo = () => {
                state.system.FlangedSide.Tower[selectedVertex.spanIndex].anticlockwiseWrapAngleRelativeToPreviousSpanDegrees = saved;
                state.system.FlangedSide.Tower[selectedVertex.spanIndex].WrapAroundSpan = savedType;
                state.systemValidityArgs = saved2;
            }
            break;
        }
        case 'clockwiseWrapSpan': {
            const startBearing = bearing(
                selectedVertex.center,
                selectedVertex.handle,
                { final: true }
            )
            const movedBearing = bearing(
                selectedVertex.center,
                movedPoint,
                { final: true }
            )
            const otherWrapAngle = state.system.FlangedSide.Tower[selectedVertex.spanIndex].anticlockwiseWrapAngleRelativeToPreviousSpanDegrees || 0;
            const wrapMax = otherWrapAngle <= 90 ? 110 : 90;
            const startWrapAngle = state.system.FlangedSide.Tower[selectedVertex.spanIndex].clockwiseWrapAngleRelativeToPreviousSpanDegrees || 0;
            let movedWrapAngle = startWrapAngle + movedBearing - startBearing;
            movedWrapAngle = (movedWrapAngle + 360) % 360;
            movedWrapAngle = Math.round(movedWrapAngle * 10) / 10;
            if (movedWrapAngle < 0) movedWrapAngle = 0;
            if (movedWrapAngle > wrapMax) movedWrapAngle = wrapMax;
            const saved = state.system.FlangedSide.Tower[selectedVertex.spanIndex].clockwiseWrapAngleRelativeToPreviousSpanDegrees;
            const savedType = state.system.FlangedSide.Tower[selectedVertex.spanIndex].WrapAroundSpan;
            state.system.FlangedSide.Tower[selectedVertex.spanIndex].clockwiseWrapAngleRelativeToPreviousSpanDegrees = movedWrapAngle;
            const maxWrapAngle = Math.max(otherWrapAngle, movedWrapAngle);
            const saved2 = state.systemValidityArgs;
            state.systemValidityArgs.sac = undefined;
            if (maxWrapAngle <= 10) {
                state.system.FlangedSide.Tower[selectedVertex.spanIndex].WrapAroundSpan = WrapAroundSpanTypes.TenDegree;
            }
            else {
                state.system.FlangedSide.Tower[selectedVertex.spanIndex].WrapAroundSpan = WrapAroundSpanTypes.NinetyDegree;
            }
            undo = () => {
                state.system.FlangedSide.Tower[selectedVertex.spanIndex].clockwiseWrapAngleRelativeToPreviousSpanDegrees = saved;
                state.system.FlangedSide.Tower[selectedVertex.spanIndex].WrapAroundSpan = savedType;
                state.systemValidityArgs = saved2;
            }
            break;
        }
        case 'dropSpanStart': {
            let dropEndSweepAngleFromFirstSpan = 0;
            selectedVertex.segments.forEach(s => dropEndSweepAngleFromFirstSpan += s.sweepAngle);
            dropEndSweepAngleFromFirstSpan -= state.system.FlangedSide.Span[selectedVertex.spanIndex].dropSpanEndRelativeToPreviousSpanEnd || 0;
            dropEndSweepAngleFromFirstSpan -= 1;

            const availableDegrees = dropEndSweepAngleFromFirstSpan;

            let offset = 0;
            let iSegment = 0;
            while (iSegment < selectedVertex.segments.length) {
                const segment = selectedVertex.segments[iSegment];
                const movedBearing = bearing(
                    segment.center,
                    movedPoint,
                    { final: true }
                )
                const movedTempPoint = destination(
                    segment.center,
                    10,
                    movedBearing,
                    { units: 'feet' }
                )
                const segmentSector = sector(
                    segment.center,
                    segment.outerRadiusFeet,
                    segment.startBearing,
                    segment.startBearing + segment.sweepAngle,
                    { units: 'feet' }
                )
                if (booleanPointInPolygon(movedTempPoint, segmentSector)) {
                    const localOffset = (movedBearing-segment.startBearing +360)%360;
                    const updatedStart = Math.round((offset + localOffset) * 10) / 10;
                    const isPP = isPartialPivot(state.system);
                    if (!isPP || availableDegrees > updatedStart) {
                        const saved = state.system.FlangedSide.Span[selectedVertex.spanIndex].dropSpanStartRelativeToPreviousSpanStart;
                        state.system.FlangedSide.Span[selectedVertex.spanIndex].dropSpanStartRelativeToPreviousSpanStart = updatedStart;
                        const saved2 = state.systemValidityArgs;
                        state.systemValidityArgs.sac = undefined;
                        undo = () => {
                            state.system.FlangedSide.Span[selectedVertex.spanIndex].dropSpanStartRelativeToPreviousSpanStart = saved;
                            state.systemValidityArgs = saved2;
                        }
                    }
                    break;
                }
                offset += segment.sweepAngle;
                iSegment++;
            }
            
            break;
        }
        case 'dropSpanEnd': {
            let dropEndSweepAngleFromLastSpan = 0;
            selectedVertex.segments.forEach(s => dropEndSweepAngleFromLastSpan += s.sweepAngle);
            dropEndSweepAngleFromLastSpan -= state.system.FlangedSide.Span[selectedVertex.spanIndex].dropSpanStartRelativeToPreviousSpanStart || 0;
            dropEndSweepAngleFromLastSpan -= 1;
            
            const availableDegrees = dropEndSweepAngleFromLastSpan;

            let offset = 0;
            let iSegment = selectedVertex.segments.length - 1;
            while (iSegment >= 0) {
                const segment = selectedVertex.segments[iSegment];
                const movedBearing = bearing(
                    segment.center,
                    movedPoint,
                    { final: true }
                )
                const movedTempPoint = destination(
                    segment.center,
                    10,
                    movedBearing,
                    { units: 'feet' }
                )
                const segmentSector = sector(
                    segment.center,
                    segment.outerRadiusFeet,
                    segment.startBearing,
                    segment.startBearing + segment.sweepAngle,
                    { units: 'feet' }
                )
                if (booleanPointInPolygon(movedTempPoint, segmentSector)) {
                    const localOffset = (segment.startBearing+segment.sweepAngle -movedBearing +360)%360;
                    const updatedEnd = Math.round((offset + localOffset) * 10) / 10;
                    const isPP = isPartialPivot(state.system);
                    if (!isPP || availableDegrees > updatedEnd) {
                        const saved = state.system.FlangedSide.Span[selectedVertex.spanIndex].dropSpanEndRelativeToPreviousSpanEnd;
                        state.system.FlangedSide.Span[selectedVertex.spanIndex].dropSpanEndRelativeToPreviousSpanEnd = updatedEnd;
                        const saved2 = state.systemValidityArgs;
                        state.systemValidityArgs.sac = undefined;
                        undo = () => {
                            state.system.FlangedSide.Span[selectedVertex.spanIndex].dropSpanEndRelativeToPreviousSpanEnd = saved;
                            state.systemValidityArgs = saved2;
                        }
                    }
                    break;
                }
                offset += segment.sweepAngle;
                iSegment--;
            }
            
            break;
        }
        default:
            break;
    }
    let result: IGeoJSONFeatureResult | undefined = undefined;
    try {
        result = CenterPivotGeometryHelper.createSingleGeoJSONFeature(state.system, state.system.centerPivot.point);
    }
    catch (e) {
        console.log("Caught error in CenterPivotSelectMode.onDrag: CenterPivotGeometryHelper.createSingleGeoJSONFeature", e)
        // result will remain undefined
    }
    if (result && result.feature && (result.feature.geometry.type === 'Polygon' || result.feature.geometry.type === 'Point')) {
        state.verticies = result.verticies;
        feature.incomingCoords(result.feature.geometry.coordinates)
        state.fire_center_pivot_updated = true;
        const wtf = state.wheelTrackFeatureId ? this.getFeature(state.wheelTrackFeatureId) : undefined;
        if (result.wheelTracks && wtf) {
            const wt = multiLineString(result.wheelTracks.map(x => x.geometry.coordinates));
            wtf.incomingCoords(wt.geometry.coordinates);
        }
        if (result.feature.geometry.type === 'Polygon') {
            const validity = getSystemValidity(
                state.layoutId,
                state.systemId,
            {
                ...state.systemValidityArgs,
                systemAreaPolygon: result.feature.geometry,
                center: state.system.centerPivot.point,
                wheelTracks: result.wheelTracks.map(x => x.geometry),
                sac: undefined,
                sacResult: undefined,
            });
            feature.setProperty("validity", validity);
            feature.setProperty("dragId", state.dragId);
        }
        else {
            feature.setProperty("validity", 'critical');
        }

        {
            // The following will hide some features which become out of sync when the pivot is modified. This is achieved
            // by setting the rdpFeatureType to a non-existant type ("hide"). Namely, this effects the follwing features:
            // - SAC irrigated area and wheel tracks
            // - End guns
            const fs = this.map.queryRenderedFeatures();
            for (const f of fs) {
                if (
                    f.properties.user_systemId === state.systemId && (
                        f.properties.user_rdpFeatureType === "system/irrigatedArea-sac" || 
                        f.properties.user_rdpFeatureType === "system/wheelTrack" || 
                        f.properties.user_rdpFeatureType === "system/irrigatedArea-endGun"
                    )
                ) {
                    const ff = this.getFeature(f.properties?.id as string);
                    if (ff) {
                        ff.setProperty("rdpFeatureType", 'hide');
                        ff.setProperty("validity", 'hide');
                        ff.changed();
                    }
                }
            }
        }
    }
    else {
        undo();
        feature.changed();
    }
}

CenterPivotSelectMode.onTouchEnd = CenterPivotSelectMode.onMouseUp = function(this, state: IState) {
    state.dragId = undefined;
    if (state.fire_center_pivot_updated) {
        this.map.fire("draw.update_ext", {
            action: "center_pivot_updated",
            definition: state.definition,
            updatedSystem: state.system
        } as IDrawUpdateExtEvent_CenterPivotUpdated);
        state.fire_center_pivot_updated = false;
    }
    if (state.dragMoving) {
      this.fireUpdate();
    }
    this.stopDragging(state);
};
  
CenterPivotSelectMode.onTouchStart = CenterPivotSelectMode.onMouseDown = function (this, state: IState, e) {
    mutateEventForVertexFeature(this, e);
    state.dragId = uuidV4();
    try {
        DirectSelectMode.onTouchStart?.call(this, state, e)
    }
    catch(e) {
        console.log("Caught error in CenterPivotSelectMode.onTouchStart", e);
        this.clearSelectedCoordinates();
    }
}

// Note, other features can be rendered above the verticies for this feature. I am not sure how to ensure the 
// vertex z-ordering, so for now mouse locations are inspected, and if there is a vertex at the event
// location, the event feature is replaced
const mutateEventForVertexFeature = (that: MapboxDraw.DrawCustomModeThis & MapboxDraw.DrawCustomMode<any, any>, e: MapboxDraw.MapMouseEvent) => {
    if (e.featureTarget?.properties?.meta === 'vertex') return;
    // +/- 5 pixles
    const bbox = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5]
    ];
    const f = that.map.queryRenderedFeatures(bbox).filter(f => f.properties.meta === "vertex");
    if (f.length) {
        e.featureTarget = f[0]
    }
}