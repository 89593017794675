import { IAdditionalSpanData, IIrrigationProperties, IOutlet } from "rdptypes/project/ISprinklerChart";
import ISprinklers, { BoomBackTypes, DeviceTypes, DropTypes, IPackage, LDNChemPadTypes, LDNTypes, RegulatorLocations, RegulatorTypes, RigidDropTypes } from "rdptypes/project/ISprinklers";
import { BoosterPumpTypes, EndGunTypes, HoseFeedTypes, ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { WarningSeverity, WarningTypes } from "rdptypes/project/SprinklerEngineTypes/Enums";
import { IEndGun } from "rdptypes/project/Types";
import oldRoeEmulation from "../../oldRoeEmulation";
import { MaxPackages } from "../CommonConstants";
import { EstEndGunCoverage } from "../CommonFunctions";
import { EndGunHelper } from "../EndGunHelper";
import * as othf from "../OtherHelpers";
import * as sidef from "../SideFunctions";
import * as spanf from "../SpanFunctions";
import { SprinklerEngine } from "../SprinklerEngine";
import * as sysf from "../SystemFunctions";
import { Side, TOutlets, TPackages, TSpans } from "../Types";
import { OutletRecord } from "./OutletRecord";
import { SprinklerEngineResult } from "./SprinklerEngineResult";

export class SprinklerEngineRunner {
    private System: ISystemBase;
    private RightSideSprinklerEngine: SprinklerEngine;
    private LeftSideSprinklerEngine: SprinklerEngine;
    private CalcRightSideGPMForCenterFeed = (TotalGPM: number): number => {
        let sCoverage: number;
        let sOppositeCoverage: number = 0;
        let flexSide: Side = this.System.FlexSide;
        if (flexSide.Span.length > 0) {
            sOppositeCoverage = spanf.EndingRadius(this.System, flexSide, flexSide.Span[flexSide.Span.length - 1]) + EstEndGunCoverage(flexSide.EndOfSystem.EndGun.EndGunTypePrimary, false);
        }
        if (sOppositeCoverage > 0) {
            let flangeSide: Side = this.System.FlangedSide;
            if (flangeSide.Span.length > 0) {
                sCoverage = spanf.EndingRadius(this.System, flangeSide, flangeSide.Span[flangeSide.Span.length - 1]) + EstEndGunCoverage(flangeSide.EndOfSystem.EndGun.EndGunTypePrimary, false);
            }
            return TotalGPM * (sCoverage / (sCoverage + sOppositeCoverage));
        } else {
            return TotalGPM;
        }
    }

    private LoadDataIntoSprinklerEngine = (tag: String) => {
        let SE: SprinklerEngine;
        let Spans: TSpans;
        let Outlets: TOutlets;
        let Packages: TPackages;
        let Sprinklers: ISprinklers;
        let iSpanCount: number;
        let iTowerCount: number;
        let iOutletCount: number;
        let i: number;
        if (tag === "R") {
            SE = this.RightSideSprinklerEngine;
            SE.Side = this.System.FlangedSide;
            let flangeSide: Side = this.System.FlangedSide;
            iTowerCount = flangeSide.Tower.length;
            Spans = sidef.Spans(this.System, flangeSide);
            Sprinklers = flangeSide.Sprinklers;
            Outlets = othf.Outlets(flangeSide.SprinklerChart);
            Packages = othf.Packages(Sprinklers);
            if (sysf.FieldSets(this.System).SwingArm.DataValid()) {
                SE.SystemInfo.HasESAC = true;
                SE.SystemInfo.ZoneControlType = this.System.Circle.SwingArm.ZoneControlType;
                SE.SystemInfo.DistributionFlowRate = this.System.Circle.SwingArm.DistributionFlowRate;
                SE.SystemInfo.SwingArmLength = this.System.FlangedSide.EndOfSystem.SwingArmLength;
                SE.ZoneFactors.LoadESAC125Factors(this.System.Circle.SwingArm.ESAC125DistributionFlowRateFactors);
            }
            const endGun = flangeSide.EndOfSystem.EndGun;
            if (endGun.EndGunTypePrimary === EndGunTypes.DualP85) {
                SE.EndGunInfo.EndGun = EndGunTypes.SingleP85;
            } else {
                SE.EndGunInfo.EndGun = endGun.EndGunTypePrimary;
            }
            SE.EndGunInfo.BoosterPump = endGun.BoosterPump;
            if (sysf.FieldSets(this.System).ControlPanel.DataValid()) {
                SE.EndGunInfo.FrequencyHz = this.System.ControlPanel.ElectricalFrequency;
            }
            const sp = this.System.SprinklerProperties;
            SE.EngineInfo.EndPressure = sp.DesignedEndPressure;
            SE.PivotPressure = 0;
            SE.EngineInfo.TotalGPM = Math.round(sp.TotalGPM);
            SE.EngineInfo.Elevation = Math.round(sp.PeakElevation);
            if (sysf.IsDualSided(this.System)) {
                SE.EngineInfo.SideGPM = Math.round(this.CalcRightSideGPMForCenterFeed(sp.TotalGPM));
            } else {
                SE.EngineInfo.SideGPM = Math.round(sp.TotalGPM);
            }
            SE.SystemInfo.DesignedEndPressure = sp.DesignedEndPressure;
        } else {
            SE = this.LeftSideSprinklerEngine;
            SE.Side = this.System.FlexSide;
            let flexSide: Side = this.System.FlexSide;
            if (sysf.HasPowerTowerEndBoom(this.System)) {
                iTowerCount = 0;
            } else {
                iTowerCount = flexSide.Tower.length;
            }
            Spans = sidef.Spans(this.System, flexSide);
            Sprinklers = flexSide.Sprinklers;
            Outlets = othf.Outlets(flexSide.SprinklerChart);
            Packages = othf.Packages(Sprinklers);
            const endGun = flexSide.EndOfSystem.EndGun;
            if (endGun.EndGunTypePrimary === EndGunTypes.DualP85) {
                SE.EndGunInfo.EndGun = EndGunTypes.SingleP85;
            } else {
                SE.EndGunInfo.EndGun = endGun.EndGunTypePrimary;
            }
            SE.EndGunInfo.BoosterPump = endGun.BoosterPump;
            SE.EndGunInfo.FrequencyHz = this.System.ControlPanel.ElectricalFrequency;
            SE.PivotPressure = this.RightSideSprinklerEngine.PivotPressure;
            SE.EngineInfo.TotalGPM = this.RightSideSprinklerEngine.EngineInfo.TotalGPM;
            SE.EngineInfo.SideGPM = this.RightSideSprinklerEngine.EngineInfo.TotalGPM - this.RightSideSprinklerEngine.EngineInfo.SideGPM;
            SE.EngineInfo.Elevation = this.RightSideSprinklerEngine.EngineInfo.Elevation;
        }
        iSpanCount = Spans.Count;
        iOutletCount = Outlets.Count;
        SE.SystemInfo.System = this.System;
        SE.SystemInfo.HasSACESP = sysf.HasEnergySaverPackage(this.System);
        SE.SystemInfo.IsLateralMove = sysf.IsMaxigator(this.System);
        switch (this.System.SystemProperties.SystemType) {
            case SystemTypes.CenterPivot:
            case SystemTypes.KwikTow:
                SE.SystemInfo.StartingLocation = 2;
                break;
            default:
                SE.SystemInfo.StartingLocation = 0;
                break;
        }
        if (this.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && this.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
            SE.SystemInfo.HasDualSprinklerPackage = this.System.SprinklerProperties.DualSprinklerPackage;
        } else {
            SE.SystemInfo.HasDualSprinklerPackage = false;
        }
        for (i = 1; i <= iSpanCount; i++) {
            const s = Spans.Span[i - 1];
            if (i === 1) {
                SE.AddSpan(s, spanf.PipeInsideDiameter(this.System, SE.Side, s), spanf.CFactor(this.System, SE.Side, s), 0, 0, spanf.EndingLocation(this.System, SE.Side, s), spanf.EndingRadius(this.System, SE.Side, s));
            } else {
                SE.AddSpan(s, spanf.PipeInsideDiameter(this.System, SE.Side, s), spanf.CFactor(this.System, SE.Side, s), spanf.StartingLocation(this.System, SE.Side, s), spanf.StartingRadius(this.System, SE.Side, s), spanf.EndingLocation(this.System, SE.Side, s), spanf.EndingRadius(this.System, SE.Side, s));
            }
        }
        for (i = 1; i <= MaxPackages; i++) {
            if (i <= Packages.Count) {
                const pkg = Packages.Package[i - 1];
                SE.Package[i].DeviceType = pkg.Device;
                SE.Package[i].UseNelson3030 = pkg.UseNelson3030;
                SE.Package[i].UseNelsonAD3030MT = pkg.UseNelsonAD3030MT;
                let reg = pkg.Regulator;
                SE.Package[i].RegulatorPSI = reg.Pressure;
                SE.Package[i].RegulatorType = reg.RegulatorType;
                SE.Package[i].ThreadType = reg.Thread;
                SE.Package[i].RegsAsNeeded = reg.AsNeeded;
                SE.Package[i].Drops = pkg.Drop;
                SE.Package[i].HDUPipeReach = pkg.HoseDrop?.UPipeReach;
                SE.Package[i].NormalSpacing = pkg.NormalSpacing;
                SE.Package[i].DevicesDoubled = pkg.DevicesDoubled;
                SE.Package[i].End = pkg.EndingLocation * 120;
                SE.Package[i].Spacing = pkg.Spacing * 10;
                switch (pkg.Drop) {
                    case DropTypes.Hose:
                        SE.Package[i].GroundClearance = pkg.HoseDrop.GroundClearance;
                        SE.Package[i].IsRegOnTop = pkg.HoseDrop.RegulatorLocation === RegulatorLocations.Top;
                        break;
                    case DropTypes.Rigid:
                        switch (pkg.RigidDrop.DropType) {
                            case RigidDropTypes.Fixed:
                                SE.Package[i].FixedDropLength = pkg.RigidDrop.Length;
                                break;
                            case RigidDropTypes.Variable:
                                SE.Package[i].GroundClearance = pkg.RigidDrop.GroundClearance;
                                break;
                        }
                        break;
                    case DropTypes.None:
                        SE.Package[i].GroundClearance = 0;
                        break;
                }
            } else {
                SE.Package[i].DeviceType = DeviceTypes.None;
            }
        }
        for (i = 1; i <= iOutletCount; i++) {
            const o = Outlets.Outlet[i - 1];
            SE.AddOutlet(o, Spans.Span[o.SpanNumber], null);
        }
        const b = Sprinklers.BoomBacks;
        switch (b?.BoomBackType ?? BoomBackTypes.None) {
            case BoomBackTypes.None:
            case BoomBackTypes.Standard:
                SE.EngineInfo.TowerBoomBackStart = 0;
                break;
            case BoomBackTypes.Tower:
                SE.EngineInfo.TowerBoomBackStart = b.FirstTower;
                SE.EngineInfo.BoomBackDevice = b.Device;
                if (b.FirstTower > 0) {
                    SE.EngineInfo.TowerBoomBackEnd = iTowerCount;
                } else {
                    SE.EngineInfo.TowerBoomBackEnd = 0;
                }
                break;
        }
    }

    private UnloadDataFromSprinklerEngine = (tag: String) => {
        let SE: SprinklerEngine;
        let Spans: TSpans;
        let Packages: TPackages;
        let Outlets: TOutlets;
        let IP: IIrrigationProperties;
        let EndGun: IEndGun;
        let AdditionalSpanData: IAdditionalSpanData[];
        if (tag === "R") {
            SE = this.RightSideSprinklerEngine;
            let flangeSide: Side = this.System.FlangedSide;
            Spans = sidef.Spans(this.System, flangeSide);
            Packages = othf.Packages(flangeSide.Sprinklers);
            Outlets = othf.Outlets(flangeSide.SprinklerChart);
            IP = flangeSide.SprinklerChart.IrrigationProperties ?? {};
            EndGun = flangeSide.EndOfSystem.EndGun;
            AdditionalSpanData = flangeSide.SprinklerChart.AdditionalSpanData = [];
            this.System.SprinklerProperties.PivotPressure = SE.PivotPressure;
            this.System.SprinklerProperties.MaximumSystemGPM = SE.EngineInfo.MaximumSystemGPM;
            this.System.SprinklerProperties.MaximumTopInletPSI = SE.EngineInfo.MaximumTopInletPSI;
            this.System.SprinklerProperties.MinimumSystemGPM = SE.EngineInfo.MinimumSystemGPM;
            this.System.SprinklerProperties.SystemPressureLoss = SE.EngineInfo.SystemPressureLoss;
            this.System.SprinklerProperties.SystemPressureLossAtMaxSystemGPM = SE.EngineInfo.SystemPressureLossAtMaxSystemGPM;
            this.System.SprinklerProperties.DesignedEndPressure = SE.EngineInfo.EndPressure;
        } else {
            SE = this.LeftSideSprinklerEngine;
            let flexSide: Side = this.System.FlexSide;
            Spans = sidef.Spans(this.System, flexSide);
            Packages = othf.Packages(flexSide.Sprinklers);
            Outlets = othf.Outlets(flexSide.SprinklerChart);
            IP = flexSide.SprinklerChart.IrrigationProperties ?? {};
            EndGun = flexSide.EndOfSystem.EndGun;
            AdditionalSpanData = flexSide.SprinklerChart.AdditionalSpanData = [];
            this.System.SprinklerProperties.MaximumSystemGPM += SE.EngineInfo.MaximumSystemGPM;
            this.System.SprinklerProperties.MinimumSystemGPM += SE.EngineInfo.MinimumSystemGPM;
        }
        IP.CoverageRadiusWithEndGun = SE.Coverage() / 120;
        IP.CoverageRadiusWithoutEndGun = SE.SystemCoverageRadius / 120;
        IP.CoverageRadiusWithoutSwingArm = SE.ParentSystemCoverageRadius / 120;
        IP.ComputedEndPressure = SE.EngineInfo.EndPressure;
        IP.PressureExceededCount = SE.PressureExceededCount;
        IP.PressureNotMetCount = SE.PressureNotMetCount;
        IP.SideGPM = SE.EngineInfo.SideGPM;
        if (EndGun.EndGunTypePrimary !== EndGunTypes.None) {
             IP.EndGunIrrigationProperties = {
                LimitedByBoosterPump: SE.EndGunInfo.EGH.BoosterPumpLimitExceeded,
                GPMDelivered: SE.EndGunInfo.EGH.GPM,
                GPMRequired: SE.EndGunInfo.EGH.RequiredEndGunGPM,
                Pressure: SE.EndGunInfo.EGH.Pressure,
                EndGunTypeCalculated: SE.EndGunInfo.EGH.EndGunType,
                DiffuserCalculated: SE.EndGunInfo.EGH.UseDiffuser,
                NozzleSize: SE.EndGunInfo.EGH.NozzleDiameter,
                Radius: SE.EndGunInfo.EGH.Radius
            };
        } else {
            IP.EndGunIrrigationProperties = {
                LimitedByBoosterPump: false,
                GPMDelivered: 0,
                GPMRequired: 0,
                Pressure: 0,
                EndGunTypeCalculated: EndGunTypes.None,
                DiffuserCalculated: false,
                NozzleSize: 0,
                Radius: 0
            };
        }
        if (EndGun.EndGunTypeSecondary !== EndGunTypes.None) {
            let EGH2 = new EndGunHelper(SE.SystemInfo.IsLateralMove, SE.EngineInfo.SideGPM, BoosterPumpTypes.None, SE.EndGunInfo.FrequencyHz, SE.SystemPipeRadius, SE.SystemCoverageRadius, SE.EngineInfo.EndPressure, EndGun.EndGunTypeSecondary, false);
            IP.CoverageRadiusWithSecondaryEndGun = SE.SystemPipeRadius / 120 + EGH2.Radius;
            EGH2.PrimaryGunRadius = SE.EndGunInfo.EGH.Radius;

            IP.SecondaryEndGunIrrigationProperties = {
                GPMDelivered: EGH2.GPM,
                GPMRequired: EGH2.RequiredEndGunGPM,
                Pressure: EGH2.Pressure,
                EndGunTypeCalculated: EGH2.EndGunType,
                DiffuserCalculated: EGH2.UseDiffuser,
                NozzleSize: EGH2.NozzleDiameter,
                Radius: EGH2.Radius
            };
        } else {
            IP.SecondaryEndGunIrrigationProperties = {
                LimitedByBoosterPump: false,
                GPMDelivered: 0,
                GPMRequired: 0,
                Pressure: 0,
                EndGunTypeCalculated: EndGunTypes.None,
                DiffuserCalculated: false,
                NozzleSize: 0,
                Radius: 0
            };
        }
        let iSpanCount: number = Spans.Count;

        for (let i: number = 1; i <= iSpanCount; i++) {
            let s: IAdditionalSpanData = {
                GPMDelivered: SE.SpanGPMDelivered(i),
                GPMRequired: SE.SpanGPMRequired(i)
            };
            AdditionalSpanData.push(s);
        }
        let iOutletCount: number = Outlets.Count;
        for (let i: number = 1; i <= iOutletCount; i++) {
            let o: IOutlet = Outlets.Outlet[i - 1];
            let oRec: OutletRecord = SE.Outlets[i];
            if (oRec.IsInUse) {
                let po: IPackage = Packages.Package[oRec.PackageNumber - 1];
                o.Device = oRec.DeviceType;
                o.UseNelson3030 = oRec.UseNelson3030;
                o.UseNelsonAD3030MT = oRec.UseNelsonAD3030MT;
                o.DeviceDoubled = oRec.DeviceDoubled;
                o.ESP = oRec.IsESPOutlet;
                o.NozzleIndex = oRec.NozzleUsed;
                o.MaximumSpacingSetting = oRec.MaximumSpacingSetting / 120;
                o.GPMRequired = oRec.GPMRequired;
                o.GPMDelivered = oRec.GPMDelivered;
                o.Pressure = oRec.PressureAtOutlet;
                o.DownstreamGPM = oRec.DownstreamGPM;
                o.GPMRequiredNonAdjusted = oRec.GPMReqNonAdjusted;
                o.FurrowArmAdjustment = oRec.FurrowArmAdjustment / 120;
                o.NozzleClip = po.NozzleClip;
                o.TrashBusterBody = po.TrashBusterBody;
                o.Valve = po.Valve;
                o.SuperSprayPad = po.SuperSprayPad;
                o.Drop = po.Drop;
                o.Fitting.FittingType = po.Fitting?.FittingType;
                o.Fitting.DropNextToEndGun = po.Fitting?.DropNextToEndGun;
                o.Regulator.Pressure = oRec.RegulatorPSI;
                o.Regulator.RegulatorType = oRec.RegulatorType;
                o.Regulator.Thread = po.Regulator?.Thread;
                o.HoseDrop.UPipeReach = po.HoseDrop?.UPipeReach;
                o.HoseDrop.UPipeFitting = po.HoseDrop?.UPipeFitting;
                o.HoseDrop.UPipeType = po.HoseDrop?.UPipeType;
                o.HoseDrop.RegulatorLocation = po.HoseDrop?.RegulatorLocation;
                o.HoseDrop.HoseTopFitting = po.HoseDrop?.HoseTopFitting;
                o.HoseDrop.WeightBottomFitting = po.HoseDrop?.WeightBottomFitting;
                o.HoseDrop.HoseTopClamp = po.HoseDrop?.HoseTopClamp;
                o.HoseDrop.HoseBottomClamp = po.HoseDrop?.HoseBottomClamp;
                o.HoseDrop.Weight = po.HoseDrop?.Weight;
                o.HoseDrop.WeightTopFitting = po.HoseDrop?.WeightTopFitting;
                o.HoseDrop.DragAdapter = po.HoseDrop?.DragAdapter;
                o.HoseDrop.DragSock = po.HoseDrop?.DragSock;
                o.HoseDrop.ReinkeBlue = po.HoseDrop?.ReinkeBlue;
                o.HoseDrop.UseKometTrussRodSlings = po.HoseDrop?.UseKometTrussRodSlings;
                o.HoseDrop.ScrewClamp = po.HoseDrop?.ScrewClamp;
                o.HoseDrop.SubstituteSTxHB = po.HoseDrop?.SubstituteSTxHB;
                o.HoseDrop.GroundClearance = po.HoseDrop?.GroundClearance;
                o.HoseDrop.DeviceWeight = po.HoseDrop?.DeviceWeight;
                o.RigidDrop.DropType = po.RigidDrop?.DropType;
                o.RigidDrop.Length = po.RigidDrop?.Length;
                o.RigidDrop.DropMaterial = po.RigidDrop?.DropMaterial;
                o.RigidDrop.UPipeMaterial = po.RigidDrop?.UPipeMaterial;
                o.RigidDrop.BottomFitting = po.RigidDrop?.BottomFitting;
                o.RigidDrop.ReinforcementClamp = po.RigidDrop?.ReinforcementClamp;
                if (o.RigidDrop.DropType === RigidDropTypes.Fixed) {
                    o.RigidDrop.GroundClearance = oRec.AbsoluteHeight - SE.Package[po.PackageNumber].FixedDropLength;
                } else {
                    o.RigidDrop.GroundClearance = po.RigidDrop?.GroundClearance;
                }
                o.RigidDrop.DeviceWeight = po.RigidDrop?.DeviceWeight;
                o.D3000.Plate1 = po.D3000?.Plate1;
                o.D3000.Plate2 = po.D3000?.Plate2;
                o.D3000.Plate3 = po.D3000?.Plate3;
                o.D3000.BubblerClipLEPA = po.D3000?.BubblerClipLEPA;
                o.D3000.SprinklerConverter = po.D3000?.SprinklerConverter;
                o.LDN.ChemPad = po.LDN?.ChemPad;
                o.LDN.Pad = po.LDN?.Pad;
                o.LDN.LDNType = po.LDN?.LDNType;
                o.LDN.BubblerShroud = po.LDN?.BubblerShroud;
                o.KometSpray.Pad = po.KometSpray?.Pad;

                // Calculate properties directly which are calculated using computed values in ROE
                o.DeviceHeight = this.GetDeviceHeight(o);

                if (o.Drop !== DropTypes.None) {
                    if (o.ESP) o.TopOfDropHeight = o.AbsoluteHeight + 0.5;
                    else o.TopOfDropHeight = o.AbsoluteHeight;

                    
                    let sGroundClearance = 0;
                    if (o.Drop === DropTypes.Rigid){
                        sGroundClearance = o.RigidDrop.GroundClearance;
                    }
                    else {
                        sGroundClearance = o.HoseDrop.GroundClearance;
                    }

                    o.DropLength = o.TopOfDropHeight - o.DeviceHeight - sGroundClearance;
                }
            }
        }
    }
    
    private LDNExtraPadCount = (outlet: IOutlet) => {
        let k = 0;
        if (outlet.LDN.ChemPad != LDNChemPadTypes.None) k++;
        if (outlet.LDN.LDNType === LDNTypes.MultiPad){
            if (outlet.NozzleIndex < 32){
                k++;
            }
            else if (outlet.NozzleIndex < 45){
                k += 2;
            }
        }

        return k;
    }

    public GetDeviceHeight = (outlet: IOutlet) => {
        let sDeviceHeight: number = 0;

        switch (outlet.Device){
            case DeviceTypes.NelsonR3000RotatorBlue:
            case DeviceTypes.NelsonR3000FCNRotatorBlue:
            case DeviceTypes.NelsonR3000RotatorGreen:
            case DeviceTypes.NelsonR3000FCNRotatorGreen:
            case DeviceTypes.NelsonR3000RotatorRed:
            case DeviceTypes.NelsonR3000RotatorOrange:
            case DeviceTypes.NelsonR3000RotatorWhite:
            case DeviceTypes.NelsonR3000RotatorBrown:
            case DeviceTypes.NelsonR3000RotatorOlive:
            case DeviceTypes.NelsonS3000SpinnerRed:
            case DeviceTypes.NelsonS3000SpinnerPurple:
            case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
            case DeviceTypes.NelsonS3000SpinnerYellow:
            case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
            case DeviceTypes.NelsonA3000AcceleratorGold:
            case DeviceTypes.NelsonA3000AcceleratorMaroon:
            case DeviceTypes.NelsonA3000AcceleratorNavy:
            case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
            case DeviceTypes.NelsonD3000Spray:
            case DeviceTypes.NelsonD3000FCNSpray:
                sDeviceHeight = 3;
                break;
            case DeviceTypes.SenningerLDNSpray:
                sDeviceHeight = 3 + this.LDNExtraPadCount(outlet) //'Each pad is one inch thick
                break;
            case DeviceTypes.KometTwisterBlack:
            case DeviceTypes.KometTwisterWhite:
            case DeviceTypes.KometTwisterBlue:
            case DeviceTypes.KometTwisterYellow:
            case DeviceTypes.SenningerSuperSpray:
            case DeviceTypes.SenningerXiWobBlue:
            case DeviceTypes.SenningerXiWobGray:
            case DeviceTypes.SenningerXiWobBlack:
            case DeviceTypes.SenningerXiWobWhite:
                sDeviceHeight = oldRoeEmulation.zeroSomeSprinklerDeviceHeights ? 0 : 3.5;
                break;
            case DeviceTypes.KometSpray:
            case DeviceTypes.SenningerLowAngleIWobWhite:
            case DeviceTypes.SenningerLowAngleIWobBlue:
            case DeviceTypes.SenningerStandardAngleIWobGray:
            case DeviceTypes.SenningerHighAngleIWobBlack:
                sDeviceHeight = 4
                break;
            case DeviceTypes.NelsonO3000OrbitorPurple:
            case DeviceTypes.NelsonO3000OrbitorBlack:
            case DeviceTypes.NelsonO3000OrbitorBlue:
            case DeviceTypes.NelsonO3030OrbitorFXWhite:
            case DeviceTypes.NelsonO3030OrbitorFXBlack:
                sDeviceHeight = 4.5
                break;
            case DeviceTypes.SenningerXcelWobbler:
                sDeviceHeight = 5
                break;
            case DeviceTypes.SenningerQuadSpray:
                sDeviceHeight = 12
                break;
                //'NOTE: the following case are unassigned related to BoomBackDevices
            case DeviceTypes.NelsonD3000PartCircle: 
            case DeviceTypes.NelsonS3000PartCircle: 
            case DeviceTypes.NelsonR3000PartCircle:
            case DeviceTypes.SenningerLDNSpray: 
            case DeviceTypes.KometSprayPartCircle: 
            case DeviceTypes.KometTwisterPartCircle: 
            case DeviceTypes.Senninger180Fan:
                sDeviceHeight = 0;
                break;
        }

        switch (outlet.Regulator.RegulatorType){
            case RegulatorTypes.NelsonBlueSTLF:
                sDeviceHeight += 2.5;
                break;
            case RegulatorTypes.NelsonBlueFTLF:
            case RegulatorTypes.NelsonBlueSTHF:
                sDeviceHeight += 3.5;
                break;
            case RegulatorTypes.NelsonBlueFTHF:
                sDeviceHeight += 4;
                break;
            case  RegulatorTypes.SenningerLF:
                sDeviceHeight += 3.5;
                break;
            case RegulatorTypes.SenningerMF:
                sDeviceHeight += 4;
                break;
            case RegulatorTypes.SenningerPSR:
                sDeviceHeight += 6;
                break;
        }

        return sDeviceHeight / 12
    }

    public BuildSprinklerPackage = (sys: ISystemBase): SprinklerEngineResult => {
        let result = new SprinklerEngineResult();
        this.System = sys;
        this.RightSideSprinklerEngine = new SprinklerEngine();
        this.LoadDataIntoSprinklerEngine("R");
        do {
            /*if (AppClass.Instance.RunMode.IsIn(RunModes.Developer)) {
                this.RightSideSprinklerEngine.PlaceSprinklers();
            } else {*/
                try {
                    this.RightSideSprinklerEngine.PlaceSprinklers();
                } catch (err) {
                    if (err.GetWarnings) {
                        result.Warnings.push(...err.GetWarnings());
                    } else {
                        console.error(err);
                        result.Warnings.push({
                            WarningSeverity: WarningSeverity.Critical,
                            WarningType: WarningTypes.InvalidData,
                            Context: "",
                            Message: err.message
                        });
                    }
                    return result;
                }
            //}
        } while (!(this.RightSideSprinklerEngine.InspectAndModifyP85s()));
        if (sysf.IsDualSided(this.System)) {
            this.LeftSideSprinklerEngine = new SprinklerEngine();
            this.LoadDataIntoSprinklerEngine("L");
            do {
                let sCoverage: number;
                let s: Side = this.System.FlexSide;
                if (s.Span.length > 0) {
                    if (!this.LeftSideSprinklerEngine.EndGunInfo.EGH) {
                        sCoverage = spanf.EndingRadius(this.System, s, s.Span[s.Span.length - 1]) + EstEndGunCoverage(this.LeftSideSprinklerEngine.EndGunInfo.EndGun, false);
                    } else {
                        sCoverage = spanf.EndingRadius(this.System, s, s.Span[s.Span.length - 1]) + EstEndGunCoverage(this.LeftSideSprinklerEngine.EndGunInfo.EGH.EndGunType, this.LeftSideSprinklerEngine.EndGunInfo.EGH.UseDiffuser);
                    }
                }
                /*if (AppClass.Instance.RunMode.IsIn(RunModes.Developer)) {
                    this.LeftSideSprinklerEngine.CalcEndPressure(sCoverage * 120);
                    this.LeftSideSprinklerEngine.PlaceSprinklers();
                } else {*/
                    try {
                        this.LeftSideSprinklerEngine.CalcEndPressure(sCoverage * 120);
                        this.LeftSideSprinklerEngine.PlaceSprinklers();
                    } catch (err) {
                        if (err.GetWarnings) {
                            result.Warnings.push(...err.GetWarnings());
                        } else {
                            console.error(err);
                            result.Warnings.push({
                                WarningSeverity: WarningSeverity.Critical,
                                WarningType: WarningTypes.InvalidData,
                                Context: "",
                                Message: err.message
                            });
                        }
                        return result;
                    }
                //}
            } while (!(this.LeftSideSprinklerEngine.InspectAndModifyP85s()));
        }
        this.UnloadDataFromSprinklerEngine("R");
        if (sysf.IsDualSided(this.System)) {
            this.UnloadDataFromSprinklerEngine("L");
        }
        return result;
    }

}

