import { booleanDisjoint, difference, lineArc, LineString, Point, Polygon } from "@turf/turf";
import FeatureHelpers from "rdptypes/geometry/helpers/features";
import { OPTIMIZATION_CONSTANTS } from "../constants";

interface IArgs {
    fieldBoundary: Polygon; 
    obstacles: Polygon[];

    
    center: Point;
    radiusFeet: number;
    minBearing: number;
    maxBearing: number;

    aft?: boolean;

    spans: number[];
    wheelObstacles: Polygon[];
}

export interface IOptimizedWrap {
    center: Point;
    radiusFeet: number;
    minBearing: number;
    maxBearing: number;
};

const MAX_SWEEP_ANGLE = 90;
const MIN_SWEEP_ANGLE = 8;

const checkAngle = (angle: number, wrapside: boolean, args: IArgs, obstacles: Polygon[]) => {
    // As an arc is an optimistic series of lines on an arc, a conservative radius is use,
    // this ensures that the collision is detected earliest at worst
    const conservativeRadius = 2 * args.radiusFeet - args.radiusFeet * Math.cos(0.1 / 2 * Math.PI / 180);
    const wrapSector = wrapside
        ? FeatureHelpers.GetSectorDrawFeature(
            args.center,
            conservativeRadius,
            args.maxBearing,
            args.maxBearing + angle,
            null,
            { units: 'feet', degreeIncrement: 0.1 }
        )
        : FeatureHelpers.GetSectorDrawFeature(
            args.center,
            conservativeRadius,
            args.minBearing - angle,
            args.minBearing,
            null,
            { units: 'feet', degreeIncrement: 0.1 }
        )
    
    const outsideBoundary = difference(wrapSector, args.fieldBoundary);
    if (
        outsideBoundary === null && 
        !args.obstacles.some(obs => !booleanDisjoint(obs, wrapSector)) &&
        !obstacles.some(obs => !booleanDisjoint(obs, wrapSector))
    ) {
        return true;
    }
    else {
        return false;
    }
}

const extractObstructiveWheelObstacles = (wrapside: boolean, args: IArgs) => {

    if (args.wheelObstacles.length === 0 || args.spans.length === 0) {
        return [];
    }

    const fullWheelTracks: LineString[] = [];
    let runningWrapLength = 0;
    for (let i = 0; i < args.spans.length; i++) {
        const spanLength = args.spans[i];
        runningWrapLength += spanLength;
        let arc: LineString;
        if (wrapside) {
            arc = lineArc(
                args.center,
                runningWrapLength,
                args.maxBearing,
                args.maxBearing + MAX_SWEEP_ANGLE,
                { units: 'feet', steps: OPTIMIZATION_CONSTANTS.SECTOR_STEPS }
            ).geometry;

        }
        else {
            arc = lineArc(
                args.center,
                runningWrapLength,
                args.minBearing - MAX_SWEEP_ANGLE,
                args.minBearing,
                { units: 'feet', steps: OPTIMIZATION_CONSTANTS.SECTOR_STEPS }
            ).geometry;
        }
        fullWheelTracks.push(arc);
    }

    let obstacles: Polygon[] = [];
    for (let i = 0; i < args.wheelObstacles.length; i++) {
        const incommingWheelObstacle = args.wheelObstacles[i];
        if (fullWheelTracks.some(wt => !booleanDisjoint(incommingWheelObstacle, wt))) {
            obstacles.push(incommingWheelObstacle);
        }
    }
    
    return obstacles;
}

const optimize = (args: IArgs): number | undefined => {
    // const t = createTimerLog("wrapSpan2")
    const wrapside = !(args.aft || false);

    if (args.minBearing === args.maxBearing) {
        // can only add a wrap to a partial pivot
        return undefined;
    }


    let crntMin = MIN_SWEEP_ANGLE;
    let crntMax = MAX_SWEEP_ANGLE;

    const obstacles = extractObstructiveWheelObstacles(wrapside, args);
    // t.logDeltaMS("obs")

    // shortcut if start not valid:
    if (!checkAngle(crntMin, wrapside, args, obstacles)) {
        return undefined;
    }
    let bestSweepAngle = crntMin;
    // t.logDeltaMS("check1")

    // shortcut if end is valid:
    if (checkAngle(crntMax, wrapside, args, obstacles)) {
        return crntMax;
    }
    // t.logDeltaMS("check2")

    while (crntMin !== crntMax) {
        // TODO: Ideally we would like to find to 1dp, but this is too slow using below:
        // const crntI = Math.round((crntMax + crntMin) * 0.5 * 10) / 10;
        const crntI = Math.round((crntMax + crntMin) * 0.5);
        if (checkAngle(crntI, wrapside, args, obstacles)) {
            bestSweepAngle = crntI;
            crntMin = crntI;
        }
        else {
            crntMax = Math.max(crntI - 1, crntMin);
        }
    }
    // t.logDeltaMS("loop")
    
    return bestSweepAngle;    
}

export default optimize;