import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { DropTypes, FittingTypes, IPackage } from "rdptypes/project/ISprinklers";
import ISystem from "rdptypes/project/ISystem";
import { setSprinklerPackage } from ".";
import { GetFittingOptions } from "../../helpers/Rules";
import { IFnExecutePropertyValueMap, IPropertyValueMap, ISprinklerPackageFittingValidator, ISprinklerValidatorChoiceFieldWithSet, ISprinklerValidatorFieldWithSet } from "../interfaces";

const fittingTypeValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSet<FittingTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.Fitting.FittingType;

    const getAllowableValues = () => {
        return GetFittingOptions(sprinklerPackage, sys, side === SideEnum.Flex);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!sprinklerPackage?.Fitting) return true;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: FittingTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Fitting: {
                    ...sprinklerPackage.Fitting,
                    FittingType: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}

const dropNextToEndgunValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.Fitting.DropNextToEndGun;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Fitting: {
                    ...sprinklerPackage.Fitting,
                    DropNextToEndGun: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

export const fittingValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerPackageFittingValidator => {
    const options = {
        fittingType: fittingTypeValidator(sys, side, packageIdx, executePvm, excludeProperties),
        dropNextToEndgun: dropNextToEndgunValidator(sys, side, packageIdx, executePvm, excludeProperties),
    }
    return {
        ...options,
        isError: getSide(sys, side).Sprinklers.Package[packageIdx].Drop === DropTypes.None
            ? Object.values(options).some(x => x.isError)
            : false
    }
}