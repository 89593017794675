import * as React from "react";
import { useContext } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { DialogContent } from "@mui/material";
import i18next, { t } from "i18next";
import { createResetSystemActionV2 } from "../../../actions/ResetSystemActionV2";
import AuthCtx from "../../../auth/AuthCtx";
import DbCtx from "../../../db/DbCtx";
import { staticDevSettingsDbProvider } from "../../../db/DevSettingsDbProvider";

interface IProps {
    projectId: string;
    layoutId: string;
    systemId: string;
    onCancel: () => void;
    onReset: () => void;
}

const ResetProposalConfirmationDialog: React.FC<IProps> = ({
    projectId, layoutId, systemId, onReset, onCancel
}) => {
    
    const authState = useContext(AuthCtx);
    const dbState = useContext(DbCtx);    
    const dbPrj = dbState.projects[projectId];

    const handleCancel = () => {
        onCancel();
    }

    const handleOk = () => {
        const customerSettings = staticDevSettingsDbProvider.customer.get();
        const dealerPricing = undefined;
        dbPrj.pushAction(
            createResetSystemActionV2(
                layoutId,
                systemId,
                customerSettings,
                dealerPricing,
                authState
            )
        )
        onReset();
    }

    return (
        <Dialog open={true} onClose={handleCancel}>
            <DialogTitle>{i18next.format(t('reset-proposal'), 'capitalize-each')}</DialogTitle>
            <DialogContent>
                <DialogContentText p={2}>
                    {t("nav.systems.confirm-reset-proposal")}
                </DialogContentText>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={handleCancel}>{t("cancel")}</Button>
                <Button onClick={handleOk}>{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ResetProposalConfirmationDialog;