import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC, useContext } from "react";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import { FEXContext } from "../../../../foreignExchange";
import { pricingHelper } from "../../../../helpers/pricingHelper";
import DealerDataCtx from "../../../../userData/DealerDataCtx";

interface Props {
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
};

const MiscItemsHeader: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const fex = useContext(FEXContext);
    const user = useContext(DealerDataCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];
    const ph = pricingHelper({ system, user, fex })

    return (
        <>
            <FormControl style={{ marginBottom: 20 }}>
                <InputLabel id="currency-select-label">{t("currency")}</InputLabel>
                <Select
                    value={ph.localCurrency?.symbol || "USD"}
                    labelId="currency-select-label"
                    id="currency-select"
                    label={t("currency")}
                    onChange={(e) => {
                        ph.setLocalCurrency(
                            e.target.value,
                            props.dbPrj,
                            props.layoutId,
                            props.systemId,
                            authState
                        );
                    }}
                >
                    {
                        Object.entries(fex.symbols).map(([ currencyCode, currencyString ]) => <MenuItem key={currencyCode} value={currencyCode}>{`${currencyCode} (${currencyString})`}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </>
    );
};

export default MiscItemsHeader;