import AddCircle from "@mui/icons-material/AddCircle";
import DeleteCircle from "@mui/icons-material/RemoveCircle";
import { Checkbox, IconButton, Stack } from "@mui/material";
import ISystem from "rdptypes/project/ISystem";
import { ValveMaterials } from "rdptypes/project/ISystemBase.AutoGenerated";
import ISideTable, { IColumn } from "rdptypes/roe/ISideTable";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import { getValue } from "../../../../helpers/objectPathResolver";
import { translate } from "../../../../helpers/translation";
import { getComponentRenderer } from "../componentRendererFactory";
import IComponentRenderContext from "./../IComponentRenderContext";

export interface IRow {
    index: number;
    value: any;
}

export const getRows = (system: ISystem, fieldPath: string): IRow[] => {
        const rows: IRow[] = [];

        var items: any[] = getValue(system, fieldPath);
        items.forEach((x, index) => {
            rows.push({
                index: index,
                value: x,
            });
        });

    return rows;
}

interface Props {
    cmp: ISideTable;
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    ctx: IComponentRenderContext;
};

const GeneralTableRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const arrayPath = props.cmp.columns.length > 0 ? props.cmp.columns[0].arrayPath : "";

    const rows = React.useMemo(() => getRows(system, arrayPath), [ JSON.stringify(system)]);

    const [ selectedRowIndexes, setSelectedRowIndexes ] = useState<number[]>([]);

    const selectedDataRows: IRow[] = selectedRowIndexes.map(rowIndex => rows.find(x => x.index === rowIndex)).filter(row => row !== undefined);

    const someRowsSelected = !!selectedRowIndexes.length;
    const allRowsSelected = selectedDataRows.length === rows.length;
    
    const addItem = () => {

        var newItem = {};
        props.cmp.columns.forEach(col =>{
            var component = col.cmp as any;
            if(component.fieldPath !== ""){
                newItem[component.fieldPath] = component.default !== undefined ? component.default : (component.type === "boolean" ? false : undefined);
            }
            if(component.additionalBooleans && component.additionalBooleans.length > 0){
                component.additionalBooleans.forEach(ab => {
                    newItem[ab.fieldPath] = false;
                });
            }

            if(component.fieldPath === "ValveMaterial"){ // hidden attribute from UI, that needs to be initialized/set
                newItem[component.fieldPath] = ValveMaterials.Aluminum;
            }         
        });
        var copied = Object.assign([], rows.map(r => r.value));
        copied.push(newItem);

        props.ctx.pushActionAndImproveScores(createNewUpdateSystemPropertyAction(
            props.layoutId,
            props.systemId,
            arrayPath,
            copied,
            authState), [arrayPath], true);         
    };

    const deleteItem = () => {
        var newItems = [];
        rows.forEach(r =>{
            if(!selectedRowIndexes.includes(r.index)){
                newItems.push(r.value);
            }
        });
        
        props.ctx.pushActionAndImproveScores(createNewUpdateSystemPropertyAction(
            props.layoutId,
            props.systemId,
            arrayPath,
            newItems,
            authState), [arrayPath], true);    

        setSelectedRowIndexes([]);
    };

    return (
        <>
            <Stack style={{marginBottom: 10}} direction="row">
                <IconButton
                    onClick={addItem}
                    >
                    <AddCircle />
                </IconButton>
                <IconButton
                    disabled={!selectedRowIndexes.length}
                    onClick={deleteItem}
                    >
                    <DeleteCircle />
                </IconButton>
            </Stack>

            {
                rows.length !== 0 && <table style={{borderSpacing: 0}}>
                    <thead style={{fontSize: 12, whiteSpace: 'nowrap'}}>
                        <tr>
                            <th><Checkbox
                                checked={someRowsSelected}
                                indeterminate={someRowsSelected && !allRowsSelected}
                                onChange={e => {
                                    if (allRowsSelected) {
                                        setSelectedRowIndexes([]);
                                    } else {
                                        setSelectedRowIndexes(rows.map((row) => row.index));
                                    }
                                }}
                            /></th>
                            <th>#</th>
                            { props.cmp.columns
                                .filter(col => !col.visible || col.visible(system))
                                .map(col => <th>{translate(col)}</th>) }
                        </tr>
                    </thead>
                    <tbody>
                        { rows.map((row, irow) => 
                            <DataRow {...props} system={system} row={row}
                                selectedDataRows={selectedDataRows}
                                onSelectedChanged={selected => {
                                    const newSelectedRows = selectedRowIndexes.filter(x => x !== row.index);
                                    if (selected) {
                                        newSelectedRows.push(row.index);
                                    }
                                    setSelectedRowIndexes(newSelectedRows);
                                }}
                            />)}
                    </tbody>
                </table>
            }
        </>
    );
};

export const DataRow: FC<{
    row: IRow; 
    system: ISystem; 
    selectedDataRows: IRow[], 
    onSelectedChanged: (selected: boolean) => any
} & Props> = (props) => {
    const selected = props.selectedDataRows.indexOf(props.row) !== -1;
    const changeRows = [...props.selectedDataRows];
    if (!selected) {
        changeRows.push(props.row);
    }

    const buildFieldRoot = (index: number, col: IColumn) =>  {
        return col.arrayPath + "[" + index + "].";
    };

    return (
        <tr>
            <td>
                <Checkbox checked={selected} onChange={e => props.onSelectedChanged(!selected)} />
            </td>
            <td style={{borderRight: '1px solid lightgray', paddingRight: 10, fontSize: props.cmp.small ? "12px" : "1rem"}}>{props.row.index}</td>
            {
                props.cmp.columns
                    .filter(col => !col.visible || col.visible(props.system))
                    .map(col => 
                        <td style={{borderRight: '1px solid lightgray'}}>{getComponentRenderer(
                            col.cmp,
                            props.dbPrj,
                            props.layoutId,
                            props.systemId,
                            {
                                ...props.ctx,
                                fieldRoot: buildFieldRoot(props.row.index, col),
                                changeFieldRoots: changeRows.map(row => buildFieldRoot(props.row.index, col))
                            },
                            null,
                            true
                        )}
                        </td>
                    )
            }
        </tr>
    );
}

export default GeneralTableRenderer;