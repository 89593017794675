import { Feature, LineString, MultiPolygon, Point, Polygon, Position, Units, area, booleanContains, convertArea, destination, difference, feature, featureCollection, lineString, multiLineString, multiPolygon, point, polygon } from "@turf/turf";
import FeatureHelpers from "rdptypes/geometry/helpers/features";
import { customUnion } from "rdptypes/geometry/helpers/turf";
import CenterPivotGeometryHelperBase from "rdptypes/geometry/systems/centerPivot";
import { ISpanSegment, ISpanVertex, calculateSpanSegments } from "rdptypes/geometry/systems/centerPivot/systemCoordinates";
import { getEndOfSystemIfValid } from "rdptypes/helpers/EndOfSystem";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { getSpansWithoutEndOfSystem, getSpansWithoutSAC } from "rdptypes/helpers/Spans";
import { isPartialPivot } from "rdptypes/helpers/system";
import { ECircleDirection, getWrapAngle } from "rdptypes/helpers/Wraps";
import { IEndgunInformation } from "rdptypes/project/IEndgunInformation";
import ISystem from "rdptypes/project/ISystem";
import { EndGunTypes, EndOfSystemTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { swingArmLengthsFeet } from "rdptypes/reinkeComponents";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import { ImportExportFeature, ImportExportFeatureProperties, ImportExportTypes } from "../../../helpers/importExport";
import { EndGunLocation, getEndGunThrow } from "../../../model/project/IEndGun";
import ILayout from "../../../model/project/ILayout";
import IProject from "../../../model/project/IProject";
import calculateEngGunOnOffs from "../../../optimization/centerPivot/endgun";
import { BoundaryHelper } from "../../BoundaryHelper";
import { ObstacleHelper } from "../../ObstacleHelper";
import { IBufferedSystemPolygonsForSystemClearancePolygons, IGetSystemValidityArgs, IGetSystemValidityArgs_Sac, ILayoutClearancePolygons, getLayoutClearancePolygons, getSwingArmClearancePolygons, getSystemClearancePolygons } from "../interfaces";
import LateralGeometryHelper from "../LateralGeometryHelper";
import { IGeoJSONFeatureResult, IProperties } from "./interfaces";

interface IWrapspanInformation {
    center: Point;
    headingFrom: number;
    headingTo: number;
    effectiveFromSpanIndex: number;
    wheelTrackRadii: number[];
    wrapRadiusFeet: number;
}

interface ICenterPivotGeometryHelperArgs {
    layoutId: string;
    systemId: string;
    project: IProject;
}

interface ICenterPivotGeometryHelperOptions {
    isActive?: boolean;
    degreeIncrement?: number;
    isEditable?: boolean;
}

export default class CenterPivotGeometryHelper extends CenterPivotGeometryHelperBase<IProperties> {

    private project: IProject;
    private layout: ILayout;
    private layoutId: string;
    private systemId: string;

    private isEditable: boolean;
    private isActive: boolean;
    private degreeIncrement = 0.1;
    private units: Units = 'feet';

    private _irrigatedAreaAcres: number | undefined = undefined;
    private _wrapspanInformation: {
        clockwise: IWrapspanInformation[];
        anticlockwise: IWrapspanInformation[];
    } | undefined = undefined;

    private _mainIrrigatedAreaPolygon: Polygon | undefined = undefined;
    private _systemValidityArgs: IGetSystemValidityArgs | null = null;
    private _cached_createSingleGeoJSONFeature: IGeoJSONFeatureResult | undefined = undefined;
    
    constructor(args: ICenterPivotGeometryHelperArgs, options?: ICenterPivotGeometryHelperOptions) {

        const { layoutId, systemId, project } = args;
        const isActive = options?.isActive ?? false;
        const isEditable = options?.isEditable ?? true;
        
        if (!project) {
            throw new Error("No project associated with this system");
        }
        const layout = project.layouts[layoutId];
        if (!layout) {
            throw new Error("No layout associated with this system");
        }
        const system = layout.systems[systemId];
        super({ 
            system,
            properties: {
                isCenterPivot: true,
                systemId: systemId,
                layoutId: layoutId,
                activeSystem: isActive,
                isEditable: isEditable
            }
        });
        
        this.project = project;        
        this.layout = layout;
        this.layoutId = layoutId;
        this.systemId = systemId;
        this.isActive = isActive;
        this.isEditable = isEditable

        if (options) {
            if (options.degreeIncrement !== undefined && options.degreeIncrement > 0) this.degreeIncrement = options.degreeIncrement;
        }
    }

    // public accessors:
    public get propertiesForAll(): IProperties {  
        return {
            isCenterPivot: true,
            systemId: this.systemId,
            layoutId: this.layoutId,
            activeSystem: this.isActive,
            isEditable: this.isEditable
        }
    }
    public get irrigatedAreaAcres() {
        if (this._irrigatedAreaAcres === undefined) {
            const ap = this.getAreaPolygon({includeEndguns: true, includeSAC: true});
            let irrigatedAreaSqMeters = ap ? area(ap) : 0;
            this._irrigatedAreaAcres = convertArea(irrigatedAreaSqMeters, 'meters', 'acres');
        }
        return this._irrigatedAreaAcres!;
    }
    public get systemRadiusFeetIncludingEndboomOrSac(): number {
        let l = this.systemRadiusFeetExcludingEos;
        const eos = getEndOfSystemIfValid(this.system, SideEnum.Flanged);
        if (eos) {
            // TODO: Not to simon :use spanfunctions when merging
            if (eos.endOfSystemType === EndOfSystemTypes.EndBoom) {
                l += eos.endBoomLength;
            }
            else {
                l += eos.endBoomLength + eos.swingArmLength;
            }
        }
        return l;
    }
    public get mainIrrigatedAreaPolygon() {
        // TODO: use areaPolygon
        if (this._mainIrrigatedAreaPolygon === undefined) {
            const p = FeatureHelpers.GetSectorDrawFeature(
                this.center,
                this.systemRadiusFeetExcludingEos,
                this.bearingStart,
                this.bearingEnd,
                null,
                this.featureHelperOptions
            )
            this._mainIrrigatedAreaPolygon = p.geometry;
        }
        return this._mainIrrigatedAreaPolygon;
    }
    public get getSacAreaAcres() {
        let sacPolygon = this.getSacAreaPolygon();
        let metresSqd = 0;
        sacPolygon.irrigatedArea.forEach((iap) => {
            metresSqd += area(iap)
        });

        return convertArea(metresSqd, 'meters', 'acres');
    }
    public get getEndgunAcres(): {type: EndGunTypes, areas: number[], isOnSac: boolean, isPrimary: boolean}[] {
        let sacPolygon = this.getSacAreaPolygon();
        if (sacPolygon.irrigatedArea.length){//has SAC
            let primaryEndGunAcres = 0;
            let secondaryEndGunAcres = 0;
            for (const endGunPoly of sacPolygon.endGunAreas){
                const acres = convertArea(area(endGunPoly), 'meters', 'acres');
                if (endGunPoly.properties.isPrimary) {
                    primaryEndGunAcres += acres;
                }
                else {
                    secondaryEndGunAcres += acres;
                }
            }
            const ret: {type: EndGunTypes, areas: number[], isOnSac: boolean, isPrimary: boolean}[] = [];
            if (primaryEndGunAcres) {
                ret.push({
                    type: this.system.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypePrimary,
                    isPrimary: true, 
                    areas: [ primaryEndGunAcres ], 
                    isOnSac: true
                });
            }
            if (secondaryEndGunAcres) {
                ret.push({
                    type: this.system.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary,
                    isPrimary: false, 
                    areas: [ secondaryEndGunAcres ], 
                    isOnSac: true
                });
            }
            return ret;
        }
        else {
            //TODO: this is similar to what is in getAreaPolygon at the moment
            let guns: {type: EndGunTypes, areas: number[], isOnSac: boolean, isPrimary: boolean}[] = [];
            for (const eg of this.nonSacEndGuns) {
                let acres: number[] = [];
                for (const onOff of eg.onOffs) {
                    const endGunPoly = FeatureHelpers.GetAnnulusSectorDrawFeature(
                        eg.center,
                        eg.throwStartFeet,
                        eg.throwEndFeet,
                        onOff.startBearing,
                        onOff.endBearing,
                        {
                            rdpFeatureType: "system/endGunIrrigatedArea",
                            ...this.propertiesForAll
                        },
                        this.featureHelperOptions
                    );
                    acres.push(convertArea(area(endGunPoly), 'meters', 'acres'));
                }
                guns.push({type: eg.type, isPrimary: eg.isPrimary, areas: acres, isOnSac: false});
            }
            return guns;
        }
    }
    public get wrapSpanAreaAcres () {
        let wsap = this.getWrapSpanAreaPolygons();
        if (typeof(wsap) === "undefined" || wsap.length === 0) return undefined;
        let runningArea = 0;
        for (const p of wsap) {
            runningArea += convertArea(area(p), 'meters', 'acres')
        }
        return runningArea;
    }
    public get dropSpanAcres () {
        let dsap = this.getDropSpanAreaPolygons();
        if (typeof(dsap) === "undefined"|| dsap.drops.length === 0) return undefined;
        let runningArea = 0;
        for (const p of [ ...dsap.drops, ...dsap.wraps]) {
            runningArea += convertArea(area(p), 'meters', 'acres')
        }
        return runningArea;
    }
    public get pivotAreaAcres() {
        const pa = this.getPivotAreaPolygonOrCenterPoint();
        if (pa.type === 'Point') return 0;
        return convertArea(area(pa), 'meters', 'acres');
    }

    // private accessors:
    private get featureHelperOptions() {
        return { 
            units: this.units,
            degreeIncrement: this.degreeIncrement
        }
    }
    private get isPartialPivot() {
        return (this.bearingStart % 360) !== (this.bearingEnd % 360);
    }
    private get bearingStart() { return (this.system.Circle.CenterPivot.isPartialPivot && this.system.Circle!.CenterPivot!.clockwiseCompassHeadingStart) || 0; }
    private get bearingEnd() { return (this.system.Circle.CenterPivot.isPartialPivot && this.system.Circle!.CenterPivot!.clockwiseCompassHeadingEnd) || 0; }
    private get systemRadiusFeetIncludingEndboom(): number {
        let l = this.systemRadiusFeetExcludingEos;
        const eos = getEndOfSystemIfValid(this.system, SideEnum.Flanged);
        if (eos) {
            // TODO: Not to simon :use spanfunctions when merging
            if (eos.endOfSystemType === EndOfSystemTypes.EndBoom) {
                l += eos.endBoomLength;
            }
        }
        return l;
    }
    private get clockwiseWrapSpans(): IWrapspanInformation[] {
        if (this._wrapspanInformation === undefined) {
            this.setWrapspanInformationMember();
        }
        return this._wrapspanInformation!.clockwise;
    }
    private get anticlockwiseWrapSpans(): IWrapspanInformation[] {
        if (this._wrapspanInformation === undefined) {
            this.setWrapspanInformationMember();
        }
        return this._wrapspanInformation!.anticlockwise;
    }

    // public methods:
    public getSystemValidityArgs(_layoutClearancePolygons?: ILayoutClearancePolygons, buffedSystemPolygons?: IBufferedSystemPolygonsForSystemClearancePolygons) {
        if (!this._systemValidityArgs) {
            if (!this._cached_createSingleGeoJSONFeature) {
                this._cached_createSingleGeoJSONFeature = CenterPivotGeometryHelper.createSingleGeoJSONFeature(this.system, this.centerPoint);
            }
            const data = this._cached_createSingleGeoJSONFeature;
            let systemAreaPolygon: Polygon | undefined = undefined;
            if (data.feature && data.feature.geometry.type === 'Polygon') {
                systemAreaPolygon = data.feature.geometry;
            }
            const layoutClearancePolygons = _layoutClearancePolygons ?? getLayoutClearancePolygons(
                this.project,
                this.layoutId
            );
            const systemClearancePolygons = getSystemClearancePolygons(
                this.project,
                this.layoutId,
                this.systemId,
                buffedSystemPolygons
            );

            let sac: IGetSystemValidityArgs_Sac | undefined = undefined;
            if (
                this.system.FlangedSide.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC &&
                this.system.sacOptimizerResult?.success
            ) {
                const sacPolygons = this.getSacAreaPolygon(false);
                sac = {
                    polygons: sacPolygons.irrigatedArea.map(f => f.geometry),
                    wheelTracks: [
                        ...sacPolygons.guidanceWheelTracks.map(f => f.geometry),
                        ...sacPolygons.nonGuidanceWheelTracks.map(f => f.geometry),
                    ],
                    ...getSwingArmClearancePolygons(this.project, this.layoutId)
                }
            }
            this._systemValidityArgs = {
                systemClearanceObstacles: layoutClearancePolygons.clearanceObstacles,
                systemClearanceBoundaries: layoutClearancePolygons.clearanceBoundaries,         
                systemAreaPolygon,
                systemClearancePivotCenterBoundaries: layoutClearancePolygons.clearancePivotCenterBoundary,
                systemClearanceSystemObstacles: systemClearancePolygons.clearanceSystemObstacles,
                center: this.center,
                systemClearanceWheelObstacles: layoutClearancePolygons.clearanceWheelObstacles,
                wheelTracks: data.wheelTracks?.map(x => x.geometry),
                sac,
                allowOverlap: this.system.overlapping || false,
                sacResult: this.system.sacOptimizerResult
                    ? { success: this.system.sacOptimizerResult.success }
                    : undefined,
                centerPivot: {
                    radiusEnvelope: this.systemRadiusFeetIncludingEndboomOrSac,
                }
            }
        }
        return this._systemValidityArgs!;
    }
    public calculateEndGunOnOffs(additionalObstacles: Polygon[] = []) {
        let result: IEndgunInformation[] = [];

        // TODO: Check that the following correctly defines an end gun. The end
        // guns created by the SAC use a diffent method to define the throw angle.
        // Also, bring the sac endgun calculation into this getter
        if (this.system.sacOptimizerResult) {
            // then end guns are drawn in the getDrawFeatures body:
            return result;
        }
        else {
            const nonBufferedBoundary = this.layout.fieldBoundary
                ? BoundaryHelper.getPolygon(this.layout.fieldBoundary)
                : undefined;
            if (!nonBufferedBoundary) {
                return result;
            }
            else {
                const nonBufferedObstacles = this.layout.obstacles.flatMap(x => ObstacleHelper.getPolygon(x));
                const endGunTypes: {type: EndGunTypes, isPrimary: boolean, throwFeet: number }[] = [];
                if (this.system.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary && this.system.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary !== EndGunTypes.None) {
                    const throwFeet = getEndGunThrow(this.system, SideEnum.Flanged, EndGunLocation.Primary);
                    if (throwFeet) {
                        endGunTypes.push({
                            type: this.system.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary,
                            isPrimary: true,
                            throwFeet
                        });
                    }
                }
                if (this.system.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary && this.system.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary !== EndGunTypes.None) {
                    const throwFeet = getEndGunThrow(this.system, SideEnum.Flanged, EndGunLocation.Secondary);
                    if (throwFeet) {
                        endGunTypes.push({
                            type: this.system.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary,
                            isPrimary: false,
                            throwFeet
                        });
                    }
                }
                const boundariesForGuns = this.layout.wetAreaBoundary
                    ? BoundaryHelper.getPolygon(this.layout.wetAreaBoundary)
                    : nonBufferedBoundary;
                    
                const allowableAreaPolygonWithSystem = booleanContains(boundariesForGuns, this.center)
                    ? boundariesForGuns
                    : undefined;
                if (!allowableAreaPolygonWithSystem) {
                    this._endGunOnOffs = result;
                    return this._endGunOnOffs!;
                }

                nonBufferedObstacles.push(...additionalObstacles);

                const allSystems = Object.entries(this.layout.systems);
                const thisSystemIndex = allSystems.findIndex(([sid]) => sid === this.systemId);
                for (let i = 0; i < allSystems.length; i++) {
                    const [systemId, system] = allSystems[i];
                    if (systemId === this.systemId) continue;
                    if (system.SystemProperties.SystemType === SystemTypes.CenterPivot) {
                        const systemGeometryHelper = new CenterPivotGeometryHelper({
                            project: this.project,
                            layoutId: this.layoutId,
                            systemId
                        })
                        const p = systemGeometryHelper.getAreaPolygon({includeEndguns: false, includeSAC: true});
                        if (p) {                
                            nonBufferedObstacles.push(p);
                        }
                    }
                    else if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
                        const systemGeometryHelper = new LateralGeometryHelper({
                            project: this.project,
                            layoutId: this.layoutId,
                            systemId
                        })
                        const p = systemGeometryHelper.getAreaPolygon();
                        if (p) {
                            nonBufferedObstacles.push(p);
                        }
                    }
                }

                // we need to remove the overlapping parts of the obstacles with
                // the system so to not stop end guns early
                const thisSysPolygon = this.getAreaPolygon({ includeEndguns: false, includeSAC: false });
                const nonBufferedObstacles2 = nonBufferedObstacles.flatMap(o => {
                    const d = difference(o, thisSysPolygon);
                    if (!d) return [];
                    if (d.geometry.type === 'MultiPolygon') {
                        return d.geometry.coordinates.map(x => polygon(x).geometry);
                    }
                    else {
                        return d.geometry;
                    }
                })
                for (const endGun of endGunTypes) {
                    const throwFeet = endGun.throwFeet;

                    const spanSegments = calculateSpanSegments(this.system, getSpansWithoutSAC(this.system, SideEnum.Flanged).length - 1).segments;
                    for (const spanSegment of spanSegments) {

                        // calculate end gun on off for main area:
                        if (!booleanContains(allowableAreaPolygonWithSystem, spanSegment.center)) {
                            // dont do if center is not in boundary
                            continue;
                        }
                        const mainAreaCalculatedOnOffs = calculateEngGunOnOffs({
                            boundary: allowableAreaPolygonWithSystem,
                            obstacles: nonBufferedObstacles2,
                            system: {
                                center: spanSegment.center,
                                radiusFeet: spanSegment.outerRadiusFeet,
                                minBearing: spanSegment.startBearing,
                                maxBearing: spanSegment.startBearing + spanSegment.sweepAngle
                            },
                            throwDistanceFeet: throwFeet
                        })
                        result.push({
                            center: spanSegment.center,
                            throwStartFeet: spanSegment.outerRadiusFeet,
                            throwEndFeet: spanSegment.outerRadiusFeet + throwFeet,
                            onOffs: mainAreaCalculatedOnOffs,
                            type: endGun.type,
                            isPrimary: endGun.isPrimary
                        })
                        for (const onOff of mainAreaCalculatedOnOffs) {
                            nonBufferedObstacles2.push(
                                FeatureHelpers.GetAnnulusSectorDrawFeature(
                                    spanSegment.center,
                                    spanSegment.outerRadiusFeet,
                                    spanSegment.outerRadiusFeet + throwFeet,
                                    onOff.startBearing,
                                    onOff.endBearing,
                                    null,
                                    { units: 'feet' }
                                ).geometry
                            )
                        }
                        
                    }
                }
                return result;
            }
        }
    }
    getExportFeatures(): ImportExportFeature[] {

        const features: ImportExportFeature[] = [];

        // // Sac regions
        const sacPolygons = this.getSacAreaPolygon();
        if (sacPolygons.irrigatedArea.length === 1) {
            const p = polygon<ImportExportFeatureProperties>(
                sacPolygons.irrigatedArea[0].geometry.coordinates, {
                    importType: 'sacIrrigatedArea'
                }
            )
            features.push(p);
        }
        else if (sacPolygons.irrigatedArea.length > 1) {
            const mp = multiPolygon<ImportExportFeatureProperties>(
                sacPolygons.irrigatedArea.map(ia => ia.geometry.coordinates),{
                    importType: 'sacIrrigatedArea'
                }
            )
            features.push(mp);
        }
        if (sacPolygons.endGunAreas.length === 1) {
            const p = polygon<ImportExportFeatureProperties>(
                sacPolygons.endGunAreas[0].geometry.coordinates, {
                    importType: 'endGunIrrigatedArea'
                }
            )
            features.push(p);
        }
        else if (sacPolygons.endGunAreas.length > 1) {
            const mp = multiPolygon<ImportExportFeatureProperties>(
                sacPolygons.endGunAreas.map(ia => ia.geometry.coordinates),{
                    importType: 'endGunIrrigatedArea'
                }
            )
            features.push(mp);
        }

        // Pivot center (Point)
        features.push(
            point<ImportExportFeatureProperties>(
                this.center.coordinates, {
                    importType: 'pivotCenter'
                }
            )
        )

        // // If the system has no radius, we only want to show the pivot center
        if (this.systemRadiusFeetExcludingEos === 0) return features;

        // Wheel tracks (Lines)        
        const result = this._getDrawFeaturesSelectMode();
        if (result.wheelTracks) {
            result.wheelTracks.forEach((wt) => {
                const wtf = lineString<ImportExportFeatureProperties>(wt.geometry.coordinates, {
                    importType: "wheelTrack"
                })
                if (wt.properties.spanIndex) wtf.properties.wtId = wt.properties.spanIndex;
                if (wt.properties.type) wtf.properties.type = wt.properties.type;
                if (wt.properties.wrapIndex !== undefined) wtf.properties.wrapId = wt.properties.wrapIndex;
                features.push(wtf);
            })
        }
        if (sacPolygons.guidanceWheelTracks.length) {
            const x = swingArmLengthsFeet.find(x => x.value === this.system.FlangedSide.EndOfSystem.SwingArmLength);
            let importType: ImportExportTypes | null = null;
            switch (x?.shortLabel) {
                case "MSAC":
                case "MSSAC":
                    importType = "gMSAC";
                    break;
                case "SAC":
                    importType = "gSAC";
                    break;
                case "SSAC":
                    importType = "gSSAC";
                    break;
            }
            if (importType) {
                sacPolygons.guidanceWheelTracks.forEach((wt) => {
                    const wtf = lineString<ImportExportFeatureProperties>(wt.geometry.coordinates, {
                        importType
                    })
                    features.push(wtf);
                });
            }
        }
        if (sacPolygons.nonGuidanceWheelTracks.length) {
            const x = swingArmLengthsFeet.find(x => x.value === this.system.FlangedSide.EndOfSystem.SwingArmLength);
            let importType: ImportExportTypes | null = null;
            switch (x?.shortLabel) {
                case "MSAC":
                case "MSSAC":
                    importType = "nMSAC";
                    break;
                case "SAC":
                    importType = "nSAC";
                    break;
                case "SSAC":
                    importType = "nSSAC";
                    break;
            }
            if (importType) {
                sacPolygons.nonGuidanceWheelTracks.forEach((wt) => {
                    const wtf = lineString<ImportExportFeatureProperties>(wt.geometry.coordinates, {
                        importType
                    })
                    features.push(wtf);
                });
            }
        }
        if (sacPolygons.endBoom.length) {
            const x = swingArmLengthsFeet.find(x => x.value === this.system.FlangedSide.EndOfSystem.SwingArmLength);
            let importType: ImportExportTypes | null = null;
            switch (x?.shortLabel) {
                case "MSAC":
                case "MSSAC":
                    importType = "ebMSAC";
                    break;
                case "SAC":
                    importType = "ebSAC";
                    break;
                case "SSAC":
                    importType = "ebSSAC";
                    break;
            }
            if (importType) {
                sacPolygons.endBoom.forEach((wt) => {
                    const wtf = lineString<ImportExportFeatureProperties>(wt.geometry.coordinates, {
                        importType
                    })
                    features.push(wtf);
                });
            }
        }
        else if (result.endBoomTrack?.length) {
            result.endBoomTrack.forEach(ebt => {
                const wtf = lineString<ImportExportFeatureProperties>(ebt.geometry.coordinates, {
                    importType: "endBoom"
                })
                if (ebt.properties.type) wtf.properties.type = ebt.properties.type;
                if (ebt.properties.wrapIndex !== undefined) wtf.properties.wrapId = ebt.properties.wrapIndex;
                features.push(wtf);
            })
        }

        // irrigated area (main system only)
            features.push(FeatureHelpers.GetSectorDrawFeature(
                this.center,
                this.systemRadiusFeetIncludingEndboom,
                this.bearingStart,
                this.bearingEnd,
                {
                    importType: 'mainIrrigatedArea'
                },
                this.featureHelperOptions
            ) as ImportExportFeature)

        // // wrap spans:
        for (const wrapSpan of [...this.clockwiseWrapSpans, ...this.anticlockwiseWrapSpans]) {
            
            // wrap span blockade (point)
            // dont need
            
            // Wrap span wheel tracks (Lines)
            // dont need

            // wrap irrigated area (polyogn)
            features.push(FeatureHelpers.GetSectorDrawFeature(
                wrapSpan.center,
                wrapSpan.wrapRadiusFeet,
                wrapSpan.headingFrom, 
                wrapSpan.headingTo,
                {
                    importType: 'wrapIrrigatedArea'
                },
                this.featureHelperOptions
            ) as ImportExportFeature);
        }

        // end gun(s):
        // Note: Eng gun only added if SAC not added
        for (const eg of this.nonSacEndGuns) {
            const endGunSegments = eg.onOffs.map(onOff => (
                FeatureHelpers.GetAnnulusSectorDrawFeature(
                    eg.center,
                    eg.throwStartFeet,
                    eg.throwEndFeet,
                    onOff.startBearing,
                    onOff.endBearing,
                    {},
                    this.featureHelperOptions
                )
            ))
            if (endGunSegments.length === 1) {
                const p = polygon<ImportExportFeatureProperties>(
                    endGunSegments[0].geometry.coordinates, {
                        importType: 'endGunIrrigatedArea'
                    }
                )
                features.push(p);
            }
            else if (endGunSegments.length > 1) {
                const mp = multiPolygon<ImportExportFeatureProperties>(
                    endGunSegments.map(ia => ia.geometry.coordinates),{
                        importType: 'endGunIrrigatedArea'
                    }
                )
                features.push(mp);
            }
            
        }

        return features;
    }
    getDrawFeatures(drawMode?: string) {
        if (this.isActive && drawMode === "center_pivot_select") {
            const features: Feature[] = [];
            const result = this._getDrawFeaturesSelectMode();
            if (result.feature) {
                result.feature.properties = {
                    ...result.feature.properties,   
                    ...this.propertiesForAll,    
                    rdpFeatureType: "centerPivotSelectMode/irrigatedArea",
                    isCenterPivotSelect: true,
                    // verticies,
                    systemId: this.systemId,
                    id: this.systemId,
                    wheelTrackFeatureId: `wt-${this.systemId}`
                }
                features.push(result.feature);
            
            }
            if (result.wheelTracks) {
                const wheelTracks = multiLineString(result.wheelTracks.map(x => x.geometry.coordinates), {
                    rdpFeatureType: "centerPivotSelectMode/wheelTracks"
                })
                wheelTracks.id = `wt-${this.systemId}`
                features.push(wheelTracks);
            }
            
            {
                // SAC and end gun areas are added so that they are visible in center pivot select mode.
                // They are hidden from that mode when a vertex is dragged.
                
                // Render sac regions in center pivot select mode
                const sacPolygons = this.getSacAreaPolygon();
                sacPolygons.irrigatedArea.forEach(p => features.push(p));
                sacPolygons.guidanceWheelTracks.forEach(p => features.push(p));
                sacPolygons.nonGuidanceWheelTracks.forEach(p => features.push(p));
                sacPolygons.endGunAreas.forEach(p => features.push(p));
                // sacPolygons.endBoom.forEach(p => features.push(p));
                
                // end gun(s):
                // Note: Eng gun only added if SAC not added
                for (const eg of this.nonSacEndGuns) {
                    for (const onOff of eg.onOffs) {
                        features.push(FeatureHelpers.GetAnnulusSectorDrawFeature(
                            eg.center,
                            eg.throwStartFeet,
                            eg.throwEndFeet,
                            onOff.startBearing,
                            onOff.endBearing,
                            {
                                rdpFeatureType: "system/irrigatedArea-endGun",
                                ...this.propertiesForAll
                            },
                            this.featureHelperOptions
                        ))
                    }
                }
            }

            return features;
        }
        else {
            return this.generateSimpleSelectGeoJSON();
        }
    }
    getAreaPolygon(settings: {includeEndguns: boolean, includeSAC: boolean}): Polygon | undefined {
        // NOTE: If the pivot has no spans, it might be a point - if so return undefined!

        const pivotArea = this.getPivotAreaPolygonOrCenterPoint();
        if (pivotArea.type === 'Point') {
            return undefined;
        }
        const wrapAreas = this.getWrapSpanAreaPolygons(true);
        const dropAreas = this.getDropSpanAreaPolygons(true);

        let allAreas: (Polygon|MultiPolygon)[] = [
            pivotArea,
            ...wrapAreas,
            ...dropAreas.drops,
            ...dropAreas.wraps
        ];
        

        if (settings.includeSAC) {
            // sometimes the union between the SAC and the pivot does not successfully
            // create a single polygon (probaly due to the differnce in coord system used for
            // the calculation). As such, we extend the last sector by 0.1ft
            const sacIrrigatedArea = this.getSacAreaPolygon().irrigatedArea;
            if (sacIrrigatedArea.length) {
                const lss = this.getLastSpanSectors();
                for (const spanSegment of lss) {
                    const a = FeatureHelpers.GetAnnulusSectorDrawFeature(
                        spanSegment.center,
                        spanSegment.outerRadiusFeet,
                        spanSegment.outerRadiusFeet + 0.1,
                        spanSegment.startBearing,
                        spanSegment.startBearing + spanSegment.sweepAngle,
                        null,
                        { units: 'feet' }
                    ).geometry
                    allAreas.push(a);
                }
                allAreas.push(...sacIrrigatedArea.map(x => x.geometry));
            }
        }

        if (settings.includeEndguns) {
            allAreas.push(...this.getEndGunAreaPolygons(true));
        }

        if (allAreas.length === 1 && allAreas[0].type === "Polygon") {
            return allAreas[0];
        }
        
        let pUnion: Feature<Polygon | MultiPolygon> | null = feature(allAreas[0]);
        for (const p of allAreas.slice(1)) {
            pUnion = customUnion(pUnion, p);
            if (!pUnion) {
                console.log("Could not union these shapes", pUnion)
                return undefined;
            }
        }

        let pUnionSinglePolygon: Feature<Polygon>;
        if (pUnion.geometry.type === 'MultiPolygon') {
            // sometimes the union will create invalid polygons within it!
            // lets remove these:
            const pUnionFilteredCoords = pUnion.geometry.coordinates.map(poly => {
                const outerRing = poly.slice(0, 1)[0];
                const innerRings = poly.slice(1);
                if (!outerRing || outerRing.length < 4) {
                    return []
                }
                const innerRingsChecked = innerRings.filter((x) => x.length >= 4);
                return [
                    outerRing,
                    ...innerRingsChecked
                ]
            });
            const pUnion2 = multiPolygon(pUnionFilteredCoords);

            // sometimes union the objects creates small artifacts. We will test here and remove:
            const polygons = pUnion2.geometry.coordinates.map(polyRing => polygon(polyRing));
            const areas = polygons.map(p => area(p));
            // NOTE: The union of (particularly the end guns) result in some small features that do not join
            // the main polygon. So here, we are filtering out those with an area <= 2 m2
            const largeAreas = areas.filter(a => a > 2); // sq meters
            if (largeAreas.length > 1) {
                console.log("Union created a polygon with too many large areas:", featureCollection(polygons));
                return undefined;
                
            }
            else if (largeAreas.length === 0) {
                console.log("Union created a polygon with no large areas:", featureCollection(polygons));
                return undefined;
            }
            else {
                // just right!
                pUnionSinglePolygon = polygons[areas.findIndex(v => v === largeAreas[0])]
            }
        }
        else {
            pUnionSinglePolygon = pUnion as Feature<Polygon>;
        }

        // lets also remove all those artifacts from the polygon:
        const finalRings = pUnionSinglePolygon.geometry.coordinates.slice(0, 1);
        for (const innerRing of pUnionSinglePolygon.geometry.coordinates.slice(1)) {
            const p = polygon([ innerRing ]);
            if (area(p) > 0.5) {
                finalRings.push(innerRing);
            }
        }
        pUnionSinglePolygon = polygon(finalRings);
        return pUnionSinglePolygon.geometry;
    }
    public getLastSpanSectors() {
        if (getSpansWithoutSAC(this.system, SideEnum.Flanged).length === 0) return [];
        return calculateSpanSegments(this.system, getSpansWithoutSAC(this.system, SideEnum.Flanged).length - 1).segments;
    }

    // private methods:
    private setWrapspanInformationMember() {
        // gather clockwise/anticlockwise wrap span information, only if this is a partial pivot:
        const clockwiseWrapSpans: Omit<IWrapspanInformation, 'wheelTrackRadii' | 'wrapRadiusFeet'>[] = [];
        const anticlockwiseWrapSpans: Omit<IWrapspanInformation, 'wheelTrackRadii' | 'wrapRadiusFeet'>[] = [];
        if (isPartialPivot(this.system)) {
            let clockwiseWrapSpanRunningLength = 0;
            let anticlockwiseWrapSpanRunningLength = 0;
            for (let i = 0; i < getSpansWithoutEndOfSystem(this.system, SideEnum.Flanged).length; i++) {
                const span = this.system.FlangedSide.Span[i];
                const tower = this.system.FlangedSide.Tower[i];
                if (!Number.isFinite(spanf.LengthInFeet(this.system.FlangedSide, span))) {
                    // Don't draw spans where the length hasn't yet been selected
                    continue;
                }

                clockwiseWrapSpanRunningLength += spanf.LengthInFeet(this.system.FlangedSide, span);
                anticlockwiseWrapSpanRunningLength += spanf.LengthInFeet(this.system.FlangedSide, span);
                const clockwiseWrapAngleRelativeToPreviousSpanDegrees = getWrapAngle(this.system, tower, ECircleDirection.FWD);
                if (clockwiseWrapAngleRelativeToPreviousSpanDegrees 
                    && clockwiseWrapAngleRelativeToPreviousSpanDegrees !== 0) {
                    const lastCenter = clockwiseWrapSpans.length > 0 
                        ? clockwiseWrapSpans[clockwiseWrapSpans.length - 1].center 
                        : this.centerPoint;
                    const lastHeading = clockwiseWrapSpans.length > 0 
                        ? clockwiseWrapSpans[clockwiseWrapSpans.length - 1].headingTo
                        : this.bearingEnd;
                    const center = destination(lastCenter, clockwiseWrapSpanRunningLength, lastHeading, { units: 'feet' }).geometry;
                    clockwiseWrapSpanRunningLength = 0;
                    clockwiseWrapSpans.push({
                        center,
                        headingFrom: (lastHeading + 360) % 360,
                        headingTo: (lastHeading + clockwiseWrapAngleRelativeToPreviousSpanDegrees + 360) % 360,
                        effectiveFromSpanIndex: i + 1
                    });
                }
                const anticlockwiseWrapAngleRelativeToPreviousSpanDegrees = getWrapAngle(this.system, tower, ECircleDirection.REV);
                if (anticlockwiseWrapAngleRelativeToPreviousSpanDegrees 
                    && anticlockwiseWrapAngleRelativeToPreviousSpanDegrees !== 0) {
                    const lastCenter = anticlockwiseWrapSpans.length > 0 
                        ? anticlockwiseWrapSpans[anticlockwiseWrapSpans.length - 1].center 
                        : this.centerPoint;
                    const lastHeading = anticlockwiseWrapSpans.length > 0 
                        ? anticlockwiseWrapSpans[anticlockwiseWrapSpans.length - 1].headingFrom
                        : this.bearingStart;
                    const center = destination(lastCenter, anticlockwiseWrapSpanRunningLength, lastHeading, { units: 'feet' }).geometry;
                    anticlockwiseWrapSpanRunningLength = 0;
                    anticlockwiseWrapSpans.push({
                        center,
                        headingFrom: (lastHeading - anticlockwiseWrapAngleRelativeToPreviousSpanDegrees + 360) % 360,
                        headingTo: (lastHeading + 360) % 360,
                        effectiveFromSpanIndex: i + 1
                    });
                }
            }
        }
        
        const clockwise: IWrapspanInformation[] = [];
        for (const wrapSpan of clockwiseWrapSpans) {
            // accumulate wrap span wheel tracks
            let wrapRadiusFeet = 0;
            const wrapWheelTrackRadii: number[] = [];
            for (let i = wrapSpan.effectiveFromSpanIndex; i < getSpansWithoutSAC(this.system, SideEnum.Flanged).length; i++) {
                const span = this.system.FlangedSide.Span[i];
                if (!Number.isFinite(spanf.LengthInFeet(this.system.FlangedSide, span))) {
                    // Don't draw spans where the length hasn't yet been selected
                    continue;
                }
                wrapRadiusFeet += spanf.LengthInFeet(this.system.FlangedSide, span);
                wrapWheelTrackRadii.push(wrapRadiusFeet);
            }
            clockwise.push({
                ...wrapSpan,
                wheelTrackRadii: wrapWheelTrackRadii,
                wrapRadiusFeet
            })
        }

        const anticlockwise: IWrapspanInformation[] = [];
        for (const wrapSpan of anticlockwiseWrapSpans) {
            // accumulate wrap span wheel tracks
            let wrapRadiusFeet = 0;
            const wrapWheelTrackRadii: number[] = [];
            for (let i = wrapSpan.effectiveFromSpanIndex; i < getSpansWithoutSAC(this.system, SideEnum.Flanged).length; i++) {
                const span = this.system.FlangedSide.Span[i];
                if (!Number.isFinite(spanf.LengthInFeet(this.system.FlangedSide, span))) {
                    // Don't draw spans where the length hasn't yet been selected
                    continue;
                }
                wrapRadiusFeet += spanf.LengthInFeet(this.system.FlangedSide, span);
                wrapWheelTrackRadii.push(wrapRadiusFeet);
            }
            anticlockwise.push({
                ...wrapSpan,
                wheelTrackRadii: wrapWheelTrackRadii,
                wrapRadiusFeet
            })
        }
        
        this._wrapspanInformation = {
            clockwise,
            anticlockwise
        }
    }
    private _getDrawFeaturesSelectMode() {
        if (!this._cached_createSingleGeoJSONFeature) {
            this._cached_createSingleGeoJSONFeature = CenterPivotGeometryHelper.createSingleGeoJSONFeature(this.system, this.centerPoint);
        }
        const data = this._cached_createSingleGeoJSONFeature;
        return data;
    }
    private getWrapSpanAreaPolygons(letWrapIntrudeIntoPivot = false): Polygon[] {
        // Note, to union all these areas successfully. The wraps must overlap slightly the main system.
        // setting letWrapIntrudeIntoPivot=true will provide an overlapping section which will then get
        // removed again during a union. 
        // WARNING: Using this option will effect the area provided if not unioned with the
        // main section.
        const polygons: Polygon[] = [];
        
        let hasDropBeenEncountered = false;
        for (let i = 0; i < getSpansWithoutSAC(this.system, SideEnum.Flanged).length && !hasDropBeenEncountered; i++) {
            let spanSegments: ISpanSegment[];
            try {
                spanSegments = calculateSpanSegments(this.system, i).segments;
            } 
            catch (e) {
                console.log("error", e)
                continue;
            }
            for (const spanSegment of spanSegments) {
                if (spanSegment.hasAddedDropSpan) {
                    hasDropBeenEncountered = true;
                    break;
                }
                if (spanSegment.type === 'anticlockwiseWrap' || spanSegment.type === 'clockwiseWrap') {
                    let startBearing = spanSegment.startBearing;
                    let endBearing = spanSegment.startBearing + spanSegment.sweepAngle;
                    let innerRadiusFeet = spanSegment.innerRadiusFeet;
                    if (letWrapIntrudeIntoPivot) {
                        if (spanSegment.type === 'anticlockwiseWrap') {
                            endBearing += 0.1;
                        }
                        else if (spanSegment.type === 'clockwiseWrap') {
                            startBearing -= 0.1;
                        }
                        innerRadiusFeet -= 1;
                    }
                    let innerArc: Position[];
                    if (spanSegment.innerRadiusFeet === 0) {
                        innerArc = [ spanSegment.center.coordinates ]
                    }
                    else {
                        innerArc = FeatureHelpers.GetLineArcDrawFeature(
                            spanSegment.center, 
                            innerRadiusFeet,
                            startBearing,
                            endBearing,
                            null,
                            this.featureHelperOptions
                        ).geometry.coordinates;
                    }
                    const outerArc = FeatureHelpers.GetLineArcDrawFeature(
                        spanSegment.center, 
                        spanSegment.outerRadiusFeet,
                        startBearing,
                        endBearing,
                        null,
                        this.featureHelperOptions
                    );
                    if (!innerArc.length || !outerArc.geometry.coordinates.length) {
                        continue;
                    }
                    polygons.push(
                        polygon([
                            [
                                ...innerArc,
                                ...outerArc.geometry.coordinates.reverse(),
                                innerArc[0]
                            ]
                        ]).geometry
                    )
                }
            }
        }

        // console.log("wraps new", featureCollection(polygons.map(p => feature(p))))
        return polygons;
    }
    private getDropSpanAreaPolygons(letWrapIntrudeIntoPivot = false): { drops: Polygon[], wraps: Polygon[] } {
        // Note, to union all these areas successfully. The wraps must overlap slightly the main system.
        // setting letWrapIntrudeIntoPivot=true will provide an overlapping section which will then get
        // removed again during a union. 
        // WARNING: Using this option will effect the area provided if not unioned with the
        // main section.
        const drops: Polygon[] = [];
        const wraps: Polygon[] = [];
        
        let hasDropBeenEncountered = false;
        for (let i = 0; i < getSpansWithoutSAC(this.system, SideEnum.Flanged).length; i++) {
            let spanSegments: ISpanSegment[];
            try {
                spanSegments = calculateSpanSegments(this.system, i).segments;
            } 
            catch (e) {
                console.log("error", e)
                continue;
            }
            if (!hasDropBeenEncountered) {
                hasDropBeenEncountered = spanSegments.some(x => x.hasAddedDropSpan);
            }
            if (!hasDropBeenEncountered) continue;
            for (const spanSegment of spanSegments) {
                if (spanSegment.type === 'center' || spanSegment.type === 'anticlockwiseWrap' || spanSegment.type === 'clockwiseWrap') {
                    let startBearing = spanSegment.startBearing;
                    let endBearing = spanSegment.startBearing + spanSegment.sweepAngle;
                    let innerRadiusFeet = spanSegment.innerRadiusFeet;
                    if (letWrapIntrudeIntoPivot) {
                        if (spanSegment.type === 'anticlockwiseWrap') {
                            endBearing += 0.1;
                        }
                        else if (spanSegment.type === 'clockwiseWrap') {
                            startBearing -= 0.1;
                        }
                        innerRadiusFeet -= 1;
                    }
                    let innerArc: Position[];
                    if (spanSegment.innerRadiusFeet === 0) {
                        innerArc = [ spanSegment.center.coordinates ]
                    }
                    else {
                        innerArc = FeatureHelpers.GetLineArcDrawFeature(
                            spanSegment.center, 
                            innerRadiusFeet,
                            startBearing,
                            endBearing,
                            null,
                            this.featureHelperOptions
                        ).geometry.coordinates;
                    }
                    const outerArc = FeatureHelpers.GetLineArcDrawFeature(
                        spanSegment.center, 
                        spanSegment.outerRadiusFeet,
                        startBearing,
                        endBearing,
                        null,
                        this.featureHelperOptions
                    );
                    if (!innerArc.length || !outerArc.geometry.coordinates.length) {
                        continue;
                    }
                    if (spanSegment.type === 'center') {
                        drops.push(
                            polygon([
                                [
                                    ...innerArc,
                                    ...outerArc.geometry.coordinates.reverse(),
                                    innerArc[0]
                                ]
                            ]).geometry
                        )
                    }
                    else {
                        // then its a wrap on a drop
                        wraps.push(
                            polygon([
                                [
                                    ...innerArc,
                                    ...outerArc.geometry.coordinates.reverse(),
                                    innerArc[0]
                                ]
                            ]).geometry
                        )
                    }
                }
            }
        }

        // console.log("drop centers new", featureCollection(drops.map(p => feature(p))))
        // console.log("drop wraps new", featureCollection(wraps.map(p => feature(p))))

        return {
            drops, wraps
        };
    }
    private getPivotAreaPolygonOrCenterPoint(): Polygon | Point {
        // NOTE: If the pivot has no spans, it might be a point!
        if (getSpansWithoutEndOfSystem(this.system, SideEnum.Flanged).length === 0) {
            return this.centerPoint;
        }

        //assume we skip wrap spans in this calculation
        // main area arc
        let systemRadiusToDrop = 0;
        let hasDropped = false;
        for (let i = 0; i < getSpansWithoutSAC(this.system, SideEnum.Flanged).length && !hasDropped; i++) {
            const span = this.system.FlangedSide.Span[i];
            const tower = this.system.FlangedSide.Span[i];
            systemRadiusToDrop = spanf.EndingRadius(this.system, this.system.FlangedSide, span);
            if (tower.Disconnecting) {
                hasDropped = true;
            }
        }
        
        // if a span length is not defined, the systemRadius will be NaN
        if (!isFinite(systemRadiusToDrop)) {
            return this.centerPoint;
        }

        const fullSystemBoundary: Position[] = [];
        if (!this.isPartialPivot) {
            // simple case, just return the circle and return
            return FeatureHelpers.GetCircleDrawFeature(
                this.center,
                systemRadiusToDrop,
                null,
                this.featureHelperOptions
            ).geometry;
        }
        // then this is a partial pivot:
        // center
        fullSystemBoundary.push(this.center.coordinates);
        fullSystemBoundary.push(...FeatureHelpers.GetLineArcDrawFeature(
            this.center,
            systemRadiusToDrop,
            this.bearingStart,
            this.bearingEnd,
            null,
            this.featureHelperOptions
        ).geometry.coordinates);

        // center to close the polygon
        fullSystemBoundary.push(this.center.coordinates);
        const p = polygon([ fullSystemBoundary ]).geometry;
        return p;
    }
    private getEndGunAreaPolygons(intrudeIntoPivot = false): Polygon[] {
        // Note, to union all these areas successfully. The end guns must overlap slightly the main system.
        // setting intrudeIntoPivot=true will provide an overlapping section which will then get
        // removed again during a union. 
        // WARNING: Using this option will effect the area provided if not unioned with the
        // main section.
        const area: Polygon[] = [];
        const sacAreas = this.getSacAreaPolygon();
        if (sacAreas.irrigatedArea.length) {
            area.push(...sacAreas.endGunAreas.map(f => f.geometry))
        }
        else {
            for (const eg of this.nonSacEndGuns) {
                for (const onOff of eg.onOffs) {
                    let endGunThrowStart = eg.throwStartFeet;
                    if (intrudeIntoPivot) {
                         // as the main area will absorb this overlap on union, add start end gun radius -1ft to prevent turfjs bug during union
                        endGunThrowStart -= 1;
                    }
                    const endGunPoly = FeatureHelpers.GetAnnulusSectorDrawFeature(
                        eg.center,
                        endGunThrowStart,
                        eg.throwEndFeet,
                        onOff.startBearing,
                        onOff.endBearing,
                        {
                            rdpFeatureType: "system/endGunIrrigatedArea",
                            ...this.propertiesForAll
                        },
                        this.featureHelperOptions
                    ).geometry;
                    area.push(endGunPoly)
                } 
            }
        }
        return area;
    }

    // static methods:
    public static isCenterPivot(feature?: Feature) {
        if (!feature || !feature.properties) return false;
        const isCenterPivot = feature.properties.user_isCenterPivot || feature.properties.isCenterPivot;
        return isCenterPivot === true;
    }
    public static isEditable(feature?: Feature) {
        if (!feature || !feature.properties) return false;
        const isEditable = feature.properties.user_isEditable || feature.properties.isEditable;
        return isEditable === true;
    }
    public static getDefinition(feature?: Feature): IProperties | undefined {
        if (!feature || !feature.properties) return undefined;
        const systemId = feature.properties.user_systemId || feature.properties.systemId;
        const layoutId = feature.properties.user_layoutId || feature.properties.layoutId;
        const isCenterPivot = feature.properties.user_isCenterPivot || feature.properties.isCenterPivot;
        if (!isCenterPivot || !systemId || !layoutId) return undefined;
        const isEditable = feature.properties.user_isEditable || feature.properties.isEditable;
        return {
            isCenterPivot: true,
            systemId,
            layoutId,
            activeSystem: false,
            isEditable
        }
    }   
    public static createSingleGeoJSONFeature(system: ISystem, centerPoint: Point, options: { includeSac: boolean } = { includeSac: false }): IGeoJSONFeatureResult {
        
        // NOTE: To use the area functions in this static function I have 
        // created a "fake" project. This is ok here as no layout/project dependent 
        // functions are called from this static function.
        // TODO: Refactor so that the areas are calculated statically, or so that a system can
        // be used without project/layouts to generate the helper class
        const fakeProject: IProject = {
            layouts: {
                /* @ts-ignore */
                "1": {
                    systems: {
                        "1": system
                    }
                }
            }
        }
        const gh = new CenterPivotGeometryHelper(
            {
                project: fakeProject, layoutId: "1", systemId: "1"
            }, 
            {
                degreeIncrement: 1 // for speed
            }
        )

        const verticies: ISpanVertex[] = [];
        const wheelTracks: Feature<LineString, { spanIndex: number, type: 'anticlockwiseWrap' | 'center' | 'clockwiseWrap', wrapIndex?: number }>[] = [];
        const endBoomTrack: Feature<LineString, { type: 'anticlockwiseWrap' | 'center' | 'clockwiseWrap', wrapIndex?: number }>[] = [];
        verticies.push({
            handle: centerPoint,
            type: 'pivotCenter',
            spanIndex: 0,
            center: centerPoint,
        });
        // can only render if all spans lengths are defined:
        const someUndefinedLength = getSpansWithoutSAC(system, SideEnum.Flanged).some(x => !Number.isFinite(spanf.LengthInFeet(system.FlangedSide, x)));
        for (let i = 0; i < getSpansWithoutSAC(system, SideEnum.Flanged).length && !someUndefinedLength; i++) {
            let spanSegments: ISpanSegment[];
            let spanVerticies: ISpanVertex[];
            try {
                const result = calculateSpanSegments(system, i);
                spanSegments = result.segments;
                spanVerticies = result.verticies;
            } 
            catch (e) {
                console.log("error", e)
                continue;
            }
            const outerRing: Position[] = [];
            verticies.push(...spanVerticies);
            for (const spanSegment of spanSegments) {
                const arc = FeatureHelpers.GetLineArcDrawFeature(
                    spanSegment.center,
                    spanSegment.outerRadiusFeet,
                    spanSegment.startBearing,
                    spanSegment.startBearing + spanSegment.sweepAngle,
                    {
                        spanIndex: i,
                        type: spanSegment.type,
                        wrapIndex: spanSegment.wrapNumber
                    },
                    { units: 'feet', degreeIncrement: 1 }
                ) as Feature<LineString, { spanIndex: number, type: 'anticlockwiseWrap' | 'center' | 'clockwiseWrap', wrapIndex?: number }>;

                // dont add to wheel track if it is an endboom
                if (!system.FlangedSide.Span[i].EndBoom) {
                    wheelTracks.push(arc)
                }
                else {
                    endBoomTrack.push(arc);
                }
            }
        }
        
        const areaPolygon: Polygon | undefined = gh.getAreaPolygon({ includeEndguns: false, includeSAC: options.includeSac });

        if (!areaPolygon) {
            return {
                verticies: [verticies[0]],
                feature: point(centerPoint.coordinates)
            };        
        }
        return {
            feature: feature(areaPolygon) as Feature<Polygon> ,
            verticies,
            wheelTracks,
            endBoomTrack
        }
    }
}