import { ISacOptimizerSettingsFormState } from "../../../../../components/DealerSettingsDialog/SacOptimizerSettings";
import { MetersProjectionHelper } from "../../projection";
import { importPathData } from "../pathData/importPathData";

import { IGenerateSACReturn, ISACOptimizationResultGeometryRDP3 } from "../SAC/ISACOptimizationSolutionRDP3";
import { SACCentrePivotType } from "../SAC/SACCentrePivotType";
import { SACModel } from "../SAC/SACModel";
import { SACModelConfiguration } from "../SAC/SACModelConfiguration";
import { SACObstacleConstraints } from "../SAC/SACObstacleConstraints";
import { SACOptimizationProblem } from "../SAC/SACOptimizationProblem";
import { SACOptimizationSolution } from "../SAC/SACOptimizationSolution";
import { SACOrientation } from "../SAC/SACOrientation";
import { SACOptimizer } from "../SACOptimizer";

import { jsts } from "../jstsLib";

import { PathData } from "rdptypes/project/IPathData";
import { EndGunAreaContainer } from "../Objects/EndGunDataContainer";
import { IPivotData } from "../pivotData";

export const getSacParametersMeters = (type: SACType) => {
    
    const green = 51;
    const gray = 79;
    const gold = 100;
    const blue = 109;
    const orange = 114;
    const black = 121;

    let spanLengthFeet: number;
    let endBoomLengthFeet: number;
    let bellyProfileInches: number[];
    let orientation: SACOrientation;

    switch (type) {
        case SACType.MSAC_156_LEADING:
        case SACType.MSAC_156_TRAILING: {
            spanLengthFeet = 156;
            endBoomLengthFeet = 51;
            bellyProfileInches = [
                green,
                gray,
                gold,
                blue,
                gold,
                gray,
                green
            ]
            break;
        }
        case SACType.MSAC_175_LEADING:
        case SACType.MSAC_175_TRAILING: {
            spanLengthFeet = 175;
            endBoomLengthFeet = 61;
            bellyProfileInches = [
                green,
                gray,
                gold,
                orange,
                orange,
                gold,
                gray,
                green
            ]
            break;
        }
        case SACType.SAC_LEADING:
        case SACType.SAC_TRAILING: {
            spanLengthFeet = 193.511;
            endBoomLengthFeet = 85.857;
            bellyProfileInches = [
                green,
                gray,
                gold,
                orange,
                black,
                orange,
                gold,
                gray,
                green
            ]
            break;
        }
        case SACType.SSAC_LEADING:
        case SACType.SSAC_TRAILING: {
            spanLengthFeet = 212.9;
            endBoomLengthFeet = 105.15;
            bellyProfileInches = [
                green,
                gray,
                gold,
                orange,
                black,
                black,
                orange,
                gold,
                gray,
                green
            ]
            break;
        }
        default:
            throw new Error("Unknown SAC type");
    }

    switch (type) {        
        case SACType.SAC_LEADING:
        case SACType.SSAC_LEADING:
        case SACType.MSAC_156_LEADING:
        case SACType.MSAC_175_LEADING:
            orientation = SACOrientation.Leading;
            break;
        case SACType.SAC_TRAILING:
        case SACType.SSAC_TRAILING:
        case SACType.MSAC_156_TRAILING:
        case SACType.MSAC_175_TRAILING:
            orientation = SACOrientation.Trailing;
            break;
        default:
            throw new Error("Unknown SAC type");
    }

    const inchesToMetres = 0.0254;
    const feetToMeters = 0.3048;

    return {
        spanLength: spanLengthFeet * feetToMeters,
        endBoomLength: endBoomLengthFeet * feetToMeters,
        bellyProfile: bellyProfileInches.map(x => x * inchesToMetres),
        orientation
    }
}

export class OptimizeUtilities {

    static readonly EQUAL_DELTA = 0.000000000001;
    
    public static FindEndGunArea(startAzimuth: number, endAzimuth: number, pivotRadius: number, endGunThrow: number): number {
        let area: number;
        if (endAzimuth > startAzimuth)
            area = (((endAzimuth - startAzimuth) * (pivotRadius + endGunThrow) * (pivotRadius + endGunThrow)) / 2) - (((endAzimuth - startAzimuth) * pivotRadius * pivotRadius) / 2);
        else
            area = (((endAzimuth + (Math.PI * 2) - startAzimuth) * (pivotRadius + endGunThrow) * (pivotRadius + endGunThrow)) / 2) - (((endAzimuth + (Math.PI * 2) - startAzimuth) * pivotRadius * pivotRadius) / 2);
        return (area);
    }

    
    private static createSacOptimizationProblems(
        parentSystemMainPolygon: jsts.geom.Geometry, 
        pivotDatas: IPivotData[],
        eqBoundary: jsts.geom.Geometry, 
        sTowerBoundary: jsts.geom.Geometry, 
        waBoundary: jsts.geom.Geometry, 
        waObstacles: jsts.geom.Geometry[], 
        type: SACType, 
        spanObstacles: jsts.geom.Geometry[], 
        wheelObstacles: jsts.geom.Geometry[], 
        endGunThrowsMeters: number[],
        settings: ISacOptimizerSettingsFormState
    ): SACOptimizationProblem[] {

        const optimizationProblems: SACOptimizationProblem[] = [];
        const sacParametersMeters = getSacParametersMeters(type);
        for (const pData of pivotDatas) {
            const configuration = new SACModelConfiguration(
                sacParametersMeters.spanLength,
                sacParametersMeters.endBoomLength,
                sacParametersMeters.bellyProfile,
                sacParametersMeters.orientation,
                pData.radius
            );
            const model = new SACModel(configuration, settings);

            const maxThrow = Math.max(...endGunThrowsMeters, 0);
            const buffer = pData.radius + sacParametersMeters.spanLength + sacParametersMeters.endBoomLength + maxThrow;
            const bbox = new jsts.geom.GeometryFactory().createPolygon([
                new jsts.geom.Coordinate(pData.center.x - buffer, pData.center.y + buffer),
                new jsts.geom.Coordinate(pData.center.x + buffer, pData.center.y + buffer),
                new jsts.geom.Coordinate(pData.center.x + buffer, pData.center.y - buffer),
                new jsts.geom.Coordinate(pData.center.x - buffer, pData.center.y - buffer),
                new jsts.geom.Coordinate(pData.center.x - buffer, pData.center.y + buffer),
            ]);

            const objectConstraints = new SACObstacleConstraints({
                EquipmentBoundary: eqBoundary.intersection(bbox),
                STowerBoundary: sTowerBoundary.intersection(bbox),
                SpanObstacles: spanObstacles.map(x => x.intersection(bbox)),
                WheelObstacles: wheelObstacles.map(x => x.intersection(bbox)),
                WetBoundary: waBoundary.intersection(bbox),
                wetAreaObstacles: waObstacles.map(x => x.intersection(bbox)),
            }); 

            var optimizationProblem = new SACOptimizationProblem({
                Model: model,
                ObstacleConstraints: objectConstraints,
                PivotCentre: pData.center,
                ParentSystemPolygon: parentSystemMainPolygon,
                endGunThrowsMeters
            });

            let centrePivotAngleDifference = Math.abs(pData.endBearing - pData.startBearing) % 360;
            if (centrePivotAngleDifference > 180) {
                centrePivotAngleDifference = 360 - centrePivotAngleDifference;
            }

            if (centrePivotAngleDifference < 0.01) {
                optimizationProblem.CentrePivotType = SACCentrePivotType.Full;
            }
            else
            {
                optimizationProblem.CentrePivotType = SACCentrePivotType.Partial;
                optimizationProblem.PartialPivotAngleDegreesMin = pData.startBearing % 360;
                optimizationProblem.PartialPivotAngleDegreesMax = pData.endBearing % 360;
            }

            optimizationProblems.push(optimizationProblem);
        }
        return optimizationProblems;
    }

    // TODO: Improve this logic.
    // Sometimes when a SAC cannot be generated. Reduced pivot angles are offered that may lead to a SAC solution.
    // In this function, we will handle these reduced angles only for the first Pivot (i.e. pivot i = 0). The 
    // remaining pivots will be wrap spans. How can we handle these?
    public static async generateSACRDP3(
        parentSystemMainPolygon: jsts.geom.Geometry, 
        pivotDatas: IPivotData[],
        eqBoundary: jsts.geom.Geometry, 
        sTowerBoundary: jsts.geom.Geometry, 
        waBoundary: jsts.geom.Geometry, 
        waObstacles: jsts.geom.Geometry[], 
        type: SACType, 
        spanObstacles: jsts.geom.Geometry[], 
        wheelObstacles: jsts.geom.Geometry[], 
        endGunThrowsMeters: number[],
        isCancelled: () => Promise<boolean>,
        settings: ISacOptimizerSettingsFormState
    ): Promise<IGenerateSACReturn> {

        const optimizationProblems = OptimizeUtilities.createSacOptimizationProblems(
            parentSystemMainPolygon, 
            pivotDatas,
            eqBoundary, 
            sTowerBoundary,
            waBoundary, 
            waObstacles,
            type, 
            spanObstacles, 
            wheelObstacles,
            endGunThrowsMeters,
            settings
        );

        const solutions: SACOptimizationSolution[] = new Array(optimizationProblems.length);
        for (let i = 0; i < optimizationProblems.length; i++) {
            const optimizer = new SACOptimizer(optimizationProblems[i]);
            const optimizerResult = await optimizer.optimizeRDP3(isCancelled);
            if (optimizerResult.success === false) {
                if (i === 0 && optimizerResult.canReduce) {
                    return {
                        success: false,
                        canReduce: true,
                        reduce: optimizerResult.reduce
                    };
                }
                else {
                    return {
                        success: false,
                        canReduce: false
                    };
                }
            }
            solutions[i] = optimizerResult.solution;
        }

        const solution = this.mergeSolutions(solutions);
        const geometry = this.getGeometry(solution);

        return {
            success: true,
            result: {
                geometry
            },
        };
    }

    public static async generateSACRDP3_fromPathData(
        parentSystemMainPolygon: jsts.geom.Geometry, 
        pivotDatas: IPivotData[],
        eqBoundary: jsts.geom.Geometry, 
        sTowerBoundary: jsts.geom.Geometry, 
        waBoundary: jsts.geom.Geometry, 
        waObstacles: jsts.geom.Geometry[], 
        type: SACType, 
        spanObstacles: jsts.geom.Geometry[], 
        wheelObstacles: jsts.geom.Geometry[], 
        endGunThrowsMeters: number[],
        isCancelled: () => Promise<boolean>,
        settings: ISacOptimizerSettingsFormState,
        pathData: PathData[],
        metersProjectionHelper: MetersProjectionHelper,
    ): Promise<IGenerateSACReturn> {

        const optimizationProblems = OptimizeUtilities.createSacOptimizationProblems(
            parentSystemMainPolygon, 
            pivotDatas,
            eqBoundary, 
            sTowerBoundary,
            waBoundary, 
            waObstacles,
            type, 
            spanObstacles, 
            wheelObstacles,
            endGunThrowsMeters,
            settings
        );

        if (optimizationProblems.length !== 1) {
            console.log("Cannot import SAC Path Data for a system with wrap spans");
            return {
                success: false,
                canReduce: false
            };
        }

        const optimizationProblem = optimizationProblems[0];
        const optimizer = new SACOptimizer(optimizationProblem);
        const problem = optimizer.Problem;
        const solution = importPathData(problem, pathData, metersProjectionHelper);
        if (solution) {
            const geometry = this.getGeometry(solution);
            return {
                success: true,
                result: {
                    geometry
                },
            };
        }
        else {
            return {
                success: false,
                canReduce: false
            };
        }
    }

    private static mergeSolutions(solutions: SACOptimizationSolution[]) {
        if (solutions.length === 1) {
            return solutions[0];
        }
        
        solutions.sort((a, b) => {
            return b.Problem.Model.Configuration.PivotSpanLengthMetres - a.Problem.Model.Configuration.PivotSpanLengthMetres;
        });
        const sortedSolutions: SACOptimizationSolution[] = [];
        sortedSolutions.push(solutions[0]);
        const factory = new jsts.geom.GeometryFactory();
        for (let i = 1; i < solutions.length; i++)
        {
            const leftProblem = sortedSolutions[0].problem;
            const leftLine = factory.createLineString([
                leftProblem.PivotCentre,
                new jsts.geom.Coordinate(
                    leftProblem.PivotCentre.x 
                        + leftProblem.Model.Configuration.PivotSpanLengthMetres * Math.sin(leftProblem.PartialPivotAngleDegreesMin * Math.PI / 180.0),
                    leftProblem.PivotCentre.y 
                        + leftProblem.Model.Configuration.PivotSpanLengthMetres * Math.cos(leftProblem.PartialPivotAngleDegreesMin * Math.PI / 180.0)
                )
            ])

            const rightProblem = sortedSolutions[sortedSolutions.length - 1].problem;
            const rightLine =  factory.createLineString([
                rightProblem.PivotCentre,
                new jsts.geom.Coordinate(
                    rightProblem.PivotCentre.x 
                        + rightProblem.Model.Configuration.PivotSpanLengthMetres * Math.sin(rightProblem.PartialPivotAngleDegreesMax * Math.PI / 180.0),
                    rightProblem.PivotCentre.y 
                        + rightProblem.Model.Configuration.PivotSpanLengthMetres * Math.cos(rightProblem.PartialPivotAngleDegreesMax * Math.PI / 180.0)
                )
            ])
            
            
            
            if (leftLine.distance(factory.createPoint(solutions[i].Problem.PivotCentre)) <
                rightLine.distance(factory.createPoint(solutions[i].Problem.PivotCentre)))
            {
                sortedSolutions.unshift(solutions[i]);
            }
            else
            {
                sortedSolutions.push(solutions[i]);
            }
        }

        return SACOptimizationSolution.Union(sortedSolutions);
    }

    private static getGeometry(solution: SACOptimizationSolution): ISACOptimizationResultGeometryRDP3 {        
        const endBoomTrack = solution.EndBoomTrack.Lines.map(line => (
            line.Vertices.map(v => [v.X, v.Y] as [number, number])
        ));
        const guidanceWheelTrack = solution.GuidanceWheelTrack.Lines.map(line => (
            line.Vertices.map(v => [v.X, v.Y] as [number, number])
        ));
        const nonGuidanceWheelTrack = solution.NonGuidanceWheelTrack.Lines.map(line => (
            line.Vertices.map(v => [v.X, v.Y] as [number, number])
        ));
        let converageShapePolygons: jsts.geom.Polygon[] = [];
        switch (solution.CoverageShape.getGeometryType()) {
            case 'Polygon': {
                converageShapePolygons.push(solution.CoverageShape as jsts.geom.Polygon);
                break;
            }
            case 'MultiPolygon': {
                const multiPolygon = solution.CoverageShape as jsts.geom.MultiPolygon;
                for (let i = 0; i < multiPolygon.getNumGeometries(); i++) {
                    converageShapePolygons.push(multiPolygon.getGeometryN(i) as jsts.geom.Polygon);
                }        
                break;
            }
        }

        let coverageShapeVerticies: [number, number][][][] = [];
        for (const polygon of converageShapePolygons) {
            const outerRing = polygon.getExteriorRing();
            const innerRings: jsts.geom.LinearRing[] = [];
            for (let i = 0; i < polygon.getNumInteriorRing(); i++) {
                innerRings.push(polygon.getInteriorRingN(i));
            }
            coverageShapeVerticies.push(
                [
                    outerRing,
                    ...innerRings
                ].map(ring => ring.getCoordinates().map(coord => [ coord.x, coord.y ]))
            )
        }     
        
        
            // START: EndGun Coverage
        const endGunVerticies: [number, number][][][][] = []; // primary, the secondary
        
        for (const endGun of solution.endGuns) {
            const crntEndGunVerticies: [number, number][][][] = [];
            for (const endGunArea of endGun.EndGunAreas) {
                const egc = this.BuildAnEndGunCoverage(endGunArea);
                crntEndGunVerticies.push(egc.polygon);
            }
            endGunVerticies.push(crntEndGunVerticies);
        }
        // END: EndGun Coverage
        
        return {
            nonGuidanceWheelTrack,
            guidanceWheelTrack,
            coverageShapeVerticies,
            endGunVerticies,
            endBoomTrack
        }
    }

    private static BuildAnEndGunCoverage(
        endGunAreaContainer: EndGunAreaContainer
    ): {
        polygon: [number,number][][]
    } {

        const innerRing: [number, number][] = endGunAreaContainer.positions.map(p => {
            return [
                endGunAreaContainer.CenterLongitude + p.start.X,
                endGunAreaContainer.CenterLatitude + p.start.Y,
            ];
        });
        const outerRing: [number, number][] = endGunAreaContainer.positions.map(p => {
            return [
                endGunAreaContainer.CenterLongitude + p.end.X,
                endGunAreaContainer.CenterLatitude + p.end.Y,
            ];
        });

        return {
            polygon: [
                [
                    ...innerRing,
                    ...outerRing.reverse(),
                    innerRing[0]
                ]
            ]
        }
    }
}


// /// <summary>
// /// Enum to specify the type of MSAC/SAC/SSAC.
// /// </summary>
export enum SACType {
    SAC_TRAILING = 0,
    SAC_LEADING,
    SSAC_TRAILING,
    SSAC_LEADING,
    MSAC_156_TRAILING,
    MSAC_156_LEADING,
    MSAC_175_TRAILING,
    MSAC_175_LEADING,
    NOT_SPECIFIED
}