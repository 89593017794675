import { Box, FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC, useContext } from "react";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import { FEXContext } from "../../../../foreignExchange";
import { pricingHelper } from "../../../../helpers/pricingHelper";
import DealerDataCtx from "../../../../userData/DealerDataCtx";
import PercentageTableRow from "./PercentageTableRow";
import PricingTableRow from "./PricingTableRow";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
};

const ProposalInformationPricingTableRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const user = useContext(DealerDataCtx);
    const fex = useContext(FEXContext);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];
    const ph = pricingHelper({ system, user, fex });

    const subTotalMinusTaxUsdCents = ph.totals.subTotalMinusTaxMinorUnits("USD", {
        system: true,
        installation: true,
        freight: true,
        pivotPad: true,
        miscItems: true,
        tradeIn: true
    });
    const taxUsdCents = ph.totals.taxMinorUnits("USD", {
        system: true,
        installation: true,
        freight: true,
        pivotPad: true,
        miscItems: true,
        tradeIn: true
    });

    const totalUsdCents = subTotalMinusTaxUsdCents + taxUsdCents;

    const prefix = "proposal-information.";

    return (
        <>
            <TableContainer component={Box}>
                <Table sx={{marginBottom: "10px"}}>
                    {
                        ph.localCurrency?.symbol && (
                            <TableHead>
                                <TableCell sx={{textAlign: "right", padding: "3px 16px"}} />
                                <TableCell sx={{textAlign: "center", padding: "3px 16px"}}>USD</TableCell>
                                <TableCell sx={{textAlign: "center", padding: "3px 16px"}}>{ph.localCurrency.symbol}</TableCell>
                            </TableHead>
                        )
                    }
                    <TableBody>
                        <PricingTableRow
                            label={`${t("common-phrases.sltListPrice")}${ph.system.anyPriceIsNaN? "*" : ""}`}
                            priceCents={ph.system.totalListPriceUsdCents}
                            canBeTaxable={false}
                            priceReadonly
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={"USD"}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PricingTableRow 
                            label={`${t("common-phrases.sltCustomerPrice")}${ph.system.anyPriceIsNaN? "*" : ""}`}
                            priceCents={ph.system.systemPriceUsdCents}
                            canBeTaxable
                            saveIsTaxable={(taxable) => ph.system.saveIsTaxable(taxable, props.dbPrj, props.layoutId, props.systemId, authState)}
                            isTaxable={ph.system.isTaxable}
                            priceReadonly
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={"USD"}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PricingTableRow 
                            label={"+ " + t("common-phrases.sltInstallation")}
                            priceCents={ph.installation.priceMinorUnits}
                            savePrice={(priceCents) => ph.installation.savePrice(priceCents, props.dbPrj, props.layoutId, props.systemId, authState)}
                            canBeTaxable
                            saveIsTaxable={(taxable) => ph.installation.saveIsTaxable(taxable, props.dbPrj, props.layoutId, props.systemId, authState)}
                            isTaxable={ph.installation.isTaxable}
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={ph.installation.currency}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PricingTableRow 
                            label={"+ " + t("common-phrases.sltFreight")}
                            priceCents={ph.freight.priceMinorUnits}
                            savePrice={(priceCents) => ph.freight.savePrice(priceCents, props.dbPrj, props.layoutId, props.systemId, authState)}
                            canBeTaxable
                            saveIsTaxable={(taxable) => ph.freight.saveIsTaxable(taxable, props.dbPrj, props.layoutId, props.systemId, authState)}
                            isTaxable={ph.freight.isTaxable}
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={ph.freight.currency}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PricingTableRow 
                            label={"+ " + t(prefix + "pivot-pads")}
                            priceCents={ph.pivotPad.priceMinorUnits}
                            savePrice={(priceCents) => ph.pivotPad.savePrice(priceCents, props.dbPrj, props.layoutId, props.systemId, authState)}
                            canBeTaxable
                            saveIsTaxable={(taxable) => ph.pivotPad.saveIsTaxable(taxable, props.dbPrj, props.layoutId, props.systemId, authState)}
                            isTaxable={ph.pivotPad.isTaxable}
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={ph.pivotPad.currency}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PricingTableRow
                            label={"+ " + t(prefix + "misc-items")}
                            priceCents={ph.miscItems.preTaxTotalCostMinorUnits}
                            canBeTaxable={true}
                            taxableReadonly
                            isTaxable={ph.miscItems.anyTaxable}
                            priceReadonly
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={ph.miscItems.currency}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PricingTableRow 
                            label={"- " + t(prefix + "trade-in")}
                            priceCents={ph.tradeIn.priceMinorUnits}
                            savePrice={(priceCents) => ph.tradeIn.savePrice(priceCents, props.dbPrj, props.layoutId, props.systemId, authState)}
                            canBeTaxable
                            saveIsTaxable={(taxable) => ph.tradeIn.saveIsTaxable(taxable, props.dbPrj, props.layoutId, props.systemId, authState)}
                            isTaxable={ph.tradeIn.isTaxable}
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={ph.tradeIn.currency}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PricingTableRow
                            label={`= ${t(prefix + "sub-total")}${ph.system.anyPriceIsNaN? "*" : ""}`}
                            priceCents={subTotalMinusTaxUsdCents}
                            canBeTaxable={false}
                            priceReadonly
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={"USD"}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PricingTableRow
                            label={`+ ${t(prefix + "sales-tax")}${ph.system.anyPriceIsNaN? "*" : ""}`}
                            priceCents={taxUsdCents}
                            canBeTaxable={false}
                            priceReadonly
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={"USD"}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PricingTableRow
                            label={`= ${t(prefix + "total")}${ph.system.anyPriceIsNaN? "*" : ""}`}
                            priceCents={totalUsdCents}
                            canBeTaxable={false}
                            priceReadonly
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={"USD"}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
            {
                ph.system.anyPriceIsNaN && <Typography sx={{ fontSize:"0.8em", textAlign: "right"}}>
                <strong>*Prices are incomplete - price is missing for some components.</strong>       
            </Typography>
            }
            <TableContainer component={Box} sx={{marginTop: "10px"}}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={(ph.localCurrency?.symbol ?? "USD") === "USD" ? 3 : 5}>
                            <Typography>Price computation</Typography>
                            <RadioGroup 
                                row
                                value={ph.downPayment.method}
                                onChange={(ev) => {
                                    const v = ev.target.value;
                                    if (v === "Percent" || v === "Price") {
                                        ph.downPayment.setMethod(v, props.dbPrj, props.layoutId, props.systemId, authState)
                                    }
                                }}
                            >
                                <FormControlLabel 
                                    value={"Percent"} 
                                    control={<Radio />} 
                                    label={"Percent"} />
                                <FormControlLabel 
                                    value={"Price"} 
                                    control={<Radio />} 
                                    label={"Price"} />
                            </RadioGroup>
                            </TableCell>
                        </TableRow>
                        <PercentageTableRow 
                            priceComputation={ph.downPayment.method}
                            label={t("common-phrases.sltDownPayment")}
                            percentage={ph.downPayment.downPayment.percent}
                            saveUsdCents={(usdCents) => ph.downPayment.downPayment.setMinorUnits(usdCents, props.dbPrj, props.layoutId, props.systemId, authState)}
                            savePercentage={(percentage) => ph.downPayment.downPayment.setPercent(percentage, props.dbPrj, props.layoutId, props.systemId, authState)}
                            usdCents={ph.downPayment.downPayment.minorUnits}
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={ph.downPayment.currency}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PercentageTableRow 
                            priceComputation={ph.downPayment.method}
                            label={t("common-phrases.sltDueOnDelivery")}
                            percentage={ph.downPayment.dueOnDelivery.percent}
                            saveUsdCents={(usdCents) => ph.downPayment.dueOnDelivery.setMinorUnits(usdCents, props.dbPrj, props.layoutId, props.systemId, authState)}
                            savePercentage={(percentage) => ph.downPayment.dueOnDelivery.setPercent(percentage, props.dbPrj, props.layoutId, props.systemId, authState)}
                            usdCents={ph.downPayment.dueOnDelivery.minorUnits}
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={ph.downPayment.currency}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                        <PercentageTableRow
                            priceComputation={ph.downPayment.method}
                            label={t("common-phrases.sltDueOnInstallation")}
                            percentage={Math.round(ph.downPayment.dueOnInstallation.percent * 10) / 10}
                            usdCents={ph.downPayment.dueOnInstallation.minorUnits}
                            readonly
                            localCurrency={ph.localCurrency?.symbol}
                            valueCurrency={ph.downPayment.currency}
                            rate={ph.localCurrency?.fexAvailable && ph.localCurrency?.rate}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ProposalInformationPricingTableRenderer;