import * as turf from "@turf/turf";
import { Feature, LineString, MultiPolygon, Polygon, Position, along, area, buffer, convertArea, coordAll, destination, difference, feature, intersect, length, lineString, multiLineString, point, polygon, union } from "@turf/turf";
import { customDifference, ptldCustom } from "rdptypes/geometry/helpers/turf";
import RdpGeometryHelper from "rdptypes/geometry/systems/lateral";
import { getCombineLateralLinePolygons, getLateralLinePolygons, getLateralLineString, getSegmentLength, getSegmentLinePositions, getSegmentLine_Line } from "rdptypes/geometry/systems/lateral/helpers";
import { EndGunDescription, ILateralSpanTowerV2, ILineSegment, IProperties } from "rdptypes/geometry/systems/lateral/interfaces";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import ISystem from "rdptypes/project/ISystem";
import { EndGunTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IsPivotingLateral } from "roedata/roe_migration/SystemFunctions";
import { ImportExportFeature, ImportExportFeatureProperties } from "../../../helpers/importExport";
import { EndGunLocation, getEndGunThrow } from "../../../model/project/IEndGun";
import ILayout from "../../../model/project/ILayout";
import IProject from "../../../model/project/IProject";
import calculateEngGunOnOffs from "../../../optimization/centerPivot/endgun";
import { BoundaryHelper } from "../../BoundaryHelper";
import { IElevationPointFeature } from "../../GeometryProvider/IElevationPointFeature";
import { mapElevationToFeature } from "../../GeometryProvider/mapElevationToFeature";
import { ObstacleHelper } from "../../ObstacleHelper";
import CenterPivotGeometryHelper from "../CenterPivotGeometryHelper";
import { IBufferedSystemPolygonsForSystemClearancePolygons, IGetSystemValidityArgs, ILayoutClearancePolygons, getLayoutClearancePolygons, getSystemClearancePolygons } from "../interfaces";
import { ISpanVertex } from "./systemCoordinates";
import mapboxgl = require("mapbox-gl");

interface ILateralGeometryHelperArgs {
    layoutId: string;
    systemId: string;
    project: IProject;
}
interface ILateralGeometryHelperOptions {
    isActive?: boolean;
}

export default class LateralGeometryHelper extends RdpGeometryHelper<IProperties> {

    private project: IProject;
    private layout: ILayout;
    private layoutId: string;
    private systemId: string;    
    private _systemValidityArgs: IGetSystemValidityArgs | null = null;
    
    constructor(args: ILateralGeometryHelperArgs, options?: ILateralGeometryHelperOptions) {

        const { layoutId, systemId, project } = args;
        const isActive = options?.isActive ?? false;

        if (!project) {
            throw new Error("No project associated with this system");
        }
        const layout = project.layouts[layoutId];
        if (!layout) {
            throw new Error("No layout associated with this system");
        }
        const system = layout.systems[systemId];
        super({ 
            system,
            properties: {
                isLateral: true,
                systemId: systemId,
                layoutId: layoutId,
                activeSystem: isActive
            }
        });

        this.project = project;
        this.layout = layout;
        this.layoutId = layoutId;
        this.systemId = systemId;
        this.isActive = options?.isActive ?? this.isActive;
    }

    // public accessors:
    public get feedLineSegments(): ILineSegment[] {
        if (this._feedLineSegments === undefined) {
            this._calculateDirectionalSpanTowersV2();
        }
        return this._feedLineSegments!;
    }
    public get propertiesForAll(): IProperties {        
        return {
            isLateral: true,
            systemId: this.systemId,
            layoutId: this.layoutId,
            activeSystem: this.isActive
        }
    }

    // private methods:
    private isFlexDropValid(): boolean {
        const start = this.system.Lateral.dropSpanStartRelativeToPreviousSpanStart || 0;
        const end = this.system.Lateral.dropSpanEndRelativeToPreviousSpanEnd || 0;
        if (start === 0 && end === 0) {
            return true;
        }
        const l = length(feature(this.feedCenterLine()), { units: 'feet' });
        if (l - end - start < 1) {
            // invalid flex drop:
            return false;
        }
        return true;
    }

    // public methods:
    public feedLineOrPolygon(): LineString | Polygon {
        if (!this.isCanalFeed) {
            return this.feedCenterLine();
        }
        
        const feed = this.feedCenterLine();
        return polygon([
            [
                ...feed.coordinates,
                ...this.flexSideCenterLine().coordinates.reverse(),
                feed.coordinates[0]
            ]
        ]).geometry;
    }
    public calculateElevationProfile(map: mapboxgl.Map) {
        const FEED_RESOLUTION = 10;
        const getSpanTowerAtRadius = (spanTowers:  ILateralSpanTowerV2[], radius: number) => {
            for (const spanTower of spanTowers) {
                if (spanTower.insideRadius <= spanTower.outsideRadius) {
                    if (spanTower.insideRadius <= radius && spanTower.outsideRadius >= radius) {
                        return spanTower;
                    }
                }
                else {
                    if (spanTower.insideRadius >= radius && spanTower.outsideRadius <= radius) {
                        return spanTower;
                    }
                }
            }
            return null;
        }
        const getSpanTowerLinePosition = (spanTower:  ILateralSpanTowerV2, feedStart: number, radius: number) => {
            if (!spanTower) return null;
            let rStart = 0;
            for (const s of spanTower.leftLostSegments) {
                rStart += getSegmentLength(s, 0);
                if (rStart > feedStart) return null;
            }
            let rEnd = rStart;
            for (let i = 0; i < spanTower.segments.length; i++) {
                const s = spanTower.segments[i];
                if (s.type !== "line") {
                    // we are only considering line sections here,
                    // it is assumed radius sections do not add any length to the line position
                    continue;
                }
                rStart = rEnd;
                rEnd += getSegmentLength(s, 0);
                if (rEnd < feedStart) continue;
                const trimStart = feedStart - rStart;
                if (trimStart === 0) {
                    return getSegmentLinePositions(s, radius)[0];
                }
                if (rEnd === feedStart) {
                    return getSegmentLinePositions(s, radius).slice(-1)[0];
                }
                const a = getSegmentLinePositions(s, radius, { trimStart: feedStart - rStart });
                return a[0];
            }
            return null;
        }
        const getSpanTowerPivotPosition = (spanTower:  ILateralSpanTowerV2, feedStart: number, radius: number, center: Position) => {
            if (!spanTower) return null;
            for (let i = 0; i < spanTower.segments.length; i++) {
                const s = spanTower.segments[i];
                if (s.type === "line") {
                    // we are only considering pivot sections here
                    continue;
                }
                if (s.center[0] === center[0] && s.center[1] === center[1]) {
                    return getSegmentLinePositions(s, radius);
                }
            }
            return null;
        }
        const getMaxElevationFeatureFromPositions = (map: mapboxgl.Map, positions: Position[]): IElevationPointFeature | null => {
            const elevationPositions = positions
                .map(x => mapElevationToFeature(map, x))
                .filter(x => x && x.properties.elevationMeters !== null);
            if (elevationPositions.length) {
                const best = elevationPositions.reduce(
                    (prev, crnt) => {
                        if (crnt.properties.elevationMeters > prev.properties.elevationMeters) {
                            return crnt;
                        }
                        return prev;
                    },
                    elevationPositions[0]
                )
                return best;
            }
            return null;
        }
        
        interface IData {
            offset: number;
            feedLineElevationFeature: IElevationPointFeature;
            flangedSide: {
                radius: number;
                feedLineElevationFeature: IElevationPointFeature;
            }[];
            flexSide: {
                radius: number;
                feedLineElevationFeature: IElevationPointFeature;
            }[];
        }
        const data: IData[] = [];
        let runningOffset = 0;
        const generateRadiusOffsets = (maxRadius: number) => {
            const radiusOffsets: number[] = [];
            if (maxRadius > 0) {
                for (let i = 0; i < maxRadius; i += FEED_RESOLUTION) {
                    radiusOffsets.push(i)
                }
                radiusOffsets.push(maxRadius);
            }
            else if (maxRadius < 0) {
                for (let i = 0; i > maxRadius; i -= FEED_RESOLUTION) {
                    radiusOffsets.push(i)
                }
                radiusOffsets.push(maxRadius);
            }
            return radiusOffsets;
        }
        const flangedSideRadii: number[] = [];
        if (this.directionalRigidSideSpanTowers.length) {
            const max = this.directionalRigidSideSpanTowers.slice(-1)[0].outsideRadius;
            flangedSideRadii.push(...generateRadiusOffsets(max));
        }
        const flexSideRadii: number[] = [];
        if (this.directionalFlexSideSpanTowers.length) {
            const max = this.directionalFlexSideSpanTowers.slice(-1)[0].outsideRadius;
            flexSideRadii.push(...generateRadiusOffsets(max));
        }

        // we need to use the rigid side segments as the feed line does not include any pivots or retracing
        // There will always be rigid side towers if there are spans.
        if (!this.directionalRigidSideSpanTowers.length) return []
        const insideRigidTower = this.directionalRigidSideSpanTowers[0];

        for (const segment of insideRigidTower.segments) {
            let segmentOffset = 0;
            if (segment.type !== 'line') {
                // then its a radius:
                const d: IData = {
                    offset: runningOffset,
                    flangedSide: [],
                    flexSide: [],
                    feedLineElevationFeature: mapElevationToFeature(map, segment.center),
                }
                for (const radius of flangedSideRadii) {
                    const spanTower = getSpanTowerAtRadius(this.directionalRigidSideSpanTowers, radius);
                    const positions = getSpanTowerPivotPosition(spanTower, runningOffset + segmentOffset, radius, segment.center);
                    const maxElevationFeature = getMaxElevationFeatureFromPositions(map, positions);
                    if (maxElevationFeature) {
                        d.flangedSide.push({
                            radius,
                            feedLineElevationFeature: maxElevationFeature
                        })
                    }
                }
                for (const radius of flexSideRadii) {
                    const spanTower = getSpanTowerAtRadius(this.directionalFlexSideSpanTowers, radius);
                    const positions = getSpanTowerPivotPosition(spanTower, runningOffset + segmentOffset, radius, segment.center);
                    const maxElevationFeature = getMaxElevationFeatureFromPositions(map, positions);
                    if (maxElevationFeature) {
                        d.flexSide.push({
                            radius,
                            feedLineElevationFeature: maxElevationFeature
                        })
                    }
                }
                data.push(d);
            }
            else {
                // then its a line
                const segmentLength = getSegmentLength(segment, 0);
                const segmentOffsets: number[] = [];
                for (let i = 0; i < segmentLength; i += FEED_RESOLUTION) {
                    segmentOffsets.push(i);
                }
                segmentOffsets.push(segmentLength);
                for (const segmentOffset of segmentOffsets) {
                    const segmentSections = getSegmentLine_Line(segment, 0, { trimStart: segmentOffset });
                    const d: IData = {
                        offset: runningOffset + segmentOffset,
                        feedLineElevationFeature: mapElevationToFeature(map, segmentSections[0]),
                        flangedSide: [],
                        flexSide: []
                    }
                    for (const radius of flangedSideRadii) {
                        const spanTower = getSpanTowerAtRadius(this.directionalRigidSideSpanTowers, radius);
                        try {
                            const position = getSpanTowerLinePosition(spanTower, runningOffset + segmentOffset, radius);
                            if (position) {
                                d.flangedSide.push({
                                    radius,
                                    feedLineElevationFeature: mapElevationToFeature(map, position)
                                })
                            }
                        }
                        catch {
                            console.warn(`Flanged elevation position (feed:${segmentOffset}, distance:${radius}) could not be calculated`);
                        }
                    }
                    for (const radius of flexSideRadii) {
                        const spanTower = getSpanTowerAtRadius(this.directionalFlexSideSpanTowers, radius);
                        try {
                            const position = getSpanTowerLinePosition(spanTower, runningOffset + segmentOffset, radius);
                            if (position) {
                                d.flexSide.push({
                                    radius,
                                    feedLineElevationFeature: mapElevationToFeature(map, position)
                                })
                            }
                        }
                        catch (e) {
                            console.warn(`Flex elevation position (feed:${segmentOffset}, distance:${radius}) could not be calculated`);
                        }
                    }
                    data.push(d);
                }
                runningOffset += segmentLength;
            }
        }
        
        return data;
    } 
    public getSystemValidityArgs(_layoutClearancePolygons?: ILayoutClearancePolygons, buffedSystemPolygons?: IBufferedSystemPolygonsForSystemClearancePolygons) {
        if (!this._systemValidityArgs) {
            const systemAreaPolygon = this.getAreaPolygon();
            const layoutClearancePolygons = _layoutClearancePolygons ?? getLayoutClearancePolygons(
                this.project,
                this.layoutId
            );
            const systemClearancePolygons = getSystemClearancePolygons(
                this.project,
                this.layoutId,
                this.systemId,
                buffedSystemPolygons
            );
            this._systemValidityArgs = {
                systemClearanceObstacles: layoutClearancePolygons.clearanceObstacles,
                systemClearanceBoundaries: layoutClearancePolygons.clearanceBoundaries,
                systemAreaPolygon,
                systemClearanceWheelObstacles: layoutClearancePolygons.clearanceWheelObstacles,
                systemClearanceSystemObstacles: systemClearancePolygons.clearanceSystemObstacles,
                wheelTracks: this.getWheelTracks(),
                allowOverlap: this.system.overlapping || false,
                feedLine: this.feedLineOrPolygon()
            }
        }
        return this._systemValidityArgs!;
    }
    public calculateEndGunPolygonsForSide(side: 'rigid' | 'flex', additionalObstacles: Polygon[] = []): EndGunDescription {
        const DEFAULT_NO_ENDGUNS_RETURN: EndGunDescription = { features: [], endGunInformation: [] };
        const features: {feature: Feature<Polygon>, isPrimary: boolean}[] = [];        
        const endGunInformation: { endGun: EndGunTypes, isPrimary: boolean, onOffs: { on: number, off: number }[], throwFeet: number }[] = [];        
        if (side === 'flex' && !this.hasFlexSide) { 
            return DEFAULT_NO_ENDGUNS_RETURN;
        }
        const nonBufferedBoundary = this.layout.fieldBoundary
            ? BoundaryHelper.getPolygon(this.layout.fieldBoundary)
            : undefined;
        if (!nonBufferedBoundary) {
            return DEFAULT_NO_ENDGUNS_RETURN;
        }

        const nonBufferedAllowableAreaPolygon = this.layout.wetAreaBoundary
            ? BoundaryHelper.getPolygon(this.layout.wetAreaBoundary)
            : nonBufferedBoundary;
        const allowableAreaPolygonWithSystem = turf.booleanContains(nonBufferedAllowableAreaPolygon, this.lateralCenterLine)
            ? nonBufferedAllowableAreaPolygon
            : undefined;
        if (!allowableAreaPolygonWithSystem) {
            return DEFAULT_NO_ENDGUNS_RETURN;
        }

        let _allowableAreaPolygon: Feature<Polygon | MultiPolygon> | null = feature(
            allowableAreaPolygonWithSystem
        );
        const tryAddObstacleToAllowableAreaPolygon = (obstacle: Polygon | Feature<Polygon | MultiPolygon>): boolean => {
            try {
                _allowableAreaPolygon = customDifference(_allowableAreaPolygon, obstacle);
                return true;
            }
            catch {
                _allowableAreaPolygon = null;
                return false;
            }
        }
        const nonBufferedObstacles = this.layout.obstacles.map(x => ObstacleHelper.getPolygon(x));
        for (const obs of nonBufferedObstacles) {
            if (!tryAddObstacleToAllowableAreaPolygon(obs)) {
                return DEFAULT_NO_ENDGUNS_RETURN;
            }
        }

        for (const obs of additionalObstacles) {
            if (!tryAddObstacleToAllowableAreaPolygon(obs)) {
                return DEFAULT_NO_ENDGUNS_RETURN;
            }
        }

        const allSystems = Object.entries(this.layout.systems);
        for (let i = 0; i < allSystems.length; i++) {
            const [systemId, system] = allSystems[i];
            if (systemId === this.systemId) continue;
            if (system.SystemProperties.SystemType === SystemTypes.CenterPivot) {
                const systemGeometryHelper = new CenterPivotGeometryHelper({
                    project: this.project,
                    layoutId: this.layoutId,
                    systemId
                })
                const p = systemGeometryHelper.getAreaPolygon({includeEndguns: false, includeSAC: true});
                if (p) {
                    if (!tryAddObstacleToAllowableAreaPolygon(p)) {
                        return DEFAULT_NO_ENDGUNS_RETURN;
                    }
                }
            }
            else if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
                const systemGeometryHelper = new LateralGeometryHelper({
                    project: this.project,
                    layoutId: this.layoutId,
                    systemId
                })
                const p = systemGeometryHelper.getAreaPolygon();
                if (p) {   
                    if (!tryAddObstacleToAllowableAreaPolygon(p)) {
                        return DEFAULT_NO_ENDGUNS_RETURN;
                    }
                }
            }
        }

        let throwMultiplier: number;
        if (this.isRigidSideForward()) {
            throwMultiplier = side === 'rigid' ? 1 : -1;
        }
        else {
            throwMultiplier = side === 'flex' ? 1 : -1;
        }
        
        const endGunTypes: {type: EndGunTypes, isPrimary: boolean, throwFeet: number}[] = [];
        const systemSide = side === 'rigid'
            ? this.system.FlangedSide
            : this.system.FlexSide;
        const sideEnum = side === 'rigid'
            ? SideEnum.Flanged
            : SideEnum.Flex;
        if (systemSide.EndOfSystem.EndGun.EndGunTypePrimary && systemSide.EndOfSystem.EndGun.EndGunTypePrimary !== EndGunTypes.None) {
            const throwFeet = getEndGunThrow(this.system, sideEnum, EndGunLocation.Primary);
            if (throwFeet) {
                endGunTypes.push({
                    type: systemSide.EndOfSystem.EndGun.EndGunTypePrimary, 
                    isPrimary: true,
                    throwFeet: throwFeet
                });
            }
        }
        if (systemSide.EndOfSystem.EndGun.EndGunTypeSecondary && systemSide.EndOfSystem.EndGun.EndGunTypeSecondary !== EndGunTypes.None) {
            const throwFeet = getEndGunThrow(this.system, sideEnum, EndGunLocation.Secondary);
            if (throwFeet) {
                endGunTypes.push({
                    type: systemSide.EndOfSystem.EndGun.EndGunTypeSecondary, 
                    isPrimary: false,
                    throwFeet: throwFeet
                });
            }
        }
        const lastTower = side === 'rigid'
            ? this.directionalRigidSideSpanTowers[this.directionalRigidSideSpanTowers.length - 1]
            : this.directionalFlexSideSpanTowers[this.directionalFlexSideSpanTowers.length - 1];
        
        const thisSystemPolygons = lastTower.segments.map(s => {
            const p = getLateralLinePolygons([s], 0, lastTower.outsideRadius);
            return p.polygons;
        })
        for (const endGun of endGunTypes) {
            const throwFeet = endGun.throwFeet;

            let systemConfigurationHeadingFrom = 0;
            const onOffs: { on: number; off: number; }[] = [];

            // First add the endguns for line segments:
            for (let iSegment = 0; iSegment < lastTower.segments.length; iSegment++) {
                const segment = lastTower.segments[iSegment];
                
                const lastSpanLine = getLateralLineString([ segment ], lastTower.outsideRadius);
                if (segment.type !== 'line') {
                    systemConfigurationHeadingFrom += length(feature(lastSpanLine), { units: 'feet' });
                    continue;
                }
                const endGunRigidEnd = getLateralLineString([ segment ], lastTower.outsideRadius + throwFeet * throwMultiplier);
                
                const endGunFullPolygon = polygon([
                    [
                        ...lastSpanLine.coordinates,
                        ...[...endGunRigidEnd.coordinates].reverse(),
                        lastSpanLine.coordinates[0]
                    ]
                ]);
                
                let segmentAllowableAreaPolygon = intersect(endGunFullPolygon, _allowableAreaPolygon);
                if (!segmentAllowableAreaPolygon) continue;
                for (let idx = 0; idx < thisSystemPolygons.length; idx++) {
                    if (idx !== iSegment) {
                        for (const p of thisSystemPolygons[idx]) {
                            segmentAllowableAreaPolygon = segmentAllowableAreaPolygon ? customDifference(segmentAllowableAreaPolygon, p) : null;
                        }
                    }
                }
                if (!segmentAllowableAreaPolygon) continue;
                
                const endGunAllowablePolygonCoords = coordAll(segmentAllowableAreaPolygon);
                const leadingLine = lineString([
                    lastSpanLine.coordinates[0], endGunRigidEnd.coordinates[0]
                ])
                const endGunAllowablePolygonCoordDistances = [ 
                    ...new Set(endGunAllowablePolygonCoords.map(x => ptldCustom(x, leadingLine, { units: 'feet' })))
                ].sort((a, b) => a - b);
        
                const endGunPolys: {feature: Feature<Polygon>, isPrimary: boolean}[] = [];
                for (let i = 0; i < endGunAllowablePolygonCoordDistances.length - 1; i++) {
                    const d1 = endGunAllowablePolygonCoordDistances[i];
                    const d2 = endGunAllowablePolygonCoordDistances[i + 1];
                    const p1 = along(lastSpanLine, d1, { units: 'feet' }).geometry.coordinates;
                    const p2 = along(lastSpanLine, d2, { units: 'feet' }).geometry.coordinates;
                    const o1 = along(endGunRigidEnd, d1, { units: 'feet' }).geometry.coordinates;
                    const o2 = along(endGunRigidEnd, d2, { units: 'feet' }).geometry.coordinates;
                    const irrigatedPolygon = polygon(
                        [
                            [
                                p1,p2,o2,o1,p1
                            ]
                        ], {
                            rdpFeatureType: "system/irrigatedArea-endGun",
                            ...this.propertiesForAll
                        }
                    )
                    const bufferedIrrigatedPolygon = buffer(irrigatedPolygon, -0.5, { units: 'feet'});
                    if (!bufferedIrrigatedPolygon) continue;
        
                    if (difference(bufferedIrrigatedPolygon, segmentAllowableAreaPolygon) === null) {
                        const on = Math.round((d1 + systemConfigurationHeadingFrom) * 100) / 100;
                        const off = Math.round(( d2 + systemConfigurationHeadingFrom ) * 100) / 100;
                        const prevOnOff = onOffs[onOffs.length - 1];
                        if (prevOnOff && prevOnOff.off === on) {
                            prevOnOff.off = off;
                        }
                        else {
                            onOffs.push({ 
                                on: Math.round((d1 + systemConfigurationHeadingFrom) * 100) / 100, 
                                off: Math.round(( d2 + systemConfigurationHeadingFrom ) * 100) / 100
                            });
                        }
                        endGunPolys.push({feature: irrigatedPolygon, isPrimary: endGun.isPrimary});                    
                    }
                }
        
                for (let i = 0; i < endGunPolys.length; i++) {
                    if (!tryAddObstacleToAllowableAreaPolygon(endGunPolys[i].feature)) {
                        return DEFAULT_NO_ENDGUNS_RETURN;
                    }
                }
                features.push(...endGunPolys);
                systemConfigurationHeadingFrom += length(feature(lastSpanLine), { units: 'feet' });
            }

            // Now add the radii:
            systemConfigurationHeadingFrom = 0;
            for (let iSegment = 0; iSegment < lastTower.segments.length; iSegment++) {
                const segment = lastTower.segments[iSegment];
                const lastSpanLine = getLateralLineString([ segment ], lastTower.outsideRadius);
                if (segment.type === 'line') {
                    // handled above:
                    systemConfigurationHeadingFrom += length(feature(lastSpanLine), { units: 'feet' });
                    continue;
                }
                
                const rings = _allowableAreaPolygon.geometry.type === 'Polygon'
                    ? [ _allowableAreaPolygon.geometry.coordinates ]
                    : _allowableAreaPolygon.geometry.coordinates;
                for (const ring of rings) {
                    const boundary = polygon([ ring[0] ]).geometry;
                    const obstacles = ring.slice(1).map(x => polygon([ x ]).geometry);
                    thisSystemPolygons.forEach((ps, idx) => {
                        if (idx !== iSegment) {
                            for (const p of ps) {
                                obstacles.push(p)
                            }
                        }
                    });

                    // The line of sight polygons used in calcualteEndGunOnOffs are confused by points that lie at the center
                    // of the los. Therefor, the obstacles are diffed with the buffer so that they do not include this center
                    // point.
                    // Further more, the obstacles from the lastTower outer radius and beyond is all we are interested in interms of
                    // los collisions
                    const buff = buffer(point(segment.center), lastTower.outsideRadius, { units: "feet" });
                    const obstaclesMinusCenterBuffer: Polygon[] = [];
                    obstacles.forEach(x => {
                        const d = customDifference(x, buff);
                        if (d.geometry.type === 'Polygon') {
                            obstaclesMinusCenterBuffer.push(d.geometry)
                        }
                        else {
                            d.geometry.coordinates.forEach(y => {
                                const p = polygon(y);
                                obstaclesMinusCenterBuffer.push(p.geometry);
                            })
                        }
                    });
                    
                    const results = calculateEngGunOnOffs({
                        system: {
                            center: point(segment.center).geometry,
                            minBearing: segment.type === 'anticlockwise' ? segment.b2 : segment.b1,
                            maxBearing: segment.type === 'anticlockwise' ? segment.b1 : segment.b2,
                            radiusFeet: lastTower.outsideRadius
                        },
                        throwDistanceFeet: throwFeet,
                        obstacles: obstaclesMinusCenterBuffer,
                        boundary
                    })

                    // console.log("args", 
                    //     turf.featureCollection([
                    //         boundary, ...obstaclesMinusCenterBuffer
                    //     ].map(x => feature(x)))
                    // )
                    // console.log("results", results)
                    
                    for (const result of results) {
                        const p1 = destination(segment.center, lastTower.outsideRadius, segment.type === 'anticlockwise' ? result.endBearing : result.startBearing, { units: 'feet' });
                        const p2 = destination(segment.center, lastTower.outsideRadius, segment.type === 'anticlockwise' ? result.startBearing : result.endBearing, { units: 'feet' });
                        const np1 = turf.nearestPointOnLine(lastSpanLine, p1, { units: 'feet' });
                        const np2 = turf.nearestPointOnLine(lastSpanLine, p2, { units: 'feet' });
                        if (Math.abs(np2.properties.location - np1.properties.location) < 1) {
                            console.log("End gun area to small, skipping")
                            continue;
                        }
                        onOffs.push({
                            on: systemConfigurationHeadingFrom + np1.properties.location,
                            off: systemConfigurationHeadingFrom + np2.properties.location,
                        })
                        
                        const poly = getCombineLateralLinePolygons(
                            [ {
                                ...segment,
                                b1: segment.type === 'anticlockwise' ? result.endBearing : result.startBearing,
                                b2:  segment.type === 'anticlockwise' ? result.startBearing : result.endBearing,
                            }], lastTower.outsideRadius, lastTower.outsideRadius + throwFeet
                        )
                        if (!tryAddObstacleToAllowableAreaPolygon(poly)) {
                            return DEFAULT_NO_ENDGUNS_RETURN;
                        }
                    }
                }
                systemConfigurationHeadingFrom += length(feature(lastSpanLine), { units: 'feet' });
            }
            endGunInformation.push({ endGun: endGun.type, onOffs, isPrimary: endGun.isPrimary, throwFeet });
        }
        return {
            features,
            endGunInformation
        };
    }
    getEndGunInformation() {
        const { flex, rigid } = this._calculateEndGuns();
        return {
            rigid: rigid.endGunInformation,
            flex: flex.endGunInformation
        }
    }
    getDistanceFromSystemCenterToAftStartFeet() {
        if (!this.isCanalFeed) {
            return 0;
        }
        return this.distanceFromCanalCenterToAftSide;
    }
    getDistanceFromSystemCenterToFwdStartFeet() {
        if (!this.isCanalFeed) {
            return 0;
        }
        return this.distanceFromCanalCenterToFwdSide;
    }
    getDistanceFromSystemCenterToAftEndFeet() {
        let runningLengthFeet = 0;
        const spans = this.isRigidSideForward() 
            ? this.directionalFlexSideSpanTowers 
            : this.directionalRigidSideSpanTowers;
        if (spans.length) {
            runningLengthFeet = Math.abs(spans.slice(-1)[0].outsideRadius);
        }
        runningLengthFeet += this.getDistanceFromSystemCenterToAftStartFeet();
        return runningLengthFeet;
    }
    getDistanceFromSystemCenterToFwdEndFeet() {
        let runningLengthFeet = 0;
        const spans = this.isRigidSideForward() 
            ? this.directionalRigidSideSpanTowers 
            : this.directionalFlexSideSpanTowers;
        if (spans.length) {
            runningLengthFeet = Math.abs(spans.slice(-1)[0].outsideRadius);
        }
        runningLengthFeet += this.getDistanceFromSystemCenterToFwdStartFeet();
        return runningLengthFeet;
    }
    getIrrigatedAreaPolygons() {
        const flexSide = this._getFlexSideIrrigatedAreaPolygons();
        const rigidSide = this._getRigidSideIrrigatedAreaPolygons();
        const endGuns = this._calculateEndGuns();

        const egfeatures: Feature<Polygon>[] = [
            ...endGuns.flex.features.map(x => x.feature),
            ...endGuns.rigid.features.map(x => x.feature)
        ];        

        const a = [ 
            ...rigidSide.mainSystem, ...rigidSide.drop,
            ...flexSide.mainSystem, ...flexSide.drop,
            ...egfeatures 
        ];
        return a;
    }
    getFlexSideMainIrrigatedAreaAcres() {
        const areaPolygons = this._getFlexSideIrrigatedAreaPolygons();
        let runningIrrigatedAreaSqm = 0;
        areaPolygons.mainSystem.forEach(p => {
            runningIrrigatedAreaSqm += area(p);
        })
        return convertArea(runningIrrigatedAreaSqm, 'meters', 'acres');
    }
    getFlexSideDropIrrigatedAreaAcres() {
        const areaPolygons = this._getFlexSideIrrigatedAreaPolygons();
        let runningIrrigatedAreaSqm = 0;
        areaPolygons.drop.forEach(p => {
            runningIrrigatedAreaSqm += area(p);
        })
        return convertArea(runningIrrigatedAreaSqm, 'meters', 'acres');
    }
    getFlangedSideMainIrrigatedAreaAcres() {
        const areaPolygons = this._getRigidSideIrrigatedAreaPolygons();
        let runningIrrigatedAreaSqm = 0;
        areaPolygons.mainSystem.forEach(p => {
            runningIrrigatedAreaSqm += area(p);
        })
        return convertArea(runningIrrigatedAreaSqm, 'meters', 'acres');
    }
    getFlangedSideDropIrrigatedAreaAcres() {
        const areaPolygons = this._getRigidSideIrrigatedAreaPolygons();
        let runningIrrigatedAreaSqm = 0;
        areaPolygons.drop.forEach(p => {
            runningIrrigatedAreaSqm += area(p);
        })
        return convertArea(runningIrrigatedAreaSqm, 'meters', 'acres');
    }
    getEndGunIrrigatedAreaAcres() {
        const endGuns = this._calculateEndGuns();

        let flexAcres: {primary: number[], secondary: number[]} = {primary: [], secondary: []};
        endGuns.flex.features.forEach(p => {
            if (p.isPrimary){
                flexAcres.primary.push(convertArea(area(p.feature), 'meters', 'acres'));
            }
            else {
                flexAcres.secondary.push(convertArea(area(p.feature), 'meters', 'acres'));
            }
        });
        
        let rigidAcres: {primary: number[], secondary: number[]} = {primary: [], secondary: []};
        endGuns.rigid.features.forEach(p => {
            if (p.isPrimary){
                rigidAcres.primary.push(convertArea(area(p.feature), 'meters', 'acres'));
            }
            else {
                rigidAcres.secondary.push(convertArea(area(p.feature), 'meters', 'acres'));
            }
        });

        return {flexAcres, rigidAcres};
    }
    getIrrigatedAreaAcres(): number | undefined {
        let endgunAcres = this.getEndGunIrrigatedAreaAcres();
        const areas = [
            this.getFlangedSideMainIrrigatedAreaAcres(),
            this.getFlangedSideDropIrrigatedAreaAcres(),
            this.getFlexSideMainIrrigatedAreaAcres(),
            this.getFlexSideDropIrrigatedAreaAcres(),
            ...endgunAcres.flexAcres.primary,
            ...endgunAcres.flexAcres.secondary,
            ...endgunAcres.rigidAcres.primary,
            ...endgunAcres.rigidAcres.secondary
        ];
        let runningIrrigatedArea = 0;
        areas.forEach(a => {
            runningIrrigatedArea += a as number;
        })
        return runningIrrigatedArea;
    }
    getDrawFeatures(drawMode?: string) {
        if (this.isActive && drawMode === "lateral_select") {
            return this._getDrawFeaturesSelectMode();
        }
        else {
            return this.generateSimpleSelectGeoJSON();
        }
    }
    // This is without end gun area
    getAreaPolygon(): Polygon | undefined {
        const result = this._getSinglePolygonAndVerticies();
        if (result.geometry.type === 'Polygon') {
            return result.geometry;
        }
        else {
            return undefined;
        }
    }
    public getExportFeatures(): ImportExportFeature[] {
        const features: ImportExportFeature[] = [];

        // feed line
        features.push(
            feature(
                this.feedCenterLine(),
                {
                    importType: 'feedLine'
                }
            ) as ImportExportFeature
        )

        // canal:
        if (this.isCanalFeed) {
            features.push(
                feature(
                    this.canalCenterLine,
                    {
                        importType: 'canal'
                    }
                )
            )
        }

        // // irrigated area flex side
        {
            let u: Feature<Polygon|MultiPolygon> | null = null;
            const flexSide = this._getFlexSideIrrigatedAreaPolygons();
            for (const ia of [...flexSide.mainSystem, ...flexSide.drop ]) {
                if (u === null) {
                    u = ia;
                }
                else {
                    u = union(u, ia);
                }
            }
            if (u) {
                features.push(
                    feature(
                        u.geometry,
                        {
                            importType: 'flexSideIrrigatedArea'
                        }
                    )
                )
            }
        }

        // // irrigated area rigid side
        {
            let u: Feature<Polygon|MultiPolygon> | null = null;
            const rigidSide = this._getRigidSideIrrigatedAreaPolygons();
            for (const ia of [...rigidSide.mainSystem, ...rigidSide.drop ]) {
                if (u === null) {
                    u = ia;
                }
                else {
                    u = union(u, ia);
                }
            }
            if (u) {
                features.push(
                    feature(
                        u.geometry,
                        {
                            importType: 'rigidSideIrrigatedArea'
                        }
                    )
                )
            }
        }

        const wts = this.getWheelTracksDetailed();
        wts.flexSide.forEach((w, idx) => {
            const wtf = lineString<ImportExportFeatureProperties>(w.coordinates, {
                importType: "wheelTrack",
                wtId: idx,
                side: "flex"
            });
            features.push(wtf);
        });
        wts.rigidSide.forEach((w, idx) => {
            const wtf = lineString<ImportExportFeatureProperties>(w.coordinates, {
                importType: "wheelTrack",
                wtId: idx,
                side: "rigid"
            });
            features.push(wtf);
        });
        if (wts.flexEndBoom) {
            const wtf = lineString<ImportExportFeatureProperties>(wts.flexEndBoom.coordinates, {
                importType: "endBoom",
                side: "flex"
            });
            features.push(wtf);
        }
        if (wts.rigidEndBoom) {
            const wtf = lineString<ImportExportFeatureProperties>(wts.rigidEndBoom.coordinates, {
                importType: "endBoom",
                side: "rigid"
            });
            features.push(wtf);
        }

        // // end guns:
        const endGuns = this._calculateEndGuns();
        const egfeatures: Feature<Polygon>[] = [
            ...endGuns.flex.features.map(x => x.feature),
            ...endGuns.rigid.features.map(x => x.feature)
        ];     

        for (const eg of egfeatures) {
            const p = polygon<ImportExportFeatureProperties>(
                eg.geometry.coordinates, {
                    importType: 'endGunIrrigatedArea'
                }
            )
            features.push(p);
        }

        console.log("fe", features)

        return features;
    }

    // private methods:
    private _getDrawFeaturesSelectMode() {
        const features: Feature[] = [];

        if (this.directionalFlexSideSpanTowers.length === 0 && this.directionalRigidSideSpanTowers.length === 0) {
            // lateral center line (canal center or feed center)
            features.push(
                feature(
                    this.lateralCenterLine,
                    {
                        ...this.propertiesForAll,
                        rdpFeatureType: "system/lateral"
                    }
                )
            )
            return features;
        }

        // system area:
        const result = this._getSinglePolygonAndVerticies();
        if (result.geometry && result.geometry.type === 'Polygon') {
            features.push(
                feature(
                    result.geometry,
                    {
                        ...this.propertiesForAll,
                        rdpFeatureType: "centerPivotSelectMode/irrigatedArea",
                        activeSystem: this.isActive,
                        wheelTrackFeatureId: `wt-${this.systemId}`
                    }
                )
            )
        }

        // wheel tracks:
        if (result.wheelTracks) {
            const wheelTracks = multiLineString(
                result.wheelTracks.map(x => x.coordinates),
                {
                    rdpFeatureType: "centerPivotSelectMode/wheelTracks"
                }
            )
            wheelTracks.id = `wt-${this.systemId}`;
            features.push(wheelTracks);
        }

        {
            // end gun areas are added so that they are visible in lateral select mode.
            // They are hidden from that mode when a vertex is dragged.
            // end guns:
            const endguns = this._calculateEndGuns();
            const egfeatures: Feature<Polygon>[] = [
                ...endguns.flex.features.map(x => x.feature),
                ...endguns.rigid.features.map(x => x.feature)
            ];        
            features.push(...egfeatures);
        }
        
        return features;
    }    
    private _getSinglePolygonAndVerticies(): { 
        geometry: Polygon | LineString, 
        verticies: ISpanVertex[], 
        wheelTracks: LineString[],
        flexSidePolygon?: Polygon,
        flangedSidePolygon?: Polygon
    } {
        // console.log("sys", this.system)
        const verticies: ISpanVertex[] = [];
        const feedVerticies: ISpanVertex[] = [];
        const feedLine = this.lateralCenterLine;
        for (let i = 0; i < this.lateralCenterLine.coordinates.length; i++) {
            const handle = point(feedLine.coordinates[i]).geometry;            
            if (
                i === 0 && 
                IsPivotingLateral(this.system) &&
                (this.system.FlangedSide.Span.length && (!this.system.lateral.endPivot || this.system.lateral.startPivot?.retrace))
            ) {
                // dont add pivot end, the startPivot vertex will be used
                // console.log("wont add end feed")
            }
            else if (
                (i === this.lateralCenterLine.coordinates.length - 1) && 
                IsPivotingLateral(this.system) &&
                (this.system.FlangedSide.Span.length && (!this.system.lateral.startPivot || this.system.lateral.endPivot?.retrace))
            ) {
                // dont add pivot start, the endPivot vertex will be used
                // console.log("wont add start feed")
            }
            else {
                feedVerticies.push({
                    type: 'feedLine',
                    handle,
                    vertexIndex: i,
                    pivotIdLabel: IsPivotingLateral(this.system) ? (this.lateralCenterLine.coordinates.length - i).toString() : undefined
                });
            }
        }

        type IrrigatedAreaResult = { irrigatedArea: Polygon, channel?: Polygon[] } | undefined;
        const getIrrigatedAreaPolygonForSide = (side: "flanged" | "flex"): IrrigatedAreaResult => {
            let u: Feature<MultiPolygon | Polygon> | null = null;
            const towers = side === "flex" ? this.directionalFlexSideSpanTowers : this.directionalRigidSideSpanTowers;
            const firstTower = towers[0];
            if (!firstTower) return undefined;
            const insideRadius = firstTower.insideRadius;
            for (let i = towers.length - 1; i >= 0; i--) {
                const spanTower = towers[i];
                if (i === towers.length - 1 || spanTower.isDropSpan) {
                    u = getCombineLateralLinePolygons(
                        spanTower.segments,
                        insideRadius,
                        spanTower.outsideRadius,
                        u
                    )
                }
            }

            let irrigatedArea: Polygon;
            if (!u) {
                console.log("Irrigated area polygon was not formed");
                return undefined;
            }
            
            if (u.geometry.type === 'MultiPolygon') {
                const fc = turf.featureCollection(u.geometry.coordinates.map(p => polygon(p)));
                const d = turf.dissolve(fc);
                if (d.features.length === 1) {
                    irrigatedArea = d.features[0].geometry;
                }
                else {
                    console.log("Irrigated area polygon generated a multipolygon. This multipolygon could not be dissolved", u, d);
                    return undefined;
                }
            }

            if (u.geometry.type === 'Polygon') {
                irrigatedArea = u.geometry;
            }
            return {
                irrigatedArea
            }
        }

        const getChannel = () => {
            if (this.isCanalFeed && this.directionalFlexSideSpanTowers.length && this.directionalRigidSideSpanTowers.length) {
                const leadingFlanged = this.directionalRigidSideSpanTowers[0];
                const leadingFlex = this.directionalFlexSideSpanTowers[0];
                const lineFlanged = getLateralLineString(leadingFlanged.segments, leadingFlanged.insideRadius);
                const lineFlex = getLateralLineString(leadingFlex.segments, leadingFlex.insideRadius);
                return polygon([
                    [
                        ...lineFlanged.coordinates,
                        ...lineFlex.coordinates.slice().reverse(),
                        lineFlanged.coordinates[0]
                    ]
                ])
            }
            return undefined;
        }

        const getVerticiesForSide = (side: "flanged" | "flex") => {
            const towers = side === 'flex'
                ? this.directionalFlexSideSpanTowers
                : this.directionalRigidSideSpanTowers;
            const verticies: ISpanVertex[] = [];

            if (IsPivotingLateral(this.system) && towers.length) {
                const tower = towers[0];
                const insideTrackLineFull = getLateralLineString(this.feedLineSegments, 0);
                const firstSegment = tower.segments.filter((x): x is ILineSegment => x.type === 'line')[0];
                const lastSegment = tower.segments.filter((x): x is ILineSegment => x.type === 'line').slice(-1)[0];
                const b1 = turf.bearing(firstSegment.p2, firstSegment.p1);
                const handleStart = destination(firstSegment.p1, 0, b1, { units: 'feet' });
                const b2 = turf.bearing(lastSegment.p1, lastSegment.p2);
                const handleEnd = destination(lastSegment.p2, 0, b2, { units: 'feet' });
                if (!this.system.lateral.startPivot?.retrace) {
                    verticies.push({
                        type: 'pivotingEnd',
                        handle: handleEnd.geometry,
                        line: insideTrackLineFull,
                        tower,
                        pivotIdLabel: this.system.lateral.endPivot
                            ? "end" 
                            : (this.system.lateral.line.coordinates.length - 1 + 1).toString()
                    });
                }
                if(!this.system.lateral.endPivot?.retrace) {
                    verticies.push({
                        type: 'pivotingStart',
                        handle: handleStart.geometry,
                        line: insideTrackLineFull,
                        tower,
                        pivotIdLabel: this.system.lateral.startPivot
                            ? "start" 
                            : "1"
                    });
                }
            }

            for (let i = 0; i < towers.length; i++) {
                const prevSpanTower = towers[i - 1];
                const spanTower = towers[i];
                if (prevSpanTower?.isDropSpan) {
                    const insideTrackLineFull = getLateralLineString(prevSpanTower.segments, spanTower.insideRadius);
                    if (spanTower.leftLostSegments.length) {
                        const insideTrackLineLeft = getLateralLineString(spanTower.leftLostSegments, spanTower.insideRadius);     
                        verticies.push({
                            type: 'dropSpanStart',
                            handle: point(insideTrackLineLeft.coordinates.slice(-1)[0]).geometry,
                            spanIndex: prevSpanTower.spanTowerIndex,
                            line: insideTrackLineFull,
                            side: side === 'flex' ? "FlexSide" : "FlangedSide"
                        })
                    }
                    else {
                        verticies.push({
                            type: 'dropSpanStart',
                            handle: point(insideTrackLineFull.coordinates[0]).geometry,
                            spanIndex: prevSpanTower.spanTowerIndex,
                            line: insideTrackLineFull,
                            side: side === 'flex' ? "FlexSide" : "FlangedSide"
                        })
                    }
                    if (spanTower.rightLostSegments.length) {
                        const insideTrackLineRight = getLateralLineString(spanTower.rightLostSegments, spanTower.insideRadius); 
                        verticies.push({
                            type: 'dropSpanEnd',
                            handle: point(insideTrackLineRight.coordinates[0]).geometry,
                            spanIndex: prevSpanTower.spanTowerIndex,
                            line: insideTrackLineFull,
                            side: side === 'flex' ? "FlexSide" : "FlangedSide"
                        });
                    }
                    else {
                        verticies.push({
                            type: 'dropSpanEnd',
                            handle: point(insideTrackLineFull.coordinates.slice(-1)[0]).geometry,
                            spanIndex: prevSpanTower.spanTowerIndex,
                            line: insideTrackLineFull,
                            side: side === 'flex' ? "FlexSide" : "FlangedSide"
                        })
                    }
                }
            }

            return {
                verticies
            }            
        }

        const getFlexDropVerticies = () => {
            const flexTower1 = this.directionalFlexSideSpanTowers[0];
            const flangedTower1 = this.directionalRigidSideSpanTowers[0];
            
            const insideFlexTrackLine = getLateralLineString(flexTower1.segments, flexTower1.insideRadius + 0.5 * flexTower1.outsideRadius);
            const insideFlangedTrackLine = getLateralLineString(flangedTower1.segments, flangedTower1.insideRadius);

            const verticies: ISpanVertex[] = [                
                {
                    type: 'flexDropStart',
                    handle: point(insideFlexTrackLine.coordinates[0]).geometry,
                    line: insideFlangedTrackLine
                },
                {
                    type: 'flexDropEnd',
                    handle: point(insideFlexTrackLine.coordinates.slice(-1)[0]).geometry,
                    line: insideFlangedTrackLine
                }
            ];
            return verticies;
        }

        // system area flex side
        let flexSideIrrigatedAreaResult: IrrigatedAreaResult = undefined;
        if (this.hasFlexSide) {
            if (this.hasFlexDrop) {
                if (!this.isFlexDropValid()) {
                    return {
                        geometry: feedLine,
                        verticies: feedVerticies,
                        wheelTracks: this.getWheelTracks()
                    }
                }
                else {
                    verticies.push(...getFlexDropVerticies());
                }
            }
            const flexSideVerticies = getVerticiesForSide('flex');
            verticies.push(...flexSideVerticies.verticies);
            flexSideIrrigatedAreaResult = getIrrigatedAreaPolygonForSide('flex');
        }

        // system area rigid side
        let flangedSideIrrigatedAreaResult: IrrigatedAreaResult = undefined;
        {
            const flangedSideVerticies = getVerticiesForSide('flanged');
            verticies.push(...flangedSideVerticies.verticies);
            flangedSideIrrigatedAreaResult = getIrrigatedAreaPolygonForSide('flanged');
        }

        if (!flangedSideIrrigatedAreaResult) {
            return {
                geometry: feedLine,
                verticies: feedVerticies,
                wheelTracks: this.getWheelTracks()
            }
        }
        
        verticies.push(...feedVerticies);
        if (!flexSideIrrigatedAreaResult) {
            return {
                geometry: flangedSideIrrigatedAreaResult.irrigatedArea,
                verticies,
                wheelTracks: this.getWheelTracks(),
                flangedSidePolygon: flangedSideIrrigatedAreaResult.irrigatedArea
            }
        }
        
        let systemAreaUnion = turf.union(flangedSideIrrigatedAreaResult.irrigatedArea, flexSideIrrigatedAreaResult.irrigatedArea);
        if (flangedSideIrrigatedAreaResult.channel?.length) {
            for (const p of flangedSideIrrigatedAreaResult.channel) {
                systemAreaUnion = turf.union(systemAreaUnion, p);
            }
        }
        if (flexSideIrrigatedAreaResult.channel?.length) {
            for (const p of flexSideIrrigatedAreaResult.channel) {
                systemAreaUnion = turf.union(systemAreaUnion, p);
            }
        }
        const channel = getChannel();
        if (channel) {
            systemAreaUnion = turf.union(systemAreaUnion, channel);
        }
        let systemArea: Polygon | undefined;
        if (!systemAreaUnion || systemAreaUnion.geometry.type !== "Polygon") {
            console.log("systemAreaUnion is not a polygon", systemAreaUnion);
            systemArea = undefined;
        }
        else {
            systemArea = systemAreaUnion.geometry;
        }
        
        return {
            geometry: systemArea,
            verticies,
            wheelTracks: this.getWheelTracks(),
            flexSidePolygon: flexSideIrrigatedAreaResult.irrigatedArea,
            flangedSidePolygon: flangedSideIrrigatedAreaResult.irrigatedArea
        }

    }
    private getWheelTracks(): LineString[] {
        const detailed = this.getWheelTracksDetailed();
        const tracks: LineString[] = [];
        if (detailed.flexSideStart) {
            tracks.push(detailed.flexSideStart);
        }
        tracks.push(...detailed.flexSide);
        tracks.push(detailed.rigidSideStart);
        tracks.push(...detailed.rigidSide);

        return tracks;
    }
    
    // public static methods:
    public static isLateral(feature?: Feature) {
        if (!feature || !feature.properties) return false;
        const isLateral = feature.properties.user_isLateral || feature.properties.isLateral;
        return isLateral === true;
    }
    public static getDefinition(feature?: Feature): IProperties | undefined {
        if (!feature || !feature.properties) return undefined;
        const systemId = feature.properties.user_systemId || feature.properties.systemId;
        const layoutId = feature.properties.user_layoutId || feature.properties.layoutId;
        const isLateral = feature.properties.user_isLateral || feature.properties.isLateral;
        if (!isLateral || !systemId) return undefined;
        return {
            isLateral: true,
            systemId,
            layoutId,
            activeSystem: false
        }
    }    
    public static createSingleGeoJSONFeature(system: ISystem) {

        const helper = new LateralGeometryHelper({
            systemId: "1",
            layoutId: "1",
            project: {
                layouts: {
                    "1": {
                        systems: {
                            "1": system
                        }
                    }
                }
            } as any
        })

        return helper._getSinglePolygonAndVerticies();
    }
}
